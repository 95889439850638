import { useQuery } from '@apollo/client'

import { atlasClient } from 'graphql/client'

import { GET_UNREAD_MESSAGES_COUNT } from './messageMenuOption.queries'

export const useUnreadMessagesCount = () => {
  const { data } = useQuery(GET_UNREAD_MESSAGES_COUNT, {
    client: atlasClient,
  })
  return {
    unreadMessagesCount: data?.unreadMessageCount?.count ?? 0,
  }
}
