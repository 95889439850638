import { useEffect } from 'react'

import { isProductsPageContent } from 'pages/LegacyPage/LegacyPage.utils'

import {
  SAVE_PRODUCTS_EVENT_NAME,
  SAVE_PRODUCTS_RESPONSE_EVENT_NAME,
  SAVING_LOADER_PRESENT_EVENT_NAME,
} from '../productsEditModal.constants'
import {
  PHP_ORDER_MODIFY_ERROR_TOAST_EVENT_NAME,
  PHP_ORDER_MODIFY_SUCCESS_TOAST_EVENT_NAME,
} from 'features/Hubs/Orders/Review/components/OrderReviewReleaseHeader/useInitializePHPOrderModifyToasts'

const addLoaderObserver = () => {
  const observerCallback = function () {
    if (document.querySelector('.order_info .spinner')) {
      window.parent?.postMessage(
        { type: SAVING_LOADER_PRESENT_EVENT_NAME, loader: true },
        window.location.origin,
      )
      observer.disconnect()
    }
  }

  const observer = new MutationObserver(observerCallback)
  observer.observe(document, {
    childList: true,
    subtree: true,
  })
}

export const ProductsEditEvents = () => {
  useEffect(() => {
    const showOnlyProductsContent = isProductsPageContent()
    if (!showOnlyProductsContent) return

    const saveProductsEventHandler = (e: MessageEvent) => {
      if (e.data === SAVE_PRODUCTS_EVENT_NAME) {
        const saveButton = document.getElementById('designer-edit-order')
        const retailerSaveButton = document.getElementById('retailer-edit-note')

        if (saveButton && saveButton.click) {
          addLoaderObserver()
          saveButton.click()
        } else if (retailerSaveButton && retailerSaveButton.click) {
          addLoaderObserver()
          retailerSaveButton.click()
        }
      }
    }

    const successSaveMessageHandler = () => {
      window.parent?.postMessage(
        { type: SAVE_PRODUCTS_RESPONSE_EVENT_NAME, success: true },
        window.location.origin,
      )
    }

    const errorSaveMessageHandler = () => {
      window.parent?.postMessage(
        { type: SAVE_PRODUCTS_RESPONSE_EVENT_NAME, success: false },
        window.location.origin,
      )
    }

    // This event listener takes care of executing a click in the save button when
    // we receive an event type SAVE_PRODUCTS_EVENT_NAME
    window.addEventListener('message', saveProductsEventHandler)

    // Event listener that will tell us when the user saved successfully the products
    // We will trigger another event to the parent of the window informing this
    window.addEventListener(
      PHP_ORDER_MODIFY_SUCCESS_TOAST_EVENT_NAME,
      successSaveMessageHandler,
    )

    // Event listener that will tell us when the user had errors when saving the products
    // We will trigger another event to the parent of the window informing this
    window.addEventListener(
      PHP_ORDER_MODIFY_ERROR_TOAST_EVENT_NAME,
      errorSaveMessageHandler,
    )

    return () => {
      window.removeEventListener('message', saveProductsEventHandler)
      window.removeEventListener(
        PHP_ORDER_MODIFY_SUCCESS_TOAST_EVENT_NAME,
        successSaveMessageHandler,
      )
      window.removeEventListener(
        PHP_ORDER_MODIFY_ERROR_TOAST_EVENT_NAME,
        errorSaveMessageHandler,
      )
    }
  }, [])

  return null
}
