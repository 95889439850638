import { Component } from 'react'

import classNames from 'classnames'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import PropTypes from 'prop-types'

import { translateText } from 'utils/sdks/localize'

import { PRODUCT_COLOR_CODE, PRODUCT_SIZE_CODE } from 'shop/products/traits'
import { getVariantByCode, hasVariantOrderMinimum } from 'shop/products/utils'

import { Loader, TabContainer } from 'components/Core'
import {
  BULK_COLUMN_ID,
  BULK_TABLE_ID,
  SIZED_TABLE_ID,
  UNITS_AVAILABLE_ROW_ID,
} from 'modals/Shop/TablesConfiguration'

import ProductDetailBulkTable from '../ProductDetailBulkTable/ProductDetailBulkTable.container'
import ProductDetailSizedTable from '../ProductDetailSizedTable/ProductDetailSizedTable.container'
import styles from './ProductDetailTableWrapper.less'

export const BULK_PANEL_POSITION = 0
export const SIZED_PANEL_POSITION = 1

class ProductDetailTableWrapper extends Component {
  state = {
    tablesAreInitialized: false,
  }

  componentDidMount = () => {
    const {
      isProRetailer,
      setSelectedTabId,
      flags: { proRetailProductDetail },
    } = this.props

    if (isProRetailer && !proRetailProductDetail) {
      setSelectedTabId(BULK_PANEL_POSITION)
    }

    this.initializeTables()
  }

  componentDidUpdate = ({ availabilityGroupsItems }) => {
    this.initializeTables()
    this.setUnitsRowInitialState(availabilityGroupsItems)
  }

  componentWillUnmount = () => {
    const {
      resetTableToDefaultState,
      isProRetailer,
      setSelectedTabId,
      flags: { proRetailProductDetail },
    } = this.props

    if (!isProRetailer || proRetailProductDetail) {
      setSelectedTabId(SIZED_PANEL_POSITION)
      resetTableToDefaultState({ tableId: SIZED_TABLE_ID })
    }
    resetTableToDefaultState({ tableId: BULK_TABLE_ID })
  }

  getOrderMinimums = () => {
    const {
      product: { orderMinimum },
      product: { variants },
      isProRetailer,
      flags: { proRetailProductDetail },
    } = this.props
    const isOrderMinimum = hasVariantOrderMinimum(variants) || orderMinimum
    return (
      isOrderMinimum > 0 && (
        <span
          className={classNames(styles.orderMinInfo, {
            [styles.onlyBulkTable]: !proRetailProductDetail && isProRetailer,
          })}
        >
          <span className={styles.orderMin}>*</span>
          {translateText('There are unit minimums')}
        </span>
      )
    )
  }

  getTabContent = (selectedPanel) => {
    const {
      doors,
      product,
      discounts,
      discountFactor,
      priceTypeId,
      currencyCode,
      retailCurrency,
      variantColumnCode,
      variantRowCode,
      isProductReady,
      availabilityGroupsItems,
      areDiscountsReady,
      trackCheckboxClick,
      skuDeliveries,
      userDateFormat,
    } = this.props
    const { tablesAreInitialized } = this.state
    const commonProps = {
      product,
      discounts,
      discountFactor,
      doors,
      currencyCode,
      retailCurrency,
      priceTypeId,
      isProductReady,
      variantColumnCode,
      variantRowCode,
      isTableInitialized: tablesAreInitialized,
      areDiscountsReady,
      trackCheckboxClick,
      skuDeliveries,
      userDateFormat,
    }

    return selectedPanel === SIZED_PANEL_POSITION ? (
      <ProductDetailSizedTable
        {...commonProps}
        availabilityGroupsItems={availabilityGroupsItems}
      />
    ) : (
      <ProductDetailBulkTable {...commonProps} tableId={BULK_TABLE_ID} />
    )
  }

  setUnitsRowInitialState = (prevAvailabilityGroupsItems) => {
    const { availabilityGroupsItems, initTableRow } = this.props
    if (!isEqual(availabilityGroupsItems, prevAvailabilityGroupsItems)) {
      initTableRow({
        tableId: SIZED_TABLE_ID,
        rowId: UNITS_AVAILABLE_ROW_ID,
        value: false,
      })
    }
  }

  initializeTables = () => {
    const {
      isProductReady,
      variantRowCode,
      variantColumnCode,
      isProRetailer,
      flags: { proRetailProductDetail },
    } = this.props
    const { tablesAreInitialized } = this.state
    if (isProductReady && !tablesAreInitialized) {
      const {
        initializeTable,
        initializeTableTotalRow,
        doors,
        product: { casepackIds, variants },
      } = this.props
      const variantRow = getVariantByCode(variants, variantRowCode)
      const variantColumn = getVariantByCode(variants, variantColumnCode)
      const sectionsIds = map(get(variantRow, 'values'), (value) => value.id)
      const dynamicRowsIds = map(doors, (door) => door.key)

      const sizedTableDynamicColumns = [
        ...map(casepackIds, (id) => id),
        ...map(get(variantColumn, 'values'), (size) => size.id),
      ]
      if (!isProRetailer || proRetailProductDetail) {
        initializeTable({
          tableId: SIZED_TABLE_ID,
          sectionsIds,
          dynamicRowsIds: [...dynamicRowsIds, UNITS_AVAILABLE_ROW_ID],
          dynamicColumnsIds: sizedTableDynamicColumns,
        })
        initializeTableTotalRow({
          tableId: SIZED_TABLE_ID,
          dynamicColumnsIds: sizedTableDynamicColumns,
        })
      }
      initializeTable({
        tableId: BULK_TABLE_ID,
        sectionsIds,
        dynamicRowsIds: [...dynamicRowsIds],
        dynamicColumnsIds: [BULK_COLUMN_ID],
      })
      initializeTableTotalRow({
        tableId: BULK_TABLE_ID,
        dynamicColumnsIds: [BULK_COLUMN_ID],
      })

      this.setState({ tablesAreInitialized: true })
    }
  }

  renderTabs = () => {
    const {
      isProRetailer,
      isProductReady,
      setSelectedTabId,
      flags: { proRetailProductDetail },
      trackBulk,
      trackSized,
    } = this.props

    return isProRetailer && !proRetailProductDetail ? (
      <TabContainer.ScreenContainer className={styles.bulkTabContainer}>
        <Loader active={!isProductReady}>
          {this.getTabContent(BULK_PANEL_POSITION)}
        </Loader>
      </TabContainer.ScreenContainer>
    ) : (
      <TabContainer
        initialValue={SIZED_PANEL_POSITION}
        render={(
          selectedPanel,
          onSelectPanel,
          onSlide,
          sliderPosition,
          panelRefHandler,
          panelWidth,
        ) => (
          <>
            <TabContainer.PanelContainer
              onSlide={onSlide}
              sliderPosition={sliderPosition}
              selectedPanel={selectedPanel}
              onSelectPanel={(panel) => {
                if (panel === 0) {
                  trackBulk()
                } else {
                  trackSized()
                }
                onSelectPanel(panel)
                setSelectedTabId(panel)
              }}
              panelRefHandler={panelRefHandler}
              panelWidth={panelWidth}
              panelClassName={styles.tabWidth}
              options={[
                {
                  id: BULK_TABLE_ID,
                  content: <div className={styles.tabHeaderText}>Bulk</div>,
                },
                {
                  id: SIZED_TABLE_ID,
                  content: <div className={styles.tabHeaderText}>Sized</div>,
                },
              ]}
              showMenu={false}
              showArrows={false}
              underlineSelected
              className={styles.tabHeader}
            />
            <TabContainer.ScreenContainer className={styles.tabContent}>
              <Loader active={!isProductReady}>
                {this.getTabContent(selectedPanel)}
              </Loader>
            </TabContainer.ScreenContainer>
          </>
        )}
      />
    )
  }

  render() {
    const { className } = this.props
    return (
      <div className={classNames(styles.ProductDetailTableWrapper, className)}>
        <div className={styles.tabsContainer}>
          {this.getOrderMinimums()}
          {this.renderTabs()}
        </div>
      </div>
    )
  }
}

ProductDetailTableWrapper.propTypes = {
  doors: PropTypes.array,
  product: PropTypes.shape({
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    wholesaleRange: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    }).isRequired,
    retailRange: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string,
    }).isRequired,
    deliveryWindow: PropTypes.shape({
      start: PropTypes.string,
      complete: PropTypes.string,
    }),
    variants: PropTypes.arrayOf(PropTypes.object).isRequired,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
    traitValues: PropTypes.array.isRequired,
    description: PropTypes.string,
    skus: PropTypes.object.isRequired,
    casepacks: PropTypes.object.isRequired,
    casepackIds: PropTypes.array.isRequired,
  }).isRequired,
  discounts: PropTypes.array,
  discountFactor: PropTypes.number,
  currencyCode: PropTypes.string.isRequired,
  retailCurrency: PropTypes.string.isRequired,
  className: PropTypes.string,
  isProductReady: PropTypes.bool,
  priceTypeId: PropTypes.string.isRequired,
  isProRetailer: PropTypes.bool,
  initializeTable: PropTypes.func,
  initializeTableTotalRow: PropTypes.func,
  resetTableToDefaultState: PropTypes.func,
  variantRowCode: PropTypes.string,
  variantColumnCode: PropTypes.string,
  availabilityGroupsItems: PropTypes.object,
  initTableRow: PropTypes.func,
  setSelectedTabId: PropTypes.func,
  areDiscountsReady: PropTypes.bool,
  flags: PropTypes.shape({
    proRetailProductDetail: PropTypes.bool,
  }),
  trackBulk: PropTypes.func,
  trackSized: PropTypes.func,
  trackCheckboxClick: PropTypes.func,
  skuDeliveries: PropTypes.object,
}

ProductDetailTableWrapper.defaultProps = {
  doors: [],
  discounts: [],
  discountFactor: 1,
  className: '',
  isProRetailer: false,
  isProductReady: true,
  initializeTable: () => {},
  resetTableToDefaultState: () => {},
  initTableRow: () => {},
  initializeTableTotalRow: () => {},
  variantRowCode: PRODUCT_COLOR_CODE,
  variantColumnCode: PRODUCT_SIZE_CODE,
  availabilityGroupsItems: new Map(),
  setSelectedTabId: () => {},
  areDiscountsReady: false,
  flags: {
    proRetailProductDetail: false,
  },
  trackBulk: () => {},
  trackSized: () => {},
  trackCheckboxClick: () => {},
  skuDeliveries: {},
}

export default ProductDetailTableWrapper
