import { FC, useRef, useState } from 'react'

import { Dropdown, Icon, TextField } from '@joor/design-system'

import { SettingFormPropsType, TextFieldProps } from './SettingForm.types'
import {
  IconWrapper,
  SectionDropdown,
  SectionSettingForm,
  SectionTextField,
} from './SettingForm.ui'

interface ITextFieldProps {
  textField: TextFieldProps
  // @ts-expect-error
  onChangeValue: ({ newValue: string, key: string, withError: boolean }) => void
  /**
   * Clears the input on focus only if its current value is still
   * the initial value.
   */
  clearOnFocus?: boolean
}
const SettingTextField: FC<ITextFieldProps> = ({
  textField,
  onChangeValue,
  clearOnFocus,
}) => {
  const {
    key,
    testId,
    label,
    placeholder,
    defaultValue,
    errorMessage,
    caption,
    regexPattern,
  } = textField
  const initialValueRef = useRef(defaultValue)
  const [value, setValue] = useState(defaultValue)
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({})

  return (
    <SectionTextField>
      <TextField
        data-testid={testId}
        key={defaultValue}
        label={label}
        placeholder={placeholder}
        value={value}
        onFocus={() => {
          // Will set the input value to empty string if we still have the
          // first value that loaded the input at the moment we click on it.
          if (defaultValue === initialValueRef.current && clearOnFocus) {
            setValue('')
          }
        }}
        onChange={({ target: { value: newValue } }): void => {
          setValue(newValue)
        }}
        onBlur={({ target: { value: newValue } }): void => {
          const withError = !newValue.match(regexPattern)
          setErrors((prevState) => ({ ...prevState, [key]: withError }))
          if (newValue === '') {
            setValue(defaultValue)
          } else {
            setValue(newValue)
            onChangeValue({ newValue, key, withError })
          }
        }}
        errorMessage={errors[key] ? errorMessage : ''}
        caption={errors[key] ? caption : ''}
      />
    </SectionTextField>
  )
}

const SettingForm = (props: SettingFormPropsType): JSX.Element => {
  const {
    id,
    dropdownSection,
    textFields,
    onChangeValue,
    icons,
    clearOnFocus,
  } = props

  return (
    <SectionSettingForm data-testid={`SettingForm-${id}`}>
      <SectionDropdown>
        <Dropdown
          testId={dropdownSection.testId}
          label={dropdownSection.label}
          options={dropdownSection.options}
          value={dropdownSection.value}
          onSelect={({ selected }: any): void =>
            onChangeValue(id, selected, dropdownSection.key)
          }
        />
      </SectionDropdown>
      {textFields?.map((textField) => {
        return (
          <SettingTextField
            textField={textField}
            onChangeValue={({ newValue, key, withError }) =>
              onChangeValue(id, newValue, key, withError)
            }
            clearOnFocus={clearOnFocus}
          />
        )
      })}
      {icons &&
        icons.map((icon) => {
          const { testId, iconName, onClick, iconSize, disabled } = icon
          return (
            <IconWrapper $disabled={disabled}>
              <Icon
                data-testid={testId}
                iconName={iconName}
                iconSize={iconSize}
                onClick={(): void => onClick(id)}
              />
            </IconWrapper>
          )
        })}
    </SectionSettingForm>
  )
}

export default SettingForm
