import { createAction } from 'redux-actions'

import {
  CHANGE_COLLECTION_SEARCH_TEXT,
  CHANGE_RETAILER_SEARCH_TEXT,
  SET_RETAILER_ID,
} from './constants'

export const changeRetailerSearchText = createAction(
  CHANGE_RETAILER_SEARCH_TEXT,
)
export const setRetailerId = createAction(SET_RETAILER_ID)
export const changeCollectionSearchText = createAction(
  CHANGE_COLLECTION_SEARCH_TEXT,
)
