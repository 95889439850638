import { handleActions } from 'redux-actions'

export const defaultState = {}

const reducer = handleActions(
  {
    'ITEM/TOGGLE_FLIP_ITEM_COLOR': (
      state,
      { payload: { pageKey, itemColorId } },
    ) => {
      const flippedItemColors = state[pageKey] ? [...state[pageKey]] : []
      const itemColorIsFlipped = flippedItemColors.includes(itemColorId)

      if (itemColorIsFlipped) {
        return {
          ...state,
          [pageKey]: flippedItemColors.filter((id) => id !== itemColorId),
        }
      }

      return {
        ...state,
        [pageKey]: [...flippedItemColors, itemColorId],
      }
    },
    'ITEM/TOGGLE_FLIP_ALL_ITEM_COLORS': (
      state,
      { payload: { pageKey, itemColorIds } },
    ) => {
      let flippedItemColors = [...itemColorIds]
      if (state[pageKey]) {
        flippedItemColors =
          itemColorIds.length === state[pageKey].length ? [] : [...itemColorIds]
      }

      return { ...state, [pageKey]: flippedItemColors }
    },
  },
  {},
)

export default reducer
