import { useLazyQuery, useQuery } from '@apollo/client'
import { sortBy } from 'lodash'

import {
  AllBrandsAccountsDocument,
  AllBrandsAccountsQuery,
  Brand,
  BrandStatus,
  BrandSubType,
  Geography,
  LocationType,
} from '__generated__/atlas-types'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

export const WHOLESALE_MIN_OPTIONS = [
  { label: '$10', value: '10' },
  { label: '$50', value: '50' },
  { label: '$100', value: '100' },
  { label: '$250', value: '250' },
  { label: '$500', value: '500' },
]

const getWholesaleMaxDisabledValue = (
  optionValue: number,
  wholesaleMinValue?: string | null,
) => parseInt(wholesaleMinValue || '0', 10) >= optionValue

export const getWholesaleMaxOptions = (wholesaleMinValue?: string | null) => [
  {
    label: '$150',
    value: '150',
    isDisabled: getWholesaleMaxDisabledValue(150, wholesaleMinValue),
  },
  {
    label: '$250',
    value: '250',
    isDisabled: getWholesaleMaxDisabledValue(250, wholesaleMinValue),
  },
  {
    label: '$500',
    value: '500',
    isDisabled: getWholesaleMaxDisabledValue(500, wholesaleMinValue),
  },
  {
    label: '$750',
    value: '750',
    isDisabled: getWholesaleMaxDisabledValue(750, wholesaleMinValue),
  },
  {
    label: '>$1000',
    value: '1000',
    isDisabled: getWholesaleMaxDisabledValue(1000, wholesaleMinValue),
  },
]
export const CLIENTELE_AGE = [
  { label: '16-22', value: '16-22 (Teen/College)' },
  { label: '22-25', value: '22-25 (Young Professional)' },
  {
    label: '25-40',
    value: '25-40 (Professional/Cool Mom)',
  },
  { label: '40-65', value: '40-65 (Boomer)' },
  { label: 'All Ages', value: '16-60 (All Ages)' },
]
export const TYPE_OPTIONS = [
  { label: 'Store', value: LocationType.STORE },
  { label: 'Office', value: LocationType.OFFICE },
]

const COUNTRIES = atlas`#graphql
  query RetailerLocationCountries {
    geographies(types: "COUNTRY") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const useCountries = () => {
  const { data, loading } = useQuery(COUNTRIES, {
    client: atlasClient,
  })
  const formattedCountries = relayConnectionToArray(
    data?.geographies,
  )?.map((country: Geography) => ({ label: country.name, value: country.id }))

  return {
    countries: formattedCountries,
    loading,
  }
}

const STATES = atlas`#graphql
  query RetailerProfileStates($countryId: ID!) {
    states: geographies(
      types: ["STATE"]
      filter: { geographyParentId: $countryId }
    ) {
      edges {
        node {
          name
          id
          abbreviation
        }
      }
    }
  }
`

export const useStates = () => {
  const [fetchCountryStates, { loading, data }] = useLazyQuery(STATES, {
    client: atlasClient,
  })

  const fetchStates = (countryId: string) =>
    fetchCountryStates({ variables: { countryId } })
  const states = relayConnectionToArray(data?.states)
  const formattedStates = states?.map((state: Geography) => ({
    label: state.name,
    value: state.id,
  }))

  return {
    stateOptions: formattedStates,
    states,
    loading,
    fetchStates,
  }
}

const CLIENTELE_DESCRIPTIONS = atlas`#graphql
query ClienteleDescriptions {
  listClienteleDescriptions {
    edges {
      node {
        id
        name
      }
    }
  }
}
`

export const useClienteleDescriptions = () => {
  const { data, loading } = useQuery(CLIENTELE_DESCRIPTIONS, {
    client: atlasClient,
  })
  return {
    loading,
    clienteleDescriptions: relayConnectionToArray(
      data?.listClienteleDescriptions,
    ),
  }
}

const CLIENTELE_INSTERESTS = atlas`#graphql
  query ClienteleInterests {
  listClienteleInterests {
    edges {
      node {
        id
        name
      }
    }
  }
}
`

export const useClienteleInterests = () => {
  const { data, loading } = useQuery(CLIENTELE_INSTERESTS, {
    client: atlasClient,
  })
  return {
    loading,
    clienteleInterests: relayConnectionToArray(data?.listClienteleInterests),
  }
}

const PRODUCT_LINES = atlas`#graphql
 query ProductLines {
  brandProductLines {
    id
    name
  }
}
 `

export const useProductLines = () => {
  const { data, loading } = useQuery(PRODUCT_LINES, { client: atlasClient })
  const productLines = sortBy(data?.brandProductLines || [], 'name')
  return {
    loading,
    productLines,
  }
}

atlas`#graphql
  query AllBrandsAccounts ($filter: BrandsFilter, $offset: Int, $limit: Int) {
    brands (filter: $filter, offset: $offset, limit: $limit) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const useLazyBrands = (searchText: string) => {
  const [getBrands, { data, loading }] = useLazyQuery<AllBrandsAccountsQuery>(
    AllBrandsAccountsDocument,
    {
      client: atlasClient,
      variables: {
        filter: {
          searchName: searchText,
          status: [BrandStatus.PRE_ACTIVE, BrandStatus.ACTIVE],
          subtype: BrandSubType.ALL,
          allowUnconnectedBrands: true,
        },
        offset: 0,
        limit: 100,
      },
    },
  )
  const brands = relayConnectionToArray(data?.brands)
  const brandOptions = brands?.map((brand: Brand) => ({
    label: brand?.name,
    value: brand?.id,
  }))
  return {
    loading,
    getBrands,
    brands: brandOptions,
  }
}
