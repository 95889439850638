import { Component, cloneElement } from 'react'

import classNames from 'classnames/bind'
import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'
import { intlShape } from 'react-intl'
import { Modal as BaseModal } from 'semantic-ui-react'

import Button from 'components/Core/Button/Button'
import Header from 'components/Core/Header/Header'

import Loader from '../Loader/Loader'
import styles from './Modal.component.less'
import messages from './Modal.messages'

const DEFAULT_PRIMARY_ACTION_LABEL = 'Save'
const DEFAULT_SECONDARY_ACTION_LABEL = 'Cancel'

const PRIMARY = 'primary'
const SECONDARY = 'secondary'

class Modal extends Component {
  state = {
    modalIsOpen: false,
  }

  handleOpen = () => {
    if (this.props.onOpen) this.props.onOpen()
    this.setState({ modalIsOpen: true })
  }

  handleClose = () => {
    if (this.props.onClose) this.props.onClose()
    this.setState({ modalIsOpen: false })
  }

  handleButtonClick = (buttonActionOnClick, buttonHandleClose) => {
    if (buttonActionOnClick) buttonActionOnClick()
    if (buttonHandleClose) this.handleClose()
  }

  renderPrimaryActionLabel = () => {
    const { intl, primaryActionLabel } = this.props
    if (primaryActionLabel === DEFAULT_SECONDARY_ACTION_LABEL) {
      return intl.formatMessage(messages.cancel)
    }
    return primaryActionLabel
  }

  renderSecondaryActionLabel = () => {
    const { intl, secondaryActionLabel } = this.props
    if (secondaryActionLabel === DEFAULT_SECONDARY_ACTION_LABEL) {
      return intl.formatMessage(messages.cancel)
    }
    return secondaryActionLabel
  }

  renderActionButtons = () =>
    this.props.actionButtons.map((actionButton) => {
      const buttonProps = {
        className: styles.actionButton,
        disabled: actionButton.disabled,
        key: actionButton.key,
        onClick: () =>
          this.handleButtonClick(
            actionButton.onClick,
            actionButton.closeOnClick,
          ),
        primary: actionButton.type === PRIMARY,
        secondary: actionButton.type === SECONDARY,
        ...(actionButton.testId && { testId: actionButton.testId }),
      }
      return <Button {...buttonProps}>{actionButton.label}</Button>
    })

  renderButton = (buttonType) => {
    const { primaryDisabled, loading } = this.props
    const buttonProps = {
      id: this.props[`${buttonType}ActionId`],
      disabled: buttonType === PRIMARY && (primaryDisabled || loading),
      key: buttonType,
      [buttonType]: true,
    }

    return (
      <Button
        {...buttonProps}
        onClick={() => {
          this.handleButtonClick(
            this.props[`${buttonType}ActionOnClick`],
            this.props[`${buttonType}ActionClose`],
          )
        }}
      >
        {this[`render${capitalize(buttonType)}ActionLabel`]()}
      </Button>
    )
  }

  renderPrimaryAndSecondaryButtons = () => {
    const { primaryActionHidden, secondaryActionHidden } = this.props
    return [
      ...(!secondaryActionHidden ? [this.renderButton(SECONDARY)] : []),
      ...(!primaryActionHidden ? [this.renderButton(PRIMARY)] : []),
    ]
  }

  render() {
    const {
      idCloseButton,
      actionButtons,
      large,
      className,
      loading,
      primaryActionHidden,
      closeActionOnClick,
      trigger,
      secondaryActionHidden,
      errorHeader,
      hideCloseButton,
    } = this.props

    const modalTrigger = trigger
      ? cloneElement(trigger, { onClick: this.handleOpen })
      : null
    return (
      <BaseModal
        data-testid="modal"
        trigger={modalTrigger}
        onClose={this.handleClose}
        open={this.state.modalIsOpen || this.props.open}
        closeOnDimmerClick={false}
        className={classNames(className, { [styles.large]: large })}
      >
        <BaseModal.Header>
          <Header
            id={this.props.id}
            className={styles.wideHeader}
            error={errorHeader}
          >
            {this.props.title}
          </Header>
          {!hideCloseButton && (
            <Button
              id={idCloseButton}
              className={styles.closeButton}
              onClick={() => {
                closeActionOnClick()
                this.handleClose()
              }}
              close
            />
          )}
        </BaseModal.Header>
        <BaseModal.Content>
          {loading && <Loader active>{this.props.children}</Loader>}
          {!loading && this.props.children}
        </BaseModal.Content>
        {!(primaryActionHidden && secondaryActionHidden) && (
          <BaseModal.Actions className={styles.container}>
            {actionButtons.length
              ? this.renderActionButtons()
              : this.renderPrimaryAndSecondaryButtons()}
          </BaseModal.Actions>
        )}
      </BaseModal>
    )
  }
}

Modal.propTypes = {
  id: PropTypes.string,
  actionButtons: PropTypes.array,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node, // intl messages
  ]),
  className: PropTypes.string,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  large: PropTypes.bool,
  trigger: PropTypes.node,
  children: PropTypes.node,
  primaryActionLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node, // intl messages
  ]),
  primaryActionOnClick: PropTypes.func,
  primaryActionClose: PropTypes.bool,
  primaryDisabled: PropTypes.bool,
  primaryActionHidden: PropTypes.bool,
  primaryActionId: PropTypes.string,
  secondaryActionLabel: PropTypes.string,
  secondaryActionOnClick: PropTypes.func,
  secondaryActionClose: PropTypes.bool,
  secondaryActionHidden: PropTypes.bool,
  closeActionOnClick: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  intl: intlShape.isRequired,
  errorHeader: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
}

Modal.defaultProps = {
  id: '',
  actionButtons: [],
  title: '',
  className: '',
  loading: false,
  open: false,
  large: false,
  trigger: null,
  children: null,
  errorHeader: false,
  primaryActionLabel: DEFAULT_PRIMARY_ACTION_LABEL,
  primaryActionOnClick: null,
  primaryActionClose: true,
  primaryDisabled: false,
  primaryActionHidden: false,
  primaryActionId: '',
  secondaryActionLabel: DEFAULT_SECONDARY_ACTION_LABEL,
  secondaryActionOnClick: null,
  secondaryActionClose: true,
  secondaryActionHidden: false,
  secondaryActionId: '',
  closeActionOnClick: () => {},
  onClose: () => {},
  onOpen: () => {},
  hideCloseButton: false,
}

export default Modal
