import { handleActions } from 'redux-actions'

import {
  CHANGE_COLLECTION_SEARCH_TEXT,
  CHANGE_RETAILER_SEARCH_TEXT,
  SET_RETAILER_ID,
} from './constants'

export const defaultState = {
  retailerSearchText: '',
  retailerId: '',
  collectionSearchText: '',
}

const reducer = handleActions(
  {
    [CHANGE_RETAILER_SEARCH_TEXT]: (state, { payload }) => ({
      ...state,
      retailerSearchText: payload,
    }),
    [SET_RETAILER_ID]: (state, { payload }) => ({
      ...state,
      retailerId: payload,
    }),
    [CHANGE_COLLECTION_SEARCH_TEXT]: (state, { payload }) => ({
      ...state,
      collectionSearchText: payload,
    }),
  },
  defaultState,
)

export default reducer
