import get from 'lodash/get'
import min from 'lodash/min'
import random from 'lodash/random'

import * as constants from './constants'
import {
  PRODUCT,
  TEXT,
} from 'containers/Showroom/GridLayout/GridElements/GridElements.constants'
import { CATEGORY } from 'containers/Showroom/Styleboard/GroupSelection/GroupSelection.component'

const POLL_INTERVAL = 3000 // 3 seconds
const MAX_BACKOFF = 300000 // 300 seconds

const initialState = {
  commentsMode: false,
  grouping: {
    criteria: CATEGORY,
    enabled: false,
  },
  isSaving: false,
  loading: true,
  pollInterval: POLL_INTERVAL,
  pollRetries: 0,
  showProductInfo: true,
  styleboardHasChanges: false,
  styleboard: {
    brand: null,
    id: null,
    name: 'UNTITLED STYLEBOARD',
    lastModified: null,
    layout: [],
    logo: '',
    items: [],
    status: 'ACTIVE',
    textContents: [],
  },
  syncedFromRemote: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case constants.ADD_ELEMENT_TO_STYLEBOARD: {
      const { element, elementType } = action
      const elementId = `${elementType}-${Date.now()}`
      return {
        ...state,
        styleboard: {
          ...state.styleboard,
          layout: [
            ...get(state, 'styleboard.layout', []),
            {
              i: element.id || elementId,
              x: 0,
              y: 2,
              ...action.layout,
            },
          ],
          items: [
            ...get(state, 'styleboard.items', []),
            ...(elementType === PRODUCT ? [element] : []),
          ],
          textContents: [
            ...get(state, 'styleboard.textContents', []),
            ...(elementType === TEXT
              ? [{ ...element, layoutKey: elementId }]
              : []),
          ],
        },
      }
    }
    case constants.DELETE_ELEMENT_FROM_STYLEBOARD: {
      return {
        ...state,
        styleboard: {
          ...state.styleboard,
          items: [
            ...get(state, 'styleboard.items', []).filter(
              ({ collectionProduct }) =>
                collectionProduct.product.id !== action.id,
            ),
          ],
          textContents: [
            ...get(state, 'styleboard.textContents', []).filter(
              (textContent) => textContent.layoutKey !== action.id,
            ),
          ],
        },
      }
    }
    case constants.INCREASE_POLL_INTERVAL: {
      const pollRetries = state.pollRetries + 1
      const delay =
        1000 * (state.pollInterval / 1000 + Math.pow(2, pollRetries)) +
        random(0, 1000)

      return {
        ...state,
        pollInterval: min([delay, MAX_BACKOFF]),
        pollRetries,
      }
    }
    case constants.RESET_POLL_INTERVAL: {
      return {
        ...state,
        pollInterval: POLL_INTERVAL,
        pollRetries: 0,
      }
    }
    case constants.STOP_POLLING: {
      return {
        ...state,
        pollInterval: 0,
      }
    }
    case constants.SET_GROUPING_CRITERIA: {
      return {
        ...state,
        grouping: {
          ...state.grouping,
          criteria: action.criteria,
        },
      }
    }
    case constants.SET_NEW_STYLEBOARD: {
      return {
        ...initialState,
        styleboard: {
          ...initialState.styleboard,
          ...action.styleboard,
        },
        loading: action.loading,
      }
    }
    case constants.SET_IS_SAVING: {
      return {
        ...state,
        isSaving: action.isSaving,
      }
    }
    case constants.SET_STATIC_ELEMENT_IN_STYLEBOARD: {
      const elementLayout = get(state, 'styleboard.layout', []).find(
        ({ i }) => i === action.id,
      )
      return {
        ...state,
        styleboard: {
          ...state.styleboard,
          layout: [
            ...get(state, 'styleboard.layout', []).filter(
              ({ i }) => i !== action.id,
            ),
            { ...elementLayout, static: action.static },
          ],
        },
      }
    }
    case constants.SET_STYLEBOARD: {
      return {
        ...state,
        styleboardHasChanges: false,
        styleboard: {
          ...state.styleboard,
          ...action.styleboard,
        },
        syncedFromRemote: false,
        loading: action.loading,
      }
    }
    case constants.SET_STYLEBOARD_LAYOUT: {
      if (state.grouping.enabled) {
        return state
      }

      return {
        ...state,
        styleboard: {
          ...state.styleboard,
          layout: action.layout,
        },
      }
    }
    case constants.SET_STYLEBOARD_NAME: {
      return {
        ...state,
        styleboard: {
          ...state.styleboard,
          name: action.name,
        },
      }
    }
    case constants.SET_SYNCED_FROM_REMOTE: {
      return {
        ...state,
        syncedFromRemote: action.value,
      }
    }
    case constants.SET_TEXT_IN_STYLEBOARD: {
      const textContent = get(state, 'styleboard.textContents', []).find(
        ({ layoutKey }) => layoutKey === action.layoutKey,
      )
      const newTextContent = {
        ...textContent,
        content: action.content,
      }
      return {
        ...state,
        styleboardHasChanges: true,
        styleboard: {
          ...state.styleboard,
          textContents: get(state, 'styleboard.textContents', [])
            .filter(({ layoutKey }) => layoutKey !== action.layoutKey)
            .concat(newTextContent),
        },
      }
    }
    case constants.TOGGLE_COMMENTS_MODE: {
      return {
        ...state,
        commentsMode: !state.commentsMode,
      }
    }
    case constants.TOGGLE_GROUP_MODE: {
      return {
        ...state,
        grouping: {
          ...state.grouping,
          enabled: !state.grouping.enabled,
        },
      }
    }
    case constants.TOGGLE_SHOW_PRODUCT_INFO: {
      return {
        ...state,
        showProductInfo: !state.showProductInfo,
      }
    }
    default:
      return state
  }
}
