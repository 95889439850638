import { handleActions } from 'redux-actions'

export const defaultState = {
  pageLoaderIsActive: false,
}

const reducer = handleActions(
  {
    'PAGE_LOADER/SET_ACTIVE': (state, action) => ({
      ...state,
      pageLoaderIsActive: action.payload,
    }),
  },
  defaultState,
)

export default reducer
