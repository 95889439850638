const BORDER_WIDTH = 1
const FLYOUT_WIDTH = 200

export class FlyoutContainerStyle {
  constructor(node, innerWidth) {
    this.node = node
    this.innerWidth = innerWidth
  }

  _numLevelsUntilEdge() {
    const roomToGrowX =
      this.innerWidth - this.node.getBoundingClientRect().right
    return Math.ceil(roomToGrowX / FLYOUT_WIDTH)
  }

  _isAtRightEdge(level) {
    return level === this._numLevelsUntilEdge()
  }

  _isGoingLeft(level) {
    return level > this._numLevelsUntilEdge()
  }

  _getDefaultStyle(previousTierElement) {
    const style = {}
    style.top =
      previousTierElement.getBoundingClientRect().top -
      this.node.getBoundingClientRect().top -
      BORDER_WIDTH * 2
    style.left =
      previousTierElement.getBoundingClientRect().right -
      this.node.getBoundingClientRect().left -
      BORDER_WIDTH
    return style
  }

  _getRightEdgeStyle(previousTierContainer, previousTierElement) {
    const style = {}
    style.top =
      previousTierContainer.getBoundingClientRect().bottom -
      this.node.getBoundingClientRect().top -
      BORDER_WIDTH * 2

    const pixelsOfRoom =
      this.innerWidth - previousTierElement.getBoundingClientRect().right
    const newLeftUnadjusted =
      previousTierElement.getBoundingClientRect().left -
      this.node.getBoundingClientRect().left -
      BORDER_WIDTH * 3
    style.left = newLeftUnadjusted + pixelsOfRoom
    return style
  }

  _getLeftStyle(previousTierElement) {
    const style = {}
    style.top =
      previousTierElement.getBoundingClientRect().top -
      this.node.getBoundingClientRect().top -
      BORDER_WIDTH * 2
    style.left =
      previousTierElement.getBoundingClientRect().left -
      this.node.getBoundingClientRect().left -
      FLYOUT_WIDTH -
      BORDER_WIDTH * 3
    return style
  }

  getStyle(level, previousTierIndex) {
    if (level === 0) {
      return { left: -1 }
    }

    const previousTierContainer = this.node.querySelector(
      `.flyouts > div:nth-child(${level})`,
    )
    const previousTierElement = previousTierContainer.querySelector(
      `div:nth-child(${previousTierIndex + 1})`,
    )

    if (this._isAtRightEdge(level)) {
      return this._getRightEdgeStyle(previousTierContainer, previousTierElement)
    } else if (this._isGoingLeft(level)) {
      return this._getLeftStyle(previousTierElement)
    }

    return this._getDefaultStyle(previousTierElement)
  }
}
