import { PureComponent } from 'react'

import classNames from 'classnames'
import {
  find,
  get,
  intersection,
  isEmpty,
  isNumber,
  isUndefined,
  map,
  size,
} from 'lodash'
import PropTypes from 'prop-types'

import getDisplayName from 'utils/getDisplayName'
import { fromGlobalId } from 'utils/transformations/graphql'

import {
  PRICE_ROW_ID,
  QUANTITY_COLUMN_ID,
  SUGG_RETAILER_COLUMN_ID,
  UNITS_AVAILABLE_ROW_ID,
  WHOLESALE_COLUMN_ID,
} from 'shop/TablesConfiguration'
import { getFormattedPrice } from 'shop/formatters'
import { getSkusPriceRangeByTrade } from 'shop/products/getters'
import {
  EMPTY_VALUE,
  PRODUCT_SIZE_CODE,
  PRODUCT_WHOLESALE_ATTRIBUTE,
} from 'shop/products/traits'
import { getCasepackMultiplier, getNewPrice } from 'shop/products/utils'
import { getAttributeIdFromVariant } from 'shop/products/utils.js'

import { Table as CoreTable, Icon, Number, Tooltip } from 'components/Core'
import { ALIGN_RIGHT } from 'components/Shop/Table/Cell'

import styles from './TableFormatter.less'

export const getCopyButton = (callback) => (
  <Tooltip
    content="Copy quantities"
    trigger={
      <div onClick={callback}>
        <Icon name="copy" className={styles.copyPasteIcon} />
      </div>
    }
    position="top right"
  />
)

export const getPasteButton = (callback) => (
  <Tooltip
    content="Paste quantities"
    trigger={
      <div onClick={callback}>
        <Icon name="paste" className={styles.copyPasteIcon} />
      </div>
    }
    position="top right"
  />
)

export const withTableFormatter = (WrappedComponent) => {
  class Wrapper extends PureComponent {
    getVariantValueFromTable = (cellParams) =>
      get(
        this.props.variantInfoSection,
        `[${cellParams.rowId}][${cellParams.columnId}]`,
      )

    getNumberInput = ({
      cellParams,
      cellGeneralParams,
      disableInput = false,
      isValidQuantity = true,
    }) => {
      const inputValue = this.getVariantValueFromTable(cellParams)

      return (
        <Number
          defaultValue={inputValue || '0'}
          className={classNames(styles.numberInput, {
            [styles.invalid]: !isValidQuantity,
          })}
          disabled={disableInput}
          onChange={this.handleNumberInputChange(cellParams, cellGeneralParams)}
          onlyNaturalNumbersAllowed
          clearDefaultValueOnFocus
          allowEmptyValue={false}
        />
      )
    }

    getRowQuantity = (cellParams) =>
      this.getVariantValueFromTable({
        ...cellParams,
        columnId: QUANTITY_COLUMN_ID,
      }) || 0

    getSkuPrices = (cellParams, quantity) => {
      const { wholesalePrice, retailPrice } = this.getVariantValueFromTable({
        ...cellParams,
        rowId: PRICE_ROW_ID,
      })

      const {
        wholesalePrice: oldWholesalePrice,
        retailPrice: oldRetailPrice,
      } = this.getRowPrices(
        get(this.props.variantInfoSection, cellParams.rowId),
      )

      const newWholesalePrice = getNewPrice(wholesalePrice, quantity)
      const newRetailPrice = getNewPrice(retailPrice, quantity)

      return {
        wholesalePrice: {
          min: oldWholesalePrice.min + newWholesalePrice.min,
          max: oldWholesalePrice.max + newWholesalePrice.max,
        },
        retailPrice: {
          min: oldRetailPrice.min + newRetailPrice.min,
          max: oldRetailPrice.max + newRetailPrice.max,
        },
      }
    }

    setRowQuantityAndPrices = ({
      cellParams,
      newValue,
      quantity,
      cellGeneralParams,
    }) => {
      const {
        updateCellValue,
        product: { casepacks },
      } = this.props
      const casepackMultiplier = getCasepackMultiplier(
        cellParams.columnId,
        casepacks,
      )
      const newQuantityValue =
        this.getRowQuantity(cellGeneralParams || cellParams) +
        newValue * casepackMultiplier
      const { wholesalePrice, retailPrice } = this.getSkuPrices(
        cellGeneralParams || cellParams,
        newValue,
      )
      const newValuesConfig = [
        {
          value: quantity,
        },
        {
          ...(cellGeneralParams && cellGeneralParams),
          columnId: QUANTITY_COLUMN_ID,
          value: newQuantityValue,
        },
        {
          ...(cellGeneralParams && cellGeneralParams),
          columnId: WHOLESALE_COLUMN_ID,
          value: wholesalePrice,
        },
        {
          ...(cellGeneralParams && cellGeneralParams),
          columnId: SUGG_RETAILER_COLUMN_ID,
          value: retailPrice,
        },
      ]

      newValuesConfig.forEach((cellParamsConfig) => {
        updateCellValue({
          ...cellParams,
          ...cellParamsConfig,
        })
      })
    }

    getRowPrices = (row) => {
      const wholesalePrice = get(row, `${WHOLESALE_COLUMN_ID}`)
      const retailPrice = get(row, `${SUGG_RETAILER_COLUMN_ID}`)
      return { wholesalePrice, retailPrice }
    }

    setTotalRowValues = (cellParams, previousValue, newValue) => {
      const {
        product,
        setTotalValueFromColumn,
        setTotalValueOfQuantityColumn,
        setTotalValueOfWholesaleColumn,
        setTotalValueOfRetailColumn,
      } = this.props
      const quantity = newValue - (previousValue || 0)

      setTotalValueFromColumn({ cellParams, quantity })
      setTotalValueOfQuantityColumn({ cellParams, quantity, product })
      setTotalValueOfWholesaleColumn({ cellParams, quantity })
      setTotalValueOfRetailColumn({ cellParams, quantity })
    }

    getSimpleCell = (value, translate = true) => ({
      props: {
        className: classNames(styles.column, translate ? '' : 'notranslate'),
      },
      children: <div>{value}</div>,
    })

    getCellWithProps = (value, extraProps, translate = true) => ({
      props: {
        className: classNames(styles.column, translate ? '' : 'notranslate'),
        ...extraProps,
      },
      children: <div>{value}</div>,
    })

    // TODO(ch61297): Function used in ProductDetailSizedTable  and can be replaced by this one
    getCasepackHeaderColumns = (casepacksSkuDeliveries = []) => {
      const { casepacks, casepackIds } = this.props.product
      const lastCasepackIndex = size(casepacks) - 1
      return map(casepackIds, (casepackId, index) => {
        const colorCasepackDeliveryRange = casepacksSkuDeliveries.find(
          (casepack) =>
            fromGlobalId(fromGlobalId(casepack.id).id)?.type?.split('|')[2] ===
            fromGlobalId(casepackId).id,
        )?.deliveryRange
        return {
          props: {
            className: classNames(styles.column, {
              [styles.singleColumn]: casepacks.length === 1,
              [styles.multipleColumns]: casepacks.length > 1,
            }),
            align: ALIGN_RIGHT,
            withBorder: index === lastCasepackIndex,
          },
          children: (
            <Tooltip
              position="top right"
              hoverable
              trigger={
                <div
                  className={classNames(styles.casepacksHeader, 'notranslate')}
                >
                  {casepacks[casepackId].name}
                </div>
              }
              content={this.getCasepacksTooltipContent(
                casepacks[casepackId],
                colorCasepackDeliveryRange,
              )}
            />
          ),
        }
      })
    }

    // TODO(ch61297): Function used in ProductDetailSizedTable and can be replaced by this one
    getCasepacksTooltipContent = (
      casepack,
      colorCasepackDeliveryRange = '',
    ) => [
      <div
        key="title"
        className={classNames(styles.casepacksTooltipTitle, 'notranslate')}
      >
        {casepack.name}{' '}
        {colorCasepackDeliveryRange.length > 1 && (
          <span
            className={styles.deliveryDate}
          >{`Delivery: ${colorCasepackDeliveryRange}`}</span>
        )}
      </div>,
      <CoreTable
        key="table"
        borderless
        className={styles.casepacksTooltipTable}
      >
        <CoreTable.Header>
          <CoreTable.Row>
            {casepack.sizes.map(({ value }) => (
              <CoreTable.HeaderCell
                className={classNames(
                  styles.casepacksTooltipCell,
                  'notranslate',
                )}
                key={value}
              >
                {value}
              </CoreTable.HeaderCell>
            ))}
            <CoreTable.HeaderCell className={styles.casepacksTooltipCell}>
              Total
            </CoreTable.HeaderCell>
          </CoreTable.Row>
        </CoreTable.Header>
        <CoreTable.Body>
          <CoreTable.Row>
            {casepack.sizes.map(({ value, quantity }) => (
              <CoreTable.Cell
                className={classNames(
                  styles.casepacksTooltipCell,
                  'notranslate',
                )}
                key={value}
              >
                {quantity}
              </CoreTable.Cell>
            ))}
            <CoreTable.Cell
              className={classNames(styles.casepacksTooltipCell, 'notranslate')}
            >
              {casepack.total}
            </CoreTable.Cell>
          </CoreTable.Row>
        </CoreTable.Body>
      </CoreTable>,
    ]

    // TODO(ch61297): Function used in ProductDetailSizedTable and can be replaced by this one
    getSizeHeaderColumns = (productSizesDelivery = {}) => {
      const {
        product: { variants },
      } = this.props
      const sizeVariant = find(
        variants,
        (variant) => variant.code === PRODUCT_SIZE_CODE,
      )
      const sizes = get(sizeVariant, 'values')
      const lastSizeIndex = size(sizes) - 1

      return map(sizes, ({ value, id }, index) => {
        const sizeId = getAttributeIdFromVariant(id)
        const tooltipProps = {
          trigger: <span>{value}</span>,
          content: <span>Delivery: {productSizesDelivery[sizeId]}</span>,
        }
        const cellValue = productSizesDelivery[sizeId] ? (
          <Tooltip {...tooltipProps} />
        ) : (
          value
        )
        const simpleCellStructure = this.getSimpleCell(cellValue)
        return {
          ...simpleCellStructure,
          props: {
            ...simpleCellStructure.props,
            withBorder: lastSizeIndex === index,
            align: ALIGN_RIGHT,
          },
        }
      })
    }

    // TODO(ch61297): Function used in ProductDetailSizedTable and can be replaced by this one with small changes
    getFormattedVariantColumnInPriceRow = ({
      variantRowValue,
      variantColumnValue,
      extraColumnProps,
      shouldDisplayValue,
    }) => {
      const {
        product: { skus },
        priceTypeId,
      } = this.props
      const { skusIds } = variantRowValue
      const variantSkus = map(skusIds, (skuId) => skus.get(skuId))
      const {
        min: minWholesaleValue,
        max: maxWholesaleValue,
      } = getSkusPriceRangeByTrade(
        variantSkus,
        priceTypeId,
        PRODUCT_WHOLESALE_ATTRIBUTE,
      )
      const skuColumnPrice = this.getVariantValueFromTable({
        rowId: PRICE_ROW_ID,
        columnId: get(variantColumnValue, 'id'),
      })

      if (shouldDisplayValue) {
        const wholesalePrice =
          getFormattedPrice(get(skuColumnPrice, 'wholesalePrice')) ||
          EMPTY_VALUE
        return this.getCellWithProps(wholesalePrice, extraColumnProps)
      }

      const shouldDisplayEmptyCell =
        !skuColumnPrice || minWholesaleValue === maxWholesaleValue
      const wholesalePrice = shouldDisplayEmptyCell
        ? ''
        : getFormattedPrice(get(skuColumnPrice, 'wholesalePrice')) ||
          EMPTY_VALUE
      return this.getCellWithProps(wholesalePrice, extraColumnProps)
    }

    // TODO(ch61297): Function used in ProductDetailSizedTable and can be replaced by this one with small changes
    getInputCellsFromArray = (array, options = {}, objectById) => {
      const {
        cellParams,
        cellGeneralParams,
        variantId,
        variantRowValue: { skusIds },
        showNumberInput,
        disabled,
      } = options

      return map(array, (element, index) => {
        const extraColumnProps = {
          highlighted: true,
          withBorder: size(array) === index + 1,
          align: ALIGN_RIGHT,
        }
        const isCasepack = !isUndefined(objectById)
        let disableInput = false
        if (!isCasepack) {
          const skusInCommon = intersection(element.skusIds, skusIds)
          disableInput = isEmpty(skusInCommon)
        } else {
          disableInput = isUndefined(
            get(objectById, `[${element}]colors[${variantId}]`),
          )
        }
        const numberInput = this.getNumberInput({
          cellParams: {
            ...cellParams,
            columnId: element.id || element,
          },
          ...(cellGeneralParams && {
            cellGeneralParams: {
              ...cellGeneralParams,
              columnId: element.id || element,
            },
          }),
          disableInput: disabled || disableInput,
        })

        return showNumberInput
          ? this.getCellWithProps(numberInput, extraColumnProps)
          : this.getCellWithProps('', extraColumnProps)
      })
    }

    // TODO(ch61297): Function used in ProductDetailSizedTable and can be replaced by this one with small changes
    getUnitsColumnsFromArray = (cellParams, array) =>
      map(array, (element, index) => {
        const extraColumnProps = {
          highlighted: true,
          withBorder: size(array) === index + 1,
        }
        const unitsAvailable = this.getVariantValueFromTable({
          ...cellParams,
          rowId: UNITS_AVAILABLE_ROW_ID,
          columnId: get(element, 'id') || element,
        })
        return this.getCellWithProps(
          <span
            className={classNames(styles.unitsAvailable, {
              [styles.invalid]: unitsAvailable < 0,
            })}
          >
            {unitsAvailable}
          </span>,
          extraColumnProps,
        )
      })

    updateUnitsAvailable = (cellParams, value) => {
      const { updateCellValue } = this.props
      const unitsAvailable = this.getVariantValueFromTable({
        ...cellParams,
        rowId: UNITS_AVAILABLE_ROW_ID,
      })
      if (isNumber(unitsAvailable)) {
        updateCellValue({
          ...cellParams,
          rowId: UNITS_AVAILABLE_ROW_ID,
          value: unitsAvailable - value,
        })
      }
    }

    handleNumberInputChange = (cellParams, cellGeneralParams) => (
      _,
      { value, defaultValue },
    ) => {
      const quantity = parseInt(value || 0, 10)

      if (quantity !== defaultValue) {
        const previousValue = this.getVariantValueFromTable(cellParams)
        const newValue = quantity - (previousValue || 0)
        this.updateUnitsAvailable(cellParams, newValue)
        this.setRowQuantityAndPrices({
          cellParams,
          cellGeneralParams,
          newValue,
          quantity,
        })
        this.setTotalRowValues(cellParams, previousValue, quantity)
      }
    }

    render() {
      return (
        <WrappedComponent
          getNumberInput={this.getNumberInput}
          getSimpleCell={this.getSimpleCell}
          getCellWithProps={this.getCellWithProps}
          getCasepackHeaderColumns={this.getCasepackHeaderColumns}
          getSizeHeaderColumns={this.getSizeHeaderColumns}
          getFormattedVariantColumnInPriceRow={
            this.getFormattedVariantColumnInPriceRow
          }
          getInputCellsFromArray={this.getInputCellsFromArray}
          getUnitsColumnsFromArray={this.getUnitsColumnsFromArray}
          {...this.props}
        />
      )
    }
  }

  Wrapper.displayName = `withTableFormatter(${getDisplayName(
    WrappedComponent,
  )})`
  Wrapper.propTypes = {
    variantInfoSection: PropTypes.object,
    updateCellValue: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    priceTypeId: PropTypes.string,
    setTotalValueFromColumn: PropTypes.func.isRequired,
    setTotalValueOfWholesaleColumn: PropTypes.func.isRequired,
    setTotalValueOfRetailColumn: PropTypes.func.isRequired,
    setTotalValueOfQuantityColumn: PropTypes.func.isRequired,
  }
  Wrapper.defaultProps = {
    priceTypeId: '',
    variantInfoSection: {},
  }

  return Wrapper
}

export default withTableFormatter
