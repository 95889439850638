import { atlas, graphene } from 'graphql/tags'

export const COLLECTION_PRODUCT_COLORS = atlas`#graphql
  query collectionProductsQueryDupe2(
      $filter: ProductsCollectionFilter
      $externalMediaEnabled: Boolean!
      $brandIds: [ID!]) {
    collectionProducts(
      filter: $filter
      brandIds: $brandIds
      ) {
      edges {
        node {
          id
          priceTypes {
            edges {
              node {
                id
                name
                currency {
                  id
                  code
                }
                retailCurrency {
                  id
                  code
                }
              }
            }
          }
          product {
            id
            name
            images {
              url
            }
            externalMedia @include(if: $externalMediaEnabled){
              id
              thumbnailUrl
              type
            }
          }
          collection {
            id
            name
          }
          variants {
            edges {
              node {
                id
                code
              }
              values {
                edges {
                  node {
                    id
                    trait {
                      code
                    }
                    value
                    displayDetails {
                      hexColor
                      swatchImage {
                        url
                      }
                      merchandiseImages {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export const COLLECTION_PRODUCT_VISIBILITY = atlas`#graphql
  query collectionProductsQueryDupe3(
    $filter: ProductsCollectionFilter
    $brandIds: [ID!]) {
    collectionProducts(
      filter: $filter
      brandIds: $brandIds
      ) {
      edges {
        node {
          id
          collection {
            id
            visibility
          }
        }
      }
    }
  }
`

export const PUBLIC_COLLECTIONS = atlas`#graphql
  query PublicCollectionsQueryDupe1($brandId: ID!, $filters: PublicCollectionsFilter ) {
    public {
      collections(
        brandId: $brandId
        filters: $filters
      ) {
        id
        name
        products {
          id
          name
          images {
            id
            url
          }
          externalMedia {
            id
            thumbnailUrl
            type
          }
          variants {
            colors {
              id
              name
              image {
                id
                url
              }
              swatch {
                hexColor
                image {
                  id
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

export const COLLECTIONS = atlas`#graphql
  query CollectionsQueryDupe4($brandId: ID!) {
    collections(
      brandId: $brandId
      filters: { limitByProfileSettingsMax: false, archived: false }
      orderBy: { field: POSITION }
    ) {
      edges {
        node {
          id
          name
          visibility
        }
      }
    }
  }
`

export const ASSOCIATED_BRANDS = graphene`#graphql
  query connectedBrands {
    currentUser {
      brandAccounts {
        edges {
          node {
            id
            name
            displayName
          }
        }
      }
    }
  }
`

export const BRAND_PROFILE = atlas`#graphql
  query brandProfiles($brandIds: [ID!]!) {
    brandProfiles(brandIds: $brandIds) {
      edges {
        node {
          id
          name
          description
          yearEstablished
          productLines
          wholesaleMin
          wholesaleMax
          retailMin
          retailMax
          websiteUrl
          customUrlHandle
          socialMediaAccounts {
            url
            socialMediaType
          }
          accountMedia {
            id
            url
            mediaType
          }
          logoId
          logoUrl
        }
      }
    }
  }
`

export const PUBLIC_BRAND_PROFILE = atlas`#graphql
  query PublicBrandProfiles($brandIds: [ID!]!) {
    public {
      brandProfiles(brandIds: $brandIds) {
        edges {
          node {
            id
            name
            description
            yearEstablished
            productLines
            wholesaleMin
            wholesaleMax
            retailMin
            retailMax
            websiteUrl
            customUrlHandle
            socialMediaAccounts {
              url
              socialMediaType
            }
            accountMedia {
              id
              url
              mediaType
            }
            logoId
            logoUrl
          }
        }
      }
    }
  }
`

export const SHOWROOM_COLLECTIONS = atlas`#graphql
  query ShowroomCollections {
    showroomCollections(filter: { status: ACTIVE }) {
      edges {
        node {
          id
          name
          visibleToAllConnected
        }
      }
    }
  }
`

export const PRICETYPES_QUERY = atlas`#graphql
  query PriceTypesQueryDupe2($brandIds: [ID!]!) {
    priceTypes(brandIds: $brandIds) {
      edges {
        node {
          id
          name
          currency {
            id
            code
          }
          retailCurrency {
            id
            code
          }
        }
      }
    }
  }
`
