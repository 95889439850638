import { useEffect } from 'react'

import { useRenderToasters } from 'hooks/useRenderToasters'

export const PHP_ORDER_MODIFY_SUCCESS_TOAST_EVENT_NAME =
  'order-review-2023_toast_success'
export const PHP_ORDER_MODIFY_ERROR_TOAST_EVENT_NAME =
  'order-review-2023_toast_error'

const useInitializePHPOrderModifyToasts = () => {
  const { renderSuccessToast, renderErrorToast } = useRenderToasters()

  useEffect(() => {
    const showSuccessToast = () =>
      renderSuccessToast({ description: 'Products have been updated.' })
    const showErrorToast = () =>
      renderErrorToast({
        description: 'There was an error while trying to update the products.',
      })

    window.addEventListener(
      PHP_ORDER_MODIFY_SUCCESS_TOAST_EVENT_NAME,
      showSuccessToast,
    )
    window.addEventListener(
      PHP_ORDER_MODIFY_ERROR_TOAST_EVENT_NAME,
      showErrorToast,
    )

    return () => {
      window.removeEventListener(
        PHP_ORDER_MODIFY_SUCCESS_TOAST_EVENT_NAME,
        showSuccessToast,
      )
      window.removeEventListener(
        PHP_ORDER_MODIFY_ERROR_TOAST_EVENT_NAME,
        showErrorToast,
      )
    }
  }, [])
}

export default useInitializePHPOrderModifyToasts
