import { ToastType, useNotifications } from '@joor/design-system'

import { MediaType } from '__generated__/atlas-types'

import { makeRequest } from 'utils/requests'

import { toGlobalId } from './transformations/graphql'

export const uploadMediaFile = (
  file: File,
  targetMediaType: MediaType.IMAGE | MediaType.VIDEO,
  onError: () => void,
) => {
  if (!file) {
    return Promise.reject('media file was not provided')
  }
  const form = new FormData()
  form.append('files[]', file)

  const MEDIA_TYPE = targetMediaType === MediaType.IMAGE ? 1 : 3
  const UPLOAD_URL = `/Uploads/new_upload/1/${MEDIA_TYPE}`

  return makeRequest({
    url: UPLOAD_URL,
    data: form,
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  })
    .then((response) => {
      const { data } = response
      if (!data) {
        onError()
        return {}
      } else {
        return { id: data[0].joor_id, url: data[0].url, fileName: data[0].name }
      }
    })
    .catch(onError)
}

export const ERROR_TOAST = {
  imageSizeError: {
    title: 'ERROR',
    description:
      'The image you are uploading is too large. Please, choose a smaller image',
    type: 'error' as ToastType,
  },
  fileSizeError: {
    title: 'ERROR',
    description:
      'The file you are uploading is too large. Please, choose a smaller file',
    type: 'error' as ToastType,
  },
  fileTypeError: {
    title: 'ERROR',
    description: 'The file type you are uploading is not supported.',
    type: 'error' as ToastType,
  },
  imageTypeError: {
    title: 'ERROR',
    description:
      'The image type you are uploading is not supported. Please, try with .jpg or .png',
    type: 'error' as ToastType,
  },
  uploadError: {
    title: 'ERROR',
    description: 'There was a problem uploading your file.',
    type: 'error' as ToastType,
  },
}

export const useUploadMedia = () => {
  const { addToasts } = useNotifications()

  const handleUploadImage = async ({
    img,
    imageSizeLimit = 4000000,
    validImageTypes = ['image/png', 'image/jpeg', 'image/gif'],
  }: {
    img?: File
    imageSizeLimit?: number
    validImageTypes?: string[]
  }) => {
    if (!img) return

    if (img.size >= imageSizeLimit) {
      addToasts([ERROR_TOAST.imageSizeError])
      return null
    }

    if (!validImageTypes.includes(img.type)) {
      addToasts([ERROR_TOAST.imageTypeError])
      return null
    }

    const newImage = await uploadMediaFile(img, MediaType.IMAGE, () => {
      addToasts([ERROR_TOAST.uploadError])
    })

    if (newImage?.id) {
      return {
        ...newImage,
        id: toGlobalId('Upload', newImage.id),
      }
    } else {
      return null
    }
  }
  return { handleUploadImage }
}
