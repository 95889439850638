import naturalSort from 'natural-sort'

import { language } from 'localization/setup'

const collator = new Intl.Collator(language, {
  numeric: true,
  sensitivity: 'base',
})

const intlCompareByFn = (selector) => (a, b) => {
  const aValue = selector(a)
  const bValue = selector(b)
  return collator.compare(aValue, bValue)
}

export const intlSortBy = (selector) => (collection) =>
  Array.isArray(collection)
    ? [...collection].sort(intlCompareByFn(selector))
    : collection

export const intlSort = (collection) => intlSortBy((x) => x)(collection)

export const intlSortByCode = (collection) =>
  intlSortBy((item) => item.code)(collection)

export const naturalCompareByCodeAndName = (a, b) =>
  a.code === b.code
    ? naturalSort()(a.name, b.name)
    : naturalSort()(a.code, b.code)

export const naturalCompareByPositionAndLabel = (a, b) =>
  a.position === b.position
    ? naturalSort()(a.label, b.label)
    : naturalSort()(a.position, b.position)

export const naturalSortByCodeAndName = (collection) =>
  collection
    ? collection.concat().sort(naturalCompareByCodeAndName)
    : collection

export const naturalSortBy = (collection, prop) =>
  collection
    ? collection.concat().sort((a, b) => naturalSort()(a[prop], b[prop]))
    : collection

export const naturalSortByCode = (collection) =>
  naturalSortBy(collection, 'code')

export const sortByPositionAndLabel = (collection) =>
  collection
    ? collection.concat().sort(naturalCompareByPositionAndLabel)
    : collection
