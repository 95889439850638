import BrandSettingsTooltip from './tooltips/BrandSettingsTooltip/BrandSettingsTooltip'
import { INFO_TOOLTIP_SETTINGS } from './tooltips/BrandSettingsTooltip/BrandTooltipVariants'
import GallerySettingsTooltip from './tooltips/GallerySettingsTooltip/GallerySettingsTooltip'
import {
  GALLERY_TOOLTIP_EXTRA_SETTINGS,
  MEDIA_BANNER_SETTINGS,
} from './tooltips/GallerySettingsTooltip/GalleryTooltipVariants'
import TextBannerTooltip from './tooltips/TextBannerTooltip/TextBannerTooltip.jsx'
import WidgetTextTooltip from './tooltips/WidgetTextTooltip/WidgetTextTooltip'
import { TEXT_TOOLTIP_EXTRA_SETTINGS } from './tooltips/WidgetTextTooltip/WidgetTextVariants'
import * as constants from 'features/Storefront/constants'

const getNonTextType = (rowItem) =>
  rowItem?.elements?.filter(
    (element) => element?.elementType !== constants.TEXT_WIDGET,
  )?.[0]?.elementType

export const getRowSettingsTooltip = (rowItem) => {
  const widgetType = rowItem?.elements?.[0]?.elementType
  const defaultTooltip = {
    component: GallerySettingsTooltip,
    settings: GALLERY_TOOLTIP_EXTRA_SETTINGS[widgetType],
  }

  switch (rowItem?.rowType) {
    case constants.MEDIA_BANNER:
      return {
        component: GallerySettingsTooltip,
        settings: MEDIA_BANNER_SETTINGS,
      }
    case constants.TEXT_BANNER:
      return { component: TextBannerTooltip }
    case constants.ABOUT_BRAND:
    case constants.ABOUT_SHOWROOM:
      return {
        component: BrandSettingsTooltip,
        settings: INFO_TOOLTIP_SETTINGS,
      }
    case constants.IMAGE_TEXT:
    case constants.VIDEO_TEXT:
    case constants.DOCUMENT_TEXT:
    case constants.ORB360_TEXT:
    case constants.LINESHEET_TEXT:
    case constants.COLLECTION_TEXT:
      const nonTextWidgetType = getNonTextType(rowItem)
      const settings = TEXT_TOOLTIP_EXTRA_SETTINGS[nonTextWidgetType]
      return { component: WidgetTextTooltip, settings }

    default:
      return defaultTooltip
  }
}
