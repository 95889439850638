import PropTypes from 'prop-types'

import { ReactComponent as JOORLogo } from 'assets/images/logo_joor.svg'

import { LEGACY_RETAIL_REGISTRATION, SIGN_IN } from 'routes/paths'

import navbarStyles from './PublicNavbar.less'

const PublicNavbar = ({ hasContinue }) => {
  const currentUrl = window.location.href
  const loginUrl = hasContinue ? `/login?continue=${currentUrl}` : SIGN_IN

  return (
    <div className={navbarStyles.empty}>
      <div className={navbarStyles.navBar}>
        <div className={navbarStyles.publicNavbar}>
          <a href="/" className={navbarStyles.joorLogo}>
            <JOORLogo />
          </a>
          <div className={navbarStyles.publicLinks}>
            <a href={LEGACY_RETAIL_REGISTRATION}>Register</a>
            <a href={loginUrl}>Log In</a>
          </div>
        </div>
      </div>
    </div>
  )
}

PublicNavbar.propTypes = {
  hasContinue: PropTypes.bool,
}

PublicNavbar.defaultProps = {
  hasContinue: true,
}

export default PublicNavbar
