import { useQuery } from '@apollo/client'

import { atlasClient } from 'graphql/client'

import { GET_NEW_INVITATIONS_COUNT } from './connectionsMenuOption.queries'

export const useNewConnectionsCount = () => {
  const { data } = useQuery(GET_NEW_INVITATIONS_COUNT, {
    client: atlasClient,
  })
  return {
    newConnectionsCount: data?.newInvitationCount?.count ?? 0,
  }
}
