import { Modal } from '@joor/design-system'
import styled from 'styled-components'

type StyleModalProps = {
  $modalHeight: number
}
export const StyledModal = styled(Modal)<StyleModalProps>`
  overflow-y: auto;
  min-height: 421px;
  width: 400px !important;
  position: absolute;
  top: 80px;
  * {
    font-family: var(--font-family-alpha);
    font-size: var(--size-font-small);
    font-weight: var(--font-weight-medium);
    line-height: var(--line-height-16);
    letter-spacing: var(--letter-spacing-tighter);
  }
  /* 
    if overflow is not unset the dropdown will stay hidden within the
    context of the modal. with the .pricetype-assigned class we make sure
    that after the first dropdown selection has been done we always enable
    the scroll with the "overflow-y: auto" property
   */
  &:not(.pricetype-assigned),
  &:not(.pricetype-assigned) > div:first-of-type {
    overflow: unset;
  }
  > header > h2 {
    white-space: wrap;
    margin-right: 16px;
  }
  > div:first-of-type {
    padding: 0 24px;
    > div:nth-child(3) {
      padding-bottom: 16px;
    }
  }
`
export const StyledSectionContainer = styled.div`
  padding: 0 4px 24px;
`
export const StyledDescription = styled.div`
  color: var(--color-primary-900);
  padding: 0 4px 12px;
`
export const StyledPriceTypeRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
  margin: 8px 0;
  > div:first-child {
    text-align: right;
  }
  > div:nth-child(2) {
    min-width: 165px;
  }
`
export const BrandCheckboxSectionMessage = styled.div`
  padding-bottom: 4px;
`
export const BrandCheckboxContainer = styled.div`
  padding: 4px 16px;
`
export const PriceTypeCheckboxContainer = styled.div`
  padding: 4px 24px;
`
