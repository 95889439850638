import { handleActions } from 'redux-actions'

import { INITIAL_DATE, timestampToUnixEpoch } from 'utils/dates'

import withStorage from 'store/utils/storage/withStorage'

import {
  CREATED_BY_RETAILER_FILTER_KEY,
  DISMISS_NEW,
  DISMISS_TIMESTAMPED_NEWS,
  RETAILER_CREATED_ORDERS_KEY,
  STYLEBOARD_COMMENTS_KEY,
  UPCOMING_MAINTENANCE_BANNER_KEY,
  actionsPrefix,
} from './constants'

export const defaultState = {
  [UPCOMING_MAINTENANCE_BANNER_KEY]: true,
  [RETAILER_CREATED_ORDERS_KEY]: timestampToUnixEpoch(INITIAL_DATE),
  [STYLEBOARD_COMMENTS_KEY]: timestampToUnixEpoch(INITIAL_DATE),
  [CREATED_BY_RETAILER_FILTER_KEY]: timestampToUnixEpoch(INITIAL_DATE),
}

const reducer = handleActions(
  {
    [DISMISS_NEW]: (state, { payload }) => ({
      ...state,
      [payload]: false,
    }),
    [DISMISS_TIMESTAMPED_NEWS]: (state, { payload }) => ({
      ...state,
      [payload]: timestampToUnixEpoch(new Date()),
    }),
  },
  defaultState,
)

export default withStorage(reducer, {
  key: actionsPrefix,
  version: 0,
})
