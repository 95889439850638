import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import iconInFieldLoading from 'assets/images/icon_in_field_loading.gif'
import { isLoading as selectorIsLoading } from 'store/autosave/selectors'

import styles from './AutosaveLoader.less'

const mapStateToProps = (state) => ({ isLoading: selectorIsLoading(state) })

const withRedux = connect(mapStateToProps)

export const Autosave = ({ isLoading }) =>
  isLoading ? (
    <div className={styles.AutosaveLoader}>
      <img src={iconInFieldLoading} alt="loading..." />
      saving...
    </div>
  ) : null

Autosave.propTypes = {
  isLoading: PropTypes.bool.isRequired,
}

export default withRedux(Autosave)
