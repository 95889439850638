export const LEFT = 'LEFT'
export const RIGHT = 'RIGHT'
export const POSITION_OPTIONS = [
  {
    label: 'Left',
    value: LEFT,
  },
  {
    label: 'Right',
    value: RIGHT,
  },
]

export const DEFAULT_OPTIONS = {
  position: 'LEFT',
  showCategories: true,
  showDescription: true,
  showIcons: true,
  showName: true,
  showRetail: true,
  showURL: true,
  showWholesale: true,
  showYear: true,
}

export const INFO_TOOLTIP_SETTINGS = {
  switchList: [
    {
      id: 'showName',
      label: 'Show Name',
    },
    {
      id: 'showYear',
      label: 'Show Year Established',
    },
    {
      id: 'showCategories',
      label: 'Show Categories',
    },
    {
      id: 'showWholesale',
      label: 'Show Wholesale Price Range',
    },
    {
      id: 'showRetail',
      label: 'Show Retail Price Range',
    },
    {
      id: 'showURL',
      label: 'Show Website URL',
    },
    {
      id: 'showIcons',
      label: 'Show Social Icons',
    },
    {
      id: 'showDescription',
      label: 'Show Description',
    },
  ],
}
