import {
  every,
  filter,
  find,
  get,
  groupBy,
  isEmpty,
  isNil,
  isUndefined,
  values as lodashValues,
  map,
  maxBy,
  minBy,
  reduce,
  size,
  some,
  sumBy,
  uniq,
} from 'lodash'

import {
  decimalWithCommas,
  formatBooleanToString,
  formatNameWithCode,
} from 'utils/formatters'
import { getSizedImageUrl } from 'utils/images.js'
import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import { getFormattedCurrency } from 'shop/formatters'
import { APPROVED_STATUS } from 'shop/orders/constants'
import {
  BOOLEAN_TRAITS,
  EMPTY_VALUE,
  EXCLUDED_TRAITS,
  PRODUCT_COLOR_CODE,
  PRODUCT_FABRICATION_CODE_ID,
  PRODUCT_FABRICATION_NAME_ID,
  PRODUCT_FIT_CODE,
  PRODUCT_NUMBER_CODE,
  PRODUCT_SIZE_CODE,
} from 'shop/products/traits'
import {
  findSkuPriceByPriceTypeId,
  fromCasepackId,
  getVariantByCode,
  toGlobalSkuId,
  toSkuId,
} from 'shop/products/utils'

export const getPriceFromRange = ({ min, max }, priceType, placeholder) => {
  if (min === null) {
    return (
      placeholder || (
        <>
          <span className="isolate">Not available in</span>{' '}
          <var>{getFormattedCurrency(priceType)}</var>
        </>
      )
    )
  }
  if (min === max || max === null) {
    return decimalWithCommas(min)
  }
  return `${decimalWithCommas(min)} - ${decimalWithCommas(max)}`
}

export const findTraitByCode = (values, code) =>
  find(values, (value) => get(value, 'trait.code') === code)

export const getProductName = (product) => get(product, 'name', '')

export const getProductMinimum = (product) => get(product, 'orderMinimum', 0)

export const getProductFabrication = (product) => {
  const fabricationNameTrait = findTraitByCode(
    get(product, 'traitValues', []),
    PRODUCT_FABRICATION_NAME_ID,
  )
  const fabricationCodeTrait = findTraitByCode(
    get(product, 'traitValues', []),
    PRODUCT_FABRICATION_CODE_ID,
  )
  const fabricationName = get(fabricationNameTrait, 'value', '')
  const fabricationCode = get(fabricationCodeTrait, 'value', '')

  if (fabricationName || fabricationCode) {
    return formatNameWithCode(fabricationName, fabricationCode)
  }
  return EMPTY_VALUE
}

export const getProductOrderMinimum = (product) =>
  get(product, 'orderMinimum', 0)

export const getProductCode = (product) => {
  const productNumberTrait = findTraitByCode(
    get(product, 'traitValues'),
    PRODUCT_NUMBER_CODE,
  )
  return product?.externalIdentifier || productNumberTrait?.value || ''
}

export const getSkusPriceRangeByTrade = (skus = [], priceTypeId, trade) => {
  const prices = reduce(
    skus,
    (acc, sku) => {
      const skuPrice = findSkuPriceByPriceTypeId(sku, priceTypeId)
      return skuPrice ? [...acc, skuPrice] : acc
    },
    [],
  )
  const maxValue = maxBy(prices, (price) => parseFloat(get(price, trade)))
  const minValue = minBy(prices, (price) => parseFloat(get(price, trade)))
  return {
    min: get(minValue, trade, null),
    max: get(maxValue, trade, null),
  }
}

export const getDeliveryWindow = (collections, collectionId) => {
  const productcollection = find(
    collections,
    (collection) => collection.id === collectionId,
  )
  return {
    start: get(productcollection, 'delivery.startShipDate', null),
    complete: get(productcollection, 'delivery.completeShipDate', null),
  }
}

export const filterSkusVariantsByValue = (skus, variantValue) =>
  filter(skus, (sku) => {
    const skuVariant = findTraitByCode(
      sku.variantValues,
      get(variantValue, 'trait.code'),
    )
    return skuVariant && get(skuVariant, 'id') === get(variantValue, 'id')
  })

export const getVariantValuesWithSkusIds = (variantValues, skus) =>
  reduce(
    variantValues,
    (acc, variantValue) => {
      const matchingSkus = filterSkusVariantsByValue(skus, variantValue)
      const skusIds = map(matchingSkus, (matchingSku) => matchingSku.id)
      return [...acc, { ...variantValue, skusIds }]
    },
    [],
  )

export const getProductVariants = (product) => {
  const variants = get(product, 'variants', [])
  const skus = get(product, 'skus', [])
  return map(variants, (variant) => {
    let variantName =
      size(variant.values) > 1 ? `${variant.name}s` : variant.name
    if (variant.code === PRODUCT_SIZE_CODE) {
      const concatString = findTraitByCode(
        product.traitValues,
        PRODUCT_FIT_CODE,
      )
      variantName = !isEmpty(get(concatString, 'value'))
        ? `${variantName} (${concatString.value})`
        : variantName
    }
    const variantValues = getVariantValuesWithSkusIds(variant.values, skus)

    return {
      ...variant,
      name: variantName,
      values: variantValues,
    }
  })
}

export const getColorVariantImages = (product) => {
  const colorVariant = getVariantByCode(
    get(product, 'variants'),
    PRODUCT_COLOR_CODE,
  )
  const colorVariants = get(colorVariant, 'values')
  return reduce(
    colorVariants,
    (variantImages, colorVariantValue) => {
      const merchandiseImages = get(
        colorVariantValue,
        'displayDetails.merchandiseImages',
        [],
      )
      const imagesWithUrl = reduce(
        merchandiseImages,
        (acc, merchandiseImage) => {
          const imageUrl = get(merchandiseImage, 'url')
          const image = {
            ...merchandiseImage,
            id: `Color-${imageUrl}`,
            code: get(colorVariantValue, 'value', null),
          }
          return imageUrl ? [...acc, image] : acc
        },
        [],
      )
      return [...variantImages, ...imagesWithUrl]
    },
    [],
  )
}

export const getProductImages = (product) => {
  const productImages = reduce(
    get(product, 'images', []),
    (acc, image) => {
      const imageObject = {
        code: null,
        url: image.url,
        id: `Product-${image.url}`,
      }
      return image.url ? [...acc, imageObject] : acc
    },
    [],
  )
  return [...getColorVariantImages(product), ...productImages]
}

export const getProductVideos = (product) => get(product, 'videos', [])

export const getProductExternalMedia = (product) =>
  get(product, 'externalMedia', [])

export const getTraitValue = (trait) => {
  const traitCode = get(trait, 'trait.code')
  const traitValue = get(trait, 'value', EMPTY_VALUE) || EMPTY_VALUE

  if (traitValue !== EMPTY_VALUE && BOOLEAN_TRAITS.includes(traitCode)) {
    return traitValue === 'None'
      ? EMPTY_VALUE
      : formatBooleanToString(traitValue)
  }

  return traitValue
}

export const getProductTraitValues = (product) => {
  const traitValues = get(product, 'traitValues', [])
  return reduce(
    traitValues,
    (acc, trait) => {
      const traitCode = get(trait, 'trait.code')

      if (EXCLUDED_TRAITS.includes(traitCode)) {
        return acc
      }

      const traitCustomerCode = trait.externalIdentifier

      return [
        ...acc,
        {
          name: `${get(trait, 'trait.name', '')}${
            traitCustomerCode ? ' / Code' : ''
          }`,
          value: `${getTraitValue(trait)}${
            traitCustomerCode ? ` (${traitCustomerCode})` : ''
          }`,
        },
      ]
    },
    [],
  )
}

export const getProductDescription = (product) =>
  get(product, 'description', EMPTY_VALUE)

export const getProductSkusMap = (product) => {
  const skus = get(product, 'skus', [])
  return reduce(
    skus,
    (skusMap, sku) => {
      skusMap.set(sku.id, sku)
      return skusMap
    },
    new Map(),
  )
}

export const getCasepackTotalQuantity = (sizes) =>
  sumBy(sizes, ({ quantity }) => quantity || 0)

export const getCasepackSizes = (skus) =>
  map(skus, (sku) => {
    const sizeVariant = findTraitByCode(sku.variantValues, PRODUCT_SIZE_CODE)
    return {
      id: get(sizeVariant, 'id', ''),
      value: get(sizeVariant, 'value', ''),
      quantity: sku.quantity,
    }
  })

export const getCasepackInventoryId = (casepackId) => {
  const { sizeVariantId, colorVariantId } = fromCasepackId(casepackId)
  return toGlobalSkuId(colorVariantId, sizeVariantId)
}

export const getCasepackSkuId = (casepackId) => {
  const { sizeVariantId, colorVariantId } = fromCasepackId(casepackId)
  return toSkuId(colorVariantId, sizeVariantId)
}

export const getCasepackSkus = (casepack) =>
  reduce(
    get(casepack, 'skus'),
    (acc, sku) => {
      const sizeVariant = findTraitByCode(sku.variantValues, PRODUCT_SIZE_CODE)
      return {
        ...acc,
        ...(sizeVariant &&
          sizeVariant.id && {
            [sizeVariant.id]: sku,
          }),
      }
    },
    {},
  )

export const getCasepackColors = (casepacks) =>
  reduce(
    casepacks,
    (colors, casepack) => {
      const { id: originalCasepackId } = casepack
      const { colorVariantId } = fromCasepackId(originalCasepackId)
      const colorVariantIdFormatted = toGlobalId(
        'SKUTraitValue',
        toGlobalId('StyleColor', colorVariantId),
      )

      return {
        ...colors,
        [colorVariantIdFormatted]: {
          originalCasepackId,
          inventoryId: getCasepackInventoryId(originalCasepackId),
          skus: getCasepackSkus(casepack),
        },
      }
    },
    {},
  )

export const getProductCasepacks = (product) =>
  reduce(
    get(product, 'casepacksGroupByCasepackId'),
    (acc, casepacks, casepackId) => {
      const sizes = getCasepackSizes(get(casepacks, '[0].skus'))
      return {
        ...acc,
        [casepackId]: {
          id: casepackId,
          originalId: get(casepacks, '[0].id'),
          name: get(casepacks, '[0].name'),
          description: get(casepacks, '[0].description'),
          externalIdentifier: casepacks?.[0]?.externalIdentifier,
          skus: reduce(
            casepacks,
            (skus, casepack) => [...skus, ...casepack.skus],
            [],
          ),
          sizes,
          total: getCasepackTotalQuantity(sizes),
          colors: getCasepackColors(casepacks),
        },
      }
    },
    {},
  )

export const getColorVariantSwatchesInfo = ({
  product,
  colorUnits = new Map(),
  filterSoldOutColors = false,
  ignoresInventory = false,
}) => {
  const variants = get(product, 'variants', [])
  const colorValues = get(
    getVariantByCode(variants, PRODUCT_COLOR_CODE),
    'values',
    [],
  )
  return colorValues.map(
    ({ id, value, displayDetails, externalIdentifier }) => {
      const colorId = fromGlobalId(fromGlobalId(id).id).id
      const units = colorUnits.get(colorId)
      const isSoldOut = !ignoresInventory && filterSoldOutColors && units <= 0
      const colorName =
        (isSoldOut ? 'Sold out: ' : '') +
        formatNameWithCode(value, externalIdentifier)
      return {
        color: {
          images: reduce(
            get(displayDetails, 'merchandiseImages'),
            (acc, merchandiseImage) =>
              merchandiseImage.url ? [...acc, merchandiseImage.url] : acc,
            [],
          ),
          colorName,
          id,
        },
        swatch: {
          url: getSizedImageUrl(
            get(displayDetails, 'swatchImage.url'),
            '50x50',
          ),
          colorNumber: get(displayDetails, 'hexColor'),
        },
        crossed: isSoldOut,
      }
    },
  )
}

export const getProductTagValues = (product) => get(product, 'tagValues', [])

export const anySkuHasInventory = (productUnits) =>
  some(productUnits, (units) => get(units, 'positive') > 0)

export const getProductUnits = (product = {}, unitsBySkuId = new Map()) => {
  const { skus, casepacks } = product
  const skusIds = map(skus, (sku) => sku.id)
  const casepackInventoryIds = map(casepacks, (casepack) =>
    toGlobalId('Sku', getCasepackSkuId(casepack.id)),
  )
  const productUnits = reduce(
    skusIds.concat(casepackInventoryIds),
    (acc, skuId) => [...acc, unitsBySkuId.get(skuId)],
    [],
  )
  const noSkuHasInventory = every(productUnits, isUndefined)
  if (noSkuHasInventory) {
    return null
  }

  const productHasInventory = anySkuHasInventory(productUnits)
  return productHasInventory
    ? sumBy(productUnits, (e) => get(e, 'positive', 0))
    : sumBy(productUnits, (e) => get(e, 'negative', 0))
}

export const getProductUnitsAvailableValue = (
  product,
  unitsBySkuId,
  ignoresInventory = false,
) => {
  if (!product || !unitsBySkuId) {
    return EMPTY_VALUE
  }
  const productUnits = getProductUnits(product, unitsBySkuId)
  if (isNil(productUnits) || ignoresInventory) {
    return null
  }
  return productUnits
}

export const aggregateProductCasepacksSkus = (product) => {
  const casepacks = get(product, 'casepacks', [])
  const casepacksGroupByCasepackId = groupBy(casepacks, ({ id: casepackId }) =>
    toGlobalId('Casepack', fromCasepackId(casepackId).casepackId),
  )

  return {
    ...product,
    casepacksGroupByCasepackId,
    // to be able to mantain the order received from backend
    casepackIds: uniq(
      map(casepacks, ({ id: casepackId }) =>
        toGlobalId('Casepack', fromCasepackId(casepackId).casepackId),
      ),
    ),
  }
}

// This function returns the concatenation of product skus and its casepacks' skus
export const getAllProductSkus = (product = {}, variantValue = {}) => {
  const { skus, casepacks } = product
  const { skusIds } = variantValue
  const variantSkus = skus ? map(skusIds, (skuId) => skus.get(skuId)) : []
  const casepacksSkus = reduce(
    casepacks,
    (acc, { colors }) => [
      ...acc,
      ...lodashValues(get(colors, `${get(variantValue, 'id')}.skus`)),
    ],
    [],
  )
  return [...variantSkus, ...casepacksSkus]
}

export const getAvailableQuantity = (item, orderStatusId) =>
  orderStatusId === APPROVED_STATUS.id
    ? item.availableQuantity + (item.submittedQuantity || 0)
    : item.availableQuantity

export const filterProductSkusInventoryDatesByAvailability = (
  variantSkuIds = [],
  inventoryDates,
  inventoriesAvailability,
) =>
  reduce(
    inventoryDates,
    (acc, inventoryDate) => {
      const { id: inventoryId } = inventoryDate
      const inventory = inventoriesAvailability?.[inventoryId] || new Map()
      const skuQuantities = reduce(
        variantSkuIds,
        (acc, skuId) => [...acc, inventory.get(skuId)],
        [],
      )

      if (every(skuQuantities, isUndefined)) {
        return acc
      }

      return [...acc, inventoryDate]
    },
    [],
  )

export const filterProductCasepackInventoryDatesByAvailability = (
  variantId,
  casepacks,
  inventoryDates,
  inventoriesAvailability,
) =>
  reduce(
    inventoryDates,
    (acc, inventoryDate) => {
      const { id: inventoryId } = inventoryDate
      const inventory = inventoriesAvailability?.[inventoryId] || new Map()
      const casepackQuantities = reduce(
        casepacks,
        (acc, casepack) => [
          ...acc,
          inventory.get(casepack?.colors?.[variantId]?.inventoryId),
        ],
        [],
      )

      if (every(casepackQuantities, isUndefined)) {
        return acc
      }

      return [...acc, inventoryDate]
    },
    [],
  )
