import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import unionWith from 'lodash/unionWith'
import { handleActions } from 'redux-actions'

import {
  initialFilterReducerState,
  unselectedFilterReducerState,
} from 'utils/filters/utils'

import withStorage from 'store/utils/storage/withStorage'

import {
  ADD_ALL_SELECTS,
  APPLY_SELECTED_FILTERS,
  CLEAR_ALL_FILTERS,
  CLEAR_FILTER,
  CLEAR_SELECTS_FOR_COLLECTION,
  DESELECT_UNAVAILABLE_PRODUCTS,
  NONE_GROUP_BY_TAG,
  NONE_SORT_BY_TAG,
  SET_BRAND,
  SET_CART_INFO,
  SET_DOORS,
  SET_EVENT,
  SET_FILTER,
  SET_GROUP_BY_TAG_INFO,
  SET_ORDER_GROUP_ID,
  SET_ORDER_PRICETYPE,
  SET_ORDER_TYPE_ID,
  SET_PRICETYPES,
  SET_RETAILER,
  SET_SEARCH_TEXT,
  SET_SHOULD_DISABLE_CART,
  SET_SHOULD_UPDATE_CART,
  SET_SORT_BY_TAG_INFO,
  SET_WAREHOUSE,
  TOGGLE_DESELECT_ALL_FILTERS,
  TOGGLE_SELECTS,
  TOGGLE_SELECT_FILTER,
} from './constants'
import { getCatalogFilters } from 'containers/ProductCatalog/CatalogFilters/filters'

const catalogFilters = getCatalogFilters(false)

export const defaultState = {
  appliedFilters: { ...initialFilterReducerState(catalogFilters) },
  brand: null,
  cartInfo: {},
  doors: [],
  event: null,
  groupByTagInfo: NONE_GROUP_BY_TAG,
  sortByTagInfo: NONE_SORT_BY_TAG,
  localStorageLoaded: false,
  orderGroupId: '',
  orderPriceType: null,
  priceTypes: [],
  retailer: null,
  searchText: '',
  selectedFilters: { ...initialFilterReducerState(catalogFilters) },
  selects: [],
  shouldUpdateCart: false,
  shouldDisableCart: false,
  warehouse: null,
}

const reducer = handleActions(
  {
    [DESELECT_UNAVAILABLE_PRODUCTS]: (state, { payload }) => ({
      ...state,
      selects: state.selects.filter((s) => !payload.includes(s.productId)),
    }),
    [SET_RETAILER]: (state, { payload }) => ({
      ...state,
      retailer: { ...payload },
    }),
    [SET_DOORS]: (state, { payload }) => ({
      ...state,
      doors: [...payload],
    }),
    [SET_PRICETYPES]: (state, { payload }) => ({
      ...state,
      priceTypes: [...payload],
    }),
    [SET_ORDER_PRICETYPE]: (state, { payload }) =>
      payload
        ? {
            ...state,
            orderPriceType: { ...payload },
          }
        : state,
    [SET_WAREHOUSE]: (state, { payload }) => ({
      ...state,
      warehouse: { ...payload },
    }),
    [SET_EVENT]: (state, { payload }) => ({
      ...state,
      event: { ...payload },
    }),
    [SET_SHOULD_UPDATE_CART]: (state, { payload }) => ({
      ...state,
      shouldUpdateCart: payload,
    }),
    [SET_SHOULD_DISABLE_CART]: (state, { payload }) => ({
      ...state,
      shouldDisableCart: payload,
    }),
    [TOGGLE_SELECT_FILTER]: (state, { payload }) => {
      const { filterKey } = payload
      const { reducerHandler } = catalogFilters[filterKey]
      return {
        ...state,
        selectedFilters: reducerHandler(state.selectedFilters, { ...payload }),
      }
    },
    [TOGGLE_DESELECT_ALL_FILTERS]: (state) => ({
      ...state,
      selectedFilters: {
        ...state.selectedFilters,
        ...unselectedFilterReducerState(catalogFilters),
      },
    }),
    [APPLY_SELECTED_FILTERS]: (state) => ({
      ...state,
      appliedFilters: { ...state.selectedFilters },
    }),
    [SET_FILTER]: (state, { payload: { key, value } }) => ({
      ...state,
      appliedFilters: {
        ...state.appliedFilters,
        [key]: value ? [catalogFilters[key].toFilterValue(key, value)] : [],
      },
      selectedFilters: {
        ...state.selectedFilters,
        [key]: value ? [catalogFilters[key].toFilterValue(key, value)] : [],
      },
    }),
    [SET_BRAND]: (state, { payload }) => ({
      ...state,
      brand: { ...payload },
    }),
    [SET_SEARCH_TEXT]: (state, { payload }) => ({
      ...state,
      searchText: payload,
    }),
    [SET_ORDER_TYPE_ID]: (state, { payload }) => ({
      ...state,
      orderTypeId: payload,
    }),
    [SET_CART_INFO]: (state, { payload }) => {
      const cartInfoByRetailerId = groupBy(payload, 'id')
      return {
        ...state,
        cartInfo: cartInfoByRetailerId,
      }
    },
    [SET_GROUP_BY_TAG_INFO]: (state, { payload }) => ({
      ...state,
      groupByTagInfo: payload,
    }),
    [SET_SORT_BY_TAG_INFO]: (state, { payload }) => ({
      ...state,
      sortByTagInfo: payload,
    }),
    [SET_ORDER_GROUP_ID]: (state, { payload }) => ({
      ...state,
      orderGroupId: payload,
    }),
    [CLEAR_ALL_FILTERS]: (state) => ({
      ...state,
      selectedFilters: {
        ...initialFilterReducerState(catalogFilters),
      },
      appliedFilters: {
        ...initialFilterReducerState(catalogFilters),
      },
    }),
    [CLEAR_FILTER]: (state, { payload: filterName }) => {
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [filterName]: initialFilterReducerState(catalogFilters)?.[filterName],
        },
        appliedFilters: {
          ...state.appliedFilters,
          [filterName]: initialFilterReducerState(catalogFilters)?.[filterName],
        },
      }
    },
    [ADD_ALL_SELECTS]: (state, { payload: selects }) => {
      const selectsMatch = (select1, select2) => {
        return (
          select1.colorId === select2.colorId &&
          select1.collectionId === select2.collectionId &&
          select1.productId === select2.productId
        )
      }
      const filteredSelects = unionWith(state.selects, selects, selectsMatch)
      return { ...state, selects: filteredSelects }
    },
    [TOGGLE_SELECTS]: (state, { payload: select }) => {
      const prevSelectsState = state.selects
      const selectsWithoutPayloadSelect = prevSelectsState.filter(
        (stateSelect) => {
          const selectMatches =
            stateSelect.colorId === select.colorId &&
            stateSelect.collectionId === select.collectionId &&
            stateSelect.productId === select.productId
          return !selectMatches
        },
      )
      const alreadyASelect =
        selectsWithoutPayloadSelect.length < prevSelectsState.length

      const newSelectsState = alreadyASelect
        ? selectsWithoutPayloadSelect
        : [...prevSelectsState, select]
      return { ...state, selects: newSelectsState }
    },
    [CLEAR_SELECTS_FOR_COLLECTION]: (state, { payload: collectionId }) => ({
      ...state,
      selects: cloneDeep(state.selects).filter(
        (select) => select.collectionId !== collectionId,
      ),
    }),
  },
  defaultState,
)

const postLoad = (state) => ({
  ...state,
  localStorageLoaded: true,
})

export default withStorage(reducer, {
  key: 'BRAND_SHOP',
  version: 1,
  postLoadHooks: [postLoad],
  omitFields: ['searchText'],
})
