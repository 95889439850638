import PropTypes from 'prop-types'

import PriceRange from './PriceRange.component'

const PriceRangeContainer = ({ applyFilter, currency }) => {
  return <PriceRange applyFilter={applyFilter} currency={currency} />
}

PriceRangeContainer.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
}

export default PriceRangeContainer
