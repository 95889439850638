import { PRODUCTS_PAGE_CONTENT_PARAM } from 'features/Hubs/Orders/Review/modals/ProductsEditModal/productsEditModal.constants'

export const isProductsPageContent = () =>
  Boolean(
    new URLSearchParams(window.location.href).get(PRODUCTS_PAGE_CONTENT_PARAM),
  )

export const handleShowOnlyProductsContent = () => {
  const showOnlyProductsContent = isProductsPageContent()

  if (!showOnlyProductsContent) return

  /* To improve the Iframe experience we need to update some styles of the PHP page */

  const headerBorder = document.querySelector('#header-border') as HTMLElement
  if (headerBorder) headerBorder.style.marginTop = '0'

  const orderDivPage = document.querySelector('.page div.order') as HTMLElement
  if (orderDivPage) {
    orderDivPage.style.paddingTop = '1px'
    orderDivPage.style.paddingBottom = '0'
  }

  const orderPage = document.querySelector('.page') as HTMLElement
  if (orderPage) orderPage.style.paddingBottom = '0'

  /* To improve the Iframe experience we need to hide the following elements */

  const zendeskChat = document.querySelector(
    '.zendesk-messaging',
  ) as HTMLElement
  if (zendeskChat) {
    zendeskChat.style.setProperty('display', 'none', 'important')
  }

  const productsFormButtons = document.querySelector(
    '#OrderModifyForm .buttons',
  ) as HTMLElement
  if (productsFormButtons) productsFormButtons.style.display = 'none'

  const pageTitle = document.querySelector(
    '.order_edit.module h1',
  ) as HTMLElement
  if (pageTitle) pageTitle.style.display = 'none'

  const pageTitleId = document.querySelector(
    '.order_edit.module h2',
  ) as HTMLElement
  if (pageTitleId) pageTitleId.style.display = 'none'

  const pageSubtitle = document.querySelector(
    '#order_information h5',
  ) as HTMLElement
  if (pageSubtitle) pageSubtitle.style.display = 'none'

  const firstAddMoreStyles = document.querySelector(
    '#order_information div a',
  ) as HTMLElement
  if (firstAddMoreStyles) firstAddMoreStyles.style.display = 'none'

  const secondAddMoreStyles = document.querySelector(
    'div.order_info.container div.final-totals div a',
  ) as HTMLElement
  if (secondAddMoreStyles) secondAddMoreStyles.style.display = 'none'
}
