import { useMemo, useRef, useState } from 'react'

import { GammaBadge, Icon } from '@joor/design-system'
import Cookies from 'js-cookie'
import styled from 'styled-components'

import { ReactComponent as IconBeaker } from 'assets/images/icon_beaker.svg'

import FeatureList from './components/FeatureList/FeatureList'
import {
  FEATURE_COUNT_BADGE_COOKIE,
  FEATURE_COUNT_BADGE_VALUE,
  FEATURE_COUNT_DEFAULT_VALUE,
} from './constants/constants'
import messages from './constants/messages'
import { useAvailableFeatures } from './earlyAccessWidget.hooks'
import { WidgetIds } from './earlyAccessWidget.ids'

const Layout = styled.section`
  position: relative;
  z-index: 2002;
`

const Container = styled.div`
  display: flex;
  height: 40px;
  width: 427px;
  border-radius: 20px;
  background: var(--color-neutral-000-full);
  box-shadow: var(--elevation-4);
  cursor: pointer;
`

const Beaker = styled(IconBeaker)`
  height: 40px;
  width: 48px;
  min-width: 48px;
  background: var(--color-secondary-400);
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`

const Menu = styled.div`
  padding: 8px 14px;
`

const Title = styled.h3`
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-secondary-800);
  flex-grow: 1;
  user-select: none;

  &&& {
    font-family: var(--font-family-alpha);
    font-style: normal;
    font-weight: var(--font-weight-semibold);
    font-size: var(--size-font-small);
    line-height: var(--line-height-16);
    letter-spacing: var(--letter-spacing-tighter);
    margin: 0;
    padding: 12px 16px;
  }
`

const BeakerContainer = styled.div`
  position: relative;
`

const FeatureCount = styled(GammaBadge)`
  position: absolute;
  top: 1px;
  right: -4px;
`

const EarlyAccessWidget = () => {
  const ref = useRef<HTMLDivElement>(null)
  const features = useAvailableFeatures()
  const [showOptions, setShowOptions] = useState(false)

  const featureCountBadge = useMemo(() => {
    // If there are no features, return a default value for the badge count
    if (!features.length) {
      return FEATURE_COUNT_DEFAULT_VALUE
    }

    const cookieValue = Cookies.get(FEATURE_COUNT_BADGE_COOKIE)

    // If the cookie value is not set, return the number of features
    if (!cookieValue) {
      return features.length
    }

    // If the cookie value matches the previous logic value, return the number of features (reset)
    if (cookieValue === FEATURE_COUNT_BADGE_VALUE) {
      return features.length
    }

    try {
      const parsedCookieValue = JSON.parse(cookieValue) as string[]

      // Filter out features based on whether they are present in the parsed cookie value
      const filteredFeatures = features.filter((feature) => {
        return !parsedCookieValue.includes(feature.earlyAccessFeatureFlag)
      })

      // Return the count of filtered features
      return filteredFeatures.length
    } catch (error) {
      return FEATURE_COUNT_DEFAULT_VALUE
    }
  }, [features])

  const handleWidgetClick = () => {
    if (featureCountBadge && !showOptions) {
      Cookies.set(
        FEATURE_COUNT_BADGE_COOKIE,
        JSON.stringify(
          features.map((feature) => feature.earlyAccessFeatureFlag),
        ),
      )
    }
    setShowOptions(!showOptions)
  }

  if (!features.length) {
    return null
  }

  return (
    <Layout ref={ref}>
      <Container
        data-testid={WidgetIds.Container}
        id={WidgetIds.Container}
        onClick={handleWidgetClick}
      >
        <BeakerContainer>
          <Beaker />
          {!!featureCountBadge && (
            <FeatureCount
              data-testid={WidgetIds.FeatureCount}
              value={featureCountBadge}
            />
          )}
        </BeakerContainer>
        <Title data-testid={WidgetIds.Title} id={WidgetIds.Title}>
          {messages.widgetTitle}
        </Title>
        <Menu>
          <Icon iconName="menu" plain />
        </Menu>
      </Container>
      <FeatureList visible={showOptions} />
    </Layout>
  )
}

export default EarlyAccessWidget
