import LegacyPage from 'pages/LegacyPage'

import { featureFlagContentWrapper } from './launchDarkly'

// eslint-disable-next-line import/prefer-default-export
export const featureFlagRouteWrapper = (
  WrappedComponent,
  { flagKey },
  props,
) => {
  const featureComponent = <WrappedComponent {...props} />
  const defaultComponent = <LegacyPage {...props} />
  return featureFlagContentWrapper(featureComponent, defaultComponent, flagKey)
}
