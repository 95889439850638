import { getDisplayName } from 'utils/mixpanel'
import { useUserAnalytics } from 'utils/sdks/userAnalytics'

const withAnalytics = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { trackEvent } = useUserAnalytics()
    const brandId = props?.brandId

    const trackers = {
      trackBulk: () =>
        trackEvent('Catalog_Bulk', {
          brandId,
        }),
      trackSized: () =>
        trackEvent('Catalog_Sized', {
          brandId,
        }),
      trackCheckboxClick: (colorName) =>
        trackEvent('Catalog_StyleBox', {
          'Color Noted': colorName,
          brandId,
        }),
    }

    return <WrappedComponent {...props} {...trackers} />
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return Wrapper
}

export default withAnalytics
