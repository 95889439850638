import get from 'lodash/get'
import PropTypes from 'prop-types'

import { timestampToUnixEpoch } from 'utils/dates'

import styles from './NewsBanner.component.less'
import NewsBannerItem from './NewsBannerItem/NewsBannerItem.component'
import newsConfig from './constants'

const filterNews = (newsKey, lastLogin, allVisibleNews) => {
  if (get(newsConfig[newsKey], 'timestamp')) {
    const lastLoginUnixEpoch = timestampToUnixEpoch(lastLogin)
    const dismissedTimestamp = allVisibleNews[newsKey]
    return lastLoginUnixEpoch > dismissedTimestamp
  }
  return allVisibleNews[newsKey]
}

const NewsBanner = ({
  newsToDisplay,
  allVisibleNews,
  dismissNews,
  dismissTimestampedNews,
  dynamicMessages,
  lastLogin,
  onClickOnText,
}) => (
  <div className={styles.newsBannerWrapper}>
    {newsToDisplay
      // Don't show dismissed news
      .filter((newsKey) => filterNews(newsKey, lastLogin, allVisibleNews))
      .map((newsKey) => (
        <NewsBannerItem
          key={newsKey}
          message={dynamicMessages[newsKey]}
          onClose={
            get(newsConfig[newsKey], 'timestamp')
              ? () => dismissTimestampedNews(newsKey)
              : () => dismissNews(newsKey)
          }
          onClickOnText={onClickOnText}
          {...newsConfig[newsKey]}
        />
      ))}
  </div>
)

NewsBanner.propTypes = {
  allVisibleNews: PropTypes.object,
  newsToDisplay: PropTypes.array,
  dismissNews: PropTypes.func,
  dismissTimestampedNews: PropTypes.func,
  dynamicMessages: PropTypes.object,
  lastLogin: PropTypes.string,
  onClickOnText: PropTypes.func,
}

NewsBanner.defaultProps = {
  allVisibleNews: {},
  newsToDisplay: [],
  dismissNews: () => {},
  dismissTimestampedNews: () => {},
  dynamicMessages: {},
  lastLogin: '',
  onClickOnText: null,
}

export default NewsBanner
