const getStringSize = (str: string) => {
  return ((str.length * 16) / 8).toFixed(2)
}

const getLocalStorageKeysSortedBySize = () => {
  const localStorageSizes = []

  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i) ?? ''
    const value = localStorage.getItem(key)
    if (value) {
      localStorageSizes.push({ key, size: getStringSize(value || '') })
    }
  }

  return localStorageSizes.sort((a, b) => Number(b.size) - Number(a.size))
}

const cleanHeavierKeys = (numKeysToClean: number = 2) => {
  /*
    LocalStorage is a browser-provided component with a capacity of 10MB, many of our
    libraries use it for multiple reasons, for example localize.js store a cache of translations. 
    Another example is Keycloak, which store some tokens allow the authentication token to be
    refreshed. If LocalStorage is full, the web application doesn't work, for this reason in 
    the auth step, if we have this problem, we delete the heavier keys in LocalStorage.
  */
  const localStorageKeys = getLocalStorageKeysSortedBySize()
  const numKeysToDelete =
    numKeysToClean > localStorageKeys.length
      ? localStorageKeys.length
      : numKeysToClean
  for (let i = 0; i < numKeysToDelete; i++) {
    localStorage.removeItem(localStorageKeys[i].key)
  }
}

const isLocalStorageFullError = (error: DOMException) => {
  return (
    error.name === 'QuotaExceededError' &&
    error.message.startsWith("Failed to execute 'setItem' on 'Storage'")
  )
}

export const domErrorHandler = (error: DOMException) => {
  if (isLocalStorageFullError(error)) {
    cleanHeavierKeys()
  }
}

// @typescript-eslint/no-explicit-any
export const errorHandler = (
  error: any,
  errorMapper: Record<string, (ex: any) => void> = {},
) => {
  const errorHandler = errorMapper[error?.constructor?.name] ?? (() => {})
  errorHandler(error)
}
