import { useSelector } from 'react-redux'

import { isProRetailer } from 'store/currentUser/selectors'

import { RETAIL_SALES } from 'routes/paths'

import MenuLinkWrapper from '../MenuLinkWrapper/MenuLinkWrapper'
import messages from 'features/NavbarRevamp/constants/navbarRevamp.messages'
import { ReportingOptionIds } from 'features/NavbarRevamp/navbarRevamp.ids'

const ReportingMenuLink = () => {
  const isProRetailerAccount = useSelector<object, boolean>(isProRetailer)

  if (!isProRetailerAccount) {
    return null
  }
  return (
    <MenuLinkWrapper
      to={RETAIL_SALES}
      id={ReportingOptionIds.ReportingTrigger}
      data-testid={ReportingOptionIds.ReportingTrigger}
    >
      {messages.reporting}
    </MenuLinkWrapper>
  )
}

export default ReportingMenuLink
