import get from 'lodash/get'

import {
  decimalWithCommas,
  formatDropdownOptions,
  formatNameWithCode,
  formatNameWithCodeAndDash,
  formatNameWithCodeAndNameInBrackets,
} from 'utils/formatters'
import { translateText } from 'utils/sdks/localize'

import { EMPTY_VALUE } from 'shop/products/traits'

export const ALL_COLLECTIONS = 'all'

export const getFormattedCurrency = (priceType, withDash = true) => {
  const currencyCode = get(priceType, 'currency.code')
  const priceTypeName = get(priceType, 'name')

  return withDash
    ? formatNameWithCodeAndDash(currencyCode, priceTypeName)
    : formatNameWithCode(priceTypeName, currencyCode)
}

export const getFormattedCurrencyPrice = (currency) => {
  const currencyCode = get(currency, 'code')
  const currencyName = get(currency, 'name')

  return formatNameWithCodeAndNameInBrackets(currencyCode, currencyName)
}

export const getFormattedPrice = (price) => {
  if (price) {
    const min = decimalWithCommas(price.min) || EMPTY_VALUE
    const max = decimalWithCommas(price.max) || EMPTY_VALUE
    return min === max ? `${min}` : `${min} - ${max}`
  }
  return ''
}

export const getCollectionsFormatted = (collections) => {
  const defaultOption = {
    id: ALL_COLLECTIONS,
    name: translateText('All styles by linesheet'),
  }

  return [
    ...formatDropdownOptions([defaultOption]),
    ...formatDropdownOptions(collections, undefined, false, 'notranslate'),
  ]
}
