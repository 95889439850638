import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import { Quill } from 'react-quill'
import { handleActions } from 'redux-actions'

import {
  ADD_ROW,
  CLEAR_ACTIVE_ELEMENT,
  DELETE_ROW,
  INIT_PAGES,
  INIT_PANEL,
  INIT_PROFILE,
  MARK_EDITING,
  MARK_SELECTED,
  RESTORE_PAGES,
  RESTORE_PANEL,
  RESTORE_PROFILE,
  SET_BRAND_NAVIGATION,
  SET_ROWS,
  TOGGLE_BRAND_NAVIGATION,
  TOGGLE_EDIT_MODE,
  UPDATE_PAGES,
  UPDATE_PROFILE,
  UPDATE_ROW,
  UPDATE_TEXT_STYLES,
  VIEW_AS_TARGET,
} from './constants'
import { getDefaultTextStyles } from 'features/Storefront/EditionNavbar/Sections/TextSettings/TextSettings.constants'
import { CONNECTED_RETAILER, ONLINE } from 'features/Storefront/constants'
import {
  QUILL_FONTS,
  SIZE_WHITELIST,
} from 'features/Storefront/widgets/TextWidget/TextWidget.constants'

export const defaultState = {
  editMode: false,
  visionMode: ONLINE,
  brandNavigation: false,
  viewAs: CONNECTED_RETAILER,
  profileFetched: false,
  storefrontFetched: false,
  lastPublished: '',
  shouldUpdate: '',
  pages: [],
  backupPages: [],
  pagesFetched: false,
  backupPanel: {
    id: '',
    editingElementId: '',
    selectedElementId: '',
    rows: [],
  },
  panel: {
    id: '',
    editingElementId: '',
    selectedElementId: '',
    rows: [],
    textStyles: getDefaultTextStyles(),
  },
}

function registerQuill(textStyles) {
  const Parchment = Quill.import('parchment')
  const config = {
    scope: Parchment.Scope.BLOCK,
  }

  const Keyboard = Quill.import('modules/keyboard')
  class StorefrontKeyboard extends Keyboard {
    constructor(quill, options) {
      super(quill, options)
      // Override delete bindings for keeping styles
      this.bindings[8] = this.bindings[8].map((b) => {
        return {
          ...b,
          handler: function (range, context) {
            b.handler.call(this, range, context)
            // Keep last styles when erasing and getting an empty line
            if (context.prefix.length === 1 || context.offset === 0) {
              this.quill.format('size', context.format.size)
              this.quill.format('font', context.format.font)
              const newRange = {
                ...range,
                custom: true,
                font: context.format.font,
                size: context.format.size,
              }
              this.quill.emitter.emit(
                'editor-change',
                'selection-change',
                newRange,
                range,
                'user',
              )
            }
            return false
          },
        }
      })
    }
  }
  Quill.register('modules/keyboard', StorefrontKeyboard, true)

  // FONT
  const Font = Quill.import('formats/font')
  Font.whitelist = Object.keys(QUILL_FONTS)

  // STYLES
  const Styles = new Parchment.Attributor.Class('styles', 'styles', config)
  Styles.whitelist = ['', ...textStyles.map((s) => s.name.toLowerCase())]
  Quill.register(Styles, true)

  // FONT SIZE
  const Size = Quill.import('attributors/style/size')
  Size.whitelist = SIZE_WHITELIST
  Quill.register(Size, true)

  // CUSTOM LINE HEIGHT
  const CustomLineHeight = new Parchment.Attributor.Style(
    'line-height',
    'line-height',
    config,
  )
  Quill.register(CustomLineHeight, true)
}

registerQuill(defaultState.panel.textStyles)

const reducer = handleActions(
  {
    [TOGGLE_EDIT_MODE]: (state) => ({
      ...state,
      editMode: !state.editMode,
      storefrontFetched: false,
      panel: {
        ...state.panel,
        editingElementId: '',
        selectedElementId: '',
      },
    }),
    [TOGGLE_BRAND_NAVIGATION]: (state) => ({
      ...state,
      brandNavigation: !state.brandNavigation,
    }),
    [SET_BRAND_NAVIGATION]: (state, { value }) => ({
      ...state,
      brandNavigation: value,
    }),
    [SET_ROWS]: (state, { value: rows }) => ({
      ...state,
      panel: {
        ...state.panel,
        rows,
      },
    }),
    [ADD_ROW]: (state, { value: { index, row } }) => {
      const newRows = [...state.panel.rows]
      newRows.splice(index, 0, row)
      return {
        ...state,
        panel: {
          ...state.panel,
          rows: newRows,
          editingElementId: row.id,
          selectedElementId: '',
        },
      }
    },
    [INIT_PANEL]: (state, { value: storefront }) => {
      const {
        __typename,
        brandId,
        updatedOn,
        shouldUpdate,
        ...panelProps
      } = storefront

      const newTextStyles = panelProps?.textStyles?.length
        ? panelProps?.textStyles
        : getDefaultTextStyles()
      const panel = {
        ...state.panel,
        ...panelProps,
        textStyles: newTextStyles,
      }
      registerQuill(panel.textStyles)
      return {
        ...state,
        brandId,
        lastPublished: updatedOn,
        updatedOn,
        shouldUpdate,
        panel,
        backupPanel: cloneDeep(panel),
        storefrontFetched: true,
      }
    },
    [DELETE_ROW]: (state, { value: rowIndex }) => {
      const rows = [...state.panel.rows]
      rows.splice(rowIndex, 1)
      return {
        ...state,
        panel: {
          ...state.panel,
          rows,
        },
      }
    },
    [MARK_EDITING]: (state, { value: id }) => ({
      ...state,
      panel: {
        ...state.panel,
        editingElementId: id,
        selectedElementId: '',
      },
    }),
    [MARK_SELECTED]: (state, { value: id }) => ({
      ...state,
      panel: {
        ...state.panel,
        selectedElementId: state.panel.selectedElementId === id ? '' : id,
        editingElementId: '',
      },
    }),
    [UPDATE_ROW]: (state, { value: row }) => {
      const newRows = [...state.panel.rows]
      const oldRow = newRows.find((el) => el.id === row.id)
      oldRow.elements = row.elements
      oldRow.settings = row.settings
      oldRow.template = row.template
      oldRow.title = row.title
      return {
        ...state,
        panel: {
          ...state.panel,
          editingElementId: '',
          selectedElementId: '',
          rows: newRows,
        },
      }
    },
    [UPDATE_TEXT_STYLES]: (state, { value: textStyles }) => {
      return {
        ...state,
        panel: {
          ...state.panel,
          textStyles: textStyles,
        },
      }
    },
    [INIT_PROFILE]: (state, { value: profile }) => ({
      ...state,
      profile: profile,
      backupProfile: cloneDeep(profile),
      profileFetched: true,
    }),
    [UPDATE_PROFILE]: (state, { value }) => ({
      ...state,
      profile: { ...state.profile, ...value },
    }),
    [RESTORE_PROFILE]: (state) => ({
      ...state,
      profile: cloneDeep(state.backupProfile),
    }),
    [RESTORE_PANEL]: (state) => ({
      ...state,
      panel: cloneDeep(state.backupPanel),
    }),
    [VIEW_AS_TARGET]: (state, { value: viewAs }) => ({
      ...state,
      viewAs,
    }),
    [CLEAR_ACTIVE_ELEMENT]: (state) => ({
      ...state,
      panel: {
        ...state.panel,
        editingElementId: '',
        selectedElementId: '',
      },
    }),
    [INIT_PAGES]: (state, { value: pages }) => {
      const sortedPages = sortBy([...pages], (page) => page.orderNbr)
      return {
        ...state,
        pages: [...sortedPages],
        backupPages: cloneDeep(sortedPages),
        pagesFetched: true,
      }
    },
    [UPDATE_PAGES]: (state, { value }) => ({
      ...state,
      pages: { ...state.pages, ...value },
    }),
    [RESTORE_PAGES]: (state) => ({
      ...state,
      pages: cloneDeep(state.backupPages),
    }),
  },
  defaultState,
)

export default reducer
