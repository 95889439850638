import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { formatNumber } from 'utils/formatters'

import { setPriceType, setRetailer } from 'store/orders/inOrderReport/actions'

import {
  IN_ORDER_ALLOCATION_REPORT,
  IN_ORDER_BUDGET_REPORT,
  ORDERS_CART,
} from 'routes/paths'

import {
  BrandCartContainer,
  InfoContainer,
  LinkWrapper,
  MenuBody,
  MenuHeader,
  MenuSubHeader,
  OrderTitle,
  Price,
  ReportButton,
  ReportContainer,
  ReportsLabel,
  RetailerOrder,
  Unit,
  UnitAmount,
  UnitLabel,
  ViewCartButton,
} from '../../CartDropdown.ui'
import { AccountType } from '../../cartDropdown.queries'
import messages from 'features/NavbarRevamp/constants/navbarRevamp.messages'
import { CartDropdownIds } from 'features/NavbarRevamp/navbarRevamp.ids'

interface IBrandCartProps {
  accountsWithOrders: AccountType[]
  isCartLoading: boolean
}

interface IUnitProps {
  label: string
  value: number
}

interface ICartOrderProps {
  retailer: AccountType
  budgetReport: boolean
  saveReportInfo: (retailer: AccountType) => void
}

const Units = ({ label, value }: IUnitProps) => (
  <Unit>
    <UnitAmount className="notranslate">{value}</UnitAmount>
    <UnitLabel>{label}</UnitLabel>
  </Unit>
)

const CartOrder = ({
  retailer,
  budgetReport,
  saveReportInfo,
}: ICartOrderProps) => {
  if (!retailer) {
    return null
  }

  const { displayName, orders } = retailer
  const {
    totalStyles,
    totalSkus,
    totalUnits,
    totalDiscountedPrice,
    priceType,
  } = orders || {
    totalStyles: 0,
    totalSkus: 0,
    totalUnits: 0,
    totalDiscountedPrice: 0,
  }
  const currencyCode = priceType?.currency?.code || 'USD'

  return (
    <RetailerOrder>
      <OrderTitle className="notranslate">{displayName}</OrderTitle>
      <InfoContainer>
        <Units label={messages.styles} value={totalStyles} />
        <Units label={messages.colors} value={totalSkus} />
        <Units label={messages.units} value={totalUnits} />
        <Price>
          <UnitAmount className="notranslate">
            {formatNumber(totalDiscountedPrice)}
          </UnitAmount>
          <UnitLabel>{`${messages.total} (${currencyCode})`}</UnitLabel>
        </Price>
      </InfoContainer>
      <ReportContainer>
        <ReportsLabel>{messages.reports}: </ReportsLabel>
        <Link
          to={IN_ORDER_ALLOCATION_REPORT}
          data-testid="allocation-report-link"
          onClick={(): void => saveReportInfo(retailer)}
        >
          <ReportButton>{messages.allocation}</ReportButton>
        </Link>
        {budgetReport && (
          <Link
            to={IN_ORDER_BUDGET_REPORT}
            data-testid="budget-report-link"
            onClick={(): void => saveReportInfo(retailer)}
          >
            <ReportButton>{messages.budget}</ReportButton>
          </Link>
        )}
      </ReportContainer>
    </RetailerOrder>
  )
}

const BrandCart = ({ accountsWithOrders, isCartLoading }: IBrandCartProps) => {
  const dispatch = useDispatch()
  const { budgetReport } = useFlags()

  const saveReportInfo = (retailer: AccountType): void => {
    dispatch(setRetailer({ value: retailer?.id, label: retailer?.displayName }))
    dispatch(
      setPriceType({
        value: retailer?.orders?.priceType?.id,
        label: retailer?.orders?.priceType?.currency?.code,
      }),
    )
  }
  return (
    <BrandCartContainer data-testid="brand-cart">
      <MenuHeader>{messages.cart}</MenuHeader>
      <MenuBody>
        {accountsWithOrders?.length ? (
          accountsWithOrders.map((retailer: AccountType) => (
            <CartOrder
              key={retailer.displayName}
              retailer={retailer}
              budgetReport={budgetReport}
              saveReportInfo={saveReportInfo}
            />
          ))
        ) : (
          <MenuSubHeader>{messages.emptyCart}</MenuSubHeader>
        )}
      </MenuBody>
      <LinkWrapper>
        <Link
          to={ORDERS_CART}
          data-testid={CartDropdownIds.CartView}
          id={CartDropdownIds.CartView}
        >
          <ViewCartButton loading={isCartLoading}>
            {messages.cartView}
          </ViewCartButton>
        </Link>
      </LinkWrapper>
    </BrandCartContainer>
  )
}

export default BrandCart
