import compose from 'lodash/flowRight'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { getAccountId, isLiteBrand } from 'store/currentUser/selectors'

import { withAnalytics } from './LegacyPage.analytics'
import LegacyPage from './LegacyPage.component'
import withModal from 'containers/ModalHandler'

const mapStateToProps = (state) => ({
  isLiteBrand: isLiteBrand(state),
  accountId: getAccountId(state),
})

const withRedux = connect(mapStateToProps)

export default compose(
  withRedux,
  withRouter,
  withModal,
  withAnalytics,
)(LegacyPage)
