/* eslint-disable max-len */
import { useState } from 'react'

import {
  Button,
  Dropdown,
  Icon,
  Option,
  SecondaryButton,
  Tooltip,
  typography,
} from '@joor/design-system'
import '@joor/design-system/dist/designTokens/variables.css'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { formatTimestamp } from 'utils/formatters'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { useScreenSize } from 'hooks/useScreenSize'
import { isAccountTypeBrand, userIsLoggedIn } from 'store/currentUser/selectors'
import {
  INIT_PANEL,
  RESTORE_PANEL,
  RESTORE_PROFILE,
  SET_BRAND_NAVIGATION,
  TOGGLE_BRAND_NAVIGATION,
  TOGGLE_EDIT_MODE,
  UPDATE_TEXT_STYLES,
  VIEW_AS_TARGET,
} from 'store/storefront/constants'
import {
  getAccountId,
  getBackupPanel,
  getBrandNavigation,
  getEditModeEnabled,
  getExistsHomePage,
  getInMenuPages,
  getLastPublished,
  getPanel,
  hasChanges,
  isLastOnlinePage,
} from 'store/storefront/selectors'

import Sidebar from 'components/Core/Sidebar'
import { SidebarElement } from 'components/Core/Sidebar/Sidebar'
import StorefrontSaveChanges from 'modals/Storefront/StorefrontSaveChanges/StorefrontSaveChanges.component'
import { ACCOUNTS_HOME } from 'routes/paths'

import { useStorefrontNavigation } from '../Storefront.hooks'
import { HeaderIds } from '../Storefront.ids'
import { useUpsertStorefront } from '../StorefrontNavbar/StorefrontNavbar.hooks'
import {
  CONNECTED_RETAILER,
  DISCONNECTED_RETAILER,
  LOGGED_OUT_USER,
  OFFLINE,
  ONLINE,
} from '../constants'
import BrandDetails from './Sections/BrandDetails'
import Pages from './Sections/Pages'
import { MAX_ONLINE_IN_MENU_PAGES } from './Sections/Pages/Pages.constants'
import { useBulkUpdateStorefront } from './Sections/Pages/Pages.hooks'
import { PageElement } from './Sections/Pages/Pages.types'
import { MODAL_FIRST_HOME } from './Sections/Pages/actions/PageActions'
import Modals from './Sections/Pages/actions/modals'
import QR from './Sections/QR'
import TextSettings from './Sections/TextSettings'
import withModal from 'containers/ModalHandler'

export const DISCARD_BUTTON = 'storefront_edition_navbar_discard_changes'
export const SAVE_BUTTON = 'storefront_edition_navbar_save_changes'
export const EDIT_BUTTON = 'storefront_edition_navbar_edit_button'
export const BACK_HOME_ID = 'storefront_edition_navbar_back_home_button'

// TODO: change media queries when a styled theme is added for styled components
const Navbar = styled.div`
  height: 50px;
  width: 100%;
  background-color: var(--color-secondary-200);
  position: sticky;
  top: 0;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  margin-bottom: -78px;
  font-size: 12px;
`

const LeftContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  > span:nth-child(2),
  button:nth-child(2) {
    margin-left: 8px;
  }
  button {
    min-width: 100px;
    @media (max-width: 768px) {
      min-width: auto;
      padding: 8px;
      > span {
        margin-right: 0 !important;
      }
      svg {
        transform: scale(1.5);
      }
    }
  }
  @media (max-width: 480px) {
    width: 200%;
  }
`

const VerticalSeparator = styled.div`
  height: 32px;
  width: 1px;
  margin: 0 8px;
  background-color: #afbcc5;
`

const RightContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > * {
    margin-right: 8px !important;
  }

  > span:first-child {
    text-align: right;
    @media (max-width: 768px) {
      width: 40%;
      min-width: 150px;
    }
    @media (max-width: 480px) {
      display: none;
    }
  }

  > div:last-child {
    button {
      @media (max-width: 640px) {
        min-width: auto;
        padding: 8px;
        > span {
          margin-right: 0 !important;
        }
        svg {
          transform: scale(1.5);
        }
      }
    }
  }
`

const ViewDropdown = styled.div`
  width: 80%;
  max-width: 250px;
  margin-right: 10px;
  @media (max-width: 639px) {
    & > div > div:nth-child(3) {
      white-space: nowrap;
    }
  }
  @media (max-width: 480px) {
    width: 100%;
  }
`

const TooltipContainer = styled.div`
  > span {
    ${typography.alpha.primaryActive.caption};
    font-size: 12px !important;
    & > div {
      width: 300px;
    }
  }
`

const StatusDropdown = styled.div`
  min-width: 96px;
  @media (max-width: 639px) {
    white-space: normal;
    min-width: 60px;
  }

  > div [data-testid='Option'] {
    span:first-child {
      transform: scale(0.4);
      margin-top: 2px;
      @media (max-width: 639px) {
        transform: scale(0.8);
        margin-top: 0;
      }
    }
    &:first-child {
      span:first-child {
        svg {
          --main-color: var(--color-success-500);
        }
      }
    }
    &:nth-child(2) {
      span:first-child {
        svg {
          --main-color: var(--color-error-500);
        }
      }
    }
  }
`

const Message = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-error-800);
  span {
    margin-left: 8px;
    font-family: var(--font-family-alpha);
    font-weight: var(--font-weight-regular);
  }
  svg g {
    fill: var(--color-error-500);
  }
`

const viewAsOptions: Option[] = [
  {
    id: HeaderIds.ViewConnectedRetailerOption,
    label: 'View: Connected Retailer',
    value: CONNECTED_RETAILER,
  },
  {
    id: HeaderIds.ViewUnconnectedRetailerOption,
    label: 'View: Unconnected Retailer',
    value: DISCONNECTED_RETAILER,
  },
  {
    id: HeaderIds.ViewLoggedOutUserOption,
    label: 'View: Logged Out User',
    value: LOGGED_OUT_USER,
  },
]

type NavbarProps = {
  openDynamicModal: Function
}

const EditionNavbar = ({ openDynamicModal }: NavbarProps) => {
  const dispatch = useDispatch()
  const datePublished = useSelector(getLastPublished)
  const isEditMode = useSelector(getEditModeEnabled)
  const panel = useSelector(getPanel)
  const isLoggedIn = useSelector<boolean>(userIsLoggedIn)
  const isBrandAccount = useSelector<boolean>(isAccountTypeBrand)
  const brandId = useSelector(getAccountId)
  const showBrandNavigation = useSelector(getBrandNavigation)
  const storefrontHasChanges = useSelector(hasChanges)
  const isUnderReferenceLarge = useScreenSize(1023)
  const isUnderReferenceMedium = useScreenSize(767)
  const isUnderReferenceSmall = useScreenSize(639)
  const inMenu: PageElement[] = useSelector(getInMenuPages)
  const isLastOnline: boolean = useSelector(isLastOnlinePage)
  const onlinePagesInMenu = inMenu.filter((page) => page.isOnline).length
  const existsHomePage: boolean = useSelector(getExistsHomePage)
  const { textStyles: backupTextStyles } = useSelector(getBackupPanel)
  const [openModal, setOpenModal] = useState<string>('')
  const [selectedElement, setSelectedElement] = useState(-1)
  const { renderSuccessToast, renderErrorToast } = useRenderToasters()

  const { loading: saveLoading, upsertStorefront } = useUpsertStorefront()
  const { bulkUpdate } = useBulkUpdateStorefront()
  const {
    refetch: refetchStorefrontNavigation,
    loading: loadingNavigation,
  } = useStorefrontNavigation(isLoggedIn, isBrandAccount, brandId)
  const disabled = saveLoading

  const viewOptions: Option[] = [
    {
      iconName: 'circle',
      label: isUnderReferenceSmall ? '' : 'Online',
      value: ONLINE,
    },
    {
      iconName: 'circle',
      label: isUnderReferenceSmall ? '' : 'Offline',
      value: OFFLINE,
    },
  ]

  const viewOptionDefaultValue = panel?.isOnline
    ? viewOptions[0]
    : viewOptions[1]

  const handleStatusChange = async (): Promise<unknown> => {
    if (
      panel.orderNbr > 0 &&
      onlinePagesInMenu === MAX_ONLINE_IN_MENU_PAGES &&
      !panel.isOnline
    ) {
      return renderErrorToast({
        description:
          'Maximum number of 10 online pages in navigation menu already reached.',
      })
    }
    if (panel.isHome) {
      return renderErrorToast({
        description: 'Your homepage cannot be taken offline.',
      })
    }
    if (panel.isOnline && isLastOnline) {
      return renderErrorToast({
        description:
          'Please make sure that at least one of your pages are online in your custom profile before you take this page offline.',
      })
    }
    if (!existsHomePage) return setOpenModal(MODAL_FIRST_HOME)
    try {
      const { data, errors } = await bulkUpdate([
        { id: panel.id, isOnline: !panel.isOnline },
      ])
      if (!data || errors) {
        return renderErrorToast({
          description: 'There was an error changing your page settings.',
        })
      }
      renderSuccessToast({
        description: `${panel.displayName} page has been taken ${
          panel.isOnline ? 'Offline' : 'Online'
        }.`,
      })
    } catch {
      return renderErrorToast({
        description: 'There was an error changing your page settings.',
      })
    }
  }
  const handleOnSaveClick = async (): Promise<unknown> => {
    if (!panel?.rows.length) {
      return renderErrorToast({ description: 'Cannot save empty pages.' })
    }
    try {
      const { data, errors } = await upsertStorefront()
      if (!data || errors) {
        renderErrorToast({
          description: 'There was an error saving your custom profile.',
        })
      } else {
        dispatch({ type: INIT_PANEL, value: data })
        dispatch({ type: TOGGLE_EDIT_MODE })
        renderSuccessToast({
          description: 'Your custom profile has been saved.',
        })
      }
    } catch {
      renderErrorToast({
        description: 'There was an error saving your custom profile.',
      })
    }
  }

  const EditButton = isEditMode ? (
    <>
      <SecondaryButton
        onClick={() => {
          dispatch({ type: RESTORE_PANEL })
          dispatch({ type: TOGGLE_EDIT_MODE })
        }}
        data-testid={DISCARD_BUTTON}
      >
        Discard Changes
      </SecondaryButton>
      <Button
        onClick={handleOnSaveClick}
        disabled={disabled}
        data-testid={SAVE_BUTTON}
      >
        Save Changes
      </Button>
    </>
  ) : isUnderReferenceLarge ? (
    <TooltipContainer>
      <Tooltip
        defaultPlacement="bottom"
        content="Editing options are not available at this browser size. Please expand to edit."
      >
        <Button disabled {...(isUnderReferenceSmall && { iconName: 'edit' })}>
          {isUnderReferenceSmall ? <span /> : 'Edit Page'}
        </Button>
      </Tooltip>
    </TooltipContainer>
  ) : (
    <Button
      id={HeaderIds.EditPageButton}
      onClick={() => {
        dispatch({ type: SET_BRAND_NAVIGATION, value: false })
        dispatch({ type: TOGGLE_EDIT_MODE })
      }}
      data-testid={EDIT_BUTTON}
    >
      Edit Page
    </Button>
  )

  const backToHomeHandler = () => {
    if (storefrontHasChanges) {
      openDynamicModal({
        component: StorefrontSaveChanges,
        redirectTo: ACCOUNTS_HOME,
      })
    } else {
      window.location.href = ACCOUNTS_HOME
    }
  }

  const joorHomeBtn = isUnderReferenceMedium ? (
    <Tooltip defaultPlacement="bottom" content="Back to JOOR Home">
      <SecondaryButton iconName="home" onClick={backToHomeHandler}>
        <span />
      </SecondaryButton>
    </Tooltip>
  ) : (
    <SecondaryButton
      id={HeaderIds.BackToJoorHomeButton}
      onClick={backToHomeHandler}
      data-testid={BACK_HOME_ID}
    >
      Back to JOOR Home
    </SecondaryButton>
  )

  enum ElementIds {
    Pages,
    BrandDetails,
    TextStyles,
    QR,
  }

  const elements: SidebarElement[] = [
    ...([
      {
        id: ElementIds.Pages,
        iconName: 'pages',
        tooltip: 'Pages',
        content: <Pages />,
      },
      {
        id: ElementIds.BrandDetails,
        iconName: 'details',
        tooltip: 'Brand Details',
        content: <BrandDetails />,
      },
      {
        id: ElementIds.TextStyles,
        iconName: 'text',
        tooltip: 'Text Styles',
        content: <TextSettings />,
      },
      {
        id: ElementIds.QR,
        iconName: 'qr',
        tooltip: 'Profile QR Code',
        content: <QR />,
      },
    ] as SidebarElement[]),
  ]
  const isSectionVisible = isEditMode ? !isUnderReferenceLarge : true
  return (
    <Navbar>
      <LeftContainer>
        <Sidebar
          elements={elements}
          onSidebarElementClick={(): void => {
            dispatch({ type: RESTORE_PROFILE })
            dispatch({
              type: UPDATE_TEXT_STYLES,
              value: backupTextStyles,
            })
          }}
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
        {joorHomeBtn}
        <VerticalSeparator />
        {!isEditMode && (
          <ViewDropdown id={HeaderIds.ViewRetailerOptions}>
            <Dropdown
              variant={isEditMode ? 'DISABLED' : 'DEFAULT'}
              options={viewAsOptions}
              defaultValue={viewAsOptions[0]}
              onSelect={({
                selected: { value },
              }: {
                selected: Option
              }): void => {
                dispatch({ type: VIEW_AS_TARGET, value })
              }}
              hasSelectedOption
            />
          </ViewDropdown>
        )}
        {isSectionVisible && isEditMode && (
          <SecondaryButton
            id={HeaderIds.ShowBrandNavButton}
            onClick={() => {
              dispatch({ type: TOGGLE_BRAND_NAVIGATION })
            }}
          >
            {showBrandNavigation ? 'Hide' : 'Show'} Brand Nav
          </SecondaryButton>
        )}
        {!isSectionVisible && (
          <Message>
            <Icon iconName="exclamation" />
            <span>
              Editing options are not available at this browser size. Please
              expand to edit.
            </span>
          </Message>
        )}
      </LeftContainer>
      {isSectionVisible && (
        <RightContainer>
          <span>
            {`Page ${
              panel?.isOnline ? 'online' : 'offline'
            } since: ${formatTimestamp(datePublished, 'MM/DD/YYYY')} at `}
            {formatTimestamp(datePublished, 'HH:mma')}
          </span>
          <StatusDropdown>
            <Dropdown
              value={viewOptionDefaultValue}
              onSelect={handleStatusChange}
              options={viewOptions}
            />
          </StatusDropdown>
          {EditButton}
        </RightContainer>
      )}
      {openModal && (
        <Modals
          id={panel.id}
          openModal={openModal}
          setOpenModal={setOpenModal}
          name={panel.displayName}
          isHome={panel.isHome}
          loadingNavigation={loadingNavigation}
          refetchStorefrontNavigation={refetchStorefrontNavigation}
        />
      )}
    </Navbar>
  )
}

export default withModal(EditionNavbar)
