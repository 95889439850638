import isEmpty from 'lodash/isEmpty'
import isObject from 'lodash/isObject'
import reduce from 'lodash/reduce'

/**
 * Old behevaior of babel would transform objects
 * into array's when spreading in array.
 * https://github.com/joor/joor-react/pull/3065
 *
 * [...{ hi: 'bye' }] -> []
 *
 * @param {*} input
 */
function transformToArray(input) {
  if (!Array.isArray(input)) {
    return []
  }
  return input
}

// Given a filterKey, returns a filterSetting
export const filterKeyToFilter = (filters, filterKey) =>
  Object.values(filters).find((filter) => filter.filterKey === filterKey)

// Returns the initial state for the reducer
// it accounts for filters with the same 'filterKey'
export const initialFilterReducerState = (filters) =>
  Object.values(filters).reduce((initialState, filterSettings) => {
    if (!isEmpty(filterSettings.defaultState)) {
      return {
        ...initialState,
        [filterSettings.filterKey]: initialState[filterSettings.filterKey]
          ? [
              ...transformToArray(initialState[filterSettings.filterKey]),
              ...transformToArray(filterSettings.defaultState),
            ]
          : filterSettings.defaultState,
      }
    }
    return {
      ...initialState,
      [filterSettings.filterKey]: initialState[filterSettings.filterKey]
        ? [
            ...transformToArray(initialState[filterSettings.filterKey]),
            ...transformToArray(filterSettings.unselectedState),
          ]
        : filterSettings.unselectedState,
    }
  }, {})

// Returns the unselected state for the reducer
// it does not count the quick filters (which do not have a clear all)
export const unselectedFilterReducerState = (filters) =>
  Object.values(filters).reduce((initialState, filterSettings) => {
    if (filterSettings.quick) return initialState
    return {
      ...initialState,
      [filterSettings.filterKey]: initialState[filterSettings.filterKey]
        ? [
            ...transformToArray(initialState[filterSettings.filterKey]),
            ...transformToArray(filterSettings.unselectedState),
          ]
        : filterSettings.unselectedState,
    }
  }, {})

// Translates applied filters to the graphql input variable
export const appliedFiltersToGraphQLVariables = (filters, appliedFilters) =>
  Object.keys(filters).reduce((graphQLFilters, filterName) => {
    const filterType = filters[filterName]
    const value = appliedFilters[filterType.filterKey]
    return ((Array.isArray(value) || isObject(value)) && isEmpty(value)) ||
      !value
      ? graphQLFilters
      : filterType.toGraphQLVariable(graphQLFilters, {
          value,
        })
  }, {})

export const joinFilterValuesByProp = (filters = [], filterBy = 'name') =>
  filters.map((filter) => (filter ? filter[filterBy] : '')).join(', ')

// Returns an array with the selected filters ordered by appearance in the filterSetup
// and with the structure necessary to be rendered by the PillBox
export const getSelectedFiltersInArrayPillFormatted = ({
  filterSetup,
  filters,
  selectedFilters,
  onActionClick,
  dateFormat,
}) =>
  reduce(
    filterSetup,
    (acc, { key }) => {
      const { filterKey, getSelectedFiltersPillFormatted } = filters[key]
      const selectedFilter = selectedFilters[key]
      if (!isEmpty(selectedFilter)) {
        const selectedFiltersPillFormatted = getSelectedFiltersPillFormatted({
          selectedFilters,
          onActionClick: (filterData) =>
            onActionClick({ filterKey, ...filterData }),
          dateFormat,
        })
        return acc.concat(selectedFiltersPillFormatted)
      }
      return acc
    },
    [],
  )

export const getCountAppliedFilters = ({
  filterSetup,
  filters,
  appliedFilters,
}) =>
  reduce(
    filterSetup,
    (acc, { key }) => {
      const appliedFilter = appliedFilters[key]
      const { getCountFilters } = filters[key]

      return acc + getCountFilters(appliedFilter)
    },
    0,
  )
