import { relayConnectionToArray } from './graphql'

export const getBrandConnectedAccounts = (connection) => {
  const connectedAccounts = relayConnectionToArray(connection)
  return connectedAccounts.map((connectedAccount) => {
    const { brandDoorMatches, priceTypeMatches, locations } = connectedAccount
    return {
      ...connectedAccount,
      brandDoorMatches: brandDoorMatches
        ? relayConnectionToArray(brandDoorMatches)
        : [],
      priceTypeMatches: priceTypeMatches
        ? relayConnectionToArray(priceTypeMatches)
        : [],
      locations: locations ? relayConnectionToArray(locations) : [{}],
    }
  })
}
