import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import compose from 'lodash/flowRight'
import { connect } from 'react-redux'

import {
  initTableRow,
  pasteToRow,
  setRowToCopy,
  updateCellValue,
} from 'store/shop/productDetailModal/actions'
import {
  getCellValueFromTable,
  isRowInitialized,
  isValidQuantity,
} from 'store/shop/productDetailModal/selectors'

import { withFormatter } from 'modals/Shop/TableFormatter'

import ProductDetailSizedTable from './ProductDetailSizedTable.component'

const mapStateToProps = (state) => ({
  getCellValueFromTable: getCellValueFromTable(state),
  isValidQuantity: isValidQuantity(state),
  isRowInitialized: isRowInitialized(state),
})

const mapDispatchToProps = {
  updateCellValue,
  initTableRow,
  setRowToCopy,
  pasteToRow,
}

const withRedux = connect(mapStateToProps, mapDispatchToProps)

const withProductDetailSizedTableFlagsWrapper = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { skuDeliveries, localizeUserData } = useFlags()

    return (
      <WrappedComponent
        {...props}
        flags={{
          skuDeliveries,
          localizeUserData,
        }}
      />
    )
  }

  return Wrapper
}

export default compose(
  withRedux,
  withProductDetailSizedTableFlagsWrapper,
  withFormatter,
)(ProductDetailSizedTable)
