import filter from 'lodash/filter'
import map from 'lodash/map'

export class MultiSelect {
  constructor(drilldown) {
    this.drilldown = drilldown
  }

  onClick(e, tierItem, level, index) {
    const alreadyClicked = this.drilldown.isSelected(level, index)

    if (alreadyClicked && this.drilldown.hasChildren(tierItem)) {
      this.drilldown.expandNextTier(level)
    } else {
      this.setSelection(index, level, tierItem)
    }
  }

  onClose() {
    const selectedLeafNodes = this.selectedLeafNodes()
    if (selectedLeafNodes.length && this.drilldown.hasSelectionsChanged()) {
      const finalSelection = map(selectedLeafNodes, (node) => node.label).join(
        ', ',
      )
      this.drilldown.handleChange(
        [...this.drilldown.state.selectionsByTier],
        finalSelection,
        selectedLeafNodes,
        {},
      )
    }
  }

  selectedLeafNodes = () => {
    const lastTierIndex = this.drilldown.state.selectionsByTier.length - 1
    const selectedIndexes = this.drilldown.state.selectionsByTier[lastTierIndex]
    const dataInTier = this.drilldown.dataInFinalTier()

    const allNodesInFinalTier = map(
      selectedIndexes,
      (index) => dataInTier[index],
    )
    return filter(allNodesInFinalTier, (node) => !node.children)
  }

  setSelection = (index, level, tierItem) => {
    const isEndNode = !this.drilldown.hasChildren(tierItem)

    if (isEndNode) {
      const selectionsByTier = this.setSelectionInMultiSelectTier(index, level)
      this.drilldown.setState({ selectionsByTier })
    } else {
      const selectionsByTier = this.drilldown.changeSelectionAtLevel(
        level,
        index,
      )
      this.drilldown.addSingleSelectionAndExpand(selectionsByTier)
    }
  }

  setSelectionInMultiSelectTier = (index, level) => {
    let selectionsByTier = [...this.drilldown.state.selectionsByTier]

    if (selectionsByTier[level] && selectionsByTier[level].includes(index)) {
      const myIndex = selectionsByTier[level].indexOf(index)
      selectionsByTier[level].splice(myIndex, 1)
      if (selectionsByTier[level].length === 0) {
        selectionsByTier = selectionsByTier.splice(0, level)
      }
    } else if (selectionsByTier[level]) {
      selectionsByTier[level].push(index)
      selectionsByTier[level] = selectionsByTier[level].sort()
    } else {
      selectionsByTier[level] = [index]
    }
    return selectionsByTier
  }
}
