import { Dispatch, SetStateAction } from 'react'

import { ExportTemplates } from './exportModal.constants'

export type UseStateSetter<T> = Dispatch<SetStateAction<T>>

export enum FileFormats {
  CSV = 'csv',
  Excel = 'excel',
  PDF = 'pdf',
  OrderExcel = 'orderExcel',
}

export enum ExportType {
  Standard = 'standard',
  Alternative = 'alternative',
}

export enum StyleDataLevel {
  Color = 'color',
  SKU = 'sku',
}

export enum ImageResolution {
  Normal = 'normal',
  High = 'high',
}

export enum OversoldType {
  NegativeNumbers = 'negative-numbers',
  SoldOut = 'sold-out',
}

export type CsvDataType = {
  hasNordstromPermission: boolean
  exportType: ExportType
  priceTypeId: string
  styleDataLevel: StyleDataLevel
  nordstromPriceTypeIds: string[]
}

export type ExcelDataType = {
  hasNordstromPermission: boolean
  exportType: ExportType
  priceTypeId: string
  styleDataLevel: StyleDataLevel
  nordstromPriceTypeIds: string[]
}

export type PdfDataType = {
  style: ExportTemplates
  priceTypeId: string
  printRetailPrice: boolean
  printWholesalePrice: boolean
  customField01: string
  customField02: string
  customField03: string
  groupBy: string[]
  pageBreakByGroup: boolean
  overflowColors: boolean
  showTagNames: boolean
  imageResolution: ImageResolution
  oversoldType: OversoldType
  warehouse_ids: string[]
  dates: string[]
  mergeLinesheets: boolean
  Badges: string[]
  smallerSwatches: boolean
  groupFutureInventoryDates: boolean
  hideSoldOut: boolean
}

export type OrderExcelDataType = {
  designerId: string
  doorIds: string[]
  includeImages: boolean
  linesheetIds: string[]
  priceTypeId: string
}
