import get from 'lodash/get'
import PropTypes from 'prop-types'

import getDisplayName from 'utils/getDisplayName'
import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

export const getBrandIdFromHistory = (history) => {
  const {
    location: { search: locationSearch },
  } = history
  const brandIdMatch = locationSearch.match(/brandId=(\d+)/)
  return brandIdMatch && brandIdMatch[1]
}
export const getAccounts = (props) => {
  const {
    isBrandUser,
    history,
    retailerAccount,
    accountId,
    brand,
    brandAccount,
    orderForRetailerId,
  } = props

  let retailerId
  let brandIdFormatted

  if (isBrandUser) {
    const plainRetailerId = retailerAccount
      ? fromGlobalId(retailerAccount.id).id
      : orderForRetailerId

    retailerId = plainRetailerId ? toGlobalId('Retailer', plainRetailerId) : ''
    brandIdFormatted = toGlobalId('Brand', accountId)
  } else {
    const brandIdFromHistory = getBrandIdFromHistory(history)

    if (brandAccount) {
      brandIdFormatted = brandAccount.id
    } else if (brandIdFromHistory) {
      brandIdFormatted = toGlobalId('Brand', brandIdFromHistory)
    } else {
      brandIdFormatted = toGlobalId('Brand', fromGlobalId(get(brand, 'id')).id)
    }
    retailerId = toGlobalId('Retailer', accountId)
  }
  return {
    retailerAccount: {
      ...retailerAccount,
      id: retailerId,
    },
    brandAccount: {
      id: brandIdFormatted,
    },
  }
}

export const withAccountsFormatter = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { retailerAccount, brandAccount } = getAccounts(props)
    const newProps = {
      ...props,
      retailerAccount,
      brandAccount,
    }
    return <WrappedComponent {...newProps} />
  }

  Wrapper.displayName = `withAccountsFormatter(${getDisplayName(
    WrappedComponent,
  )})`

  Wrapper.propTypes = {
    brandAccount: PropTypes.object,
    retailerAccount: PropTypes.object,
    accountId: PropTypes.number.isRequired,
    isBrandUser: PropTypes.bool.isRequired,
    history: PropTypes.object.isRequired,
    enableQuantifying: PropTypes.bool,
  }
  Wrapper.defaultProps = {
    brandAccount: null,
    retailerAccount: {},
    enableQuantifying: true,
  }
  return Wrapper
}

export default withAccountsFormatter
