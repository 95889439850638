import { useEffect, useState } from 'react'

import { Button, typography } from '@joor/design-system'
import '@joor/design-system/dist/designTokens/variables.css'
import { isEqual } from 'lodash'
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { reorder } from 'utils/array'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { isAccountTypeBrand, userIsLoggedIn } from 'store/currentUser/selectors'
import { INIT_PANEL, TOGGLE_EDIT_MODE } from 'store/storefront/constants'
import {
  getAccountId,
  getEditModeEnabled,
  getInMenuPages,
  getNotInMenuPages,
  getPanel,
  getTotalPages,
  hasPages,
} from 'store/storefront/selectors'

import { STOREFRONT } from 'routes/paths'

import PageItem from './PageItem'
import {
  MAX_ONLINE_IN_MENU_PAGES,
  MAX_PAGES,
  PAGES_SECTION_IN_MENU_LIST,
  PAGES_SECTION_NOT_IN_MENU_LIST,
} from './Pages.constants'
import { useBulkUpdateStorefront } from './Pages.hooks'
import { NewPage, PageElement, SaveOrder } from './Pages.types'
import {
  calculateNewOrder,
  getDefaultPageName,
  getNewPageOrderNbr,
} from './Pages.utils'
import { useStorefrontNavigation } from 'features/Storefront/Storefront.hooks'
import { useUpsertStorefront } from 'features/Storefront/StorefrontNavbar/StorefrontNavbar.hooks'

export const CREATE_ID = 'storefront_create_page'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 28px;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  > button {
    margin: 24px auto 16px;
    padding: 15px 10px;
  }
`
const DroppableContainer = styled.div`
  min-height: 10px;
`
const Title = styled.span`
  height: 60px;
  line-height: 60px;
  margin-left: 24px;
  font-size: 36px !important;
  ${typography.alpha.primaryActive.headline1};
`
const Subtitle = styled.span`
  margin-left: 24px;
  ${typography.alpha.primaryActive.body1};
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--color-primary-200);
  margin-bottom: 16px;
`

const PageManagement = styled.div`
  display: flex;
  flex-direction: column;
  h3 {
    ${typography.alpha.primaryActive.body2};
    padding-left: 24px;
    &:last-of-type {
      margin-top: 24px;
    }
  }
  > button {
    margin: 24px auto 16px;
  }
`
const Description = styled.p`
  ${typography.alpha.primaryActive.caption};
  padding: 0 8px 0 24px;
`

const Pages = () => {
  const inMenu: PageElement[] = useSelector(getInMenuPages)
  const notInMenu: PageElement[] = useSelector(getNotInMenuPages)
  const hasStorefrontPages = useSelector<boolean>(hasPages)
  const panel = useSelector(getPanel)
  const editMode = useSelector(getEditModeEnabled)
  const totalPages = useSelector(getTotalPages)
  const isLoggedIn = useSelector<boolean>(userIsLoggedIn)
  const isBrandAccount = useSelector<boolean>(isAccountTypeBrand)
  const brandId = useSelector(getAccountId)
  const [orderedInMenu, setOrderedInMenu] = useState(inMenu)
  const [orderedNotInMenu, setOrderedNotInMenu] = useState(notInMenu)
  const onlineInMenu =
    inMenu.filter((page) => page.isOnline).length >= MAX_ONLINE_IN_MENU_PAGES
  const [inMenuLimit, setInMenuLimit] = useState(onlineInMenu)
  const history = useHistory()
  const { renderErrorToast, renderSuccessToast } = useRenderToasters()
  const dispatch = useDispatch()

  const {
    refetch: refetchStorefrontNavigation,
    loading: loadingNavigation,
  } = useStorefrontNavigation(isLoggedIn, isBrandAccount, brandId)
  const {
    loading: loadingNewPage,
    upsertStorefront: createNewPage,
  } = useUpsertStorefront()
  const { bulkUpdate } = useBulkUpdateStorefront()

  useEffect(() => {
    setOrderedInMenu(inMenu)
  }, [inMenu])

  useEffect(() => {
    setInMenuLimit(onlineInMenu)
  }, [inMenu])

  useEffect(() => {
    setOrderedNotInMenu(notInMenu)
  }, [notInMenu])

  const buttonCreateNewPageDisabled = loadingNavigation || loadingNewPage

  const handleOnCreateNewPageClick = async () => {
    if (totalPages === MAX_PAGES) {
      return renderErrorToast({
        description:
          'Maximum number of 25 pages already reached. Please delete an existing page to create a new one.',
      })
    }

    const {
      displayName: nextDisplayName,
      uniqueName: nextUniqueName,
    } = getDefaultPageName([...orderedInMenu, ...orderedNotInMenu])
    const newPageConfig: NewPage = {
      isHome: !hasStorefrontPages,
      orderNbr: getNewPageOrderNbr([...orderedInMenu, ...orderedNotInMenu]),
      displayName: nextDisplayName,
      uniqueName: nextUniqueName,
      isOnline: false,
      isNewPage: true,
    }

    try {
      const result = await createNewPage(newPageConfig)
      if (!result?.data || result?.errors) {
        return renderErrorToast({
          description: 'There was an error creating your new page',
        })
      }

      await refetchStorefrontNavigation()
      const {
        id,
        isHome,
        orderNbr,
        displayName,
        uniqueName,
        isOnline,
        brandId: brandIdFromResponse,
      } = result?.data?.upsertStorefront

      dispatch({
        type: INIT_PANEL,
        value: {
          ...panel,
          id,
          isHome,
          orderNbr,
          displayName,
          uniqueName,
          isOnline,
          brandId: brandIdFromResponse,
        },
      })
      history.push(
        generatePath(STOREFRONT, {
          accountId: brandId,
          pageName: uniqueName,
        }),
      )
      renderSuccessToast({ description: 'Page created.' })
    } catch {
      renderErrorToast({
        description: 'There was an error creating your new page',
      })
    }
  }
  const saveOrder: SaveOrder = async (
    isInMenu,
    firstBlockPages,
    differentBlocks = false,
    secondBlockPages,
  ) => {
    let bulkUpdateInput
    const formatInput = (p: PageElement) => {
      const { id, orderNbr } = p
      return { id, orderNbr }
    }
    if (differentBlocks) {
      const newInMenuOrder = calculateNewOrder(firstBlockPages, true)
      const newNotInMenuOrder = calculateNewOrder(
        secondBlockPages as PageElement[],
        false,
      )
      bulkUpdateInput = [
        ...newInMenuOrder.map(formatInput),
        ...newNotInMenuOrder.map(formatInput),
      ]
    } else {
      const newInMenuOrder = calculateNewOrder(firstBlockPages, isInMenu)
      bulkUpdateInput = [...newInMenuOrder.map(formatInput)]
    }
    try {
      const { data, errors } = await bulkUpdate(bulkUpdateInput)
      if (!data || errors) {
        renderErrorToast({
          description: 'There was an error updating the order of your pages.',
        })
      }
    } catch {
      renderErrorToast({
        description: 'There was an error updating the order of your pages.',
      })
    }
  }
  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const { source, destination } = result
    if (destination.droppableId === source.droppableId) {
      let items, setter, isInMenu
      if (source.droppableId === 'inMenu') {
        items = orderedInMenu
        setter = setOrderedInMenu
        isInMenu = true
      } else {
        items = orderedNotInMenu
        setter = setOrderedNotInMenu
        isInMenu = false
      }
      const ordered = reorder(items, source.index, destination.index)
      setter(ordered)
      if (
        !(isEqual(ordered, orderedInMenu) || isEqual(ordered, orderedNotInMenu))
      ) {
        await saveOrder(isInMenu, ordered, false)
      }
    } else {
      const originIsMenu = source.droppableId === 'inMenu'
      const sourceItems = Array.from(
        originIsMenu ? orderedInMenu : orderedNotInMenu,
      )
      const destinationItems: PageElement[] = Array.from(
        originIsMenu ? orderedNotInMenu : orderedInMenu,
      )
      const [removed] = sourceItems.splice(source.index, 1)
      destinationItems.splice(destination.index, 0, removed)

      if (originIsMenu) {
        setOrderedInMenu(sourceItems)
        setOrderedNotInMenu(destinationItems)
        await saveOrder(originIsMenu, sourceItems, true, destinationItems)
      } else {
        if (inMenuLimit && orderedNotInMenu[source.index].isOnline) {
          renderErrorToast({
            description: 'There is a limit of 10 online pages in Menu.',
          })
        } else {
          setOrderedNotInMenu(sourceItems)
          setOrderedInMenu(destinationItems)
          await saveOrder(originIsMenu, destinationItems, true, sourceItems)
        }
      }
    }
  }

  return (
    <PageContainer>
      <Title>Pages</Title>
      <Subtitle>Manage the pages in you profile</Subtitle>
      <Button
        disabled={buttonCreateNewPageDisabled}
        onClick={handleOnCreateNewPageClick}
        iconName="add"
        data-testid={CREATE_ID}
      >
        Create New Page
      </Button>
      <Separator />
      <PageManagement>
        <DragDropContext onDragEnd={onDragEnd}>
          <h3>In Menu</h3>
          <Droppable droppableId="inMenu">
            {(provided) => (
              <DroppableContainer
                data-testid={PAGES_SECTION_IN_MENU_LIST}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {orderedInMenu.map((item, index) => (
                  <PageItem
                    data-testid={`storefront_in_menu_item_${item.uniqueName}`}
                    onClick={() => {
                      if (item.id === panel.id) return
                      if (editMode) dispatch({ type: TOGGLE_EDIT_MODE })
                      history.push(
                        generatePath(STOREFRONT, {
                          accountId: brandId,
                          ...(!item.isHome && { pageName: item.uniqueName }),
                        }),
                      )
                    }}
                    {...item}
                    key={item.id}
                    index={index}
                    loadingNavigation={loadingNavigation}
                    refetchStorefrontNavigation={refetchStorefrontNavigation}
                  />
                ))}
                {provided.placeholder}
              </DroppableContainer>
            )}
          </Droppable>
          <h3>Not In Menu</h3>
          <Description>
            These pages are hidden from the navigation and can be linked to
            images and/or text in your profile.
          </Description>
          <Droppable droppableId="notInMenu">
            {(provided) => (
              <DroppableContainer
                data-testid={PAGES_SECTION_NOT_IN_MENU_LIST}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {orderedNotInMenu.map((item, index) => (
                  <PageItem
                    onClick={() => {
                      if (item.id === panel.id) return
                      if (editMode) dispatch({ type: TOGGLE_EDIT_MODE })
                      history.push(
                        generatePath(STOREFRONT, {
                          accountId: brandId,
                          ...(!item.isHome && { pageName: item.uniqueName }),
                        }),
                      )
                    }}
                    {...item}
                    key={item.id}
                    index={index}
                    loadingNavigation={loadingNavigation}
                    refetchStorefrontNavigation={refetchStorefrontNavigation}
                    data-testid={`storefront_not_in_menu_item_${item.uniqueName}`}
                  />
                ))}
                {provided.placeholder}
              </DroppableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </PageManagement>
    </PageContainer>
  )
}

export default Pages
