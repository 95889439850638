import { FunctionComponent, LazyExoticComponent } from 'react'

import * as flags from './flags'
import * as lazy from './lazy'
import * as paths from './paths'

export type RouteType = {
  path: string
  component: FunctionComponent | LazyExoticComponent<any>
  title?: string
  feature?: string
}

export const PRIVATE_ROUTES: RouteType[] = [
  {
    path: paths.MOVE_PRODUCTS_PRODUCTS_SELECTION,
    component: lazy.SelectProductsPage,
    title: 'Select Products',
  },
  {
    path: paths.MOVE_PRODUCTS_PO_SELECTION,
    component: lazy.MergeOrdersPage,
    title: 'Move Products',
  },
  {
    path: paths.CONNECTION,
    component: lazy.ConnectionPage,
    title: 'Connection',
    feature: flags.NEW_CONNECTIONS_UI,
  },
  {
    path: paths.ASSORTMENT_SIZING,
    component: lazy.AssortmentSizingPage,
  },
  {
    path: paths.DATA_SELF_SERVE,
    component: lazy.DataSelfServePage,
    title: 'Self-serve Data Configuration',
    feature: flags.RETAIL_INTEGRATION_SETTINGS,
  },
  {
    path: paths.SSO_SETTINGS,
    component: lazy.SsoSettingsPage,
    title: 'SSO Settings',
    feature: flags.SSO_SETTINGS,
  },
  {
    path: paths.ASSORTMENT_REVIEW_UNITS,
    component: lazy.AssortmentReviewPage,
    feature: flags.ASSORTMENT_REVIEW_FLAG,
  },
  {
    path: paths.ASSORTMENT_REVIEW,
    component: lazy.AssortmentReviewPage,
    feature: flags.ASSORTMENT_REVIEW_FLAG,
  },
  {
    path: paths.ASSORTMENT_REVIEW_EDIT_STYLES,
    component: lazy.AssortmentReviewPage,
    feature: flags.ASSORTMENT_REVIEW_FLAG,
  },
  {
    path: paths.ASSORTMENT_REVIEW_EDIT_ATTRIBUTES,
    component: lazy.AssortmentReviewPage,
    feature: flags.ASSORTMENT_REVIEW_FLAG,
  },
  {
    path: paths.WORKSPACE_LIST,
    component: lazy.WorkspaceListPage,
    feature: flags.WORKSPACE_LIST_PAGE,
  },
  {
    path: paths.ASSORTMENT_LIST,
    component: lazy.AssortmentListPage,
  },
  {
    path: paths.ORDER_LIST,
    component: lazy.OrderListPage,
    feature: flags.ORDER_LIST_FLAG,
  },
  {
    path: paths.CANCEL_STYLE,
    component: lazy.CancelStylePage,
  },
  {
    path: paths.ADMIN_INVITE_USERS,
    component: lazy.InviteUsersPage,
    title: 'Invite Users',
  },
  {
    path: paths.ACCOUNTS_HOME,
    component: lazy.BrandDashboardPage,
    feature: flags.BRAND_DASHBOARD,
  },
  {
    path: paths.HOME,
    component: lazy.RetailDashboard,
  },
  {
    path: paths.BRAND_PROFILE,
    component: lazy.BrandProfilePage,
  },
  {
    path: paths.IMPORT_STYLES,
    component: lazy.ImportStylesPage,
    feature: flags.SELF_SERVE,
  },
  {
    path: paths.ATTRIBUTE_SETTINGS,
    component: lazy.AttributeSettingsPage,
  },
  {
    path: paths.PRODUCT_CATALOG,
    component: lazy.ProductCatalogPage,
    title: 'Catalog',
  },
  {
    path: paths.PAYMENT_SETTINGS,
    component: lazy.PaymentSettingsPage,
    feature: flags.BRAND_PAYMENTS_SETTINGS,
    title: 'Payments',
  },
  {
    path: paths.PERMISSIONS_SETTINGS,
    component: lazy.PermissionsSettingsPage,
    feature: flags.SELF_SERVE_PERMISSIONING,
    title: 'Permissions',
  },
  {
    path: paths.MANAGE_PERMISSIONS,
    component: lazy.ManagePermissionsPage,
    title: 'Manage Permissions',
    feature: flags.ENABLE_MANAGE_PERMISSIONS,
  },
  {
    path: paths.MANAGE_PERMISSIONS_CONFIGURATION_CREATE,
    component: lazy.ManagePermissionsConfigurationPage,
    title: 'Manage Permissions',
    feature: flags.ENABLE_MANAGE_PERMISSIONS,
  },
  {
    path: paths.MANAGE_PERMISSIONS_CONFIGURATION_EDIT,
    component: lazy.ManagePermissionsConfigurationPage,
    title: 'Manage Permissions',
    feature: flags.ENABLE_MANAGE_PERMISSIONS,
  },
  {
    path: paths.MANAGE_USER_PERMISSIONS,
    component: lazy.UserPermissionsPage,
    title: 'User Permissions',
    feature: flags.ENABLE_MANAGE_PERMISSIONS,
  },
  {
    path: paths.PREMIUM_GROUP,
    component: lazy.PremiumGroupPage,
    title: 'Premium Group',
  },
  {
    path: paths.NEW_ORDER_REVIEW,
    component: lazy.NewOrderReviewPage,
    title: 'Order Review',
    feature: flags.ORDER_REVIEW_2023,
  },
  {
    path: paths.MANAGE_REGIONS,
    component: lazy.ManageRegionsPage,
    feature: flags.SELF_SERVE_PERMISSIONING,
    title: 'Manage regions',
  },
  {
    path: paths.PASSPORT_QUICK_CONNECT,
    component: lazy.PassportQuickConnect,
    title: 'Passport Quick Connect',
  },
  {
    path: paths.DISCOVERY_DASHBOARD,
    component: lazy.DiscoveryDashboard,
    title: 'Discovery Dashboard',
  },
  {
    path: paths.NEW_SALES_SUMMARY,
    component: lazy.SalesSummaryPage,
    feature: flags.BRAND_REPORTING,
    title: 'Sales Summary',
  },
  {
    path: paths.NEW_STYLE_DETAIL,
    component: lazy.StyleDetailPage,
    feature: flags.BRAND_REPORTING,
    title: 'Style Detail',
  },
  {
    path: paths.NEW_PRODUCTION,
    component: lazy.ProductionPage,
    feature: flags.BRAND_REPORTING,
    title: 'Production',
  },
  {
    path: paths.DELIVERY_REPORT,
    component: lazy.DeliveryReportPage,
    title: 'Delivery Report',
  },
  {
    path: paths.RETAIL_SALES,
    component: lazy.RetailSalesPage,
    title: 'Retail Sales',
  },
  {
    path: paths.CUSTOM_REPORTS,
    component: lazy.CustomReportsPage,
    feature: flags.REPORTING_CONFIGURATIONS,
    title: 'Custom Reports',
  },
  {
    path: paths.CUSTOM_REPORT,
    component: lazy.CustomReportPage,
    feature: flags.REPORTING_CONFIGURATIONS,
    title: 'Custom Report',
  },
  {
    path: paths.ANALYTICS_DASHBOARD,
    component: lazy.AnalyticsDashboardPage,
    feature: flags.ANALYTICS_DASHBOARD,
    title: 'Dashboard',
  },
  {
    path: paths.ANALYTICS_ENGAGEMENT_CONNECTIONS_DASHBOARD,
    component: lazy.AnalyticsEngagementConnectionsPage,
    feature: flags.ANALYTICS_DASHBOARD,
    title: 'Dashboard',
  },
  {
    path: paths.ANALYTICS_JOOR_PAY_REPORT_DASHBOARD,
    component: lazy.AnalyticsJoorPayReportPage,
    feature: flags.JOOR_PAY_ANALYTICS,
    title: 'JOOR Pay Report Dashboard',
  },
  {
    path: paths.SHOWROOM_TEMPLATE_LIST,
    component: lazy.ShowroomTemplateListPage,
    title: 'The Edit - Templates',
    feature: flags.VIRTUAL_SHOWROOM,
  },
  {
    path: paths.SHOWROOM_COLLECTION_ADD,
    component: lazy.ShowroomCollectionPage,
    title: 'The Edit - Collection',
    feature: flags.VIRTUAL_SHOWROOM,
  },
  {
    path: paths.SHOWROOM_COLLECTION_VIEW,
    component: lazy.ShowroomCollectionPage,
    title: 'The Edit - Collection',
    feature: flags.VIRTUAL_SHOWROOM,
  },
  {
    path: paths.SHOWROOM_COLLECTION_LIST,
    component: lazy.ShowroomCollectionListPage,
    title: 'The Edit - Collections',
    feature: flags.VIRTUAL_SHOWROOM,
  },
  {
    path: paths.SHOWROOM_STYLEBOARD_ADD,
    component: lazy.ShowroomStyleboardPage,
    title: 'The Edit - Styleboard',
    feature: flags.VIRTUAL_SHOWROOM,
  },
  {
    path: paths.SHOWROOM_STYLEBOARD_VIEW,
    component: lazy.ShowroomStyleboardPage,
    title: 'The Edit - Styleboard',
  },
  {
    path: paths.SHOWROOM_STYLEBOARD_LIST,
    component: lazy.ShowroomStyleboardListPage,
    title: 'The Edit - Styleboards',
    feature: flags.VIRTUAL_SHOWROOM,
  },
  {
    path: paths.SHOWROOM_MANAGEMENT,
    component: lazy.ShowroomManagementPage,
    title: 'Manage Showrooms',
    feature: flags.VIRTUAL_SHOWROOM,
  },
  {
    path: paths.STOREFRONT,
    component: lazy.StorefrontPage,
    title: 'Profile',
  },
  {
    path: paths.THIRD_PARTY_SETTINGS,
    component: lazy.ThirdPartySettingsPage,
    title: 'ThirdPartySettingsPage',
    feature: flags.VNTANA,
  },
  {
    path: paths.EXTERNAL_PAYMENTS,
    component: lazy.ExternalPaymentsPage,
    title: 'JOOR Pay External Payments',
  },
  {
    path: paths.LINESHEETS,
    component: lazy.LinesheetsListViewPage,
    title: 'Linesheets',
  },
  {
    path: paths.LINESHEET_DETAIL,
    component: lazy.LinesheetsDetailViewPage,
    title: 'Linesheet Detail',
  },
  {
    path: paths.DISCOVER_BRANDS,
    component: lazy.DiscoverBrands,
    title: 'Discover Brands',
  },
  {
    path: paths.IN_ORDER_ALLOCATION_REPORT,
    component: lazy.InOrderReportPage,
    title: 'Allocation Report',
  },
  {
    path: paths.IN_ORDER_BUDGET_REPORT,
    component: lazy.InOrderReportPage,
    title: 'Budget Report',
    feature: flags.BUDGET_REPORT,
  },

  {
    path: paths.EXPORT_TEMPLATES,
    component: lazy.IntegrationSettingsPage,
    title: 'Export Settings',
    feature: flags.CUSTOM_EXPORT_SETTINGS,
  },
  {
    path: paths.LIST_JOBS,
    component: lazy.IntegrationSettingsPage,
    title: 'List Jobs',
    feature: flags.FLEXIBLE_ORDER_IMPORT_MODAL,
  },
  {
    path: paths.RETAILER_PROFILE,
    component: lazy.RetailerProfilePage,
    title: 'Retailer Profile',
  },
  {
    path: paths.NEW_STYLES,
    component: lazy.StylesPage,
    title: 'Styles',
  },
  {
    path: paths.FIND_NEW_RETAILERS,
    component: lazy.FindNewRetailersPage,
    title: 'Find New Retailers',
    feature: flags.FIND_NEW_RETAILERS_FLAG,
  },
  {
    path: paths.SUBMISSIONS_CONTACT,
    component: lazy.SubmissionsContactPage,
    title: 'Submission',
    feature: flags.BRAND_SUBMISSIONS_FLAG,
  },
  {
    path: paths.SUBMISSIONS_VIEW,
    component: lazy.SubmissionsViewPage,
    title: 'Submission',
    feature: flags.BRAND_SUBMISSIONS_FLAG,
  },
  {
    path: paths.ZENDESK_SSO_LOGIN,
    component: lazy.ZendeskSSOLoginPage,
    title: 'Zendesk SSO Login',
  },
]

export const PUBLIC_ROUTES: RouteType[] = [
  {
    path: paths.PUBLIC_BRAND_PROFILE,
    component: lazy.PublicBrandProfilePage,
  },
  {
    path: paths.PUBLIC_PRODUCT_CATALOG,
    component: lazy.PublicProductCatalogPage,
    title: 'Catalog',
  },
  {
    path: paths.PUBLIC_INVOICE,
    component: lazy.PublicInvoicePage,
    title: 'Invoice',
  },
  {
    path: paths.PASSPORT_LANDING,
    component: lazy.PassportLandingPage,
    title: 'Passport',
  },
  {
    path: paths.PASSPORT_FAVORITES,
    component: lazy.PassportFavoritesPage,
    title: 'Passport Favorites',
  },
  {
    path: paths.PASSPORT_EVENT,
    component: lazy.PassportEventLandingPage,
    title: 'Passport Event',
  },
  {
    path: paths.PASSPORT_SHOWROOM,
    component: lazy.PassportShowroomPage,
    title: 'Passport Showroom',
  },
  {
    path: paths.INTEGRATION_SETTINGS_PAGE,
    component: lazy.IntegrationSettingsPage,
    title: 'Integration Settings',
  },
  {
    path: paths.SHOWROOM_PUBLIC_COLLECTION_VIEW,
    component: lazy.ShowroomCollectionPage,
    title: 'The Edit - Collection',
    feature: flags.VIRTUAL_SHOWROOM,
  },
  {
    path: paths.PUBLIC_STOREFRONT,
    component: lazy.StorefrontPage,
    title: 'Profile',
  },
  // TODO(CLEANUP): REMOVE THIS PATH
  {
    path: paths.PUBLIC_INSTAGRAM_LANDING,
    component: lazy.InstagramLandingPage,
    title: 'JOOR',
  },
  {
    path: paths.PUBLIC_LINESHEET_DETAIL,
    component: lazy.LinesheetsDetailViewPage,
    title: 'Linesheet Detail',
  },
  {
    path: paths.PUBLIC_PAYMENTS_PAGE,
    component: lazy.PublicPaymentsPage,
    title: 'Pay Now',
  },
]
export const DEFAULT_ROUTES: RouteType[] = [
  // Routes are matched in sequence; put any new /r/ or /ra/ paths
  // before the catch-all error paths, or they will never be reached
  {
    path: paths.PUBLIC_NOT_FOUND,
    component: lazy.ErrorPage,
    title: 'Page Not Found',
  },
  {
    path: paths.AUTHENTICATED_NOT_FOUND,
    component: lazy.ErrorPage,
    title: 'Page Not Found',
  },
]
