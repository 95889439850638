import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import Truncate from 'react-truncate'

import Tooltip from 'components/Core/Tooltip/Tooltip'

class TruncateWithTooltip extends Component {
  state = { isTruncated: false }

  handleTruncate = (isTruncated) => {
    if (!this.state.isTruncated && isTruncated) {
      this.setState({ isTruncated: true })
    }
  }

  render() {
    const { children, className, lines, noTranslate, tooltipProps } = this.props

    const contents = (
      <Truncate lines={lines} onTruncate={this.handleTruncate}>
        {children}
      </Truncate>
    )
    const contentsWithToolTip = (
      <Tooltip
        trigger={contents}
        content={children}
        className={noTranslate ? 'notranslate' : ''}
        {...tooltipProps}
      />
    )

    return (
      <div className={classNames(className, { notranslate: noTranslate })}>
        {this.state.isTruncated ? contentsWithToolTip : contents}
      </div>
    )
  }
}

TruncateWithTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  className: PropTypes.string,
  lines: PropTypes.number,
  noTranslate: PropTypes.bool,
  tooltipProps: PropTypes.object,
}
TruncateWithTooltip.defaultProps = {
  className: '',
  lines: 2,
  noTranslate: false,
  tooltipProps: {},
}

export default TruncateWithTooltip
