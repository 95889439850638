export class HoverableSelect {
  constructor(drilldown) {
    this.drilldown = drilldown
  }

  applySelection = (e, tierItem, selectionsByTier) => {
    this.drilldown.handleChange(selectionsByTier, tierItem.label, tierItem, e)
    this.drilldown.close()
  }

  onMouseEnter = (e, tierItem, level, index) => {
    if (this.drilldown.hasChildren(tierItem)) {
      this.setSelection(e, tierItem, level, index)
    }
  }

  onClick(e, tierItem, level, index) {
    const selectionsByTier = this.drilldown.changeSelectionAtLevel(level, index)
    this.drilldown.setState({ selectionsByTier })
    this.applySelection(e, tierItem, selectionsByTier)
  }

  onClose = () => {}

  setSelection = (e, tierItem, level, index) => {
    const selectionsByTier = this.drilldown.changeSelectionAtLevel(level, index)
    this.drilldown.addSingleSelectionAndExpand(selectionsByTier)

    if (!this.drilldown.hasChildren(tierItem)) {
      this.applySelection(e, tierItem, selectionsByTier)
    }
  }
}
