import get from 'lodash/get'
import PropTypes from 'prop-types'

import Toaster from 'components/Core/Toaster/Toaster'

import styles from './NotificationHandler.less'

const NotificationHandler = ({ messages, resetNotification }) => (
  <div className={styles.NotificationHandler}>
    {messages.map((message) => {
      const type = { [message.type]: true }
      const options = get(message, 'options', {})
      const onDismiss = options.isPersistent
        ? null
        : () => resetNotification(message.id)
      return (
        <Toaster key={message.id} onDismiss={onDismiss} {...type} {...options}>
          {message.message}
        </Toaster>
      )
    })}
  </div>
)

NotificationHandler.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      message: PropTypes.node.isRequired,
      type: PropTypes.string,
      options: PropTypes.shape({
        long: PropTypes.bool,
        link: PropTypes.shape({
          pathname: PropTypes.string,
          linkText: PropTypes.string,
          search: PropTypes.string,
          baseURL: PropTypes.string,
          hash: PropTypes.string,
        }),
      }),
      isPersistent: PropTypes.bool,
    }),
  ),
  resetNotification: PropTypes.func,
}

NotificationHandler.defaultProps = {
  messages: [],
  resetNotification: () => {},
}

export default NotificationHandler
