import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'

import createToggleListReducer from 'store/utils/toggleListReducer'

const defaultNavState = {
  navHidden: false,
}

export const defaultState = {
  nav: defaultNavState,
  rows: {
    expandedRows: [],
    allSelected: false,
  },
  items: {
    selectedItems: [],
    allSelected: false,
  },
  bulk: {
    bulkCopyItem: null,
    bulkCopyAction: null,
  },
  legend: {
    legendHidden: true,
  },
}
const nav = handleActions(
  {
    'NAV/TOGGLE_NAV': (state, action) => ({
      ...state,
      navHidden: action.payload.navHidden,
    }),
  },
  defaultNavState,
)

const bulk = handleActions(
  {
    'BULK/SET_BULK_ACTION': (state, action) => ({
      ...state,
      bulkCopyItem: action.payload.selectedItem,
      bulkCopyAction: action.payload.bulkCopyAction,
    }),
    'BULK/CANCEL_BULK_ACTION': (state) => ({
      ...state,
      bulkCopyItem: null,
      bulkCopyAction: null,
    }),
  },
  defaultState.bulk,
)

const legend = handleActions(
  {
    'LEGEND/TOGGLE_LEGEND': (state, action) => {
      let { legendHidden } = defaultState.legend
      if (
        action.payload.legendHidden === true ||
        action.payload.legendHidden === false
      ) {
        ;({ legendHidden } = action.payload)
      } else if ('legendHidden' in state) {
        legendHidden = state.legendHidden === true
      }
      return {
        ...state,
        legendHidden,
      }
    },
  },
  defaultState.legend,
)

const items = createToggleListReducer('selectedItem')
const rows = createToggleListReducer('expandedRow')
export default combineReducers({ bulk, nav, rows, items, legend })
