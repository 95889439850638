import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import {
  BADGE_CODE,
  BADGE_HEIGHT,
  BADGE_WIDTH,
  getSizedImage,
} from 'utils/images'

import { ORB360_MEDIA_TYPE, VNTANA_MEDIA_TYPE } from 'shop/products/utils'

import {
  Divider,
  HeaderAndValues,
  Icon,
  Image,
  PhotoBreadcrumb,
  Tooltip,
} from 'components/Core'
import { TEXT_BUBBLE } from 'components/Core/Icon/Icon'
import ExternalMediaIndicators from 'components/Core/ProductCard/ExternalMediaIndicators'

import BaseProductCardContext from './BaseProductCard.context'
import styles from './ProductCard.module.scss'
import { ProductPropType } from './propTypes'
import useSwatchImageSelector from './useSwatchImageSelector'

export const setSelectedImage = ({
  color: { images: colorImages = [] },
  images,
  selectedImageIndex,
  setSelectedImageIndex,
}) => {
  const imageIndexFromColor = images.findIndex(
    (image) => image === colorImages[0],
  )
  const newIndex =
    selectedImageIndex !== imageIndexFromColor && imageIndexFromColor !== -1
      ? imageIndexFromColor
      : selectedImageIndex

  setSelectedImageIndex(newIndex)
}
const BaseProductCard = ({
  product: {
    name,
    code,
    comments,
    images = [],
    videos = [],
    externalMedia = [],
    badges,
  },
  className,
  children,
  displayProductCode,
  displayExternalMediaThumbnail,
  displayExternalMediaIndicators,
}) => {
  const { localizeUserData } = useFlags()
  const externalMediaUrls = externalMedia
    .map((ext) => ext.thumbnailUrl)
    .filter((url) => url != undefined)

  const productImages = [
    ...images,
    ...(displayExternalMediaThumbnail ? externalMediaUrls : []),
  ]
  const {
    handleSwatchSelection,
    selectedColorId,
    selectedImageIndex,
    setSelectedImageIndex,
  } = useSwatchImageSelector({
    productImages,
  })
  const badge = get(badges, '[0]', null)
  const commentIcon = (
    <span>
      <Icon name={TEXT_BUBBLE} />
    </span>
  )
  return (
    <BaseProductCardContext.Provider
      value={{ handleSwatchSelection, selectedColorId }}
    >
      <div className={classNames(styles.ProductCard, className)}>
        <PhotoBreadcrumb
          imagePaths={productImages}
          className={classNames(styles.photoBreadCrumb, 'photo-breadcrumb')}
          showArrows
          selectedImageIndex={selectedImageIndex}
          onClick={(index) => setSelectedImageIndex(index)}
        />
        {displayExternalMediaIndicators && (
          <ExternalMediaIndicators
            showVideo={videos?.length > 0}
            showVntana={externalMedia?.some(
              (ex) => ex?.type === VNTANA_MEDIA_TYPE,
            )}
            showOrb360={externalMedia?.some(
              (ex) => ex?.type === ORB360_MEDIA_TYPE,
            )}
          />
        )}
        {badge && (
          <Image
            src={getSizedImage(get(badge, 'image.url', ''), BADGE_CODE)}
            className={styles.badge}
            height={BADGE_HEIGHT}
            width={BADGE_WIDTH}
          />
        )}
        <div className={styles.info}>
          <div
            className={classNames(styles.productNameAndComments, {
              notranslate: !localizeUserData,
            })}
            data-testid={code}
          >
            <HeaderAndValues
              header={name}
              values={[code]}
              className={styles.headerSection}
              headerClassName={styles.header}
              displayOnlyHeader={!displayProductCode}
              translateValues={localizeUserData}
            />
            {comments && (
              <Tooltip
                content={comments}
                trigger={commentIcon}
                position="top center"
              />
            )}
          </div>
          <Divider verticalMargin={10} horizontalMargin={0} />
          {children}
        </div>
      </div>
    </BaseProductCardContext.Provider>
  )
}

BaseProductCard.propTypes = {
  product: ProductPropType,
  children: PropTypes.node,
  className: PropTypes.string,
  displayProductCode: PropTypes.bool,
  displayExternalMediaThumbnail: PropTypes.bool,
  displayExternalMediaIndicators: PropTypes.bool,
}

BaseProductCard.defaultProps = {
  product: {
    code: '',
    swatchesInfo: [],
  },
  children: null,
  className: '',
  displayProductCode: true,
  displayExternalMediaThumbnail: false,
  displayExternalMediaIndicators: false,
}

export default BaseProductCard
