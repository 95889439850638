import { useEffect } from 'react'

import { isEmpty } from 'lodash'

import { TYPE_BRAND } from 'store/currentUser/selectors'

import { CurrentUserInfo } from '../userInfo'

/**
 * Helper for translating text to selected language.
 *
 * We do not need to specify target language because
 * LocalizeJS know how to detect correct language from cookie.
 * Check if Localize object is defined on window object is
 * because without it tests will fail, or we will have to mock
 * it on many places. Also in cases when page fails to load
 * LocalizeJS due to unavailability it should fail gracefully
 * instead of throwing errors in console.
 */
export const translateText = (originalText: string) => {
  if (window.Localize) {
    return window.Localize.translate(originalText)
  }
  return originalText
}

export const defaultLanguages = [{ code: 'en', name: 'English' }]

export const getAvailableLanguages = () => {
  let availableLanguages = defaultLanguages
  if (window.Localize) {
    window.Localize.getAvailableLanguages((err, languages) => {
      if (!err) {
        availableLanguages = languages
      }
    })
  }
  availableLanguages.sort((a, b) => a.name.localeCompare(b.name))
  return availableLanguages
}

export const LANGUAGE_COOKIE = 'ljs-lang'
export const switchLanguage = (languageCode: string) => {
  if (window.Localize && languageCode) {
    window.Localize.setLanguage(languageCode)
  }
  if (languageCode) {
    document.cookie = `${LANGUAGE_COOKIE}=${languageCode};expires=90;path=/;`
  }
}

export const translateIfFeatureFlag = (flag: boolean) =>
  flag ? '' : 'notranslate'

export const useLocalize = (userInfo?: CurrentUserInfo) => {
  useEffect(() => {
    if (isEmpty(userInfo) || !window.Localize) return
    const isBrand = userInfo?.account?.type === TYPE_BRAND
    window.Localize.initialize({
      key: isBrand
        ? process.env.REACT_APP_BRAND_LOCALIZE_JS_PROJECT_KEY
        : process.env.REACT_APP_RETAIL_LOCALIZE_JS_PROJECT_KEY,
      rememberLanguage: true,
      saveNewPhrasesFromSource: true,
      translateBody: true,
      translateTitle: true,
      defaultLanguage: userInfo?.displayLanguage || 'en',
      targetLanguage: userInfo?.displayLanguage || 'en',
    })
  }, [userInfo])
}
