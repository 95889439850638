import sortBy from 'lodash/sortBy'
import uniqueId from 'lodash/uniqueId'

import * as constants from './constants'

export const addMissingGalleryElements = (
  array,
  template,
  contentTypes,
  rowId,
) => {
  const elementType =
    array[0]?.elementType || contentTypes?.[0] || constants.IMAGE_WIDGET
  let requiredElements
  if (elementType === constants.SHOWROOM_BRAND_WIDGET) {
    const rowsNumber = array.length
      ? JSON.parse(array?.[0]?.presentation)?.rowsNumber
      : 1
    requiredElements = constants.DISTRIBUTION_SIZE[template] * rowsNumber
  } else {
    requiredElements = constants.DISTRIBUTION_SIZE[template]
  }
  if (array.length >= requiredElements) {
    return array
  }
  const newArray = [...array]
  const currentIndexes = array.map((el) => el.orderNbr)
  const missingIndexes = []
  for (let i = 0; i < requiredElements; i++) {
    if (!currentIndexes.includes(i)) {
      missingIndexes.push(i)
    }
  }
  missingIndexes.forEach((index) => {
    newArray.push({
      id: `LOCAL_${elementType}_${rowId}_${index}`,
      elementType,
      content: elementType === constants.TEXT_WIDGET ? '{}' : {},
      orderNbr: index,
    })
  })
  return newArray
}
export const addMissingTextElements = (
  array,
  template,
  rowType,
  contentTypes,
  rowId,
) => {
  let requiredElements =
    rowType === constants.MEDIA_BANNER
      ? 2
      : constants.DISTRIBUTION_SIZE[template]

  if (array.length >= requiredElements) {
    return array
  }
  const contentType = contentTypes?.find(
    (type) => type !== constants.TEXT_WIDGET,
  )
  const content = constants.WIDGET_CONTENTS[contentType]
  const newElement = {
    id: uniqueId(`LOCAL_${contentType}_${rowId}_`),
    elementType: contentType,
    content,
    orderNbr: 0,
  }
  const oldElement = { ...array[0] }
  oldElement.orderNbr++
  return [newElement, oldElement]
}
export const addMissingInstagramElement = (rowId) => [
  {
    id: uniqueId(`LOCAL_${constants.SIX_EQUAL}_${rowId}_`),
    elementType: constants.INSTAGRAM_WIDGET,
    presentation: '',
    orderNbr: 0,
  },
]
const inferRowType = (rowType, template, array) => {
  if (rowType && rowType !== 'undefined') {
    return rowType
  }
  if (template === constants.ONE_FULL_WIDTH) {
    if (array.length === 1) {
      return constants.TEXT_BANNER
    }
    return constants.MEDIA_BANNER
  }
  const elem = array[0]
  return constants.WIDGET_TO_ROWTYPE[elem?.elementType]
}

export const addMissingElements = (
  array,
  template,
  rowType,
  contentTypes,
  rowId,
) => {
  const finalRowType = inferRowType(rowType, template, array)
  const isNotGalleryType = !constants.GALLERY_TEMPLATES.includes(finalRowType)
  if (finalRowType === constants.SOCIAL) {
    if (array.length > 0) {
      return array
    }
    return addMissingInstagramElement(rowId)
  }
  if (
    array.length === 1 &&
    finalRowType !== constants.TEXT_BANNER &&
    isNotGalleryType
  )
    return addMissingTextElements(
      array,
      template,
      finalRowType,
      contentTypes,
      rowId,
    )

  return addMissingGalleryElements(array, template, contentTypes, rowId)
}

export const orderArray = (array = []) =>
  sortBy(array, (el) => el.orderNbr).map(
    ({ orderNbr: _orderNbr, ...rest }) => ({
      ...rest,
    }),
  )

export const mapTextStyles = (styles) => {
  styles = styles || []
  return styles.map((style) => ({
    id: style.id,
    name: style.name,
    order: style.orderNbr,
    fontSize: style.size,
    fontWeight: style.weight,
    fontFamily: style.typeface,
  }))
}

export const getAspect = (rowType) => {
  if (
    rowType === constants.DOCUMENT_GALLERY ||
    rowType === constants.LINESHEET_GALLERY ||
    rowType === constants.COLLECTION_GALLERY ||
    rowType === constants.IMAGE_TEXT ||
    rowType === constants.DOCUMENT_TEXT ||
    rowType === constants.LINESHEET_TEXT ||
    rowType === constants.COLLECTION_TEXT ||
    rowType === constants.VIDEO_TEXT
  ) {
    return {
      aspect: constants.ASPECT_LANDSCAPE,
    }
  }
  if (rowType === constants.IMAGE_GALLERY) {
    return {
      aspect: constants.ASPECT_PORTRAIT,
    }
  }
}
