import classNames from 'classnames'
import PropTypes from 'prop-types'

import Checkbox from 'components/Core/Checkbox/Checkbox'

import styles from './Radio.less'

const Radio = ({
  id,
  checked,
  label,
  onClick,
  value,
  className = '',
  disabled,
  ...rest
}) => (
  <Checkbox
    id={id}
    checked={checked}
    radio
    label={label}
    onClick={onClick}
    value={value}
    className={classNames(styles.Radio, className)}
    disabled={disabled}
    type="radio"
    aria-checked={checked ? 'true' : 'false'}
    {...rest}
  />
)

Radio.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

Radio.defaultProps = {
  checked: undefined,
  label: '',
  onClick: () => {},
  value: '',
  className: '',
  disabled: false,
}

export default Radio
