import isEmpty from 'lodash/isEmpty'
import uniqueId from 'lodash/uniqueId'
import { useSelector } from 'react-redux'
import { matchPath } from 'react-router'

import { makeRequest } from 'utils/requests'

import { isLiteRetailer, isProRetailer } from 'store/currentUser/selectors'

import {
  ASPECT_LANDSCAPE,
  ASPECT_PORTRAIT,
  CONNECTED_RETAILER,
  DISCONNECTED_RETAILER,
  DISTRIBUTION_SIZE,
  INSTAGRAM_WIDGET,
  LOGGED_OUT_USER,
  SHOWROOM_BRAND_WIDGET,
  WIDGET_CONTENTS,
} from './constants'
import {
  IMAGE,
  TYPE_IMAGE,
  TYPE_VIDEO,
} from 'containers/Showroom/GridLayout/GridElements/GridElements.constants'

export const isSamePath = (firstPathname, secondPathname) =>
  Boolean(
    matchPath(firstPathname, {
      path: secondPathname,
      exact: true,
    }),
  )

const createElement = (elementType) => {
  return {
    id: uniqueId(`LOCAL_${elementType}_`),
    elementType: elementType,
    content: WIDGET_CONTENTS[elementType],
  }
}

const getTotalNumer = (elementType, template, rowNumber, selectedOption) => {
  if (elementType === SHOWROOM_BRAND_WIDGET) {
    return DISTRIBUTION_SIZE[template] * rowNumber
  }
  if (elementType === INSTAGRAM_WIDGET) {
    return 1
  }
  return parseInt(selectedOption?.label, 10)
}

export const getNewElements = ({
  rowItem,
  template,
  options,
  rowsNumber,
  elementType,
}) => {
  if (isEmpty(options) || isEmpty(template) || isEmpty(rowItem)) {
    return rowItem?.elements || []
  }

  const elements = rowItem?.elements
  const selectedOption = options.find((option) => option.value === template)
  const totalNumber = getTotalNumer(
    elementType,
    template,
    rowsNumber,
    selectedOption,
  )

  if (totalNumber < elements.length) {
    return elements.slice(0, totalNumber)
  }

  const newElements = []
  for (let i = 0; i < totalNumber - elements.length; i++) {
    newElements.push(createElement(elements?.[0]?.elementType))
  }

  return [...elements, ...newElements]
}

export const uploadMediaFile = (file, targetMediaType, onError) => {
  const uploadType = targetMediaType === IMAGE ? TYPE_IMAGE : TYPE_VIDEO

  if (!file) {
    return Promise.reject('media file was not provided')
  }
  const form = new FormData()

  form.append('files[]', file)
  const UPLOAD_URL = `/Uploads/new_upload/1/${uploadType}`
  return makeRequest({
    url: UPLOAD_URL,
    data: form,
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
  })
    .then((response) => {
      const { data } = response
      if (!data) {
        onError()
        return {}
      } else {
        return { id: data[0].joor_id, url: data[0].url }
      }
    })
    .catch(onError)
}

export const getVideoCover = (file = null, seekTo = 0.0, url) =>
  new Promise((resolve, reject) => {
    const videoPlayer = document.createElement('video')
    videoPlayer.crossOrigin = '*'
    videoPlayer.setAttribute('src', url ? url : URL.createObjectURL(file))
    videoPlayer.load()
    videoPlayer.addEventListener('error', (ex) => {
      reject('error when loading video file', ex)
    })
    videoPlayer.addEventListener('loadedmetadata', () => {
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.')
        return
      }
      videoPlayer.currentTime = seekTo
      videoPlayer.addEventListener('seeked', () => {
        const canvas = document.createElement('canvas')
        canvas.width = videoPlayer.videoWidth
        canvas.height = videoPlayer.videoHeight
        const ctx = canvas.getContext('2d')
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob)
          },
          'image/jpeg',
          0.75 /* quality */,
        )
      })
    })
  })

export const getUserVisibility = ({
  viewMode,
  isLoggedIn,
  isBrandAccount,
  isAccountConnected,
}) => {
  if (viewMode === LOGGED_OUT_USER || !isLoggedIn) {
    return LOGGED_OUT_USER
  } else if (
    viewMode === DISCONNECTED_RETAILER ||
    (!isBrandAccount && !isAccountConnected)
  ) {
    return DISCONNECTED_RETAILER
  } else if (
    viewMode === CONNECTED_RETAILER ||
    (!isBrandAccount && isAccountConnected)
  ) {
    return CONNECTED_RETAILER
  }
}

export const useRetailerAccountType = () => {
  const LITE_RETAILER = 'Lite Retailer'
  const PRO_RETAILER = 'Pro Retailer'
  const BRAND = 'Brand'
  const isLite = useSelector(isLiteRetailer)
  const isPro = useSelector(isProRetailer)
  return isLite ? LITE_RETAILER : isPro ? PRO_RETAILER : BRAND
}

export const getAspectStyle = (aspect, styles) => {
  switch (aspect) {
    case ASPECT_PORTRAIT:
      return styles.portrait
    case ASPECT_LANDSCAPE:
      return styles.landscape
    default:
      return styles.square
  }
}
