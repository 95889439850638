import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Divider, Tooltip } from 'components/Core'
import Button from 'components/Core/ButtonV2/Button'
import { WHITE } from 'components/Core/Tooltip/Tooltip'

import withAnalytics from '../RowSettings.analytics'
import styles from './TextBannerTooltip.module.scss'
import RadioSelector from 'features/Storefront/DragDropTemplate/Row/RadioSelector/RadioSelector'
import {
  MEDIUM_WIDTH,
  NARROW_WIDTH,
  WIDE_WIDTH,
} from 'features/Storefront/constants'

const RADIO_OPTIONS = [
  {
    label: 'Wide',
    value: WIDE_WIDTH,
  },
  {
    label: 'Medium',
    value: MEDIUM_WIDTH,
  },
  {
    label: 'Narrow',
    value: NARROW_WIDTH,
  },
]

const TRACKER_TITLE = 'Text Banner Row Level Settings'

const TextBannerTooltip = ({
  isOpen,
  onApplyChanges,
  rowItem,
  top,
  trackRowSettings,
}) => {
  const [checked, setChecked] = useState(WIDE_WIDTH)
  const defaultWidth = rowItem?.settings?.width || WIDE_WIDTH

  useEffect(() => {
    if (defaultWidth) {
      setChecked(defaultWidth)
    }
  }, [defaultWidth])

  const content = (
    <>
      <h3 className={styles.title}>Text Banner Settings</h3>
      <RadioSelector
        onClick={(_, { value }) => setChecked(value)}
        radioOptions={RADIO_OPTIONS}
        radioTitle="Text Width"
        selectedRadio={checked}
        className={styles.subtitle}
      />
      <Divider />
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.smallButton}
          onClick={() => {
            onApplyChanges({ ...rowItem, settings: { width: defaultWidth } })
          }}
        >
          Cancel
        </Button>
        <Button
          className={styles.mediumButton}
          onClick={() => {
            trackRowSettings(TRACKER_TITLE, { Width: checked })
            onApplyChanges({ ...rowItem, settings: { width: checked } })
          }}
        >
          Apply
        </Button>
      </div>
    </>
  )

  return (
    <Tooltip
      className={styles.TextBannerTooltip}
      content={content}
      open={isOpen}
      style={{
        top: `${top + 100}px`,
        right: '10px',
      }}
      color={WHITE}
    />
  )
}

TextBannerTooltip.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onApplyChanges: PropTypes.func.isRequired,
  rowItem: PropTypes.object.isRequired,
  top: PropTypes.number.isRequired,
}

export default withAnalytics(TextBannerTooltip)
