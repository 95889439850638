import { ATTRIBUTE_SETTINGS, RETAILER_SETTINGS } from 'routes/paths'

import DropdownMenuTextLink from '../../DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/NavbarRevamp/constants/navbarRevamp.messages'
import { SettingsDropdownIds } from 'features/NavbarRevamp/navbarRevamp.ids'

const RetailerSettings = ({
  isRetailerAccount,
  canViewRetailerSettings,
}: {
  isRetailerAccount: boolean
  canViewRetailerSettings: boolean
}) =>
  isRetailerAccount && canViewRetailerSettings ? (
    <>
      <DropdownMenuTextLink
        to={RETAILER_SETTINGS}
        message={messages.retailerSettings}
        id={SettingsDropdownIds.SettingsOptionRetailerSettings}
      />
      <DropdownMenuTextLink
        to={ATTRIBUTE_SETTINGS}
        message={messages.attributeSettings}
        id={SettingsDropdownIds.SettingsOptionAttributeSettings}
      />
    </>
  ) : null

export default RetailerSettings
