import { useEffect } from 'react'

import { TextButton } from '@joor/design-system'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import useSearchParam from 'hooks/useSearchParam'

import { isProductsPageContent } from 'pages/LegacyPage/LegacyPage.utils'
import { NEW_ORDER_REVIEW } from 'routes/paths'

import useInitializePHPOrderModifyToasts from './useInitializePHPOrderModifyToasts'

const HeaderContainer = styled.div`
  position: fixed;
  top: 53px;
  width: 100%;
  display: flex;
  z-index: 10;
  padding: 20px 0px 20px 40px;
`

const checkIfShouldShowHeader = () =>
  window.location.pathname.startsWith('/orders/review') ||
  window.location.pathname.startsWith('/orders/modify')
/**
 * This header is only to be used during the iterative release
 * phase of OR2023. Once we've fully released OR2023 this header
 * will be removed.
 *
 * TODO: write a very simple test case that checks if all tabs render
 * correctly and that each tab links somewhere correctly. We should also
 * test if the component works correctly when in the expected url. The actual
 * links the tabs should point to doesn't need to be tested as it is
 * determined in the imported helper functions which are already tested
 * and there's no need to cover it also here.
 *
 */
const OrderReviewReleaseHeader = () => {
  const orderIdParam = useSearchParam('order_id')
  const shouldShowHeader = checkIfShouldShowHeader()
  const showOnlyProductsContent = isProductsPageContent()
  const history = useHistory()

  const redirectToOverview = () => {
    orderIdParam &&
      history.push(generatePath(NEW_ORDER_REVIEW, { id: orderIdParam }))
  }

  /**
   * Adds a css classname to the ogo order container to make sure we add
   * extra margin to the top so nothing stays hidden
   */
  useEffect(() => {
    const RELEASE_HEADER_PHP_CLASSNAME = 'order-review-2023-release-header'
    const orderPHPDivContainer = document.querySelector('.order.content')
    const hasOrderIdParam = window.location.search.includes('order_id')
    if (orderPHPDivContainer && hasOrderIdParam && !showOnlyProductsContent) {
      orderPHPDivContainer.classList.add(RELEASE_HEADER_PHP_CLASSNAME)
    }

    return () => {
      if (orderPHPDivContainer && hasOrderIdParam && !showOnlyProductsContent) {
        orderPHPDivContainer.classList.remove(RELEASE_HEADER_PHP_CLASSNAME)
      }
    }
  }, [])

  useInitializePHPOrderModifyToasts()

  if (!orderIdParam || !shouldShowHeader || showOnlyProductsContent) {
    return null
  }

  return (
    <HeaderContainer>
      <TextButton data-testid="BackToOverviewBtn" onClick={redirectToOverview}>
        Back to Overview
      </TextButton>
    </HeaderContainer>
  )
}

export default OrderReviewReleaseHeader
