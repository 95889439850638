import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import reduce from 'lodash/reduce'

import {
  EMPTY_DELIVERY_WINDOW,
  getAggregatedSkuDeliveryRange,
  getDeliveryWindowText,
} from 'utils/transformations/deliveryWindows'
import { fromGlobalId } from 'utils/transformations/graphql'

import { PRODUCT_COLOR_CODE, PRODUCT_SIZE_CODE } from 'shop/products/traits'
import { fromCasepackId, fromSkuId } from 'shop/products/utils'
import { getVariantByCode } from 'shop/products/utils'

export const getProductSizes = (product, variantId) => {
  const rawVariantId = fromGlobalId(fromGlobalId(variantId).id).id
  const productSkus = get(product, 'skus', [])
  const productSizeVariants = getVariantByCode(
    product?.variants || [],
    PRODUCT_SIZE_CODE,
  )
  const productSizeVariantValues = productSizeVariants?.values || []
  const productSizes = reduce(
    productSizeVariantValues,
    (acc, size) => {
      const sizeSkus = productSkus.find((productSku) => {
        const { colorVariantId, sizeVariantId } = fromSkuId(productSku.id)
        return (
          fromGlobalId(fromGlobalId(size.id).id).id === sizeVariantId &&
          rawVariantId === colorVariantId
        )
      })
      return sizeSkus
        ? [
            ...acc,
            {
              ...size,
              sku: sizeSkus,
            },
          ]
        : acc
    },
    [],
  )
  return productSizes
}

export const getProductCasepacks = (product, variantId) => {
  const productCasepacks = get(product, 'casepacks', [])
  const rawVariantId = fromGlobalId(fromGlobalId(variantId).id).id

  return filter(
    productCasepacks,
    (casepack) => fromCasepackId(casepack.id).colorVariantId === rawVariantId,
  ).map((casepack) => ({ ...casepack, value: casepack.name }))
}

export const getProductColor = (product, variantId) => {
  const productColorVariants = getVariantByCode(
    product?.variants || [],
    PRODUCT_COLOR_CODE,
  )
  const productColorVariantValues = productColorVariants?.values || []
  const productColor = filter(
    productColorVariantValues,
    (color) => color.id === variantId,
  )
  return get(productColor, '[0]', {})
}

export const getDeliveryRangeFromSize = (
  skuDeliveries = [],
  productSize = {},
) => {
  const skuDelivery = find(skuDeliveries, (skuDelivery) => {
    const skuDeliverySkuId = skuDelivery?.sku?.id
    const productSizeSkuId = productSize?.sku?.id
    const { sizeVariantId } = fromCasepackId(productSize?.id)
    const matchingSizeId = skuDeliverySkuId === productSizeSkuId
    const matchingCasepackId =
      fromCasepackId(skuDeliverySkuId).sizeVariantId === sizeVariantId
    return skuDeliverySkuId && (matchingSizeId || matchingCasepackId)
  })
  const casepackSkusWithDelivery = skuDeliveries.reduce(
    (acc, el) =>
      productSize.skus?.find((sku) => sku?.id === el?.sku?.id)
        ? [...acc, el?.sku?.id]
        : acc,
    [],
  )
  const casepackAggregatedDelivery = skuDeliveries.filter((el) =>
    casepackSkusWithDelivery.includes(el?.sku?.id),
  )
  const skuDeliveryRange = getDeliveryWindowText(
    skuDelivery?.startShipDate,
    skuDelivery?.completeShipDate,
    EMPTY_DELIVERY_WINDOW,
  )

  return skuDeliveryRange !== EMPTY_DELIVERY_WINDOW
    ? skuDeliveryRange
    : getAggregatedSkuDeliveryRange(casepackAggregatedDelivery)
}
