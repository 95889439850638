import { useQuery } from '@apollo/client'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { useSelector } from 'react-redux'

import {
  CarriedBrand,
  GoogleAddress,
  ProvidedInfoQueryDocument,
} from '__generated__/atlas-types'

import {
  fromGlobalId,
  relayConnectionToArray,
  toGlobalId,
} from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import { getAccountId, isAccountTypeBrand } from 'store/currentUser/selectors'

import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

atlas`#graphql
  query ProvidedInfoQuery ($retailerId: ID!) {
    retailerProfile (id:$retailerId) {
      id
      description
      logo {
        id
      }
      websiteUrl
      socialMediaAccounts {
        type
        url
      }
      profileSnoozeCounter
      primaryLocation {
        id
        type
        name
        address1
        address2
        city
        state
        country
        zip
        phone
        clienteleInfo  {
          age
          male
          female
          descriptions {
            id
            name
          }
          interests {
            id
            name
          }
        }
        categories {
          id
          name
        }
        brandsCarried {
          id
          accountId
          name
        }
        wholesaleRange {
          min
          max
        }
        yearEstablished
      }
    }
  }
`

export const useProvidedInfo = () => {
  const isBrandAccount = useSelector(isAccountTypeBrand)
  const accountId = useSelector(getAccountId)
  const retailerId = accountId ? toGlobalId('Retailer', accountId) : undefined
  const { data, loading, refetch } = useQuery(ProvidedInfoQueryDocument, {
    client: atlasClient,
    variables: {
      retailerId: retailerId || '',
    },
    skip: !accountId || isBrandAccount,
    fetchPolicy: 'network-only',
  })

  const primaryLocation: RetailerProfileLocation = {
    ...data?.retailerProfile?.primaryLocation,
    id: data?.retailerProfile?.primaryLocation?.id || '',
    brandsCarried: uniqBy(
      data?.retailerProfile?.primaryLocation?.brandsCarried
        ?.filter((brandsCarried) =>
          Boolean(fromGlobalId(brandsCarried.accountId)?.id),
        )
        ?.map((carriedBrand: CarriedBrand) => ({
          ...carriedBrand,
          name: carriedBrand?.name || '',
          id: carriedBrand?.accountId || '',
        })),
      'id',
    ),
  }

  return {
    retailerId: retailerId ?? '',
    providedAddress: primaryLocation,
    providedProfileData: data?.retailerProfile,
    loading,
    refetchProvidedAddress: refetch,
  }
}

const GOOGLE_MAPS_INFO_QUERY = atlas`#graphql
  query ListRetailerGoogleAddresses ($retailerIds: [ID!]!) {
    listRetailerGoogleAddresses (retailerIds: $retailerIds) {
      edges {
        node {
          id
          address1
          city
          country
          state
          zip
          phone
          website
          url
          created
          modified
          accepted
          businessStatus
          placeId
          locationId
        }
      }
    }
  }

`

export const useGoogleInfo = () => {
  const isBrandAccount = useSelector(isAccountTypeBrand)
  const accountId = useSelector(getAccountId)
  const formattedId = accountId ? toGlobalId('Retailer', accountId) : undefined
  const { data, loading } = useQuery(GOOGLE_MAPS_INFO_QUERY, {
    client: atlasClient,
    variables: {
      retailerIds: [formattedId],
    },
    skip: !accountId || isBrandAccount,
  })
  const googleAddresses = sortBy(
    relayConnectionToArray(data?.listRetailerGoogleAddresses),
    (address: GoogleAddress) => {
      return new Date(address.created).getTime()
    },
  )
  const googleAddress = googleAddresses[googleAddresses.length - 1]

  return {
    googleAddress,
    googleWebsite: googleAddress?.website,
    loading,
  }
}
