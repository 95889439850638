// @ts-nocheck
// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import '@joor/design-system/dist/designTokens/variables.css'
import AuthProvider from 'context/AuthProvider'
import 'react-app-polyfill/stable'
import ReactDOM from 'react-dom'

import 'styles/globals.css'
// eslint-disable-next-line import/no-unresolved
import 'styles/tailwind.generated.css'
import 'styles/variables.css'
import initializeDatadogRUM from 'utils/sdks/datadog'
import bootstrapGTM from 'utils/sdks/gtm'
import initializeHighcharts from 'utils/sdks/highcharts'

import App from './App'

bootstrapGTM()

initializeHighcharts()

initializeDatadogRUM()

const rootElement = document.getElementById('root')
ReactDOM.render(
  <AuthProvider>
    <App />
  </AuthProvider>,
  rootElement,
)
