import isNil from 'lodash/isNil'

import { getNewPrice } from 'shop/products/utils'

import {
  FIRST_COLUMN_ID,
  QUANTITY_COLUMN_ID,
  SUGG_RETAILER_COLUMN_ID,
  WHOLESALE_COLUMN_ID,
} from 'modals/Shop/TablesConfiguration'

export const updateAmount = (total, newValue, oldValue) =>
  total + newValue - oldValue

export const updatePrices = (total = {}, newValue = {}, oldValue = {}) => ({
  min: updateAmount(total.min, newValue.min, oldValue.min),
  max: updateAmount(total.max, newValue.max, oldValue.max),
})

export const calculateValues = (valuesObj = {}, quantity = 1) => {
  const wholesaleValue = getNewPrice(valuesObj.wholesalePrice, quantity)
  const retailValue = getNewPrice(valuesObj.retailPrice, quantity)

  return [wholesaleValue, retailValue]
}

export const getUnitsFromRowValues = (rowValues = {}) => {
  const quantities = {}
  const nonUnitProps = [
    FIRST_COLUMN_ID,
    QUANTITY_COLUMN_ID,
    SUGG_RETAILER_COLUMN_ID,
    WHOLESALE_COLUMN_ID,
  ]
  for (const prop in rowValues) {
    if (!nonUnitProps.includes(prop)) {
      quantities[prop] = rowValues[prop]
    }
  }

  return quantities
}

export const getTotalUnits = (oldTotals = {}, newUnits = {}, oldUnits = {}) => {
  const newTotals = {}
  for (const prop in oldTotals) {
    newTotals[prop] = updateAmount(
      oldTotals[prop],
      newUnits[prop],
      oldUnits[prop],
    )
  }
  return newTotals
}

export const getUpdatedUnitsRow = (
  unitsRow = {},
  newUnits = {},
  oldUnits = {},
) => {
  const finalUnits = {}
  for (const prop in newUnits) {
    finalUnits[prop] = isNil(unitsRow[prop])
      ? unitsRow[prop]
      : (unitsRow[prop] || 0) - (newUnits[prop] || 0) + (oldUnits[prop] || 0)
  }
  return finalUnits
}

export const calculateTotalRowPrices = (rowUnits = {}, rowPrices = {}) => {
  const retailPrice = { max: 0, min: 0 }
  const wholesalePrice = { max: 0, min: 0 }
  for (const prop in rowUnits) {
    const [wholeSale, retail] = calculateValues(rowPrices[prop], rowUnits[prop])
    wholesalePrice.max += wholeSale.max
    wholesalePrice.min += wholeSale.min
    retailPrice.max += retail.max
    retailPrice.min += retail.min
  }

  return [wholesalePrice, retailPrice]
}

export const getNewTotalRow = (
  totalRow,
  { newQuantity, newWholeSaleValue, newRetailValue },
  { previousQuantity, previousWholesaleValue, previousRetailValue },
) => ({
  [QUANTITY_COLUMN_ID]: updateAmount(
    totalRow[QUANTITY_COLUMN_ID],
    newQuantity,
    previousQuantity,
  ),
  [WHOLESALE_COLUMN_ID]: updatePrices(
    totalRow[WHOLESALE_COLUMN_ID],
    newWholeSaleValue,
    previousWholesaleValue,
  ),
  [SUGG_RETAILER_COLUMN_ID]: updatePrices(
    totalRow[SUGG_RETAILER_COLUMN_ID],
    newRetailValue,
    previousRetailValue,
  ),
})
