import { atlas } from 'graphql/tags'

export const SET_DISPLAY_LANGUAGE_FOR_USER = atlas`#graphql
  mutation setDisplayLanguageForUser($input: SetDisplayLanguageForUserInput!) {
    setDisplayLanguageForUser(input: $input) {
      id
      displayLanguage
    }
  }
`
