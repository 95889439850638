import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Radio } from 'components/Core'

import styles from './RadioSelector.module.scss'

const RadioSelector = ({
  onClick,
  radioOptions,
  radioTitle,
  selectedRadio,
  className,
}) => (
  <div className={classNames(styles.RadioSelector, className)}>
    <h4 className={styles.title}>{radioTitle}</h4>
    {radioOptions?.map((radio) => (
      <Radio
        id={radio?.value}
        key={radio?.value}
        onClick={onClick}
        label={radio?.label}
        checked={selectedRadio === radio?.value}
        value={radio?.value}
        className={styles.radio}
        {...radio}
      />
    ))}
  </div>
)

RadioSelector.propTypes = {
  onClick: PropTypes.func.isRequired,
  radioOptions: PropTypes.array.isRequired,
  radioTitle: PropTypes.string.isRequired,
  selectedRadio: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
}

RadioSelector.defaultProps = {
  selectedRadio: null,
  className: '',
}

export default RadioSelector
