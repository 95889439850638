import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './ProLiteIndicator.less'

const ProLiteIndicator = ({ isLiteAccount, isProAccount, className }) => {
  if (!isLiteAccount && !isProAccount) {
    return null
  }
  const text = isProAccount ? 'PRO' : 'LITE'
  const indicatorClasses = classnames(styles.ProLiteIndicator, className, {
    [styles.pro]: isProAccount,
    [styles.lite]: isLiteAccount,
  })
  return (
    <div className={indicatorClasses}>
      <div className={styles.text}>{text}</div>
    </div>
  )
}

ProLiteIndicator.propTypes = {
  isLiteAccount: PropTypes.bool,
  isProAccount: PropTypes.bool,
  className: PropTypes.string,
}

ProLiteIndicator.defaultProps = {
  isLiteAccount: false,
  isProAccount: false,
  className: '',
}

export default ProLiteIndicator
