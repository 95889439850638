import { handleActions } from 'redux-actions'

// TODO: (James 20180405) Consolidate namespaced state objs into one modal key val pair.
export const defaultState = {
  ordersModal: null,
  assortmentsModal: null,
}

const reducer = handleActions(
  {
    'ORDER/SET_CURRENT_MODAL': (state, action) => {
      const { payload } = action

      return {
        ...state,
        ordersModal: payload,
      }
    },
    'ASSORTMENT/SET_CURRENT_MODAL': (state, action) => {
      const { payload } = action

      return {
        ...state,
        assortmentsModal: payload,
      }
    },
  },
  defaultState,
)

export default reducer
