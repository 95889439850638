import { connect } from 'react-redux'
import { compose } from 'redux'

import { removeDownloadTaskId } from 'store/fileDownloads/actions'

import FileDownloadPoller from './FileDownloadPoller.component'
import { withDownloadStatus } from './FileDownloadPoller.queries'

const mapDispatchToProps = {
  removeDownloadTaskId,
}

const withRedux = connect(null, mapDispatchToProps)

export default compose(withDownloadStatus, withRedux)(FileDownloadPoller)
