import {
  ASPECT_LANDSCAPE,
  ASPECT_PORTRAIT,
  ASPECT_SQUARE,
  COLLECTIONS_WIDGET,
  DOCUMENT_WIDGET,
  FOUR_EQUAL,
  IMAGE_WIDGET,
  INSTAGRAM_WIDGET,
  LINESHEET_WIDGET,
  NINE_EQUAL,
  ONE_REGULAR,
  ORB_WIDGET,
  PRODUCT_WIDGET,
  SHOWROOM_BRAND_WIDGET,
  SIX_EQUAL,
  THREE_EQUAL,
  TWELVE_EQUAL,
  TWO_EQUAL,
  VIDEO_WIDGET,
} from 'features/Storefront/constants'

export const ASPECT_OPTIONS = [
  {
    text: 'Portrait (3:4)',
    key: ASPECT_PORTRAIT,
    value: ASPECT_PORTRAIT,
  },
  {
    text: 'Square (1:1)',
    key: ASPECT_SQUARE,
    value: ASPECT_SQUARE,
  },
  {
    text: 'Landscape (3:2)',
    key: ASPECT_LANDSCAPE,
    value: ASPECT_LANDSCAPE,
  },
]

export const ASPECT_VIDEO_OPTIONS = [
  {
    text: 'Portrait (9:16)',
    key: ASPECT_PORTRAIT,
    value: ASPECT_PORTRAIT,
  },
  {
    text: 'Landscape (16:9)',
    key: ASPECT_LANDSCAPE,
    value: ASPECT_LANDSCAPE,
  },
]

export const WIDGET_NUM_OPTIONS = [
  {
    label: '1',
    value: ONE_REGULAR,
  },
  {
    label: '2',
    value: TWO_EQUAL,
  },
  {
    label: '3',
    value: THREE_EQUAL,
  },
  {
    label: '4',
    value: FOUR_EQUAL,
  },
]

export const INSTAGRAM_NUM_OPTIONS = [
  {
    label: '3',
    value: THREE_EQUAL,
  },
  {
    label: '6',
    value: SIX_EQUAL,
  },
  {
    label: '9',
    value: NINE_EQUAL,
  },
  {
    label: '12',
    value: TWELVE_EQUAL,
  },
]

export const BRAND_NUM_OPTIONS = [
  {
    label: '2',
    value: TWO_EQUAL,
  },
  {
    label: '3',
    value: THREE_EQUAL,
  },
  {
    label: '4',
    value: FOUR_EQUAL,
  },
]

export const IMAGE_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'Image Gallery Settings',
    label: 'Section Title',
  },
  dropdownSettings: {
    title: 'Image Aspect Ratio',
    options: ASPECT_OPTIONS,
  },
  radioSettings: {
    title: 'Number of Images',
    options: WIDGET_NUM_OPTIONS,
  },
  trackerTitle: 'Image Gallery Row Level Settings',
}

export const COLLECTION_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'The Edit Gallery Settings',
    label: 'Section Title',
  },
  dropdownSettings: {
    title: 'Cover Image Aspect Ratio',
    options: ASPECT_OPTIONS,
  },
  radioSettings: {
    title: 'Number of Collections',
    options: WIDGET_NUM_OPTIONS,
  },
  trackerTitle: 'The Edit Gallery Row Level Settings',
}

export const LINESHEET_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'Linesheet Gallery Settings',
    label: 'Section Title',
  },
  dropdownSettings: {
    title: 'Cover Image Aspect Ratio',
    options: ASPECT_OPTIONS,
  },
  radioSettings: {
    title: 'Number of Linesheets',
    options: WIDGET_NUM_OPTIONS,
  },
  trackerTitle: 'Linesheet Gallery Row Level Settings',
}

export const DOCUMENT_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'Document (Lookbook) Gallery Settings',
    label: 'Section Title',
  },
  dropdownSettings: {
    title: 'Cover Image Aspect Ratio',
    options: ASPECT_OPTIONS,
  },
  radioSettings: {
    title: 'Number of Documents',
    options: WIDGET_NUM_OPTIONS,
  },
  trackerTitle: 'Document Gallery Row Level Settings',
}

export const ORB360_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'ORB360 Gallery Settings',
    label: 'Section Title',
  },
  radioSettings: {
    title: 'Number of ORB360 Videos',
    options: [WIDGET_NUM_OPTIONS[2], WIDGET_NUM_OPTIONS[3]],
  },
  trackerTitle: 'ORB360 Gallery Row Level Settings',
}

export const PRODUCT_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'Product Gallery Settings',
    label: 'Section Title',
  },
  trackerTitle: 'Product Gallery Row Level Settings',
}

export const VIDEO_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'Video Gallery Settings',
    label: 'Section Title',
  },
  dropdownSettings: {
    title: 'Video Aspect Ratio',
    options: ASPECT_VIDEO_OPTIONS,
  },
  radioSettings: {
    title: 'Number of Videos',
    options: WIDGET_NUM_OPTIONS,
  },
  trackerTitle: 'Video Gallery Row Level Settings',
}

export const INSTAGRAM_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'Instagram Gallery Settings',
    label: 'Section Title',
  },
  radioSettings: {
    title: 'Number of Images Displayed',
    options: INSTAGRAM_NUM_OPTIONS,
  },
}

export const MEDIA_BANNER_SETTINGS = {
  switchSettings: {
    title: 'Media Banner Settings',
    label: 'Overlay Text',
  },
  trackerTitle: 'Media Gallery Row Level Settings',
}
export const CUSTOM_DROPDOWN = 'CUSTOM_DROPDOWN'
export const SHOWROOM_GALLERY_SETTINGS = {
  switchSettings: {
    title: 'Brand Directory Settings',
    label: 'Section Title',
  },
  radioSettings: {
    title: 'Brands per Row',
    options: BRAND_NUM_OPTIONS,
  },
  dropdownSettings: {
    title: 'Number of Rows',
    options: CUSTOM_DROPDOWN,
  },
}

export const GALLERY_TOOLTIP_EXTRA_SETTINGS = {
  [IMAGE_WIDGET]: IMAGE_GALLERY_SETTINGS,
  [COLLECTIONS_WIDGET]: COLLECTION_GALLERY_SETTINGS,
  [LINESHEET_WIDGET]: LINESHEET_GALLERY_SETTINGS,
  [DOCUMENT_WIDGET]: DOCUMENT_GALLERY_SETTINGS,
  [ORB_WIDGET]: ORB360_GALLERY_SETTINGS,
  [PRODUCT_WIDGET]: PRODUCT_GALLERY_SETTINGS,
  [VIDEO_WIDGET]: VIDEO_GALLERY_SETTINGS,
  [INSTAGRAM_WIDGET]: INSTAGRAM_GALLERY_SETTINGS,
  [SHOWROOM_BRAND_WIDGET]: SHOWROOM_GALLERY_SETTINGS,
}
