export default {
  action: 'Apply Filters',
  addDate: 'Add a date',
  addDateFilter: 'Add Date as Filter',
  allNotes: 'Notes',
  approved: 'Approved',
  buyer: 'Buyer',
  cancelled: 'Cancelled',
  clearAll: 'Clear All',
  completeShip: 'Complete Ship',
  created: 'Created',
  createdByRetailer: 'Created by Retailers',
  date: 'Date',
  divisionCode: 'Division Code',
  event: 'Event',
  exportStatus: 'Export Status',
  failed: 'Failed',
  linesheet: 'Linesheet',
  modified: 'Modified',
  none: 'None',
  notesWithoutQuantities: 'Notes without quantities',
  notesWithQuantities: 'Notes with quantities',
  pending: 'Pending',
  priceType: 'Price Type',
  quantities: 'Quantities',
  salesRep: 'Sales Rep',
  scheduled: 'Scheduled',
  search: 'Search',
  shipped: 'Shipped',
  startShip: 'Start Ship',
  status: 'Status',
  success: 'Success',
  title: 'Search & Filter',
  type: 'Type',
  viewLegacyUnassignedOrders: 'Only Unassigned Orders',
  viewUnassignedOrders: 'No retailer',
  viewHubUnassignedOrders: 'No hub',
  yourSelection: 'Your Selection',
}
