import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

export const getEditModeEnabled = (state) => state.storefront.editMode
export const getBrandNavigation = (state) => state.storefront.brandNavigation
export const getPanel = (state) => state.storefront.panel
export const getPanelId = (state) => state.storefront.panel.id
export const getBackupPanel = (state) => state.storefront.backupPanel
export const getViewMode = (state) => state.storefront.viewAs
export const getStorefrontFetched = (state) =>
  state.storefront.storefrontFetched
export const getStorefrontShouldUpdate = (state) =>
  state.storefront.shouldUpdate
export const getProfileFetched = (state) => state.storefront.profileFetched
export const getProfileInfo = (state) => state.storefront.profile

export const hasChanges = (state) => {
  const { panel = {}, backupPanel = {} } = state.storefront
  return !isEqual(panel.rows, backupPanel.rows)
}

export const hasTextStyleChanges = (state) => {
  const { panel = {}, backupPanel = {} } = state.storefront
  return !isEqual(panel.textStyles, backupPanel.textStyles)
}

export const hasProfileChanges = (state) => {
  const { profile = {}, backupProfile = {} } = state.storefront
  return !isEqual(profile, backupProfile)
}

export const getBrandId = (state) => state.storefront.brandId
export const getUpdateDate = (state) => state.storefront.updatedOn
export const getLastPublished = (state) => state.storefront.lastPublished

export const getTextStyles = (state) => {
  let styles = {}
  for (const style of state.storefront.panel.textStyles) {
    styles[style.name.toLowerCase()] = {
      size: style.fontSize.split('px')[0],
      font: style.fontFamily,
    }
  }
  return styles
}

export const getInMenuPages = (state) =>
  state?.storefront?.pages?.filter((page) => page.orderNbr >= 1)
export const getNotInMenuPages = (state) =>
  sortBy(
    state?.storefront?.pages?.filter((page) => page.orderNbr < 1),
    (page) => -page.orderNbr,
  )
export const getPages = (state) => state?.storefront?.pages
export const hasPages = (state) => state.storefront.pages.length > 0
export const isLastOnlinePage = (state) =>
  state?.storefront?.pages?.filter((page) => page.isOnline).length === 1
export const getExistsHomePage = (state) =>
  state?.storefront?.pages?.some((page) => page.isHome)
export const getTotalPages = (state) => state.storefront.pages.length
export const getAccountId = (state) => state?.currentUser?.account?.accountId
