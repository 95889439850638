import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../Button/Button'
import Grid from '../Grid/Grid'
import Header from '../Header/Header'
import styles from './Legend.less'

const Legend = ({ onXClick, title, children }) => {
  const legendStyles = classNames(styles.legend)
  return (
    <div role="dialog" aria-label={`${title} Legend`} className={legendStyles}>
      <div className={styles.legendHeaderContainer}>
        <Header
          divider
          content={
            <Grid>
              <div aria-label={`Close ${title} Legend`}>
                <Button close onClick={onXClick} />
              </div>
            </Grid>
          }
        >
          {title}
        </Header>
      </div>
      <div className={styles.legendChildrenContainer}>{children}</div>
    </div>
  )
}
Legend.propTypes = {
  onXClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.any,
}

Legend.defaultProps = {
  title: '',
  children: '',
}

export default Legend
