import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Tab as SemanticUITab } from 'semantic-ui-react'

import styles from './Tab.less'

const Tab = ({
  panes,
  divider,
  onTabChange,
  activeIndex,
  rightPullComponent,
}) => {
  const tabStyle = classNames(styles.Tabs, { [styles.bottomBorder]: divider })

  const indexProps = {}
  if (activeIndex) {
    indexProps.activeIndex = activeIndex
  }

  return (
    <div className={tabStyle}>
      <div className={styles.rightPull}>{rightPullComponent}</div>
      <SemanticUITab
        menu={{ text: true }}
        panes={panes}
        onTabChange={onTabChange}
        {...indexProps}
      />
    </div>
  )
}

Tab.propTypes = {
  panes: PropTypes.arrayOf(PropTypes.object).isRequired,
  divider: PropTypes.bool,
  onTabChange: PropTypes.func,
  activeIndex: PropTypes.number,
  rightPullComponent: PropTypes.node,
}

Tab.defaultProps = {
  divider: false,
  onTabChange: () => {},
  activeIndex: null,
  rightPullComponent: null,
}

export default Tab
