import { graphql } from '@apollo/client/react/hoc'

import { graphene } from 'graphql/tags'

const exportStatusQuery = graphene`#graphql
  query ExportStatus($taskId: String!) {
    exportStatus(exportStatusInput: { taskId: $taskId }) {
      status
      result
    }
  }
`

const POLLING_INTERVAL = 1500

// we only need this when developing locally
// (otherwise, the browser can't read the URL)
const convertLocalhost = (url) =>
  url.replace('http://localstack', 'http://localhost')

const extractResult = (exportStatus) =>
  exportStatus && exportStatus.result
    ? convertLocalhost(exportStatus.result)
    : null

const mapResponseToProps = ({ data: { exportStatus } }) => ({
  downloadStatus: exportStatus ? exportStatus.status : null,
  downloadUrl: extractResult(exportStatus),
})

export const withDownloadStatus = graphql(exportStatusQuery, {
  alias: 'withDownloadStatus',
  props: mapResponseToProps,
  options: ({ taskId }) => ({
    variables: { taskId },
    pollInterval: POLLING_INTERVAL,
  }),
  skip: ({ taskId }) => !taskId,
})
