import { formatDate, formatDateWithInputFormat } from 'utils/formatters'

import { translateText } from '../sdks/localize'

const INPUT_DATE_FORMAT = 'YYYY-MM-DD'
const OUTPUT_DATE_FORMAT = 'MM/DD/YYYY'
export const EMPTY_DELIVERY_WINDOW = '—'

export const getDeliveryWindowText = (
  startShipDate,
  endShipDate,
  fallback = translateText('Immediate'),
  inputDateFormat = INPUT_DATE_FORMAT,
  outputDateFormat = OUTPUT_DATE_FORMAT,
) => {
  if (startShipDate) {
    return `${formatDateWithInputFormat(
      startShipDate,
      inputDateFormat,
      outputDateFormat,
    )}${
      endShipDate
        ? ` - ${formatDateWithInputFormat(
            endShipDate,
            inputDateFormat,
            outputDateFormat,
          )}`
        : ''
    }`
  }
  return fallback
}

export const getDefaultDeliveryWindow = (creationDate = new Date()) => {
  const startDate = new Date(creationDate)
  const endDate = new Date(
    new Date(creationDate).setDate(new Date(creationDate).getDate() + 14),
  )
  return {
    startDate: formatDate(startDate, 'YYYY-MM-DD'),
    endDate: formatDate(endDate, 'YYYY-MM-DD'),
  }
}

export const getAggregatedSkuDeliveryRange = (
  skuDeliveries = [],
  userDateFormat = OUTPUT_DATE_FORMAT,
) => {
  const startShipDates = skuDeliveries
    .map((skuDelivery) => skuDelivery.startShipDate)
    .filter((startShipDate) => startShipDate !== null)
    .sort()
  const completeShipDates = skuDeliveries
    .map((skuDelivery) => skuDelivery.completeShipDate)
    .filter((completeShipDates) => completeShipDates !== null)
    .sort()
  return getDeliveryWindowText(
    startShipDates[0],
    completeShipDates[completeShipDates.length - 1],
    EMPTY_DELIVERY_WINDOW,
    undefined,
    userDateFormat,
  )
}
