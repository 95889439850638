import { useLazyQuery, useQuery } from '@apollo/client'

import {
  AllBrandsAccountsQuery,
  Brand,
  BrandStatus,
  BrandSubType,
  ProductLine,
} from '__generated__/atlas-types'

import {
  fromGlobalId,
  relayConnectionToArray,
  toGlobalId,
} from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

const PRODUCT_LINES = atlas`#graphql
 query ProductLines {
  brandProductLines {
    id
    name
  }
}
 `
export const useProductLines = () => {
  const { data, loading } = useQuery(PRODUCT_LINES, { client: atlasClient })
  const productLines = data?.brandProductLines || []
  const productLineOptions = productLines.map((productLine: ProductLine) => ({
    label: productLine.name,
    value: toGlobalId('Category', fromGlobalId(productLine.id)?.id),
  }))
  return {
    loading,
    productLines: productLineOptions,
  }
}

const LITE_RETAILER_REQUIRED_CARRIED_BRANDS = atlas`#graphql
  query AllBrandsAccounts ($filter: BrandsFilter, $offset: Int, $limit: Int) {
    brands (filter: $filter, offset: $offset, limit: $limit) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const useLazyBrands = (searchText: string) => {
  const [getBrands, { data, loading }] = useLazyQuery<AllBrandsAccountsQuery>(
    LITE_RETAILER_REQUIRED_CARRIED_BRANDS,
    {
      client: atlasClient,
      variables: {
        filter: {
          searchName: searchText,
          status: [BrandStatus.PRE_ACTIVE, BrandStatus.ACTIVE],
          subtype: BrandSubType.ALL,
          allowUnconnectedBrands: true,
        },
        offset: 0,
        limit: 100,
      },
    },
  )
  const brands = relayConnectionToArray(data?.brands)
  const brandOptions = brands?.map((brand: Brand) => ({
    label: brand?.name,
    value: brand?.id,
  }))
  return {
    loading,
    getBrands,
    brands: brandOptions,
  }
}
