import { useQuery } from '@apollo/client'
import { Query } from '@apollo/client/react/components'
import { useSelector } from 'react-redux'

import { formatDropdownOptions } from 'utils/formatters'
import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas, graphene } from 'graphql/tags'
import { isLiteRetailer } from 'store/currentUser/selectors'

import {
  formatOptionsForDropdown,
  getConnectedAccountsBrandList,
} from './RetailerStartOrderModal.utils'

export const MINIMUM_CHARACTERS_QUERY = 2

const connectedAccountsQuery = graphene`#graphql
  query connectedAccountsQuery($searchFilter: String) {
    connectedAccounts(filterBy: { displayName: $searchFilter }) {
      edges {
        node {
          ... on BrandAccount {
            id
            displayName
          }
        }
      }
    }
  }
`

export const withConnectedAccounts = (WrappedComponent) => (props) => {
  const { brandSearchText } = props
  const skipQuery = brandSearchText?.length < MINIMUM_CHARACTERS_QUERY
  return (
    <Query
      query={connectedAccountsQuery}
      variables={{ searchFilter: brandSearchText }}
      skip={skipQuery}
    >
      {({ loading, error, data }) => {
        const customProps = skipQuery
          ? { ...props }
          : {
              ...props,
              connectedAccounts:
                !loading && !error && data
                  ? formatDropdownOptions(
                      relayConnectionToArray(data.connectedAccounts),
                      undefined,
                      true,
                      'notranslate',
                    )
                  : [],
              connectedAccountsQueryLoading: loading,
              connectedAccountsQueryError: error,
              connectedAccountsData: [],
            }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}

const userAccounts = graphene`
  query userAccounts {
    viewer {
      id
      accounts {
        edges {
          node {
            ... on BrandAccount {
              id
              name
              }
            ... on RetailerAccount {
              id
              name
              }
            ... on AdminAccount {
              id
              name
            }
          }
        }
      }
    }
  }
`
const useUserAccounts = () => {
  const { data } = useQuery(userAccounts)
  const retailerIds = relayConnectionToArray(data?.viewer?.accounts)?.map(
    (account) => account.id,
  )
  return retailerIds || []
}

const userConnectedAccounts = atlas`#graphql
  query UserConnectedAccounts ($retailerIds: [ID!]!, $searchText: String) {
    retailers (retailerIds:$retailerIds) {
      edges{
        node{
          id
          displayName
          name
          brands(filters: {searchText: $searchText}, allConnections:true){
            edges{
              id
              code
              profileName
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const withUserConnectedAccounts = (WrappedComponent) => (props) => {
  const shouldFetchAllConnections = useSelector(isLiteRetailer)
  const { brandSearchText } = props
  const skipQuery =
    brandSearchText?.length < MINIMUM_CHARACTERS_QUERY ||
    !shouldFetchAllConnections
  const retailerIds = useUserAccounts()

  return (
    <Query
      query={userConnectedAccounts}
      client={atlasClient}
      variables={{
        retailerIds,
        searchText: brandSearchText,
      }}
      skip={skipQuery}
    >
      {({ loading, error, data }) => {
        const customProps = skipQuery
          ? { ...props }
          : {
              ...props,
              connectedAccounts:
                !loading && !error && data
                  ? formatOptionsForDropdown(
                      getConnectedAccountsBrandList(data),
                    )
                  : [],
              connectedAccountsQueryLoading: loading,
              connectedAccountsQueryError: error,
              connectedAccountsData: getConnectedAccountsBrandList(data) || [],
            }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}

export const priceTypesQuery = graphene`#graphql
  query priceTypesQuery($brandId: ID) {
    priceTypes(filterBy: { brandIds: [$brandId] }) {
      edges {
        node {
          id
          name
          currency {
            id
            name
            code
          }
          retailCurrency {
            id
            name
            code
          }
        }
      }
    }
  }
`

/**
 * props.preselectedBrand allows us to pass a pre-selected brand object
 * and still trigger the priceTypes query with it.
 */
export const withPriceTypes = (WrappedComponent) => (props) => (
  <Query
    query={priceTypesQuery}
    variables={{ brandId: props.brandId || props?.preselectedBrand?.id }}
    skip={!props.brandId && !props?.preselectedBrand?.id}
  >
    {({ loading, error, data, networkStatus }) => {
      const customProps = {
        ...props,
        priceTypes:
          !loading && !error && data && data.priceTypes
            ? relayConnectionToArray(data.priceTypes)
            : [],
        priceTypesLoading: loading,
        priceTypesError: error,
        arePriceTypesReady: networkStatus === 7,
      }
      return <WrappedComponent {...customProps} />
    }}
  </Query>
)

export const doorIdsQuery = atlas`#graphql
  query doorIdsQuery($brandId: ID!) {
    brandDoors(brandId: $brandId) {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`
