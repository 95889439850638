import { Component } from 'react'

import classNames from 'classnames'
import propTypes from 'prop-types'
import DayPicker, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { parseDate } from 'react-day-picker/moment'

import { formatDate } from 'utils/formatters'
import { translateText } from 'utils/sdks/localize'

import Button from 'components/Core/Button/Button'

import styles from './DatePicker.less'

class DatePicker extends Component {
  state = {
    range: {
      from: parseDate(this.props.startDate, 'YYYY-MM-DD'),
      to: parseDate(this.props.endDate, 'YYYY-MM-DD'),
    },
    date: parseDate(this.props.date, 'YYYY-MM-DD'),
    calendarIsOpen: false,
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.calendarIsOpen && this.state.calendarIsOpen) {
      document.body.addEventListener('click', this.handleOutsideClick)
    } else if (prevState.calendarIsOpen && !this.state.calendarIsOpen) {
      document.body.removeEventListener('click', this.handleOutsideClick)
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleOutsideClick)
  }

  onSubmitWrapper = (forceSubmit) => {
    if (!this.props.range) {
      this.handleCalendarClose()
      this.props.onSubmit(this.state.date)
    }

    const {
      range: { from, to },
    } = this.state
    if ((from && to) || forceSubmit) {
      this.handleCalendarClose()
      const endDate = !to && this.props.singleDate ? from : to
      this.props.onSubmit({ startDate: from, endDate })
    }
  }

  getInputValue = () => {
    if (this.props.range) {
      const {
        range: { from, to },
      } = this.state
      let value = ''
      if (from) value = `${formatDate(from, this.props.dateFormat)}`
      if (to && this.props.range)
        value = `${value} - ${formatDate(to, this.props.dateFormat)}`
      return value
    }

    if (!this.state.date) {
      return ''
    }
    return formatDate(this.state.date, this.props.dateFormat)
  }

  getDefaultDayPickerProps = () => ({
    months: [
      translateText('January'),
      translateText('February'),
      translateText('March'),
      translateText('April'),
      translateText('May'),
      translateText('June'),
      translateText('July'),
      translateText('August'),
      translateText('September'),
      translateText('October'),
      translateText('November'),
      translateText('December'),
    ],
    weekdaysShort: [
      translateText('Su'),
      translateText('Mo'),
      translateText('Tu'),
      translateText('We'),
      translateText('Th'),
      translateText('Fr'),
      translateText('Sa'),
    ],
  })

  addDayToRange = (day) => {
    let state
    const {
      range: { from, to },
    } = this.state
    if (from && to) {
      state = {
        range: {
          from: day,
          to: null,
        },
      }
    } else {
      state = { range: DateUtils.addDayToRange(day, this.state.range) }
    }

    return state
  }

  handleDayClick = (day) => {
    let state
    let forceSubmit = false
    if (this.props.range) {
      // to close the modal if the same day is clicked
      forceSubmit =
        this.props.singleDate &&
        this.state.range.from &&
        DateUtils.isSameDay(this.state.range.from, day)

      state = this.addDayToRange(day)

      // to submit if you click a single time in a day and then click away
      if (this.props.singleDate && !this.props.buttonText) {
        if (!this.state.range.to) {
          this.props.onSubmit({
            startDate: state.range.from,
            endDate: state.range.from,
          })
        }
      }
      if (
        !(day.getTime() > state.range.from.getTime()) &&
        state.range.to !== undefined
      ) {
        state = {
          range: {
            from: day,
            to: null,
          },
        }
      }
    } else {
      state = { date: day }
    }
    this.setState(
      () => state,
      () => !this.props.buttonText && this.onSubmitWrapper(forceSubmit),
    )
  }

  handleCalendarOpen = () =>
    !this.props.disabled && this.setState(() => ({ calendarIsOpen: true }))

  handleCalendarClose = () => this.setState(() => ({ calendarIsOpen: false }))

  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.handleCalendarClose()
    }
  }

  render() {
    const { buttonText, placeholder, disabled, className } = this.props

    let dayPickerProps

    const { calendarIsOpen } = this.state

    if (this.props.range) {
      const {
        range: { from, to },
      } = this.state
      const modifiers = { start: from, end: to }

      dayPickerProps = {
        selectedDays: [from, { from, to }],
        ...this.getDefaultDayPickerProps(),
        modifiers,
      }
    } else {
      dayPickerProps = {
        selectedDays: [this.state.date],
        ...this.getDefaultDayPickerProps(),
      }
    }

    return (
      <div
        ref={(node) => {
          this.node = node
        }}
        className={classNames(className, styles.DatePicker, {
          [styles.open]: calendarIsOpen,
          [styles.disabled]: disabled,
        })}
      >
        <span
          className={classNames(styles.datePickerIcon, {
            [styles.disabledIcon]: disabled,
          })}
          onClick={
            calendarIsOpen ? this.handleCalendarClose : this.handleCalendarOpen
          }
        >
          <input
            className={classNames(styles.datePickerInput, 'notranslate')}
            type="text"
            placeholder={placeholder}
            value={this.getInputValue()}
            disabled={disabled}
            readOnly
          />
        </span>
        {calendarIsOpen && (
          <DayPicker
            className={classNames('SelectableJoorTable', 'notranslate')}
            {...dayPickerProps}
            onDayClick={this.handleDayClick}
          />
        )}
        {calendarIsOpen && buttonText && (
          <Button
            secondary
            onClick={() =>
              this.onSubmitWrapper(
                this.props.singleDate && this.state.range.from,
              )
            }
            className={styles.datePickerButton}
          >
            {buttonText}
          </Button>
        )}
      </div>
    )
  }
}

DatePicker.propTypes = {
  className: propTypes.string,
  buttonText: propTypes.string,
  onSubmit: propTypes.func,
  placeholder: propTypes.string,
  disabled: propTypes.bool,
  startDate: propTypes.string,
  endDate: propTypes.string,
  date: propTypes.string,
  range: propTypes.bool,
  singleDate: propTypes.bool,
  dateFormat: propTypes.string,
}

DatePicker.defaultProps = {
  className: null,
  buttonText: '',
  disabled: false,
  onSubmit: () => {},
  startDate: undefined,
  endDate: undefined,
  date: undefined,
  placeholder: '',
  range: false,
  singleDate: false,
  dateFormat: 'MM/DD/YYYY',
}

export default DatePicker
