import { handleActions } from 'redux-actions'

import {
  CHANGE_BRAND_SEARCH_TEXT,
  CHANGE_COLLECTION_SEARCH_TEXT,
  SET_BRAND_ID,
} from './constants'

export const defaultState = {
  brandSearchText: '',
  brandId: '',
  collectionSearchText: '',
}

const reducer = handleActions(
  {
    [CHANGE_BRAND_SEARCH_TEXT]: (state, { payload }) => ({
      ...state,
      brandSearchText: payload,
    }),
    [SET_BRAND_ID]: (state, { payload }) => ({
      ...state,
      brandId: payload,
    }),
    [CHANGE_COLLECTION_SEARCH_TEXT]: (state, { payload }) => ({
      ...state,
      collectionSearchText: payload,
    }),
  },
  defaultState,
)

export default reducer
