import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import {
  factorToPercent,
  formatDecimalValue,
  percentToFactor,
} from 'utils/formatters'

import Input from './Input'
import styles from './Input.less'

const CURRENCY_SCALE = 2

// TODO(ch18341): The logic around adjustmentType and percent is very specific to
// the PriceModifers component. That logic should be extracted into that component
// making the Number input a much simpler component
// https://app.clubhouse.io/joor/story/18341/refactor-price-modifier-logic-out-of-number-input

// Props can be viewed here: https://react.semantic-ui.com/elements/input
class Number extends Component {
  constructor(props) {
    super(props)
    const { defaultValue, percent, isCurrency, adjustmentType } = this.props
    const value = this.formatValue(
      defaultValue,
      percent,
      isCurrency,
      adjustmentType,
    )

    this.state = { value }
  }

  componentDidUpdate(prevProps) {
    const { defaultValue, percent, isCurrency, adjustmentType } = this.props
    /* eslint-disable react/no-did-update-set-state */
    if (
      prevProps.percent !== percent ||
      prevProps.defaultValue !== defaultValue
    ) {
      const value = this.formatValue(
        defaultValue,
        percent,
        isCurrency,
        adjustmentType,
      )
      this.setState(() => ({ value }))
    }
    /* eslint-enable react/no-did-update-set-state */
  }

  formatValue = (defaultValue, percent, isCurrency, adjustmentType) => {
    if (defaultValue === '') {
      return defaultValue
    }
    if (percent)
      return formatDecimalValue(
        factorToPercent({ value: defaultValue, adjustmentType }),
      )
    if (isCurrency) return formatDecimalValue(defaultValue, 2)
    return formatDecimalValue(defaultValue)
  }

  handleBlur = () => {
    const {
      adjustmentType,
      allowEmptyValue,
      defaultValue,
      isCurrency,
      onBlur,
      percent,
    } = this.props

    return (event, args) => {
      const decimalValue = isCurrency
        ? formatDecimalValue(this.state.value, 2)
        : formatDecimalValue(this.state.value)

      const emptyValue = allowEmptyValue ? '' : defaultValue
      const value = this.state.value !== '' ? decimalValue : emptyValue

      if (percent) {
        onBlur(event, {
          ...args,
          defaultValue,
          value: percentToFactor({ value, adjustmentType }),
        })
      } else {
        onBlur(event, { ...args, defaultValue, value })
      }

      this.setState(() => ({ value }))
    }
  }

  handleChange = () => {
    const { onChange, maxAmount, allowComma, onNotifyChange } = this.props

    return (event, args) => {
      const removedBadCharacters = this.handleValueRemoveBadCharacters(
        args.value,
      )
      const singleDecimalValue = this.handleValueRemoveMultipleDecimals(
        removedBadCharacters,
      )
      let value = this.handleValuePrecisionAndScale(singleDecimalValue)
      if (allowComma === false) {
        value = value.replace(/,/g, '')
      }
      if (Boolean(maxAmount) && value >= maxAmount) {
        value = maxAmount.toString()
      }

      this.setState(() => ({ value }))
      const noCommas = value.replace(/,/g, '')
      onChange(event, { ...args, value: noCommas })
      onNotifyChange(noCommas)
    }
  }

  handleFocus = () => {
    const { clearDefaultValueOnFocus, defaultValue, onFocus } = this.props

    return (event, args) => {
      if (clearDefaultValueOnFocus && this.state.value === defaultValue) {
        this.setState(() => ({ value: '' }))
      }
      if (onFocus) {
        onFocus(event, {
          ...args,
          value: this.state.value,
          changeInputValue: (value) => this.setState(() => ({ value })),
        })
      }
    }
  }

  handleValueRemoveBadCharacters = (value) => {
    const { onlyNaturalNumbersAllowed } = this.props
    return onlyNaturalNumbersAllowed
      ? value.replace(/[^0-9]/g, '')
      : value.replace(/[^0-9.,]/g, '')
  }

  handleValueRemoveMultipleDecimals = (value) => {
    const splitPeriods = value.split('.')
    const singleDecimalValue =
      splitPeriods.shift() +
      (splitPeriods.length ? `.${splitPeriods.join('')}` : '')

    return singleDecimalValue
  }

  handleValuePrecisionAndScale = (value) => {
    const { precision, scale: propScale, isCurrency } = this.props
    const scale = isCurrency ? CURRENCY_SCALE : propScale
    const hasDecimal = value.indexOf('.') > -1
    const wholeNumberPrecision = hasDecimal
      ? precision - scale + 1
      : precision - scale
    const fractionScale = hasDecimal ? scale + 1 : scale

    let cleanValue = ''
    let wholeNumber = hasDecimal ? value.split('.')[0] : value
    let fraction = hasDecimal ? `.${value.split('.')[1]}` : ''

    if (fraction.length > fractionScale)
      fraction = fraction.slice(0, fractionScale)
    if (wholeNumber.length > wholeNumberPrecision)
      wholeNumber = wholeNumber.slice(0, wholeNumberPrecision)

    cleanValue = wholeNumber + fraction
    return cleanValue
  }

  render() {
    const {
      id,
      className,
      disabled,
      success,
      percent,
      placeholder,
    } = this.props

    const inputArgs = {
      id,
      onBlur: this.handleBlur(),
      onChange: this.handleChange(),
      onFocus: this.handleFocus(),
      value: this.state.value,
      disabled,
      placeholder,
    }
    if (this.props['aria-label']) {
      inputArgs['aria-label'] = this.props['aria-label']
    }

    return (
      <div
        data-testid="number-input"
        className={classNames(
          styles.Input,
          { [styles.success]: success, [styles.disabled]: disabled },
          className,
        )}
      >
        <Input {...inputArgs} />
        {percent && (
          <span
            className={classNames(styles.percent, {
              [styles.disabled]: disabled,
            })}
          >
            %
          </span>
        )}
      </div>
    )
  }
}

Number.propTypes = {
  allowEmptyValue: PropTypes.bool,
  className: PropTypes.string,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  clearDefaultValueOnFocus: PropTypes.bool,
  defaultValue: PropTypes.any,
  isCurrency: PropTypes.bool,
  scale: PropTypes.number,
  precision: PropTypes.number,
  percent: PropTypes.bool,
  adjustmentType: PropTypes.oneOf(['ADDITIVE', 'SUBTRACTIVE', 'MARKUP']),
  onlyNaturalNumbersAllowed: PropTypes.bool,
  allowComma: PropTypes.bool,
  placeholder: PropTypes.string,
  maxAmount: PropTypes.number,
  onNotifyChange: PropTypes.func,
}

Number.defaultProps = {
  allowEmptyValue: true,
  className: null,
  success: false,
  disabled: false,
  onChange: () => {},
  onBlur: () => {},
  clearDefaultValueOnFocus: false,
  defaultValue: '',
  isCurrency: false,
  scale: 6, // Total number of decimal places (ie. scale of 3 could be 22.256)
  precision: 15, // Total number of decimals (ie. precision of 8 could be 12345678, or 12345.678)
  percent: false, // Is this a decimal or percentage value? (If percentage, suffix should be %)
  adjustmentType: null,
  onlyNaturalNumbersAllowed: false,
  allowComma: true,
  onNotifyChange: () => {}, // Used to update a parent state any time the input content changes
}

export default Number
