export const ADD_ELEMENT_TO_STYLEBOARD = 'ADD_ELEMENT_TO_STYLEBOARD'
export const DELETE_ELEMENT_FROM_STYLEBOARD = 'DELETE_ELEMENT_FROM_STYLEBOARD'
export const INCREASE_POLL_INTERVAL = 'INCREASE_POLL_INTERVAL'
export const RESET_POLL_INTERVAL = 'RESET_POLL_INTERVAL'
export const STOP_POLLING = 'STOP_POLLING'
export const SET_GROUPING_CRITERIA = 'SET_GROUPING_CRITERIA'
export const SET_NEW_STYLEBOARD = 'SET_NEW_STYLEBOARD'
export const SET_IS_SAVING = 'SET_IS_SAVING'
export const SET_STATIC_ELEMENT_IN_STYLEBOARD =
  'SET_STATIC_ELEMENT_IN_STYLEBOARD'
export const SET_STYLEBOARD = 'SET_STYLEBOARD'
export const SET_STYLEBOARD_LAYOUT = 'SET_STYLEBOARD_LAYOUT'
export const SET_STYLEBOARD_NAME = 'SET_STYLEBOARD_NAME'
export const SET_SYNCED_FROM_REMOTE = 'SET_SYNCED_FROM_REMOTE'
export const SET_TEXT_IN_STYLEBOARD = 'SET_TEXT_IN_STYLEBOARD'
export const TOGGLE_COMMENTS_MODE = 'TOGGLE_COMMENTS_MODE'
export const TOGGLE_GROUP_MODE = 'TOGGLE_GROUP_MODE'
export const TOGGLE_SHOW_PRODUCT_INFO = 'TOGGLE_SHOW_PRODUCT_INFO'
