import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  Dropdown,
  MultiSelectAsyncDropdown,
  MultiSelectDropdown,
  Option,
  TextArea,
} from '@joor/design-system'
import { sortBy } from 'lodash'
import styled from 'styled-components'

import { Brand } from '__generated__/atlas-types'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { BusinessInfoView } from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.ids'

import { useLazyBrands, useProductLines } from './BusinessInfo.hooks'
import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

export const WHOLESALE_MIN_OPTIONS = [
  { label: '$10', value: '10' },
  { label: '$50', value: '50' },
  { label: '$100', value: '100' },
  { label: '$250', value: '250' },
  { label: '$500', value: '500' },
]

const getWholesaleMaxDisabledValue = (
  optionValue: number,
  wholesaleMinValue?: string | null,
) => parseInt(wholesaleMinValue || '0', 10) >= optionValue

export const getWholesaleMaxOptions = (wholesaleMinValue?: string | null) => [
  {
    label: '$150',
    value: '150',
    isDisabled: getWholesaleMaxDisabledValue(150, wholesaleMinValue),
  },
  {
    label: '$250',
    value: '250',
    isDisabled: getWholesaleMaxDisabledValue(250, wholesaleMinValue),
  },
  {
    label: '$500',
    value: '500',
    isDisabled: getWholesaleMaxDisabledValue(500, wholesaleMinValue),
  },
  {
    label: '$750',
    value: '750',
    isDisabled: getWholesaleMaxDisabledValue(750, wholesaleMinValue),
  },
  {
    label: '>$1000',
    value: '1000',
    isDisabled: getWholesaleMaxDisabledValue(1000, wholesaleMinValue),
  },
]

type BusinessInfoProps = {
  description?: string
  address?: Partial<RetailerProfileLocation>
  setDescription: Dispatch<SetStateAction<string>>
  setAddress: Dispatch<SetStateAction<Partial<RetailerProfileLocation>>>
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 8px;
  max-height: 100%;
  overflow: auto;
  && {
    padding: 16px 24px 24px;
  }
`
const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  > * {
    width: 100%;
    flex-basis: 50%;
  }
`
const StyledRowLabel = styled.div`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  letter-spacing: 0.6px;
  margin: 8px 0;
`
const StyledTextArea = styled(TextArea)`
  max-height: 200px;
`

const BusinessInfo: React.FC<BusinessInfoProps> = ({
  description,
  setDescription,
  address,
  setAddress,
}) => {
  const defaultWholesaleMaxOptions = getWholesaleMaxOptions(null)
  const [brandSearchText, setBrandSearchText] = useState<string>('')
  const [brandOptions, setBrandOptions] = useState<Option[]>([])
  const [categoryOptions, setCategoryOptions] = useState<Option[]>([])
  const [wholesaleMaxOptions, setWholesaleMaxOptions] = useState<Option[]>(
    defaultWholesaleMaxOptions,
  )
  const { loading: brandsLoading, brands, getBrands } = useLazyBrands(
    brandSearchText,
  )
  const { loading: productLineLoading, productLines } = useProductLines()

  const selectedCategories =
    address?.categories?.map((category) => ({
      value: category.id,
      label: category.name || '',
    })) || ([] as Option[])
  const selectedCarriedBrands =
    address?.brandsCarried?.map((carriedBrand) => ({
      value: carriedBrand.id,
      label: carriedBrand.name || '',
    })) || ([] as Option[])
  const carriedBrandOptions = selectedCarriedBrands.concat(brands)
  const getBrandOptions = async (inputValue: string) => {
    if (inputValue.length > 2) {
      setBrandSearchText(inputValue)
    } else if (!inputValue.length) {
      setBrandSearchText('')
    }
    const result = await getBrands()
    const brandOptions = relayConnectionToArray(result?.data?.brands)?.map(
      (brand: Brand) => ({
        label: brand?.name,
        value: brand?.id,
      }),
    )
    return brandOptions
  }

  useEffect(() => {
    const brandIds = address?.brandsCarried?.map(
      (carriedBrand) => carriedBrand.id,
    )
    const options = sortBy(
      brands.filter((brand: Option) => !brandIds?.includes(brand.value)),
      'label',
    )
    setBrandOptions(options)
    getBrandOptions(brandSearchText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brands.length])
  useEffect(() => {
    const selectedCategoryIds = address?.categories?.map(
      (category) => category.id,
    )
    const options = productLines.filter(
      (productLine: Option) =>
        !selectedCategoryIds?.includes(productLine.value),
    )
    setCategoryOptions(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productLines.length, selectedCategories.length])
  useEffect(() => {
    const updatedWholesaleMaxOptions = getWholesaleMaxOptions(
      address?.wholesaleRange?.max,
    )
    setWholesaleMaxOptions(updatedWholesaleMaxOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.wholesaleRange?.min])

  const wholesaleValueOption =
    parseInt(address?.wholesaleRange?.min ?? '10', 10) <
    parseInt(address?.wholesaleRange?.max ?? '150', 10)
      ? wholesaleMaxOptions.find(
          (option) => option.value === address?.wholesaleRange?.max,
        )
      : undefined

  return (
    <StyledContainer data-testid={BusinessInfoView.Component}>
      <StyledTextArea
        id={BusinessInfoView.DescriptionField}
        className="noTranslate"
        label={'About Us Description'}
        placeholder="Give brands an introduction to your business..."
        value={description}
        onChange={(event): void => setDescription(event.target?.value)}
        isRequired
      />
      <div>
        <StyledRowLabel>Wholesale Price Point</StyledRowLabel>
        <StyledRow>
          <Dropdown
            id={BusinessInfoView.WholesaleMinDropdown}
            label="Minimum Price"
            value={WHOLESALE_MIN_OPTIONS.find(
              (option) => option.value === address?.wholesaleRange?.min,
            )}
            onSelect={({ selected }: { selected: Option }): void => {
              const maxValue =
                wholesaleMaxOptions.find(
                  (option) => option.value === address?.wholesaleRange?.max,
                )?.value || 0
              const isMinValueHigherThanMax = selected?.value >= maxValue
              setAddress({
                ...address,
                ...(isMinValueHigherThanMax && { wholesaleMax: null }),
                wholesaleRange: {
                  max: isMinValueHigherThanMax
                    ? null
                    : address?.wholesaleRange?.max,
                  min: selected?.value,
                },
              })
            }}
            options={WHOLESALE_MIN_OPTIONS}
            isRequired
            placeholder="Select one"
          />
          <Dropdown
            id={BusinessInfoView.WholesaleMaxDropdown}
            key={wholesaleValueOption}
            label="Maximum Price"
            value={wholesaleValueOption}
            onSelect={({ selected }: { selected: Option }): void =>
              setAddress({
                ...address,
                wholesaleRange: {
                  ...address?.wholesaleRange,
                  max: selected?.value,
                },
              })
            }
            options={wholesaleMaxOptions}
            isRequired
            placeholder="Select one"
          />
        </StyledRow>
      </div>
      <MultiSelectDropdown
        id={BusinessInfoView.CategoriesDropdown}
        label="Categories"
        isRequired
        value={selectedCategories}
        options={categoryOptions}
        isLoading={productLineLoading}
        onSelect={({ selected }: { selected: Option }): void => {
          setAddress({
            ...address,
            categories: [
              ...(address?.categories || []),
              { id: selected?.value, name: selected.label },
            ],
          })
        }}
        onDeselect={({ deselected }: { deselected: Option }): void => {
          const updatedCategories = address?.categories?.filter(
            (category) => category.id !== deselected.value,
          )
          setAddress({
            ...address,
            categories: updatedCategories,
          })
        }}
        placeholder="Select Categories"
        maxOptionsToShow={5}
      />
      <MultiSelectAsyncDropdown
        id={BusinessInfoView.CarriedBrandsDropdown}
        maxOptionsToShow={5}
        label="Brands Carried"
        isRequired
        options={carriedBrandOptions}
        placeholder="Select Brands"
        value={selectedCarriedBrands}
        defaultOptions={brandOptions}
        getOptions={getBrandOptions}
        isLoading={brandsLoading}
        onSelect={({ selected }: { selected: Option }): void => {
          setAddress({
            ...address,
            brandsCarried: [
              ...(address?.brandsCarried || []),
              { id: selected?.value, name: selected.label },
            ],
          })
        }}
        onDeselect={({ deselected }: { deselected: Option }): void => {
          const updatedCarriedBrands = address?.brandsCarried?.filter(
            (carriedBrand) => carriedBrand.id !== deselected.value,
          )
          setAddress({
            ...address,
            brandsCarried: updatedCarriedBrands,
          })
        }}
      />
    </StyledContainer>
  )
}

export default BusinessInfo
