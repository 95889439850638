import classNames from 'classnames'
import PropTypes from 'prop-types'

import Checkbox from 'components/Core/Checkbox/Checkbox'
import Icon from 'components/Core/Icon/Icon'
import Table from 'components/Core/Table/Table'

import styles from './Table.less'

const renderExpansionIcon = (isRowExpanded) => {
  const iconName = isRowExpanded ? 'dropup' : 'chevron'
  return <Icon name={iconName} />
}

const ItemTableHeaderRow = ({
  collapsing,
  columns,
  columnOrder,
  selectableItemIds,
  isAllItemsSelected,
  singleSelect,
  hasSelectColumn,
  isForExpandableTable,
  hasFirstEmptyColumn,
  anyRowExpanded,
  toggleSelectItems,
  indicator,
  className,
  onExpandAllRows,
}) => (
  <Table.Row className={className}>
    {indicator && <Table.HeaderCell width="indicator"> </Table.HeaderCell>}
    {isForExpandableTable && (
      <Table.HeaderCell
        onClick={() => {
          return onExpandAllRows()
        }}
        className={styles.expandableRow}
      >
        {renderExpansionIcon(anyRowExpanded)}
      </Table.HeaderCell>
    )}
    {hasFirstEmptyColumn && <Table.HeaderCell> </Table.HeaderCell>}
    {hasSelectColumn && (
      <Table.HeaderCell
        width="checkbox"
        emptyCell={singleSelect}
        className={classNames({ [styles.noPaddingLeft]: indicator })}
        collapsing={collapsing}
      >
        <Checkbox
          onClick={() => toggleSelectItems(selectableItemIds)}
          checked={isAllItemsSelected}
        />
      </Table.HeaderCell>
    )}
    {columnOrder.map((id) => (
      <Table.HeaderCell
        {...columns[id].align}
        key={id}
        width={columns[id].width}
      >
        {columns[id].description}
      </Table.HeaderCell>
    ))}
  </Table.Row>
)

ItemTableHeaderRow.propTypes = {
  /* array of all item ids that can be selected in the table */
  selectableItemIds: PropTypes.arrayOf(PropTypes.string),
  /*
  The prop columns takes an object containing columnId as key.
  {
    columnId: {
      description: 'Editable', // Table header label
      width: 'small', // Width of the column
      cellContent: item => item.editableOn, // function determining cell contents
    },
  }
  */
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.object.isRequired,
  /* array of Column Id in the order that they should be displayed */
  columnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  collapsing: PropTypes.bool,
  isAllItemsSelected: PropTypes.bool,
  singleSelect: PropTypes.bool,
  className: PropTypes.string,
  toggleSelectItems: PropTypes.func,
  hasSelectColumn: PropTypes.bool,
  indicator: PropTypes.bool,
  isForExpandableTable: PropTypes.bool,
  hasFirstEmptyColumn: PropTypes.bool,
  onExpandAllRows: PropTypes.func,
  anyRowExpanded: PropTypes.bool,
}

ItemTableHeaderRow.defaultProps = {
  collapsing: false,
  isAllItemsSelected: false,
  selectableItemIds: [],
  singleSelect: false,
  className: '',
  toggleSelectItems: () => {},
  hasSelectColumn: true,
  indicator: false,
  isForExpandableTable: false,
  hasFirstEmptyColumn: false,
  onExpandAllRows: () => {},
}

export default ItemTableHeaderRow
