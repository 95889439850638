import PropTypes from 'prop-types'

import Toaster from 'components/Core/Toaster/Toaster'

import styles from './ExceptionHandler.less'

const ExceptionHandler = ({ errorMessages, handleClearErrorMessages }) => (
  <div className={styles.ExceptionHandler}>
    {errorMessages && errorMessages.length > 0 && (
      <Toaster
        onDismiss={handleClearErrorMessages}
        headerMessage="Your changes were not saved"
        error
      >
        {errorMessages.map((error) => (
          <p key={error}>{error}</p>
        ))}
      </Toaster>
    )}
  </div>
)

ExceptionHandler.propTypes = {
  errorMessages: PropTypes.array,
  handleClearErrorMessages: PropTypes.func,
}

ExceptionHandler.defaultProps = {
  errorMessages: [],
  handleClearErrorMessages: () => {},
}

export default ExceptionHandler
