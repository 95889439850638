import get from 'lodash/get'
import { createSelector } from 'reselect'

export const TYPE_BRAND = 2
export const TYPE_RETAILER = 3
export const BRAND_ACCOUNT = 'BrandAccount'
export const RETAILER_ACCOUNT = 'RetailerAccount'
export const SUBTYPE_LITE_BRAND = 'LIMITED_ACCESS_BRAND'
export const SUBTYPE_CORE_BRAND = 'PROXY'

export const RESTRICT_EDIT_STYLES = 'RESTRICT_EDIT_STYLES'
export const RESTRICT_ADD_OR_CREATE_STYLES = 'RESTRICT_ADD_OR_CREATE_STYLES'
export const RESTRICT_ADD_EDIT_LINESHEET = 'RESTRICT_ADD_EDIT_LINESHEET'
export const RESTRICT_ARCHIVE_ADMIN_LINESHEETS =
  'RESTRICT_ARCHIVE_ADMIN_LINESHEETS'
export const RESTRICT_DELETE_ADMIN_LINESHEET = 'RESTRICT_DELETE_ADMIN_LINESHEET'
export const RESTRICT_SYNC_LINESHEETS_TO_IPAD =
  'RESTRICT_SYNC_LINESHEETS_TO_IPAD'
export const RESTRICT_EDIT_PRIVACY_ADMIN_SETTINGS_LINESHEETS =
  'RESTRICT_EDIT_PRIVACY_ADMIN_SETTINGS_LINESHEETS'
export const RESTRICT_USER_FROM_CREATING_CUSTOM_PO =
  'RESTRICT_USER_FROM_CREATING_CUSTOM_PO'
export const ALLOW_EDIT_INVENTORY_SETTINGS = 'ALLOW_EDIT_INVENTORY_SETTINGS'
export const RESTRICT_EDIT_APPROVED_SHIPPED_ORDERS =
  'RESTRICT_EDIT_APPROVED_SHIPPED_ORDERS'
export const RESTRICT_CANCEL_STYLES = 'RESTRICT_CANCEL_STYLES'
export const CREATE_RETAILER = 'CREATE_RETAILER'
export const ALLOW_EDIT_RETAILER_SETTINGS = 'ALLOW_EDIT_RETAILER_SETTINGS'
export const RESTRICT_EDIT_CURRENCIES = 'RESTRICT_EDIT_CURRENCIES'
export const RESTRICT_EDIT_WAREHOUSES = 'RESTRICT_EDIT_WAREHOUSES'
export const RETAILER_SUBMIT_NOTES_IF_NE_INVENTORY =
  'RETAILER_SUBMIT_NOTES_IF_NE_INVENTORY'
export const RESTRICT_BRAND_USER_OVERSELL = 'RESTRICT_BRAND_USER_OVERSELL'
export const RESTRICT_BRAND_USER_APPROVE_ORDER =
  'RESTRICT_BRAND_USER_APPROVE_ORDER'
export const RESTRICT_GEOGRAPHIC_REGION = 'RESTRICT_GEOGRAPHIC_REGION'
export const RESTRICT_ADD_EDIT_EVENTS = 'RESTRICT_ADD_EDIT_EVENTS'
export const ALLOW_SCHEDULE_ASSORTMENT_EXPORT =
  'ALLOW_SCHEDULE_ASSORTMENT_EXPORT'
export const ALLOW_MARK_ORDERS_EXPORTED = 'ALLOW_MARK_ORDERS_EXPORTED'
export const ALLOW_FORCE_EXPORT_ORDERS = 'ALLOW_FORCE_EXPORT_ORDERS'
export const RESTRICT_ASSORTMENT_EDIT_DONE = 'RESTRICT_ASSORTMENT_EDIT_DONE'
export const RESTRICT_ASSORTMENT_ARCHIVE = 'RESTRICT_ASSORTMENT_ARCHIVE'
export const RESTRICT_ASSORTMENT_UNARCHIVE = 'RESTRICT_ASSORTMENT_UNARCHIVE'
export const RETAILER_FILTER_AVAILABILITY = 'RETAILER_FILTER_AVAILABILITY'
export const ALLOW_EDIT_RETAIL_BUDGETS = 'ALLOW_EDIT_RETAIL_BUDGETS'
export const ALLOW_EDIT_SEASONS = 'ALLOW_EDIT_SEASONS'
export const ALLOW_EDIT_SIZE_MAPPING = 'ALLOW_EDIT_SIZE_MAPPING'
export const ALLOW_EDIT_RETAIL_DOOR = 'ALLOW_EDIT_RETAIL_DOOR'
export const ALLOW_EDIT_TAG_LIBRARIES = 'ALLOW_EDIT_TAG_LIBRARIES'
export const ALLOW_EDIT_ASSORTMENT_TYPES = 'ALLOW_EDIT_ASSORTMENT_TYPES'
export const ALLOW_EDIT_ASSORTMENT_DELIVERIES =
  'ALLOW_EDIT_ASSORTMENT_DELIVERIES'
export const ALLOW_EDIT_ASSORTMENT_EVENTS = 'ALLOW_EDIT_ASSORTMENT_EVENTS'
export const RESTRICT_EDIT_PO_NUMBER = 'RESTRICT_EDIT_PO_NUMBER'
export const ALLOW_EXPORT_ASSORTMENTS = 'ALLOW_EXPORT_ASSORTMENTS'
export const ALLOW_EXPORT_FTP_FROM_WEB = 'ALLOW_EXPORT_FTP_FROM_WEB'
export const RESTRICT_COPY_ASSORTMENT = 'RESTRICT_COPY_ASSORTMENT'
export const RESTRICT_EDIT_BRAND_STYLE_NUMBER =
  'RESTRICT_EDIT_BRAND_STYLE_NUMBER'
export const RESTRICT_EDIT_BRAND_STYLE_NAME = 'RESTRICT_EDIT_BRAND_STYLE_NAME'
export const RESTRICT_EDIT_BRAND_COLOR_NAME = 'RESTRICT_EDIT_BRAND_COLOR_NAME'
export const RESTRICT_EDIT_BRAND_COLOR_CODE = 'RESTRICT_EDIT_BRAND_COLOR_CODE'
export const RESTRICT_EDIT_CUSTOM_PO_NUMBER = 'RESTRICT_EDIT_CUSTOM_PO_NUMBER'
export const ALLOW_MANAGE_ALL_USER_BRAND_DOORS =
  'ALLOW_MANAGE_ALL_USER_BRAND_DOORS'
export const ALLOW_EDIT_SUPPLIERS = 'ALLOW_EDIT_SUPPLIERS'
export const RESTRICT_REMOVE_CONNECTIONS = 'RESTRICT_REMOVE_CONNECTIONS'
export const RESTRICT_SHARE_LINESHEETS = 'RESTRICT_SHARE_LINESHEETS'
export const CAN_ASSIGN_ORDERS_TO_NEW_CONNECTIONS =
  'CAN_ASSIGN_ORDERS_TO_NEW_CONNECTIONS'
export const ALLOW_MANAGE_PAYMENTS = 'ALLOW_MANAGE_PAYMENTS'
export const ALLOW_INVOICE_RETAILERS = 'ALLOW_INVOICE_RETAILERS'
export const ALLOW_MANAGE_PERMISSIONS = 'ALLOW_MANAGE_PERMISSIONS'
export const RESTRICT_ADD_STYLES_WHEN_SHIP_BEFORE_LINESHEET_SHIP =
  'RESTRICT_ADD_STYLES_WHEN_SHIP_BEFORE_LINESHEET_SHIP'
export const RESTRICT_CHANGE_DELIVERY_DATES = 'RESTRICT_CHANGE_DELIVERY_DATES'
export const RESTRICT_CHANGE_ORDER_START_SHIP_BEFORE_LINESHEET_START_SHIP =
  'RESTRICT_CHANGE_ORDER_START_SHIP_BEFORE_LINEHSEET_START_SHIP'
export const RESTRICT_USER_FRM_PLACING_ORDER_MIN =
  'RESTRICT_USER_FRM_PLACING_ORDER_MIN'
export const RESTRICT_APPLY_DISCOUNTS = 'RESTRICT_APPLY_DISCOUNTS'
export const ALLOW_MAKE_LINESHEETS_PUBLIC = 'ALLOW_MAKE_LINESHEETS_PUBLIC'
export const RESTRICT_USER_TO_MOVE_PRODUCTS = 'RESTRICT_USER_TO_MOVE_PRODUCTS'
export const ALLOW_APPLY_CUSTOM_STYLE_BADGES_TO_ADMIN_LINESHEETS =
  'ALLOW_APPLY_CUSTOM_STYLE_BADGES_TO_ADMIN_LINESHEETS'
export const RESTRICT_EDIT_BILLING_SHIPPING = 'RESTRICT_EDIT_BILLING_SHIPPING'
export const ALLOW_EDIT_STYLES_IMAGE = 'ALLOW_EDIT_STYLES_IMAGE'
export const RESTRICT_DELETE_STYLES = 'RESTRICT_DELETE_STYLES'

export const getCurrentUser = (state) => state.currentUser
const createCurrentUserSelector = (selector) =>
  createSelector(getCurrentUser, selector)
const getAccount = createCurrentUserSelector(
  (currentUser) => currentUser.account,
)
const createAccountSelector = (selector) => createSelector(getAccount, selector)

export const getUserId = createCurrentUserSelector(
  (currentUser) => currentUser.userId,
)

export const getDateFormat = createCurrentUserSelector(
  (currentUser) => currentUser.dateFormat,
)

export const getUserName = createCurrentUserSelector(
  (currentUser) => currentUser.name,
)

export const getUserEmail = createCurrentUserSelector(
  (currentUser) => currentUser.email,
)

export const getUserApiHost = createCurrentUserSelector(
  (currentUser) => currentUser.apiHost,
)

const createAccountTypeSelector = (type) =>
  createAccountSelector((account) => account?.type === type)
export const isAccountTypeBrand = createAccountTypeSelector(TYPE_BRAND)
export const isAccountTypeRetailer = createAccountTypeSelector(TYPE_RETAILER)

export const userCanFindConnections = createAccountSelector(
  (account) => account.canFindConnections,
)
export const userCanShop = createAccountSelector((account) => account.canShop)
export const userCanViewAssortments = createAccountSelector(
  (account) => account.canViewAssortments,
)
export const userCanViewDataPortal = createAccountSelector(
  (account) => account.canViewDataPortal,
)
export const userIsAdmin = createCurrentUserSelector(
  (currentUser) => currentUser.isAdmin,
)
export const userIsOwner = createCurrentUserSelector(
  (currentUser) => currentUser.isOwner,
)
export const userIsInternal = createCurrentUserSelector(
  (currentUser) => currentUser.isInternal,
)
export const userIsLoggedInAsAdmin = createCurrentUserSelector(
  (currentUser) => currentUser.loggedAsAdmin,
)
export const userIsLoggedIn = createCurrentUserSelector((currentUser) =>
  get(currentUser, 'isLoggedIn', false),
)
export const userLastLogin = createCurrentUserSelector(
  (currentUser) => currentUser.lastLogin,
)
export const userCanViewRetailerSettings = createCurrentUserSelector(
  (currentUser) => currentUser.canViewRetailerSettings,
)
export const userCanTransact = createAccountSelector(
  (account) => account?.canTransact,
)

export const getDisplayLanguage = createCurrentUserSelector(
  (currentUser) => currentUser.displayLanguage,
)

export const hasLimitedAccess = createAccountSelector(
  (account) => account.limitedAccess,
)
export const isLiteBrand = (state) =>
  isAccountTypeBrand(state) && hasLimitedAccess(state)

const isAccountTypeCoreBrand = createAccountSelector(
  (account) => account.coreBrand,
)
export const isCoreBrand = (state) =>
  isAccountTypeBrand(state) && isAccountTypeCoreBrand(state)

export const isProBrand = (state) =>
  isAccountTypeBrand(state) && !hasLimitedAccess(state)

export const isProRetailer = (state) =>
  isAccountTypeRetailer(state) && userCanViewAssortments(state)

export const isLiteRetailer = (state) =>
  isAccountTypeRetailer(state) && !isProRetailer(state)

const getAccountType = (state) =>
  isAccountTypeRetailer(state) ? RETAILER_ACCOUNT : BRAND_ACCOUNT

export const getAccountTypeAndLevel = (state) => {
  const accountType = getAccountType(state)
  const isRetailer = accountType === RETAILER_ACCOUNT
  if (isRetailer) {
    const isLite = isLiteRetailer(state)
    const accountLevel = isLite ? 'Lite' : 'Pro'
    return ['Retail', accountLevel]
  } else {
    const isLite = isLiteBrand(state)
    const accountLevel = isLite ? 'Lite' : 'Pro'
    return ['Brand', accountLevel]
  }
}

export const getAccountProfileName = createAccountSelector(
  (account) => account.profileName,
)
export const getAccountId = createAccountSelector(
  (account) => account.accountId,
)
export const getAccountLogoURL = createAccountSelector(
  (account) => account.logoURL,
)

export const isAccountUserPermissionSet = (permission) =>
  createCurrentUserSelector((currentUser) =>
    currentUser.settings?.accountUserPermissions.some(
      (userPermission) => userPermission.key === permission,
    ),
  )

export const isAssortmentUnarchivedRestricted = isAccountUserPermissionSet(
  RESTRICT_ASSORTMENT_UNARCHIVE,
)

export const isAssortmentArchivedRestricted = isAccountUserPermissionSet(
  RESTRICT_ASSORTMENT_ARCHIVE,
)

export const isCancelStylesRestricted = isAccountUserPermissionSet(
  RESTRICT_CANCEL_STYLES,
)

export const isMarkOrderExportedAllowed = isAccountUserPermissionSet(
  ALLOW_MARK_ORDERS_EXPORTED,
)

export const isSetToReExportExportOrderAllowed = isAccountUserPermissionSet(
  ALLOW_FORCE_EXPORT_ORDERS,
)

export const isAddEditEventsRestricted = isAccountUserPermissionSet(
  RESTRICT_ADD_EDIT_EVENTS,
)

export const createIsIntegratedWithSelector = (accountName) =>
  createCurrentUserSelector((currentUser) =>
    currentUser.integrations?.includes(accountName),
  )

export const isCreateCustomPoRestricted = isAccountUserPermissionSet(
  RESTRICT_USER_FROM_CREATING_CUSTOM_PO,
)

export const isEditInventorySettingsAllowed = isAccountUserPermissionSet(
  ALLOW_EDIT_INVENTORY_SETTINGS,
)

export const isEditPONumberRestricted = isAccountUserPermissionSet(
  RESTRICT_EDIT_PO_NUMBER,
)

export const isEditStylesRestricted = isAccountUserPermissionSet(
  RESTRICT_EDIT_STYLES,
)

export const isAddOrCreateStylesRestricted = isAccountUserPermissionSet(
  RESTRICT_ADD_OR_CREATE_STYLES,
)

export const isEditApprovedShippedRestricted = isAccountUserPermissionSet(
  RESTRICT_EDIT_APPROVED_SHIPPED_ORDERS,
)

export const isEditBillingShippingRestricted = isAccountUserPermissionSet(
  RESTRICT_EDIT_BILLING_SHIPPING,
)

export const isRemoveConnectionsRestricted = isAccountUserPermissionSet(
  RESTRICT_REMOVE_CONNECTIONS,
)

export const isEditCurrenciesRestricted = isAccountUserPermissionSet(
  RESTRICT_EDIT_CURRENCIES,
)

export const isShareLinesheetsRestricted = isAccountUserPermissionSet(
  RESTRICT_SHARE_LINESHEETS,
)

export const canAssignOrdersToNewConnections = isAccountUserPermissionSet(
  CAN_ASSIGN_ORDERS_TO_NEW_CONNECTIONS,
)

export const allowManagePayments = isAccountUserPermissionSet(
  ALLOW_MANAGE_PAYMENTS,
)

export const allowInvoiceRetailers = isAccountUserPermissionSet(
  ALLOW_INVOICE_RETAILERS,
)

export const allowManagePermissions = isAccountUserPermissionSet(
  ALLOW_MANAGE_PERMISSIONS,
)

export const isAddStylesWhenShipBeforeLinesheetShipRestricted = isAccountUserPermissionSet(
  RESTRICT_ADD_STYLES_WHEN_SHIP_BEFORE_LINESHEET_SHIP,
)

export const isChangingDeliveryDatesRestricted = isAccountUserPermissionSet(
  RESTRICT_CHANGE_DELIVERY_DATES,
)

export const isChangeOrderStartShipBeforeLinesheetRestricted = isAccountUserPermissionSet(
  RESTRICT_CHANGE_ORDER_START_SHIP_BEFORE_LINESHEET_START_SHIP,
)

export const isPlaceOrderUnderMinRestricted = isAccountUserPermissionSet(
  RESTRICT_USER_FRM_PLACING_ORDER_MIN,
)

export const isApproveOrderRestricted = isAccountUserPermissionSet(
  RESTRICT_BRAND_USER_APPROVE_ORDER,
)

export const isApplyDiscountsRestricted = isAccountUserPermissionSet(
  RESTRICT_APPLY_DISCOUNTS,
)

export const isBrandUserOversellingRestricted = isAccountUserPermissionSet(
  RESTRICT_BRAND_USER_OVERSELL,
)

export const allowMakeLinesheetsPublic = isAccountUserPermissionSet(
  ALLOW_MAKE_LINESHEETS_PUBLIC,
)

export const restrictArchiveLinesheet = isAccountUserPermissionSet(
  RESTRICT_ARCHIVE_ADMIN_LINESHEETS,
)

export const restrictDeleteLinesheet = isAccountUserPermissionSet(
  RESTRICT_DELETE_ADMIN_LINESHEET,
)

export const restrictSyncLinesheetsToIpad = isAccountUserPermissionSet(
  RESTRICT_SYNC_LINESHEETS_TO_IPAD,
)

export const restrictEditLinesheetPrivacySettings = isAccountUserPermissionSet(
  RESTRICT_EDIT_PRIVACY_ADMIN_SETTINGS_LINESHEETS,
)

export const restrictAddEditLinesheet = isAccountUserPermissionSet(
  RESTRICT_ADD_EDIT_LINESHEET,
)

export const restrictMergeOrders = isAccountUserPermissionSet(
  RESTRICT_USER_TO_MOVE_PRODUCTS,
)

export const allowEditCustomStylesBadges = isAccountUserPermissionSet(
  ALLOW_APPLY_CUSTOM_STYLE_BADGES_TO_ADMIN_LINESHEETS,
)

export const allowEditStylesImage = isAccountUserPermissionSet(
  ALLOW_EDIT_STYLES_IMAGE,
)

export const isDeleteStylesRestricted = isAccountUserPermissionSet(
  RESTRICT_DELETE_STYLES,
)
