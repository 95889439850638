import { useSelector } from 'react-redux'

import { userIsLoggedIn } from 'store/currentUser/selectors'

import PublicNavbar from './PublicNavbar.component'
import NavbarRevamp from 'features/NavbarRevamp/NavbarRevamp.component'

const PublicNavbarContainer = () => {
  const isLoggedIn = useSelector((state) => userIsLoggedIn(state))

  if (isLoggedIn) {
    return <NavbarRevamp />
  }

  return <PublicNavbar />
}

export default PublicNavbarContainer
