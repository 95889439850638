import PropTypes from 'prop-types'

import {
  getTextFromSelectedDropdownMultiOptions,
  getTextFromSelectedDropdownOption,
} from 'utils/formatters'
import { getDisplayName, getUrlParam } from 'utils/mixpanel'

export const withAnalytics = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { mixpanel, location, selectedRetailerId } = props

    const getCreateOrderTemplateExcelDownloadedParams = ({
      events,
      pricesTypes,
      collections,
      retailerOptions,
      selectedPriceType,
      selectedCollections,
      selectedOrderEvent,
    }) => ({
      Retailer: getTextFromSelectedDropdownOption(
        retailerOptions,
        selectedRetailerId,
      ),
      'Price Type': getTextFromSelectedDropdownOption(
        pricesTypes,
        selectedPriceType,
      ),
      Linesheet: getTextFromSelectedDropdownMultiOptions(
        collections,
        selectedCollections,
      ),
      Event: getTextFromSelectedDropdownOption(events, selectedOrderEvent),
      ...getUrlParam(location),
    })

    const trackers = {
      trackCreateOrderTemplateExcelDownloaded: (config) =>
        mixpanel.track(
          'Orders List_Excel Order Template Downloaded',
          getCreateOrderTemplateExcelDownloadedParams(config),
        ),
    }

    return <WrappedComponent {...props} {...trackers} />
  }

  Wrapper.propTypes = {
    mixpanel: PropTypes.object,
    location: PropTypes.object.isRequired,
  }
  Wrapper.defaultProps = {
    mixpanel: { track: () => {} },
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return Wrapper
}

export default withAnalytics
