import { useEffect } from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import isEmpty from 'lodash/isEmpty'

import { TYPE_BRAND, TYPE_RETAILER } from 'store/currentUser/selectors'

import { getPersonObject } from './mixpanel'

export const useHotjar = (userInfo) => {
  const { hotjarUserAttributes } = useFlags()

  useEffect(() => {
    if (!hotjarUserAttributes || isEmpty(userInfo) || !window.hj) return

    const isPro =
      (userInfo.account?.type === TYPE_BRAND &&
        !userInfo.account?.limitedAccess) ||
      (userInfo.account?.type === TYPE_RETAILER &&
        userInfo.account?.canViewAssortments)

    const hotjarData = {
      ...getPersonObject(userInfo),
      email: userInfo.email,
      isInternal: userInfo.isInternal,
      isPro,
      isLoggedIn: userInfo.isLoggedIn,
    }

    window.hj('identify', 686103, hotjarData)
  }, [hotjarUserAttributes, userInfo])
}
