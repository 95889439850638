type windowURLs = {
  REACT_APP_GRAPHQL_URL: string
}

const _urls: Partial<windowURLs> = window.appConfig
const hostName = window.location.hostname

export const getConfig = () => ({
  urls: {
    api: process.env.REACT_APP_API_URL || 'https://api-' + hostName,
    atlas:
      process.env.REACT_APP_ATLAS_URL ||
      'https://atlas-' + hostName + '/graphql',
    backend:
      process.env.REACT_APP_GRAPHQL_URL ||
      'https://backend-' + hostName + '/graphql',
    reporting:
      process.env.REACT_APP_REPORTING_URL || 'https://reporting-' + hostName,
    udt: process.env.REACT_APP_UDT_URL || 'https://udt-' + hostName,
    keycloak:
      process.env.REACT_APP_AUTH_URL ||
      'https://keycloak-' + hostName + '/auth',
  },
  contentful: {
    DELIVERY_TOKEN: process.env.REACT_APP_CONTENTFUL_DELIVERY_TOKEN,
    PREVIEW_TOKEN: process.env.REACT_APP_CONTENTFUL_PREVIEW_TOKEN,
    SPACE_ID: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    ENVIRONMENT: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
    PREVIEW_HOST: process.env.REACT_APP_CONTENTFUL_PREVIEW_HOST,
    DELIVERY_HOST: process.env.REACT_APP_CONTENTFUL_DELIVERY_HOST,
  },
})
export const config = getConfig()
