import { Button, LegacyModal } from '@joor/design-system'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { getAccountId, getPanel } from 'store/storefront/selectors'

import { STOREFRONT as STOREFRONT_PATH } from 'routes/paths'

import { DeleteModalProps } from '../modals.types'
import { useBulkUpdateStorefront } from 'features/Storefront/EditionNavbar/Sections/Pages/Pages.hooks'

const Container = styled.div`
  margin-top: 26px;
  width: 560px;
`

const NoHomePageModal = ({
  id,
  isOpen,
  isHome,
  setModalIsOpen,
  loadingNavigation,
  refetchStorefrontNavigation,
}: DeleteModalProps): JSX.Element => {
  const brandId = useSelector(getAccountId)
  const panel = useSelector(getPanel)
  const history = useHistory()

  const { renderErrorToast, renderSuccessToast } = useRenderToasters()
  const { bulkUpdate } = useBulkUpdateStorefront()

  const handleMakeOnlineAndHome = async () => {
    if (isHome) {
      return renderErrorToast({
        description: 'Your homepage cannot be deleted.',
      })
    }
    try {
      const { data, errors } = await bulkUpdate([
        { id, isOnline: true, isHome: true },
      ])

      await refetchStorefrontNavigation()
      if (id === panel.id) {
        history.push(
          generatePath(STOREFRONT_PATH, {
            accountId: brandId,
          }),
        )
      }
      setModalIsOpen('')
      if (!data || errors) {
        return renderErrorToast({
          description: 'There was an error changing your page settings.',
        })
      }
      return renderSuccessToast({
        description: `Page has been taken Online and set as Home.`,
      })
    } catch {
      return renderErrorToast({
        description: 'There was an error changing your page settings.',
      })
    }
  }

  return (
    <LegacyModal isOpen={isOpen} onClose={(): void => setModalIsOpen('')}>
      <LegacyModal.Title>Create new Custom Profile</LegacyModal.Title>
      <LegacyModal.Content>
        <Container>
          By turning this page online, your new custom profile will be activated
          and will replace your existing brand profile that is currently visible
          to retailers. This page will become your home page. Are you sure you
          want to take this page online?
        </Container>
      </LegacyModal.Content>
      <LegacyModal.Actions>
        <LegacyModal.CloseButton>Cancel</LegacyModal.CloseButton>
        <Button onClick={handleMakeOnlineAndHome} disabled={loadingNavigation}>
          Yes
        </Button>
      </LegacyModal.Actions>
    </LegacyModal>
  )
}

export default NoHomePageModal
