import { useQuery } from '@apollo/client'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { get, groupBy, isEmpty, map, uniq } from 'lodash'
import { useSelector } from 'react-redux'

import { formatDropdownOptions } from 'utils/formatters'
import {
  fromGlobalId,
  relayConnectionToArray,
  toGlobalId,
} from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { getPanel } from 'store/storefront/selectors'

import {
  ASSOCIATED_BRANDS,
  BRAND_PROFILE,
  COLLECTIONS,
  COLLECTION_PRODUCT_COLORS,
  COLLECTION_PRODUCT_VISIBILITY,
  PRICETYPES_QUERY,
  PUBLIC_BRAND_PROFILE,
  PUBLIC_COLLECTIONS,
  SHOWROOM_COLLECTIONS,
} from './StorefrontWidgets.queries'
import {
  combineCollectionProducts,
  getStorefrontProductIds,
} from './StorefrontWidgets.utils'
import {
  PUBLIC_VISIBILITY,
  VISIBLE_TO_ALL_RETAILERS,
} from 'features/Storefront/constants'

const useProductInfo = (
  productIds,
  collectionIds,
  formattedBrandId,
  isLoggedIn,
  isAccountConnected,
  isBrandAccount,
) => {
  const {
    orb360ProductVideos: orbVideosAreEnabled,
    vntana: vntanaIsEnabled,
  } = useFlags()

  const { data, loading } = useQuery(COLLECTION_PRODUCT_COLORS, {
    client: atlasClient,
    variables: {
      filter: {
        productIds,
        collectionIds,
      },
      brandIds: [formattedBrandId],
      externalMediaEnabled: orbVideosAreEnabled || vntanaIsEnabled,
    },
    skip:
      isEmpty(collectionIds) ||
      isEmpty(productIds) ||
      !isLoggedIn ||
      (!isAccountConnected && !isBrandAccount),
  })

  const collectionProducts = data
    ? relayConnectionToArray(data?.collectionProducts)
    : []

  const formattedCollectionProducts = collectionProducts.map(
    (collectionProduct) => ({
      ...collectionProduct,
      variants: map(collectionProduct?.variants?.edges, (variant) => ({
        ...variant?.node,
        values: relayConnectionToArray(variant?.values),
      })),
      priceTypes: map(collectionProduct?.priceTypes?.edges, (priceType) => ({
        ...priceType?.node,
      })),
    }),
  )

  return {
    productsInfo: formattedCollectionProducts,
    productLoading: loading,
  }
}

const useCollectionVisibility = (
  productIds,
  collectionIds,
  formattedBrandId,
  isLoggedIn,
  isAccountConnected,
  isBrandAccount,
) => {
  const { data, loading } = useQuery(COLLECTION_PRODUCT_VISIBILITY, {
    client: atlasClient,
    variables: {
      filter: {
        productIds,
        collectionIds,
      },
      brandIds: [formattedBrandId],
    },
    skip:
      isEmpty(collectionIds) ||
      isEmpty(productIds) ||
      !isLoggedIn ||
      (!isAccountConnected && !isBrandAccount),
  })

  const collectionProducts = data
    ? relayConnectionToArray(data?.collectionProducts)
    : []

  return {
    collectionProductsVisibility: collectionProducts,
    visibilityLoading: loading,
  }
}

const usePublicCollections = ({
  brandId,
  isAccountConnected,
  isBrandAccount,
  collectionIds,
  productIds,
}) => {
  const { data, loading } = useQuery(PUBLIC_COLLECTIONS, {
    client: atlasClient,
    variables: {
      brandId,
      filters: { skipLimitByProfileSettings: true, collectionIds, productIds },
    },
    skip:
      !brandId ||
      isAccountConnected ||
      isBrandAccount ||
      (!collectionIds.length && !productIds.length),
  })

  const collections = data?.public?.collections || []

  return {
    publicCollections: collections,
    loading,
  }
}

const useProducts = (
  productIds,
  collectionIds,
  formattedBrandId,
  isLoggedIn,
  isAccountConnected,
  isBrandAccount,
) => {
  const { productsInfo, productLoading } = useProductInfo(
    productIds,
    collectionIds,
    formattedBrandId,
    isLoggedIn,
    isAccountConnected,
    isBrandAccount,
  )
  const {
    collectionProductsVisibility,
    visibilityLoading,
  } = useCollectionVisibility(
    productIds,
    collectionIds,
    formattedBrandId,
    isLoggedIn,
    isAccountConnected,
    isBrandAccount,
  )
  const {
    publicCollections,
    loading: publicCollectionsLoading,
  } = usePublicCollections({
    brandId: formattedBrandId,
    isAccountConnected,
    isBrandAccount,
    collectionIds,
    productIds,
  })
  const collectionProducts = combineCollectionProducts(
    productsInfo,
    collectionProductsVisibility,
  )
  return {
    collectionProducts:
      isAccountConnected || isBrandAccount
        ? collectionProducts
        : publicCollections,
    loading: productLoading || visibilityLoading || publicCollectionsLoading,
  }
}

const useCollections = ({
  brandId,
  isAccountConnected,
  isBrandAccount,
  collectionIds,
  productIds,
}) => {
  const {
    loading: publicCollectionsLoading,
    publicCollections,
  } = usePublicCollections({
    brandId,
    isAccountConnected,
    isBrandAccount,
    collectionIds,
    productIds,
  })
  const { loading: collectionsLoading, data } = useQuery(COLLECTIONS, {
    client: atlasClient,
    variables: { brandId },
    skip: !brandId || (!isAccountConnected && !isBrandAccount),
  })
  const collectionsFromQuery = get(data, 'collections', [])
  const formattedCollections = relayConnectionToArray(collectionsFromQuery)
  const filteredCollections = formattedCollections.filter(
    (collection) =>
      collection.visibility === PUBLIC_VISIBILITY ||
      collection.visibility === VISIBLE_TO_ALL_RETAILERS,
  )

  return {
    loading: collectionsLoading || publicCollectionsLoading,
    collections:
      isAccountConnected || isBrandAccount
        ? filteredCollections
        : publicCollections,
  }
}

const getCombinedShowroomBrandInfo = (associatedBrands, brandProfiles) =>
  brandProfiles.map((brandProfile) => {
    const brandProfileId = fromGlobalId(brandProfile.id).id
    const extraInfo = associatedBrands.find(
      (associatedBrand) =>
        fromGlobalId(associatedBrand.id).id === brandProfileId,
    )
    return {
      ...brandProfile,
      ...extraInfo,
      id: brandProfileId,
    }
  })

const useAssociatedBrands = (isLoggedIn) => {
  const { loading, data } = useQuery(ASSOCIATED_BRANDS, {
    skip: !isLoggedIn,
  })

  const associatedBrands = data?.currentUser?.brandAccounts || {}
  const formattedAssociatedBrands = relayConnectionToArray(associatedBrands)

  return {
    loading,
    associatedBrands: formattedAssociatedBrands,
  }
}

const useBrandProfile = (isLoggedIn, brandIds) => {
  const query = isLoggedIn ? BRAND_PROFILE : PUBLIC_BRAND_PROFILE
  const { loading, data, refetch: refetchBrandProfile } = useQuery(query, {
    variables: { brandIds },
    client: atlasClient,
    skip: isEmpty(brandIds),
  })

  const brandProfiles = isLoggedIn
    ? relayConnectionToArray(data?.brandProfiles)
    : relayConnectionToArray(data?.public?.brandProfiles)

  return {
    loading,
    brandProfiles: brandProfiles || [],
    refetchBrandProfile,
  }
}

export const useShowroomBrands = (isLoggedIn, brandId) => {
  const {
    associatedBrands,
    loading: associatedBrandsLoading,
  } = useAssociatedBrands(isLoggedIn)
  const brandIds = associatedBrands?.map((associatedBrand) =>
    toGlobalId('Brand', fromGlobalId(associatedBrand.id).id),
  )
  const {
    brandProfiles,
    loading: brandLoading,
    refetchBrandProfile,
  } = useBrandProfile(isLoggedIn, uniq(brandIds.concat(brandId)))
  const combinedShowroomBrands = getCombinedShowroomBrandInfo(
    associatedBrands,
    brandProfiles,
  )
  return {
    associatedBrands,
    showroomBrands: groupBy(combinedShowroomBrands, 'id'),
    loading: brandLoading || associatedBrandsLoading,
    refetchBrandProfile,
  }
}

const useShowrooms = (isLoggedIn) => {
  const { loading, data } = useQuery(SHOWROOM_COLLECTIONS, {
    client: atlasClient,
    skip: !isLoggedIn,
  })
  const showroomCollections = data?.showroomCollections || []
  const formattedShowrooms = relayConnectionToArray(showroomCollections)
  const filteredShowrooms = formattedShowrooms.filter(
    (showroom) => showroom?.visibleToAllConnected,
  )
  const showroomOptions = formatDropdownOptions(filteredShowrooms)

  return {
    showroomCollections: showroomOptions,
    loading,
  }
}

const usePriceType = (brandId, isLoggedIn) => {
  const { loading, data } = useQuery(PRICETYPES_QUERY, {
    client: atlasClient,
    skip: !brandId || !isLoggedIn,
    variables: {
      brandIds: [brandId],
    },
  })
  const priceTypes = relayConnectionToArray(data?.priceTypes) || []
  return {
    loading,
    priceType: priceTypes[0],
  }
}

export const useWidgets = (
  brandId,
  isLoggedIn,
  isAccountConnected,
  isBrandAccount,
) => {
  const formattedBrandId = toGlobalId('Brand', brandId)
  const storefront = useSelector(getPanel)
  const { productIds, collectionIds } = getStorefrontProductIds(storefront)
  const { collectionProducts } = useProducts(
    productIds,
    collectionIds,
    formattedBrandId,
    isLoggedIn,
    isAccountConnected,
    isBrandAccount,
  )
  const { collections } = useCollections({
    brandId: formattedBrandId,
    isAccountConnected,
    isBrandAccount,
    collectionIds,
    productIds,
  })
  const { showroomBrands, associatedBrands } = useShowroomBrands(
    isLoggedIn,
    formattedBrandId,
  )
  const { showroomCollections } = useShowrooms(isLoggedIn)
  const { priceType } = usePriceType(formattedBrandId, isLoggedIn)

  return {
    priceType,
    showroomCollections,
    showroomBrands,
    associatedBrands,
    collectionProducts,
    collections,
  }
}
