export const CREDIT_CARD_TAB = 0
// Since the tabs will show on mutually exclusive
// conditions, both will be index 1
export const ACH_TAB = 1
export const SEPA_TAB = 1

const prefix = 'PAYMENTS'

export const SET_SHOULD_USE_CONNECTED_ACCOUNT = `${prefix}/SET_SHOULD_USE_CONNECTED_ACCOUNT`
export const SET_SELECTED_TAB = `${prefix}/SET_SELECTED_TAB`
