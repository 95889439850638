import { Component } from 'react'

import classNames from 'classnames'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import size from 'lodash/size'
import slice from 'lodash/slice'
import PropTypes from 'prop-types'

import {
  getAllProductSkus,
  getSkusPriceRangeByTrade,
} from 'shop/products/getters'
import {
  EMPTY_VALUE,
  PRODUCT_RETAIL_ATTRIBUTE,
  PRODUCT_WHOLESALE_ATTRIBUTE,
} from 'shop/products/traits'

import {
  keyboardNavigationHandler,
  selectAllOnFocus,
} from 'components/AssortmentUnitsPage/KeyboardNavigation/KeyboardNavigation'
import { Number } from 'components/Core'
import Table from 'components/Shop/Table'
import { ALIGN_RIGHT } from 'components/Shop/Table/Cell'
import { HEADER } from 'components/Shop/Table/Container'
import {
  BULK_COLUMN_ID,
  BULK_POSITION_IN_COLUMNS,
  DISCOUNT_POSITION_IN_ROWS,
  DOOR_POSITION_IN_ROWS,
  PRICE_POSITION_IN_ROWS,
  PRICE_ROW_ID,
  QUANTITY_COLUMN_ID,
  QUANTITY_POSITION_IN_COLUMNS,
  SUGG_RETAILER_COLUMN_ID,
  TOTAL_ROW_ID,
  TOTAL_SECTION_ID,
  VARIANT_POSITION_IN_ROWS,
  WHOLESALE_COLUMN_ID,
  getDoorsFinalPositionInRows,
  getPositionInRows,
  getShowNumberInput,
} from 'modals/Shop/TablesConfiguration'

import styles from './ProductDetailBulkTable.less'

class ProductDetailBulkTable extends Component {
  constructor(props) {
    super(props)
    this.tableRef = null
  }

  componentDidMount() {
    if (this.tableRef) {
      this.tableRef.addEventListener('keydown', keyboardNavigationHandler)
      this.tableRef.addEventListener('focusin', selectAllOnFocus)
    }
  }

  componentDidUpdate() {
    this.setBulkPrices()
  }

  componentWillUnmount() {
    if (this.tableRef) {
      this.tableRef.removeEventListener('keydown', keyboardNavigationHandler)
      this.tableRef.removeEventListener('focusin', selectAllOnFocus)
    }
  }

  setBulkPrices = () => {
    const {
      isTableInitialized,
      isRowInitialized,
      tableId,
      initTableRow,
      sections,
      updateCellValue,
      areDiscountsReady,
    } = this.props
    const isPriceRowInitialized = isRowInitialized({
      tableId,
      rowId: PRICE_ROW_ID,
    })

    if (isTableInitialized && !isPriceRowInitialized && areDiscountsReady) {
      sections.forEach((section) => {
        const { wholesalePrice, retailPrice } = this.getBulkPrice(section)
        updateCellValue({
          tableId,
          sectionId: section.id,
          rowId: PRICE_ROW_ID,
          columnId: BULK_COLUMN_ID,
          value: { wholesalePrice, retailPrice },
        })
      })
      initTableRow({ tableId, rowId: PRICE_ROW_ID, value: true })
    }
  }

  getBulkHeader = () => {
    const { getSimpleCell } = this.props
    const simpleCellStructure = getSimpleCell('Bulk')
    return {
      ...simpleCellStructure,
      props: {
        ...simpleCellStructure.props,
        align: ALIGN_RIGHT,
      },
    }
  }

  getRowFormattedWithColumn = (row, column, columnPosition) => ({
    ...row,
    columns: [
      ...slice(row.columns, 0, columnPosition),
      column,
      ...slice(row.columns, columnPosition),
    ],
  })

  getNumberInput = ({ section, rowId, columnId }) => {
    const {
      getCellValueFromTable,
      tableId,
      isValidQuantity,
      product: { orderMinimum: productOrderMinimum },
    } = this.props
    const {
      id: sectionId,
      variantValue: { orderMinimum: variantOrderMinimum },
    } = section
    const tableParams = {
      tableId,
      sectionId,
      rowId,
      columnId,
    }
    const inputValue = getCellValueFromTable(tableParams)
    const isInvalidQuantity =
      (variantOrderMinimum || productOrderMinimum) &&
      !isValidQuantity({
        ...tableParams,
        variantOrderMinimum,
        productOrderMinimum,
      })
    return (
      <Number
        defaultValue={inputValue || '0'}
        className={classNames(styles.numberInput, {
          [styles.invalid]: isInvalidQuantity,
        })}
        onChange={this.handleNumberInputChange(sectionId, rowId)}
        onlyNaturalNumbersAllowed
        clearDefaultValueOnFocus
        allowEmptyValue={false}
      />
    )
  }

  getFormattedPriceRowFromSection = (section) => {
    const { getCellWithProps } = this.props
    const priceRow = section.rows[PRICE_POSITION_IN_ROWS]
    const extraColumnProps = {
      highlighted: true,
    }
    const bulkColumn = getCellWithProps('', extraColumnProps)
    return this.getRowFormattedWithColumn(
      priceRow,
      bulkColumn,
      BULK_POSITION_IN_COLUMNS,
    )
  }

  getFormattedDiscountRowFromSection = (section) => {
    const { getCellWithProps, discounts } = this.props
    const discountRow = section.rows[DISCOUNT_POSITION_IN_ROWS]
    const discount = get(discounts[0], 'factor', null)
    const cellContent = (
      <div className={styles.discounts}>
        {discount ? `${discount}%` : EMPTY_VALUE}
      </div>
    )
    const extraColumnProps = {
      highlighted: true,
    }

    return this.getRowFormattedWithColumn(
      discountRow,
      getCellWithProps(cellContent, extraColumnProps),
      BULK_POSITION_IN_COLUMNS,
    )
  }

  getFormattedVariantRowFromSection = (section) => {
    const {
      tableId,
      doors,
      getCellWithProps,
      getSimpleCell,
      getRowPrices,
      getRowQuantity,
      getFormattedPrice,
      discounts,
    } = this.props
    const { id: sectionId, rows } = section
    const variantIndex = getPositionInRows(VARIANT_POSITION_IN_ROWS, discounts)
    const variantRow = rows[variantIndex]
    const numberInput = this.getNumberInput({
      section,
      rowId: variantRow.id,
      columnId: BULK_COLUMN_ID,
    })
    const extraColumnProps = {
      highlighted: true,
      align: ALIGN_RIGHT,
    }
    const cellParams = {
      tableId,
      sectionId,
      rowId: variantRow.id,
    }
    const { wholesalePrice, retailPrice } = getRowPrices(cellParams)
    const quantityValue = getRowQuantity(cellParams)

    return {
      ...variantRow,
      columns: [
        ...slice(variantRow.columns, 0, BULK_POSITION_IN_COLUMNS),
        ...(isEmpty(doors)
          ? [getCellWithProps(numberInput, extraColumnProps)]
          : []),
        ...(!isEmpty(doors) ? [getCellWithProps('', extraColumnProps)] : []),
        getSimpleCell(isEmpty(doors) ? quantityValue : ''),
        getSimpleCell(isEmpty(doors) ? getFormattedPrice(wholesalePrice) : ''),
        getSimpleCell(isEmpty(doors) ? getFormattedPrice(retailPrice) : ''),
        ...slice(variantRow.columns, QUANTITY_POSITION_IN_COLUMNS),
      ],
    }
  }

  getFormattedDoorRowsFromSection = (section) => {
    const {
      tableId,
      doors,
      getCellWithProps,
      getSimpleCell,
      getRowQuantity,
      getRowPrices,
      getFormattedPrice,
      discounts,
    } = this.props
    const { id: sectionId, rows } = section
    const doorSize = size(doors)
    const doorIndex = getPositionInRows(DOOR_POSITION_IN_ROWS, discounts)
    const endDoorsPosition = getDoorsFinalPositionInRows(doors, doorIndex)
    const doorRows =
      doorSize > 0 ? slice(rows, doorIndex, endDoorsPosition) : []

    return map(doorRows, (doorRow, index) => {
      const showNumberInput = getShowNumberInput(doorSize, index)
      const numberInput = this.getNumberInput({
        section,
        rowId: doorRow.id,
        columnId: BULK_COLUMN_ID,
      })
      const extraColumnProps = {
        highlighted: true,
        align: ALIGN_RIGHT,
      }
      const cellParams = {
        tableId,
        sectionId,
        rowId: doorRow.id,
      }
      const { wholesalePrice, retailPrice } = getRowPrices(cellParams)
      const quantityValue = getRowQuantity(cellParams)

      return {
        ...doorRow,
        columns: [
          ...slice(doorRow.columns, 0, BULK_POSITION_IN_COLUMNS),
          ...(!showNumberInput ? [getCellWithProps('', extraColumnProps)] : []),
          ...(showNumberInput
            ? [getCellWithProps(numberInput, extraColumnProps)]
            : []),
          getSimpleCell(quantityValue),
          getSimpleCell(getFormattedPrice(wholesalePrice)),
          getSimpleCell(getFormattedPrice(retailPrice)),
          ...slice(doorRow.columns, QUANTITY_POSITION_IN_COLUMNS),
        ],
      }
    })
  }

  getTotalRowFromTable = () => {
    const {
      tableId,
      getSimpleCell,
      getRowQuantity,
      getRowPrices,
      getFormattedPrice,
    } = this.props
    const cellParams = {
      tableId,
      sectionId: TOTAL_SECTION_ID,
      rowId: TOTAL_ROW_ID,
    }
    const quantityValue = getRowQuantity(cellParams)
    const { wholesalePrice, retailPrice } = getRowPrices(cellParams)
    return {
      props: {
        tertiary: true,
        medium: true,
        skipNavigation: true,
      },
      columns: [
        getSimpleCell('Total'),
        getSimpleCell(''),
        getSimpleCell(quantityValue),
        getSimpleCell(getFormattedPrice(wholesalePrice)),
        getSimpleCell(getFormattedPrice(retailPrice)),
      ],
    }
  }

  getBulkPrice = (section) => {
    const { product, priceTypeId, discountFactor } = this.props
    const { variantValue } = section
    const productSkus = getAllProductSkus(product, variantValue)
    const {
      min: minWholesalePrice,
      max: maxWholesalePrice,
    } = getSkusPriceRangeByTrade(
      productSkus,
      priceTypeId,
      PRODUCT_WHOLESALE_ATTRIBUTE,
    )
    const {
      min: minRetailPrice,
      max: maxRetailPrice,
    } = getSkusPriceRangeByTrade(
      productSkus,
      priceTypeId,
      PRODUCT_RETAIL_ATTRIBUTE,
    )

    return {
      wholesalePrice: {
        min: minWholesalePrice * discountFactor,
        max: maxWholesalePrice * discountFactor,
      },
      retailPrice: {
        min: minRetailPrice * discountFactor,
        max: maxRetailPrice * discountFactor,
      },
    }
  }

  handleNumberInputChange = (sectionId, rowId) => (
    _,
    { value, defaultValue },
  ) => {
    const {
      tableId,
      updateCellValue,
      getCellValueFromTable,
      setTotalRowValues,
      getNewPrice,
    } = this.props
    const quantity = parseInt(value || 0, 10)
    const cellParams = {
      tableId,
      sectionId,
      rowId,
      columnId: BULK_COLUMN_ID,
    }
    if (quantity !== defaultValue) {
      const { wholesalePrice, retailPrice } = getCellValueFromTable({
        ...cellParams,
        rowId: PRICE_ROW_ID,
      })
      const newWholesalePrice = getNewPrice(wholesalePrice, quantity)
      const newRetailPrice = getNewPrice(retailPrice, quantity)

      const newValuesConfig = [
        {
          columnId: BULK_COLUMN_ID,
          value: quantity,
        },
        {
          columnId: QUANTITY_COLUMN_ID,
          value: quantity,
        },
        {
          columnId: WHOLESALE_COLUMN_ID,
          value: newWholesalePrice,
        },
        {
          columnId: SUGG_RETAILER_COLUMN_ID,
          value: newRetailPrice,
        },
      ]

      newValuesConfig.forEach((cellConfig) => {
        updateCellValue({
          ...cellParams,
          ...cellConfig,
        })
      })
    }

    const previousValue = getCellValueFromTable({
      ...cellParams,
      columnId: BULK_COLUMN_ID,
    })
    setTotalRowValues(cellParams, previousValue, quantity)
  }

  renderHeader = () => {
    const { headerCols } = this.props
    const headerRow = {
      props: {
        big: true,
        secondary: true,
      },
      columns: [
        ...slice(headerCols, 0, BULK_POSITION_IN_COLUMNS),
        this.getBulkHeader(),
        ...slice(headerCols, BULK_POSITION_IN_COLUMNS),
      ],
    }
    return <Table.Container type={HEADER} rows={[headerRow]} />
  }

  renderBody = () => {
    const { sections, doors, discounts } = this.props
    const rows = []
    const doorsIndex = getPositionInRows(DOOR_POSITION_IN_ROWS, discounts)
    const positionSliced = getDoorsFinalPositionInRows(doors, doorsIndex)

    sections.forEach((section) => {
      rows.push(
        ...[
          this.getFormattedPriceRowFromSection(section),
          ...(!isEmpty(discounts)
            ? [this.getFormattedDiscountRowFromSection(section)]
            : []),
          this.getFormattedVariantRowFromSection(section),
          ...this.getFormattedDoorRowsFromSection(section),
          ...slice(section.rows, positionSliced),
        ],
      )
    })

    return (
      <Table.Container
        rows={[
          ...rows,
          ...(!isEmpty(sections) ? [this.getTotalRowFromTable()] : []),
        ]}
      />
    )
  }

  render() {
    return (
      <div
        className={styles.ProductDetailBulkTable}
        data-scroll-container="x-axis"
      >
        <Table
          headerSticky
          firstColSticky
          className={styles.table}
          tableRef={(ref) => {
            this.tableRef = ref
          }}
        >
          {this.renderHeader()}
          {this.renderBody()}
        </Table>
      </div>
    )
  }
}

ProductDetailBulkTable.propTypes = {
  product: PropTypes.shape({
    variants: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
  getSimpleCell: PropTypes.func,
  headerCols: PropTypes.array,
  sections: PropTypes.object,
  doors: PropTypes.array,
  discounts: PropTypes.array,
  getCellWithProps: PropTypes.func,
  updateCellValue: PropTypes.func,
  getCellValueFromTable: PropTypes.func,
  setTotalRowValues: PropTypes.func,
  tableId: PropTypes.string.isRequired,
  isValidQuantity: PropTypes.func,
  isTableInitialized: PropTypes.bool,
  priceTypeId: PropTypes.string.isRequired,
  getRowQuantity: PropTypes.func,
  getRowPrices: PropTypes.func,
  discountFactor: PropTypes.number,
  initTableRow: PropTypes.func,
  isRowInitialized: PropTypes.func,
  getNewPrice: PropTypes.func,
  getFormattedPrice: PropTypes.func,
  areDiscountsReady: PropTypes.bool,
}

ProductDetailBulkTable.defaultProps = {
  getSimpleCell: () => {},
  headerCols: [],
  sections: new Map(),
  doors: [],
  discounts: [],
  getCellWithProps: () => {},
  updateCellValue: () => {},
  getCellValueFromTable: () => {},
  isValidQuantity: () => {},
  setTotalRowValues: () => {},
  isTableInitialized: false,
  getRowQuantity: () => {},
  getRowPrices: () => {},
  discountFactor: 1,
  initTableRow: () => {},
  isRowInitialized: () => {},
  getNewPrice: () => {},
  getFormattedPrice: () => {},
  areDiscountsReady: false,
}

export default ProductDetailBulkTable
