import { useMutation, useQuery } from '@apollo/client'

import { toGlobalId } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

const CHECK_MERGE_CRITERIA = atlas`#graphql
  query CheckMergeCriteria ($targetAccountId: ID!) {
    checkMergingAccountCriteria(targetAccountId: $targetAccountId){
      ids
    }
  }
`

export const useCheckMergeCriteria = (
  targetAccountId?: string,
  accountIdsToMerge?: string[],
) => {
  const { data, loading } = useQuery(CHECK_MERGE_CRITERIA, {
    client: atlasClient,
    fetchPolicy: 'no-cache',
    variables: { targetAccountId, accountIdsToMerge },
    skip: !targetAccountId,
  })
  const allowedAccountIds = data?.checkMergingAccountCriteria?.ids || []

  return {
    loading,
    allowedAccounts: allowedAccountIds.map((id: string) =>
      toGlobalId('Account', id),
    ),
  }
}

const MERGE_ACCOUNTS = atlas`#graphql
  mutation MergeAccounts($input: MergeAccountsInput!) {
    mergeAccounts(input: $input) {
      success
    }
  }
`

type UseMergeAccountsResult = {
  loading: boolean
  mergeAccounts: (
    primaryAccountId?: string,
    secondaryAccountIds?: string[],
  ) => any
}

export const useMergeAccounts = (): UseMergeAccountsResult => {
  const [mutate, { loading }] = useMutation(MERGE_ACCOUNTS, {
    client: atlasClient,
  })

  const mergeAccounts = async (
    primaryAccountId?: string,
    secondaryAccountIds?: string[],
  ) => {
    try {
      mutate({
        variables: {
          input: {
            targetAccountId: primaryAccountId,
            accountIds: secondaryAccountIds,
          },
        },
      })
    } catch {}
  }

  return { mergeAccounts, loading }
}
