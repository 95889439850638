/* eslint-disable react/prop-types */
import { useEffect } from 'react'

import Modal from 'components/Core/Modal'

const SessionExpiredModal = ({ setVisibility }) => {
  const openLoginPopup = () => {
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
                  width=500,height=600,left=500,top=100`
    window.open('/?continue=login-popup', 'JOOR Login', params)
  }

  const closeModal = () => {
    setVisibility(false)
  }

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (
        event.origin.includes('web-sessions') &&
        event.data === 'login-success'
      ) {
        setVisibility(false)
      }
    })
  })

  return (
    <Modal
      open
      title={'Session Expired'}
      primaryActionLabel={'Login'}
      primaryActionOnClick={openLoginPopup}
      primaryActionClose={false}
      secondaryActionHidden={true}
      onClose={closeModal}
    >
      Your session has expired, please login again.
    </Modal>
  )
}

export default SessionExpiredModal
