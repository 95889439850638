import { handleActions } from 'redux-actions'

export const defaultState = {
  selectedRetailerId: null,
  importedFiles: [],
  retailerSearchText: '',
}

const reducer = handleActions(
  {
    'EXCEL_ORDERS_MODAL/SET_IMPORTED_FILES': (state, { payload }) => {
      const newState = { ...state }
      newState.importedFiles = payload

      return newState
    },
    'EXCEL_ORDERS_MODAL/SET_SELECTED_RETAILER_ID': (state, { payload }) => {
      const newState = { ...state }
      newState.selectedRetailerId = payload

      return newState
    },
    'EXCEL_ORDERS_MODAL/CHANGE_RETAILER_SEARCH_TEXT': (state, { payload }) => ({
      ...state,
      retailerSearchText: payload,
    }),
  },
  defaultState,
)

export default reducer
