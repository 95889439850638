import get from 'lodash/get'
import moment from 'moment'
import { handleActions } from 'redux-actions'

import {
  initialFilterReducerState,
  unselectedFilterReducerState,
} from 'utils/filters/utils'
import { toServerDate } from 'utils/formatters'

import withStorage from 'store/utils/storage/withStorage'

import {
  dashboardFilters,
  getDateRangeDefault,
} from 'containers/BrandDashboard/FilterPanel/filters'

export const defaultState = {
  defaultCurrency: {},
  selectedFilters: {
    ...initialFilterReducerState(dashboardFilters),
  },
  appliedFilters: {
    ...initialFilterReducerState(dashboardFilters),
  },
  appointmentDateFilter: {
    startDate: toServerDate(moment()),
    endDate: toServerDate(moment().add(30, 'days')),
  },
  areAppointmentsBeingUpdated: false,
}

const reducer = handleActions(
  {
    'BRAND_DASHBOARD/SET_DEFAULT_CURRENCY': (state, { payload }) => ({
      ...state,
      defaultCurrency: payload,
    }),
    'BRAND_DASHBOARD/TOGGLE_SELECT_FILTER': (state, { payload }) => {
      const { filterKey } = payload
      const filterSettings = dashboardFilters[filterKey]
      const { reducerHandler } = filterSettings
      return {
        ...state,
        selectedFilters: reducerHandler(state.selectedFilters, { ...payload }),
      }
    },
    'BRAND_DASHBOARD/TOGGLE_DESELECT_ALL_FILTERS': (state) => ({
      ...state,
      selectedFilters: {
        ...state.selectedFilters,
        ...unselectedFilterReducerState(dashboardFilters),
      },
    }),
    'BRAND_DASHBOARD/APPLY_SELECTED_FILTERS': (state) => ({
      ...state,
      appliedFilters: { ...state.selectedFilters },
    }),
    'BRAND_DASHBOARD/TOGGLE_APPOINTMENT_DATE_FILTER': (
      state,
      { payload: { startDate, endDate } },
    ) => ({
      ...state,
      appointmentDateFilter: {
        startDate: toServerDate(startDate),
        endDate: toServerDate(endDate),
      },
    }),
    'BRAND_DASHBOARD/SET_APPOINTMENT_HUB_LOADER': (state, { payload }) => ({
      ...state,
      areAppointmentsBeingUpdated: payload,
    }),
  },
  defaultState,
)

export const setNewDefaultDateHook = (state) => {
  const isDefaultDate = get(state.appliedFilters, 'dateRange.isDefault', true)
  if (!isDefaultDate) {
    return state
  }
  return {
    ...state,
    selectedFilters: {
      ...state.selectedFilters,
      dateRange: getDateRangeDefault(),
    },
    appliedFilters: {
      ...state.appliedFilters,
      dateRange: getDateRangeDefault(),
    },
  }
}

export default withStorage(reducer, {
  key: 'BRAND_DASHBOARD',
  version: 1,
  postLoadHooks: [setNewDefaultDateHook],
  omitFields: ['appointmentDateFilter', 'areAppointmentsBeingUpdated'],
})
