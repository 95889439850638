import { useState } from 'react'

const setSelectedImage = ({
  color: { images: colorImages = [] },
  images,
  selectedImageIndex,
  setSelectedImageIndex,
}) => {
  const productImageMatchingColor = images.findIndex(
    (image) => image === colorImages[0],
  )
  const hasColorImage = productImageMatchingColor >= 0
  const newIndex = hasColorImage
    ? productImageMatchingColor
    : selectedImageIndex

  setSelectedImageIndex(newIndex)
}

const useSwatchImageSelector = ({ productImages }) => {
  const [selectedColorId, setSelectedColorId] = useState('')
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const handleSwatchSelection = (color) => {
    setSelectedColorId(color.id)
    setSelectedImage({
      color,
      images: productImages,
      selectedImageIndex,
      setSelectedImageIndex,
    })
  }

  return {
    selectedColorId,
    handleSwatchSelection,
    selectedImageIndex,
    setSelectedImageIndex,
  }
}

export default useSwatchImageSelector
