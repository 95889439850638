import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'

const Image = ({
  alt,
  src,
  className,
  width,
  height,
  inRow,
  onClick,
  containerClassName,
}) => {
  if (!src) {
    return (
      <Icon
        name={inRow ? 'noImageInRow' : 'noImage'}
        className={className}
        onClick={onClick}
        containerClassName={containerClassName}
      />
    )
  }
  return (
    <img
      alt={alt}
      width={width}
      height={height}
      src={src}
      className={className}
      onClick={onClick}
    />
  )
}
Image.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  inRow: PropTypes.bool,
  onClick: PropTypes.func,
  containerClassName: PropTypes.string,
}
Image.defaultProps = {
  className: '',
  src: '',
  alt: '',
  width: 56,
  height: 75,
  inRow: false,
  onClick: () => {},
  containerClassName: '',
}

export default Image
