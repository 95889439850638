import { handleActions } from 'redux-actions'

export const defaultState = {
  errorMessages: [],
}

const reducer = handleActions(
  {
    'ERROR/ADD_ERROR_MESSAGES': (state, action) => ({
      ...state,
      errorMessages: [...state.errorMessages, ...action.payload],
    }),
    'ERROR/CLEAR_ERROR_MESSAGES': (state) => ({
      ...state,
      errorMessages: [],
    }),
  },
  defaultState,
)

export default reducer
