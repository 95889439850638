import moment from 'moment'

import DateFilter from 'utils/filters/dateFilter'
import { toServerDate } from 'utils/formatters'

import styles from './FilterPanel.component.less'

const DEFAULT_DAYS = 20

export const getDateRangeDefault = () => ({
  startDate: toServerDate(moment().subtract(DEFAULT_DAYS, 'days')),
  endDate: toServerDate(moment().add(1, 'days')),
})

export const reportingFilters = {
  dateRange: new DateFilter({
    filterKey: 'dateRange',
    label: 'Created',
    dataKey: 'dateRange',
    styles,
    messages: {
      addDate: 'Select a date range',
      addDateFilter: 'Apply',
    },
    defaultState: getDateRangeDefault(),
  }),
}

export const filterSetup = [
  {
    key: 'dateRange',
    title: 'Created Date',
    filters: [reportingFilters.createdDateRange],
  },
]
