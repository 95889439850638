export const getRetailerFeatureLink = (
  urlPattern: RegExp,
  redirectUrl: string,
) => {
  const currentPath = window.location.pathname

  if (urlPattern.test(currentPath)) {
    const retailerId = getRetailerIdFromPath(currentPath)
    return `${redirectUrl}/${retailerId}`
  }
  return ''
}

const getRetailerIdFromPath = (path: string) => {
  return path.split('/')[3]
}
