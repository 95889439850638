import {
  CsvDataType,
  ExcelDataType,
  ExportType,
  FileFormats,
  OrderExcelDataType,
  PdfDataType,
  StyleDataLevel,
} from 'features/Linesheets/Modals/ExportModal/exportModal.types'

export type BasicPayloadType = {
  downloadToken: number
  Collection?: { id: string | null }[]
  linesheet_ids?: (string | null)[]
  file: FileFormats
  for_order_import: number
}

export type CsvPayloadType = BasicPayloadType & {
  export_type?: ExportType
  is_nordstrom_export: string
  price_type_id: string | string[]
  type: StyleDataLevel
}

export type PdfPayloadType = BasicPayloadType & {
  style: string
  price_type_id: string
  print_retail_price: number
  print_wholesale_price: number
  CustomField: string[]
  group_by: (string | null)[]
  page_break_by_group: number
  overflow_colors: number
  show_tag_names: number
  use_hi_res_images: number
  oversold_type: number
  warehouse_ids: string
  dates: string
  merge_linesheets: number
  Badges: string[]
  smaller_swatches: number
  group_future_inventory_dates: number
  hide_sold_out: number
}

export type OrderExcelPayloadType = BasicPayloadType & {
  designer_id: string
  door_ids: string[]
  include_images: number
  linesheet_ids: string
  price_type_id: string
}

export const isPdfDataType = (
  data: CsvDataType | ExcelDataType | PdfDataType | OrderExcelDataType,
): data is PdfDataType => data.hasOwnProperty('mergeLinesheets')

export const isOrderExcelDataType = (
  data: CsvDataType | ExcelDataType | PdfDataType | OrderExcelDataType,
): data is OrderExcelDataType => data.hasOwnProperty('designerId')
