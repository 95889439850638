import { MutationHookOptions, useMutation } from '@apollo/client'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { camelCase } from 'change-case'
import { useSelector } from 'react-redux'

import {
  SubscribeUserToFeatureDocument,
  SubscribeUserToFeatureInput,
  SubscribeUserToFeatureMutation,
  SubscribeUserToFeatureMutationVariables,
  UnSubscribeUserToFeatureDocument,
  UnSubscribeUserToFeatureInput,
  UnSubscribeUserToFeatureMutation,
  UnSubscribeUserToFeatureMutationVariables,
} from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import {
  isLiteBrand as isLiteBrandSelector,
  isLiteRetailer as isLiteRetailerSelector,
  isProBrand as isProBrandSelector,
  isProRetailer as isProRetailerSelector,
} from 'store/currentUser/selectors'

import { FEATURES } from './constants/constants'

export const useSubscribeUserToFeature = (
  options?: MutationHookOptions<
    SubscribeUserToFeatureMutation,
    SubscribeUserToFeatureMutationVariables
  >,
) => {
  const [subscribeUser, { loading }] = useMutation(
    SubscribeUserToFeatureDocument,
    {
      ...options,
      client: atlasClient,
    },
  )

  const subscribeUserToFeature = ({
    featureName,
    userId,
  }: {
    featureName: string
    userId: string
  }) => {
    const input: SubscribeUserToFeatureInput = {
      featureName,
      userId,
    }

    return subscribeUser({
      variables: {
        input,
      },
    })
  }

  return {
    isSubscribing: loading,
    subscribeUserToFeature,
  }
}

export const useUnSubscribeUserToFeature = (
  options?: MutationHookOptions<
    UnSubscribeUserToFeatureMutation,
    UnSubscribeUserToFeatureMutationVariables
  >,
) => {
  const [unSubscribeUser, { loading }] = useMutation(
    UnSubscribeUserToFeatureDocument,
    {
      ...options,
      client: atlasClient,
    },
  )

  const unSubscribeUserToFeature = ({
    featureName,
    userId,
  }: {
    featureName: string
    userId: string
  }) => {
    const input: UnSubscribeUserToFeatureInput = {
      featureName,
      userId,
    }

    return unSubscribeUser({
      variables: {
        input,
      },
    })
  }

  return {
    isUnSubscribing: loading,
    unSubscribeUserToFeature,
  }
}

export const useAvailableFeatures = () => {
  const flags = useFlags()
  const userSelectors = useSelector((state) => ({
    isProRetailer: isProRetailerSelector(state),
    isLiteRetailer: isLiteRetailerSelector(state),
    isProBrand: isProBrandSelector(state),
    isLiteBrand: isLiteBrandSelector(state),
  }))
  return FEATURES.filter((feature) => {
    // We can have features that are specific to a user type. We are not returning
    // the feature if the user doesn't match with the userTypeSpecific.
    const matchesUserType =
      !feature.userTypeSpecific ||
      feature.userTypeSpecific.some((userType) => userSelectors[userType])
    const matchesFeatureFlag = flags[camelCase(feature.earlyAccessAdminFlag)]
    return matchesFeatureFlag && matchesUserType
  })
}
