import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'

import { Dropdown, Option, TextField } from '@joor/design-system'
import styled from 'styled-components'

import { Geography, LocationType } from '__generated__/atlas-types'

import { AddressEditionView } from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.ids'
import { validateWebsiteUrl } from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.utils'

import { useCountries, useStates } from './AddressEdition.hooks'
import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

const TYPE_OPTIONS = [
  { label: 'Store', value: LocationType.STORE },
  { label: 'Office', value: LocationType.OFFICE },
]

type AddressEditionProps = {
  address: Partial<RetailerProfileLocation> | null
  website?: string
  setAddress: Dispatch<SetStateAction<Partial<RetailerProfileLocation>>>
  setWebsite: Dispatch<SetStateAction<string>>
}

const StyledContainer = styled.div`
  max-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  && {
    padding: 16px 24px !important;
  }
`
const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  > * {
    width: 100%;
    flex-basis: 50%;
  }
`

const AddressEdition: React.FC<AddressEditionProps> = ({
  address,
  website,
  setAddress,
  setWebsite,
}) => {
  const [countryOption, setCountryOption] = useState<Option | null>(null)
  const [stateOption, setStateOption] = useState<Option | null>(null)

  const { loading: countriesLoading, countries } = useCountries()
  const {
    fetchStates,
    loading: statesLoading,
    stateOptions,
    states,
  } = useStates()

  useEffect(() => {
    if (countries?.length && address?.country) {
      const selectedCountry = countries.find(
        (countryOption: Option) => countryOption.label === address.country,
      )
      setCountryOption(selectedCountry)
      fetchStates(selectedCountry?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countriesLoading])
  useEffect(() => {
    if (stateOptions?.length && address?.state) {
      const selectedState = states.find(
        (state: Geography) =>
          state.abbreviation === address?.state ||
          state.name === address?.state,
      )
      const selectedStateOption = stateOptions.find(
        (state: Option) => selectedState?.id === state.value,
      )
      setStateOption(selectedStateOption)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statesLoading, stateOptions?.length, address?.state])

  return (
    <StyledContainer data-testid={AddressEditionView.Component}>
      <Dropdown
        id={AddressEditionView.CountryDropdown}
        isRequired
        testId="country-dropdown"
        label="Country"
        value={countryOption}
        onSelect={({ selected }: { selected: Option }): void => {
          setCountryOption(selected)
          fetchStates(selected?.value)
          setAddress({
            ...address,
            country: selected.label,
            state: '',
          })
        }}
        options={countries}
        disabled={countriesLoading}
        loading={countriesLoading}
      />
      <TextField
        id={AddressEditionView.Address1Field}
        className="noTranslate"
        isRequired
        label={'Address 1'}
        placeholder="Enter Address"
        value={address?.address1 ?? ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setAddress({
            ...address,
            address1: e.target.value,
          })
        }
      />
      <TextField
        id={AddressEditionView.Address2Field}
        className="noTranslate"
        label={'Address 2'}
        placeholder="Enter Address"
        value={address?.address2 ?? ''}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setAddress({
            ...address,
            address2: e.target.value,
          })
        }
      />
      <StyledRow>
        <TextField
          id={AddressEditionView.CityField}
          isRequired
          label={'City'}
          placeholder="Enter City"
          value={address?.city ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({
              ...address,
              city: e.target.value,
            })
          }
        />
        {Boolean(stateOptions?.length) ? (
          <Dropdown
            id={AddressEditionView.StateDropdown}
            testId="state-dropdown"
            label="State"
            value={stateOption}
            onSelect={({ selected }: { selected: Option }): void => {
              const selectedState = states.find(
                (state: Geography) =>
                  selected.label === state.name ||
                  selected.label === state.abbreviation,
              )
              setStateOption({
                value: selected.value,
                label: selectedState?.abbreviation
                  ? selectedState?.abbreviation
                  : selectedState.name,
              })
              setAddress({
                ...address,
                state: selected.label,
              })
            }}
            options={stateOptions}
            disabled={statesLoading}
            isRequired
            loading={statesLoading}
          />
        ) : (
          <TextField
            id={AddressEditionView.TerritoryField}
            label={'Territory'}
            placeholder="Enter territory here"
            value={address?.state ?? ''}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setAddress({
                ...address,
                state: e.target.value,
              })
            }
            isRequired
          />
        )}
      </StyledRow>
      <StyledRow>
        <TextField
          id={AddressEditionView.ZipField}
          label={'Zip/Postal Code'}
          placeholder="Enter Zip or Postal Code"
          value={address?.zip ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setAddress({
              ...address,
              zip: e.target.value,
            })
          }
          maxLength={15}
        />
        <Dropdown
          id={AddressEditionView.TypeDropdown}
          testId="type-dropdown"
          label="Type"
          defaultValue={TYPE_OPTIONS[0]}
          value={TYPE_OPTIONS.find((option) => option.value === address?.type)}
          onSelect={({ selected }: { selected: Option }): void =>
            setAddress({
              ...address,
              type: selected?.value as LocationType,
            })
          }
          options={TYPE_OPTIONS}
          isRequired
        />
      </StyledRow>
      <StyledRow>
        <TextField
          id={AddressEditionView.PhoneField}
          label={'Phone Number'}
          placeholder="Enter Phone Number"
          value={address?.phone ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const numberValue = e.target.value.replace(/\D+/g, '')
            setAddress({
              ...address,
              phone: numberValue,
            })
          }}
          isRequired
          maxLength={12}
        />
        <TextField
          id={AddressEditionView.WebsiteField}
          label={'Website'}
          placeholder="Enter your website"
          value={website ?? ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setWebsite(e.target.value)
          }}
          isRequired
          errorMessage={
            validateWebsiteUrl(website || '') || !Boolean(website)
              ? undefined
              : 'Please introduce a valid website (http://www.website.com)'
          }
        />
      </StyledRow>
    </StyledContainer>
  )
}

export default AddressEdition
