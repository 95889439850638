import { useState } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import { Drilldown, Icon } from 'components/Core'

import styles from './GroupSelection.component.less'

export const COLLECTION = 'COLLECTION'
export const CATEGORY = 'CATEGORY'
export const DELIVERY = 'DELIVERY'
export const STYLE_TAGS = 'STYLE_TAGS'

const OPTIONS = {
  [COLLECTION]: 'Linesheet',
  [CATEGORY]: 'Product Category',
  [DELIVERY]: 'Shipping Delivery Window',
  [STYLE_TAGS]: 'Style Tags',
}

const GroupSelection = ({
  selectedCriteria,
  setGroupingCriteria,
  trackGroupBy,
  styleTagGroups,
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const options = [
    { id: COLLECTION, label: OPTIONS.COLLECTION },
    { id: CATEGORY, label: OPTIONS.CATEGORY },
    { id: DELIVERY, label: OPTIONS.DELIVERY },
  ]
  if (styleTagGroups.length) {
    options.push({
      id: STYLE_TAGS,
      label: OPTIONS.STYLE_TAGS,
      children: styleTagGroups.map((group) => ({
        id: `${STYLE_TAGS}:${group}`,
        label: group,
      })),
    })
  }
  const groupedByLabel = selectedCriteria.includes(STYLE_TAGS)
    ? selectedCriteria.split(':')[1]
    : OPTIONS[selectedCriteria]
  return (
    <div
      className={styles.GroupSelection}
      onClick={() => setShowOptions(!showOptions)}
    >
      <div className={styles.label}>
        <span className={styles.selectedCriteria}>
          Grouped by {groupedByLabel}
        </span>
        <Icon
          name="arrowUp"
          className={classNames(styles.arrow, {
            [styles.closed]: !showOptions,
          })}
        />
        <Drilldown
          className={styles.drilldown}
          flyoutContainerClassname={styles.flyoutContainer}
          open={showOptions}
          hoverableSelect
          activeClassName={styles.activeItem}
          onChange={(_, { value }) => {
            if (value.id === STYLE_TAGS) {
              return
            }
            trackGroupBy(OPTIONS[value.id] || OPTIONS[STYLE_TAGS])
            setGroupingCriteria(value.id)
            setShowOptions(false)
          }}
          data={options}
        />
      </div>
    </div>
  )
}

GroupSelection.propTypes = {
  selectedCriteria: PropTypes.string.isRequired,
  setGroupingCriteria: PropTypes.func.isRequired,
  trackGroupBy: PropTypes.func.isRequired,
  styleTagGroups: PropTypes.array.isRequired,
}

export default GroupSelection
