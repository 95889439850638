import PropTypes from 'prop-types'

import Table from './Table'

export const HEADER = 'header'
const BODY = 'body'

const Container = ({ rows, className, type }) => {
  const children = rows.map((row, index) => (
    /* eslint-disable react/no-array-index-key */
    <Table.Row {...row.props} key={`Row_${index}`}>
      {row.columns.map((column, index2) => (
        /* eslint-disable react/no-array-index-key */
        <Table.Cell {...column.props} key={`Column_${index2}`}>
          {column.children}
        </Table.Cell>
      ))}
    </Table.Row>
  ))

  return type === BODY ? (
    <tbody className={className}>{children}</tbody>
  ) : (
    <thead className={className}>{children}</thead>
  )
}

Container.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      props: PropTypes.object,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          children: PropTypes.node,
          props: PropTypes.object,
        }),
      ).isRequired,
    }),
  ),
  className: PropTypes.string,
  type: PropTypes.oneOf([BODY, HEADER]),
}

Container.defaultProps = {
  rows: [],
  className: '',
  type: BODY,
}

export default Container
