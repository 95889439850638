import { graphql } from '@apollo/client/react/hoc'
import flow from 'lodash/fp/flow'

import { formatConnectedAccountsForDropdown } from 'formatters/orders'
import { withNoTranslate } from 'utils/formatters'
import { getBrandConnectedAccounts } from 'utils/transformations/accounts'
import { relayConnectionToArray } from 'utils/transformations/graphql'

import { graphene } from 'graphql/tags'

import { priceTypeMatchesFragment } from 'containers/ProductCatalog/ProductCatalog.fragments'

export const MINIMUM_CHARACTERS_QUERY = 2

const initialBrandStartOrderModalQuery = graphene`#graphql
  query initialBrandStartOrderModalQuery {
    viewerBrand {
      id
      orderEvents(orderBy: [{ field: NAME, order: ASCENDING }]) {
        edges {
          node {
            id
            name
            code
          }
        }
      }
      types {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    warehouses(orderBy: [{ field: ID, order: ASCENDING }]) {
      edges {
        node {
          id
          name
          code
          default
        }
      }
    }
  }
`

// TODO(ch44742) Sustainable way (w/o repetition) for connectedAccounts query
const connectedAccountsQuery = graphene`#graphql
  query connectedAccountsQuery($searchFilter: String) {
    connectedAccounts(filterBy: { displayName: $searchFilter }) {
      edges {
        node {
          ... on RetailerAccount {
            id
            displayName
            locations(filterBy: { primary: true }) {
              edges {
                node {
                  id
                  state
                  city
                }
              }
            }
          }
        }
      }
    }
  }
`

const dataForRetailerQuery = graphene`#graphql
  query DataForRetailerIdQuery($retailerAccountId: ID!) {
    warehouses(orderBy: [{ field: ID, order: ASCENDING }]) {
      edges {
        node {
          id
          name
          code
          default
        }
      }
    }
    node(id: $retailerAccountId) {
      ... on RetailerAccount {
        id
        ...priceTypeMatches
        brandWarehouseMatches {
          edges {
            node {
              id
              name
              code
              default
            }
          }
        }
        brandDoorMatches(orderBy: [{ field: NAME, order: ASCENDING }]) {
          edges {
            node {
              id
              name
              code
            }
          }
        }
      }
    }
  }
  ${priceTypeMatchesFragment}
`

export const initialBrandStartOrderModalData = ({
  data: { error, loading, viewerBrand, warehouses },
}) => ({
  orderEvents:
    !loading && !error && viewerBrand
      ? relayConnectionToArray(viewerBrand.orderEvents)
      : [],
  orderTypes:
    !loading && !error && viewerBrand
      ? relayConnectionToArray(viewerBrand.types)
      : [],
  warehouses: !loading && !error ? relayConnectionToArray(warehouses) : [],
  initialStartOrderQueryLoading: loading,
  initialStartOrderQueryError: error,
})

export const withInitialBrandStartOrderInfo = graphql(
  initialBrandStartOrderModalQuery,
  {
    options: {
      fetchPolicy: 'network-only',
    },
    props: initialBrandStartOrderModalData,
  },
)

const formatForStartOrderModal = flow(
  relayConnectionToArray,
  formatConnectedAccountsForDropdown,
)

export const dataForRetailer = ({
  data: { error, loading, node, warehouses },
}) => ({
  doors: node
    ? withNoTranslate(formatForStartOrderModal(node.brandDoorMatches))
    : [],
  priceTypes: node ? relayConnectionToArray(node.priceTypeMatches) : [],
  warehouses:
    node && node.brandWarehouseMatches.edges.length > 0
      ? relayConnectionToArray(node.brandWarehouseMatches)
      : relayConnectionToArray(warehouses),
  dataForRetailerQueryLoading: loading,
  dataForRetailerQueryError: error,
})

export const withRetailersData = graphql(dataForRetailerQuery, {
  options: ({ retailerId, preselectedRetailer }) => {
    return {
      fetchPolicy: 'network-only',
      variables: { retailerAccountId: retailerId || preselectedRetailer?.id },
    }
  },
  props: dataForRetailer,
  skip: ({ retailerId, preselectedRetailer }) =>
    !retailerId && !preselectedRetailer?.id,
})

export const connectedAccountsData = ({
  data: { connectedAccounts, error, loading },
}) => ({
  connectedAccounts:
    !loading && !error && connectedAccounts
      ? getBrandConnectedAccounts(connectedAccounts)
      : [],
  connectedAccountsQueryLoading: loading,
  connectedAccountsQueryError: error,
})

export const withConnectedAccounts = graphql(connectedAccountsQuery, {
  options: ({ retailerSearchText }) => ({
    variables: { searchFilter: retailerSearchText },
  }),
  props: connectedAccountsData,
  skip: ({ retailerSearchText }) =>
    retailerSearchText.length < MINIMUM_CHARACTERS_QUERY,
})
