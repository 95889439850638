import { config } from 'config'
import Keycloak, { KeycloakConfig, KeycloakInitOptions } from 'keycloak-js'

import { LOGOUT } from 'routes/paths'

/**
 * KeycloakConfig configures the connection to the Keycloak server.
 */
const keycloakConfig: KeycloakConfig = {
  realm: 'joor',
  clientId: 'joor-react',
  url: config.urls.keycloak,
}

/**
 * KeycloakInitOptions configures the Keycloak client.
 */
const authInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  checkLoginIframe: true,
  checkLoginIframeInterval: 60, // 1 minute
  messageReceiveTimeout: 30000, // 30 seconds
}

const authInstance = Keycloak(keycloakConfig)

authInstance.onTokenExpired = () => {
  authInstance.updateToken(30).catch((_error) => {
    authInstance.clearToken()
    window.location.assign(LOGOUT)
  })
}

export const auth = {
  init: () => authInstance.init(authInitOptions),
  login: authInstance.login,
  getToken: () => authInstance.token,
}
