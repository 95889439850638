import React, { ReactElement } from 'react'

import { Button, SecondaryButton } from '@joor/design-system'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { toGlobalId } from 'utils/transformations/graphql'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { getAccountId } from 'store/currentUser/selectors'
import { INIT_PROFILE, RESTORE_PROFILE } from 'store/storefront/constants'
import { getProfileInfo, hasProfileChanges } from 'store/storefront/selectors'

import { useUpsertStorefrontProfile } from '../BrandDetails.hooks'
import { DetailsMenuIds } from 'features/Storefront/Storefront.ids'
import { useShowroomBrands } from 'features/Storefront/StorefrontWidgets.hooks'

const ButtonsContainer = styled.div`
  margin: 2em 0;
  display: flex;

  button:first-child {
    margin-right: 1em;
  }
`

export const BrandDetailActions: React.FC = (): ReactElement => {
  const dispatch = useDispatch()
  const storefrontHasChanges = useSelector(hasProfileChanges)
  const profile = useSelector(getProfileInfo)
  const upsertStorefrontProfile = useUpsertStorefrontProfile()
  const disabled = !storefrontHasChanges
  const accountId = useSelector(getAccountId)
  const { renderErrorToast } = useRenderToasters()
  const isLoggedIn = accountId !== null
  const formattedBrandId = toGlobalId('Brand', accountId)

  const { refetchBrandProfile } = useShowroomBrands(
    isLoggedIn,
    formattedBrandId,
  )
  const handleOnSaveClick = async (): Promise<void> => {
    try {
      const { data, errors } = await upsertStorefrontProfile()
      if (!data || errors) {
        renderErrorToast({
          description: 'There was an error saving your profile.',
        })
      } else {
        dispatch({ type: INIT_PROFILE, value: profile })
        //TODO: when back fix the upsertStorefrontProfile answer, we can remove the refetch
        await refetchBrandProfile()
      }
    } catch {
      renderErrorToast({
        description: 'There was an error saving your profile.',
      })
    }
  }
  return (
    <ButtonsContainer>
      <Button
        id={DetailsMenuIds.SaveButton}
        disabled={disabled}
        onClick={handleOnSaveClick}
      >
        Save
      </Button>
      <SecondaryButton
        disabled={disabled}
        onClick={() => dispatch({ type: RESTORE_PROFILE })}
      >
        Cancel
      </SecondaryButton>
    </ButtonsContainer>
  )
}

export default BrandDetailActions
