import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import styles from './Cell.less'

export const ALIGN_RIGHT = 'right'
export const ALIGN_LEFT = 'left'
export const ALIGN_CENTER = 'center'

const Cell = ({
  children,
  className,
  withBorder,
  highlighted,
  align,
  colSpan,
  fullWidth,
}) => {
  const extraClasses = {
    [styles.highlighted]: highlighted,
    [styles.withBorder]: withBorder,
    [styles.right]: align === ALIGN_RIGHT,
    [styles.left]: align === ALIGN_LEFT,
    [styles.center]: align === ALIGN_CENTER,
    [styles.fullWidth]: fullWidth,
  }

  return (
    <td
      className={classNames(styles.Cell, className, extraClasses)}
      colSpan={colSpan}
    >
      {children}
    </td>
  )
}

Cell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  withBorder: PropTypes.bool,
  highlighted: PropTypes.bool,
  align: PropTypes.oneOf([ALIGN_RIGHT, ALIGN_LEFT, ALIGN_CENTER]),
  colSpan: PropTypes.number,
  fullWidth: PropTypes.bool,
}

Cell.defaultProps = {
  children: null,
  className: '',
  withBorder: false,
  highlighted: false,
  align: ALIGN_RIGHT,
  colSpan: 1,
  fullWidth: false,
}

export default Cell
