import { atlas } from 'graphql/tags'

export const BRAND_PRODUCT_LINES = atlas`#graphql
  query BrandProductLines {
    brandProductLines {
      id
      name
      active
    }
  }
`

export const UPSERT_BRAND_PROFILE = atlas`#graphql
  mutation UpsertBrandProfileMutationDupe1($input: UpsertBrandProfileInput!) {
    upsertBrandProfile(input: $input) {
      id
      name
      description
      yearEstablished
      productLines
      wholesaleMin
      wholesaleMax
      retailMin
      retailMax
      websiteUrl
      customUrlHandle
      socialMediaAccounts {
        socialMediaType
        url
      }
      accountMedia {
        id
        url
      }
      logoId
      logoUrl
    }
  }
`
