import '@joor/design-system/dist/designTokens/variables.css'
import styled from 'styled-components'

export const Container = styled.div`
  .icon {
    svg {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
    &.invalid {
      path {
        fill: var(--color-error-600);
      }
    }
  }
`

type MessageProps = {
  invalid?: boolean
}
export const Message = styled.span<MessageProps>`
  font-family: var(--font-family-alpha);
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.8px;
  color: ${({ invalid }) =>
    invalid ? 'var(--color-error-600)' : 'var(--color-primary-900)'};
`
export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  .button {
    margin: 10px 0;
    width: calc(50% - 5px);
    height: 35px;
    > div:first-child {
      width: 100%;
    }
    button {
      width: 100%;
      height: 35px;
      background-color: var(--color-neutral-000-full);
      border: 1px solid var(--color-primary-500);
      border-radius: 5px;
      font-family: var(--font-family-alpha);
      font-size: var(--size-font-small);
      cursor: pointer;
    }
    button:first-of-type {
      margin-right: 10px;
    }
  }
  .applyButton button {
    background-color: var(--color-primary-500);
    color: var(--color-neutral-000-full);
  }
`

type SubtitleProps = {
  invalid?: boolean
}
export const Subtitle = styled.p<SubtitleProps>`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-regular);
  margin-bottom: 6px;
  letter-spacing: 0.6px;
  line-height: 16px;
  display: flex;
  color: ${({ invalid }) =>
    invalid ? 'var(--color-error-600)' : 'var(--color-primary-900)'};
`
export const InputAreaOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-family: var(--font-family-alpha);
    font-size: var(--size-font-small);
    color: #8c9ba8;
    letter-spacing: 0.6px;
    line-height: 16px;
    margin-top: 0px;
    margin-bottom: 11px;
    &.browseFiles {
      color: var(--color-secondary-800);
    }
  }
`
export const CropContainer = styled.div`
  > div:first-child {
    display: flex;
    align-items: center;
    > span {
      height: 21px;
      font-size: var(--size-font-small);
      font-weight: var(--font-weight-regular);
      margin-right: 5px;
    }
    svg {
      height: 16px;
      width: 16px;
    }
  }
  > div:nth-child(2) {
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-secondary-200);
    border: 1px solid var(--color-secondary-600);
    border-radius: 5px;
  }
`
export const SelectedImage = styled.img`
  width: 100%;
`
