import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  Dropdown,
  Icon,
  MultiSelectDropdown,
  Option,
} from '@joor/design-system'
import styled from 'styled-components'

import { joinElementsWithAComma } from 'utils/formatters'

const StyledSection = styled.div`
  padding: 28px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const ExcludedSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  background-color: var(--color-secondary-200);
  border-radius: 8px;
  border: 1px solid var(--color-secondary-300);
  margin: 16px;
  padding: 16px;
  cursor: auto;
  gap: 8px;
`

type SecondModalStepProps = {
  accounts: Option[]
  excludedAccounts: string[]
  primaryAccount: Option | null
  setPrimaryAccount: Dispatch<SetStateAction<Option | null>>
  secondaryAccounts: Option[]
  setSecondaryAccounts: Dispatch<SetStateAction<Option[]>>
  loading: boolean
}

const SecondModalStep: React.FC<SecondModalStepProps> = ({
  accounts,
  excludedAccounts,
  primaryAccount,
  setPrimaryAccount,
  secondaryAccounts,
  setSecondaryAccounts,
  loading,
}) => {
  const [targetAccount, setTargetAccount] = useState<Option | null>(null)
  const [accountsToMerge, setAccountsToMerge] = useState<Option[]>([])
  const [availableOptions, setAvailableOptions] = useState<Option[]>([])
  const [excludedOptions, setExcludedOptions] = useState<Option[]>([])

  useEffect(() => {
    const optiosToExclude = accounts.filter((account) =>
      excludedAccounts.includes(account.value),
    )
    const optionsToChoose = accounts.filter(
      (account) =>
        account.value !== primaryAccount?.value &&
        !excludedAccounts.includes(account.value),
    )
    setExcludedOptions(optiosToExclude)
    setAvailableOptions(optionsToChoose)
    setTargetAccount(primaryAccount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primaryAccount, accounts])

  useEffect(() => {
    setAccountsToMerge(secondaryAccounts)
  }, [secondaryAccounts])

  return (
    <StyledSection>
      <Dropdown
        testId="primary-account-selector"
        options={accounts}
        label="Select your destination/primary account"
        placeholder="Select account"
        value={targetAccount}
        onSelect={({ selected }: { selected: Option }): void => {
          setPrimaryAccount(selected)
          setSecondaryAccounts([])
        }}
      />
      <MultiSelectDropdown
        key={targetAccount?.value}
        testId="multiple-account-selector"
        options={availableOptions}
        label="Select accounts to merge"
        placeholder="Select options"
        onSelect={({ selected }: { selected: Option }): void =>
          setSecondaryAccounts([...accountsToMerge, selected])
        }
        onDeselect={({ deselected }: { deselected: Option }): void => {
          const updatedSecondaryAccounts = accountsToMerge.filter(
            (accountToMerge) => accountToMerge.value === deselected.value,
          )
          setSecondaryAccounts(updatedSecondaryAccounts)
        }}
        isDisabled={!targetAccount || loading}
      />
      {Boolean(excludedAccounts.length) && targetAccount?.value && (
        <ExcludedSection>
          <Icon iconName="information" />
          {loading ? (
            'Checking accounts merging availability'
          ) : (
            <>
              The following accounts cannot be merged:{' '}
              {joinElementsWithAComma(
                excludedOptions.map((excludedOption) => excludedOption.label),
              )}
            </>
          )}
        </ExcludedSection>
      )}
    </StyledSection>
  )
}

export default SecondModalStep
