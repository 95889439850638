import { EARLY_ACCESS_PREFIX as FEATURE_PREFIX } from 'featurePrefix.ids'

const WIDGET_PREFIX = 'widget'
const FEATURE_OPTIONS_PREFIX = 'feature-option'

export const WidgetIds = {
  Title: `${FEATURE_PREFIX}__${WIDGET_PREFIX}__create`,
  Container: `${FEATURE_PREFIX}__${WIDGET_PREFIX}__container`,
  Content: `${FEATURE_PREFIX}__${WIDGET_PREFIX}__content`,
  PageContainer: `${FEATURE_PREFIX}__${WIDGET_PREFIX}__page-container`,
  FeatureCount: `${FEATURE_PREFIX}__${WIDGET_PREFIX}__feature-count`,
}

export const FeatureIds = {
  Title: `${FEATURE_PREFIX}__${FEATURE_OPTIONS_PREFIX}__title`,
  Description: `${FEATURE_PREFIX}__${FEATURE_OPTIONS_PREFIX}__description`,
  Link: `${FEATURE_PREFIX}__${FEATURE_OPTIONS_PREFIX}__link`,
  Feedback: `${FEATURE_PREFIX}__${FEATURE_OPTIONS_PREFIX}__feedback`,
}
