import { useEffect, useState } from 'react'

import { LegacyModalProvider } from '@joor/design-system'
import { createPortal } from 'react-dom'

import { MODAL_DELETE, MODAL_FIRST_HOME, MODAL_RENAME } from '../PageActions'
import DeleteModal from './DeleteModal/DeleteModal'
import NoHomePageModal from './NoHomePageModal/NoHomePageModal'
import RenameModal from './RenameModal/RenameModal'

type Props = {
  id: string
  openModal: string
  isHome: boolean
  setOpenModal: (arg0: string) => void
  name: string
  loadingNavigation: boolean | undefined
  refetchStorefrontNavigation: () => void
}

const Modals = ({
  id,
  openModal,
  setOpenModal,
  name,
  isHome,
  loadingNavigation,
  refetchStorefrontNavigation,
}: Props): JSX.Element => {
  const [rootPopupsNode, setRootPopupsNode] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const node = document.getElementById('root-popups')
    setRootPopupsNode(node)
  }, [openModal])

  return (
    <>
      {rootPopupsNode &&
        createPortal(
          <LegacyModalProvider>
            <RenameModal
              id={id}
              isOpen={openModal === MODAL_RENAME}
              setModalIsOpen={setOpenModal}
              name={name}
            />
            <DeleteModal
              id={id}
              isOpen={openModal === MODAL_DELETE}
              setModalIsOpen={setOpenModal}
              isHome={isHome}
              loadingNavigation={loadingNavigation}
              refetchStorefrontNavigation={refetchStorefrontNavigation}
            />
            <NoHomePageModal
              id={id}
              isOpen={openModal === MODAL_FIRST_HOME}
              setModalIsOpen={setOpenModal}
              isHome={isHome}
              loadingNavigation={loadingNavigation}
              refetchStorefrontNavigation={refetchStorefrontNavigation}
            />
          </LegacyModalProvider>,
          rootPopupsNode,
        )}
    </>
  )
}

export default Modals
