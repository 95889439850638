import { handleActions } from 'redux-actions'

import {
  SET_CONFIGURATION_NAME,
  SET_CURRENT_COLLECTIONS,
  SET_CURRENT_DIVISIONS,
  SET_CURRENT_DIVISION_CATEGORIES,
  SET_CURRENT_PRICE_TYPES,
} from './constants'

export const defaultState = {
  divisions: {},
  divisionCategories: {},
  configurationName: '',
  collections: {},
  priceTypes: {},
}

const reducer = handleActions(
  {
    [SET_CURRENT_DIVISIONS]: (state, { payload: currentDivisions }) => ({
      ...state,
      divisions: currentDivisions,
    }),
    [SET_CURRENT_DIVISION_CATEGORIES]: (
      state,
      { payload: currentDivisionCategories },
    ) => ({
      ...state,
      divisionCategories: currentDivisionCategories,
    }),
    [SET_CONFIGURATION_NAME]: (state, { payload: configurationName }) => ({
      ...state,
      configurationName,
    }),
    [SET_CURRENT_COLLECTIONS]: (state, { payload: currentCollections }) => ({
      ...state,
      collections: currentCollections,
    }),
    [SET_CURRENT_PRICE_TYPES]: (state, { payload: currentPriceTypes }) => ({
      ...state,
      priceTypes: currentPriceTypes,
    }),
  },
  defaultState,
)

export default reducer
