import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import { toGlobalId } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { setCurrentUser } from 'store/currentUser/actions'
import { getUserId } from 'store/currentUser/selectors'

import { SET_DISPLAY_LANGUAGE_FOR_USER } from './localizeDropdown.queries'

export const useUpdateSelectedLanguage = () => {
  const userId = useSelector((state) => getUserId(state))
  const dispatch = useDispatch()
  const [setDisplayLanguageForUser] = useMutation(
    SET_DISPLAY_LANGUAGE_FOR_USER,
    {
      client: atlasClient,
    },
  )

  const updateSelectedLanguage = (code: string) => {
    dispatch(
      setCurrentUser({
        displayLanguage: code,
      }),
    )
    if (userId) {
      setDisplayLanguageForUser({
        variables: {
          input: {
            id: toGlobalId('User', userId),
            languageCode: code,
          },
        },
      })
    }
  }
  return { updateSelectedLanguage }
}
