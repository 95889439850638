import { handleActions } from 'redux-actions'

import {
  initialFilterReducerState,
  unselectedFilterReducerState,
} from 'utils/filters/utils'

import { cancelStyleFilters } from 'containers/CancelStyleFilters/filters'
import { replaceStyleFilters } from 'containers/ReplaceStyleFilters/filters'

export const defaultState = {
  searchFilter: '',
  replaceSearchFilter: '',
  onlyDroppedFilter: false,
  selectedFilters: {
    ...initialFilterReducerState(cancelStyleFilters),
  },
  appliedFilters: {
    ...initialFilterReducerState(cancelStyleFilters),
  },
  selectedReplaceFilters: {
    ...initialFilterReducerState(replaceStyleFilters),
  },
  appliedReplaceFilters: {
    ...initialFilterReducerState(replaceStyleFilters),
  },
  hasAppliedFilters: false,
  hasAppliedReplaceFilters: false,
}

const reducer = handleActions(
  {
    'CANCEL_STYLE_FILTER/TOGGLE_SELECT_FILTER': (state, { payload }) => {
      const { dataKey } = payload
      const filterSettings = cancelStyleFilters[dataKey]
      const { reducerHandler } = filterSettings
      return {
        ...state,
        selectedFilters: reducerHandler(state.selectedFilters, { ...payload }),
      }
    },
    'CANCEL_STYLE_FILTER/TOGGLE_DESELECT_ALL_FILTERS': (state) => ({
      ...state,
      selectedFilters: {
        ...unselectedFilterReducerState(cancelStyleFilters),
      },
    }),
    'CANCEL_STYLE_FILTER/APPLY_SEARCH_FILTER': (
      state,
      { payload: searchText },
    ) => ({
      ...state,
      searchFilter: searchText.trim(),
      hasAppliedFilters: searchText.trim() !== '' || state.onlyDroppedFilter,
    }),
    'CANCEL_STYLE_FILTER/TOGGLE_ONLY_DROPPED_FILTER': (state) => ({
      ...state,
      onlyDroppedFilter: !state.onlyDroppedFilter,
      hasAppliedFilters:
        !state.onlyDroppedFilter || state.searchFilter.trim() !== '',
    }),
    'CANCEL_STYLE_FILTER/APPLY_SELECTED_FILTERS': (state) => ({
      ...state,
      hasAppliedFilters: true,
    }),
    'CANCEL_STYLE_FILTER/TOGGLE_APPLIED_FILTERS': (state) => ({
      ...state,
      appliedFilters: { ...state.selectedFilters },
    }),
    'CANCEL_STYLE_FILTER/TOGGLE_RESET_ALL_FILTERS': (state) => ({
      ...state,
      appliedFilters: {
        ...initialFilterReducerState(cancelStyleFilters),
      },
      selectedFilters: {
        ...initialFilterReducerState(cancelStyleFilters),
      },
      searchFilter: '',
      onlyDroppedFilter: false,
      hasAppliedFilters: false,
    }),
    'REPLACE_STYLE_FILTER/APPLY_SEARCH_FILTER': (
      state,
      { payload: searchText },
    ) => ({
      ...state,
      replaceSearchFilter: searchText.trim(),
      hasAppliedReplaceFilters: searchText.trim() !== '',
    }),
    'REPLACE_STYLE_FILTER/TOGGLE_DESELECT_ALL_FILTERS': (state) => ({
      ...state,
      selectedReplaceFilters: {
        ...unselectedFilterReducerState(replaceStyleFilters),
      },
    }),
    'REPLACE_STYLE_FILTER/APPLY_SELECTED_FILTERS': (state) => ({
      ...state,
      hasAppliedReplaceFilters: true,
    }),
    'REPLACE_STYLE_FILTER/TOGGLE_APPLIED_REPLACE_FILTERS': (state) => ({
      ...state,
      appliedReplaceFilters: {
        ...state.selectedReplaceFilters,
      },
    }),
    'REPLACE_STYLE_FILTER/TOGGLE_SELECT_FILTER': (state, { payload }) => {
      const { dataKey } = payload
      const filterSettings = replaceStyleFilters[dataKey]
      const { reducerHandler } = filterSettings
      return {
        ...state,
        selectedReplaceFilters: reducerHandler(state.selectedReplaceFilters, {
          ...payload,
        }),
      }
    },
    'REPLACE_STYLE_FILTER/TOGGLE_RESET_ALL_FILTERS': (state) => ({
      ...state,
      appliedReplaceFilters: {
        ...initialFilterReducerState(replaceStyleFilters),
      },
      selectedReplaceFilters: {
        ...initialFilterReducerState(replaceStyleFilters),
      },
      replaceSearchFilter: '',
      hasAppliedReplaceFilters: false,
    }),
  },
  defaultState,
)

export default reducer
