import { graphql } from '@apollo/client/react/hoc'
import flow from 'lodash/fp/flow'

import { formatConnectedAccountsForDropdown } from 'formatters/orders'
import { withNoTranslate } from 'utils/formatters'
import { getBrandConnectedAccounts } from 'utils/transformations/accounts'
import { relayConnectionToArray } from 'utils/transformations/graphql'

import { graphene } from 'graphql/tags'

export const MINIMUM_CHARACTERS_QUERY = 2

const formatForCreateOrderTemplateModal = flow(
  relayConnectionToArray,
  formatConnectedAccountsForDropdown,
)

const initialCreateOrderTemplateQuery = graphene`#graphql
  query InitialCreateOrderTemplate {
    collections(
      orderBy: [{ field: NAME, order: ASCENDING }]
      filterBy: { archived: false }
    ) {
      edges {
        node {
          id
          name
          code
          archived
        }
      }
    }
    viewerBrand {
      id
      orderEvents(orderBy: [{ field: NAME, order: ASCENDING }]) {
        edges {
          node {
            id
            name
            code
          }
        }
      }
      priceTypes {
        edges {
          node {
            id
            name
            currency {
              id
              code
            }
          }
        }
      }
    }
  }
`

// TODO(ch44742) Sustainable way (w/o repetition) for connectedAccounts query
const connectedAccountsQuery = graphene`#graphql
  query connectedAccountsQuery($searchFilter: String) {
    connectedAccounts(filterBy: { displayName: $searchFilter }) {
      edges {
        node {
          ... on RetailerAccount {
            id
            displayName
            locations(filterBy: { primary: true }) {
              edges {
                node {
                  id
                  state
                  city
                }
              }
            }
          }
        }
      }
    }
  }
`

export const initialCreateOrderTemplateData = ({
  data: { loading, error, collections, viewerBrand },
}) => ({
  initialCreateOrderTemplateLoading: loading,
  initialCreateOrderTemplateError: error,
  collections:
    !loading && !error && collections
      ? relayConnectionToArray(collections)
      : [],
  orderEvents:
    !loading && !error && viewerBrand
      ? relayConnectionToArray(viewerBrand.orderEvents)
      : [],
  brandPriceTypes:
    !loading && !error && viewerBrand
      ? relayConnectionToArray(viewerBrand.priceTypes)
      : [],
})

const retailerPriceTypesAndDoors = graphene`#graphql
  query RetailerPriceTypesAndDoors($retailerAccountId: ID!) {
    node(id: $retailerAccountId) {
      ... on RetailerAccount {
        id
        brandDoorMatches {
          edges {
            node {
              id
              code
              name
            }
          }
        }
        priceTypeMatches(orderBy: [{ field: CREATED, order: ASCENDING }]) {
          edges {
            node {
              id
              name
              currency {
                id
                code
              }
            }
          }
        }
      }
    }
  }
`
export const retailerPriceTypesAndDoorsData = ({
  data: { loading, error, node },
}) => ({
  retailerPriceTypesAndDoorsLoading: loading,
  retailerPriceTypesAndDoorsError: error,
  retailerDoors: node
    ? withNoTranslate(formatForCreateOrderTemplateModal(node.brandDoorMatches))
    : [],
  retailerPriceTypes: node ? relayConnectionToArray(node.priceTypeMatches) : [],
})

export const connectedAccountsData = ({
  data: { connectedAccounts, error, loading },
}) => ({
  connectedAccounts:
    !loading && !error && connectedAccounts
      ? getBrandConnectedAccounts(connectedAccounts)
      : [],
  connectedAccountsQueryLoading: loading,
})

export const withInitialCreateOrderTemplateInfo = graphql(
  initialCreateOrderTemplateQuery,
  {
    props: initialCreateOrderTemplateData,
  },
)

export const withRetailerPriceTypesAndDoors = graphql(
  retailerPriceTypesAndDoors,
  {
    options: ({ selectedRetailerId }) => ({
      variables: { retailerAccountId: selectedRetailerId },
    }),
    props: retailerPriceTypesAndDoorsData,
    skip: ({ selectedRetailerId }) => !selectedRetailerId,
  },
)

export const withConnectedAccounts = graphql(connectedAccountsQuery, {
  options: ({ retailerSearchText }) => ({
    variables: { searchFilter: retailerSearchText },
  }),
  props: connectedAccountsData,
  skip: ({ retailerSearchText }) =>
    retailerSearchText.length < MINIMUM_CHARACTERS_QUERY,
})
