import get from 'lodash/get'

export const getUrlParam = (location) => ({
  URL: location.pathname,
})

export const getDisplayName = (WrappedComponent) =>
  get(WrappedComponent, 'displayName') ||
  get(WrappedComponent, 'name') ||
  'Component'

export const getEventName = (location) => {
  const pathname = get(location, 'pathname', '').toLowerCase()
  if (
    pathname.includes('/collections/view') ||
    pathname.includes('/collections/tokenview')
  ) {
    return 'ShoppingView'
  } else if (pathname.includes('/styles/view')) {
    return 'StyleView'
  } else if (pathname.includes('/orders/shipping_billing')) {
    return 'CheckoutView'
  }
  return ''
}
