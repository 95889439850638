// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Info_Info__2uam4 {\n  display: inline-block;\n  vertical-align: top;\n}\n.Info_Info__2uam4 svg {\n  width: 16px;\n  height: 16px;\n}\n.ui.popup {\n  z-index: 10000;\n}\n", "",{"version":3,"sources":["webpack://src/components/Core/Info/Info.less"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,cAAc;AAChB","sourcesContent":[".Info {\n  display: inline-block;\n  vertical-align: top;\n}\n.Info svg {\n  width: 16px;\n  height: 16px;\n}\n:global .ui.popup {\n  z-index: 10000;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Info": "Info_Info__2uam4"
};
export default ___CSS_LOADER_EXPORT___;
