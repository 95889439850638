import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'

import styles from './Pillbox.less'

const Pillbox = ({
  label,
  onClick,
  maxWidth,
  transparent,
  className,
  disabled,
}) => (
  <div
    className={classNames(styles.Pillbox, className, {
      [styles.transparent]: transparent,
    })}
  >
    <span style={{ maxWidth }}>{label}</span>
    {!disabled && (
      <Icon
        className={styles.close}
        name="close-x"
        label={`Remove ${label} filter`}
        onClick={onClick}
      />
    )}
  </div>
)

Pillbox.propTypes = {
  label: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  maxWidth: PropTypes.number,
  transparent: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

Pillbox.defaultProps = {
  maxWidth: null,
  transparent: false,
  className: null,
  disabled: false,
}

export default Pillbox
