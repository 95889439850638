import compose from 'lodash/flowRight'
import { connect } from 'react-redux'

import { getAccountId, isAccountTypeBrand } from 'store/currentUser/selectors'

import PageNavbar from './PageNavbar'

const mapStateToProps = (state) => ({
  isBrandAccount: isAccountTypeBrand(state),
  accountId: getAccountId(state),
})

const withRedux = connect(mapStateToProps)
const PageNavbarContainer = (props) => {
  return <PageNavbar {...props} />
}

export default compose(withRedux)(PageNavbarContainer)
