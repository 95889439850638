import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Pagination as BasePagination, Icon } from 'semantic-ui-react'

import styles from './Pagination.less'

const Pagination = ({
  activePage,
  totalPages,
  onPageChange,
  inverted,
  disabled,
}) => {
  const renderIcon = (direction) => (
    <Icon
      size="large"
      name={`angle ${direction}`}
      fitted
      className={styles.angle}
    />
  )

  return (
    <BasePagination
      activePage={activePage}
      totalPages={totalPages}
      onPageChange={onPageChange}
      firstItem={null}
      lastItem={null}
      boundaryRange={0}
      secondary
      pointing
      className={classNames({ [styles.invertedPagination]: inverted })}
      nextItem={{
        'aria-label': 'Next item',
        content: renderIcon('right'),
      }}
      prevItem={{
        'aria-label': 'Previous item',
        content: renderIcon('left'),
      }}
      disabled={disabled}
    />
  )
}

Pagination.propTypes = {
  activePage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  totalPages: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  onPageChange: PropTypes.func,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
}

Pagination.defaultProps = {
  activePage: 1,
  onPageChange() {},
  inverted: false,
  disabled: false,
}

Pagination.Item = BasePagination.Item

export default Pagination
