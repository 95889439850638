import compose from 'lodash/flowRight'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withLaunchDarkly } from 'utils/sdks/launchDarkly.v2'
import { withMixpanel } from 'utils/sdks/mixpanel'

import {
  getAccountId,
  getAccountProfileName,
  getDateFormat,
  isAccountTypeBrand,
  isProRetailer,
} from 'store/currentUser/selectors'
import { setShouldDisableCart, setShouldUpdateCart } from 'store/shop/actions'
import {
  setWarehouseDeliveryDate,
  setWarehouseId,
} from 'store/shop/productDetailModal/actions'
import {
  getBulkQuantities,
  getCasepackQuantities,
  getSelectedTabId,
  getSkuQuantities,
  getWarehouseDeliveryDate,
  getWarehouseId,
  hasNotedVariants,
  hasValuesInTable,
} from 'store/shop/productDetailModal/selectors'
import {
  getBrand,
  getDoors,
  getEventId,
  getOrderPriceType,
  getOrderTypeId,
  getPriceTypes,
  getRetailer,
  getWarehouseId as getShopWarehouseId,
} from 'store/shop/selectors'

import { withAnalytics } from 'modals/Shop/ProductDetailModal//ProductDetailModal.analytics'

import ProductDetailModal from './ProductDetailModal.component'
import { withProductFormatter } from './ProductDetailModal.formatter'
import { withAddToOrderMutation } from './ProductDetailModal.mutations'
import {
  withAggregateSkuInventoryItems,
  withCollectionProduct,
  withCollections,
  withDiscounts,
  withPriceTypes,
  withSkuDeliveries,
  withWarehouses,
  withWarehousesFromConnection,
} from './ProductDetailModal.queries'
import { withAccountsFormatter } from './ProductDetailModalAccounts.formatter'
import { withWarehousesFormatter } from './ProductDetailModalWarehouses.formatter'

const mapStateToProps = (state) => ({
  accountId: getAccountId(state),
  accountProfileName: getAccountProfileName(state),
  brand: getBrand(state),
  eventId: getEventId(state),
  getBulkQuantities: getBulkQuantities(state),
  getCasepackQuantities: getCasepackQuantities(state),
  userDateFormat: getDateFormat(state),
  getSkuQuantities: getSkuQuantities(state),
  hasNotedVariants: hasNotedVariants(state),
  hasValuesInTable: hasValuesInTable(state),
  isBrandUser: isAccountTypeBrand(state),
  isProRetailer: isProRetailer(state),
  priceTypes: getPriceTypes(state),
  retailerAccount: getRetailer(state),
  selectedDoors: getDoors(state),
  selectedPriceType: getOrderPriceType(state),
  selectedTabId: getSelectedTabId(state),
  selectedWarehouseId: getShopWarehouseId(state),
  typeId: getOrderTypeId(state),
  warehouseDeliveryDate: getWarehouseDeliveryDate(state),
  warehouseId: getWarehouseId(state),
})
const mapDispatchToProps = {
  setWarehouseDeliveryDate,
  setWarehouseId,
  setShouldUpdateCart,
  setShouldDisableCart,
}
const withRedux = connect(mapStateToProps, mapDispatchToProps)
const ProductDetailModalContainer = compose(
  withRouter,
  withMixpanel,
  withRedux,
  withLaunchDarkly,
  withAccountsFormatter,
  withCollectionProduct,
  withCollections,
  withDiscounts,
  withWarehouses,
  withWarehousesFromConnection,
  withWarehousesFormatter,
  withAggregateSkuInventoryItems,
  withPriceTypes,
  withSkuDeliveries,
  withProductFormatter,
  withAddToOrderMutation,
  withAnalytics,
)(ProductDetailModal)

ProductDetailModalContainer.propTypes = {
  brandAccount: PropTypes.shape({
    id: PropTypes,
  }),
  enableQuantifying: PropTypes.bool,
  userDateFormat: PropTypes.string,
  /**
   * Tells the modal if it should expect a
   * priceTypeId to be passed from above.
   */
  externalPriceTypeId: PropTypes.bool,
  forceDeliveryWindow: PropTypes.bool,
  linesheetId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  priceTypeId: PropTypes.string,
  selectedColor: PropTypes.string,
  /**
   * If true the modal will show a price
   * disclaimer text instead of the "no available in"
   * string it shows otherwise.
   */
  showPriceDisclaimer: PropTypes.bool,
  styledId: PropTypes.string,
}

export default ProductDetailModalContainer
