import { ChangeEvent, useState } from 'react'

import { Button, LegacyModal } from '@joor/design-system'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { getAccountId, getPanel } from 'store/storefront/selectors'

import { STOREFRONT as STOREFRONT_PATH } from 'routes/paths'

import { RenameModalProps } from '../modals.types'
import FormInput from 'features/Storefront/EditionNavbar/Sections/BrandDetails/FormInput/FormInput'
import { useBulkUpdateStorefront } from 'features/Storefront/EditionNavbar/Sections/Pages/Pages.hooks'
import { generateUniqueName } from 'features/Storefront/EditionNavbar/Sections/Pages/Pages.utils'

export const RENAME_ID = 'storefront_rename_page_action'

const Container = styled.div`
  label {
    margin-top: 26px;
  }
  input {
    width: 560px;
    color: #2d3439;
  }
`

const RenameModal = ({
  id,
  isOpen,
  setModalIsOpen,
  name,
}: RenameModalProps): JSX.Element => {
  const [pageName, setPageName] = useState<string>(name || '')
  const brandId = useSelector(getAccountId)
  const panel = useSelector(getPanel)
  const history = useHistory()
  const { loading: bulkLoading, bulkUpdate } = useBulkUpdateStorefront()
  const { renderErrorToast, renderSuccessToast } = useRenderToasters()

  const formattedPageName = pageName.replace(/\s+/g, ' ').trim()

  const handleSavePageRename = async () => {
    if (!pageName) {
      return renderErrorToast({ description: 'Please add a valid page name' })
    }
    try {
      const uniquePageName: string = generateUniqueName(pageName)
      const { data, errors } = await bulkUpdate([
        {
          id,
          displayName: formattedPageName,
          uniqueName: uniquePageName,
        },
      ])
      if (!data || errors) {
        renderErrorToast({
          description: 'There was an error renaming your page',
        })
      } else {
        renderSuccessToast({ description: 'Page renamed.' })
        if (id === panel.id) {
          history.push(
            generatePath(STOREFRONT_PATH, {
              accountId: brandId,
              pageName: formattedPageName,
            }),
          )
        }
        setModalIsOpen('')
        setPageName(formattedPageName)
      }
    } catch {
      renderErrorToast({ description: 'There was an error renaming your page' })
    }
  }

  return (
    <LegacyModal
      isOpen={isOpen}
      onClose={(): void => {
        setModalIsOpen('')
        setPageName(name || '')
      }}
    >
      <LegacyModal.Title>Edit Page Name</LegacyModal.Title>
      <LegacyModal.Content>
        <Container>
          <FormInput
            label="Page Name"
            type="text"
            id="name"
            value={pageName}
            onChange={(e: ChangeEvent): void =>
              setPageName((e?.target as HTMLInputElement).value)
            }
          />
        </Container>
      </LegacyModal.Content>
      <LegacyModal.Actions>
        <LegacyModal.CloseButton>Cancel</LegacyModal.CloseButton>
        <Button
          onClick={handleSavePageRename}
          disabled={bulkLoading || pageName === name}
          data-testid={RENAME_ID}
        >
          Save Changes
        </Button>
      </LegacyModal.Actions>
    </LegacyModal>
  )
}

export default RenameModal
