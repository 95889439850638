const addDivisionCodeMigration = (state) => ({
  selectedFilters: {
    ...state.selectedFilters,
    divisions: [],
  },
  appliedFilters: {
    ...state.appliedFilters,
    divisions: [],
  },
})

const addModifiedFieldToExportStatusMigration = (state) => ({
  selectedFilters: {
    ...state.selectedFilters,
    exportStatus: {
      ...state.selectedFilters.exportStatus,
      modified: false,
    },
  },
  appliedFilters: {
    ...state.appliedFilters,
    exportStatus: {
      ...state.appliedFilters.exportStatus,
      modified: false,
    },
  },
})

const addFilterByCreatedByRetailer = (state) => ({
  selectedFilters: {
    ...state.selectedFilters,
    createdByRetailer: false,
  },
  appliedFilters: {
    ...state.appliedFilters,
    createdByRetailer: false,
  },
})

export const migrations = [
  addDivisionCodeMigration,
  addModifiedFieldToExportStatusMigration,
  addFilterByCreatedByRetailer,
]
