import { createAction } from 'redux-actions'

import {
  INIT_TABLE_ROW,
  INIT_TABLE_STRUCTURE,
  INIT_TABLE_TOTAL_ROW_STRUCTURE,
  PASTE_TO_ROW,
  RESET_TABLE_TO_DEFAULT_STATE,
  SET_ROW_TO_COPY,
  SET_SELECTED_TAB_ID,
  SET_WAREHOUSE_DELIVERY_DATE,
  SET_WAREHOUSE_ID,
  UPDATE_CELL_VALUE,
} from './constants'

export const updateCellValue = createAction(UPDATE_CELL_VALUE)
export const initializeTable = createAction(INIT_TABLE_STRUCTURE)
export const setWarehouseDeliveryDate = createAction(
  SET_WAREHOUSE_DELIVERY_DATE,
)
export const setWarehouseId = createAction(SET_WAREHOUSE_ID)
export const initializeTableTotalRow = createAction(
  INIT_TABLE_TOTAL_ROW_STRUCTURE,
)
export const resetTableToDefaultState = createAction(
  RESET_TABLE_TO_DEFAULT_STATE,
)
export const setSelectedTabId = createAction(SET_SELECTED_TAB_ID)
export const initTableRow = createAction(INIT_TABLE_ROW)
export const setRowToCopy = createAction(SET_ROW_TO_COPY)
export const pasteToRow = createAction(PASTE_TO_ROW)
