import { useState } from 'react'

import { Icon, Tooltip, typography } from '@joor/design-system'
import '@joor/design-system/dist/designTokens/variables.css'
import { Draggable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'

import { fromGlobalId } from 'utils/transformations/graphql'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { getBrandId, getPanelId } from 'store/storefront/selectors'

import { NoHoverProps, PageItemProps } from './Pages.types'
import { getStorefrontPanelUrl } from './Pages.utils'
import PageActions from './actions'

export const HOME_ID = 'storefront_page_home'
export const SHARE_ID = 'storefront_page_share'
export const ELLIPSES_ID = 'storefront_page_context_menu'

type ItemContainerProps = {
  active: boolean
}
const DragContainer = styled.div`
  width: 100%;
`
const NoHover = styled.div``
const OnHover = styled.div`
  opacity: 0;
  display: flex;
  align-items: center;
  position: relative;
  width: 0;
  div {
    white-space: nowrap;
  }
`

const ItemContainer = styled.div<ItemContainerProps>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  height: 32px;
  padding-right: 8px;
  ${typography.alpha.primaryActive.body1};
  background-color: var(--color-neutral-000-full);

  ${NoHover}:first-child {
    display: inline-block;
    width: 24px;
  }

  > div {
    display: flex;
    align-items: center;
  }
  ${(props) => {
    if (props.active)
      return css`
        background-color: var(--color-secondary-300);
      `
  }}
  &:hover {
    background-color: var(--color-secondary-100);
    ${NoHover} {
      display: none;
    }
    ${OnHover} {
      opacity: 1;
      width: auto;
    }
  }
`

const ItemName = styled.span`
  margin-left: 6px;
`

const StyledIcon = styled(Icon)`
  transform: scale(0.7);
  cursor: pointer;
`

const NoHoverIcon = styled(Icon)<NoHoverProps>`
  svg {
    --main-color: var(--color-primary-800);
    ${(props) => {
      if (props.online !== undefined) {
        if (props.online)
          return css`
            transform: scale(0.4);
            --main-color: var(--color-success-500);
          `
        return css`
          transform: scale(0.4);
          --main-color: var(--color-error-500);
        `
      }
    }}
  }
  &:hover svg {
    --main-color: var(--color-primary-800);
  }
`
const PageItemContainer = styled.div`
  position: relative;
`

const PageItem = ({
  id,
  index,
  displayName,
  uniqueName,
  isHome = false,
  isOnline = false,
  orderNbr,
  onClick,
  loadingNavigation,
  refetchStorefrontNavigation,
}: PageItemProps) => {
  const [openActions, setOpenActions] = useState<boolean>(false)
  const panelId = useSelector(getPanelId)
  const brandId = useSelector(getBrandId)
  const fromGlobalBrandId = fromGlobalId(brandId)?.id
  const { renderSuccessToast, renderErrorToast } = useRenderToasters()

  const handleCopyUrlClick = async () => {
    try {
      const url = getStorefrontPanelUrl(isHome, uniqueName, fromGlobalBrandId)
      await navigator.clipboard.writeText(url)
      renderSuccessToast({ description: 'Page URL copied.' })
    } catch {
      renderErrorToast({ description: 'There was an error copying the URL.' })
    }
  }
  return (
    <PageItemContainer>
      <Draggable draggableId={id} index={index} isDragDisabled={openActions}>
        {(provided, snapshot) => (
          <ItemContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            style={{
              ...provided.draggableProps.style,
              ...(snapshot.isDragging && {
                background: 'var(--color-secondary-300)',
              }),
            }}
            active={panelId === id}
          >
            <DragContainer onClick={onClick} {...provided.dragHandleProps}>
              <NoHover />
              <OnHover>
                <NoHoverIcon iconName="kabob" />
              </OnHover>
              <NoHoverIcon online={isOnline} iconName="circle" />
              <ItemName>{displayName}</ItemName>
            </DragContainer>
            <div>
              {isHome && (
                <StyledIcon
                  iconName="home"
                  // @ts-expect-error
                  variant="SUBDUED"
                  data-testid={HOME_ID}
                />
              )}
              {isOnline && (
                <OnHover onClick={handleCopyUrlClick}>
                  <Tooltip defaultPlacement="bottom" content="Copy Page URL">
                    <StyledIcon iconName="share" data-testid={SHARE_ID} />
                  </Tooltip>
                </OnHover>
              )}
              <div
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenActions(!openActions)
                }}
              >
                <OnHover>
                  {openActions ? (
                    <StyledIcon
                      iconName="ellipses"
                      data-testid={`${ELLIPSES_ID}_${id}_${isHome}`}
                    />
                  ) : (
                    <Tooltip defaultPlacement="bottom" content={'Page actions'}>
                      <StyledIcon
                        iconName="ellipses"
                        data-testid={`${ELLIPSES_ID}_${id}_${isHome}`}
                      />
                    </Tooltip>
                  )}
                </OnHover>
              </div>
            </div>
          </ItemContainer>
        )}
      </Draggable>
      <PageActions
        isHome={isHome}
        isOnline={isOnline}
        id={id}
        orderNbr={orderNbr}
        openActions={openActions}
        setOpenActions={setOpenActions}
        displayName={displayName}
        loadingNavigation={loadingNavigation}
        refetchStorefrontNavigation={refetchStorefrontNavigation}
      />
    </PageItemContainer>
  )
}

export default PageItem
