import concat from 'lodash/concat'
import map from 'lodash/map'
import reverse from 'lodash/reverse'
import some from 'lodash/some'

const buildSelectionsByTierReversed = (
  children,
  defaultValues,
  selectionsByTier,
) => {
  const anyFound = map(children, (child, index) => {
    if (defaultValues.includes(child.id)) {
      if (!selectionsByTier[0]) {
        // eslint-disable-next-line no-param-reassign
        selectionsByTier[0] = []
      }
      selectionsByTier[0].push(index)
      return true
    } else if (child.children) {
      if (
        buildSelectionsByTierReversed(
          child.children,
          defaultValues,
          selectionsByTier,
        )
      ) {
        selectionsByTier.push([index])
        return true
      }
    }
    return false
  })
  return some(anyFound)
}

export const buildSelectionsByTier = (children, defaultValues) => {
  const selectionsByTierReversed = []
  buildSelectionsByTierReversed(
    children,
    concat(defaultValues) || [],
    selectionsByTierReversed,
  )
  return reverse(selectionsByTierReversed)
}

export const findDefaultNodes = (children, defaultValues, results) => {
  for (let i = 0; i < children.length; i += 1) {
    const child = children[i]
    if (concat(defaultValues).includes(child.id)) {
      results.push(child)
    } else if (child.children) {
      const subtreeValue = findDefaultNodes(
        child.children,
        defaultValues,
        results,
      )
      if (subtreeValue) {
        return subtreeValue
      }
    }
  }
  return null
}
