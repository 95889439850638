/* eslint-disable react/jsx-filename-extension */
import get from 'lodash/get'
import mixpanel from 'mixpanel-browser'
import {
  MixpanelConsumer,
  MixpanelProvider as OriginalMixpanelProvider,
} from 'react-mixpanel'

import { getAccountTypeName } from 'utils/account'

const REACT_APP_MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL_KEY
const mixpanelKeyInvalid =
  !REACT_APP_MIXPANEL_KEY || REACT_APP_MIXPANEL_KEY.length === 0

const getUserId = ({ userId }) => userId
export const getPersonObject = (userInfo) => ({
  accountId: get(userInfo, 'account.accountId'),
  canShop: get(userInfo, 'account.canShop'),
  canTransact: get(userInfo, 'account.canTransact'),
  canViewAssortments: get(userInfo, 'account.canViewAssortments'),
  canViewDataPortal: get(userInfo, 'account.canViewDataPortal'),
  profileName: get(userInfo, 'account.profileName'),
  userId: get(userInfo, 'userId'),
  accountUserId: get(userInfo, 'accountsUserId'),
  isAdmin: get(userInfo, 'isAdmin'),
  loggedAsAdmin: get(userInfo, 'loggedAsAdmin'),
  retailerSettings: get(userInfo, 'canViewRetailerSettings'),
  assortmentPlanning: get(userInfo, 'assortmentPlanning'),
  accountType: get(userInfo, 'account.type'),
  accountTypeName: getAccountTypeName(userInfo),
})

const MixpanelProvider = ({ userInfo, children }) => {
  if (mixpanelKeyInvalid) {
    const nullMixpanelObject = {
      track: () => {},
    }
    return (
      <OriginalMixpanelProvider mixpanel={nullMixpanelObject}>
        {children}
      </OriginalMixpanelProvider>
    )
  }

  mixpanel.init(REACT_APP_MIXPANEL_KEY, { persistence: 'localStorage' })

  const userId = getUserId(userInfo)
  if (userId) {
    mixpanel.identify(userId)
    mixpanel.people.set({
      $name: userInfo.account.profileName,
      $email: userInfo.$email,
      'User ID': userId,
      'Account ID': userInfo.account.accountId,
    })
  }
  mixpanel.register(getPersonObject(userInfo))

  return (
    <OriginalMixpanelProvider mixpanel={mixpanel}>
      {children}
    </OriginalMixpanelProvider>
  )
}

// Recommended to use useUserAnalytics hook from src/utils/sdks/userAnalytics.js
export const withMixpanel = (WrappedComponent) => {
  if (mixpanelKeyInvalid) return WrappedComponent

  return (props) => (
    <MixpanelConsumer>
      {(mixpanelFn) => <WrappedComponent {...props} mixpanel={mixpanelFn} />}
    </MixpanelConsumer>
  )
}

export default MixpanelProvider
