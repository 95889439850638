import { IntlProvider, addLocaleData } from 'react-intl'
import de from 'react-intl/locale-data/de'
import es from 'react-intl/locale-data/es'
import fr from 'react-intl/locale-data/fr'
import it from 'react-intl/locale-data/it'
import ja from 'react-intl/locale-data/ja'
import ru from 'react-intl/locale-data/ru'
import zh from 'react-intl/locale-data/zh'

import data from './data.json'

addLocaleData([...fr])
addLocaleData([...it])
addLocaleData([...de])
addLocaleData([...ja])
addLocaleData([...es])
addLocaleData([...zh])
addLocaleData([...ru])

export const DEFAULT_LOCALE = 'en_US'

const navigatorLanguage =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  DEFAULT_LOCALE

// Split locales with a region code
export const language = navigatorLanguage.toLowerCase().split(/[_-]+/)[0]
export const languageCode = navigatorLanguage
export const messages = data
export const { intl } = new IntlProvider(
  {
    locale: language,
    messages: messages[language],
  },
  {},
).getChildContext()
