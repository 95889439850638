const prefix = 'PERMISSIONS'

export const SET_CURRENT_DIVISIONS = `${prefix}/SET_CURRENT_DIVISIONS`
export const SET_CURRENT_DIVISION_CATEGORIES = `${prefix}/SET_CURRENT_DIVISION_CATEGORIES`
export const SET_CONFIGURATION_NAME = `${prefix}/SET_CONFIGURATION_NAME`
export const SET_CURRENT_COLLECTIONS = `${prefix}/SET_CURRENT_COLLECTIONS`
export const SET_CURRENT_PRICE_TYPES = `${prefix}/SET_CURRENT_PRICE_TYPES`
export const ENABLE_FILTER_PRODUCTS_BY_USER_DIVISION_AND_CATEGORY_ID =
  'RmVhdHVyZUFjY2Vzczo4OA=='
export const ENABLE_FILTER_PRODUCTS_BY_USER_COLLECTION_ID =
  'RmVhdHVyZUFjY2Vzczo4OQ=='
export const ENABLE_FILTER_PRODUCTS_BY_USER_PRICE_TYPE_ID =
  'RmVhdHVyZUFjY2Vzczo5MA=='
