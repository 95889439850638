import {
  INVENTORY_SETTINGS,
  MANAGE_PERMISSIONS,
  ORDER_SETTINGS,
  PAYMENT_SETTINGS,
  PERMISSIONS_SETTINGS,
  STYLE_SETTINGS,
} from 'routes/paths'

import DropdownMenuTextLink from '../../DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/NavbarRevamp/constants/navbarRevamp.messages'
import { SettingsDropdownIds } from 'features/NavbarRevamp/navbarRevamp.ids'

const BrandSettings = ({
  isBrandAccount,
  restrictEditStyles,
  allowManagePayments,
  brandPaymentsSettings,
  canTransact,
  canManagePermissions,
  allowEditInventorySettings,
  isLiteBrandAccount,
}: {
  isBrandAccount: boolean
  restrictEditStyles: boolean
  allowManagePayments: boolean
  brandPaymentsSettings: boolean
  canTransact: boolean
  canManagePermissions: boolean
  allowEditInventorySettings: boolean
  isLiteBrandAccount: boolean
}) =>
  isBrandAccount ? (
    <>
      {!restrictEditStyles && (
        <DropdownMenuTextLink
          to={STYLE_SETTINGS}
          message={messages.styleSettings}
          id={SettingsDropdownIds.SettingsOptionStyleSettings}
        />
      )}
      {brandPaymentsSettings && allowManagePayments && (
        <DropdownMenuTextLink
          to={PAYMENT_SETTINGS}
          message={messages.paymentSettings}
          id={SettingsDropdownIds.SettingsOptionPayment}
        />
      )}
      {canTransact && (
        <DropdownMenuTextLink
          to={ORDER_SETTINGS}
          message={messages.ordersSettings}
          id={SettingsDropdownIds.SettingsOptionOrderSettings}
        />
      )}
      {!isLiteBrandAccount && (
        <DropdownMenuTextLink
          to={canManagePermissions ? MANAGE_PERMISSIONS : PERMISSIONS_SETTINGS}
          message={messages.permissionSettings}
          id={SettingsDropdownIds.SettingsOptionPermissions}
        />
      )}
      {allowEditInventorySettings && !isLiteBrandAccount && (
        <DropdownMenuTextLink
          to={INVENTORY_SETTINGS}
          message={messages.inventorySettings}
          id={SettingsDropdownIds.SettingsOptionInventory}
        />
      )}
    </>
  ) : null

export default BrandSettings
