import { useQuery } from '@apollo/client'
import { useSelector } from 'react-redux'

import { CounterQueryDocument } from '__generated__/atlas-types'

import {
  relayConnectionToArray,
  toGlobalId,
} from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import {
  getAccountId,
  isAccountTypeBrand,
  userIsLoggedIn,
} from 'store/currentUser/selectors'

export const LITE_RETAILER_MODAL_SNOOZED = 'LITE_RETAILER_MODAL_SNOOZED'

atlas`#graphql
  query CounterQuery ($retailerId: ID!) {
    retailerProfile (id:$retailerId) {
        id
        profileSnoozeCounter
    }
  }
`

const useCounter = () => {
  const isBrandAccount = useSelector(isAccountTypeBrand)
  const accountId = useSelector(getAccountId)
  const retailerId = accountId ? toGlobalId('Retailer', accountId) : undefined
  const { data, loading } = useQuery(CounterQueryDocument, {
    client: atlasClient,
    variables: {
      retailerId: retailerId || '',
    },
    skip: !retailerId || isBrandAccount,
  })
  const counter = data?.retailerProfile?.profileSnoozeCounter
  return { counter, loading }
}

const ACCOUNT_PRIVACY = atlas`#graphql
  query AccountPrivacy {
    activeViewingAccount{
      id
      privacy
    }
  }
`

export const useAccountManagerQuery = () => {
  const isUserLoggedIn = useSelector(userIsLoggedIn)
  const { data, loading } = useQuery(ACCOUNT_PRIVACY, {
    client: atlasClient,
    skip: !isUserLoggedIn,
  })
  const isHidden = !data?.activeViewingAccount.privacy

  return {
    isHidden,
    loading,
  }
}

const RETAIL_IMPERSONATION = atlas`#graphql
  query ListRetailerImpersonationFeedback($accountIds: [ID!]!) {
    listRetailerImpersonationFeedback(accountIds: $accountIds) {
      edges {
        node {
          id
        }
      }
    }
  }
`

export const useRetailImpersonationQuery = () => {
  const isBrandAccount = useSelector(isAccountTypeBrand)
  const accountId = useSelector(getAccountId)
  const formattedId = accountId ? toGlobalId('Account', accountId) : undefined
  const { data, loading } = useQuery(RETAIL_IMPERSONATION, {
    client: atlasClient,
    variables: { accountIds: [formattedId] },
    skip: !accountId || isBrandAccount,
  })
  const retailImpersonation = relayConnectionToArray(
    data?.listRetailerImpersonationFeedback,
  )?.[0]
  const impersonatingRetailer = Boolean(retailImpersonation?.id)

  return {
    impersonatingRetailer,
    loading,
  }
}

const shouldRenderLiteRetailerRequiredInfoModal = ({
  counter = 0,
  isHidden = false,
  isImpersonatingBrand = false,
}: {
  counter: number | null | undefined
  isHidden?: boolean
  isImpersonatingBrand?: boolean
}) => {
  const isModalSnoozedInSession =
    window.localStorage.getItem(LITE_RETAILER_MODAL_SNOOZED) === 'true'
  const shouldRenderModal =
    !isHidden &&
    !isImpersonatingBrand &&
    counter !== null &&
    !isModalSnoozedInSession

  return shouldRenderModal
}

export const useRenderLiteRetailerRequiredInfoModal = () => {
  const { counter, loading: counterLoading } = useCounter()
  const { isHidden, loading: accountPrivacyLoading } = useAccountManagerQuery()
  const {
    impersonatingRetailer,
    loading: impersonationLoading,
  } = useRetailImpersonationQuery()

  const loading =
    counterLoading || accountPrivacyLoading || impersonationLoading

  const shouldOpenLiteRetailerRequiredInfoModal = shouldRenderLiteRetailerRequiredInfoModal(
    {
      counter,
      isHidden,
      isImpersonatingBrand: impersonatingRetailer,
    },
  )

  return {
    shouldOpenLiteRetailerRequiredInfoModal,
    loading,
  }
}
