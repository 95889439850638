import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'
import Tooltip from 'components/Core/Tooltip/Tooltip'

import styles from './Swatch.less'

const Swatch = ({
  swatch,
  className,
  width,
  height,
  color,
  small,
  tooltipPosition,
  tooltipClassName,
  onClick,
  crossed,
  selected,
}) => {
  const { url, colorNumber } = swatch || {}
  let colorSwatch

  const getCrossedOverlay = () =>
    crossed && (
      <svg className={styles.crossed}>
        <line x1={0} y1="100%" x2="100%" y2={0} className={styles.line} />
      </svg>
    )

  if (url) {
    colorSwatch = (
      <div
        className={classNames(className, styles.swatch, {
          [styles.selected]: selected,
        })}
        style={{ height, width }}
      >
        <img
          src={url}
          alt="swatch"
          width={width}
          height={height}
          onClick={() => onClick(color)}
        />
        {getCrossedOverlay()}
      </div>
    )
  } else if (colorNumber) {
    colorSwatch = (
      <div
        className={classNames(className, styles.swatch, {
          [styles.selected]: selected,
        })}
        style={{
          display: 'inline-block',
          width,
          height,
          backgroundColor: colorNumber.includes('#')
            ? colorNumber
            : `#${colorNumber}`,
        }}
        onClick={() => onClick(color)}
      >
        {getCrossedOverlay()}
      </div>
    )
  } else {
    const iconName = small ? 'noSwatchSmall' : 'noSwatch'
    colorSwatch = (
      <div
        className={classNames(className, styles.noSwatch, {
          [styles.swatch]: crossed,
          [styles.empty]: crossed,
          [styles.selected]: selected,
        })}
        onClick={() => onClick(color)}
      >
        <Icon name={iconName} style={{ width, height }} />
        {getCrossedOverlay()}
      </div>
    )
  }

  const colorName = get(color, 'colorName')

  if (colorName) {
    return (
      <Tooltip
        className={tooltipClassName}
        trigger={colorSwatch}
        content={colorName}
        position={tooltipPosition}
      />
    )
  }
  return colorSwatch
}

Swatch.propTypes = {
  swatch: PropTypes.shape({
    colorNumber: PropTypes.string,
    url: PropTypes.string,
  }),
  className: PropTypes.string,
  color: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  small: PropTypes.bool,
  tooltipPosition: PropTypes.string,
  tooltipClassName: PropTypes.string,
  onClick: PropTypes.func,
  crossed: PropTypes.bool,
  selected: PropTypes.bool,
}

Swatch.defaultProps = {
  className: '',
  width: 37,
  height: 37,
  swatch: {},
  color: {},
  small: false,
  tooltipPosition: 'right center',
  tooltipClassName: '',
  onClick: () => {},
  crossed: false,
  selected: false,
}

export default Swatch
