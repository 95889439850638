import classNames from 'classnames'
import PropTypes from 'prop-types'

import { translateText } from 'utils/sdks/localize'

import Dropdown from 'components/Core/Dropdown'
import { NO_RESULTS_MESSAGE } from 'components/Core/Dropdown/Dropdown'
import Search from 'components/Core/Search/Search'

import styles from './ElementSelector.less'

const ElementSelector = ({
  displayFilters,
  displaySearchFilter,
  displayDropdownFilter,
  handleSearch,
  handleDropdownChange,
  dropdownOptions,
  selectedDropdownOptionId,
  children,
  title,
}) => (
  <div className={styles.elementSelectorContainer}>
    <div>
      <span className={styles.label}>{title}</span>
    </div>
    <div className={styles.elementSelector}>
      {displayFilters && (
        <div className="tw-flex tw-flex-row">
          {displaySearchFilter && (
            <Search
              className={classNames(styles.search, {
                [styles.withoutDropdown]: !displayDropdownFilter,
              })}
              handleAction={handleSearch}
              inputOnly
            />
          )}
          {displayDropdownFilter && (
            <Dropdown
              onChange={handleDropdownChange}
              options={dropdownOptions}
              noResultsMessage={translateText(NO_RESULTS_MESSAGE)}
              search
              value={selectedDropdownOptionId}
              parentClassName={styles.dropdown}
            />
          )}
        </div>
      )}
      <div
        className={classNames(styles.childrenContainer, {
          [styles.withoutFilters]: !displayFilters,
        })}
      >
        {children}
      </div>
    </div>
  </div>
)

ElementSelector.propTypes = {
  displayFilters: PropTypes.bool,
  displaySearchFilter: PropTypes.bool,
  displayDropdownFilter: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleDropdownChange: PropTypes.func,
  dropdownOptions: PropTypes.array,
  selectedDropdownOptionId: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
}

ElementSelector.defaultProps = {
  displayFilters: true,
  displaySearchFilter: false,
  displayDropdownFilter: true,
  handleSearch: () => {},
  handleDropdownChange: () => {},
  dropdownOptions: [],
  selectedDropdownOptionId: '',
  children: null,
  title: '',
}

export default ElementSelector
