import { useEffect } from 'react'

import { NetworkStatus, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import {
  fromGlobalId,
  relayConnectionToArray,
  toGlobalId,
} from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas, graphene } from 'graphql/tags'
import { useRenderToasters } from 'hooks/useRenderToasters'
import { isLiteBrand } from 'store/currentUser/selectors'
import {
  applySelectedFilters,
  setBrand,
  setOrderPriceType,
  setRetailer,
  toggleDeselectAllFilters,
} from 'store/shop/actions'
import { INIT_PAGES, INIT_PANEL } from 'store/storefront/constants'
import {
  getBrandId,
  getPages,
  getStorefrontShouldUpdate,
} from 'store/storefront/selectors'

import {
  BRAND_PROFILE,
  PUBLIC_BRAND_PROFILE,
  PUBLIC_STOREFRONT as PUBLIC_STOREFRONT_PATH,
  STOREFRONT as STOREFRONT_PATH,
} from 'routes/paths'

import { formatStorefront } from './Storefront.transformations'
import {
  generateShowroomTemplate,
  generateStorefrontTemplate,
} from './defaultStorefront'
import { BRAND_PROFILE as BRAND_PROFILE_QUERY } from 'features/Storefront/StorefrontWidgets.queries.jsx'
import { UPDATE_STOREFRONT } from 'features/Storefront/constants'

export const SENT_CONNECTION_STATUS = 'SENT'
export const PENDING = 'PENDING'

export const storefrontFragment = atlas`#graphql
  fragment storefrontFragment on Storefront {
    id
    brandId
    isOnline
    modified
    isHome
    orderNbr
    displayName
    uniqueName
    statusSince
    rows {
      edges {
        node {
          id
          title
          template
          orderNbr
          elements {
            id
            orderNbr
            elementType
            presentation
            ... on StorefrontTextElement {
              content
            }
            ... on StorefrontCollectionElement {
              collectionId
              collectionTitle
              coverUrl
              coverId
            }
            ... on StorefrontVideoElement {
              videoId
              videoUrl
            }
            ... on StorefrontProductElement {
              collectionId
              productId
            }
            ... on StorefrontImageElement {
              imageId
              imageUrl
              imageExternalLink
            }
            ... on StorefrontAboutElement {
              settings
            }
            ... on StorefrontShowroomCollectionElement {
              showroomCollectionId
              showroomCollectionTitle
              coverId
              coverUrl
            }
            ... on StorefrontLookbookElement {
              lookbookId
              lookbookTitle
              lookbookUrl
              coverId
              coverUrl
            }
            ... on StorefrontOrb360Element {
              externalVideoId
              externalVideoLink
            }
            ... on StorefrontShowroomBrandElement {
              showroomBrandId
            }
            ... on StorefrontInstagramElement {
              imageUrls
              instagramAuthId
            }
          }
        }
      }
    }
    textStyles {
      id
      name
      typeface
      weight
      size
      orderNbr
    }
  }
`

export const STOREFRONT = atlas`#graphql
  query storefronts($brandId: ID!, $filters: StorefrontFilters) {
    storefronts(brandIds: [$brandId], filters: $filters) {
      ...storefrontFragment
    }
  }
  ${storefrontFragment}
`

export const PUBLIC_STOREFRONT = atlas`#graphql
  query PublicStorefront($brandId: ID!, $filters: StorefrontFilters) {
    public {
      storefronts(brandIds: [$brandId], filters: $filters) {
        ...storefrontFragment
      }
    }
  }
  ${storefrontFragment}
`

export const useStorefront = (
  isLoggedIn,
  brandId,
  isBrandAccount,
  accountId,
) => {
  const { loading: showroomLoading, isShowroomUser } = useShowroomUser(
    isLoggedIn,
    isBrandAccount,
  )

  const dispatch = useDispatch()
  const isBrandAndLiteUser = useSelector(isLiteBrand)
  const storefrontBrandId = useSelector(getBrandId)
  const pages = useSelector(getPages)
  //brandId from path param
  const formattedId = toGlobalId('Brand', brandId)
  //account id logged
  const currentAccountId = accountId ? toGlobalId('Brand', accountId) : ''
  const storefrontQuery = isLoggedIn ? STOREFRONT : PUBLIC_STOREFRONT
  const params = useParams()
  const { renderErrorToast } = useRenderToasters()

  let filters = params?.pageName
    ? { uniqueName: params?.pageName }
    : { isHome: true }

  const shouldUpdate = useSelector(getStorefrontShouldUpdate)

  const { loading: loadingStorefrontNavigation } = useStorefrontNavigation(
    isLoggedIn,
    isBrandAccount,
    brandId,
  )
  const { loading, data, errors, networkStatus, refetch } = useQuery(
    storefrontQuery,
    {
      fetchPolicy: 'cache-and-network',
      client: atlasClient,
      skip: !formattedId,
      notifyOnNetworkStatusChange: true,
      variables: {
        brandId: formattedId,
        filters,
      },
    },
  )

  const existsHome = async () => {
    let thereIsHome = false
    try {
      const { data, errors } = await refetch({ filters: { isHome: true } })
      if (data && !errors) {
        const storefronts = data?.storefronts || data?.public?.storefronts || []
        const storefront = formatStorefront(storefronts[0] || {}, formattedId)
        if (storefront.id) {
          thereIsHome = true
        }
      }
      return thereIsHome
    } catch {
      return thereIsHome
    }
  }
  const history = useHistory()
  const storefronts = data?.storefronts || data?.public?.storefronts || []

  useEffect(() => {
    if (shouldUpdate === UPDATE_STOREFRONT) {
      refetch()
      networkStatus === NetworkStatus.ready &&
        dispatch({ type: INIT_PANEL, value: { shouldUpdate: '' } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkStatus, shouldUpdate])

  useEffect(() => {
    if (!loading && data) {
      const storefront = formatStorefront(storefronts[0] || {}, formattedId)
      //Default initial page when trying to access home url. There are still no home page but there is a default page.
      const defaultInitialPage =
        !storefront.rows.length &&
        !storefront.isHome &&
        pages.length > 0 &&
        !params.pageName

      const liteBrandOwner =
        isBrandAndLiteUser && formattedId === currentAccountId

      if (defaultInitialPage) {
        const pageNameRedirect =
          pages.find((page) => page.orderNbr === 1) ||
          pages.find((page) => page.orderNbr === -1)
        const route = isLoggedIn
          ? generatePath(STOREFRONT_PATH, {
              accountId: brandId,
              pageName: pageNameRedirect.uniqueName,
            })
          : generatePath(PUBLIC_STOREFRONT_PATH, {
              accountId: brandId,
              pageName: pageNameRedirect.uniqueName,
            })
        return history.push(route)
      }
      //brand lite, no access to custom profile and redirect to old brand profile
      if (liteBrandOwner) {
        const route = isLoggedIn
          ? generatePath(BRAND_PROFILE, { accountId: brandId })
          : generatePath(PUBLIC_BRAND_PROFILE, { accountId: brandId })
        history.push(route)
      }
      const currentBrandAccount = isBrandAccount
        ? fromGlobalId(currentAccountId)?.id
        : brandId
      if (storefront.id) {
        const accountNotOwnerAndPageOffline =
          formattedId !== currentAccountId && !storefront.isOnline
        //account not owner and page not online, redirect to home
        if (accountNotOwnerAndPageOffline) {
          const route = isLoggedIn
            ? generatePath(STOREFRONT_PATH, { accountId: currentBrandAccount })
            : generatePath(PUBLIC_STOREFRONT_PATH, {
                accountId: currentBrandAccount,
              })
          history.push(route)
        }
        if (!storefront?.rows.length) {
          // eslint-disable-next-line unused-imports/no-unused-vars
          const { rows, ...restStorefront } = storefront
          dispatch({
            type: INIT_PANEL,
            value: isShowroomUser
              ? { ...generateShowroomTemplate(), ...restStorefront }
              : { ...generateStorefrontTemplate(), ...restStorefront },
          })
        } else {
          dispatch({ type: INIT_PANEL, value: storefront })
        }

        // Needed to clear filters when back from catalog
        dispatch(setRetailer({}))
        dispatch(setBrand({}))
        dispatch(setOrderPriceType({}))
        dispatch(toggleDeselectAllFilters())
        dispatch(applySelectedFilters())
      }
      if (!storefront.id) {
        //account is owner, trying to access home url
        const accountOwnerHomeUrl =
          (formattedId === storefrontBrandId ||
            formattedId === currentAccountId) &&
          !params.pageName
        if (accountOwnerHomeUrl) {
          dispatch({
            type: INIT_PANEL,
            value: isShowroomUser
              ? generateShowroomTemplate()
              : generateStorefrontTemplate(),
          })
        } else {
          //Trying to access page not home and exists home
          const notHomeUrlAndExistsHome = params?.pageName && existsHome()
          // Also when the brand is not lite should be redirect to own storefront
          if (
            notHomeUrlAndExistsHome ||
            (isBrandAccount && !isBrandAndLiteUser)
          ) {
            const route = isLoggedIn
              ? generatePath(STOREFRONT_PATH, {
                  accountId: currentBrandAccount,
                })
              : generatePath(PUBLIC_STOREFRONT_PATH, {
                  accountId: currentBrandAccount,
                })
            history.push(route)
          } else {
            // In case it is a retailer and no exist a home redirect to V1 profile
            const route = isLoggedIn
              ? generatePath(BRAND_PROFILE, { accountId: currentBrandAccount })
              : generatePath(PUBLIC_BRAND_PROFILE, {
                  accountId: currentBrandAccount,
                })
            history.push(route)
          }
        }
      }
    } else if (data?.errors?.length || errors?.length) {
      renderErrorToast({
        description: 'There was a problem loading your profile.',
      })
      dispatch({
        type: INIT_PANEL,
        value: isShowroomUser
          ? generateShowroomTemplate()
          : generateStorefrontTemplate(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data])

  return {
    loading: loading || showroomLoading || loadingStorefrontNavigation,
    isShowroomUser,
  }
}

const CONNECTION_REQUEST_STATUS = atlas`#graphql
  query AccountConnectionStatuses($accountId: ID!, $otherAccountIds: [ID!]!) {
    accountConnectionStatuses(
      accountId: $accountId
      otherAccountIds: $otherAccountIds
    ) {
      accountId
      otherAccountId
      status
    }
  }
`

const useConnectionRequestSent = (brandId, retailerId) => {
  const { loading, data } = useQuery(CONNECTION_REQUEST_STATUS, {
    client: atlasClient,
    variables: {
      accountId: retailerId ? toGlobalId('Account', retailerId) : '',
      otherAccountIds: [brandId ? toGlobalId('Account', brandId) : ''],
    },
    skip: !brandId || !retailerId,
  })

  const isConnectionRequestSent =
    data?.accountConnectionStatuses?.[0]?.status === SENT_CONNECTION_STATUS
  const isPendingAcceptance =
    data?.accountConnectionStatuses?.[0]?.status === PENDING
  return {
    loading,
    isConnectionRequestSent,
    isPendingAcceptance,
  }
}

export const CONNECTED_ACCOUNTS = graphene`#graphql
  query ConnectedAccounts {
    connectedAccounts {
      edges {
        node {
          ... on RetailerAccount {
            id
          }
          ... on BrandAccount {
            id
            name
          }
        }
      }
    }
  }
`

export const useConnectedAccount = ({
  isBrandAccount,
  isLoggedIn,
  brandId,
  retailerId,
}) => {
  const { data } = useQuery(CONNECTED_ACCOUNTS, {
    variables: { isBrandAccount },
    skip: !isLoggedIn,
  })
  const {
    isConnectionRequestSent,
    isPendingAcceptance,
  } = useConnectionRequestSent(brandId, retailerId)
  const connectedAccounts = data?.connectedAccounts || {}
  const formattedAccounts = relayConnectionToArray(connectedAccounts)
  const isAccountConnected = Boolean(
    formattedAccounts.find((account) => {
      const rawAccountId = fromGlobalId(account?.id)?.id
      return rawAccountId === retailerId || rawAccountId === brandId
    }),
  )
  return {
    isAccountConnected,
    isConnectionRequestSent,
    isPendingAcceptance,
  }
}

export const PUBLIC_BRAND_INFO_QUERY = atlas`#graphql
  query PublicBrandProfile($brandIds: [ID!]!) {
    public {
      brandProfiles(brandIds: $brandIds) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`

export const usePublicProfile = ({ brandId, isLoggedIn }) => {
  const query = isLoggedIn ? BRAND_PROFILE_QUERY : PUBLIC_BRAND_INFO_QUERY

  const { loading, data } = useQuery(query, {
    variables: { brandIds: [toGlobalId('Brand', brandId)] },
    skip: !brandId,
    client: atlasClient,
  })
  const brand =
    data?.public?.brandProfiles?.edges?.[0]?.node ||
    data?.brandProfiles?.edges[0]?.node ||
    {}
  return { brand: brand, loading }
}

export const SHOWROOM_USER = graphene`#graphql
  query showroomUser {
    viewerBrand {
      id
      subtype
    }
  }
`
export const useShowroomUser = (isLoggedIn, isBrandAccount) => {
  const { loading, data } = useQuery(SHOWROOM_USER, {
    skip: !isLoggedIn || !isBrandAccount,
  })

  const isShowroomUser = data?.viewerBrand?.subtype === 'SHOWROOM'

  return {
    loading,
    isShowroomUser,
  }
}

export const STOREFRONT_BRAND_NAVIGATION = atlas`#graphql
  query storefrontNavigationAsBrand(
    $brandId: ID!
    $filters: StorefrontFilters
  ) {
    storefronts(brandIds: [$brandId], filters: $filters) {
      id
      uniqueName
      displayName
      orderNbr
      isOnline
      isHome
    }
  }
`
export const STOREFRONT_PUBLIC_NAVIGATION = atlas`#graphql
  query publicStorefrontNavigationAsBrand(
    $brandId: ID!
    $filters: StorefrontFilters
  ) {
    public {
      storefronts(brandIds: [$brandId], filters: $filters) {
        id
        uniqueName
        displayName
        orderNbr
        isOnline
        isHome
      }
    }
  }
`
export const useStorefrontNavigation = (
  isLoggedIn,
  isBrandAccount,
  brandId,
) => {
  const dispatch = useDispatch()
  const formattedId = toGlobalId('Brand', brandId)

  const storefrontNavigationQuery =
    isLoggedIn && isBrandAccount
      ? STOREFRONT_BRAND_NAVIGATION
      : STOREFRONT_PUBLIC_NAVIGATION
  const filters = isLoggedIn && isBrandAccount ? {} : { isOnline: true }
  const { loading, data, refetch } = useQuery(storefrontNavigationQuery, {
    client: atlasClient,
    skip: !formattedId,
    variables: {
      brandId: formattedId,
      filters,
    },
  })
  const pages = data?.storefronts || data?.public?.storefronts
  useEffect(() => {
    if (!loading && pages) {
      dispatch({ type: INIT_PAGES, value: pages })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data])
  return {
    loading,
    pages,
    refetch,
  }
}
