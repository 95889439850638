import Filter from './baseFilter'

/*
 * BooleanFilter
 * Filter that holds a boolean value
 * {
 *  [filterKey]: true / false
 * }
 *
 * It is not meant to show pills
 *
 * Properties:
 * - getState: returns the value of the filter given the selectedFilters
 * - getModifierProps: returns the props needed by the modifier depending on type
 *     - checkbox: needed by the checkbox prop to Accordion
 */
export default class BooleanFilter extends Filter {
  constructor({ defaultState, styles, ...rest }) {
    super(rest)
    this.unselectedState = false
    if (defaultState) {
      this.defaultState = defaultState
    }
    this.styles = styles
  }

  getModifierProps = ({ actionHandler, selectedFilters }) => {
    if (this.modifierType === 'checkbox') {
      return {
        onClick: actionHandler,
        checked: this.getState(selectedFilters),
        label: this.label,
      }
    }
    return {}
  }

  getState = (selectedFilters) => {
    const currentValue =
      selectedFilters[this.filterKey] &&
      selectedFilters[this.filterKey][this.filterSubgroupKey]
        ? selectedFilters[this.filterKey][this.filterSubgroupKey]
        : false
    return currentValue
  }

  renderFilterPanelHandler = () => null

  toGraphQLVariable = (graphQLFilters, { value }) => {
    const booleanValue = (value && value[this.filterSubgroupKey]) || false
    return {
      ...graphQLFilters,
      [this.filterKey]: {
        ...graphQLFilters[this.filterKey],
        [this.filterSubgroupKey]: booleanValue,
      },
    }
  }

  reducerHandler = (selectedFilters) => {
    const currentValue =
      selectedFilters[this.filterKey] &&
      selectedFilters[this.filterKey][this.filterSubgroupKey]
        ? selectedFilters[this.filterKey][this.filterSubgroupKey]
        : false
    return {
      ...selectedFilters,
      [this.filterKey]: {
        ...selectedFilters[this.filterKey],
        [this.filterSubgroupKey]: !currentValue,
      },
    }
  }

  addToActiveFilters = (currentState) =>
    // do not render pills
    currentState
}
