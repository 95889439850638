import { useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { formatDropdownOptions } from 'utils/formatters'
import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'

import { doorIdsQuery } from './RetailerStartOrderModal.queries'

const withFormatter = (WrappedComponent) => {
  const Wrapper = ({ ...rest }) => {
    const [
      getDoorIds,
      { data: doorIdData, loading: doorIdsLoading },
    ] = useLazyQuery(doorIdsQuery, {
      client: atlasClient,
    })

    return (
      <WrappedComponent
        {...rest}
        getDoorIds={getDoorIds}
        doors={formatDropdownOptions(
          relayConnectionToArray(doorIdData?.brandDoors),
        )}
        doorIdsLoading={doorIdsLoading}
      />
    )
  }

  Wrapper.propTypes = {
    getDoorIds: PropTypes.func,
    doorData: PropTypes.object,
    doorIdsLoading: PropTypes.bool,
  }

  Wrapper.defaultProps = {
    getDoorIds: () => () => Promise.resolve({}),
    doorData: {},
    doorIdsLoading: false,
  }

  return Wrapper
}

export default withFormatter
