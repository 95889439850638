import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Divider, Dropdown, Tooltip } from 'components/Core'
import Button from 'components/Core/ButtonV2/Button'
import { WHITE } from 'components/Core/Tooltip/Tooltip'

import withAnalytics from '../RowSettings.analytics'
import styles from './WidgetTextTooltip.module.scss'
import {
  DROPDOWN_OPTIONS,
  LEFT,
  MEDIUM,
  POSITION_OPTIONS,
  SIZE_OPTIONS,
} from './WidgetTextVariants'
import RadioSelector from 'features/Storefront/DragDropTemplate/Row/RadioSelector/RadioSelector'
import {
  ASPECT_LANDSCAPE,
  ASPECT_PORTRAIT,
} from 'features/Storefront/constants'

const getSizeOptions = (aspect) => {
  switch (aspect) {
    case ASPECT_PORTRAIT:
      return [SIZE_OPTIONS[0], { ...SIZE_OPTIONS[1], disabled: true }]
    case ASPECT_LANDSCAPE:
      return [{ ...SIZE_OPTIONS[0], disabled: true }, SIZE_OPTIONS[1]]
    default:
      return SIZE_OPTIONS
  }
}
const WidgetTextTooltip = ({
  isOpen,
  rowItem,
  widgetName,
  positionTitle,
  aspectTitle,
  sizeTitle,
  onApplyChanges,
  top,
  trackRowSettings,
}) => {
  const imageItemSettings = rowItem.elements.filter(
    (number) => number.elementType == 'IMAGE',
  )
  const aspectItem =
    rowItem?.settings?.aspect || imageItemSettings[0]?.content?.aspect
  const sizeItem =
    rowItem?.settings?.size || imageItemSettings[0]?.content?.size
  const positionItem =
    rowItem?.settings?.position || imageItemSettings[0]?.content?.position

  const defaultPosition = positionItem || LEFT
  const defaultAspect = aspectItem || ASPECT_LANDSCAPE
  const defaultSize = sizeItem || MEDIUM

  const [position, setPosition] = useState(defaultPosition)
  const [aspect, setAspect] = useState(defaultAspect)
  const [size, setSize] = useState(defaultSize)

  useEffect(() => setPosition(defaultPosition), [defaultPosition])
  useEffect(() => setAspect(defaultAspect), [defaultAspect])
  useEffect(() => setSize(defaultSize), [defaultSize])

  const content = (
    <>
      <h3>{widgetName}</h3>
      {positionTitle && (
        <RadioSelector
          onClick={(_, { value }) => setPosition(value)}
          radioOptions={POSITION_OPTIONS}
          radioTitle={positionTitle}
          selectedRadio={position}
          className={styles.subtitle}
        />
      )}
      {aspectTitle && (
        <>
          <h4 className={styles.subtitle}>{aspectTitle}</h4>
          <Dropdown
            onChange={(_, { value }) => {
              if (value === ASPECT_PORTRAIT && sizeTitle) {
                setSize(SIZE_OPTIONS[0]?.value)
              }
              if (value === ASPECT_LANDSCAPE && sizeTitle) {
                setSize(SIZE_OPTIONS[1]?.value)
              }
              setAspect(value)
            }}
            options={DROPDOWN_OPTIONS}
            value={aspect}
            className={styles.dropdown}
          />
        </>
      )}
      {sizeTitle && (
        <RadioSelector
          onClick={(_, { value }) => setSize(value)}
          radioOptions={getSizeOptions(aspect)}
          radioTitle={sizeTitle}
          selectedRadio={size}
          className={styles.subtitle}
        />
      )}
      <Divider />
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.smallButton}
          onClick={() => onApplyChanges(rowItem)}
        >
          Cancel
        </Button>
        <Button
          className={styles.mediumButton}
          onClick={() => {
            const trackerTitle = rowItem?.tooltip?.settings?.trackerTitle
            const trackerSettings = {
              Position: position,
              'Aspect Ratio': aspect,
              Size: size,
            }
            if (trackerTitle) trackRowSettings(trackerTitle, trackerSettings)
            onApplyChanges({
              ...rowItem,
              settings: {
                position,
                aspect,
                size,
              },
            })
          }}
        >
          Apply
        </Button>
      </div>
    </>
  )
  return (
    <Tooltip
      className={styles.WidgetTextTooltip}
      content={content}
      aspect={rowItem?.settings?.aspect}
      size={rowItem?.settings?.size}
      position={rowItem?.settings?.position}
      open={isOpen}
      style={{
        top: `${top + 100}px`,
        right: '10px',
      }}
      color={WHITE}
    />
  )
}

WidgetTextTooltip.propTypes = {
  rowItem: PropTypes.object.isRequired,
  widgetName: PropTypes.string.isRequired,
  top: PropTypes.number.isRequired,
  positionTitle: PropTypes.string,
  aspectTitle: PropTypes.string,
  sizeTitle: PropTypes.string,
  aspect: PropTypes.string,
  size: PropTypes.string,
  position: PropTypes.string,
}

WidgetTextTooltip.defaultProps = {
  positionTitle: '',
  aspectTitle: '',
  sizeTitle: '',
  aspect: '',
  size: '',
  position: '',
}

export default withAnalytics(WidgetTextTooltip)
