import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Grid.less'
import { sixteenColumnPropType, twelveColumnPropType } from './Grid.props'

const Grid = ({ children, className, container, id = null }) => {
  const gridClass = classNames(styles.Grid, className, {
    [styles.container]: container,
  })
  return (
    <div id={id} className={gridClass}>
      {children}
    </div>
  )
}

const Row = ({ children, className, alignColumns, id = null }) => {
  const rowClass = classNames(styles.Row, className, {
    [styles[alignColumns]]: alignColumns,
  })
  return (
    <div id={id} className={rowClass}>
      {children}
    </div>
  )
}

const Column = ({
  children,
  className,
  textAlign,
  sm,
  md,
  lg,
  onClick,
  id = null,
}) => {
  const small =
    sm >= 0 && sm <= 12 ? styles[`column-sm-${sm}`] : styles['column-sm-12']
  const medium =
    md >= 0 && md <= 12 ? styles[`column-md-${md}`] : styles['column-md-12']
  const large =
    lg >= 0 && lg <= 16 ? styles[`column-lg-${lg}`] : styles['column-lg-16']
  const alignment =
    textAlign === 'left' || textAlign === 'right' || textAlign === 'center'
      ? styles[textAlign]
      : styles.left

  const classes = [styles.Column, small, medium, large, alignment]

  return (
    <div
      id={id}
      onClick={onClick}
      className={classNames(...classes, className)}
    >
      {children}
    </div>
  )
}

Grid.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  container: PropTypes.bool,
  id: PropTypes.string,
}

Grid.defaultProps = {
  className: '',
  container: false,
  id: null,
}

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  alignColumns: PropTypes.oneOf(['left', 'center', 'right']),
}

Row.defaultProps = {
  className: '',
  alignColumns: 'center',
}

Column.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,

  // Accepts 'left', 'center' or 'right'
  textAlign: PropTypes.oneOf(['left', 'center', 'right']),

  // Accepts 1-12 column width and corresponds to small breakpoint.
  sm: twelveColumnPropType,

  // Accepts 1-12 column width and corresponds to medium breakpoint.
  md: twelveColumnPropType,

  // Accepts 1-16 column width and corresponds to large breakpoint.
  lg: sixteenColumnPropType,
}

Column.defaultProps = {
  children: null,
  className: '',
  textAlign: 'left',
  sm: 12,
  md: 12,
  lg: 16,
  onClick: () => {},
}

Grid.Row = Row
Grid.Col = Column

export default Grid
