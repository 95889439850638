import get from 'lodash/get'

import {
  AMERICAN_EXPRESS_ICON,
  BANK,
  DINERS_CLUB_ICON,
  DISCOVER_ICON,
  GENERIC_CARD_ICON,
  MASTERCARD_ICON,
  PAYPAL_ICON,
  TREVIPAY_ICON,
  VISA_DEBIT_ICON,
  VISA_ICON,
} from 'components/Core/Icon/Icon'

export const IMAGE_WIDTH = 330
export const IMAGE_HEIGHT = 450
export const BADGE_WIDTH = 50
export const BADGE_HEIGHT = 50
export const BADGE_SUFFIX = `.${BADGE_WIDTH}x${BADGE_HEIGHT}.`
export const IMAGE_CODE = 'IMAGE'
export const BADGE_CODE = 'BADGE'
export const PREVIEW_IMAGE_CODE = 'PREVIEW_IMAGE'
const PREVIEW_IMAGE_WIDTH = 450
const PREVIEW_IMAGE_HEIGHT = 600

export const isVideo = (mediaSource) =>
  mediaSource && mediaSource.includes('mp4')

/**
 * Sizes image to badge size or previewImage size
 * If imageType passed in does not match BADGE_CODE or PREVIEW_IMAGE_CODE,
 * will remove previewImage sizing if already applied else do nothing
 */
export const getSizedImage = (imageUrl, imageType) => {
  const isBadge = imageType === BADGE_CODE
  const isPreviewImage = imageType === PREVIEW_IMAGE_CODE

  if (isBadge && imageUrl) {
    const fileExtension = imageUrl.split('.').pop()
    return imageUrl.replace(`.${fileExtension}`, BADGE_SUFFIX + fileExtension)
  } else if (isPreviewImage && imageUrl) {
    return getPreviewImageSizedUrl(imageUrl)
  } else if (!isBadge && imageUrl) {
    return imageUrl.replace('.450x600.w', '')
  }
  return ''
}

/**
 * Sizes image to size/dimension specified
 * If no dimension specified, defaults to 220x300
 * For further info about supported dimensions, see: https://github.com/joor/aws-lambda/blob/master/src/imgixRedirect/constants.js#L3
 */
export const getSizedImageUrl = (url, dimension = '220x300') => {
  if (!url) {
    return url
  }
  const imageUrl = url
    .replace(/\d+[Xx]\d+\.w./, '')
    .split('.')
    .slice(0, -1)
  const ext = url.split('.').pop()
  return `${imageUrl.join('.')}.${dimension}.w.${ext}`
}

/**
 * Sizes image to preview image size
 */
export const getPreviewImageSizedUrl = (url) =>
  getSizedImageUrl(url, `${PREVIEW_IMAGE_WIDTH}x${PREVIEW_IMAGE_HEIGHT}`)

export const getIconNameForCard = (brandName = '') => {
  const lowerCaseBrand = brandName.toLowerCase()
  return (
    {
      'american express': AMERICAN_EXPRESS_ICON,
      amex: AMERICAN_EXPRESS_ICON,
      'diners club': DINERS_CLUB_ICON,
      diners: DINERS_CLUB_ICON,
      discover: DISCOVER_ICON,
      disc: DISCOVER_ICON,
      mastercard: MASTERCARD_ICON,
      mc: MASTERCARD_ICON,
      paypal: PAYPAL_ICON,
      trevipay: TREVIPAY_ICON,
      visa: VISA_ICON,
      debit: VISA_DEBIT_ICON,
      bank: BANK,
    }[lowerCaseBrand] || GENERIC_CARD_ICON
  )
}

export const getImgixCompressedUrl = ({
  image,
  quality = 35,
  flag = false,
}) => {
  if (!image) return image
  if (isVideo(image)) return image
  const isProd = image.includes('https://cdn.jooraccess.com')
  if (!isProd) return image
  if (!flag) return image

  const imagePath = image.split('https://cdn.jooraccess.com')[1]
  return `https://joor-prod.imgix.net${imagePath}?q=${quality}`
}

// TODO: All of this can be done with prop + classNames
// Not against doing things this way but the reason for the todo
// is this is an inconsistent pattern from the rest of passport.
export const generatePassportImageStyle = (options) => {
  let customImageStyle = {}
  if (get(options, 'fillContainer', false)) {
    customImageStyle = {
      ...customImageStyle,
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      zIndex: -1,
    }
  }
  if (get(options, 'isGrayscale', false)) {
    customImageStyle = {
      ...customImageStyle,
      filter: 'grayscale(100%)',
      opacity: 0.8,
    }
  }
  if (get(options, 'isStyleStory', false)) {
    customImageStyle = {
      ...customImageStyle,
      objectFit: 'cover',
      width: '100%',
      height: '100%',
      position: 'relative',
      zIndex: '-1',
    }
  }
  return customImageStyle
}

export const formatHexColorCode = (hexColorCode) => {
  if (!hexColorCode) return hexColorCode
  return hexColorCode.trim() && hexColorCode.trim()[0] !== '#'
    ? `#${hexColorCode.trim()}`
    : hexColorCode.trim()
}

export const MediaType = {
  UNKNOWN: 'UNKNOWN',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
}
