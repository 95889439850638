import { compact, isEmpty } from 'lodash'

import {
  BasicPayloadType,
  CsvPayloadType,
  OrderExcelPayloadType,
  PdfPayloadType,
} from './requests.types'
import { fromGlobalId, isGlobalId } from './transformations/graphql'
import {
  CsvDataType,
  ExcelDataType,
  ExportType,
  ImageResolution,
  OrderExcelDataType,
  OversoldType,
  PdfDataType,
} from 'features/Linesheets/Modals/ExportModal/exportModal.types'

const parseCustomProps = (data: PdfDataType): string[] => {
  const result = []

  if (data.customField01) result.push(data.customField01)
  if (data.customField02) result.push(data.customField02)
  if (data.customField03) result.push(data.customField03)

  return result
}

export const createCsvExcelPayload = (
  basicPayload: BasicPayloadType,
  data: CsvDataType | ExcelDataType,
): CsvPayloadType => {
  const isNordstromExportSelected = data.exportType === 'alternative'
  return {
    ...basicPayload,
    is_nordstrom_export: (
      data.exportType === ExportType.Alternative
    ).toString(),
    type: data.styleDataLevel,
    ...(!isNordstromExportSelected && { export_type: data.exportType }),
    ...(isNordstromExportSelected
      ? {
          price_type_id: data.nordstromPriceTypeIds?.map(
            (priceTypeId) => fromGlobalId(priceTypeId).id ?? '',
          ),
        }
      : { price_type_id: fromGlobalId(data.priceTypeId).id ?? '' }),
  }
}

export const createPdfPayload = (
  basicPayload: BasicPayloadType,
  data: PdfDataType,
): PdfPayloadType => ({
  ...basicPayload,
  style: data.style,
  price_type_id: fromGlobalId(data.priceTypeId).id ?? '',
  print_retail_price: Number(data.printRetailPrice),
  print_wholesale_price: Number(data.printWholesalePrice),
  CustomField: parseCustomProps(data).map((customFieldId: string): string =>
    isGlobalId(customFieldId)
      ? fromGlobalId(customFieldId).id || customFieldId
      : customFieldId,
  ),
  group_by: data.groupBy,
  page_break_by_group: Number(data.pageBreakByGroup),
  overflow_colors: Number(data.overflowColors),
  show_tag_names: Number(data.showTagNames),
  use_hi_res_images: Number(data.imageResolution === ImageResolution.High),
  oversold_type: Number(data.oversoldType === OversoldType.SoldOut),
  warehouse_ids: compact(data.warehouse_ids)
    .map((item) => fromGlobalId(item).id)
    .join(','),
  dates: isEmpty(data.dates) ? 'Immediate' : data.dates.join(','),
  merge_linesheets: Number(data.mergeLinesheets),
  Badges: compact(data.Badges.map((badge) => fromGlobalId(badge).id || '')),
  smaller_swatches: Number(data.smallerSwatches),
  group_future_inventory_dates: Number(data.groupFutureInventoryDates),
  hide_sold_out: Number(data.hideSoldOut || false),
})

export const createURLForPDF = (data: PdfPayloadType): string =>
  `${
    data.Collection && data.Collection.length > 1
      ? ''
      : `${data.Collection?.[0].id}/`
  }${data.style}/0/${data.print_retail_price}/${data.print_wholesale_price}/${
    data.price_type_id
  }/${data.oversold_type}
    /0/${data.overflow_colors}/${data.hide_sold_out}/1?downloadToken=${
    data.downloadToken
  }`

export const createOrderExcelPayload = (
  basicPayload: BasicPayloadType,
  data: OrderExcelDataType,
): OrderExcelPayloadType => ({
  ...basicPayload,
  designer_id: fromGlobalId(data.designerId).id || '',
  door_ids: compact(data?.doorIds?.map((door) => fromGlobalId(door).id)),
  include_images: Number(data.includeImages),
  // @ts-expect-error
  linesheet_ids: compact(
    data?.linesheetIds?.map((linesheetId) => fromGlobalId(linesheetId).id),
  ),
  price_type_id: fromGlobalId(data.priceTypeId).id || '',
})
