import compose from 'lodash/flowRight'
import { connect } from 'react-redux'

import {
  getUserId,
  isAccountTypeBrand,
  isAccountTypeRetailer,
  isLiteRetailer,
} from 'store/currentUser/selectors'
import { getCollectionSearchText as getBrandCollectionSearchText } from 'store/orderList/brandStartOrderModal/selectors'
import {
  getBrandId,
  getCollectionSearchText as getRetailerCollectionSearchText,
} from 'store/orderList/retailerStartOrderModal/selectors'
import { setOrderGroupId, setPriceTypes } from 'store/shop/actions'

import StartOrderModalWrapper from './StartOrderModalWrapper.component'
import { withCollections } from './StartOrderModalWrapper.queries'

const mapStateToProps = (state) => ({
  isAccountTypeRetailer: isAccountTypeRetailer(state),
  isAccountTypeBrand: isAccountTypeBrand(state),
  isLiteRetailer: isLiteRetailer(state),
  brandId: getBrandId(state),
  collectionSearchText: isAccountTypeRetailer(state)
    ? getRetailerCollectionSearchText(state)
    : getBrandCollectionSearchText(state),
  userId: getUserId(state),
})
const mapDispatchToProps = {
  setPriceTypes: (data) => setPriceTypes(data),
  setOrderGroupId: (data) => setOrderGroupId(data),
}

const withRedux = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRedux, withCollections)(StartOrderModalWrapper)
