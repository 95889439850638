import {
  get,
  groupBy,
  last,
  map,
  mergeWith,
  orderBy,
  reduce,
  size,
  slice,
} from 'lodash'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { EMPTY_STYLE_TAG_ID } from 'shop/products/traits'
import { fromCasepackId } from 'shop/products/utils'

import { getAvailableQuantity } from './getters'

export const transformVariants = (variants) =>
  get(variants, 'edges', []).map((variantEdge) => ({
    ...variantEdge.node,
    values: relayConnectionToArray(variantEdge.values),
  }))

export const transformTraitValues = (traitValues) =>
  get(traitValues, 'edges', []).map((traitValueEdge) => ({
    ...traitValueEdge.node,
  }))

export const transformSkus = (skus) =>
  relayConnectionToArray(skus).map((sku) => ({
    ...sku,
    prices: sku.prices || [],
    collections: relayConnectionToArray(sku.collections),
    variantValues: relayConnectionToArray(sku.variantValues),
  }))

export const transformCasepacks = (casepacks) =>
  relayConnectionToArray(casepacks).map((casepack) => ({
    ...casepack,
    value: fromCasepackId(casepack.id).sizeVariantId,
    skus: get(casepack, 'skus.edges', []).map((skuEdge) => ({
      ...skuEdge.node,
      variantValues: relayConnectionToArray(
        get(skuEdge, 'node.variantValues', []),
      ),
      quantity: skuEdge.quantity,
      prices: skuEdge.node?.prices || [],
    })),
  }))

export const transformProduct = (product, orderNbr) => ({
  ...product,
  orderNbr,
  categories: relayConnectionToArray(product.categories),
  tagValues: relayConnectionToArray(product.tagValues),
  traitValues: relayConnectionToArray(product.traitValues),
  variants: transformVariants(product.variants),
  skus: transformSkus(product.skus),
  badges: relayConnectionToArray(product.badges),
  casepacks: transformCasepacks(product.casepacks),
  isCollectionMadeToOrder: product.collection?.madeToOrder,
})

export const transformProducts = (products) =>
  relayConnectionToArray(products).map((product) => transformProduct(product))

export const transformBackupProduct = (product) => ({
  ...product,
  variants: transformVariants(product.backupVariants),
  skus: transformSkus(product.skus),
  casepacks: transformCasepacks(product.casepacks),
})

export const transformBackupProducts = (backupProducts) =>
  relayConnectionToArray(backupProducts).map((backupProduct) =>
    transformBackupProduct(backupProduct),
  )

export const transformCollectionProducts = (
  collectionProducts,
  transformationFunction = transformProduct,
) =>
  map(
    collectionProducts,
    ({ product, variants, skus, collection, casepacks, comments }) =>
      transformationFunction(
        {
          ...product,
          ...(comments && { comments }),
          ...(variants && { variants }),
          ...(skus && { skus }),
          ...(casepacks && { casepacks }),
        },
        collection,
      ),
  )

export const groupProductsByGroupedTag = (
  productsWithCollection,
  transformationFunction = transformProduct,
) => {
  const productsGroupByGrouping = groupBy(
    productsWithCollection,
    'groupedBy.id',
  )

  return reduce(
    productsGroupByGrouping,
    (acc, collectionProducts, groupedId) => ({
      ...acc,
      [groupedId === 'undefined'
        ? EMPTY_STYLE_TAG_ID
        : groupedId]: transformCollectionProducts(
        collectionProducts,
        transformationFunction,
      ),
    }),
    {},
  )
}

export const groupProductsByCollection = (
  productsWithCollection,
  transformationFunction = transformProduct,
  subGrouping = false,
) => {
  const productsGroupByCollection = groupBy(
    productsWithCollection,
    'collection.id',
  )
  return reduce(
    productsGroupByCollection,
    (acc, collectionProducts, collectionId) => ({
      ...acc,
      [collectionId]: subGrouping
        ? groupProductsByGroupedTag(collectionProducts, transformationFunction)
        : transformCollectionProducts(
            collectionProducts,
            transformationFunction,
          ),
    }),
    {},
  )
}

const transformInventoryItems = (items, orderStatusId) =>
  reduce(
    items,
    (itemsMap, item) => {
      const quantiy = getAvailableQuantity(item, orderStatusId)
      itemsMap.set(item.skuId, quantiy)
      return itemsMap
    },
    new Map(),
  )
export const transformSkuInventoryItems = (skuInventoryItems) =>
  transformInventoryItems(get(skuInventoryItems, '[0].items', []))

export const orderAvailabilityGroupsByAvailability = (availabilityGroups) => {
  const availabilityGroupsOrderByAvailability = orderBy(
    availabilityGroups,
    'availability.availableOn',
    'asc',
  )
  const lastElement = last(availabilityGroupsOrderByAvailability)
  const inmediateInventory =
    lastElement && lastElement.availability?.availableOn === null
  if (inmediateInventory) {
    const lastPosition = availabilityGroupsOrderByAvailability.length - 1
    return [
      lastElement,
      ...slice(availabilityGroupsOrderByAvailability, 0, lastPosition),
    ]
  }
  return availabilityGroupsOrderByAvailability
}

/* inventoryAvailabilityGroups should be pre order by availability day. 
    if the frontend needs to order them you can use orderAvailabilityGroupsByAvailability */
export const transformInventoryAvailability = (
  inventoryAvailabilityGroups,
  orderStatusId,
) => {
  var quantitiesPerSku = {}
  return reduce(
    inventoryAvailabilityGroups,
    (acc, { id: inventoryId, items }, index) => {
      if (index !== 0) {
        const newInventory = reduce(
          items,
          (itemsMap, item) => {
            const prevQuantity = quantitiesPerSku?.[item.skuId]
            const quantity = getAvailableQuantity(item, orderStatusId)

            quantitiesPerSku[item.skuId] = (prevQuantity || 0) + quantity

            itemsMap.set(
              item.skuId,
              prevQuantity ? quantity + prevQuantity : quantity,
            )

            return itemsMap
          },
          new Map(),
        )
        return {
          ...acc,
          [inventoryId]: newInventory,
        }
      }
      quantitiesPerSku = reduce(
        items,
        (acc, item) => ({
          ...acc,
          [item.skuId]: getAvailableQuantity(item, orderStatusId),
        }),
        quantitiesPerSku,
      )
      return {
        ...acc,
        [inventoryId]: transformInventoryItems(items, orderStatusId),
      }
    },
    {},
  )
}

export const mergeInventories = (oldInventories, newInventories = {}) =>
  oldInventories
    ? mergeWith(
        oldInventories,
        newInventories,
        (oldInventory, newInventory) => {
          const oldInventoryHasValue = Boolean(size(oldInventory))
          const newInventoryHasValue = Boolean(size(newInventory))
          if (newInventoryHasValue && oldInventoryHasValue) {
            return new Map([...oldInventory, ...newInventory])
          } else if (newInventoryHasValue) {
            return newInventory
          }
          return oldInventory
        },
      )
    : newInventories
