/* eslint-disable react/jsx-filename-extension */
// LaunchDarkly now provides native support for React v16.3.0+ leveraging the React Context API.
// Due to this and limitations of the previous third party, this v2 solution should be used going forward.
// SDK documentation: https://docs.launchdarkly.com/docs/react-sdk-reference
import {
  withLDConsumer,
  withLDProvider,
} from '@joor/launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'

import { getLaunchDarklyUserInfo } from 'utils/userInfo'

const REACT_APP_LAUNCH_DARKLY_KEY = process.env.REACT_APP_LAUNCH_DARKLY_KEY

const REACT_APP_LAUNCHDARKLY_RELAY_URL =
  process.env.REACT_APP_LAUNCHDARKLY_RELAY_URL

export const withBootstrapLaunchDarkly = (App) => (props) => {
  const options = {
    baseUrl: REACT_APP_LAUNCHDARKLY_RELAY_URL,
    streamUrl: REACT_APP_LAUNCHDARKLY_RELAY_URL,
  }

  const Wrapper = withLDProvider({
    clientSideID: REACT_APP_LAUNCH_DARKLY_KEY,
    context: {
      kind: 'user',
      ...getLaunchDarklyUserInfo(props.userInfo),
    },
    ...(REACT_APP_LAUNCHDARKLY_RELAY_URL && { options }),
  })(App)

  Wrapper.propTypes = {
    userInfo: PropTypes.shape({
      userId: PropTypes.number,
      accountsUserId: PropTypes.number,
      isInternal: PropTypes.bool.isRequired,
      isAdmin: PropTypes.bool.isRequired,
      loggedAsAdmin: PropTypes.bool.isRequired,
      account: PropTypes.shape({
        accountId: PropTypes.number,
        profileName: PropTypes.string,
        type: PropTypes.number.isRequired,
        limitedAccess: PropTypes.bool.isRequired,
      }).isRequired,
    }).isRequired,
  }

  return <Wrapper {...props} />
}

export const withLaunchDarkly = (Component) => withLDConsumer()(Component)
