import { Component } from 'react'

import classNames from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import times from 'lodash/times'
import PropTypes from 'prop-types'

import { translateText } from 'utils/sdks/localize'

import Icon from 'components/Core/Icon/Icon'

import styles from './Drilldown.less'
import { FlyoutContainerStyle } from './FlyoutContainerStyle'
import { HoverableSelect } from './HoverableSelect'
import { MultiSelect } from './MultiSelect'
import { SingleSelect } from './SingleSelect'
import { buildSelectionsByTier, findDefaultNodes } from './defaultValue'

class Drilldown extends Component {
  state = {
    open: this.props.open,
    selectionsByTier: [],
    finalSelection: '',
    expandedLevels: 0,
  }

  componentDidMount() {
    this.savedSelections = []
    document.body.addEventListener('click', (e) => this.handleOutsideClick(e))
    this.setDefaultValue()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.defaultValue === prevProps.defaultValue &&
      this.props.data === prevProps.data
    ) {
      return
    }

    this.setDefaultValue()
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', (e) =>
      this.handleOutsideClick(e),
    )
  }

  getSelectionByTier(level) {
    const selections = this.state.selectionsByTier[level]
    if (!selections) {
      return null
    }
    return selections[0]
  }

  setDefaultValue() {
    if (this.props.open !== this.state.open) {
      this.setState({ open: this.props.open })
    }

    if (!this.props.defaultValue || !this.props.data) {
      this.setState({ selectionsByTier: [], finalSelection: '' })
      this.savedSelections = []
      return
    }

    const nodes = []
    findDefaultNodes(this.props.data, this.props.defaultValue, nodes)
    const selectionsByTier = buildSelectionsByTier(
      this.props.data,
      this.props.defaultValue,
    )
    this.setState({
      selectionsByTier,
      finalSelection: map(nodes, (node) => node.label).join(', '),
    })

    this.savedSelections = cloneDeep(selectionsByTier)
  }

  getDataForTier(data, level) {
    let tier = data

    times(level, (i) => {
      tier = tier[this.getSelectionByTier(i)].children
    })
    return tier
  }

  handleChange = (selectionsByTier, finalSelection, value, e) => {
    this.props.onChange(e, { value })
    if (!this.props.maintainDefault) {
      this.setState({ finalSelection })
      this.savedSelections = cloneDeep(selectionsByTier)
    }
  }

  handleClickElement = (e, tierItem, level, index) => {
    e.stopPropagation()
    if (tierItem.disabled) {
      e.preventDefault()
    } else {
      this.selectionFactory().onClick(e, tierItem, level, index)
    }
  }

  handleHoverElement = (e, tierItem, level, index) => {
    if (this.props.hoverableSelect) {
      this.selectionFactory().onMouseEnter(e, tierItem, level, index)
    }
  }

  hasSelectionsChanged = () =>
    !isEqual(this.savedSelections, this.state.selectionsByTier)

  dataInFinalTier = () =>
    this.getDataForTier(this.props.data, this.state.selectionsByTier.length - 1)

  addSingleSelectionAndExpand = (selectionsByTier) => {
    this.setState({ selectionsByTier })
    this.expandNextTier(selectionsByTier.length - 1)
  }

  changeSelectionAtLevel = (level, index) => {
    const selectionsByTier = [...this.state.selectionsByTier].splice(0, level)
    selectionsByTier.push([index])
    return selectionsByTier
  }

  selectionFactory() {
    if (this.props.multiSelect) {
      return new MultiSelect(this)
    }
    if (this.props.hoverableSelect) {
      return new HoverableSelect(this)
    }
    return new SingleSelect(this)
  }

  handleOpenDrilldown = () => {
    if (this.props.disabled) {
      return
    }

    if (this.state.open) {
      this.close()
    } else {
      this.open()
    }
  }

  handleOutsideClick = (e) => {
    if (this.node && !this.node.contains(e.target)) {
      this.close()
    }
  }

  open = () => {
    this.setState({ open: true })
  }

  close = () => {
    this.selectionFactory().onClose()
    this.setState({
      selectionsByTier: this.savedSelections,
      expandedLevels: 0,
      open: false,
    })
  }

  expandNextTier = (level) => {
    this.setState({ expandedLevels: level + 1 })
  }

  hasChildren = (tierItem) => tierItem.children && tierItem.children.length > 0

  isSelected = (level, index) => {
    if (!this.state.selectionsByTier[level]) {
      return false
    }
    return this.state.selectionsByTier[level].includes(index)
  }

  renderFlyout = (
    parentTier,
    level,
    containerStyle = styles.flyoutContainer,
    elementStyle = styles.flyoutElement,
  ) => {
    if (this.state.expandedLevels < level) {
      return null
    }

    const flyoutContainerStyle = new FlyoutContainerStyle(
      this.node,
      window.innerWidth,
    )
    const style = flyoutContainerStyle.getStyle(
      level,
      this.getSelectionByTier(level - 1),
    )

    return (
      <div className={containerStyle} style={style} key={level}>
        {map(parentTier, (childTierItem, i) => {
          const classes = classNames(elementStyle, {
            [styles.disabledItem]: childTierItem.disabled,
            [this.props.disabledClassName]: this.props.disabledClassName,
          })
          const hasArrow =
            this.props.hoverableSelect &&
            get(childTierItem, 'children.length') > 0
          const label = this.props.shouldTranslate
            ? translateText(childTierItem.label)
            : childTierItem.label

          return (
            <div
              className={classes}
              title={childTierItem.label}
              onClick={(e) =>
                this.handleClickElement(e, childTierItem, level, i)
              }
              onMouseEnter={(e) =>
                this.handleHoverElement(e, childTierItem, level, i)
              }
              key={`${childTierItem.label} ${childTierItem.id}`}
            >
              <span className={styles.label}>{label}</span>
              {this.isSelected(level, i) && (
                <Icon className={styles.checkmark} name="checkmark" />
              )}
              {hasArrow && !this.isSelected(level, i) && (
                <Icon className={styles.arrow} name="dropup" />
              )}
            </div>
          )
        })}
      </div>
    )
  }

  renderFlyouts(data) {
    const classes = classNames('flyouts', {
      [this.props.flyoutContainerClassname]: [
        this.props.flyoutContainerClassname,
      ],
    })
    return (
      <div className={classes}>
        {this.renderFlyout(data, 0, styles.dropdown, styles.element)}
        {map(this.state.selectionsByTier, (selection, level) =>
          this.renderFlyout(this.getDataForTier(data, level + 1), level + 1),
        )}
      </div>
    )
  }

  render() {
    const { placeholder, data, className, disabled, upward } = this.props

    const drillDownStyle = classNames(styles.Drilldown, className, {
      [styles.open]: this.state.open,
      [styles.disabled]: disabled,
      [styles.upward]: upward,
    })
    return (
      <div
        ref={(node) => {
          this.node = node
        }}
        className={drillDownStyle}
        onClick={this.handleOpenDrilldown}
      >
        <div>
          <div className={styles.input}>
            {this.state.finalSelection || placeholder}
          </div>
          <Icon name="chevron" className={styles.chevron} />
        </div>
        {this.state.open && this.renderFlyouts(data)}
      </div>
    )
  }
}

Drilldown.propTypes = {
  data: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabledClassName: PropTypes.string,
  flyoutContainerClassname: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  multiSelect: PropTypes.bool,
  upward: PropTypes.bool,
  hoverableSelect: PropTypes.bool,
  maintainDefault: PropTypes.bool,
  shouldTranslate: PropTypes.bool,
  open: PropTypes.bool,
}

Drilldown.defaultProps = {
  className: null,
  disabledClassName: null,
  flyoutContainerClassname: null,
  placeholder: null,
  disabled: false,
  defaultValue: null,
  multiSelect: false,
  upward: false,
  hoverableSelect: false,
  maintainDefault: false,
  shouldTranslate: false,
  open: false,
}

export default Drilldown
