import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import split from 'lodash/split'
import toNumber from 'lodash/toNumber'

import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

export const EMPTY_CELL_CONTENT = '—'
export const ORB360_MEDIA_TYPE = 'orb360'
export const VNTANA_MEDIA_TYPE = 'vntana'

export const getVariantByCode = (variants, code) =>
  find(variants, (variant) => get(variant, 'code') === code)

export const getVariantsWithoutCode = (variants, code) =>
  filter(variants, (variant) => get(variant, 'code') !== code)

export const findSkuPriceByPriceTypeId = (sku, priceTypeId) =>
  find(
    get(sku, 'prices'),
    (price) => get(price, 'priceType.id') === priceTypeId,
  )

export const hasVariantOrderMinimum = (variants) => {
  const variantValues = flatMap(
    variants,
    (variant) => get(variant, 'values'),
    [],
  )
  const orderMinimum = find(
    variantValues,
    (variantValue) => get(variantValue, 'orderMinimum', 0) > 0,
  )
  return !isEmpty(orderMinimum)
}

export const getVariantValueFromId = (variants, code, variantValueId) => {
  const variant = getVariantByCode(variants, code)
  return find(get(variant, 'values'), (value) => value.id === variantValueId)
}

export const filterProductTraitValuesByTraitCode = (
  productTraitValues,
  traitCode,
) =>
  filter(
    productTraitValues,
    (productTraitValue) => get(productTraitValue, 'trait.code') === traitCode,
  )

export const collectionIsMTO = (collections, collectionId) => {
  const collection = find(collections, (element) => element.id === collectionId)
  return get(collection, 'madeToOrder', false)
}

export const generateOrderGroupId = (userId) =>
  toGlobalId('OrderGroup', `${userId}-${new Date().getTime()}`)

export const fromCasepackId = (casepackId) => {
  const splittedId = split(fromGlobalId(fromGlobalId(casepackId).id).type, '|')
  return {
    colorVariantId: get(splittedId, [0], ''),
    sizeVariantId: get(splittedId, [1], ''),
    casepackId: get(splittedId, [2], ''),
  }
}

export const getCasepackMultiplier = (casepackId, casepacks) =>
  get(casepacks, `[${casepackId}].total`, 1)

export const toSkuId = (colorVariantId, sizeVariantId) => {
  const source = !isNaN(toNumber(sizeVariantId)) ? 0 : 2
  return window.btoa(`${source}|${colorVariantId}|${sizeVariantId}`)
}

export const toGlobalSkuId = (colorVariantId, sizeVariantId) =>
  toGlobalId('Sku', toSkuId(colorVariantId, sizeVariantId))

export const fromSkuId = (skuId) => {
  const splittedId = split(fromGlobalId(fromGlobalId(skuId).id).type, '|')
  return {
    colorVariantId: get(splittedId, [1], ''),
    sizeVariantId: get(splittedId, [2], ''),
  }
}

export const toGlobalSkuTraitValue = (type, id) =>
  toGlobalId('SKUTraitValue', toGlobalId(type, id))

export const getNewPrice = (value = { min: 0, max: 0 }, quantity = 0) => ({
  min: value.min * quantity,
  max: value.max * quantity,
})

export const sumUpPrices = (prices) =>
  reduce(
    prices,
    (acc, price) => ({
      min: acc.min + price.min,
      max: acc.max + price.max,
    }),
    {
      min: 0,
      max: 0,
    },
  )

export const skuIdToSizeId = (id) =>
  toGlobalId('Size', fromSkuId(id).sizeVariantId)

export const skuIdsToSizeIds = (ids) => map(ids, (id) => skuIdToSizeId(id))

export const casepackIdToSizeId = (id) =>
  toGlobalId('Size', fromCasepackId(id).sizeVariantId)

export const casepackIdsToSizeIds = (ids) =>
  map(ids, (id) => casepackIdToSizeId(id))

export const skuIdToVariantId = (id, type = 'StyleColor') =>
  idToVariantId(fromSkuId(id).colorVariantId, type)

export const idToVariantId = (id, type = 'StyleColor') =>
  toGlobalId('SKUTraitValue', toGlobalId(type, id))

export const hasProductOrderMinimum = (product) =>
  get(product, 'orderMinimum', 0) ||
  hasVariantOrderMinimum(get(product, 'variants', []))

export const getAttributeIdFromVariant = (variantId = '') =>
  fromGlobalId(fromGlobalId(variantId)?.id)?.id || ''
