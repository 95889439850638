import { KELLWOOD_ADMIN, RLM_ADMIN, STYLE_SYNC_SETTINGS } from 'routes/paths'

import DropdownMenuTextLink from '../../DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/NavbarRevamp/constants/navbarRevamp.messages'
import { SettingsDropdownIds } from 'features/NavbarRevamp/navbarRevamp.ids'

const AdminSettings = ({
  isAdmin,
  isIntegratedWithKellwood,
  isIntegratedWithRLM,
  isBrandAccount,
  isLiteBrandAccount,
}: {
  isAdmin: boolean
  isIntegratedWithKellwood: boolean
  isIntegratedWithRLM: boolean
  isBrandAccount: boolean
  isLiteBrandAccount: boolean
}) =>
  isAdmin && isBrandAccount && !isLiteBrandAccount ? (
    <>
      <DropdownMenuTextLink
        to={STYLE_SYNC_SETTINGS}
        message={messages.styleSyncSettings}
        id={SettingsDropdownIds.SettingsOptionStyleSync}
      />
      {isIntegratedWithRLM && (
        <DropdownMenuTextLink
          to={RLM_ADMIN}
          message={messages.adminIntegrationRLM}
          id={SettingsDropdownIds.SettingsOptionRLM}
        />
      )}
      {isIntegratedWithKellwood && (
        <DropdownMenuTextLink
          to={KELLWOOD_ADMIN}
          message={messages.adminIntegrationKellwood}
          id={SettingsDropdownIds.SettingsOptionKellwood}
        />
      )}
    </>
  ) : null

export default AdminSettings
