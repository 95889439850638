export const PRODUCT_NUMBER_CODE = 'STYLENUM'
export const PRODUCT_FIT_CODE = 'FIT'
export const PRODUCT_CATEGORY_GENDER_CODE = 'CATGENDER'
export const PRODUCT_CATEGORY_PARENT_CODE = 'DISPCAT'
export const PRODUCT_CATEGORY_CODE = 'DISPSUBCAT'
export const PRODUCT_CONTAINS_FUR_CODE = 'CONTAINSFUR'
export const PRODUCT_DIVISION_CODE = 'DIVISION'
export const PRODUCT_FABRICATION_NAME_ID = 'FABNAME'
export const PRODUCT_FABRICATION_CODE_ID = 'FABCODE'
export const PRODUCT_SILHOUETTE_NAME_ID = 'SILNAME'
export const PRODUCT_SILHOUTTE_CODE_ID = 'SILCODE'
export const PRODUCT_MATERIAL_CODE = 'MAT'
export const PRODUCT_COLOR_CODE = 'COLOR'
export const PRODUCT_STYLE_CODE = 'STYLE'
export const PRODUCT_SIZE_CODE = 'SIZE'
export const EMPTY_VALUE = '—'
export const PRODUCT_WHOLESALE_ATTRIBUTE = 'wholesalePrice' // TODO: be careful -> its used in a lot of places
export const PRODUCT_RETAIL_ATTRIBUTE = 'suggestedRetailPrice' // TODO: BE CAREFUL
export const STYLE_TAG_GROUP_CODE = 'STYLETAGGROUP'

export const BOOLEAN_TRAITS = [PRODUCT_CONTAINS_FUR_CODE]

export const EXCLUDED_TRAITS = [
  PRODUCT_NUMBER_CODE,
  PRODUCT_FIT_CODE,
  PRODUCT_FABRICATION_CODE_ID,
  PRODUCT_SILHOUTTE_CODE_ID,
]

export const EMPTY_STYLE_TAG_ID = 'EMPTY_STYLE_TAG'
export const getEmptyStyleTag = (styleTagGroupName = '') => ({
  id: EMPTY_STYLE_TAG_ID,
  name: `Not Assigned to ${styleTagGroupName}`,
})

export const getCategoryTrait = () => ({
  code: PRODUCT_CATEGORY_PARENT_CODE,
  name: 'Display Category',
})

export const getSubCategoryTrait = () => ({
  code: PRODUCT_CATEGORY_CODE,
  name: 'Display Sub-Category',
})

export const getFabricationTrait = () => ({
  code: PRODUCT_FABRICATION_NAME_ID,
  name: 'Fabrication Name',
})

export const getSilhouetteTrait = () => ({
  code: PRODUCT_SILHOUETTE_NAME_ID,
  name: 'Silhouette Name',
})

export const getMaterialTrait = () => ({
  code: PRODUCT_MATERIAL_CODE,
  name: 'Materials',
})

export const getProductFitTrait = () => ({
  code: PRODUCT_FIT_CODE,
  name: 'Product Fit',
})

export const getStyleNumberTrait = () => ({
  code: PRODUCT_NUMBER_CODE,
  name: 'Style Number',
})

export const getColorTrait = () => ({
  code: PRODUCT_COLOR_CODE,
  name: 'Color',
})

export const getSizeTrait = () => ({
  code: PRODUCT_SIZE_CODE,
  name: 'Size',
})
