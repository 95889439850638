import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { LITE_RETAILER_MODAL_SNOOZED } from 'hooks/useRenderLiteRetailerRequiredInfoModal'
import { actionsPrefix as NEWS_KEY } from 'store/news/constants'

export const clearStorage = (userId: string): void => {
  Object.keys(localStorage.valueOf())
    .filter(
      (key) =>
        (key.includes(userId) && !key.startsWith(NEWS_KEY)) ||
        key === LITE_RETAILER_MODAL_SNOOZED,
    )
    .forEach((key) => {
      localStorage.removeItem(key)
    })
}

const getStorageValue = (
  key: string,
  defaultValue: string | null = null,
): string | null => {
  const saved = localStorage.getItem(key)
  return saved || defaultValue
}

export const useLocalStorage = (
  key: string,
  defaultValue: string | null = null,
): [string | null, Dispatch<SetStateAction<string | null>>] => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue)
  })

  useEffect(() => {
    if (value === null) {
      localStorage.removeItem(key)
    } else {
      localStorage.setItem(key, value)
    }
  }, [key, value])

  return [value, setValue]
}
