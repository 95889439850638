import { Dispatch, SetStateAction } from 'react'

import { Checkbox, Icon, Option } from '@joor/design-system'
import styled from 'styled-components'

type ThirdModalStepProps = {
  secondaryAccounts: Option[]
  mergeAcceptance: boolean
  setMergeAcceptance: Dispatch<SetStateAction<boolean>>
}
const StyledSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 24px;
  gap: 24px;
`
const StyledTitle = styled.h2`
  && {
    font-family: var(--font-family-alpha);
    font-size: var(--size-font-medium);
    margin: 0;
  }
`
const StyledWarningTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  > h2 {
    margin: 0;
  }
`
const StyledParagraph = styled.p`
  && {
    width: 100%;
    font-family: var(--font-family-alpha);
    margin: 0;
  }
`
const StyledUnorderedList = styled.ul`
  && {
    width: 100%;
    font-family: var(--font-family-alpha);
    margin: 0;
  }
`
const StyledUnorderedListElement = styled.li`
  && {
    width: 100%;
    font-family: var(--font-family-alpha);
    margin: 0;
    list-style: none;
    padding: none;
  }
`
const StyledCheckbox = styled(Checkbox)`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex-flow: row-reverse;
  gap: 8px;
  align-items: center;
  justify-content: flex-start;
  > span {
    width: 100%;
  }
`

const ThirdModalStep: React.FC<ThirdModalStepProps> = ({
  secondaryAccounts,
  mergeAcceptance,
  setMergeAcceptance,
}) => {
  const checkboxLabel =
    // eslint-disable-next-line max-len
    'I understand the implications of merging my accounts. I understand this action is not reversible and may impact other users in my organization'
  return (
    <StyledSection>
      <StyledWarningTitle>
        <Icon iconName="warning" />
        <StyledTitle>WARNING</StyledTitle>
      </StyledWarningTitle>
      <StyledParagraph>
        Are you sure you want to merge these accounts?
      </StyledParagraph>
      <StyledParagraph>
        This action is NOT reversible. You will not be able to unmerge these
        accounts.
      </StyledParagraph>
      <StyledParagraph>
        Make sure that all users in your organization are aware that the
        following accounts will no longer be accessible:
      </StyledParagraph>
      <StyledUnorderedList>
        {secondaryAccounts.map((secondaryAccount) => (
          <StyledUnorderedListElement key={secondaryAccount.value}>
            {secondaryAccount.label}
          </StyledUnorderedListElement>
        ))}
      </StyledUnorderedList>
      <StyledCheckbox
        id="merge-acceptance"
        label={checkboxLabel}
        checked={mergeAcceptance}
        onChange={() => setMergeAcceptance(!mergeAcceptance)}
      />
    </StyledSection>
  )
}
export default ThirdModalStep
