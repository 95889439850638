import uniqBy from 'lodash/uniqBy'

import ArrayOfIdsFilter from 'utils/filters/arrayOfIdsFilter'
import DateFilter from 'utils/filters/dateFilter'
import { formatNameWithCode } from 'utils/formatters'

export const formatFilters = (appliedFilters) => {
  return {
    styleboardIds: appliedFilters.styleboardNames.map((name) => name.id),
    ownerUserIds: appliedFilters.styleboardOwners.map((owner) => owner.id),
    sharedUserIds: appliedFilters.sharedUsers.map((member) => member.id),
    sharedAccountIds: appliedFilters.sharedAccounts.map(
      (account) => account.id,
    ),
    brandIds: appliedFilters.brandNames.map((brand) => brand.id),
    createdDate: {
      startDate: appliedFilters?.createdOn?.startDate,
      endDate: appliedFilters?.createdOn?.endDate,
    },
    modifiedDate: {
      startDate: appliedFilters?.lastModified?.startDate,
      endDate: appliedFilters?.lastModified?.endDate,
    },
  }
}

export const getFilterData = (styleboardListFilters = {}) => ({
  brandNames: uniqBy(styleboardListFilters.brands, 'id').filter(
    (brand) => brand.name,
  ),
  styleboardNames: uniqBy(styleboardListFilters.styleboards, 'id').filter(
    (styleboard) => styleboard.name,
  ),
  styleboardOwners: uniqBy(styleboardListFilters.ownerUsers, 'id').filter(
    (owner) => owner.name,
  ),
  sharedUsers: uniqBy(styleboardListFilters.sharedUsers, 'id').filter(
    (user) => user.name,
  ),
  sharedAccounts: uniqBy(styleboardListFilters.sharedAccounts, 'id').filter(
    (account) => account.name,
  ),
})

export const styleboardFilters = {
  styleboardNames: new ArrayOfIdsFilter({
    filterKey: 'styleboardNames',
    label: 'Styleboard Name',
    dataKey: 'styleboardNames',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  brandNames: new ArrayOfIdsFilter({
    filterKey: 'brandNames',
    label: 'Brand Name',
    dataKey: 'brandNames',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  styleboardOwners: new ArrayOfIdsFilter({
    filterKey: 'styleboardOwners',
    label: 'Creator Name',
    dataKey: 'styleboardOwners',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  sharedUsers: new ArrayOfIdsFilter({
    filterKey: 'sharedUsers',
    label: 'Team Members Shared With',
    dataKey: 'sharedUsers',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  sharedAccounts: new ArrayOfIdsFilter({
    filterKey: 'sharedAccounts',
    label: 'Retailer Shared With',
    dataKey: 'sharedAccounts',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  createdOn: new DateFilter({
    filterKey: 'createdOn',
    label: 'Created On',
    dataKey: 'createdOn',
    messages: {
      addDateFilter: 'Add Date as Filter',
    },
  }),
  lastModified: new DateFilter({
    filterKey: 'lastModified',
    label: 'Last Modified',
    dataKey: 'lastModified',
    messages: {
      addDateFilter: 'Add Date as Filter',
    },
  }),
}

export const filterSetup = [
  {
    key: 'styleboardNames',
    title: 'Styleboard Name',
    noTranslate: true,
    filters: [styleboardFilters.styleboardNames],
  },
  {
    key: 'brandNames',
    title: 'Brand Name',
    noTranslate: true,
    filters: [styleboardFilters.brandNames],
  },
  {
    key: 'styleboardOwners',
    title: 'Creator Name',
    noTranslate: true,
    filters: [styleboardFilters.styleboardOwners],
  },
  {
    key: 'sharedUsers',
    title: 'Team Members Shared With',
    noTranslate: true,
    filters: [styleboardFilters.sharedUsers],
  },
  {
    key: 'sharedAccounts',
    title: 'Retailer Shared With',
    noTranslate: true,
    filters: [styleboardFilters.sharedAccounts],
  },
  {
    key: 'createdOn',
    title: 'Created On',
    noTranslate: true,
    filters: [styleboardFilters.createdOn],
  },
  {
    key: 'lastModified',
    title: 'Last Modified',
    noTranslate: true,
    filters: [styleboardFilters.lastModified],
  },
]

export const getPanels = ({
  filterActionHandler,
  filterData,
  selectedFilters,
  isBrandAccount,
}) => {
  const excludeFilters = isBrandAccount
    ? ['brandNames']
    : ['sharedUsers', 'sharedAccounts']

  // In case we need a subfilter in the future, refer to OrderListFilters.component
  const filters = filterSetup
    .filter((filter) => !excludeFilters?.includes(filter.key))
    .map((panelInfo) => {
      const {
        dataKey,
        renderFilterPanelHandler,
        showFilterHandler,
      } = panelInfo.filters[0]
      const actionHandler = (filterItem) =>
        filterActionHandler({ dataKey, ...filterItem })
      return renderFilterPanelHandler({
        filterData,
        selectedFilters,
        actionHandler,
        showFilterHandler,
        noTranslate: panelInfo.noTranslate,
      })
    })

  return isBrandAccount
    ? filters.filter((filter) => filter.title !== 'Brand Name')
    : filters
}
