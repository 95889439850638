import PropTypes from 'prop-types'
import { Tab as SemanticUITab } from 'semantic-ui-react'

const TabPane = ({ children }) => (
  <SemanticUITab.Pane>{children}</SemanticUITab.Pane>
)

TabPane.propTypes = {
  children: PropTypes.node.isRequired,
}

export default TabPane
