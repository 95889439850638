import get from 'lodash/get'
import PropTypes from 'prop-types'

import { getDisplayName, getUrlParam } from 'utils/mixpanel'
import { fromGlobalId } from 'utils/transformations/graphql'

import { BULK_TABLE_ID } from '../TablesConfiguration'

export const withAnalytics = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { mixpanel, location, brandAccount } = props

    const getSubmittedParams = () => {
      const {
        accountId,
        accountProfileName,
        brand,
        retailerAccount: { id: retailerId },
        isBrandUser,
        selectedTabId,
        hasNotedVariants,
      } = props

      const selectedTable = selectedTabId === BULK_TABLE_ID ? 'Bulk' : 'Sized'

      return {
        User: accountId,
        Brand: isBrandUser ? accountProfileName : get(brand, 'name', ''),
        Order_For: fromGlobalId(retailerId).id,
        Quantity: `${selectedTable} ${
          hasNotedVariants ? 'with Noted Values' : ''
        }`,
        ...getUrlParam(location),
      }
    }

    const trackers = {
      trackAddToOrder: (config) =>
        mixpanel.track('Shop_Add to Order', getSubmittedParams(config)),
      trackOpenStyleDetail: (expanded) =>
        mixpanel.track('Catalog_StyleDetails', {
          Expanded: expanded,
          brandId: brandAccount?.id,
        }),
      trackSwatchClick: (colorName) =>
        mixpanel.track('Catalog_Swatch', {
          'Color Name': colorName,
          brandId: brandAccount?.id,
        }),
    }

    return <WrappedComponent {...props} {...trackers} />
  }

  Wrapper.propTypes = {
    mixpanel: PropTypes.object,
    location: PropTypes.object.isRequired,
  }
  Wrapper.defaultProps = {
    mixpanel: { track: () => {} },
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return Wrapper
}

export default withAnalytics
