import { useEffect } from 'react'

export const useOnClickOutside = (ref, onClickOutside, shouldTrack) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside(event)
      }
    }
    if (shouldTrack) {
      document.addEventListener('mousedown', handleClickOutside, {
        capture: true,
      })
    } else {
      document.removeEventListener('mousedown', handleClickOutside, {
        capture: true,
      })
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, {
        capture: true,
      })
    }
  }, [ref, shouldTrack, onClickOutside])
}
