import {
  RETAILER_CREATED_ORDERS_KEY,
  STYLEBOARD_COMMENTS_KEY,
  UPCOMING_MAINTENANCE_BANNER_KEY,
} from 'store/news/constants'

import { VIRTUAL_SHOWROOM } from 'routes/flags'

import { BLUE, YELLOW } from './NewsBannerItem/NewsBannerItem.component'
import styles from './NewsBannerItem/NewsBannerItem.component.less'

const WHITE_CLOSE_ICON = 'whiteIcon'
const BLACK_CLOSE_ICON = 'blackIcon'

const newsConfig = {
  [RETAILER_CREATED_ORDERS_KEY]: {
    color: BLUE,
    iconClassname: styles[WHITE_CLOSE_ICON],
    timestamp: true,
  },
  [STYLEBOARD_COMMENTS_KEY]: {
    featureFlag: VIRTUAL_SHOWROOM,
    timestamp: true,
    color: BLUE,
    iconClassname: styles[WHITE_CLOSE_ICON],
  },
  [UPCOMING_MAINTENANCE_BANNER_KEY]: {
    color: YELLOW,
    message:
      'Some users are currently experiencing latency on JOOR. We apologize for the inconvenience and are actively working to resolve the issue.',
    featureFlag: 'toggle-upcoming-maintenance-banner',
    timestamp: true,
    iconClassname: styles[BLACK_CLOSE_ICON],
  },
}

export default newsConfig
