import { useQuery } from '@apollo/client'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

const RETAILER_ACCOUNT_CONNECTIONS = atlas`#graphql
  query ConnectedAccounts ($retailerIds: [ID!]!) {
    retailers (retailerIds:$retailerIds) {
      edges {
        node {
          brands {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const useRetailerConnections = (retailerId: string) => {
  const { data } = useQuery(RETAILER_ACCOUNT_CONNECTIONS, {
    client: atlasClient,
    variables: {
      retailerIds: [retailerId],
    },
    skip: !retailerId,
  })
  const brands = relayConnectionToArray(data?.retailers)?.[0]?.brands
  const connections = relayConnectionToArray(brands)
  const hasConnections = Boolean(connections?.length)

  return hasConnections
}
