import compose from 'lodash/flowRight'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import { getCurrentUserFromConfig } from 'utils/config'
import { getDisplayName, getEventName, getUrlParam } from 'utils/mixpanel'
import { withMixpanel } from 'utils/sdks/mixpanel'

export const handleTrackPageView = (
  location,
  mixpanel,
  accountId,
  viewedAccountId,
) => {
  const eventName = getEventName(location)
  if (eventName && accountId && viewedAccountId) {
    mixpanel.track(eventName, {
      accountId,
      viewedAccountId,
      ...getUrlParam(location),
    })
  }
}

export const withAnalytics = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { mixpanel, location, accountId } = props
    const { targetAccountId: viewedAccountId } = getCurrentUserFromConfig()

    const trackers = {
      trackPageView: () =>
        handleTrackPageView(location, mixpanel, accountId, viewedAccountId),
    }

    return <WrappedComponent {...props} {...trackers} />
  }

  Wrapper.propTypes = {
    mixpanel: PropTypes.object,
    location: PropTypes.object.isRequired,
  }

  Wrapper.defaultProps = {
    mixpanel: { track: () => {} },
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return compose(withRouter, withMixpanel)(Wrapper)
}

export default withAnalytics
