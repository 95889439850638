import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import getDisplayName from 'utils/getDisplayName'
import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import {
  getDeliveryWindow,
  getProductCasepacks,
  getProductCode,
  getProductDescription,
  getProductExternalMedia,
  getProductImages,
  getProductName,
  getProductOrderMinimum,
  getProductSkusMap,
  getProductTagValues,
  getProductTraitValues,
  getProductVariants,
  getProductVideos,
  getSkusPriceRangeByTrade,
} from 'shop/products/getters'
import {
  PRODUCT_RETAIL_ATTRIBUTE,
  PRODUCT_SIZE_CODE,
  PRODUCT_WHOLESALE_ATTRIBUTE,
} from 'shop/products/traits'
import { getVariantByCode } from 'shop/products/utils'

export const getSelectedPriceType = (priceTypes, selectedPriceTypeId) =>
  find(priceTypes, (priceType) => {
    const priceTypeId = fromGlobalId(get(priceType, 'id', null)).id
    return selectedPriceTypeId === priceTypeId
  })

export const getCurrencyCode = (priceType) =>
  get(priceType, 'currency.code', 'USD')
export const getRetailCurrencyCode = (priceType) =>
  get(priceType, 'retailCurrency.code', 'USD')

const sizeVariantWithDescriptionOrCodeExists = (product) => {
  const { skus, variants } = product

  const sizeVariantValues = get(
    getVariantByCode(variants, PRODUCT_SIZE_CODE),
    'values',
    [],
  )
  const skuWithDescriptionExists = find(
    [...skus.values()],
    (sku) => !isEmpty(sku.description),
  )
  const sizeVariantWithCodeExists = find(
    sizeVariantValues,
    (sizeVariantValue) => !isEmpty(sizeVariantValue.externalIdentifier),
  )

  return Boolean(skuWithDescriptionExists) || Boolean(sizeVariantWithCodeExists)
}

const casepackWithDescriptionOrCodeExists = (product) => {
  const { casepacks } = product

  return Boolean(
    find(
      casepacks,
      (casepack) =>
        !isEmpty(casepack.description) || !isEmpty(casepack.externalIdentifier),
    ),
  )
}

export const shouldDisplaySizeVariantDetails = (product) =>
  sizeVariantWithDescriptionOrCodeExists(product) ||
  casepackWithDescriptionOrCodeExists(product)

export const withProductFormatter = (WrappedComponent) => {
  const Wrapper = (props) => {
    const {
      priceTypeId: selectedPriceTypeId,
      priceTypes,
      product,
      collectionId,
      collections,
      eventId,
      ...otherProps
    } = props

    const selectedPriceGraphQLId = toGlobalId('PriceType', selectedPriceTypeId)
    const casepacks = getProductCasepacks(product)
    const casepacksSkus = reduce(
      casepacks,
      (acc, { skus }) => [...acc, ...skus],
      [],
    )

    const productFormatted = {
      id: product.id,
      name: getProductName(product),
      code: getProductCode(product),
      orderMinimum: getProductOrderMinimum(product),
      wholesaleRange: getSkusPriceRangeByTrade(
        [...product.skus, ...casepacksSkus],
        selectedPriceGraphQLId,
        PRODUCT_WHOLESALE_ATTRIBUTE,
      ),
      retailRange: getSkusPriceRangeByTrade(
        [...product.skus, ...casepacksSkus],
        selectedPriceGraphQLId,
        PRODUCT_RETAIL_ATTRIBUTE,
      ),
      deliveryWindow: getDeliveryWindow(collections, collectionId),
      variants: getProductVariants(product),
      images: getProductImages(product),
      externalMedia: getProductExternalMedia(product),
      videos: getProductVideos(product),
      traitValues: getProductTraitValues(product),
      tagValues: getProductTagValues(product),
      description: getProductDescription(product),
      skus: getProductSkusMap(product),
      badges: product.badges,
      casepacks,
      casepackIds: product.casepackIds,
      categories: product.categories,
    }

    const newProps = {
      ...otherProps,
      currencyCode: getCurrencyCode(
        getSelectedPriceType(priceTypes, selectedPriceTypeId),
      ),
      retailCurrency: getRetailCurrencyCode(
        getSelectedPriceType(priceTypes, selectedPriceTypeId),
      ),
      priceTypeId: selectedPriceGraphQLId,
      collectionId,
      ...(eventId && {
        eventId: toGlobalId('LibraryValue', fromGlobalId(eventId).id),
      }),
      product: productFormatted,
      displaySizeVariantDetails: shouldDisplaySizeVariantDetails(
        productFormatted,
      ),
    }

    return <WrappedComponent {...newProps} />
  }

  Wrapper.displayName = `withProductFormatter(${getDisplayName(
    WrappedComponent,
  )})`

  Wrapper.propTypes = {
    priceTypeId: PropTypes.string.isRequired,
    priceTypes: PropTypes.array,
    product: PropTypes.object.isRequired,
  }
  Wrapper.defaultProps = {
    priceTypes: [],
  }
  return Wrapper
}

export default withProductFormatter
