import { ToastToAdd, useNotifications } from '@joor/design-system'

import { MakeOptional } from 'types'

type RenderToast = MakeOptional<ToastToAdd, 'title'>

type GenericRenderToastFunc = (toast: RenderToast) => void

export type RenderToastFunc = (toast: Omit<RenderToast, 'type'>) => void

type UseRenderToasterHookReturnType = {
  renderToast: GenericRenderToastFunc
  renderSuccessToast: RenderToastFunc
  renderErrorToast: RenderToastFunc
  renderInfoToast: RenderToastFunc
  renderWarningToast: RenderToastFunc
}

export const useRenderToasters = (): UseRenderToasterHookReturnType => {
  const { addToasts } = useNotifications()

  const renderToast = ({
    type,
    description,
    title = '',
    className = '',
    shouldCloseManually,
    onClose,
    action,
    timeOut,
    primaryButton,
    secondaryButton,
  }: RenderToast): void =>
    addToasts([
      {
        title,
        description,
        type,
        className,
        shouldCloseManually,
        onClose,
        action,
        timeOut,
        primaryButton,
        secondaryButton,
      },
    ])

  /**
   *
   * @param toast Toast to render omitting the type
   * @returns the call to the generic renderToast method injecting the
   * type 'success' in the cb param
   */
  const renderSuccessToast: RenderToastFunc = (toast) =>
    renderToast({ ...toast, type: 'success' })
  /**
   *
   * @param toast Toast to render omitting the type
   * @returns the call to the generic renderToast method injecting the
   * type 'error' in the cb param
   */
  const renderErrorToast: RenderToastFunc = (toast) =>
    renderToast({ ...toast, type: 'error' })
  /**
   *
   * @param toast Toast to render omitting the type
   * @returns the call to the generic renderToast method injecting the
   * type 'info' in the cb param
   */
  const renderInfoToast: RenderToastFunc = (toast) =>
    renderToast({ ...toast, type: 'info' })
  /**
   *
   * @param toast Toast to render omitting the type
   * @returns the call to the generic renderToast method injecting the
   * type 'warning' in the cb param
   */
  const renderWarningToast: RenderToastFunc = (toast) =>
    renderToast({ ...toast, type: 'warning' })

  return {
    renderToast,
    renderSuccessToast,
    renderErrorToast,
    renderInfoToast,
    renderWarningToast,
  }
}
