import { createAction } from 'redux-actions'

import {
  ADD_ALL_SELECTS,
  APPLY_SELECTED_FILTERS,
  CLEAR_ALL_FILTERS,
  CLEAR_FILTER,
  CLEAR_SELECTS_FOR_COLLECTION,
  DESELECT_UNAVAILABLE_PRODUCTS,
  SET_BRAND,
  SET_CART_INFO,
  SET_DOORS,
  SET_EVENT,
  SET_FILTER,
  SET_GROUP_BY_TAG_INFO,
  SET_ORDER_GROUP_ID,
  SET_ORDER_PRICETYPE,
  SET_ORDER_TYPE_ID,
  SET_PRICETYPES,
  SET_RETAILER,
  SET_SEARCH_TEXT,
  SET_SHOULD_DISABLE_CART,
  SET_SHOULD_UPDATE_CART,
  SET_SORT_BY_TAG_INFO,
  SET_WAREHOUSE,
  TOGGLE_DESELECT_ALL_FILTERS,
  TOGGLE_SELECTS,
  TOGGLE_SELECT_FILTER,
} from './constants'

export const setRetailer = createAction(SET_RETAILER)
export const setDoors = createAction(SET_DOORS)
export const setPriceTypes = createAction(SET_PRICETYPES)
export const setOrderPriceType = createAction(SET_ORDER_PRICETYPE)
export const setWarehouse = createAction(SET_WAREHOUSE)
export const setEvent = createAction(SET_EVENT)
export const setShouldUpdateCart = createAction(SET_SHOULD_UPDATE_CART)
export const setShouldDisableCart = createAction(SET_SHOULD_DISABLE_CART)
export const toggleSelectFilter = createAction(TOGGLE_SELECT_FILTER)
export const toggleDeselectAllFilters = createAction(
  TOGGLE_DESELECT_ALL_FILTERS,
)
export const applySelectedFilters = createAction(APPLY_SELECTED_FILTERS)
export const setFilter = createAction(SET_FILTER)
export const setBrand = createAction(SET_BRAND)
export const setSearchText = createAction(SET_SEARCH_TEXT)
export const setOrderTypeId = createAction(SET_ORDER_TYPE_ID)
export const setCartInfo = createAction(SET_CART_INFO)
export const setGroupByTagInfo = createAction(SET_GROUP_BY_TAG_INFO)
export const setSortByTagInfo = createAction(SET_SORT_BY_TAG_INFO)
export const setOrderGroupId = createAction(SET_ORDER_GROUP_ID)
export const clearAllFilters = createAction(CLEAR_ALL_FILTERS)
export const clearFilter = createAction(CLEAR_FILTER)

// --- Shop To Assortment Actions ---
export const addAllSelects = createAction(ADD_ALL_SELECTS)
export const toggleSelects = createAction(TOGGLE_SELECTS)
export const clearSelectsForCollection = createAction(
  CLEAR_SELECTS_FOR_COLLECTION,
)
export const deselectUnavailableProducts = createAction(
  DESELECT_UNAVAILABLE_PRODUCTS,
)
