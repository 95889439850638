import compose from 'lodash/flowRight'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withMixpanel } from 'utils/sdks/mixpanel'

import { isCreateCustomPoRestricted } from 'store/currentUser/selectors'
import {
  changeRetailerSearchText,
  setImportedFiles,
  setSelectedRetailerId,
} from 'store/orderList/excelOrdersModal/actions'
import {
  getRetailerSearchText,
  getSelectedRetailerId,
} from 'store/orderList/excelOrdersModal/selectors'

import { withAnalytics } from 'modals/OrderList/ExcelOrdersModal/ExcelOrdersModal.analytics'

import ExcelOrdersModal from './ExcelOrdersModal.component'
import {
  withConnectedAccounts,
  withConnectionsForRetailerData,
  withInitialExcelOrderModalData,
} from './ExcelOrdersModal.queries'

const mapStateToProps = (state) => ({
  accountId: state.currentUser.account.accountId,
  selectedRetailerId: getSelectedRetailerId(state),
  retailerSearchText: getRetailerSearchText(state),
  permissions: {
    restrictCreateCustomPo: isCreateCustomPoRestricted(state),
  },
})

const mapDispatchToProps = (dispatch) => ({
  setImportedFiles: (files) => dispatch(setImportedFiles(files)),
  setSelectedRetailerId: (retailerId) =>
    dispatch(setSelectedRetailerId(retailerId)),
  changeRetailerSearchText: (searchText) =>
    dispatch(changeRetailerSearchText(searchText)),
})

const withRedux = connect(mapStateToProps, mapDispatchToProps)

const ExcelOrdersModalContainer = compose(
  withRouter,
  withMixpanel,
  withRedux,
  withInitialExcelOrderModalData,
  withConnectedAccounts,
  withConnectionsForRetailerData,
  withAnalytics,
)(ExcelOrdersModal)

export default function ExcelOrdersModalContainerWrapper(props) {
  return <ExcelOrdersModalContainer {...props} />
}
