import { useRef } from 'react'

import PropTypes from 'prop-types'
import {
  BigPlayButton,
  ControlBar,
  FullscreenToggle,
  Player,
  VolumeMenuButton,
} from 'video-react'
import 'video-react/dist/video-react.css'

import Icon from 'components/Core/Icon/Icon'
import IconButton from 'components/Core/IconButton/IconButton'

import styles from './Video.less'
import HLSSource from 'containers/Showroom/GridLayout/GridElements/MediaGridElement/HLSSource'

const Video = ({
  src,
  format,
  width,
  height,
  muted,
  autoPlay,
  disableContextMenu,
  disableFullScreen,
  loop,
}) => {
  const PIPEnabled = 'pictureInPictureEnabled' in document
  const videoRef = useRef(null)

  /**
   * Notice how Player receives a ref that is also passed down to
   * HLSSource child.
   *
   * Having a ref set on a parent component that also has to
   * be passed to a child component is causing a bug where said ref
   * won't be available on the first render of the HLSSource component.
   * This means that sometimes, we have to double click on the video
   * thumbnail to get it to display the video correctly.
   * The first time to mount the Player wrapper, the second time
   * so the already mounted videoElem has an actual value that
   * HLSSource component can use to mount the vid.
   */
  const videoElem = videoRef?.current?.video?.video

  return (
    <div
      className={styles.Video}
      data-test="contextMenuHandler"
      onContextMenu={(e) => {
        if (disableContextMenu) {
          e.preventDefault()
        }
      }}
    >
      <Player
        ref={videoRef}
        {...(format !== 'm3u8' && { src })}
        width={width}
        height={height}
        muted={muted}
        autoPlay={autoPlay}
        loop={loop}
        fluid={false}
      >
        <BigPlayButton position="center" />
        <ControlBar autoHide={false}>
          <VolumeMenuButton disabled />
          <FullscreenToggle disabled={disableFullScreen} />
          {format === 'm3u8' && (
            <HLSSource isVideoChild video={videoElem} src={src} />
          )}
          {PIPEnabled && (
            <IconButton
              className={styles.pipButton}
              order={7}
              active={false}
              onClick={() => {
                videoElem.requestPictureInPicture()
              }}
            >
              <Icon
                className={styles.pipIcon}
                name="pip"
                label="Picture in picture mode"
              />
            </IconButton>
          )}
        </ControlBar>
      </Player>
    </div>
  )
}

Video.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  muted: PropTypes.bool,
  autoPlay: PropTypes.bool,
  disableContextMenu: PropTypes.bool,
  loop: PropTypes.bool,
  disableFullScreen: PropTypes.bool,
}

Video.defaultProps = {
  src: '',
  width: null,
  height: null,
  muted: false,
  autoPlay: false,
  disableContextMenu: false,
  loop: false,
  disableFullScreen: false,
}

export default Video
