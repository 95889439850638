export class SingleSelect {
  constructor(drilldown) {
    this.drilldown = drilldown
  }

  onClick(e, tierItem, level, index) {
    const alreadyClicked = this.drilldown.isSelected(level, index)

    if (alreadyClicked && this.drilldown.hasChildren(tierItem)) {
      this.drilldown.expandNextTier(level)
    } else if (alreadyClicked) {
      this.drilldown.close()
    } else {
      this.setSelection(index, level, tierItem, e)
    }
  }

  onClose = () => {}

  setSelection = (index, level, tierItem, e) => {
    const selectionsByTier = this.drilldown.changeSelectionAtLevel(level, index)
    this.drilldown.addSingleSelectionAndExpand(selectionsByTier)

    if (!this.drilldown.hasChildren(tierItem)) {
      this.drilldown.handleChange(selectionsByTier, tierItem.label, tierItem, e)
      this.drilldown.close()
    }
  }
}
