import { useState } from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './ButtonGroup.less'

const ButtonGroup = ({ buttons, className }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const buttonGroupClasses = classnames(styles.ButtonGroup, className)
  return (
    <div className={buttonGroupClasses}>
      {buttons.map((button, index) => (
        <button
          key={button.label}
          className={styles.button}
          disabled={index === activeIndex}
          onClick={() => {
            button.onClick()
            setActiveIndex(index)
          }}
        >
          {button.label}
        </button>
      ))}
    </div>
  )
}

ButtonGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
}

ButtonGroup.defaultProps = {
  className: '',
}

export default ButtonGroup
