import {
  ASPECT_LANDSCAPE,
  ASPECT_PORTRAIT,
  ASPECT_SQUARE,
  COLLECTIONS_WIDGET,
  DOCUMENT_WIDGET,
  IMAGE_WIDGET,
  LINESHEET_WIDGET,
  ORB_WIDGET,
  VIDEO_WIDGET,
} from 'features/Storefront/constants'

export const LEFT = 'LEFT'
export const RIGHT = 'RIGHT'
export const MEDIUM = 'MEDIUM'
export const LARGE = 'LARGE'

export const DROPDOWN_OPTIONS = [
  {
    text: 'Portrait (3:4)',
    key: ASPECT_PORTRAIT,
    value: ASPECT_PORTRAIT,
  },
  {
    text: 'Square (1:1)',
    key: ASPECT_SQUARE,
    value: ASPECT_SQUARE,
  },
  {
    text: 'Landscape (3:2)',
    key: ASPECT_LANDSCAPE,
    value: ASPECT_LANDSCAPE,
  },
]
export const POSITION_OPTIONS = [
  {
    label: 'Left',
    value: LEFT,
  },
  {
    label: 'Right',
    value: RIGHT,
  },
]
export const SIZE_OPTIONS = [
  {
    label: 'Medium',
    value: MEDIUM,
  },
  {
    label: 'Large',
    value: LARGE,
  },
]

const IMAGE_TEXT_SETTINGS = {
  widgetName: 'Image + Text Settings',
  positionTitle: 'Image Position',
  aspectTitle: 'Image Aspect Ratio',
  sizeTitle: 'Image Size',
  trackerTitle: 'Image + Text Gallery Row Level Settings',
}
const DOCUMENT_TEXT_SETTINGS = {
  widgetName: 'Document + Text Settings',
  positionTitle: 'Document Widget Position',
  aspectTitle: 'Cover Image Aspect Ratio',
  sizeTitle: 'Cover Image Size',
  trackerTitle: 'Document + Text Gallery Row Level Settings',
}
const LINESHEET_TEXT_SETTINGS = {
  widgetName: 'Linesheet + Text Settings',
  positionTitle: 'Linesheet Widget Position',
  aspectTitle: 'Cover Image Aspect Ratio',
  sizeTitle: 'Cover Image Size',
  trackerTitle: 'Linesheet + Text Gallery Row Level Settings',
}
const COLLECTION_TEXT_SETTINGS = {
  widgetName: 'Collection + Text Settings',
  positionTitle: 'Collection Widget Position',
  aspectTitle: 'Cover Image Aspect Ratio',
  sizeTitle: 'Cover Image Size',
}
const ORB360_TEXT_SETTINGS = {
  widgetName: 'ORB360 + Text Settings',
  positionTitle: 'ORB360 Position',
}
const VIDEO_TEXT_SETTINGS = {
  widgetName: 'Video + Text Settings',
  positionTitle: 'Video Position',
  aspectTitle: 'Video Aspect Ratio',
  sizeTitle: 'Video Size',
  trackerTitle: 'Video + Text Gallery Row Level Settings',
}

export const TEXT_TOOLTIP_EXTRA_SETTINGS = {
  [IMAGE_WIDGET]: IMAGE_TEXT_SETTINGS,
  [VIDEO_WIDGET]: VIDEO_TEXT_SETTINGS,
  [DOCUMENT_WIDGET]: DOCUMENT_TEXT_SETTINGS,
  [ORB_WIDGET]: ORB360_TEXT_SETTINGS,
  [LINESHEET_WIDGET]: LINESHEET_TEXT_SETTINGS,
  [COLLECTIONS_WIDGET]: COLLECTION_TEXT_SETTINGS,
}
