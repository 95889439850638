import { useEffect } from 'react'

import { SquareDotLoadingIndicator } from '@joor/design-system'
import styled from 'styled-components'

import { hidePhpLoader, showPhpLoader } from 'pages/RouterPage/RouterPage'

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoaderOverLay = () => {
  useEffect(() => {
    hidePhpLoader()
    return () => {
      showPhpLoader()
    }
  }, [])
  return (
    <LoaderWrapper>
      <SquareDotLoadingIndicator />
    </LoaderWrapper>
  )
}
export default LoaderOverLay
