// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client'

/**
 * GQL document template tag that's specifically for use with hyperion queries
 * @param {Parameters<typeof gql>} args
 */
export const hyperion = (...args) => gql(...args)

/**
 * GQL document template tag that's specifically for use with atlas queries
 * @param {Parameters<typeof gql>} args
 */
export const atlas = (...args) => gql(...args)

/**
 * GQL document template tag that's specifically for use with backend.python graphene queries
 * @param {Parameters<typeof gql>} args
 */
export const graphene = (...args) => gql(...args)

/**
 * GQL document template tag that's specifically for use with rest client queries
 * @param {Parameters<typeof gql>} args
 */
export const rest = (...args) => gql(...args)
