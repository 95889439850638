import { useMutation } from '@apollo/client'
import { useNotifications } from '@joor/design-system'

import {
  BulkUpdateRetailerGoogleAddressesInput,
  BulkUpsertRetailerLocationsInput,
  RetailerImpersonationFeedbackInput,
  UpdateAccountPrivacyInput,
  UpdateRetailerPrimaryAccountInput,
  UpdateRetailerProfileInput,
} from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

import { LiteRetailerRequiredInfoViews } from './LiteRetailerRequiredInfoModal'

const UPDATE_LITE_RETAILER_WEBSITE = atlas`#graphql
   mutation UpdateLiteRetailerWebsite(
    $input: UpdateRetailerProfileInput!
  ) {
    updateRetailerProfile(input: $input) {
      id
      websiteUrl
      description
    }
  }
`

export const useUpdateWebsiteAndDescription = () => {
  const [mutate] = useMutation(UPDATE_LITE_RETAILER_WEBSITE, {
    client: atlasClient,
  })

  const saveBasicInfo = async (input: UpdateRetailerProfileInput) => {
    try {
      mutate({
        variables: {
          input,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return saveBasicInfo
}

const UPDATE_COUNTER = atlas`#graphql
   mutation UpdateLiteRetailerCounter(
    $input: UpdateRetailerProfileInput!
  ) {
    updateRetailerProfile(input: $input) {
      id
      profileSnoozeCounter
    }
  }
`

export const useUpdateCounter = () => {
  const [mutate] = useMutation(UPDATE_COUNTER, {
    client: atlasClient,
  })

  const saveCounter = async (input: UpdateRetailerProfileInput) => {
    try {
      mutate({
        variables: {
          input,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return saveCounter
}

const UPDATE_PRIMARY_LOCATION = atlas`#graphql
  mutation UpdateLiteRetailerPrimaryLocation($input: BulkUpsertRetailerLocationsInput!) {
    bulkUpsertRetailerLocations(input: $input) {
      locations {
        id
        type
        name
        isPrimaryLocation
        address1
        address2
        city
        state
        country
        zip
        phone
        clienteleInfo  {
          clienteleAge
          clienteleMale
          clienteleFemale
          clienteleDescriptions{
            id
            name
          }
          clienteleInterests{
            id
            name
          }
        }
        categories {
          id
          name
        }
        carriedBrands {
          id
          accountId
          name
        }
        wholesaleMax
        wholesaleMin
        yearEstablished
      }
    }
  }
`

export const useUpdateAddress = () => {
  const { addToasts } = useNotifications()

  const [mutate] = useMutation(UPDATE_PRIMARY_LOCATION, {
    client: atlasClient,
  })

  const saveLocations = async (
    input: BulkUpsertRetailerLocationsInput,
    step: LiteRetailerRequiredInfoViews,
  ) => {
    try {
      const response = await mutate({
        variables: {
          input,
        },
      })
      const success =
        response?.data?.bulkUpsertRetailerLocations?.locations?.length === 1
      if (success && step === LiteRetailerRequiredInfoViews.BusinessInfo) {
        addToasts([
          {
            type: 'success',
            title: 'Profile Updated!',
            description: 'Thank you for updating your profile information.',
          },
        ])
      }
    } catch (error) {
      console.error(error)
      addToasts([
        {
          type: 'error',
          title: 'Profile Not Updated!',
          description: 'There was an error saving your profile.',
        },
      ])
    }
  }

  return saveLocations
}

const UPDATE_ACCOUNT_PRIVACY = atlas`#graphql
  mutation UpdateAccountPrivacy($input: UpdateAccountPrivacyInput!) {
    updateAccountPrivacy(input: $input) {
      account {
        id
      }
    }
  }
`

export const useUpdateAccountPrivacy = () => {
  const { addToasts } = useNotifications()

  const [mutate] = useMutation(UPDATE_ACCOUNT_PRIVACY, {
    client: atlasClient,
  })

  const updatePrivacy = async (input: UpdateAccountPrivacyInput) => {
    try {
      const response = await mutate({
        variables: {
          input,
        },
      })
      const success = response?.data?.updateAccountPrivacy?.account?.id

      if (success) {
        const toasterTitle = input.privacy
          ? 'Profile Updated!'
          : 'Account Successfully Hidden'
        const toasterDescription = input.privacy
          ? 'Thank you for updating your profile information.'
          : 'Your account has been hidden from unconnected brands.'
        addToasts([
          {
            type: 'success',
            title: toasterTitle,
            description: toasterDescription,
          },
        ])
      }
    } catch (error) {
      console.error(error)
      addToasts([
        {
          type: 'error',
          title: 'Profile Not Updated!',
          description: 'There was an error saving your profile.',
        },
      ])
    }
  }

  return updatePrivacy
}

const SAVE_GOOGLE_ADDRESS_SELECTION = atlas`#graphql
  mutation BulkUpdateRetailerGoogleAddresses(
    $input: [BulkUpdateRetailerGoogleAddressesInput!]!
  ) {
    bulkUpdateRetailerGoogleAddresses(input: $input) {
      retailerGoogleAddressIds
    }
  }
`

export const useSaveGoogleAddressSelection = () => {
  const [mutate] = useMutation(SAVE_GOOGLE_ADDRESS_SELECTION, {
    client: atlasClient,
  })

  const saveGoogleOption = async (
    input: BulkUpdateRetailerGoogleAddressesInput[],
  ) => {
    try {
      mutate({
        variables: {
          input,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return saveGoogleOption
}

const SAVE_IMPERSONATION_FEEDBACK = atlas`#graphql
  mutation UpsertRetailImpersonationFeedback($input: RetailerImpersonationFeedbackInput!) {
    updateRetailerImpersonationFeedback(input: $input) {
      node {
        accountId
      }
    }
  }
`

export const useSaveImpersonationFeedback = () => {
  const { addToasts } = useNotifications()

  const [mutate] = useMutation(SAVE_IMPERSONATION_FEEDBACK, {
    client: atlasClient,
  })

  const saveImpersonationFeedback = async (
    input: RetailerImpersonationFeedbackInput,
  ) => {
    try {
      const response = await mutate({
        variables: {
          input,
        },
      })
      const success =
        response?.data?.updateRetailerImpersonationFeedback?.node?.accountId

      if (success) {
        addToasts([
          {
            type: 'success',
            title: 'Profile Updated!',
            description: 'Thank you for updating your profile information.',
          },
        ])
      }
    } catch (error) {
      console.error(error)
      addToasts([
        {
          type: 'error',
          title: 'Profile Not Updated!',
          description: 'There was an error saving your profile.',
        },
      ])
    }
  }

  return saveImpersonationFeedback
}

const SAVE_PRIMARY_ACCOUNT = atlas`#graphql
  mutation UpdateRetailerPrimaryAccount(
    $input: UpdateRetailerPrimaryAccountInput!
  ) {
    UpdateRetailerPrimaryAccount(input: $input) {
      retailerId
    }
  }
`

export const useUpdatePrimaryAccount = () => {
  const [mutate] = useMutation(SAVE_PRIMARY_ACCOUNT, {
    client: atlasClient,
  })

  const updatePrimaryAccount = async (
    input: UpdateRetailerPrimaryAccountInput,
  ) => {
    try {
      mutate({
        variables: {
          input,
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return updatePrimaryAccount
}
