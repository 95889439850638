import { DEFAULT_DISPLAY_NAME, DEFAULT_UNIQUE_NAME } from './Pages.constants'
import {
  CalculateNewOrder,
  GetDefaultPageName,
  GetNewPageOrderNbr,
  GetStorefrontPanelUrl,
} from './Pages.types'

export const getNewPageOrderNbr: GetNewPageOrderNbr = (pages) => {
  return -Math.abs(
    pages.reduce((acc, curr) => {
      if (curr.orderNbr > 0) return acc
      const negativeCurrentOrder = -Math.abs(curr.orderNbr)
      return -negativeCurrentOrder >= acc ? -negativeCurrentOrder : acc
    }, 0) + 1,
  )
}

export const getDefaultPageName: GetDefaultPageName = (allPages) => {
  const defaultPages = allPages.filter((page) =>
    page.displayName.includes(DEFAULT_DISPLAY_NAME),
  )
  if (defaultPages.length === 0)
    return {
      displayName: DEFAULT_DISPLAY_NAME,
      uniqueName: DEFAULT_UNIQUE_NAME,
    }
  const numberOfRepetitions = defaultPages.reduce((acc, curr) => {
    const currentNumber = Number(curr?.displayName?.match(/\d+/)?.[0]) || 0
    return currentNumber >= acc ? currentNumber : acc
  }, 0)
  const nextNumber = Number(numberOfRepetitions) + 1
  return {
    displayName: `${DEFAULT_DISPLAY_NAME} (${nextNumber})`,
    uniqueName: `${DEFAULT_UNIQUE_NAME}_${nextNumber}`,
  }
}

export const getCopyNumber = (
  nameList: Array<string> = [],
  originalName: string = '',
): number => {
  const copyRegEx = / [copy]+ \d+(?!.*[copy]+ \d)/g
  const rawNames = nameList.map((name) => name.replace(copyRegEx, ''))
  const occurrences = rawNames.filter((name) => name === originalName)
  return occurrences.length
}

export const getCopiedName = (
  name: string = '',
  occurrences: number = 1,
): string => `${name} copy ${occurrences}`

export const generateUniqueName = (name: string): string =>
  name
    .toLowerCase()
    .replace(/\s+/g, ' ')
    .trim()
    .replace(/ /g, '_')
    .replace(/\W/g, '')

export const cleanStorefrontToDuplicate = (
  storefrontToDuplicate: any,
): any => ({
  ...storefrontToDuplicate,
  ...(storefrontToDuplicate?.id && { id: null }),
  ...(storefrontToDuplicate?.rows && {
    rows: storefrontToDuplicate?.rows?.map((row: any) => ({
      ...row,
      id: null,
      elements: row?.elements?.map((elem: any) => ({ ...elem, id: null })),
    })),
  }),
})

export const calculateNewOrder: CalculateNewOrder = (pages, isInMenu) =>
  pages?.map((item, index) => ({
    ...item,
    orderNbr: isInMenu ? index + 1 : -Math.abs(index + 1),
  }))

export const getStorefrontPanelUrl: GetStorefrontPanelUrl = (
  isHome,
  uniqueName,
  fromGlobalBrandId,
) => {
  const [domain] = window.location.href.split('/ra/')
  const url = `${domain}/r/storefront/${fromGlobalBrandId}${
    isHome ? '' : `/${uniqueName}`
  }`
  return url
}
