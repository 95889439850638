import {
  NEW_STYLES,
  RETAILER_PROFILE,
  RETAILER_PUBLIC_CONNECTION,
  STYLES,
} from 'routes/paths'

import { Feature, UserTypeSelector } from '../earlyAccessWidget.types'
import { getRetailerFeatureLink } from '../utils/featureLinks.utils'

export const UNSUBSCRIBE_MANAGE_STYLES_PENDO_ID = 'o2R1JesAgSSmiyXwmn7Tcq1t0OQ'
export const UNSUBSCRIBE_RETAILER_PROFILE_PENDO_ID =
  'cv7C_3F27qwYDFLtghTADb2zw8s'

export const FEATURES: Feature[] = [
  {
    earlyAccessAdminFlag: 'early-access-linesheet-visibility-admin',
    earlyAccessFeatureFlag: 'early-access-linesheet-visibility',
    title: () => 'Linesheet Visibility Enhancements',
    description: () =>
      `We’ve updated our Linesheet Visibility settings to make it easy for you to control who sees your linesheets.<br/><br/>
      Opt-in to gain early access to this improved experience and let us know what you think!`,
    unsubscribePendoGuideId: 'KZY0d6ghIt7QQldbJ7-1UMD_dxc',
    userTypeSpecific: [UserTypeSelector.isProBrand],
    widgetConfigs: [],
  },
  {
    earlyAccessFeatureFlag: 'retailer-profile-brand-visibility',
    title: () => 'New Retailer Profile!',
    description: () =>
      'Discover our updated Retailer Profiles with a cleaner format and larger store images to help you easily evaluate potential retailers. Try it now and share your feedback before it goes live in July. Navigate to a Retailer Profile to see the update!',
    earlyAccessAdminFlag: 'early-access-retailer-profile-migration',
    feedbackButtonId: 'early-access__lead-gen__retailer-profile-visibility',
    unsubscribePendoGuideId: UNSUBSCRIBE_RETAILER_PROFILE_PENDO_ID,
    userTypeSpecific: [
      UserTypeSelector.isProBrand,
      UserTypeSelector.isLiteBrand,
    ],
    oldFeatureLink: () =>
      getRetailerFeatureLink(
        /^\/ra\/profile\/\d+$/i,
        RETAILER_PUBLIC_CONNECTION,
      ),
    newFeatureLink: () =>
      getRetailerFeatureLink(
        /^\/boutiques\/view\/\d+$/gim,
        RETAILER_PROFILE.replace('/:accountId', ''),
      ),
    widgetConfigs: [
      {
        urlRegexp: /^\/Accounts\/home$/gim,
        pushContent: false,
      },
      {
        urlRegexp: /^\/ra\/profile\/\d+$/gim,
        pushContent: false,
      },
      {
        urlRegexp: /^\/boutiques\/view\/\d+$/gim,
        pushContent: false,
      },
    ],
  },
  {
    earlyAccessAdminFlag: 'early-access-lite-brands-navbar-admin',
    earlyAccessFeatureFlag: 'early-access-lite-brands-navbar',
    title: () => 'New Global Navigation',
    description: () =>
      'The new global navigation bar has been refreshed to enhance your user experience and make navigating JOOR even more intuitive and user-friendly. This feature is scheduled to be available for all users April 22.',
    feedbackButtonId: 'widget__feature-option__lite-brand-navbar__feedback',
    unsubscribePendoGuideId: '3cSb-CDCPhCm3r1Hx5Cd4c2jZfQ',
    walkthroughText: 'Click here to see the walkthrough',
    walkthroughId: 'early-access__navbar-lite-brands__walkthrough',
    userTypeSpecific: [UserTypeSelector.isLiteBrand],
    widgetConfigs: [
      {
        urlRegexp: /^\/accounts\/home$/gim,
        pushContent: false,
      },
    ],
  },
  {
    earlyAccessAdminFlag: 'early-access-pro-retailers-navbar-admin',
    earlyAccessFeatureFlag: 'early-access-pro-retailers-navbar',
    title: () => 'New Global Navigation',
    description: () =>
      'The new global navigation bar has been refreshed to enhance your user experience and make navigating JOOR even more intuitive and user-friendly. This feature is scheduled to be available for all users April 22.',
    feedbackButtonId: 'widget__feature-option__pro-retailer-navbar__feedback',
    unsubscribePendoGuideId: '-1Nvl_XW0ntyJR8xDQG7Eyblf58',
    walkthroughText: 'Click here to see the walkthrough',
    walkthroughId: 'early-access__navbar-pro-retailer__walkthrough',
    userTypeSpecific: [UserTypeSelector.isProRetailer],
    widgetConfigs: [
      {
        urlRegexp: /^\/ra\/home$/gim,
        pushContent: false,
      },
    ],
  },
  {
    earlyAccessAdminFlag: 'early-access-manage-styles-admin',
    earlyAccessFeatureFlag: 'early-access-manage-styles',
    title: () => 'Updated Manage Styles Page',
    description: () =>
      'We’ve updated the Manage Styles Page with a more modern-looking UI and improved page loading performance.<br>The functionality, features, and location of elements are essentially the same. This feature is scheduled to be generally available for all users on March 25th.',
    oldFeatureLink: () => STYLES,
    newFeatureLink: () => NEW_STYLES,
    customLink: STYLES,
    linkText: 'Click here to see the existing feature',
    previewImage:
      'https://cdn.jooraccess.com/external/img/early-access/manage-styles-preview.png',
    unsubscribePendoGuideId: UNSUBSCRIBE_MANAGE_STYLES_PENDO_ID,
    // When the widget is pushing content, we need to validate that it's not breaking/hiding something by moving the content
    widgetConfigs: [
      {
        urlRegexp: /^\/ra\/styles$/gim,
        pushContent: true,
      },
      {
        urlRegexp: /^\/accounts\/home$/gim,
        pushContent: false,
        renderConditions: ['brandDashboard'],
      },
      {
        urlRegexp: /^\/styles$|\/styles\/index/gim,
        pushContent: false,
        isPhpPage: true,
      },
    ],
  },
]

export const FEATURE_COUNT_BADGE_COOKIE = 'early-access-badge'
export const FEATURE_COUNT_BADGE_VALUE = 'viewed'
export const FEATURE_COUNT_DEFAULT_VALUE = 0
export const RATE_LIMIT_ERROR_CODE = 'API_ERROR'
export const RATE_LIMIT_ERROR_MESSAGE = 'LaunchDarkly API rate limit reached'
export const CAPACITY_LIMIT_ERROR_CODE = 'FAILED_PRECONDITION'
export const CAPACITY_LIMIT_WAIT_LIST =
  'https://docs.google.com/forms/d/e/1FAIpQLSeQt6floCX54f2R_m40qFiyCs1ZE7Xrfzn6pb5XDcbG6XKlfA/viewform'

export const ERROR_MAX_CAPACITY_TOAST_CONTENT = {
  title: 'The beta is full',
  description: 'We’ve reached capacity for users to test this feature.',
  shouldCloseManually: true,
  action: {
    label: 'Click here to join the waitlist',
    handler: () => {
      window.open(CAPACITY_LIMIT_WAIT_LIST, '_blank')
    },
  },
  className: 'font-size-fix',
}
