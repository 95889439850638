import compose from 'lodash/flowRight'
import get from 'lodash/get'
import { connect } from 'react-redux'

import { INITIAL_DATE } from 'utils/dates'

import { getCurrentUser } from 'store/currentUser/selectors'
import { dismissNews, dismissTimestampedNews } from 'store/news/actions'
import { NEWS_KEYS } from 'store/news/constants'
import { createVisibleNewsSelector } from 'store/news/selectors'

import NewsBanner from './NewsBanner.component'

const visibleNewsSelector = createVisibleNewsSelector(NEWS_KEYS)

const mapStateToProps = (state) => ({
  allVisibleNews: visibleNewsSelector(state),
  lastLogin: get(getCurrentUser(state), 'lastLogin', INITIAL_DATE),
})

const mapDispatchToProps = {
  dismissNews,
  dismissTimestampedNews,
}

const withRedux = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRedux)(NewsBanner)
