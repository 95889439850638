import { PureComponent } from 'react'

import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'

import Result from './Result'
import styles from './Search.less'
import {
  EMPTY_RESULTS_TEXT,
  ENTER_KEY,
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
} from './constants'

class Search extends PureComponent {
  state = {
    value: '',
    isActive: false,
    index: null, // Track index of element in focus for arrow key events.
    maxIndex: null,
  }

  // This is necessary to keep the PureComponent in sync when overriding state with parent component.
  componentDidMount() {
    this.handleSetValueFromProp()
  }

  componentDidUpdate(prevProps) {
    const { results, forcedValue, resetForcedValue } = this.props

    this.handleSetIndexFromProp(prevProps.results.length)
    this.handleSetValueFromProp()
    if (results !== prevProps.results) {
      this.handleSetMaxIndexFromProp()
    }

    if (prevProps.forcedValue !== forcedValue && forcedValue !== null) {
      this.setState({ value: forcedValue })
      resetForcedValue()
    }
  }

  handleKeyPress(event) {
    if (event.key === KEY_ARROW_DOWN) {
      this.handleArrowDown()
    } else if (event.key === KEY_ARROW_UP) {
      this.handleArrowUp()
    }
  }

  handleArrowUp() {
    const { index, maxIndex } = this.state
    if (index - 1 < 1) {
      this.setState({ index: maxIndex })
    } else {
      this.setState({ index: index - 1 })
    }
  }

  handleArrowDown() {
    const { index, maxIndex } = this.state
    if (index + 1 > maxIndex) {
      this.setState({ index: 1 })
    } else {
      this.setState({ index: index + 1 })
    }
  }

  handleSetIndexFromProp = (prevResultsLength) => {
    const { isActive } = this.state
    const { results } = this.props

    if (isActive) {
      if (prevResultsLength === 0 && results.length > 0)
        this.setState({ index: 0 })
      if (prevResultsLength > 0 && results.length === 0)
        this.setState({ index: null })
    }
  }

  handleSetValueFromProp = () => {
    const { value } = this.props
    if (value !== null) this.handleChangeValue(value)
  }

  handleSetMaxIndexFromProp = () => {
    const { results } = this.props
    this.setState({ maxIndex: results.length })
  }

  handleChangeWrapper = (event) => {
    const { onSearchChange } = this.props
    const { value } = event.target
    this.handleChangeValue(value)
    onSearchChange(value)
  }

  handleChangeValue = (value) => this.setState(() => ({ value }))

  handleActionWrapper = (event) => {
    const { handleAction, onResultSelect, results } = this.props
    const { isActive, index, value } = this.state

    if (event.key === ENTER_KEY) {
      if (isActive && index > 0) {
        const resultText = get(
          results,
          `[${index - 1}].title`,
          EMPTY_RESULTS_TEXT,
        )
        const result = { title: resultText }
        if (resultText !== EMPTY_RESULTS_TEXT) {
          // can't select empty results warning
          onResultSelect(event, { result })
          this.setState({ index: null, isActive: false })
        }
      } else {
        handleAction(value)
        this.setState({ index: 0, isActive: false })
      }
    }
  }

  render() {
    // Props seperated by type: Booleans, Functions, Objects/Strings
    // Missing 'filter' prop, 'results' prop for backwards compatibility with SearchV1
    const { disabled, isGray, inputOnly } = this.props
    const { handleAction } = this.props
    const { className, placeholder } = this.props
    let { results } = this.props
    const { index, isActive, value } = this.state
    const resultInFocus = index ? index - 1 : null

    if (results.length < 1 && value.length > 0) {
      results = [{ title: EMPTY_RESULTS_TEXT }]
    }

    const rootStyles = classNames(className, styles.Search, {
      [styles.gray]: isGray,
      [styles.disabled]: disabled,
      [styles.active]: isActive,
    })

    return (
      <div className={rootStyles}>
        <input
          type="text"
          className={styles.input}
          value={this.state.value}
          placeholder={placeholder}
          onChange={this.handleChangeWrapper}
          onKeyUp={this.handleActionWrapper}
          disabled={disabled}
          onBlur={() => {
            window.removeEventListener('keydown', this.handleKeyPress)
            this.setState({ isActive: false, index: null })
          }}
          onFocus={() => {
            window.addEventListener('keydown', this.handleKeyPress.bind(this))
            this.setState({ isActive: true })
          }}
        />
        <Icon
          onClick={() => handleAction(value)}
          name="searchSmall"
          className={classNames(styles.icon, { [styles.disabled]: disabled })}
        />
        {isActive && !inputOnly && (
          <div className={styles.results}>
            {results.map((result, key) => (
              <Result
                inFocus={resultInFocus === key}
                isSelected={result.title === value}
                onResultSelect={this.props.onResultSelect}
                key={`${result.title}`}
                title={result.title}
              />
            ))}
          </div>
        )}
      </div>
    )
  }
}

Search.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onSearchChange: PropTypes.func,
  onResultSelect: PropTypes.func,
  handleAction: PropTypes.func,
  disabled: PropTypes.bool,
  isGray: PropTypes.bool,
  inputOnly: PropTypes.bool,
  results: PropTypes.arrayOf(PropTypes.object),
  forcedValue: PropTypes.string,
  resetForcedValue: PropTypes.func,
}

Search.defaultProps = {
  className: null,
  placeholder: 'Search...',
  value: null,
  onSearchChange: () => {},
  onResultSelect: () => {},
  handleAction: () => {},
  disabled: false,
  isGray: false,
  inputOnly: false,
  results: [],
  forcedValue: null,
  resetForcedValue: () => {},
}

export default Search
