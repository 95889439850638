import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Menu as BaseMenu } from 'semantic-ui-react'

import styles from './ActionBar.less'

const ActionBar = ({ children, className, editMode }) => {
  const editModeClass = classNames({ [styles.editMode]: editMode })
  return (
    <BaseMenu
      className={classNames(styles.actionBar, editModeClass, className)}
      fixed="bottom"
      borderless
    >
      {children}
    </BaseMenu>
  )
}

ActionBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  editMode: PropTypes.bool,
}

ActionBar.defaultProps = {
  children: null,
  className: '',
  editMode: false,
}

ActionBar.Item = BaseMenu.Item

export default ActionBar
