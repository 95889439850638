import { useEffect, useState } from 'react'

import uniqBy from 'lodash/uniqBy'
import PropTypes from 'prop-types'

import Dropdown from 'components/Core/Dropdown'
import Pillbox from 'components/Core/Pillbox/Pillbox'

import styles from './PillDropdown.less'

const PillDropdown = ({
  options,
  initialValues,
  placeholder,
  className,
  disabled,
  loading,
  onChange,
  ...extraProps
}) => {
  const [selectedValues, setSelectedValues] = useState(initialValues)

  useEffect(() => {
    setSelectedValues(initialValues)
  }, [initialValues])

  const onDropdownChange = (value) => {
    const newValue = options.find((option) => option.value === value)
    const newValues = uniqBy([...selectedValues, newValue], 'value')
    setSelectedValues(newValues)
    onChange(newValues)
  }
  const onPillboxClick = (selectedOption) => {
    const newValues = selectedValues.filter(
      (option) => option.value !== selectedOption.value,
    )
    setSelectedValues(newValues)
    onChange(newValues)
  }
  const disableActions = disabled || loading
  return (
    <div className={styles.PillDropdown}>
      <Dropdown
        key={selectedValues?.[0]?.key}
        data-testid="pillDropdown"
        loading={loading}
        disabled={disableActions}
        className={className}
        placeholder={placeholder}
        parentClassName={styles.dropdown}
        options={options}
        selectOnNavigation={false}
        onChange={(_, { value }) => {
          onDropdownChange(value)
        }}
        {...extraProps}
      />
      {selectedValues.map((value) => (
        <Pillbox
          key={value.key}
          label={value.text}
          disabled={disableActions}
          className={styles.pillbox}
          onClick={() => !disableActions && onPillboxClick(value)}
        />
      ))}
    </div>
  )
}

PillDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      text: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  initialValues: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      text: PropTypes.string,
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

PillDropdown.defaultProps = {
  options: [],
  initialValues: [],
  placeholder: '',
  className: '',
  disabled: false,
}

export default PillDropdown
