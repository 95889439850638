import { useMutation, useQuery } from '@apollo/client'

import {
  SetSelectedAccountMutationMutation,
  SetSelectedAccountMutationMutationVariables,
} from '__generated__/atlas-types'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { useRenderToasters } from 'hooks/useRenderToasters'

import { SET_SELECTED_ACCOUNT_MUTATION } from './accountsDropdown.mutations'
import { USER_ACCOUNTS } from './accountsDropdown.queries'
import {
  GrapheneAccount,
  SetSelectedAccount,
} from 'features/NavbarRevamp/navbarRevamp.types'
import { navigateToHome } from 'features/NavbarRevamp/utils/navbar.utils'

export const useUserAccounts = () => {
  const { data } = useQuery(USER_ACCOUNTS)
  const userAccounts: GrapheneAccount[] =
    relayConnectionToArray(data?.viewer?.accounts)?.map(
      (account: GrapheneAccount) => account,
    ) || []

  return userAccounts.sort((a, b) =>
    a.name.toLowerCase() <= b.name.toLowerCase() ? -1 : 1,
  )
}

export const useSetSelectedAccount = () => {
  const [mutate] = useMutation<
    SetSelectedAccountMutationMutation,
    SetSelectedAccountMutationMutationVariables
  >(SET_SELECTED_ACCOUNT_MUTATION, {
    client: atlasClient,
  })
  const { renderErrorToast } = useRenderToasters()

  const setSelectedAccount: SetSelectedAccount = (accountId) => {
    mutate({
      variables: { input: { account_id: accountId } },
    }).then((response) => {
      if (response?.data?.setSelectedAccount?.success) {
        navigateToHome()
      } else {
        renderErrorToast({
          description: response.errors,
          title: 'There was an error setting the selected account',
          shouldCloseManually: true,
        })
      }
    })
  }
  return { setSelectedAccount }
}

export const useOldSetSelectedAccount = () => {
  const [setSelectedAccount] = useMutation(SET_SELECTED_ACCOUNT_MUTATION, {
    client: atlasClient,
  })
  return setSelectedAccount
}
