import { FC } from 'react'

import styled from 'styled-components'

const StyledSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px;
  gap: 16px;
`
const StyledTitle = styled.div`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-medium);
  font-weight: var(--font-weight-medium);
  line-height: 20px;
  letter-spacing: 1.4px;
`
const StyledMessage = styled.div`
  display: inline;
  cursor: auto;
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-regular);
  letter-spacing: 1px;
`

const FourthModalStep: FC = () => (
  <StyledSection>
    <StyledTitle>We are working on it - check your email!</StyledTitle>
    <StyledMessage>
      <span>
        Thanks for merging your accounts. If you do not receive an email within
        the hour, please reach out to{' '}
      </span>
      <a href="mailto:support@joor.com">support@joor.com</a>
      <span>.</span>
    </StyledMessage>
    <StyledMessage>
      <span>
        Closing this message will log you into the destination account you
        selected at the beginning of the merge process.
      </span>
    </StyledMessage>
  </StyledSection>
)

export default FourthModalStep
