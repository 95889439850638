import { Link } from 'react-router-dom'
import styled from 'styled-components'

const MenuLinkWrapper = styled(Link)`
  text-decoration-line: none;
  padding: 5px 8px;
  align-self: center;
  display: flex;
  align-items: center;
  line-height: 16px;
  font-weight: var(--font-weight-medium);
  color: var(--color-primary-100);
  margin: 14px 8px 14px 0;
  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    margin: 13px 7px 13px -1px;
  }
  @media only screen and (max-width: 1160px) {
    margin-right: 2px;
  }
  @media only screen and (max-width: 1100px) {
    margin-right: 0;
  }
`

export default MenuLinkWrapper
