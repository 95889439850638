/* eslint-disable react/prop-types */
import classnames from 'classnames'
import groupBy from 'lodash/groupBy'

import { translateText } from 'utils/sdks/localize'

import { Drilldown } from 'components/Core'

import ArrayOfIdsFilter from './arrayOfIdsFilter'

/*
 * ArrayOfObjectsFilter
 * It's a type of filter that allows multiple selection by ids and code, e.g.
 * categories, fabrication name. Here we get the information about
 * the ids of each type or code we want to retrieve.
 * * * Attributes
 * - objectKey: they key of the code we will use to filter
 * - objectValuesKey: the key for the array of ids to filter
 * - groupByCode: the code by which filters will be grouped by
 */

export default class ArrayOfObjectsFilter extends ArrayOfIdsFilter {
  constructor({
    defaultState,
    objectKey,
    objectValuesKey,
    groupByCode,
    hoverableSelect,
    shouldTranslateValues,
    disabledStyles,
    ...rest
  }) {
    super(rest)
    if (defaultState) {
      this.defaultState = defaultState
    }
    this.objectKey = objectKey
    this.objectValuesKey = objectValuesKey
    this.groupByCode = groupByCode
    this.hoverableSelect = hoverableSelect
    this.shouldTranslateValues = shouldTranslateValues
    this.disabledStyles = disabledStyles
  }

  toGraphQLVariable = (graphQLFilters, { value }) => {
    if (this.isUnselected(value)) {
      return graphQLFilters
    }

    const valueGroupedByCode = groupBy(value, this.groupByCode)
    const formattedValues = Object.keys(valueGroupedByCode).map((code) => {
      const filters = valueGroupedByCode[code]
      return {
        [this.objectKey]: code,
        [this.objectValuesKey]: filters.map((filter) => filter.id),
      }
    })

    return {
      ...graphQLFilters,
      [this.filterKey]: formattedValues,
    }
  }

  renderSelectedFilterHandler = ({
    filterData,
    actionHandler,
    disabled = true,
  }) => {
    const filterOptions = this.getFilterOptions(filterData)

    return (
      <Drilldown
        className={classnames('notranslate', this.styles)}
        disabledClassName={this.disabledStyles}
        data={filterOptions}
        onChange={(evt, { value }) => {
          actionHandler(value)
        }}
        placeholder={translateText('Select a value')}
        disabled={disabled}
        hoverableSelect={this.hoverableSelect}
        shouldTranslate={this.shouldTranslateValues}
      />
    )
  }
}
