import { handleActions } from 'redux-actions'

export const defaultState = {
  downloadTaskIds: [],
}

const reducer = handleActions(
  {
    'FILE_DOWNLOADS/ADD_TASK_ID': (state, { payload: taskId }) => ({
      ...state,
      downloadTaskIds: [taskId, ...state.downloadTaskIds],
    }),
    'FILE_DOWNLOADS/REMOVE_TASK_ID': (state, { payload: taskId }) => ({
      ...state,
      downloadTaskIds: state.downloadTaskIds.filter((item) => item !== taskId),
    }),
  },
  defaultState,
)

export default reducer
