export default {
  chooseOnImport: 'Choose retailer on import',
  createTemplate: 'Create Order Template',
  downloadTemplate: 'Download Template',
  failedDownload:
    'Template could not be downloaded. Please change price type and try again.',
  goBack: 'Go Back to Order Import',
  includeImages: 'Include images',
  selectDoor: 'Search for a door',
  selectEvent: 'Search for an event',
  selectLinesheets: 'Search for a linesheet(s)',
  selectOne: 'Select one',
  selectPriceType: 'Search for a price type',
  selectRetailer: 'Search for a retailer',
  startTyping: 'Start typing to search...',
  successfulDownload: 'Your template has been successfully downloaded.',
  selectOrderType: 'Select order type',
}
