import { Icon } from '@joor/design-system'

import styles from './ProductCard.module.scss'

const ExternalMediaIndicators = ({ showVideo, showVntana, showOrb360 }) => (
  <div className={styles.externalMediaIndicators}>
    {showVideo && (
      <Icon
        iconName="video-on"
        iconSize="small"
        data-testid="video-indicator"
      />
    )}
    {showVntana && (
      <Icon iconName="3d" iconSize="small" data-testid="vntana-indicator" />
    )}
    {showOrb360 && (
      <Icon iconName="360" iconSize="small" data-testid="orb360-indicator" />
    )}
  </div>
)

export default ExternalMediaIndicators
