import { handleActions } from 'redux-actions'

export const defaultState = {
  messages: [],
}

const addNotificationToState = (state, notification) => ({
  ...state,
  messages: [...state.messages, notification],
})

const reducer = handleActions(
  {
    'NOTIFICATION_HANDLER/SUCCESS': (
      state,
      { payload: { message, id, options } },
    ) =>
      addNotificationToState(state, {
        id,
        message,
        type: 'success',
        options,
      }),
    'NOTIFICATION_HANDLER/ERROR': (
      state,
      { payload: { message, id, options } },
    ) =>
      addNotificationToState(state, {
        id,
        message,
        type: 'error',
        options,
      }),
    'NOTIFICATION_HANDLER/DEFAULT': (
      state,
      { payload: { message, id, options } },
    ) =>
      addNotificationToState(state, {
        id,
        message,
        type: options.type,
        options,
      }),
    'NOTIFICATION_HANDLER/RESET': (state, { payload: id }) => ({
      ...state,
      messages: state.messages.filter((notification) => notification.id !== id),
    }),
  },
  defaultState,
)

export default reducer
