import uniqBy from 'lodash/uniqBy'

import ArrayOfIdsFilter from 'utils/filters/arrayOfIdsFilter'
import DateFilter from 'utils/filters/dateFilter'
import { formatNameWithCode } from 'utils/formatters'

export const formatFilters = (appliedFilters) => {
  return {
    showroomCollectionIds: appliedFilters.collectionNames.map(
      (name) => name.id,
    ),
    ownerUserIds: appliedFilters.collectionOwners.map((owner) => owner.id),
    brandIds: appliedFilters.brandNames.map((brand) => brand.id),
    sharedUserIds: appliedFilters.sharedUsers.map((member) => member.id),
    sharedAccountIds: appliedFilters.sharedAccounts.map(
      (account) => account.id,
    ),
    createdDate: {
      startDate: appliedFilters?.createdOn?.startDate,
      endDate: appliedFilters?.createdOn?.endDate,
    },
    modifiedDate: {
      startDate: appliedFilters?.lastModified?.startDate,
      endDate: appliedFilters?.lastModified?.endDate,
    },
  }
}

export const getFilterData = (showroomCollectionListFilters = {}) => ({
  collectionNames: uniqBy(
    showroomCollectionListFilters.showroomCollections,
    'id',
  ).filter((collection) => collection.name),
  brandNames: uniqBy(showroomCollectionListFilters.brands, 'id').filter(
    (brand) => brand.name,
  ),
  collectionOwners: uniqBy(
    showroomCollectionListFilters.ownerUsers,
    'id',
  ).filter((owner) => owner.name),
  sharedUsers: uniqBy(showroomCollectionListFilters.sharedUsers, 'id').filter(
    (user) => user.name,
  ),
  sharedAccounts: uniqBy(
    showroomCollectionListFilters.sharedAccounts,
    'id',
  ).filter((account) => account.name),
})

export const collectionFilters = {
  collectionNames: new ArrayOfIdsFilter({
    filterKey: 'collectionNames',
    label: 'Collection Name',
    dataKey: 'collectionNames',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  brandNames: new ArrayOfIdsFilter({
    filterKey: 'brandNames',
    label: 'Brand Name',
    dataKey: 'brandNames',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  collectionOwners: new ArrayOfIdsFilter({
    filterKey: 'collectionOwners',
    label: 'Creator Name',
    dataKey: 'collectionOwners',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  sharedUsers: new ArrayOfIdsFilter({
    filterKey: 'sharedUsers',
    label: 'Team Members Shared With',
    dataKey: 'sharedUsers',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  sharedAccounts: new ArrayOfIdsFilter({
    filterKey: 'sharedAccounts',
    label: 'Retailers Shared With',
    dataKey: 'sharedAccounts',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  createdOn: new DateFilter({
    filterKey: 'createdOn',
    label: 'Created On',
    dataKey: 'createdOn',
    messages: {
      addDateFilter: 'Add Date as Filter',
    },
  }),
  lastModified: new DateFilter({
    filterKey: 'lastModified',
    label: 'Last Modified',
    dataKey: 'lastModified',
    messages: {
      addDateFilter: 'Add Date as Filter',
    },
  }),
}

export const getFilterSetup = () => [
  {
    key: 'collectionNames',
    title: 'Collection Name',
    noTranslate: true,
    filters: [collectionFilters.collectionNames],
  },
  {
    key: 'brandNames',
    title: 'Brand Name',
    noTranslate: true,
    filters: [collectionFilters.brandNames],
  },
  {
    key: 'collectionOwners',
    title: 'Creator Name',
    noTranslate: true,
    filters: [collectionFilters.collectionOwners],
  },
  {
    key: 'sharedUsers',
    title: 'Team Members Shared With',
    noTranslate: true,
    filters: [collectionFilters.sharedUsers],
  },
  {
    key: 'sharedAccounts',
    title: 'Retailers Shared With',
    noTranslate: true,
    filters: [collectionFilters.sharedAccounts],
  },
  {
    key: 'createdOn',
    title: 'Created On',
    noTranslate: true,
    filters: [collectionFilters.createdOn],
  },
  {
    key: 'lastModified',
    title: 'Last Modified',
    noTranslate: true,
    filters: [collectionFilters.lastModified],
  },
]

export const getPanels = ({
  filterActionHandler,
  selectedFilters,
  filterData,
  isBrandAccount,
}) => {
  // In case we need a subfilter in the future, refer to OrderListFilters.component
  const excludeFilters = isBrandAccount
    ? ['brandNames']
    : ['sharedUsers', 'sharedAccounts']

  const filters = getFilterSetup(isBrandAccount)
    .filter((filter) => !excludeFilters?.includes(filter.key))
    .map((panelInfo) => {
      const {
        dataKey,
        renderFilterPanelHandler,
        showFilterHandler,
      } = panelInfo.filters[0]
      const actionHandler = (filterItem) =>
        filterActionHandler({ dataKey, ...filterItem })

      return renderFilterPanelHandler({
        filterData,
        selectedFilters,
        actionHandler,
        showFilterHandler,
        noTranslate: panelInfo.noTranslate,
      })
    })

  return filters
}
