import { useEffect } from 'react'

import { useAuth } from 'context/AuthProvider'

interface PrivateRouteProps {
  children: JSX.Element
}
const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { isAuthenticated, isInitialized, login } = useAuth()
  useEffect(() => {
    if (isInitialized && !isAuthenticated) {
      login()
    }
  }, [isAuthenticated, isInitialized, login])

  return isAuthenticated ? children : null
}

export default PrivateRoute
