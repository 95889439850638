export const RESET_SELECTED_STYLE_COLOR_FOR_REPLACEMENT =
  'CANCEL_STYLE/RESET_SELECTED_STYLE_COLOR_FOR_REPLACEMENT'

export const SELECT_STYLE_COLOR_FOR_REPLACEMENT =
  'CANCEL_STYLE/SELECT_STYLE_COLOR_FOR_REPLACEMENT'

export const RESET_SELECTED_STYLE_COLORS_FOR_CANCELLATION =
  'CANCEL_STYLE/RESET_SELECTED_STYLE_COLORS_FOR_CANCELLATION'

export const TOGGLE_STYLE_COLOR_FOR_CANCELLATION =
  'CANCEL_STYLE/TOGGLE_STYLE_COLOR_FOR_CANCELLATION'

export const TOGGLE_ORDER = 'CANCEL_STYLE/TOGGLE_ORDER'

export const TOGGLE_ALL_ORDERS_IN_PAGE =
  'CANCEL_STYLE/TOGGLE_ALL_ORDERS_IN_PAGE'

export const RESET_SELECTED_ORDERS = 'CANCEL_STYLE/RESET_SELECTED_ORDERS'
