import { Mutation } from '@apollo/client/react/components'
import get from 'lodash/get'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

const AddToOrderMutation = atlas`#graphql
  mutation addUnitsInProgress($input: AddUnitsInProgressInput!) {
    addUnitsInProgress(input: $input) {
      createdOrderIds
    }
  }
`

export const withAddToOrderMutation = (WrappedComponent) => (props) => (
  <Mutation mutation={AddToOrderMutation} client={atlasClient}>
    {(mutate) => {
      const handleAddToOrder = ({
        orderInfo,
        skuQuantities,
        bulkQuantities,
        casepackQuantities,
      }) => {
        const { warehouseId, ...rest } = orderInfo
        const { accountDefaultWarehouse, brandWarehouses } = props
        const defaultWarehouseId =
          warehouseId ||
          get(accountDefaultWarehouse, 'id') ||
          get(brandWarehouses, '[0].id')
        return mutate({
          variables: {
            input: {
              orderInfo: {
                ...rest,
                warehouseId: defaultWarehouseId,
              },
              skuQuantities,
              bulkQuantities,
              casepackQuantities,
            },
          },
        })
      }
      return <WrappedComponent {...props} handleAddToOrder={handleAddToOrder} />
    }}
  </Mutation>
)
