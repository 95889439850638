import compose from 'lodash/flowRight'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import {
  changeBrandSearchText,
  changeCollectionSearchText,
  setBrandId,
} from 'store/orderList/retailerStartOrderModal/actions'
import {
  getBrandId,
  getBrandSearchText,
} from 'store/orderList/retailerStartOrderModal/selectors'
import {
  applySelectedFilters,
  setBrand,
  setDoors,
  setFilter,
  setGroupByTagInfo,
  setOrderPriceType,
  setSearchText,
  setSortByTagInfo,
  toggleDeselectAllFilters,
} from 'store/shop/actions'

import { withAnalytics } from './RetailerStartOrderModal.analytics'
import RetailerStartOrderModal from './RetailerStartOrderModal.component'
import withFormatter from './RetailerStartOrderModal.formatter'
import {
  withConnectedAccounts,
  withPriceTypes,
  withUserConnectedAccounts,
} from './RetailerStartOrderModal.queries'

const mapStateToProps = (state) => ({
  brandSearchText: getBrandSearchText(state),
  brandId: getBrandId(state),
})

const mapDispatchToProps = {
  changeBrandSearchText,
  setBrandId,
  setBrand,
  setFilter,
  changeCollectionSearchText,
  toggleDeselectAllFilters,
  applySelectedFilters,
  setSearchText,
  setOrderPriceType,
  setGroupByTagInfo,
  setSortByTagInfo,
  setDoors,
}

const withRedux = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withRouter,
  withRedux,
  withConnectedAccounts,
  withUserConnectedAccounts,
  withPriceTypes,
  withFormatter,
  withAnalytics,
)(RetailerStartOrderModal)
