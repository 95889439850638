import PropTypes from 'prop-types'

export const ColorPropType = PropTypes.shape({
  colorName: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
})

export const SwatchPropType = PropTypes.shape({
  colorNumber: PropTypes.string,
  url: PropTypes.string,
})

export const SwatchInfoPropType = PropTypes.shape({
  color: ColorPropType,
  swatch: SwatchPropType,
})

export const ProductPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  code: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.string),
  swatchesInfo: PropTypes.arrayOf(SwatchInfoPropType),
})
