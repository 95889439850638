import styled from 'styled-components'

import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

export const AddressSelectionTestIds = {
  Component: 'address-selection-component',
} as const

type AddressElementProps = {
  address?: Partial<RetailerProfileLocation>
  website?: string
}

const StyledContainer = styled.div`
  margin: 16px 0 0 4px;
  padding-left: 21px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-base);
  font-weight: var(--font-weight-regular);
  line-height: 16px;
  letter-spacing: 1px;
`
const StyledAddress = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledWebsite = styled.div`
  word-break: break-word;
`

const getCityLine = (city: string, state?: string) => {
  if (!state) {
    return city
  }
  return `${city}, ${state}`
}

const getCountryLine = (country: string, zip?: string) => {
  if (!zip) {
    return country
  }
  return `${zip}, ${country}`
}

const AddressElement: React.FC<AddressElementProps> = ({
  address,
  website,
}) => {
  return (
    <StyledContainer>
      <StyledAddress>
        <div>{address?.address1}</div>
        <div>{address?.address2}</div>
        <div>{getCityLine(address?.city ?? '', address?.state ?? '')}</div>
        <div>{getCountryLine(address?.country ?? '', address?.zip ?? '')}</div>
      </StyledAddress>
      <div>{address?.phone}</div>
      <StyledWebsite>{website}</StyledWebsite>
    </StyledContainer>
  )
}

export default AddressElement
