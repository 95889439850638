import { Button } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { generatePath } from 'react-router-dom'
import styled from 'styled-components'

import {
  allowManagePayments,
  getAccountId,
  isAccountTypeRetailer,
  isLiteBrand,
} from 'store/currentUser/selectors'

import {
  ACCOUNTS_HOME,
  ANALYTICS_JOOR_PAY_REPORT_DASHBOARD,
  CUSTOM_REPORTS,
  DELIVERY_REPORT,
  DEMO_REQUEST,
  NEW_PRODUCTION,
  NEW_SALES_SUMMARY,
  NEW_STYLE_DETAIL,
  REPORTS,
} from 'routes/paths'

import {
  IReportsDropdownSelectors,
  IReportsMenu,
} from '../../navbarRevamp.types'
import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import messages from 'features/NavbarRevamp/constants/navbarRevamp.messages'
import { ReportsDropdownIds } from 'features/NavbarRevamp/navbarRevamp.ids'

const NavbarReportsDropdownContainer = styled.div`
  display: flex;
`
const ButtonWrapper = styled.div`
  text-align: center;
  border-top: 1px solid var(--color-primary-400);
  padding-top: 16px;
`

const ReportsMenu = (IReportsMenu: IReportsMenu): JSX.Element => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      message={messages.salesSummary}
      to={NEW_SALES_SUMMARY}
      id={ReportsDropdownIds.ReportsOptionSalesSummary}
    />
    <DropdownMenuTextLink
      message={messages.styleDetail}
      to={NEW_STYLE_DETAIL}
      id={ReportsDropdownIds.ReportsOptionStyleDetail}
    />
    <DropdownMenuTextLink
      message={messages.productionReport}
      to={NEW_PRODUCTION}
      id={ReportsDropdownIds.ReportsOptionProductionReport}
    />
    {IReportsMenu.deliveryReportPoc && (
      <DropdownMenuTextLink
        message={messages.deliveryReport}
        to={generatePath(DELIVERY_REPORT, { brandId: IReportsMenu.accountId })}
        id={ReportsDropdownIds.ReportsOptionDelivery}
        testId={ReportsDropdownIds.ReportsOptionDelivery}
      />
    )}
    {IReportsMenu.reportingConfigurations && (
      <DropdownMenuTextLink
        message={messages.customReports}
        to={CUSTOM_REPORTS}
        id={ReportsDropdownIds.ReportsOptionCustomReports}
      />
    )}
    {IReportsMenu.canSeePaymentReport && (
      <DropdownMenuTextLink
        message={messages.joorPayReports}
        to={ANALYTICS_JOOR_PAY_REPORT_DASHBOARD}
        id={ReportsDropdownIds.ReportsOptionJOORPayReports}
      />
    )}
    <DropdownMenuTextLink
      message={messages.classicReports}
      to={REPORTS}
      id={ReportsDropdownIds.ReportsOptionClassicReports}
    />
  </DropdownMenuGroup>
)

const DemoReportsMenu = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={ACCOUNTS_HOME}
      target={DEMO_REQUEST}
      message={messages.isJoorProFeature}
      id={ReportsDropdownIds.ReportsOptionProFeature}
      testId={ReportsDropdownIds.ReportsOptionProFeature}
    />
    <ButtonWrapper>
      <Button
        id={ReportsDropdownIds.ReportsOptionProFeatureLearnMore}
        onClick={() => {
          window.open(DEMO_REQUEST)
        }}
      >
        {messages.learnMore}
      </Button>
    </ButtonWrapper>
  </DropdownMenuGroup>
)

const NavbarReportsDropdown = () => {
  const {
    deliveryReportPoc,
    reportingConfigurations,
    joorPayAnalyticsReport,
  } = useFlags()
  const {
    accountId,
    canManagePayments,
    isRetailerAccount,
    isLiteBrandAccount,
  } = useSelector<object, IReportsDropdownSelectors>((state) => ({
    accountId: getAccountId(state),
    canManagePayments: allowManagePayments(state),
    isRetailerAccount: isAccountTypeRetailer(state),
    isLiteBrandAccount: isLiteBrand(state),
  }))

  if (isRetailerAccount) {
    return null
  }
  const canSeePaymentReport = joorPayAnalyticsReport && canManagePayments
  return (
    <NavbarReportsDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.reports}
            id={ReportsDropdownIds.ReportsTrigger}
          />
        }
      >
        {isLiteBrandAccount ? (
          <DemoReportsMenu />
        ) : (
          <ReportsMenu
            deliveryReportPoc={deliveryReportPoc}
            accountId={accountId}
            reportingConfigurations={reportingConfigurations}
            canSeePaymentReport={canSeePaymentReport}
          />
        )}
      </NavbarDropdown>
    </NavbarReportsDropdownContainer>
  )
}

export default NavbarReportsDropdown
