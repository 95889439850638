import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Divider, Switch, Tooltip } from 'components/Core'
import Button from 'components/Core/ButtonV2/Button'
import { WHITE } from 'components/Core/Tooltip/Tooltip'

import withAnalytics from '../RowSettings.analytics'
import styles from './BrandSettingsTooltip.module.scss'
import { DEFAULT_OPTIONS, LEFT, POSITION_OPTIONS } from './BrandTooltipVariants'
import RadioSelector from 'features/Storefront/DragDropTemplate/Row/RadioSelector/RadioSelector'

const TRACKER_TITLE = 'About the Brand Settings'

const BrandSettingsTooltip = ({
  isOpen,
  onApplyChanges,
  rowItem,
  switchList,
  top,
  trackRowSettings,
}) => {
  const [newSettings, setNewSettings] = useState({ position: LEFT })

  const defaultSettings = rowItem?.settings
  useEffect(() => {
    setNewSettings({
      ...DEFAULT_OPTIONS,
      ...defaultSettings,
    })
  }, [defaultSettings])

  const content = (
    <>
      <h3 className={styles.title}>About the Brand Settings</h3>
      <p>This section pulls information from your Brand Details section.</p>
      <RadioSelector
        onClick={(_, { value }) => {
          setNewSettings({ ...newSettings, position: value })
        }}
        radioOptions={POSITION_OPTIONS}
        radioTitle="Profile Image Position"
        selectedRadio={newSettings?.position}
        className={styles.subtitle}
      />
      {switchList &&
        switchList.map((switchEl) => (
          <div key={switchEl?.id} className={styles.switch}>
            <Switch
              id={switchEl?.id}
              onChange={() =>
                setNewSettings((value) => ({
                  ...value,
                  [switchEl?.id]: !value[switchEl?.id],
                }))
              }
              disabled={false}
              checked={newSettings?.[switchEl?.id]}
            />
            <span>{switchEl?.label}</span>
          </div>
        ))}
      <Divider />
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.smallButton}
          onClick={() => onApplyChanges(rowItem)}
        >
          Cancel
        </Button>
        <Button
          className={styles.mediumButton}
          onClick={() => {
            const { showTitle: _showTitle, ...trackerSettings } = newSettings
            trackRowSettings(TRACKER_TITLE, trackerSettings)
            onApplyChanges({
              ...rowItem,
              settings: newSettings,
            })
          }}
        >
          Apply
        </Button>
      </div>
    </>
  )

  return (
    <Tooltip
      className={styles.BrandSettingsTooltip}
      style={{
        top: `${top + 100}px`,
        right: '10px',
      }}
      content={content}
      open={isOpen}
      color={WHITE}
    />
  )
}

BrandSettingsTooltip.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onApplyChanges: PropTypes.func.isRequired,
  top: PropTypes.number.isRequired,
  switchSettings: PropTypes.object,
  dropdownSettings: PropTypes.object,
  radioSettings: PropTypes.object,
}

BrandSettingsTooltip.defaultProps = {
  switchSettings: null,
  dropdownSettings: null,
  radioSettings: null,
}

export default withAnalytics(BrandSettingsTooltip)
