import { useState } from 'react'

import classNames from 'classnames'
import map from 'lodash/map'
import size from 'lodash/size'
import slice from 'lodash/slice'
import PropTypes from 'prop-types'

import Checkbox from 'components/Core/Checkbox/Checkbox'
import Icon from 'components/Core/Icon/Icon'
import Swatch from 'components/Core/Swatch/Swatch'

import styles from './SwatchCarousel.less'

const getDisplayedSwatches = ({ swatchesInfo, initialPosition, endPosition }) =>
  slice(swatchesInfo, initialPosition, endPosition)

const SwatchCarousel = ({
  swatchesInfo,
  className,
  swatchesInView,
  onClick,
  selectedColorId,
  displayCheckboxes,
  checkboxOnClick,
  selectedCheckboxes,
  disabledCheckboxes,
}) => {
  const [initialPosition, setInitialPosition] = useState(0)
  const [endPosition, setEndPosition] = useState(swatchesInView)

  const swatchesLength = size(swatchesInfo)
  const displayedSwatches = getDisplayedSwatches({
    swatchesInfo,
    initialPosition,
    endPosition,
  })
  const showLeftArrow = swatchesLength > swatchesInView && initialPosition > 0
  const showRightArrow =
    swatchesLength > swatchesInView && swatchesLength >= endPosition
  const conditionalClassNames = {
    [styles.noLeftPadding]: showLeftArrow,
    [styles.noRightPadding]: showRightArrow,
  }

  return (
    <div
      className={classNames(
        className,
        styles.SwatchCarousel,
        conditionalClassNames,
      )}
      data-testid="swatchCarousel"
    >
      {showLeftArrow && (
        <Icon
          id="leftArrow"
          name="dropup"
          className={styles.leftArrow}
          onClick={() => {
            setInitialPosition(initialPosition - swatchesInView)
            setEndPosition(initialPosition)
          }}
        />
      )}
      {map(displayedSwatches, (displayedSwatch) => {
        const { color, swatch, crossed } = displayedSwatch
        return (
          <div
            key={color.id}
            className={classNames(styles.colorSwatch, {
              [styles.checkboxHeight]: displayCheckboxes,
            })}
          >
            <div className={styles.swatchContainer}>
              <Swatch
                className={classNames({
                  [styles.selectedSwatch]: color.id === selectedColorId,
                })}
                color={color}
                swatch={swatch}
                width={20}
                height={20}
                tooltipPosition="bottom center"
                onClick={onClick}
                crossed={crossed}
                small
              />
            </div>
            {displayCheckboxes && (
              <div className={styles.checkboxContainer}>
                <Checkbox
                  onClick={() => checkboxOnClick(displayedSwatch)}
                  value={color.id}
                  checked={!!selectedCheckboxes[color.id]}
                  disabled={disabledCheckboxes.includes(color.id)}
                />
              </div>
            )}
          </div>
        )
      })}
      {showRightArrow && (
        <Icon
          id="rightArrow"
          name="dropup"
          className={styles.rightArrow}
          onClick={() => {
            setInitialPosition(endPosition)
            setEndPosition(endPosition + swatchesInView)
          }}
        />
      )}
    </div>
  )
}

SwatchCarousel.propTypes = {
  swatchesInfo: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.shape({
        id: PropTypes.string.isRequired,
        colorName: PropTypes.string,
        images: PropTypes.arrayOf(PropTypes.string),
      }).isRequired,
      swatch: PropTypes.shape({
        colorNumber: PropTypes.string,
        url: PropTypes.string,
      }),
      crossed: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
  swatchesInView: PropTypes.number,
  onClick: PropTypes.func,
  selectedColorId: PropTypes.string,
  displayCheckboxes: PropTypes.bool,
  displayProductCode: PropTypes.bool,
  checkboxOnClick: PropTypes.func,
  selectedCheckboxes: PropTypes.object,
  disabledCheckboxes: PropTypes.array,
}

SwatchCarousel.defaultProps = {
  swatchesInfo: [],
  className: '',
  swatchesInView: 11,
  onClick: () => {},
  selectedColorId: '',
  displayCheckboxes: false,
  displayProductCode: true,
  checkboxOnClick: () => {},
  selectedCheckboxes: {},
  disabledCheckboxes: [],
}
export default SwatchCarousel
