export const actionsPrefix = 'PRODUCT_DETAIL_MODAL'

export const UPDATE_CELL_VALUE = `${actionsPrefix}/UPDATE_CELL_VALUE`
export const INIT_TABLE_STRUCTURE = `${actionsPrefix}/INIT_TABLE_STRUCTURE`
export const INIT_TABLE_TOTAL_ROW_STRUCTURE = `${actionsPrefix}/INIT_TABLE_TOTAL_ROW_STRUCTURE`
export const RESET_TABLE_TO_DEFAULT_STATE = `${actionsPrefix}/RESET_TABLE_TO_DEFAULT_STATE`
export const SET_SELECTED_TAB_ID = `${actionsPrefix}/SET_SELECTED_TAB_ID`
export const INIT_TABLE_ROW = `${actionsPrefix}/INIT_TABLE_ROW`
export const SET_WAREHOUSE_DELIVERY_DATE = `${actionsPrefix}/SET_WAREHOUSE_DELIVERY_DATE`
export const SET_WAREHOUSE_ID = `${actionsPrefix}/SET_WAREHOUSE_ID`
export const SET_ROW_TO_COPY = `${actionsPrefix}/SET_ROW_TO_COPY`
export const PASTE_TO_ROW = `${actionsPrefix}/PASTE_TO_ROW`
