import { FONTS } from 'features/Storefront/EditionNavbar/Sections/TextSettings/TextSettings.constants'

const getQuillFonts = (fonts) => {
  let quillFonts = {}
  for (const f of fonts) {
    quillFonts[f.replaceAll(' ', '-').toLowerCase()] = f
  }
  return quillFonts
}

export const QUILL_FONTS = getQuillFonts(FONTS)

export const SIZE_WHITELIST = [
  '8px',
  '9px',
  '10px',
  '11px',
  '12px',
  '14px',
  '16px',
  '18px',
  '24px',
  '26px',
  '30px',
  '36px',
  '48px',
  '60px',
  '72px',
  '96px',
]
