import uniqueId from 'lodash/uniqueId'

import BrandSettingsTooltip from './DragDropTemplate/Row/tooltips/BrandSettingsTooltip/BrandSettingsTooltip'
import { INFO_TOOLTIP_SETTINGS } from './DragDropTemplate/Row/tooltips/BrandSettingsTooltip/BrandTooltipVariants'
import GallerySettingsTooltip from './DragDropTemplate/Row/tooltips/GallerySettingsTooltip/GallerySettingsTooltip.jsx'
import {
  DOCUMENT_GALLERY_SETTINGS,
  IMAGE_GALLERY_SETTINGS,
  LINESHEET_GALLERY_SETTINGS,
  MEDIA_BANNER_SETTINGS,
  PRODUCT_GALLERY_SETTINGS,
  SHOWROOM_GALLERY_SETTINGS,
} from './DragDropTemplate/Row/tooltips/GallerySettingsTooltip/GalleryTooltipVariants.js'
import {
  ABOUT_BRAND,
  ABOUT_SHOWROOM,
  ASPECT_LANDSCAPE,
  ASPECT_PORTRAIT,
  ASPECT_SQUARE,
  DOCUMENT_GALLERY,
  DOCUMENT_WIDGET,
  EIGHT_EQUAL,
  FOUR_EQUAL,
  IMAGE_GALLERY,
  IMAGE_WIDGET,
  INFO_WIDGET,
  LINESHEET_GALLERY,
  LINESHEET_WIDGET,
  MEDIA_BANNER,
  ONE_FULL_WIDTH,
  ONE_REGULAR,
  PRODUCT_GALLERY,
  PRODUCT_WIDGET,
  SHOWROOM_BRAND_GALLERY,
  SHOWROOM_BRAND_WIDGET,
  TEXT_DEFAULT_OVERLAY,
  TEXT_WIDGET,
  THREE_EQUAL,
  TWO_EQUAL,
} from './constants'

export const generateStorefrontTemplate = () => ({
  id: uniqueId('LOCAL_STOREFRONT_'),
  rows: [
    {
      id: uniqueId(`LOCAL_${ONE_FULL_WIDTH}_`),
      template: ONE_FULL_WIDTH,
      rowType: MEDIA_BANNER,
      elements: [
        {
          id: uniqueId(`LOCAL_${IMAGE_WIDGET}`),
          elementType: IMAGE_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${TEXT_WIDGET}`),
          elementType: TEXT_WIDGET,
          content: JSON.stringify(TEXT_DEFAULT_OVERLAY),
        },
      ],
      tooltip: {
        settings: MEDIA_BANNER_SETTINGS,
        component: GallerySettingsTooltip,
      },
      settings: {
        showTitle: true,
        position: 'OVERLAY',
      },
      title: '',
    },
    {
      id: uniqueId(`LOCAL_${ONE_REGULAR}_`),
      template: ONE_REGULAR,
      rowType: ABOUT_BRAND,
      title:
        '{"ops":[{"insert":" Title"},{"attributes":{"line-height":"1","align":"center","styles":"title1"},"insert":"\\n"}]}',
      settings: {
        showTitle: false,
      },
      elements: [
        {
          id: uniqueId(`LOCAL_${INFO_WIDGET}_`),
          elementType: INFO_WIDGET,
          content: {},
        },
      ],
      tooltip: {
        component: BrandSettingsTooltip,
        settings: INFO_TOOLTIP_SETTINGS,
      },
    },
    {
      id: uniqueId(`LOCAL_${THREE_EQUAL}_`),
      template: THREE_EQUAL,
      rowType: IMAGE_GALLERY,
      title:
        '{"ops":[{"insert":"Section Title"},{"attributes":{"line-height":"1","align":"center","styles":"title1"},"insert":"\\n"}]}',
      settings: {
        showTitle: true,
        aspect: ASPECT_PORTRAIT,
      },
      elements: [
        {
          id: uniqueId(`LOCAL_${IMAGE_WIDGET}_`),
          elementType: IMAGE_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${IMAGE_WIDGET}_`),
          elementType: IMAGE_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${IMAGE_WIDGET}_`),
          elementType: IMAGE_WIDGET,
          content: {},
        },
      ],
      tooltip: {
        component: GallerySettingsTooltip,
        settings: IMAGE_GALLERY_SETTINGS,
      },
    },
    {
      id: uniqueId(`LOCAL_${FOUR_EQUAL}_`),
      template: FOUR_EQUAL,
      rowType: PRODUCT_GALLERY,
      title:
        '{"ops":[{"insert":"Section Title"},{"attributes":{"line-height":"1","align":"center","styles":"title1"},"insert":"\\n"}]}',
      settings: {
        showTitle: true,
      },
      elements: [
        {
          id: uniqueId(`LOCAL_${PRODUCT_WIDGET}_`),
          elementType: PRODUCT_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${PRODUCT_WIDGET}_`),
          elementType: PRODUCT_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${PRODUCT_WIDGET}_`),
          elementType: PRODUCT_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${PRODUCT_WIDGET}_`),
          elementType: PRODUCT_WIDGET,
          content: {},
        },
      ],
      tooltip: {
        component: GallerySettingsTooltip,
        settings: PRODUCT_GALLERY_SETTINGS,
      },
    },
    {
      id: uniqueId(`LOCAL_${TWO_EQUAL}_`),
      template: TWO_EQUAL,
      rowType: LINESHEET_GALLERY,
      title:
        '{"ops":[{"insert":"Section Title"},{"attributes":{"line-height":"1","align":"center","styles":"title1"},"insert":"\\n"}]}',
      settings: {
        showTitle: true,
        aspect: ASPECT_LANDSCAPE,
      },
      elements: [
        {
          id: uniqueId(`LOCAL_${LINESHEET_WIDGET}_`),
          elementType: LINESHEET_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${LINESHEET_WIDGET}_`),
          elementType: LINESHEET_WIDGET,
          content: {},
        },
      ],
      tooltip: {
        component: GallerySettingsTooltip,
        settings: LINESHEET_GALLERY_SETTINGS,
      },
    },
    {
      id: uniqueId(`LOCAL_${TWO_EQUAL}_`),
      template: TWO_EQUAL,
      rowType: DOCUMENT_GALLERY,
      title:
        '{"ops":[{"insert":"Section Title"},{"attributes":{"line-height":"1","align":"center","styles":"title1"},"insert":"\\n"}]}',
      settings: {
        showTitle: true,
        aspect: ASPECT_LANDSCAPE,
      },
      elements: [
        {
          id: uniqueId(`LOCAL_${DOCUMENT_WIDGET}_`),
          elementType: DOCUMENT_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${DOCUMENT_WIDGET}_`),
          elementType: DOCUMENT_WIDGET,
          content: {},
        },
      ],
      tooltip: {
        component: GallerySettingsTooltip,
        settings: DOCUMENT_GALLERY_SETTINGS,
      },
    },
  ],
})
export const generateShowroomTemplate = () => ({
  id: uniqueId('LOCAL_SHOWROOM_'),
  rows: [
    {
      id: uniqueId(`LOCAL_${ONE_FULL_WIDTH}_`),
      template: ONE_FULL_WIDTH,
      rowType: MEDIA_BANNER,
      elements: [
        {
          id: uniqueId(`LOCAL_${IMAGE_WIDGET}`),
          elementType: IMAGE_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${TEXT_WIDGET}`),
          elementType: TEXT_WIDGET,
          content: JSON.stringify(TEXT_DEFAULT_OVERLAY),
        },
      ],
      tooltip: {
        settings: MEDIA_BANNER_SETTINGS,
      },
      settings: {
        component: GallerySettingsTooltip,
        showTitle: true,
        position: 'OVERLAY',
      },
      title: '',
    },
    {
      id: uniqueId(`LOCAL_${ONE_REGULAR}_`),
      template: ONE_REGULAR,
      rowType: ABOUT_SHOWROOM,
      title:
        '{"ops":[{"insert":"Title"},{"attributes":{"line-height":"1","align":"center","styles":"title1"},"insert":"\\n"}]}',
      settings: {
        showTitle: false,
      },
      elements: [
        {
          id: uniqueId(`LOCAL_${INFO_WIDGET}_`),
          elementType: INFO_WIDGET,
          content: {},
        },
      ],
      tooltip: {
        component: BrandSettingsTooltip,
        settings: INFO_TOOLTIP_SETTINGS,
      },
    },
    {
      id: uniqueId(`LOCAL_${THREE_EQUAL}_`),
      template: THREE_EQUAL,
      rowType: SHOWROOM_BRAND_GALLERY,
      title:
        '{"ops":[{"insert":"Section Title"},{"attributes":{"line-height":"1","align":"center","styles":"title1"},"insert":"\\n"}]}',
      settings: {
        showTitle: true,
        aspect: ASPECT_SQUARE,
      },
      elements: [
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
      ],
      tooltip: {
        component: GallerySettingsTooltip,
        settings: SHOWROOM_GALLERY_SETTINGS,
      },
    },
    {
      id: uniqueId(`LOCAL_${EIGHT_EQUAL}_`),
      template: EIGHT_EQUAL,
      rowType: SHOWROOM_BRAND_GALLERY,
      title:
        '{"ops":[{"insert":"Section Title"},{"attributes":{"line-height":"1","align":"center","styles":"title1"},"insert":"\\n"}]}',
      settings: {
        showTitle: true,
        aspect: ASPECT_SQUARE,
      },
      elements: [
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
        {
          id: uniqueId(`LOCAL_${SHOWROOM_BRAND_WIDGET}_`),
          elementType: SHOWROOM_BRAND_WIDGET,
          content: {},
        },
      ],
      tooltip: {
        component: GallerySettingsTooltip,
        settings: SHOWROOM_GALLERY_SETTINGS,
      },
    },
  ],
})
