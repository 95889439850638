import classNames from 'classnames'
import isString from 'lodash/isString'
import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'
import Cell from 'components/Core/Table/Cell'

import styles from './HeaderCell.less'

export const ASCENDING = 'ascending'
export const DESCENDING = 'descending'

const HeaderCell = ({ children, className, ...props }) => {
  const { as, sortDirection, isSorted, thickBorder, ...rest } = props
  const classes = classNames(styles.HeaderCell, className, {
    [styles.sorted]: isSorted,
    [styles.withIcon]: sortDirection,
    [styles.truncate]: isString(children),
  })

  const iconStyle = {
    float: 'right',
    position: 'absolute',
    right: 0,
    top: '40%',
  }

  if (sortDirection === ASCENDING) {
    iconStyle.transform = 'rotate(180deg)'
  }

  const disableTooltip = typeof children !== 'string'

  const icon = sortDirection && <Icon name="sortArrow" style={iconStyle} />

  return (
    <Cell
      {...rest}
      disableTooltip={disableTooltip}
      as={as}
      className={classes}
      icon={icon}
      thickBorder={thickBorder}
    >
      {children}
    </Cell>
  )
}

HeaderCell.propTypes = {
  children: PropTypes.node,
  as: PropTypes.string,
  isSorted: PropTypes.bool,
  sortDirection: PropTypes.oneOf([ASCENDING, DESCENDING, null]),
  width: PropTypes.string,
  className: PropTypes.string,
  thickBorder: PropTypes.bool,
}

HeaderCell.defaultProps = {
  children: null,
  as: 'th',
  isSorted: false,
  sortDirection: null,
  width: 'small',
  className: '',
  thickBorder: false,
}

export default HeaderCell
