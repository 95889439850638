import { useMutation } from '@apollo/client'
import { useNotifications } from '@joor/design-system'

import {
  AcceptRequestConnectionDocument,
  AcceptRequestConnectionInput,
  LGConnectionStatus,
} from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

atlas`#graphql
  mutation AcceptRequestConnection($input: AcceptRequestConnectionInput!) {
    acceptRequestConnection(input: $input) {
    brandId
      retailerId
      connectionStatus
      error
    }
  }
`

export const useAcceptConnection = () => {
  const { addToasts } = useNotifications()

  const [acceptConnection] = useMutation(AcceptRequestConnectionDocument, {
    client: atlasClient,
  })
  const handleAcceptConnection = async ({
    input,
    retailerName,
  }: {
    input: AcceptRequestConnectionInput
    retailerName: string
  }) => {
    try {
      const result = await acceptConnection({
        variables: {
          input,
        },
      })
      const success =
        result?.data?.acceptRequestConnection?.connectionStatus ===
        LGConnectionStatus.CONNECTED
      if (success) {
        addToasts([
          {
            type: 'success',
            title: 'Success!',
            description: `You are now connected to ${retailerName}`,
          },
        ])
      } else {
        addToasts([
          {
            type: 'error',
            title: 'Account not connected!',
            description: 'There was an error connecting your accounts.',
          },
        ])
      }
      return success
    } catch (error) {
      addToasts([
        {
          type: 'error',
          title: 'Account not connected!',
          description: 'There was an error connecting your accounts.',
        },
      ])
    }
  }
  return handleAcceptConnection
}
