import { useCallback, useEffect, useRef, useState } from 'react'

import ReactCrop from 'react-image-crop/'

import { isGlobalId, toGlobalId } from 'utils/transformations/graphql'
import { isValidURL } from 'utils/validations'

import { Icon } from 'components/Core'
import Button from 'components/Core/ButtonV2/Button'
import AddImageModal from 'modals/Storefront/AddImageModal/AddImageModal.component'

import styles from './AddImageTooltip.module.scss'
import {
  ASPECT_LANDSCAPE,
  ASPECT_PORTRAIT,
  ASPECT_SQUARE,
} from 'features/Storefront/constants'

export const getAspect = (aspect) => {
  switch (aspect) {
    case ASPECT_PORTRAIT:
      return 3 / 4
    case ASPECT_SQUARE:
      return 1
    case ASPECT_LANDSCAPE:
      return 3 / 2
    default:
      return 3 / 2
  }
}
export const useImage = ({
  value = {},
  openDynamicModal = () => {},
  onApplyChanges = () => {},
  aspect = '',
  onSelectImageAction = () => {},
  onCancelImageSelection = () => {},
}) => {
  const [selectedImage, setSelectedImage] = useState({})
  const [selectingImage, setSelectingImage] = useState(false)
  const [crop, setCrop] = useState({})
  const [completedCrop, setCompletedCrop] = useState(null)
  const [croppedURL, setCroppedURL] = useState('')
  const [imageLink, setImageLink] = useState('')
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const isCroppedImage = Boolean(crop.width || crop.height || crop.x || crop.y)
  const [isSrcUpdated, setIsSrcUpdated] = useState(false)
  useEffect(() => {
    setCrop({ aspect: getAspect(aspect) })
  }, [aspect])

  useEffect(() => setImageLink(value?.imageExternalLink || ''), [
    value?.imageExternalLink,
  ])

  useEffect(() => {
    if (
      !completedCrop ||
      !previewCanvasRef.current ||
      !imgRef.current ||
      !isCroppedImage
    ) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext('2d')
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = 'high'

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    )

    canvas.toBlob((croppedImage) => {
      setCroppedURL(croppedImage)
    }, 'image/png')
  }, [completedCrop])

  const onLoad = useCallback(
    (img) => {
      imgRef.current = img
      if (!isSrcUpdated) {
        img.setAttribute('src', img.src + `?t=nocache`)
        img.setAttribute('crossorigin', 'anonymous')
        setIsSrcUpdated(true)
      }
    },
    [isSrcUpdated],
  )

  const imageCrop = (
    <>
      <div className={styles.cropLabel}>
        <Icon name="crop" className={styles.cropIcon} />
        <span>Crop Image</span>
      </div>
      <div className={styles.cropContainer}>
        <ReactCrop
          src={selectedImage?.url || value.imageUrl}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={(c) => setCrop(c)}
          onComplete={(c) => setCompletedCrop(c)}
        />
      </div>
      {/* This canvas is not displayed but needed to get the croppedURL */}
      <canvas
        ref={previewCanvasRef}
        style={{
          display: 'none',
          width: Math.round(completedCrop?.width ?? 0),
          height: Math.round(completedCrop?.height ?? 0),
        }}
      />
    </>
  )

  const imageSelection = (
    <Button
      className={styles.largeButton}
      onClick={() => {
        setSelectingImage(true)
        setCrop(false)
        onSelectImageAction()
        openDynamicModal({
          component: AddImageModal,
          onCancel: () => {
            onCancelImageSelection()
            setSelectingImage(false)
          },
          onSelect: (value) => {
            const { id } = value
            const isGlobal = isGlobalId(id)
            const imageGlobalId = isGlobal ? id : toGlobalId('Upload', id)
            setSelectedImage({ ...value, id: imageGlobalId })
            setSelectingImage(false)
          },
          onExit: () => {},
        })
      }}
    >
      {value?.imageUrl ? 'Replace Image' : 'Select Image'}
    </Button>
  )

  const newUrl = croppedURL || selectedImage?.url
  const hasChanges =
    (newUrl && value?.imageUrl !== newUrl) ||
    (imageLink && value?.imageExternalLink !== imageLink)
  const parsedLink =
    imageLink.startsWith('http://') || imageLink.startsWith('https://')
      ? imageLink
      : `https://${imageLink}`
  const applyImageActions = async () => {
    const imageId = selectedImage.id || value?.imageId
    await onApplyChanges({
      imageId: imageId || value?.imageId,
      imageUrl: newUrl || value?.imageUrl,
      croppedImageSrc: croppedURL,
      imageExternalLink: isValidURL(parsedLink) ? parsedLink : '',
    })
  }
  return {
    imageSelection,
    imageLink,
    selectedImage,
    croppedURL,
    imageCrop,
    setImageLink,
    selectingImage,
    hasChanges,
    applyImageActions,
    setSelectedImage,
    setCrop,
  }
}
