import { useEffect } from 'react'

import isEmpty from 'lodash/isEmpty'

import { TYPE_BRAND, TYPE_RETAILER } from 'store/currentUser/selectors'

import { CurrentUserInfo } from '../userInfo'

export const getIsInternalUser = (isInternal: boolean, email: string) => {
  if (!email) {
    return false
  }
  const regex = /[0-9]*((joor)|(j00r)|(test))[0-9]*(access)?\.com/gi

  // we only want to check if joor is present in the domain
  const domain = email.split('@')[1]
  const result = domain.match(regex)

  return isInternal || Boolean(result?.length)
}

export const usePendo = (userInfo?: CurrentUserInfo) => {
  useEffect(() => {
    if (isEmpty(userInfo) || !window.pendo) return
    const isBrand = userInfo?.account?.type === TYPE_BRAND
    const isRetailer = userInfo?.account?.type === TYPE_RETAILER
    const isInternalUser = getIsInternalUser(
      userInfo?.isInternal ? userInfo?.isInternal : false,
      userInfo?.email ? userInfo?.email : '',
    )
    window.pendo.initialize({
      visitor: {
        id: userInfo?.userId,
        userName: userInfo?.name,
        email: userInfo?.email,
        isInternal: isInternalUser,
        isAdmin: userInfo?.isAdmin,
        isProRetailer: isRetailer && userInfo?.account?.canViewAssortments,
        isLiteRetailer: isRetailer && !userInfo?.account?.canViewAssortments,
        isProBrand: isBrand && !userInfo?.account?.limitedAccess,
        isLiteBrand: isBrand && userInfo?.account?.limitedAccess,
        displayLanguage: userInfo?.displayLanguage,
        /*
        TODO (@mgomes): add account status when available to metadata
        accountStatus: userInfo?.account?.status
         */
      },
      account: {
        id: userInfo?.account?.accountId,
        name: userInfo?.account?.profileName,
        isProRetailer: isRetailer && userInfo?.account?.canViewAssortments,
        isLiteRetailer: isRetailer && !userInfo?.account?.canViewAssortments,
        isProBrand: isBrand && !userInfo?.account?.limitedAccess,
        isLiteBrand: isBrand && userInfo?.account?.limitedAccess,
      },
    })
  }, [userInfo])
}
