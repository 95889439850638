import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Table as BaseTable } from 'semantic-ui-react'

import styles from './Table.less'

const Header = ({ children, sticky, ...props }) => (
  <BaseTable.Header
    className={classNames({ [styles.sticky]: sticky })}
    {...props}
  >
    {children}
  </BaseTable.Header>
)

Header.propTypes = {
  children: PropTypes.node,
  sticky: PropTypes.bool,
}

Header.defaultProps = {
  children: null,
  sticky: false,
}

export default Header
