import get from 'lodash/get'
import isNil from 'lodash/isNil'

export const generateDefaultValue = (formElement) => {
  let defaultVal = formElement.default

  if (formElement.type === 'dropdown') {
    const selected = formElement.options.find(
      (opt) => opt.value === formElement.default,
    )
    defaultVal = get(selected, 'text')
  }

  if (isNil(defaultVal)) {
    defaultVal = '-'
  }

  return defaultVal
}
