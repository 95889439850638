import { Component } from 'react'

const getDisplayName = (WrappedComponent) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component'

const withModal = (WrappedComponent) => {
  class WithModal extends Component {
    state = {
      isModalVisible: false,
      extraProps: {},
      LoadableComponent: null,
    }

    openModal = (modalOptions) => {
      const { component, ...extraProps } = modalOptions
      if (component === undefined || component === null) {
        throw new Error('Modal could not be opened, missing component')
      }

      this.setState({
        isModalVisible: true,
        extraProps,
        LoadableComponent: component,
      })
    }

    closeModal = () => {
      this.setState({
        isModalVisible: false,
        extraProps: {},
        LoadableComponent: null,
      })
    }

    render() {
      const { isModalVisible, extraProps, LoadableComponent } = this.state
      return (
        <>
          {isModalVisible && (
            <LoadableComponent onClose={this.closeModal} {...extraProps} />
          )}
          <WrappedComponent
            openDynamicModal={this.openModal}
            closeDynamicModal={this.closeModal}
            {...this.props}
          />
        </>
      )
    }
  }
  WithModal.displayName = `withModal(${getDisplayName(WrappedComponent)})`
  return WithModal
}

export default withModal
