import { combineReducers } from 'redux'

import assortmentList from 'store/assortmentList/reducers'
import assortmentListFilters from 'store/assortmentListFilters/reducers'
import brandExcelExportModal from 'store/assortmentReview/brandExcelExportModal/reducers'
import brandPdfExportModal from 'store/assortmentReview/brandPdfExportModal/reducers'
import copyAssortment from 'store/assortmentReview/copyAssortment/reducers'
import priceModifiers from 'store/assortmentReview/priceModifiers/reducers'
import assortmentReview from 'store/assortmentReview/reducers'
import sendToBrand from 'store/assortmentReview/sendToBrand/reducers'
import assortmentSizing from 'store/assortmentSizing/reducers'
import assortmentUnits from 'store/assortmentUnits/reducers'
import autosave from 'store/autosave/reducers'
import appointmentFormModal from 'store/brandDashboard/appointmentFormModal/reducers'
import brandDashboard from 'store/brandDashboard/reducers'
import cancelReplaceStyle from 'store/cancelReplaceStyle/reducers'
import cancelReplaceStyleFilters from 'store/cancelReplaceStyleFilters/reducers'
import currentUser from 'store/currentUser/reducers'
import editStyles from 'store/editStyles/reducers'
import exceptionHandler from 'store/exceptionHandler/reducers'
import fileDownloads from 'store/fileDownloads/reducers'
import modal from 'store/modal/reducers'
import news from 'store/news/reducers'
import notificationHandler from 'store/notificationHandler/reducers'
import assignRetailerAccountModal from 'store/orderList/assignRetailerAccountModal/reducers'
import brandStartOrderModal from 'store/orderList/brandStartOrderModal/reducers'
import createOrderTemplateModal from 'store/orderList/createOrderTemplateModal/reducers'
import duplicateOrdersModal from 'store/orderList/duplicateOrdersModal/reducers'
import excelOrdersModal from 'store/orderList/excelOrdersModal/reducers'
import orderList from 'store/orderList/reducers'
import removeStyleColorsWithoutQuantities from 'store/orderList/removeStyleColorsWithoutQuantities/reducers'
import retailerStartOrderModal from 'store/orderList/retailerStartOrderModal/reducers'
import orderListFilters from 'store/orderListFilters/reducers'
import inOrderReport from 'store/orders/inOrderReport/reducers'
import mergeOrders from 'store/orders/mergeOrders/reducers'
import ordersSelectionFilters from 'store/ordersSelectionFilters/reducers'
import pageLoader from 'store/pageLoader/reducers'
import payments from 'store/payments/reducers'
import permissions from 'store/permissions/reducers'
import quickFilters from 'store/quickFilters/reducers'
import reporting from 'store/reporting/reducers'
import addMoreProductsModal from 'store/shop/addMoreProductsModal/reducers'
import productDetailModal from 'store/shop/productDetailModal/reducers'
import shop from 'store/shop/reducers'
import showroomCollection from 'store/showroom/collection/reducers'
import collectionList from 'store/showroom/collectionList/reducers'
import showroom from 'store/showroom/reducers'
import showroomStyleboard from 'store/showroom/styleboard/reducers'
import styleboardList from 'store/showroom/styleboardList/reducers'
import storefront from 'store/storefront/reducers'

const rootReducer = combineReducers({
  currentUser,
  assortmentSizing,
  cancelReplaceStyleFilters,
  editStyles,
  exceptionHandler,
  pageLoader,
  assortmentList,
  assortmentListFilters,
  assortmentUnits,
  excelOrdersModal,
  brandStartOrderModal,
  retailerStartOrderModal,
  createOrderTemplateModal,
  orderList,
  orderListFilters,
  quickFilters,
  ordersSelectionFilters,
  cancelReplaceStyle,
  notificationHandler,
  modal,
  news,
  assortmentReview,
  brandExcelExportModal,
  brandPdfExportModal,
  copyAssortment,
  autosave,
  duplicateOrdersModal,
  priceModifiers,
  sendToBrand,
  fileDownloads,
  removeStyleColorsWithoutQuantities,
  brandDashboard,
  assignRetailerAccountModal,
  appointmentFormModal,
  productDetailModal,
  shop,
  reporting,
  addMoreProductsModal,
  permissions,
  showroom,
  showroomCollection,
  showroomStyleboard,
  styleboardList,
  collectionList,
  payments,
  storefront,
  inOrderReport,
  mergeOrders,
})

export default rootReducer
