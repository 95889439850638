import { useLazyQuery, useQuery } from '@apollo/client'

import { Geography } from '__generated__/atlas-types'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

const COUNTRIES = atlas`#graphql
  query LiteRetailerPrimaryLocationCountries {
    geographies(types: "COUNTRY") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const useCountries = () => {
  const { data, loading } = useQuery(COUNTRIES, {
    client: atlasClient,
  })
  const formattedCountries = relayConnectionToArray(
    data?.geographies,
  )?.map((country: Geography) => ({ label: country.name, value: country.id }))

  return {
    countries: formattedCountries,
    loading,
  }
}

const STATES = atlas`#graphql
  query LiteRetailerPrimaryLocationStates($countryId: ID!) {
    states: geographies(
      types: ["STATE"]
      filter: { geographyParentId: $countryId }
    ) {
      edges {
        node {
          name
          id
          abbreviation
        }
      }
    }
  }
`

export const useStates = () => {
  const [fetchCountryStates, { loading, data }] = useLazyQuery(STATES, {
    client: atlasClient,
  })

  const fetchStates = (countryId: string) =>
    fetchCountryStates({ variables: { countryId } })
  const states = relayConnectionToArray(data?.states)
  const formattedStates = states?.map((state: Geography) => ({
    label: state.name,
    value: state.id,
  }))

  return {
    stateOptions: formattedStates,
    states,
    loading,
    fetchStates,
  }
}
