import PropTypes from 'prop-types'

import { generateDefaultValue } from 'utils/formBuilder'
import { toServerDate } from 'utils/formatters'

import DatePicker from 'components/Core/DatePicker/DatePicker'
import Drilldown from 'components/Core/Drilldown/Drilldown'
import Dropdown from 'components/Core/Dropdown/Dropdown'
import Input from 'components/Core/Input/Input'
import Number from 'components/Core/Input/Number'
import Textarea from 'components/Core/Textarea/Textarea'

import styles from './FormBuilder.less'

const FormElement = ({ formElement, handleFormOrFormElementChange }) => {
  const handleInputChange = (fieldData) => (event) => {
    const {
      target: { value },
    } = event

    if (value !== event.target.defaultValue) {
      handleFormOrFormElementChange(fieldData, value)
    }
  }

  const handleNumberChange = (fieldData) => (_, { value, defaultValue }) => {
    if (value !== defaultValue) {
      handleFormOrFormElementChange(fieldData, value)
    }
  }

  const handleTextareaChange = (fieldData) => (
    event,
    { value, defaultValue },
  ) => {
    if (value !== defaultValue) {
      handleFormOrFormElementChange(fieldData, value)
    }
  }

  const handleDrilldownChange = (fieldData) => (e, { value }) => {
    handleFormOrFormElementChange(fieldData, value)
  }

  const handleDropdownChange = (fieldData) => (event, data) => {
    const selectedNull = data.value === null

    const isNullable = data.nullable

    if (isNullable && selectedNull) return

    handleFormOrFormElementChange(fieldData, data.value)
  }

  const handleDateChange = (fieldData) => (date) => {
    handleFormOrFormElementChange(fieldData, toServerDate(date))
  }

  const handleDateRangeChange = (fieldData) => (dateRange) => {
    handleFormOrFormElementChange(fieldData, {
      startDate: toServerDate(dateRange.startDate),
      endDate: toServerDate(dateRange.endDate),
    })
  }

  if (formElement.editable === false) {
    const defaultVal = generateDefaultValue(formElement)

    return <div className={styles.notEditable}>{defaultVal}</div>
  } else if (formElement.type === 'dropdown') {
    // TODO: The default should probably not be search.
    // And we should leverage default props even if not,
    // which means we should consider un-nesting the props under `formElement`
    // See ch30019
    const search =
      typeof formElement.search === 'undefined' ? true : formElement.search
    return (
      <Dropdown
        search={search}
        options={formElement.options}
        defaultValue={formElement.default}
        value={formElement.value}
        onChange={handleDropdownChange(formElement)}
        nullable={formElement.nullable}
        disabled={formElement.disabled}
        placeholder={formElement.placeholder}
      />
    )
  } else if (formElement.type === 'text') {
    return (
      <Input
        type="text"
        defaultValue={formElement.default}
        onBlur={handleInputChange(formElement)}
        disabled={formElement.disabled}
      />
    )
  } else if (formElement.type === 'number') {
    return (
      <Number
        className={formElement.className}
        defaultValue={formElement.default}
        adjustmentType={formElement.adjustmentType}
        percent={formElement.percent}
        precision={formElement.precision}
        scale={formElement.scale}
        onBlur={handleNumberChange(formElement)}
        disabled={formElement.disabled}
      />
    )
  } else if (formElement.type === 'datepicker' && formElement.range) {
    return (
      <DatePicker
        startDate={formElement.default.startDate}
        endDate={formElement.default.endDate}
        range={formElement.range}
        onSubmit={handleDateRangeChange(formElement)}
      />
    )
  } else if (formElement.type === 'datepicker') {
    return (
      <DatePicker
        date={formElement.default}
        onSubmit={handleDateChange(formElement)}
      />
    )
  } else if (formElement.type === 'drilldown') {
    return (
      <Drilldown
        data={formElement.data}
        onChange={handleDrilldownChange(formElement)}
        disabled={formElement.disabled}
        defaultValue={formElement.default}
        multiSelect={formElement.multiSelect}
      />
    )
  } else if (formElement.type === 'textarea') {
    return (
      <Textarea
        onBlur={handleTextareaChange(formElement)}
        disabled={formElement.disabled}
        defaultValue={formElement.default}
      />
    )
  } else if (formElement.type === 'custom') {
    return formElement.customFormElement
  }
  return null
}

FormElement.propTypes = {
  formElement: PropTypes.object.isRequired,
  handleFormOrFormElementChange: PropTypes.func,
}

FormElement.defaultProps = {
  handleFormOrFormElementChange: () => {},
}

export default FormElement
