import { useMutation } from '@apollo/client'
import { useNotifications } from '@joor/design-system'

import {
  SendMessageToConnectionDocument,
  SendMessageToConnectionInput,
} from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

atlas`#graphql
  mutation SendMessageToConnection(
    $recipientId: ID!
    $subject: String
    $body: String
    $uploadId: ID
  ) {
    sendMessageToConnection(
      input: { recipientId: $recipientId, subject: $subject, body: $body, uploadId: $uploadId }
    ) {
      messageId
      error
    }
  }
`

export const useMessage = () => {
  const { addToasts } = useNotifications()

  const [sendMessage] = useMutation(SendMessageToConnectionDocument, {
    client: atlasClient,
  })
  const handleSendMessage = async ({
    recipientId,
    subject,
    body,
    uploadId,
  }: SendMessageToConnectionInput) => {
    try {
      const result = await sendMessage({
        variables: {
          recipientId,
          subject,
          body,
          uploadId,
        },
      })
      const success = Boolean(result?.data?.sendMessageToConnection?.messageId)
      if (success) {
        addToasts([
          {
            type: 'success',
            title: 'Success!',
            description: 'Your message has been sent.',
          },
        ])
      } else {
        addToasts([
          {
            type: 'error',
            title: 'Message not sent!',
            description: 'There was an error sending your message.',
          },
        ])
      }
      return success
    } catch (error) {
      console.error(error)
      addToasts([
        {
          type: 'error',
          title: 'Message not sent!',
          description: 'There was an error sending your message.',
        },
      ])
    }
  }
  return handleSendMessage
}
