import { FC, useRef, useState } from 'react'

import {
  Modal,
  ModalProvider,
  SecondaryButton,
  Text,
  TextArea,
  TextField,
} from '@joor/design-system'
import styled from 'styled-components'

import { Media } from '__generated__/atlas-types'

import { useUploadMedia } from 'utils/files'

import usePortal from 'hooks/usePortal'

import { useMessage } from './MessageModal.hooks'

const IMAGE_SIZE_LIMIT = 4000000
const VALID_IMAGE_TYPES = ['image/png', 'image/jpeg']

const StyledModal = styled(Modal)`
  > header > h2 {
    text-overflow: unset;
    white-space: normal;
  }
  > div:first-of-type {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`
const StyledUploadSection = styled.div`
  > span {
    display: block;
    margin-bottom: 8px;
  }
  > div:first-of-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

type MessageModalProps = {
  retailerId: string
  retailerName: string
  onClose: () => void
  isOpen: boolean
  id?: string
}

export const MessageModal: FC<MessageModalProps> = ({
  id,
  isOpen,
  onClose,
  retailerName,
  retailerId,
}) => {
  const [subject, setSubject] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [uploadedFile, setUpdloadedFile] = useState<Media | null>(null)

  const Portal = usePortal()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const disableButton = Boolean(!message.length) || Boolean(!subject.length)

  const { handleUploadImage } = useUploadMedia()
  const handleSendMessage = useMessage()
  const openComputerBrowser = () => inputRef?.current?.click()
  const handleCloseModal = () => {
    setSubject('')
    setMessage('')
    setUpdloadedFile(null)
    onClose()
  }
  return (
    <Portal>
      <ModalProvider>
        <StyledModal
          id={id}
          isOpen={isOpen}
          title={{
            text: `Send a message to ${retailerName}`,
          }}
          size="small"
          onClose={onClose}
        >
          <Modal.Content>
            <TextField
              label="Subject"
              placeholder="Add a subject line"
              value={subject}
              onChange={(e): void => {
                setSubject(e.target.value)
              }}
              isRequired
            />
            <TextArea
              label="Message"
              placeholder="Write a brief message..."
              value={message}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
              maxLength={500}
              isRequired
            />
            <input
              data-testid="fileInput"
              ref={inputRef}
              type="file"
              accept="image/*"
              onChange={async (e) => {
                const newImage = await handleUploadImage({
                  img: e.target?.files?.[0],
                  imageSizeLimit: IMAGE_SIZE_LIMIT,
                  validImageTypes: VALID_IMAGE_TYPES,
                })
                if (newImage?.id) {
                  setUpdloadedFile(newImage)
                }
              }}
              style={{ display: 'none' }}
            />
            <StyledUploadSection>
              <span>Attach an image (JPG or PNG, 4 MB max)</span>
              <div>
                <SecondaryButton onClick={openComputerBrowser}>
                  Choose file
                </SecondaryButton>
                {uploadedFile ? (
                  `${uploadedFile.fileName}`
                ) : (
                  <Text fontSize="small" color="primary-400">
                    No file selected
                  </Text>
                )}
              </div>
            </StyledUploadSection>
          </Modal.Content>
          <Modal.Actions
            primaryButton={{
              text: 'Send',
              handler: async () => {
                const input = {
                  recipientId: retailerId,
                  subject,
                  body: message,
                  uploadId: uploadedFile?.id,
                }
                await handleSendMessage(input)
                handleCloseModal()
              },
              loading: false,
              disabled: disableButton,
            }}
          />
        </StyledModal>
      </ModalProvider>
    </Portal>
  )
}
