import { LITE_RETAILER_REQUIRED_INFO_PREFIX as FEATURE_PREFIX } from 'featurePrefix.ids'

const ADDRESS_SELECTION_PREFIX = 'address-selection'
const ADDRESS_EDITION_PREFIX = 'address-edition'
const BUSINESS_INFO_PREFIX = 'business-info'
const SECONDARY_ACCOUNT_PREFIX = 'secondary-account'
const BRAND_ACCOUNT_PREFIX = 'brand-account'

export const AddressSelectionView = {
  Component: `${FEATURE_PREFIX}_${ADDRESS_SELECTION_PREFIX}_component`,
  BrandAccountButton: `${FEATURE_PREFIX}_${ADDRESS_SELECTION_PREFIX}_brand-account-button`,
  SnoozeButton: `${FEATURE_PREFIX}_${ADDRESS_SELECTION_PREFIX}_snooze-button`,
  NextButton: `${FEATURE_PREFIX}_${ADDRESS_SELECTION_PREFIX}_next-button`,
  ProvidedAddress: `${FEATURE_PREFIX}_${ADDRESS_SELECTION_PREFIX}_provided-info`,
  GoogleAddress: `${FEATURE_PREFIX}_${ADDRESS_SELECTION_PREFIX}_google-info`,
  EditAddressButton: `${FEATURE_PREFIX}_${ADDRESS_SELECTION_PREFIX}_edit-address-button`,
} as const

export const AddressEditionView = {
  Component: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_component`,
  CancelButton: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_cancel-button`,
  UpdateButton: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_update-button`,
  CountryDropdown: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_country-dropdown`,
  Address1Field: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_address1-field`,
  Address2Field: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_address2-field`,
  CityField: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_city-field`,
  StateDropdown: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_state-dropdown`,
  TerritoryField: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_territory-field`,
  ZipField: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_zip-field`,
  TypeDropdown: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_type-dropdown`,
  PhoneField: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_phone-field`,
  WebsiteField: `${FEATURE_PREFIX}_${ADDRESS_EDITION_PREFIX}_website-field`,
} as const

export const BusinessInfoView = {
  Component: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_component`,
  SecondaryAccountButton: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_secondary-account-button`,
  BackButton: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_back-button`,
  SaveButton: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_save-button`,
  DescriptionField: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_description-field`,
  WholesaleMinDropdown: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_wholesale-min-dropdown`,
  WholesaleMaxDropdown: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_wholesale-max-dropdown`,
  CategoriesDropdown: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_categories-dropdown`,
  CarriedBrandsDropdown: `${FEATURE_PREFIX}_${BUSINESS_INFO_PREFIX}_carried-brands-dropdown`,
} as const

export const SecondaryAccountSelectionView = {
  Component: `${FEATURE_PREFIX}_${SECONDARY_ACCOUNT_PREFIX}_component`,
  BackButton: `${FEATURE_PREFIX}_${SECONDARY_ACCOUNT_PREFIX}_back-button`,
  SaveButton: `${FEATURE_PREFIX}_${SECONDARY_ACCOUNT_PREFIX}_save-button`,
  HideRadioButton: `${FEATURE_PREFIX}_${SECONDARY_ACCOUNT_PREFIX}_hide-radio-button`,
  ShowRadioButton: `${FEATURE_PREFIX}_${SECONDARY_ACCOUNT_PREFIX}_show-radio-button`,
} as const

export const BrandAccountSelectionView = {
  Component: `${FEATURE_PREFIX}_${BRAND_ACCOUNT_PREFIX}_component`,
  BackButton: `${FEATURE_PREFIX}_${BRAND_ACCOUNT_PREFIX}_back-button`,
  SubmitButton: `${FEATURE_PREFIX}_${BRAND_ACCOUNT_PREFIX}_submit-button`,
  PlaceholderOption: `${FEATURE_PREFIX}_${BRAND_ACCOUNT_PREFIX}_placeholder-option`,
  ImpersonationOption: `${FEATURE_PREFIX}_${BRAND_ACCOUNT_PREFIX}_impersonation-option`,
  BuyerOption: `${FEATURE_PREFIX}_${BRAND_ACCOUNT_PREFIX}_buyer-option`,
  OtherOption: `${FEATURE_PREFIX}_${BRAND_ACCOUNT_PREFIX}_other-option`,
  OtherField: `${FEATURE_PREFIX}_${BRAND_ACCOUNT_PREFIX}_other-field`,
} as const
