import { Dropdown, SmallIcon, Tooltip } from '@joor/design-system'
import { sortBy } from 'lodash'
import styled from 'styled-components'

import { DropdownInput } from '../../Pages/Pages.types'
import { TextSectionSettingsOnChange, TextStyleElement } from '../TextSettings'
import {
  FONTS,
  getFontSizeOptions,
  getFontWeightOptions,
} from '../TextSettings.constants'
import { formatCustomSimpleDropdownOptions } from '../utils'
import { calculateStyles } from '../utils'

export const TYPEFACE_ID = 'typeface'
export const WEIGHT_ID = 'weight'
export const SIZE_ID = 'size'

const SectionTitle = styled.div`
  overflow-wrap: break-word;
`
const DropdownSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`
const DropdownOptionsSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  label:first-child {
    width: 152px;
  }
  label:last-child {
    width: 80px;
  }
  > div:first-child {
    width: 58%;
  }
  > div:last-child {
    width: 35%;
  }
`

type TextSectionSettingsProps = {
  title: string
  defaultSettings: TextStyleElement
  tooltipContent: string
  onChange: (a: TextSectionSettingsOnChange) => void
}

const tooltipCustomStyles = {
  'line-height': '14px',
}

const TextSectionSettings: React.FC<TextSectionSettingsProps> = ({
  title,
  defaultSettings,
  tooltipContent,
  onChange,
}) => {
  return (
    <div>
      <SectionTitle>
        <h2 style={calculateStyles(defaultSettings)}>
          {title}
          {tooltipContent && (
            <Tooltip
              customTooltipStyles={tooltipCustomStyles}
              content={tooltipContent}
            >
              <SmallIcon iconName="information" />
            </Tooltip>
          )}
        </h2>
      </SectionTitle>
      <DropdownSection>
        <Dropdown
          data-testid={`${title}-${TYPEFACE_ID}`}
          label={'Typeface'}
          options={sortBy(formatCustomSimpleDropdownOptions(FONTS), 'label')}
          defaultValue={{
            label: defaultSettings?.fontFamily,
            value: defaultSettings?.fontFamily,
          }}
          onSelect={({ selected: { value } }: DropdownInput) =>
            onChange({ fontFamily: value })
          }
        />
        <DropdownOptionsSection>
          <div>
            <Dropdown
              data-testid={`${title}-${WEIGHT_ID}`}
              label={'Weight'}
              options={getFontWeightOptions()}
              defaultValue={{
                label: getFontWeightOptions().find(
                  (weight) => weight.value === defaultSettings?.fontWeight,
                )?.label as string,
                value: defaultSettings?.fontWeight,
              }}
              onSelect={({ selected: { value } }: DropdownInput) => {
                onChange({ fontWeight: value })
              }}
            />
          </div>
          <div>
            <Dropdown
              data-testid={`${title}-${SIZE_ID}`}
              variant={'DEFAULT'}
              label={'Point Size'}
              options={getFontSizeOptions()}
              defaultValue={{
                label: defaultSettings?.fontSize,
                value: defaultSettings?.fontSize,
              }}
              onSelect={({ selected: { value } }: DropdownInput) =>
                onChange({ fontSize: value })
              }
            />
          </div>
        </DropdownOptionsSection>
      </DropdownSection>
    </div>
  )
}

TextSectionSettings.defaultProps = {
  tooltipContent: '',
  onChange: () => {},
}

export default TextSectionSettings
