import React from 'react'

import { ToastConsumer } from '@joor/design-system'
import styled from 'styled-components'

const NotificationsContainer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 20001;
  display: flex;
  flex-direction: column;
  gap: 15px;

  /* restore design system styles crushed by semantic.css */
  & .toast-fix {
    button {
      margin-top: 8px !important;
      font-size: var(--size-font-small) !important;
    }
  }

  /* restore design system styles crushed by semantic.css */
  & .font-size-fix {
    button {
      font-size: var(--size-font-small) !important;
    }
  }
`
const Notifications = () => (
  <NotificationsContainer>
    <ToastConsumer />
  </NotificationsContainer>
)
export default Notifications
