import { Component } from 'react'

import debounce from 'lodash/debounce'
import find from 'lodash/find'
import PropTypes from 'prop-types'
import qs from 'qs'

import { formatRetailerWithLocation } from 'formatters/orders'
import { formatDropdownOptions, formatPriceTypeIds } from 'utils/formatters'
import { translateText } from 'utils/sdks/localize'
import { fromGlobalId } from 'utils/transformations/graphql'

import { ALL_COLLECTIONS } from 'shop/formatters'
import { NONE_GROUP_BY_TAG, NONE_SORT_BY_TAG } from 'store/shop/constants'

import Dropdown, { NO_RESULTS_MESSAGE } from 'components/Core/Dropdown/Dropdown'
import Modal from 'components/Core/Modal'
import { PRODUCT_CATALOG } from 'routes/paths'

import { BrandStartOrderIds } from '../StartOrderModal.ids'
import styles from '../StartOrderModal.less'
import messages from './BrandStartOrderModal.messages'
import { MINIMUM_CHARACTERS_QUERY } from './BrandStartOrderModal.queries'
import { COLLECTIONS_KEY } from 'containers/ProductCatalog/CatalogFilters/filters'

const DEBOUNCE_TIME = 500

export default class BrandStartOrderModal extends Component {
  state = {
    selectedDoorIds: this?.props?.preselectedDoors || [],
    selectedCollectionId: ALL_COLLECTIONS,
    selectedOrderEventId: this.props.preselectedEvent?.id || null,
    retailerOptions: this.props.preselectedRetailer?.id
      ? [
          {
            className: 'notranslate',
            key: this.props.preselectedRetailer?.id,
            text: this.props.preselectedRetailer?.name,
            value: this.props.preselectedRetailer?.id,
          },
        ]
      : [],
    selectedPriceTypeId: this.props?.preselectedPriceType?.id || null,
    selectedOrderTypeId: this.props.preselectedOrderTypeId || null,
    selectedWarehouseId: this?.props?.preselectedWarehouse?.id || null,
    selectedRetailerId: this.props?.preselectedRetailer?.id || null,
  }

  componentDidUpdate(prevProps) {
    const {
      connectedAccountsQueryLoading,
      retailerSearchText,
      dataForRetailerQueryLoading,
      orderTypes,
      preselectedPriceType,
      preselectedWarehouse,
      doors,
    } = this.props
    const preselectedData = Boolean(
      preselectedPriceType?.id || preselectedWarehouse?.id,
    )

    const { selectedOrderTypeId } = this.state
    const finishedLoading =
      prevProps.connectedAccountsQueryLoading && !connectedAccountsQueryLoading
    const searchTextChanged =
      prevProps.retailerSearchText !== retailerSearchText
    const minimumCharactersReached =
      retailerSearchText.length < MINIMUM_CHARACTERS_QUERY
    const finishedRetailLoading =
      prevProps.dataForRetailerQueryLoading && !dataForRetailerQueryLoading
    if (finishedLoading || searchTextChanged) {
      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        retailerOptions: minimumCharactersReached
          ? []
          : this.getRetailerOptions(),
      })
    }

    if (finishedRetailLoading && !preselectedData) {
      this.setWarehouseId()
      this.setPriceTypeId()

      if (doors.length === 1) {
        this.setState({ selectedDoorIds: [this.props.doors[0].value] })
      }
    }
    if (!selectedOrderTypeId && orderTypes.length > 0) {
      this.setState({ selectedOrderTypeId: orderTypes[0].id })
    }
  }

  componentWillUnmount() {
    const {
      changeRetailerSearchText,
      changeCollectionSearchText,
      setRetailerId,
    } = this.props

    setRetailerId()
    changeRetailerSearchText('')
    changeCollectionSearchText('')
  }

  setPriceTypeId = () => {
    const { priceTypes } = this.props
    let priceTypeMatches = priceTypes
    return priceTypeMatches.length
      ? this.setState({ selectedPriceTypeId: priceTypeMatches[0].id })
      : this.setState({ selectedPriceTypeId: null })
  }

  setWarehouseId = () => {
    const { warehouses } = this.props
    let warehouseMatches = warehouses

    if (warehouseMatches.length > 0) {
      const defaultWarehouse = find(warehouseMatches, 'default')
      this.setState({
        selectedWarehouseId: defaultWarehouse
          ? defaultWarehouse.id
          : warehouseMatches[0].id,
      })
    } else {
      this.setState({ selectedWarehouseId: null })
    }
  }

  setSelectedWarehouse = () => {
    const { setWarehouse, warehouses } = this.props
    let warehouseMatches = warehouses
    const { selectedWarehouseId } = this.state
    const selectedWarehouse = warehouseMatches.find(
      (warehouse) => warehouse.id === selectedWarehouseId,
    )
    setWarehouse(selectedWarehouse)
  }

  setSelectedEvent = () => {
    const { orderEvents, setEvent } = this.props
    const { selectedOrderEventId } = this.state
    const orderEvent = orderEvents.find(
      (event) => event.id === selectedOrderEventId,
    )
    setEvent(orderEvent)
  }

  setSelectedDoors = () => {
    const { doors, setDoors } = this.props
    const { selectedDoorIds } = this.state
    let doorMatches = doors
    const selectedDoors = selectedDoorIds.map((selectDoorId) =>
      doorMatches.find((door) => door.value === selectDoorId),
    )
    setDoors(selectedDoors)
  }

  setPriceTypeData = () => {
    const { priceTypes, setPriceTypes, setOrderPriceType } = this.props
    const { selectedPriceTypeId } = this.state
    let priceTypeMatches = priceTypes
    const selected =
      priceTypeMatches.length === 1
        ? priceTypeMatches[0]
        : priceTypeMatches.find(
            (priceType) => priceType.id === selectedPriceTypeId,
          )
    setPriceTypes(priceTypeMatches)
    setOrderPriceType(selected)
  }

  getRetailerOptions = () =>
    formatDropdownOptions(
      this.props.connectedAccounts,
      formatRetailerWithLocation,
      true,
      'notranslate',
      'id',
    )

  getWarehouseOptions = (warehouses) =>
    formatDropdownOptions(warehouses, undefined, false, 'notranslate')

  getOrderTypeOptions = () => [
    ...formatDropdownOptions(
      this.props.orderTypes,
      undefined,
      false,
      'notranslate',
    ),
  ]

  getPriceTypeOptions = (priceTypes) => [
    ...formatDropdownOptions(
      formatPriceTypeIds(priceTypes),
      undefined,
      false,
      'notranslate',
    ),
  ]

  getOrderEventOptions = () =>
    formatDropdownOptions(
      this.props.orderEvents,
      undefined,
      false,
      'notranslate',
    )

  /**
   * After adding support for preselectedRetailer this method must
   * be aware of where is it getting the selectedRetailer from.
   * In case we're passing a preselectedRetailer, connectedAccounts
   * props will be expected to be empty so we need to build an array
   * with out preselectedRetailer.
   *
   * There are also some cases where retailerId might be empty after opening
   * the modal with a preselectedRetaielr, so we also solve for that case.
   */
  getSelectedRetailer = (retailerId) => {
    const isRetailerPreselected = Boolean(this.props.preselectedRetailer?.id)
    const searchChanged = Boolean(this.props.retailerSearchText)

    const retailerOptions =
      isRetailerPreselected && !searchChanged
        ? [
            {
              ...this.props.preselectedRetailer,
              name: this.props.preselectedRetailer?.name,
              text: this.props.preselectedRetailer?.name,
            },
          ]
        : this.props.connectedAccounts?.map((ro) => ({
            ...ro,
            name: ro?.displayName,
          }))

    if (!retailerId && isRetailerPreselected) {
      retailerId = this.props.preselectedRetailer?.id
    }

    const foundRetailer = find(
      retailerOptions,
      (account) => account.id === retailerId,
    )

    return foundRetailer
  }

  getRequestData = () => {
    const { connectedAccounts, orderEvents, doors, warehouses } = this.props
    const {
      selectedDoorIds,
      selectedOrderEventId,
      selectedWarehouseId,
      selectedPriceTypeId,
      selectedOrderTypeId,
      selectedRetailerId,
    } = this.state
    const selectedRetailer = connectedAccounts.find(
      (connectedAccount) => connectedAccount.id === selectedRetailerId,
    )
    const { id: retailerId } = fromGlobalId(selectedRetailer.id)
    const doorIds = selectedDoorIds.map((selectedDoorId) => {
      const { id: doorId } = fromGlobalId(selectedDoorId)
      return doorId
    })

    const doorNames = selectedDoorIds.map((selectDoorId) =>
      doors.find((door) => door.value === selectDoorId),
    )
    this.setSelectedDoors()
    const orderEvent = orderEvents.find(
      (event) => event.id === selectedOrderEventId,
    )

    const { id: priceTypeId } = fromGlobalId(selectedPriceTypeId)

    const orderWarehouse = warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouseId,
    )

    const { id: orderTypeId } = fromGlobalId(selectedOrderTypeId)
    const data = {
      retailer_name: selectedRetailer.displayName,
      retailer_id: retailerId,
      door_ids: doorIds,
      door_names: doorNames,
      event_name: orderEvent && orderEvent.name,
      warehouse: orderWarehouse,
      price_type: priceTypeId,
      order_type_id: orderTypeId,
    }
    return qs.stringify(data)
  }

  handleDoorChange = (e, { value }) => {
    this.setState({ selectedDoorIds: value })
  }

  handleOrderTypeChange = (e, { value }) => {
    this.setState({ selectedOrderTypeId: value })
  }

  handleSearchRetailer = debounce((e, { searchQuery }) => {
    const { changeRetailerSearchText } = this.props
    changeRetailerSearchText(searchQuery)
  }, DEBOUNCE_TIME)

  handleSearchCollection = debounce((e, { searchQuery }) => {
    const { changeCollectionSearchText } = this.props
    changeCollectionSearchText(searchQuery)
  }, DEBOUNCE_TIME)

  handleRetailerChange = (e, { value }) => {
    if (value !== this.state.selectedRetailerId) {
      const { setRetailerId, changeCollectionSearchText } = this.props

      this.setState({
        selectedDoorIds: [],
        selectedRetailerId: value,
      })
      setRetailerId(value)
      changeCollectionSearchText('')
    }
  }

  handleCollectionChange = (_, { value }) => {
    this.setState({ selectedCollectionId: value })
  }

  handleOrderEventChange = (_, { value }) => {
    this.setState({ selectedOrderEventId: value })
  }

  handleOrderPriceTypeChange = (e, { value }) => {
    this.setState({ selectedPriceTypeId: value })
  }

  handleOrderWarehouseChange = (e, { value }) => {
    this.setState({ selectedWarehouseId: value })
  }

  deselectAllFilters = () => {
    const {
      toggleDeselectAllFilters,
      applySelectedFilters,
      setSearchText,
      setGroupByTagInfo,
      setSortByTagInfo,
    } = this.props

    toggleDeselectAllFilters()
    applySelectedFilters()
    setSearchText('')
    setGroupByTagInfo(NONE_GROUP_BY_TAG)
    setSortByTagInfo(NONE_SORT_BY_TAG)
  }

  createOrder = () => {
    const {
      selectedOrderEventId,
      selectedCollectionId,
      retailerOptions,
      selectedRetailerId,
      selectedOrderTypeId,
    } = this.state

    const {
      setPageLoaderActive,
      trackStartOrderModalSubmitted,
      history,
      collections,
      setFilter,
      setRetailer,
      setOrderTypeId,
      saveNewOrderGroupId,
    } = this.props

    const eventsOptions = this.getOrderEventOptions()
    this.deselectAllFilters()
    setPageLoaderActive(true)

    trackStartOrderModalSubmitted({
      retailerOptions,
      collectionOptions: collections,
      selectedCollectionId,
      eventsOptions,
      selectedOrderEventId,
    })

    if (selectedCollectionId === ALL_COLLECTIONS) {
      setFilter({ key: COLLECTIONS_KEY })
    } else {
      const selectedCollection = find(
        collections,
        (collection) => collection.key === selectedCollectionId,
      )
      setFilter({ key: COLLECTIONS_KEY, value: selectedCollection })
    }
    const selectedRetailer = this.getSelectedRetailer(selectedRetailerId)
    setRetailer(selectedRetailer)
    setOrderTypeId(selectedOrderTypeId)

    this.setSelectedDoors()
    this.setSelectedWarehouse()
    this.setSelectedEvent()
    this.setPriceTypeData()
    saveNewOrderGroupId()
    history.push(PRODUCT_CATALOG)
    return null
  }

  customOnPrimaryButtonClick = () => {
    const {
      selectedRetailerId,
      selectedWarehouseId,
      selectedOrderEventId,
      selectedOrderTypeId,
      selectedPriceTypeId,
      selectedDoorIds,
    } = this.state
    const {
      onPrimaryButtonClick,
      doors,
      warehouses,
      orderEvents,
      priceTypes,
    } = this.props

    const defaultPriceType = priceTypes?.[0]
    const priceType = selectedPriceTypeId
      ? priceTypes.find((priceType) => priceType.id === selectedPriceTypeId)
      : defaultPriceType

    const primaryClickProps = {
      retailer: this.getSelectedRetailer(selectedRetailerId),
      doors: doors?.filter((door) => selectedDoorIds.includes(door.key)),
      warehouse: warehouses?.find(
        (warehouse) => warehouse.id === selectedWarehouseId,
      ),
      event: orderEvents?.find((event) => event.id === selectedOrderEventId),
      orderTypeId: selectedOrderTypeId,
      priceType,
    }
    onPrimaryButtonClick(primaryClickProps)
  }

  redirectUserToShop = () => {
    const { selectedCollectionId } = this.state
    const { history, accountId } = this.props
    if (selectedCollectionId === ALL_COLLECTIONS) {
      history.push(`/collections/view/?brandId=${accountId}`)
    } else {
      const { id: collectionId } = fromGlobalId(selectedCollectionId)
      history.push(`/collections/view/${collectionId}`)
    }
  }

  handleClose = () => {
    this.props.trackStartOrderModalCancelled()
    this.props.onClose()
  }

  render() {
    const {
      dataForRetailerQueryLoading,
      doors,
      initialStartOrderQueryLoading,
      orderTypes,
      connectedAccountsQueryLoading,
      forceComplete,
      collections,
      collectionsLoading,
      modalTitle,
      priceTypes,
      selectCollection,
      onPrimaryButtonClick,
      alwaysShowPriceType,
      warehouses,
    } = this.props
    const {
      selectedCollectionId,
      selectedDoorIds,
      selectedOrderEventId,
      retailerOptions,
      selectedPriceTypeId,
      selectedOrderTypeId,
      selectedWarehouseId,
      selectedRetailerId,
    } = this.state
    let [priceTypeMatches, doorMatches, warehouseMatches] = [
      priceTypes,
      doors,
      warehouses,
    ]
    const primaryDisabled = !selectedRetailerId || !priceTypeMatches.length
    const showPriceTypeDropdown =
      alwaysShowPriceType || priceTypeMatches.length > 1
    return (
      <Modal
        id={BrandStartOrderIds.ModalTitle}
        title={modalTitle || messages.startOrder}
        primaryActionLabel={messages.startOrder}
        primaryActionOnClick={() => {
          if (onPrimaryButtonClick) {
            this.deselectAllFilters()
            this.customOnPrimaryButtonClick()
          } else {
            this.createOrder()
          }
        }}
        primaryDisabled={primaryDisabled}
        open
        onClose={this.handleClose}
        className={styles.StartOrder}
        loading={dataForRetailerQueryLoading || initialStartOrderQueryLoading}
        secondaryActionHidden={forceComplete}
        hideCloseButton={forceComplete}
        primaryActionId={BrandStartOrderIds.AcceptButton}
        secondaryActionId={BrandStartOrderIds.CancelButton}
        idCloseButton={BrandStartOrderIds.CloseButton}
      >
        <label
          id={BrandStartOrderIds.RetailerTitle}
          htmlFor="retailerOptions"
          className={styles.label}
        >
          <span className={styles.required}>*</span>
          {messages.selectRetailer}
        </label>
        <span className="notranslate">
          <Dropdown
            loading={connectedAccountsQueryLoading}
            id="retailerOptions"
            onChange={this.handleRetailerChange}
            onSearchChange={this.handleSearchRetailer}
            options={retailerOptions}
            placeholder={translateText(messages.startTyping)}
            noResultsMessage={translateText(NO_RESULTS_MESSAGE)}
            search
            value={selectedRetailerId}
            minCharacters={MINIMUM_CHARACTERS_QUERY}
            className={selectedRetailerId ? 'notranslate' : ''}
          />
        </span>
        {selectedRetailerId && !priceTypeMatches.length && (
          <div className={styles.errorLabel}>
            No price type available for this retailer
          </div>
        )}

        {doorMatches.length > 0 && [
          <label
            id={BrandStartOrderIds.DoorTitle}
            key="doorOptionsLabel"
            htmlFor="doorOptions"
            className={styles.label}
          >
            Select door(s)
          </label>,
          <span className="notranslate" key="doorOptionsSpan">
            <Dropdown
              key="doorOptionsDropdown"
              id="doorOptions"
              onChange={this.handleDoorChange}
              options={doorMatches}
              placeholder={translateText(messages.selectOne)}
              noResultsMessage={translateText(NO_RESULTS_MESSAGE)}
              multiple
              value={selectedDoorIds}
            />
          </span>,
        ]}

        {showPriceTypeDropdown && [
          <label
            id={BrandStartOrderIds.PriceTypeTitle}
            key="priceTypeOptionsLabel"
            htmlFor="priceTypeOptions"
            className={styles.label}
          >
            Select order price type
          </label>,
          <Dropdown
            key="priceTypeOptionsDropdown"
            id="priceTypeOptions"
            onChange={this.handleOrderPriceTypeChange}
            options={this.getPriceTypeOptions(priceTypeMatches)}
            value={selectedPriceTypeId}
          />,
        ]}

        {warehouseMatches.length >= 1 &&
          selectedRetailerId && [
            <label
              id={BrandStartOrderIds.RetailerTitle}
              key="warehouseOptionsLabel"
              htmlFor="warehousesOptions"
              className={styles.label}
            >
              Select order warehouse
            </label>,
            <Dropdown
              key="warehouseOptionsDropdown"
              id="warehouseOptions"
              onChange={this.handleOrderWarehouseChange}
              options={this.getWarehouseOptions(warehouseMatches)}
              value={selectedWarehouseId}
            />,
          ]}
        {orderTypes.length > 0 && [
          <label
            id={BrandStartOrderIds.OrderTypesTitle}
            key="orderTypeOptionsLabel"
            htmlFor="orderTypesOptions"
            className={styles.label}
          >
            Select order type
          </label>,
          <Dropdown
            key="orderTypeOptionsDropdown"
            id="orderTypeOptions"
            onChange={this.handleOrderTypeChange}
            options={this.getOrderTypeOptions()}
            nullable
            placeholder={translateText(messages.selectOne)}
            value={selectedOrderTypeId}
          />,
        ]}
        {selectCollection && (
          <>
            <label
              id={BrandStartOrderIds.CollectionTitle}
              htmlFor="collectionOptions"
              className={styles.label}
            >
              {messages.selectLinesheet}
            </label>
            <span className="notranslate">
              <Dropdown
                id="collectionOptions"
                loading={collectionsLoading}
                onChange={this.handleCollectionChange}
                onSearchChange={this.handleSearchCollection}
                options={collections}
                search
                noResultsMessage={translateText(NO_RESULTS_MESSAGE)}
                placeholder={translateText('Start typing to search...')}
                value={selectedCollectionId}
                className={
                  selectedCollectionId &&
                  selectedCollectionId !== ALL_COLLECTIONS
                    ? 'notranslate'
                    : ''
                }
                disabled={!selectedRetailerId}
              />
            </span>
          </>
        )}

        <label
          id={BrandStartOrderIds.EventTitle}
          htmlFor="eventOptions"
          className={styles.label}
        >
          {messages.selectEvent}
        </label>
        <span className="notranslate">
          <Dropdown
            id="eventOptions"
            onChange={this.handleOrderEventChange}
            options={this.getOrderEventOptions()}
            placeholder={translateText(messages.selectOne)}
            noResultsMessage={translateText(NO_RESULTS_MESSAGE)}
            search
            value={selectedOrderEventId}
            className={selectedOrderEventId ? 'notranslate' : ''}
          />
        </span>
      </Modal>
    )
  }
}

BrandStartOrderModal.propTypes = {
  accountId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  selectCollection: PropTypes.bool,
  setPageLoaderActive: PropTypes.func.isRequired,
  setRetailerId: PropTypes.func.isRequired,
  setRetailer: PropTypes.func.isRequired,
  setDoors: PropTypes.func.isRequired,
  setPriceTypes: PropTypes.func.isRequired,
  setOrderPriceType: PropTypes.func.isRequired,
  setWarehouse: PropTypes.func.isRequired,
  setEvent: PropTypes.func.isRequired,
  toggleDeselectAllFilters: PropTypes.func.isRequired,
  applySelectedFilters: PropTypes.func.isRequired,
  setSearchText: PropTypes.func.isRequired,
  setGroupByTagInfo: PropTypes.func.isRequired,
  setSortByTagInfo: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  setOrderTypeId: PropTypes.func.isRequired,
  preselectedRetailer: PropTypes.object,
  preselectedPriceType: PropTypes.object,
  preselectedWarehouse: PropTypes.object,
  preselectedEvent: PropTypes.object,
  preselectedOrderTypeId: PropTypes.object,
  preselectedDoor: PropTypes.arrayOf(PropTypes.string),
  alwaysShowPriceType: PropTypes.bool,
  connectedAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      displayName: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
        }),
      ),
    }),
  ),
  collections: PropTypes.array.isRequired,
  orderEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string,
    }),
  ).isRequired,
  doors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  warehouses: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  orderTypes: PropTypes.array.isRequired,
  priceTypes: PropTypes.array,
  initialStartOrderQueryLoading: PropTypes.bool,
  dataForRetailerQueryLoading: PropTypes.bool,
  history: PropTypes.object.isRequired,
  changeRetailerSearchText: PropTypes.func.isRequired,
  connectedAccountsQueryLoading: PropTypes.bool,
  retailerSearchText: PropTypes.string.isRequired,
  trackStartOrderModalSubmitted: PropTypes.func,
  trackStartOrderModalCancelled: PropTypes.func,
  flags: PropTypes.object,
  forceComplete: PropTypes.bool,
  changeCollectionSearchText: PropTypes.func,
  collectionsLoading: PropTypes.bool,
  saveNewOrderGroupId: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
}

BrandStartOrderModal.defaultProps = {
  initialStartOrderQueryLoading: false,
  dataForRetailerQueryLoading: false,
  connectedAccountsQueryLoading: false,
  connectedAccounts: [],
  doors: [],
  priceTypes: [],
  warehouses: [],
  trackStartOrderModalSubmitted: () => {},
  trackStartOrderModalCancelled: () => {},
  flags: {},
  forceComplete: false,
  changeCollectionSearchText: () => {},
  collectionsLoading: false,
  selectCollection: true,
}
