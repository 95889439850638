import { translateText } from 'utils/sdks/localize'

const prefix = 'SHOP'

export const SET_RETAILER = `${prefix}/SET_RETAILER`
export const SET_DOORS = `${prefix}/SET_DOORS`
export const SET_PRICETYPES = `${prefix}/SET_PRICETYPES`
export const SET_ORDER_PRICETYPE = `${prefix}/SET_ORDER_PRICETYPE`
export const SET_WAREHOUSE = `${prefix}/SET_WAREHOUSE`
export const SET_EVENT = `${prefix}/SET_EVENT`
export const SET_SHOULD_UPDATE_CART = `${prefix}/SET_SHOULD_UPDATE_CART`
export const SET_SHOULD_DISABLE_CART = `${prefix}/SET_SHOULD_DISABLE_CART`
export const TOGGLE_SELECT_FILTER = `${prefix}/TOGGLE_SELECT_FILTER`
export const TOGGLE_DESELECT_ALL_FILTERS = `${prefix}/TOGGLE_DESELECT_ALL_FILTERS`
export const APPLY_SELECTED_FILTERS = `${prefix}/APPLY_SELECTED_FILTERS`
export const SET_FILTER = `${prefix}/SET_FILTER`
export const SET_BRAND = `${prefix}/SET_BRAND`
export const SET_SEARCH_TEXT = `${prefix}/SET_SEARCH_TEXT`
export const SET_ORDER_TYPE_ID = `${prefix}/SET_ORDER_TYPE_ID`
export const SET_CART_INFO = `${prefix}/SET_CART_INFO`
export const SET_GROUP_BY_TAG_INFO = `${prefix}/SET_GROUP_BY_TAG_INFO`
export const SET_SORT_BY_TAG_INFO = `${prefix}/SET_SORT_BY_TAG_INFO`
export const SET_ORDER_GROUP_ID = `${prefix}/SET_ORDER_GROUP_ID`
export const CLEAR_ALL_FILTERS = `${prefix}/CLEAR_ALL_FILTERS`
export const CLEAR_FILTER = `${prefix}/CLEAR_FILTER`

export const NONE_GROUP_BY_TAG_ID = 'NONE_TAG'
export const NONE_GROUP_BY_TAG = {
  id: NONE_GROUP_BY_TAG_ID,
  code: NONE_GROUP_BY_TAG_ID,
  value: NONE_GROUP_BY_TAG_ID,
  text: translateText('None'),
}

export const GROUP_BY_COLLECTION_TAG_ID = 'COLLGROUP'
export const GROUP_BY_COLLECTION_TAG = {
  id: GROUP_BY_COLLECTION_TAG_ID,
  code: GROUP_BY_COLLECTION_TAG_ID,
  value: GROUP_BY_COLLECTION_TAG_ID,
  text: translateText('Linesheet Group'),
}

export const GROUP_BY_SILHOUETTE_TAG_ID = 'SILHOUETTE'
export const GROUP_BY_SILHOUETTE_TAG = {
  id: GROUP_BY_SILHOUETTE_TAG_ID,
  code: GROUP_BY_SILHOUETTE_TAG_ID,
  value: GROUP_BY_SILHOUETTE_TAG_ID,
  text: translateText('Silhouette'),
}

export const GROUP_BY_BADGE_ID = 'BADGE'
export const GROUP_BY_BADGE = {
  id: GROUP_BY_BADGE_ID,
  code: GROUP_BY_BADGE_ID,
  value: GROUP_BY_BADGE_ID,
  text: translateText('Badge'),
}

export const GROUP_BY_STYLE_TAG_ID = 'STYLETAG'
export const GROUP_BY_STYLE_TAG = {
  id: GROUP_BY_STYLE_TAG_ID,
  code: GROUP_BY_STYLE_TAG_ID,
  value: GROUP_BY_STYLE_TAG_ID,
  text: translateText('Style tag'),
}

export const NONE_SORT_BY_TAG_ID = 'NONE_TAG'
export const NONE_SORT_BY_TAG = {
  id: NONE_SORT_BY_TAG_ID,
  code: NONE_SORT_BY_TAG_ID,
  value: NONE_SORT_BY_TAG_ID,
  text: translateText('None'),
}

export const SORT_BY_PRICE_ASC_TAG_ID = 'PRICE_ASCENDING'
export const SORT_BY_PRICE_ASC_TAG = {
  id: SORT_BY_PRICE_ASC_TAG_ID,
  code: 'PRICE',
  value: 'ASCENDING',
  text: translateText('Price (Low to High)'),
}

export const SORT_BY_PRICE_DESC_TAG_ID = 'PRICE_DESCENDING'
export const SORT_BY_PRICE_DESC_TAG = {
  id: SORT_BY_PRICE_DESC_TAG_ID,
  code: 'PRICE',
  value: 'DESCENDING',
  text: translateText('Price (High to Low)'),
}

export const ADD_ALL_SELECTS = `${prefix}/ADD_ALL_SELECTS`
export const TOGGLE_SELECTS = `${prefix}/TOGGLE_SELECTS`
export const CLEAR_SELECTS_FOR_COLLECTION = `${prefix}/CLEAR_SELECTS_FOR_LINESHEET`
export const DESELECT_UNAVAILABLE_PRODUCTS = `${prefix}/DESELECT_UNAVAILABLE_PRODUCTS`
