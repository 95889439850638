import { FC, useEffect, useState } from 'react'

import Hls from 'hls.js'

interface IHLSSource {
  src: string
  type?: string
  video: {} | null
}

const HLSSource: FC<IHLSSource> = ({
  src,
  type = 'application/x-mpegURL',
  video,
}) => {
  // configuring hls to a good quality since start
  var config = {
    capLevelToPlayerSize: true,
    testBandwidth: false,
    abrEwmaDefaultEstimate: 2048000,
    startLevel: 3,
    autoStartLoad: true,
  }
  // @ts-expect-error
  const [hls] = useState(new Hls(config))
  useEffect(() => {
    // load hls video source base on hls.js
    // doc on https://github.com/video-dev/hls.js/blob/master/docs/API.md
    if (Hls.isSupported()) {
      hls.attachMedia(video)
      hls.loadSource(src)
    }
  }, [src, video])

  useEffect(
    () => () => {
      // destroy hls video source
      hls.destroy()
    },
    [],
  )

  return <source src={src} type={type} />
}

export default HLSSource
