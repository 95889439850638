import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Table as BaseTable } from 'semantic-ui-react'

import Cell from 'components/Core/Table/Cell'
import Header from 'components/Core/Table/Header'
import HeaderCell from 'components/Core/Table/HeaderCell'

import styles from './Table.less'

const Table = ({
  compact,
  sortable,
  children,
  className,
  fullWidth,
  borderless,
  noResultsMessages,
  testId,
}) => {
  const classes = classNames(styles.Table, className, {
    [styles.fullWidth]: fullWidth,
    [styles.sortable]: sortable,
    [styles.compact]: compact,
  })
  return (
    <div className={classes} data-testid={testId}>
      <BaseTable
        unstackable
        collapsing
        className={borderless ? 'borderless' : ''}
      >
        {children}
      </BaseTable>

      {noResultsMessages.map((message) => (
        <div className={styles.noResult} key={message}>
          {message}
        </div>
      ))}
    </div>
  )
}

Table.propTypes = {
  compact: PropTypes.bool,
  sortable: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  borderless: PropTypes.bool,
  noResultsMessages: PropTypes.array,
  testId: PropTypes.string,
}

Table.defaultProps = {
  compact: false,
  sortable: false,
  className: null,
  children: null,
  fullWidth: false,
  borderless: false,
  noResultsMessages: [],
  testId: null,
}

Table.HeaderCell = HeaderCell
Table.Body = BaseTable.Body
Table.Cell = Cell
Table.Footer = BaseTable.Footer
Table.Header = Header
Table.Row = BaseTable.Row

export default Table
