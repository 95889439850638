import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import Button from 'components/Core/Button/Button'
import Icon from 'components/Core/Icon/Icon'

import styles from './Uploader.less'
import messages from './Uploader.messages'

class Uploader extends Component {
  state = {
    files: [],
  }

  preventDefault = (event) => event.preventDefault()

  // Converts bytes given to us by dataTransfer API, into readable formats.
  convertBytesToReadableSize = (bytes) => {
    if (bytes === 0) return '0 B'
    const sizes = ['B', 'KB', 'MB']
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)

    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / Math.pow(1024, i)).toFixed(1)} ${sizes[i]}`
  }

  // Clicks hidden <input type="file"> element, to open OS file explorer
  handleOpenFileExplorer = () => this.fileInputElement.click()

  // On close of OS file explorer, receive event with files selected and save the array in state.
  handleCloseFileExplorer = (event) => {
    const incomingFiles = [...event.target.files]
    this.props.onUploadedFiles(incomingFiles)
    this.handleAddFileToState(incomingFiles)
  }

  // On drag and drop of files w/in container, prevent default, get files array, and save to state.
  handleDropFiles = (event) => {
    this.preventDefault(event)
    const incomingFiles = [...event.nativeEvent.dataTransfer.files]

    this.props.onUploadedFiles(incomingFiles)
    this.handleAddFileToState(incomingFiles)
  }

  // Set the state of file array via multi (append) or single (replace)
  handleAddFileToState = (incomingFiles) => {
    if (incomingFiles.length > 0) {
      const state = []

      // Ensures appending of multiple files, else will replace.
      if (this.props.multi === true)
        state.push(...this.state.files, ...incomingFiles)
      else state.push(incomingFiles[0])

      this.setState({ files: state })
    }
  }

  handleRemoveFile = (fileToRemove) => {
    // Filters out any file that matches last-modified timestamp and name.
    const filteredState = this.state.files.filter(
      (file) =>
        file.name !== fileToRemove.name &&
        file.lastModified !== fileToRemove.lastModified,
    )

    // We need to reset the contents of the native input after removal to be able to re-add the same files
    this.fileInputElement.value = null

    this.props.onRemovedFile(filteredState)
    this.setState({ files: filteredState })
  }

  renderButton = (multi) => {
    const { shouldHandleDropFiles } = this.props
    const buttonType = shouldHandleDropFiles ? { secondary: true } : {}
    return (
      <Button {...buttonType} onClick={this.handleOpenFileExplorer}>
        <FormattedMessage {...messages.selectFiles} values={{ multi }} />
      </Button>
    )
  }

  renderWithDropFiles = (files, multi, className, headerMessage) => (
    <>
      {headerMessage}
      <div
        className={classNames(styles.Uploader, styles.hasDropFiles, className, {
          [styles.multi]: multi,
          [styles.hasHeaderMessage]: headerMessage,
        })}
        onDragOver={this.preventDefault}
        onDrop={this.handleDropFiles}
      >
        <input
          className={styles.hidden}
          type="file"
          ref={(input) => {
            this.fileInputElement = input
          }}
          onChange={this.handleCloseFileExplorer}
          multiple={multi}
        />
        {this.renderFileInfo()}
        <div
          className={classNames(styles.context, {
            [styles.hasFiles]: files.length > 0,
          })}
        >
          {this.renderButton(multi)}
          <p className={styles.instructions}>
            <FormattedMessage {...messages.or} /> <br />
            <FormattedMessage {...messages.dragFiles} values={{ multi }} />
          </p>
        </div>
      </div>
    </>
  )

  // At file upload, displays each file in array as file elements.
  renderFileInfo = () => {
    // Builds the list of file elements
    const fileElements = this.state.files.map((file) => (
      <div className={styles.file} key={file.name}>
        <div className={styles.name}>{file.name}</div>
        <div className={styles.size}>
          <span>{this.convertBytesToReadableSize(file.size)}</span>
          <Icon
            className={styles.delete}
            name="delete"
            onClick={() => this.handleRemoveFile(file)}
          />
        </div>
      </div>
    ))

    return (
      this.state.files.length > 0 && (
        <div className={styles.files}>{fileElements}</div>
      )
    )
  }

  render() {
    const {
      className,
      shouldHandleDropFiles,
      multi,
      headerMessage,
      overrideFormattedMessage,
    } = this.props
    const { files } = this.state

    if (shouldHandleDropFiles) {
      return this.renderWithDropFiles(
        files,
        multi,
        className,
        headerMessage,
        overrideFormattedMessage,
      )
    }
    return (
      <div className={classNames(styles.Uploader, className)}>
        <input
          className={styles.hidden}
          type="file"
          ref={(input) => {
            this.fileInputElement = input
          }}
          onChange={this.handleCloseFileExplorer}
          multiple={multi}
        />
        {this.renderButton(multi)}
      </div>
    )
  }
}

Uploader.propTypes = {
  className: PropTypes.string,
  shouldHandleDropFiles: PropTypes.bool,
  multi: PropTypes.bool,
  onUploadedFiles: PropTypes.func,
  onRemovedFile: PropTypes.func,
  headerMessage: PropTypes.node,
}

Uploader.defaultProps = {
  headerMessage: null,
  className: null,
  shouldHandleDropFiles: true,
  multi: false,
  onUploadedFiles: () => {},
  onRemovedFile: () => {},
}

export default Uploader
