import Checkbox from 'components/Core/Checkbox/Checkbox'

import Filter from './baseFilter'

/*
 * CheckboxFilter
 * Groups of checkboxes without the pills. For example:
 * FilterName
 *  Group1
 *    [] Value1
 *    [] Value2
 *  Group2
 *    [] Value3
 *    [] Value4
 *
 * If there's only one item in a group, the group is collapsed so that groups's title becomes item's title:
 *  [] Group3
 */
export default class CheckboxFilter extends Filter {
  constructor({ defaultState, styles, ...rest }) {
    super(rest)
    this.unselectedState = {}
    if (defaultState) {
      this.defaultState = defaultState
    }
    this.styles = styles
  }

  getSelectedOptions = (selectedFilters) =>
    selectedFilters[this.filterKey] &&
    selectedFilters[this.filterKey][this.filterSubgroupKey]
      ? selectedFilters[this.filterKey][this.filterSubgroupKey].reduce(
          (acc, item) => ({ ...acc, [item.id]: true }),
          {},
        )
      : {}

  renderFilterPanelHandler = ({
    filterData,
    selectedFilters,
    actionHandler,
  }) => {
    const filterOptions = this.getFilterOptions(filterData)
    const selectedOptions = this.getSelectedOptions(selectedFilters)
    const disabled = this.isDisabled(selectedFilters)
    const collapseSubgroup = filterOptions.length === 1
    return {
      key: this.label,
      title: collapseSubgroup ? null : this.label,
      expanded: true,
      content: (
        <>
          {filterOptions.map((item) => {
            const isSelected = selectedOptions && selectedOptions[item.id]
            return (
              <Checkbox
                disabled={disabled}
                key={item.id}
                checked={(!disabled && isSelected) || false}
                onClick={
                  !disabled
                    ? () =>
                        actionHandler({
                          filterSubgroupKey: this.filterSubgroupKey,
                          id: item.id,
                        })
                    : () => null
                }
                label={
                  collapseSubgroup
                    ? {
                        children: this.label,
                        className: this.translateOptions ? '' : 'notranslate',
                      }
                    : {
                        children: item.name,
                        className: this.translateOptions ? '' : 'notranslate',
                      }
                }
                className={this.styles.checkbox}
              />
            )
          })}
        </>
      ),
      showFilterHandler: this.showFilterHandler,
    }
  }

  toGraphQLVariable = (graphQLFilters, { value }) => {
    const values = (value && value[this.filterSubgroupKey]) || []
    return {
      ...graphQLFilters,
      [this.filterKey]: {
        ...graphQLFilters[this.filterKey],
        [this.filterSubgroupKey]: values.map((item) => item.id),
      },
    }
  }

  reducerHandler = (selectedFilters, filterInfo) => {
    const { id } = filterInfo
    const currentValues =
      selectedFilters[this.filterKey] &&
      selectedFilters[this.filterKey][this.filterSubgroupKey]
        ? selectedFilters[this.filterKey][this.filterSubgroupKey]
        : []
    const newValues = currentValues.some(
      (filterOption) => filterOption.id === id,
    )
      ? currentValues.filter((filterOption) => filterOption.id !== id)
      : [...currentValues, filterInfo]
    return {
      ...selectedFilters,
      [this.filterKey]: {
        ...selectedFilters[this.filterKey],
        [this.filterSubgroupKey]: newValues,
      },
    }
  }

  addToActiveFilters = (currentState) =>
    // do not render pills
    currentState
}
