import classNames from 'classnames'
import PropTypes from 'prop-types'

import { featureFlagContentWrapper } from 'utils/sdks/launchDarkly'

import Icon from 'components/Core/Icon/Icon'

import styles from './NewsBannerItem.component.less'

export const YELLOW = 'yellow'
export const BLUE = 'blue'

const NewsBannerItem = ({
  message,
  color,
  onClose,
  featureFlag,
  iconClassname,
  onClickOnText,
  showCloseOption = true,
}) => {
  const handleOnClick = () => {
    if (onClickOnText !== null) {
      onClickOnText()
    }
  }

  const newsItem = (
    <div
      className={classNames(styles.newsBannerItem, styles[color], {
        [styles.clickable]: onClickOnText !== null,
      })}
    >
      <p
        style={{ whiteSpace: 'pre-line', textAlign: 'left' }}
        onClick={handleOnClick}
      >
        {message}
      </p>
      {showCloseOption && (
        <div
          className={classNames(styles.newsBannerItemClose, iconClassname)}
          onClick={onClose}
        >
          <Icon name="close-x" />
        </div>
      )}
    </div>
  )

  if (featureFlag) {
    return featureFlagContentWrapper(newsItem, null, featureFlag)
  }

  return newsItem
}

NewsBannerItem.propTypes = {
  message: PropTypes.node,
  color: PropTypes.oneOf([YELLOW, BLUE]),
  featureFlag: PropTypes.string,
  onClose: PropTypes.func,
  onClickOnText: PropTypes.func,
}

NewsBannerItem.defaultProps = {
  message: '',
  color: YELLOW,
  featureFlag: '',
  onClose: () => {},
  onClickOnText: null,
}

export default NewsBannerItem
