import { onError } from '@apollo/client/link/error'

import { cleanGraphQLErrors } from 'utils/transformations/graphql'

import { addErrorMessages } from 'store/exceptionHandler/actions'

export const DEFAULT_FINGERPRINT = '{{ default }}'

const getErrorLink = (dispatch) => {
  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      // Log user out if credentials are invalid
      if (
        graphQLErrors[0]?.extensions?.code === 'FORBIDDEN' || // atlas
        graphQLErrors[0]?.message.includes('Unauthorized Access') // backend.python
      ) {
        window.dispatchEvent(new CustomEvent('openSessionModal'))
      }

      dispatch(addErrorMessages(cleanGraphQLErrors(graphQLErrors)))
    }
  })
  return errorLink
}

export default getErrorLink
