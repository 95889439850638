import { Icon } from '@joor/design-system'
import styled from 'styled-components'

import { IDropdownMenuOption } from 'features/NavbarRevamp/navbarRevamp.types'

const MenuText = styled.div`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-alpha);
  letter-spacing: 0.6px;
  line-height: 16px;
  padding: 4px 9px;
  width: 100%;

  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    padding: 3px 8px;
  }
`

const MenuTextMessage = styled.span`
  color: var(--color-secondary-800);
  display: flex;

  > span {
    margin-left: 4px;
    width: 16px;
    height: 16px;

    svg {
      --main-color: var(--color-secondary-800);
      width: 16px;
      height: 16px;
    }
  }
`

const MenuTextAction = styled.button`
  background-color: var(--color-primary-100);
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  text-decoration-line: none;
  margin: auto 0;
  display: flex;
  line-height: 16px;
`

const DropdownMenuOption = ({
  className,
  message,
  onClick,
  testId,
  id,
  checked,
}: IDropdownMenuOption) => (
  <MenuTextAction data-testid={testId} id={id} className="menuTextLink">
    <MenuText
      className={className}
      onClick={(event) => onClick && onClick(event)}
    >
      <MenuTextMessage>
        {message}
        {checked && <Icon iconName="checkmark" plain />}
      </MenuTextMessage>
    </MenuText>
  </MenuTextAction>
)

export default DropdownMenuOption
