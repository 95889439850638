import { isEmpty } from 'lodash'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { LARGE } from './DragDropTemplate/Row/tooltips/WidgetTextTooltip/WidgetTextVariants'
import { getRowSettingsTooltip } from './DragDropTemplate/Row/utils'
import {
  addMissingElements,
  getAspect,
  mapTextStyles,
  orderArray,
} from './Storefront.transformations.utils'
import * as constants from './constants'

export const formatStorefront = (storefront = {}, brandId = '') => {
  const {
    brandId: brandIdFromQuery,
    __typename,
    rows,
    textStyles,
    statusSince = '',
    ...storefrontProps
  } = storefront
  const parsedRows = orderArray(relayConnectionToArray(rows)).map(
    ({ __typename, elements, title, template: rowTemplate, id }) => {
      const [template, rowType] = rowTemplate.split('*')
      let rowSettings, aspect, position, overlayText, rowsNumber, size, width
      const { contentTypes } = constants.NEW_ITEM_OPTIONS.reduce(
        (acc, group) => {
          const value = group.items.find((row) => row.rowType === rowType)
          return value ? value : { ...acc }
        },
        {},
      )

      const parsedElements = orderArray(
        addMissingElements(elements, template, rowType, contentTypes, id),
      ).map(
        ({
          __typename,
          elementType,
          id,
          presentation,
          coverId,
          coverUrl,
          settings,
          ...restProps
        }) => {
          const formattedPresentation = presentation
            ? JSON.parse(presentation)
            : {}
          const formattedSettings = settings ? JSON.parse(settings) : {}
          rowSettings = formattedSettings ? formattedSettings : {}
          if (!isEmpty(formattedPresentation)) {
            aspect = formattedPresentation?.aspect
            position = formattedPresentation?.position
            rowsNumber = formattedPresentation?.rowsNumber
            size = formattedPresentation?.size
            width = formattedPresentation?.width
          }
          overlayText =
            rowType === constants.MEDIA_BANNER &&
            elementType === constants.TEXT_WIDGET
              ? JSON.parse(restProps.content).overlayText
              : ''
          return {
            elementType,
            id,
            content: restProps.content || {
              ...(coverId && { imageId: coverId }),
              ...(coverUrl && { imageUrl: coverUrl }),
              ...restProps,
              ...formattedPresentation,
            },
          }
        },
      )
      const showTitle =
        overlayText && rowType === constants.MEDIA_BANNER
          ? overlayText
          : Boolean(title)
      const settings = {
        showTitle: showTitle,
        ...(width ? { width } : {}),
        ...(aspect ? { aspect } : getAspect(rowType)),
        ...(rowType === constants.MEDIA_BANNER
          ? { position: constants.OVERLAY }
          : position && { position }),
        ...(rowsNumber && { rowsNumber }),
        ...(size
          ? { size }
          : (rowType === constants.IMAGE_TEXT ||
              rowType === constants.VIDEO_TEXT ||
              rowType === constants.LINESHEET_TEXT ||
              rowType === constants.DOCUMENT_TEXT ||
              rowType === constants.COLLECTION_TEXT) && {
              size: LARGE,
            }),
        ...rowSettings,
      }
      let rowSettingsTooltip = getRowSettingsTooltip({
        elements: parsedElements,
        template,
        rowType,
        id,
      })

      return {
        template,
        rowType,
        id,
        title,
        settings,
        elements: parsedElements,
        tooltip: rowSettingsTooltip,
      }
    },
  )
  return {
    ...storefrontProps,
    brandId: brandIdFromQuery || brandId,
    updatedOn: statusSince,
    rows: parsedRows,
    textStyles: mapTextStyles(textStyles),
  }
}
