import React, { useCallback, useEffect, useMemo, useState } from 'react'

import isEmpty from 'lodash/isEmpty'
import { useDropzone } from 'react-dropzone'
import 'react-image-crop/dist/ReactCrop.css'

import { isGlobalId, toGlobalId } from 'utils/transformations/graphql'

import { useRenderToasters } from 'hooks/useRenderToasters'

import { Icon, Loader } from 'components/Core'

import { Image } from '../BrandDetails'
import * as S from './DragDropImg.styles'
import withModal from 'containers/ModalHandler'
import { IMAGE } from 'containers/Showroom/GridLayout/GridElements/GridElements.constants'
import { ASPECT_PORTRAIT } from 'features/Storefront/constants'
import { uploadMediaFile } from 'features/Storefront/utils'
import { useImage } from 'features/Storefront/widgets/ImageWidget/AddImageTooltip/AddImageTooltip.hooks'

const getDragAndDropBaseStyle = (cropImage: boolean | undefined) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 5,
  borderStyle: 'solid',
  borderColor: '#0cc0de',
  backgroundColor: '#e9f4f6',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: cropImage ? '200px' : '140px',
  cursor: 'pointer',
  textAlign: 'center' as 'center',
})
const dragAndDropRejectStyle = {
  borderColor: 'red',
}
const IMG_SIZE_LIMIT: number = 4000000 // 4MB

type CroppedImageValue = {
  croppedImageSrc: Blob | string // change only to Blob once the AddImageTooltip component has been migrated to TS
  imageId: string
  imageUrl: string
}
type File = {
  size: number
  type?: string
}
type Messages = {
  limit: string
  requirements: string
  unsopported: string
}
type ImageFile = {
  id: string
  url: string
}
type DragDropImgProps = {
  alt: string
  id: string
  messages: Messages
  imgFile: ImageFile
  selectImage: (id: string, image: Image | null, resetValue?: boolean) => void
  cropImage?: boolean
  openDynamicModal: () => void
  shouldOpenModal: boolean
}
const DragDropImg: React.FC<DragDropImgProps> = ({
  alt,
  id,
  messages,
  imgFile,
  selectImage,
  cropImage,
  openDynamicModal,
  shouldOpenModal,
}) => {
  const [imgLimitExceeded, setLimitExceeded] = useState<boolean>(false)
  const [imgValidType, setImgValidType] = useState<boolean>(true)
  const [isCroppedImg, setCroppedImg] = useState<boolean>(true)
  const [imgLoading, setImgLoading] = useState<boolean>(false)
  const [isBrowsingNewImage, setIsBrowsingNewImage] = useState<boolean>(false)
  const { renderErrorToast } = useRenderToasters()

  // Drop and crop hooks
  const {
    imageSelection,
    imageCrop,
    croppedURL,
    setSelectedImage,
    selectedImage,
    selectingImage: imageModalOpen,
    setCrop,
  } = useImage({
    value: imgFile,
    openDynamicModal,
    onApplyChanges: () => setImgLoading(false),
    aspect: ASPECT_PORTRAIT,
    onSelectImageAction: () => setIsBrowsingNewImage(false),
    onCancelImageSelection: () => {
      setIsBrowsingNewImage(false)
      setCroppedImg(true)
      setImgLoading(false)
    },
  })

  // Select image actions
  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      selectImage(id, imgFile, false)
      setIsBrowsingNewImage(false)
      setCroppedImg(false)
    }
    setCrop(false)

    const uploadedImage = await uploadMediaFile(acceptedFiles[0], IMAGE, () =>
      renderErrorToast({
        description: 'There was a problem uploading your image.',
      }),
    )
    const imageId: string = isGlobalId(uploadedImage?.id)
      ? uploadedImage?.id
      : toGlobalId('Upload', uploadedImage?.id)
    const imageUrl = uploadedImage?.url
    setImgLoading(false)
    selectImage(id, { id: imageId, url: imageUrl }, false)
    setIsBrowsingNewImage(false)
  }, [])

  const onCrop = async (value: CroppedImageValue) => {
    let croppedImageId = value.imageId
    let imageUrl = value.imageUrl
    const { croppedImageSrc } = value

    if (croppedImageSrc) {
      const uploadedCroppedImage = await uploadMediaFile(
        croppedImageSrc,
        IMAGE,
        () =>
          renderErrorToast({
            description: 'There was a problem uploading your image.',
          }),
      )
      croppedImageId = toGlobalId('Upload', uploadedCroppedImage.id) ?? ''
      imageUrl = uploadedCroppedImage.url
    }
    setCroppedImg(true)
    setImgLoading(false)
    selectImage(
      id,
      {
        id: croppedImageId,
        url: imageUrl,
      },
      false,
    )
    setIsBrowsingNewImage(false)
  }

  const validateImage = (id: string, file: File): null => {
    if (file.size >= IMG_SIZE_LIMIT) {
      setLimitExceeded(true)
      selectImage(id, null)
    } else {
      setLimitExceeded(false)
    }
    if (file.type) {
      setImgValidType(true)
    } else {
      setImgValidType(false)
      selectImage(id, null)
    }
    return null
  }

  const { getRootProps, getInputProps, open, isDragReject } = useDropzone({
    onDrop,
    maxSize: IMG_SIZE_LIMIT,
    maxFiles: 1,
    accept: 'image/*',
    validator: (file) => validateImage(id, file),
  })

  const style = useMemo(
    () => ({
      ...getDragAndDropBaseStyle(cropImage),
      ...(isDragReject || imgLimitExceeded || !imgValidType
        ? dragAndDropRejectStyle
        : {}),
    }),
    [isDragReject, imgLimitExceeded, imgValidType],
  )

  useEffect(() => {
    if (imgFile) {
      setSelectedImage(imgFile)
      setImgLoading(false)
    }
  }, [imgFile])

  useEffect(() => {
    if (shouldOpenModal && !isBrowsingNewImage) open()
  }, [shouldOpenModal, imgFile])

  useEffect(() => {
    if (!isEmpty(selectedImage)) selectImage(id, selectedImage, false)
  }, [selectedImage])

  useEffect(() => {
    if (!imageModalOpen) setImgLoading(imageModalOpen)
  }, [imageModalOpen])

  const renderErrorMessage = () => {
    if (imgLimitExceeded) {
      return <S.Message invalid>{messages.limit}</S.Message>
    } else if (!imgValidType) {
      return <S.Message invalid>{messages.unsopported}</S.Message>
    }
  }
  const renderButtons = () => {
    if (!isEmpty(imgFile) && cropImage && !isCroppedImg) {
      return (
        <div className="button applyButton">
          <button
            onClick={() => {
              if (isBrowsingNewImage) {
                setIsBrowsingNewImage(false)
                setCroppedImg(true)
                setImgLoading(false)
                return
              }
              setImgLoading(true)
              onCrop({
                imageId: imgFile?.id,
                imageUrl: imgFile?.url,
                croppedImageSrc: croppedURL,
              })
            }}
          >
            {!isBrowsingNewImage ? 'Apply Changes' : 'Cancel'}
          </button>
        </div>
      )
    } else if (!isEmpty(imgFile) && (isCroppedImg || !cropImage)) {
      return (
        <div className="button">
          <button
            onClick={() => {
              selectImage(id, imgFile, true)
              setIsBrowsingNewImage(true)
              setCroppedImg(false)
            }}
          >
            Browse Images
          </button>
        </div>
      )
    }
  }

  const imageDrop = (
    <>
      <S.Subtitle>
        <Icon className="icon" name="upload" />
        Upload File
      </S.Subtitle>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <S.InputAreaOptions>
          <p>Drag and Drop Here</p>
          <p>or</p>
          <p className="browseFiles">Browse Files</p>
        </S.InputAreaOptions>
      </div>
      {renderErrorMessage()}
    </>
  )

  return (
    <>
      <Loader active={imgLoading} useFullScreen />
      <S.Container>
        {(isEmpty(imgFile) || isBrowsingNewImage) && imageDrop}
        {!isEmpty(imgFile) &&
          cropImage &&
          !isCroppedImg &&
          !isBrowsingNewImage && <S.CropContainer>{imageCrop}</S.CropContainer>}
        {!isEmpty(imgFile) &&
          (!cropImage || isCroppedImg) &&
          !isBrowsingNewImage && (
            <S.SelectedImage src={imgFile?.url} alt={alt} />
          )}
        <S.ButtonsContainer>
          <div
            className="button"
            onClick={() => {
              setImgLoading(true)
              setCroppedImg(false)
            }}
          >
            {imageSelection}
          </div>
          {renderButtons()}
        </S.ButtonsContainer>
        <S.Message>{messages.requirements}</S.Message>
      </S.Container>
    </>
  )
}

export default withModal(DragDropImg)
