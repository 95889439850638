export const CANCELLED_STATUS = {
  id: 'CANCELLED',
  publicValue: 0,
  label: 'Cancelled',
}
export const IN_PROGRESS_STATUS = {
  id: 'IN_PROGRESS',
  publicValue: 1,
  label: 'In Progress',
}
export const PENDING_STATUS = {
  id: 'PENDING',
  publicValue: 3,
  label: 'Pending',
}
export const APPROVED_STATUS = {
  id: 'APPROVED',
  publicValue: 4,
  label: 'Approved',
}
export const SHIPPED_STATUS = {
  id: 'SHIPPED',
  publicValue: 5,
  label: 'Shipped',
}
export const NOTES_STATUS = {
  id: 'ALL_NOTES',
  publicValue: 6,
  label: 'Notes',
}
