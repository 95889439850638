import { FC } from 'react'

import { sortBy } from 'lodash'

import {
  CarriedBrand,
  LocationType,
  ProductLine,
} from '__generated__/atlas-types'

import {
  StyledAddress,
  StyledContent,
  StyledSection,
  StyledSectionWithFlex,
  StyledSubSection,
  StyledSubtitle,
  StyledText,
  StyledTitle,
} from './LocationWidgetViewMode.styles'
import {
  getFormattedClienteleInfo,
  getFormattedLocation,
  hasClienteleInfo,
} from './LocationWidgetViewMode.utils'
import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

type LocationWidgetViewModeProps = {
  location: RetailerProfileLocation
}

const LocationAddress: FC<LocationWidgetViewModeProps> = ({ location }) => {
  const { formattedAddress, formattedLocationInfo } = getFormattedLocation(
    location,
  )
  return (
    <StyledSection>
      <StyledTitle>Address</StyledTitle>
      <StyledAddress
        className="noTranslate"
        data-testid={`location_addres1_address2_${location?.id}`}
      >
        <div>{formattedAddress}</div>
        <div>{formattedLocationInfo}</div>
        <div>{location.zip}</div>
        <div>{location.phone}</div>
      </StyledAddress>
    </StyledSection>
  )
}

const Clientele = ({
  clienteleInfo,
}: {
  clienteleInfo?: RetailerProfileLocation['clienteleInfo'] | null
}) => {
  const { hasBasicInfo, hasInfo } = hasClienteleInfo(clienteleInfo)
  const {
    basicInfoFormatted,
    clienteleDescriptionFormatted,
    clienteleInterestFormatted,
  } = getFormattedClienteleInfo(clienteleInfo)
  return (
    <>
      {hasInfo && (
        <StyledSection>
          <StyledSection>
            {hasBasicInfo && (
              <StyledSubSection>
                <StyledSubtitle>Demographics</StyledSubtitle>
                <StyledText>{basicInfoFormatted}</StyledText>
              </StyledSubSection>
            )}
            {Boolean(clienteleInfo?.descriptions?.length) && (
              <StyledSubSection>
                <StyledSubtitle>Described As</StyledSubtitle>
                <StyledText>{clienteleDescriptionFormatted}</StyledText>
              </StyledSubSection>
            )}
            {Boolean(clienteleInfo?.interests?.length) && (
              <StyledSubSection>
                <StyledSubtitle>Shops For</StyledSubtitle>
                <StyledText>{clienteleInterestFormatted}</StyledText>
              </StyledSubSection>
            )}
          </StyledSection>
        </StyledSection>
      )}
    </>
  )
}

const Categories = ({ categories }: { categories?: ProductLine[] | null }) => {
  const categoryNames = sortBy(categories, 'name')?.map(
    (category) => category.name,
  )
  return (
    <>
      {Boolean(categories?.length) && (
        <StyledSubSection>
          <StyledTitle>Categories</StyledTitle>
          <StyledSectionWithFlex>
            <StyledText>{categoryNames.join(', ')}</StyledText>
          </StyledSectionWithFlex>
        </StyledSubSection>
      )}
    </>
  )
}
const CarriedBrands = ({
  carriedBrands,
}: {
  carriedBrands?: CarriedBrand[] | null
}) => {
  const carriedBrandNames = carriedBrands?.map(
    (carriedBrand) => carriedBrand.name,
  )
  return (
    <>
      {Boolean(carriedBrands?.length) && (
        <StyledSubSection>
          <StyledTitle>Brands Carried</StyledTitle>
          <StyledSection>
            <StyledText className="notranslate">
              {carriedBrandNames?.join(', ')}
            </StyledText>
          </StyledSection>
        </StyledSubSection>
      )}
    </>
  )
}

const LocationWidgetViewMode: FC<LocationWidgetViewModeProps> = ({
  location,
}) => (
  <StyledContent>
    <div>
      {location?.name && (
        <StyledSection>
          <StyledTitle>Location Name</StyledTitle>
          <StyledText className="notranslate">{location?.name}</StyledText>
        </StyledSection>
      )}
      <LocationAddress location={location} />
    </div>
    <div>
      {location?.type && (
        <StyledSection>
          <StyledTitle>Type</StyledTitle>
          <StyledText>
            {location?.type === LocationType.STORE ? 'Store' : 'Office'}
          </StyledText>
        </StyledSection>
      )}
      {location?.yearEstablished && (
        <StyledSection>
          <StyledTitle>Year Established</StyledTitle>
          <StyledText className="notranslate">
            {location?.yearEstablished}
          </StyledText>
        </StyledSection>
      )}
      {(location?.wholesaleRange?.min || location?.wholesaleRange?.max) && (
        <StyledSection>
          <StyledTitle>Wholesale Price</StyledTitle>
          <StyledText className="notranslate">
            {`$${location?.wholesaleRange?.min} - $${location?.wholesaleRange?.max}`}
          </StyledText>
        </StyledSection>
      )}
    </div>
    <div>
      <Categories categories={location?.categories} />
      <CarriedBrands carriedBrands={location?.brandsCarried} />
    </div>
    <div>
      <Clientele clienteleInfo={location?.clienteleInfo} />
    </div>
  </StyledContent>
)

export default LocationWidgetViewMode
