import classNames from 'classnames'
import PropTypes from 'prop-types'

import iconLoading from 'assets/images/icon_loading.gif'

import styles from './Loader.less'

const Loader = (props) => {
  const loaderClass = classNames(
    styles.Loader,
    {
      [styles.page]: props.children === null,
      [styles.active]: props.active,
    },
    props.className,
  )
  const dimmerClass = classNames(styles.dimmer, props.dimmerClassName, {
    [styles.dimmed]: props.active,
    [styles.fullscreen]: props.useFullScreen,
  })

  return (
    <div className={loaderClass} data-testid={props.dataTestId}>
      <div className={dimmerClass}>
        <img src={iconLoading} alt="Loading..." />
      </div>
      {props.children}
    </div>
  )
}

Loader.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  useFullScreen: PropTypes.bool,
  className: PropTypes.string,
  dimmerClassName: PropTypes.string,
  dataTestId: PropTypes.string,
}

Loader.defaultProps = {
  active: false,
  children: null,
  useFullScreen: false,
  className: '',
  dimmerClassName: '',
  dataTestId: '',
}

export default Loader
