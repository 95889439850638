import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Textarea.less'

class Textarea extends Component {
  state = { value: this.defaultValue() }

  defaultValue() {
    return this.props.defaultValue || ''
  }

  componentDidUpdate() {
    if (this.state.value === '' && this.state.value !== this.defaultValue()) {
      this.setState({ value: this.defaultValue() })
    }
  }

  handleChange = (event) => {
    const {
      target: { value },
    } = event
    const { maxLength } = this.props

    const truncatedChars = maxLength ? value.slice(0, maxLength) : value
    if (truncatedChars === this.state.value) {
      return
    }
    this.setState({ value: truncatedChars })
    this.props.onChange(event, {
      value: truncatedChars,
      defaultValue: this.defaultValue(),
    })
  }

  handleBlur = (event) => {
    this.props.onBlur(event, {
      value: this.state.value,
      defaultValue: this.defaultValue(),
    })
  }

  render() {
    const {
      disabled,
      name,
      className,
      placeholder,
      error,
      success,
    } = this.props
    return (
      <div
        className={classNames(styles.Textarea, className, {
          [styles.disabled]: disabled,
          [styles.success]: !error && success,
          [styles.error]: !success && error,
        })}
      >
        <textarea
          name={name}
          onBlur={this.handleBlur}
          value={this.state.value}
          disabled={disabled}
          onChange={this.handleChange}
          placeholder={placeholder}
        />
      </div>
    )
  }
}

Textarea.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  maxLength: PropTypes.number,
  placeholder: PropTypes.string,
  success: PropTypes.bool,
  error: PropTypes.bool,
  name: PropTypes.string,
}

Textarea.defaultProps = {
  defaultValue: '',
  disabled: false,
  className: null,
  onChange: () => {},
  onBlur: () => {},
  maxLength: null,
  placeholder: '',
  success: false,
  error: false,
  name: null,
}

export default Textarea
