import get from 'lodash/get'

import { timestampToUnixEpoch } from 'utils/dates'
import ArrayOfIdsFilter from 'utils/filters/arrayOfIdsFilter'
import BooleanFilter from 'utils/filters/booleanFilter'
import CheckboxFilter from 'utils/filters/checkboxFilter'
import DateFilter from 'utils/filters/dateFilter'
import SingleFilter from 'utils/filters/singleFilter'
import { formatNameWithCode } from 'utils/formatters'

import { CREATED_BY_RETAILER_FILTER_KEY } from 'store/news/constants'

import styles from './OrderListFilters.less'
import messages from './OrderListFilters.messages'

export const showNewsBubble = ({
  newRetailerOrdersCount,
  visibleNews,
  lastLogin,
}) => {
  const hasDismissed =
    timestampToUnixEpoch(lastLogin) <
    visibleNews[CREATED_BY_RETAILER_FILTER_KEY]
  return newRetailerOrdersCount !== 0 && !hasDismissed
}

// All the filters in Order List
export const orderFilters = {
  status: new ArrayOfIdsFilter({
    filterKey: 'status',
    label: messages.status,
    isQuickFilter: true,
    dataKey: 'status',
    options: [
      {
        id: 'PENDING',
        name: messages.pending,
      },
      {
        id: 'APPROVED',
        name: messages.approved,
      },
      {
        id: 'SHIPPED',
        name: messages.shipped,
      },
      {
        id: 'CANCELLED',
        name: messages.cancelled,
      },
    ],
    defaultState: [
      {
        id: 'PENDING',
        name: messages.pending,
      },
      {
        id: 'APPROVED',
        name: messages.approved,
      },
    ],
  }),
  quantities: new ArrayOfIdsFilter({
    filterKey: 'status',
    label: messages.quantities,
    isQuickFilter: true,
    dataKey: 'quantities',
    options: [
      {
        id: 'ALL_NOTES',
        name: messages.allNotes,
      },
      {
        id: 'NOTES_WITH_QUANTITIES',
        name: messages.notesWithQuantities,
      },
      {
        id: 'NOTES_WITHOUT_QUANTITIES',
        name: messages.notesWithoutQuantities,
      },
    ],
    defaultState: [
      {
        id: 'ALL_NOTES',
        name: messages.allNotes,
      },
      {
        id: 'NOTES_WITH_QUANTITIES',
        name: messages.notesWithQuantities,
      },
      {
        id: 'NOTES_WITHOUT_QUANTITIES',
        name: messages.notesWithoutQuantities,
      },
    ],
  }),
  salesRepresentatives: new ArrayOfIdsFilter({
    filterKey: 'salesRepresentative',
    label: messages.salesRep,
    dataKey: 'salesRepresentatives',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  collections: new ArrayOfIdsFilter({
    filterKey: 'collections',
    label: messages.linesheet,
    dataKey: 'collections',
  }),
  createdDateRange: new DateFilter({
    filterKey: 'createdDateRange',
    label: messages.created,
    dataKey: 'createdDateRange',
    messages,
    styles,
  }),
  modifiedDateRange: new DateFilter({
    filterKey: 'modifiedDateRange',
    label: messages.modified,
    dataKey: 'modifiedDateRange',
    messages,
    styles,
  }),
  deliveryStartDateRange: new DateFilter({
    filterKey: 'deliveryStartDateRange',
    label: messages.startShip,
    dataKey: 'deliveryStartDateRange',
    messages,
    styles,
  }),
  deliveryCompleteDateRange: new DateFilter({
    filterKey: 'deliveryCompleteDateRange',
    label: messages.completeShip,
    dataKey: 'deliveryCompleteDateRange',
    messages,
    styles,
  }),
  types: new ArrayOfIdsFilter({
    filterKey: 'types',
    label: messages.type,
    dataKey: 'types',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  exportStatusSuccess: new CheckboxFilter({
    filterKey: 'exportStatus',
    filterSubgroupKey: 'success',
    label: messages.success,
    isSearchable: false,
    dataKey: 'exportStatusSuccess',
    optionsKey: 'orderExportIntegrations',
    styles,
  }),
  exportStatusFailed: new CheckboxFilter({
    filterKey: 'exportStatus',
    filterSubgroupKey: 'failed',
    label: messages.failed,
    isSearchable: false,
    dataKey: 'exportStatusFailed',
    optionsKey: 'orderExportIntegrations',
    styles,
  }),
  exportStatusScheduled: new CheckboxFilter({
    filterKey: 'exportStatus',
    filterSubgroupKey: 'scheduled',
    label: messages.scheduled,
    isSearchable: false,
    dataKey: 'exportStatusScheduled',
    optionsKey: 'orderExportIntegrations',
    isDisabled: (selectedFilters) =>
      get(selectedFilters, 'exportStatus.modified', false),
    styles,
  }),
  exportStatusNone: new CheckboxFilter({
    filterKey: 'exportStatus',
    filterSubgroupKey: 'none',
    label: messages.none,
    isSearchable: false,
    dataKey: 'exportStatusNone',
    optionsKey: 'orderExportIntegrations',
    isDisabled: (selectedFilters) =>
      get(selectedFilters, 'exportStatus.modified', false),
    styles,
  }),
  orderEvents: new ArrayOfIdsFilter({
    filterKey: 'events',
    label: messages.event,
    dataKey: 'orderEvents',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.code),
  }),
  divisions: new ArrayOfIdsFilter({
    filterKey: 'divisions',
    label: messages.divisionCode,
    dataKey: 'divisions',
    nameFormatter: (filterOption) => filterOption.code,
  }),
  modifiedOrderFilter: new BooleanFilter({
    filterKey: 'exportStatus',
    filterSubgroupKey: 'modified',
    dataKey: 'modifiedOrderFilter',
    label: messages.modified,
    modifierType: 'checkbox',
    styles,
  }),
  priceTypeIds: new ArrayOfIdsFilter({
    filterKey: 'priceTypeIds',
    label: messages.priceType,
    dataKey: 'priceTypeIds',
    nameFormatter: (filterOption) =>
      formatNameWithCode(filterOption.name, filterOption.currency.code),
  }),
  createdByRetailer: new SingleFilter({
    filterKey: 'createdByRetailer',
    label: messages.createdByRetailer,
    dataKey: 'createdByRetailer',
    // TODO(sperez): ch39779 move created by retailer filter bubble out of news
    newsKey: CREATED_BY_RETAILER_FILTER_KEY,
    defaultState: false,
    showFilterHandler: ({ anyRetailerCreatedOrders }) =>
      anyRetailerCreatedOrders,
    getBubbleContent: ({ newRetailerOrdersCount }) => newRetailerOrdersCount,
    showNewsBubble,
  }),
  brandBuyers: new ArrayOfIdsFilter({
    filterKey: 'buyers',
    label: messages.buyer,
    dataKey: 'brandBuyers',
  }),
}

// Filter Setup for the Brand Order List Filter
const brandFilterSetup = [
  {
    key: 'salesRepresentative',
    title: messages.salesRep,
    noTranslate: true,
    filters: [orderFilters.salesRepresentatives],
  },
  {
    key: 'collections',
    title: messages.linesheet,
    noTranslate: true,
    filters: [orderFilters.collections],
  },
  {
    key: 'dates',
    title: messages.date,
    noTranslate: false,
    filters: [
      orderFilters.createdDateRange,
      orderFilters.modifiedDateRange,
      orderFilters.deliveryStartDateRange,
      orderFilters.deliveryCompleteDateRange,
    ],
  },
  {
    key: 'types',
    title: messages.type,
    noTranslate: true,
    filters: [orderFilters.types],
  },
  {
    key: 'exportStatus',
    title: messages.exportStatus,
    noTranslate: false,
    modifiers: [orderFilters.modifiedOrderFilter],
    filters: [
      orderFilters.exportStatusSuccess,
      orderFilters.exportStatusFailed,
      orderFilters.exportStatusScheduled,
      orderFilters.exportStatusNone,
    ],
  },
  {
    key: 'orderEvents',
    title: messages.event,
    noTranslate: true,
    filters: [orderFilters.orderEvents],
  },
  {
    key: 'divisions',
    title: messages.divisionCode,
    noTranslate: true,
    filters: [orderFilters.divisions],
  },
  {
    key: 'priceTypeIds',
    title: messages.priceType,
    noTranslate: true,
    filters: [orderFilters.priceTypeIds],
  },
  {
    key: 'createdByRetailer',
    title: messages.createdByRetailer,
    noTranslate: false,
    filters: [orderFilters.createdByRetailer],
  },
]

// Filter Setup for the Retailer Order List Filter
const retailerFilterSetup = [
  {
    key: 'brandBuyers',
    title: messages.buyer,
    noTranslate: true,
    filters: [orderFilters.brandBuyers],
  },
  {
    key: 'dates',
    title: messages.date,
    noTranslate: false,
    filters: [
      orderFilters.createdDateRange,
      orderFilters.modifiedDateRange,
      orderFilters.deliveryStartDateRange,
      orderFilters.deliveryCompleteDateRange,
    ],
  },
]

export const getFilterSetup = (isBrandUser) =>
  isBrandUser ? brandFilterSetup : retailerFilterSetup
