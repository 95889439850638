import { handleActions } from 'redux-actions'

import withStorage from 'store/utils/storage/withStorage'

import { SET_PRICETYPE, SET_RETAILER } from './constants'

export const defaultState = {
  retailer: null,
  priceType: null,
}

const reducer = handleActions(
  {
    [SET_PRICETYPE]: (state, { payload: priceType }) => ({
      ...state,
      priceType,
    }),
    [SET_RETAILER]: (state, { payload: retailer }) => ({
      ...state,
      retailer,
    }),
  },
  defaultState,
)

export default withStorage(reducer, {
  key: 'IN_ORDER_REPORT',
  version: 1,
})
