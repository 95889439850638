import { FC, useEffect, useRef, useState } from 'react'

const loadScript = (callback: Function) => {
  const scriptID = 'orb360embedscript'
  const scriptSrc = 'https://cdn.orb360.tech/orb360embed/v1.2.5/orb360embed.js'

  const existingScript = document.getElementById(scriptID)

  if (!existingScript) {
    const script = document.createElement('script')
    script.src = scriptSrc

    script.id = scriptID
    document.body.appendChild(script)

    script.onload = () => {
      if (callback) {
        callback()
      }
    }
  }

  if (existingScript && callback) {
    callback()
  }
}

type IOrb360EmbedPlayer = {
  playerData: string
}

/**
 * More info:
 * https://orb360.tech/en/docs/player
 */
type ConstructorArgsOrb360Embed = {
  target: HTMLElement
  tenant?: {
    branding?: boolean
  }
  endpoint?: string
  images?: string[]
  videos?: string[]
  query?: {}
  allowFullscreen?: boolean
  hideControls?: boolean
  hideAudioControls?: boolean
  canvas?: {
    initialImageSize?: number
    zoomedImageSize?: number
    rotationDuration?: number
    playMode?: 'none' | 'once' | 'continuous'
    defaultZoom?: number
    maxZoom?: number
    minZoom?: number
    zoomStep?: number
    clickZoomStep?: number
    dragPixelsForRotation?: number
    orientation?: number
  }
}

class Orb360Embed {
  constructor(_args: ConstructorArgsOrb360Embed) {
    return
  }
  destroy() {}
}

declare global {
  interface Window {
    Orb360Embed: typeof Orb360Embed
  }
}

const destroyOrb360Node = () => {
  const container = document.querySelector('#orb360embed')
  if (container) {
    container.innerHTML = ''
  }
}

const JOOR_Orb360Embed = (args: ConstructorArgsOrb360Embed): Orb360Embed => {
  const player = new window.Orb360Embed(args)
  player.destroy = destroyOrb360Node
  return player
}

const Orb360EmbedPlayer: FC<IOrb360EmbedPlayer> = ({ playerData = '' }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [orb360LibLoaded, setOrb360LibLoaded] = useState(false)
  const player = useRef<Orb360Embed | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    loadScript(() => {
      setScriptLoaded(true)
    })
    if (window.Orb360Embed) {
      setOrb360LibLoaded(true)
    }
  })

  useEffect(() => {
    const embedContainer = containerRef?.current
    if (!player?.current && orb360LibLoaded && playerData && embedContainer) {
      player.current = JOOR_Orb360Embed({
        target: embedContainer,
        canvas: {
          playMode: 'continuous',
        },
        ...JSON.parse(playerData),
      })
    }
  }, [orb360LibLoaded, playerData])

  // Handle changes when only the player data provided changes.
  useEffect(() => {
    if (player.current && player.current.destroy) {
      const embedContainer = containerRef?.current
      player.current.destroy()
      player.current = JOOR_Orb360Embed({
        target: embedContainer,
        canvas: {
          playMode: 'continuous',
        },
        ...JSON.parse(playerData),
      })
    }
  }, [playerData])

  if (!scriptLoaded) {
    return null
  }

  return <div id="orb360embed" ref={containerRef} />
}

export default Orb360EmbedPlayer
