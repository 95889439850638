export default {
  action: 'Apply Filters',
  search: 'Search for PO# or Retailer',
  yourSelection: 'Your Selection',
  clearAll: 'Clear All',
  title: 'Search or Filter',
  status: 'Status',
  salesRep: 'Sales Rep',
  startShip: 'Start Ship',
  completeShip: 'Complete Ship',
  date: 'Date',
  allNotes: 'Notes',
  pending: 'Pending',
  approved: 'Approved',
  shipped: 'Shipped',
  cancelled: 'Cancelled',
  addDate: 'Add a date',
  addDateFilter: 'Add Date as Filter',
  draft: 'Draft',
}
