import { handleActions } from 'redux-actions'

export const defaultState = {
  selectedAssortments: {},
}

const reducer = handleActions(
  {
    'ASSORTMENT/RESET_SELECTED_ASSORTMENTS': (state) => ({
      ...state,
      selectedAssortments: defaultState.selectedAssortments,
    }),
    'ASSORTMENT/BULK_TOGGLE_WORKSPACES': (
      state,
      { payload: assortmentIds },
    ) => {
      const selectedAssortments = {}
      assortmentIds.forEach((id) => (selectedAssortments[id] = true))
      return {
        ...state,
        selectedAssortments,
      }
    },
    'ASSORTMENT/TOGGLE_ASSORTMENT': (state, { payload: assortmentId }) => {
      const { selectedAssortments } = state

      const assortmentIsSelected = assortmentId in selectedAssortments

      if (assortmentIsSelected) {
        const {
          [assortmentId]: _isAssortmentSelected,
          ...selectionWithoutPayload
        } = selectedAssortments
        return {
          ...state,
          selectedAssortments: selectionWithoutPayload,
        }
      }

      return {
        ...state,
        selectedAssortments: {
          ...selectedAssortments,
          [assortmentId]: true,
        },
      }
    },
    'ASSORTMENT/TOGGLE_ASSORTMENTS_ON_PAGE': (state, action) => {
      const { selectedAssortments } = state
      const { payload } = action

      const allAssortmentsAreSelected = payload.every(
        (assortmentId) => assortmentId in state.selectedAssortments,
      )

      const assortmentsSelectedOnOtherPages = payload.reduce(
        (result, assortmentId) => {
          if (assortmentId in state.selectedAssortments) {
            const {
              [assortmentId]: _isAssortmentSelected,
              ...selectionWithoutPayload
            } = result
            return selectionWithoutPayload
          }
          return result
        },
        selectedAssortments,
      )

      if (allAssortmentsAreSelected) {
        return {
          ...state,
          selectedAssortments: assortmentsSelectedOnOtherPages,
        }
      }

      const assortmentsInPage = payload.reduce(
        (result, assortmentId) => ({
          ...result,
          [assortmentId]: true,
        }),
        {},
      )

      return {
        ...state,
        selectedAssortments: {
          ...assortmentsSelectedOnOtherPages,
          ...assortmentsInPage,
        },
      }
    },
  },
  defaultState,
)

export default reducer
