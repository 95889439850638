// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AutosaveLoader_AutosaveLoader__1GbsX {\n  display: inline-block;\n  margin-top: 10px;\n  margin-left: 10px;\n  margin-right: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Core/AutosaveLoader/AutosaveLoader.less"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".AutosaveLoader {\n  display: inline-block;\n  margin-top: 10px;\n  margin-left: 10px;\n  margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AutosaveLoader": "AutosaveLoader_AutosaveLoader__1GbsX"
};
export default ___CSS_LOADER_EXPORT___;
