import find from 'lodash/find'
import get from 'lodash/get'
import intersectionBy from 'lodash/intersectionBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import PropTypes from 'prop-types'

import { formatDropdownOptions } from 'utils/formatters'
import getDisplayName from 'utils/getDisplayName'

const containsKey = (warehouses, warehouseId) =>
  !isEmpty(find(warehouses, (warehouse) => warehouse.key === warehouseId))

const containsId = (warehouses, warehouseId) =>
  !isEmpty(find(warehouses, (warehouse) => warehouse.id === warehouseId))

export const getDefaultWarehouseId = (
  warehouses,
  brandWarehousesWithInventory,
  selectedWarehouseId,
  defaultWarehouse,
) => {
  // Order of a warehouse in a dropdown:
  // - selected warehouse with the inventory
  // - default warehouse with the inventory
  // - first warehouse with the inventory
  // - selected warehouse without the inventory
  // - default warehouse without the inventory
  // - first warehouse without the inventory
  const defaultId = get(defaultWarehouse, 'id')

  const selectedWarehouseInWarehouses = containsKey(
    warehouses,
    selectedWarehouseId,
  )
  const defaultInWarehouses = containsKey(warehouses, defaultId)

  const candidates = [
    ...(selectedWarehouseInWarehouses ? [selectedWarehouseId] : []),
    ...(defaultInWarehouses ? [defaultId] : []),
    ...map(warehouses, (warehouse) => warehouse.key),
  ]

  const candidatesWithInventory = sortBy(
    candidates,
    (warehouseId) => !containsId(brandWarehousesWithInventory, warehouseId),
  )

  return get(candidatesWithInventory, '[0]', null)
}

export const getWarehousesFromConnection = (
  brandWarehouses,
  warehousesFromConnection,
) => {
  const formattedWarehousesFromConnection = formatDropdownOptions(
    warehousesFromConnection,
    undefined,
    false,
    'notranslate',
  )
  const formattedbrandWarehouses = formatDropdownOptions(
    brandWarehouses,
    undefined,
    false,
    'notranslate',
  )
  if (!isEmpty(formattedWarehousesFromConnection)) {
    return intersectionBy(
      formattedWarehousesFromConnection,
      formattedbrandWarehouses,
      'key',
    )
  }
  return formattedbrandWarehouses
}

export const getWarehousesOptions = (
  isBrandUser,
  brandWarehouses,
  brandWarehousesWithInventory,
) => {
  // Brands are able to select from all warehouses. Warehouse with inventories come first.
  // Retailers select only from warehouses with inventories
  const defaultBrandWarehouses =
    brandWarehousesWithInventory.length > 0
      ? brandWarehousesWithInventory
      : brandWarehouses.length > 0
      ? [brandWarehouses[0]]
      : []
  return isBrandUser
    ? sortBy(brandWarehouses, (warehouse) =>
        isEmpty(
          find(
            brandWarehousesWithInventory,
            (warehouseWithInventory) =>
              warehouseWithInventory.id === warehouse.id,
          ),
        ),
      )
    : defaultBrandWarehouses
}

export const withWarehousesFormatter = (WrappedComponent) => {
  const Wrapper = (props) => {
    const {
      isBrandUser,
      brandWarehouses,
      brandWarehousesWithInventory,
      warehousesFromConnection,
      selectedWarehouseId,
      ...rest
    } = props

    const accountDefaultWarehouse = warehousesFromConnection.find(
      (warehouse) => warehouse.default,
    )

    const brandWarehousesOptions = getWarehousesOptions(
      isBrandUser,
      brandWarehouses,
      brandWarehousesWithInventory,
    )

    const warehouses = getWarehousesFromConnection(
      brandWarehousesOptions,
      warehousesFromConnection,
    )
    const newProps = {
      ...rest,
      brandWarehousesWithInventory,
      warehouses,
      selectedWarehouseId,
      defaultWarehouseId: getDefaultWarehouseId(
        warehouses,
        brandWarehousesWithInventory,
        selectedWarehouseId,
        accountDefaultWarehouse,
      ),
      accountDefaultWarehouse:
        accountDefaultWarehouse || warehousesFromConnection[0],
    }
    return <WrappedComponent {...newProps} />
  }

  Wrapper.displayName = `withWarehousesFormatter(${getDisplayName(
    WrappedComponent,
  )})`

  Wrapper.propTypes = {
    isBrandUser: PropTypes.bool,
    brandWarehouses: PropTypes.array,
    brandWarehousesWithInventory: PropTypes.array,
    warehousesFromConnection: PropTypes.array,
    selectedWarehouseId: PropTypes.string,
  }
  Wrapper.defaultProps = {
    isBrandUser: true,
    brandWarehouses: [],
    brandWarehousesWithInventory: [],
    warehousesFromConnection: [],
    selectedWarehouseId: null,
  }
  return Wrapper
}

export default withWarehousesFormatter
