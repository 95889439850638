import get from 'lodash/get'
import { handleActions } from 'redux-actions'

import {
  RESET_SELECTED_STYLE_COLORS,
  TOGGLE_ALL_STYLE_COLORS,
  TOGGLE_STYLE_COLOR,
} from './constants'

export const defaultState = {
  selectedStyleColors: {},
  countUnselectedStyles: 0,
}

const reducer = handleActions(
  {
    [TOGGLE_ALL_STYLE_COLORS]: (state, { payload: orders }) => {
      const selectedStyleColors = {}

      orders.forEach((order) => {
        const styleColorByOrder = {}
        get(order, 'uniqueStyles', []).forEach(({ styleColors }) => {
          styleColors.forEach((styleColor) => {
            styleColorByOrder[styleColor.id] = true
          })
        })
        selectedStyleColors[order.id] = styleColorByOrder
      })

      return {
        ...state,
        selectedStyleColors,
      }
    },
    [TOGGLE_STYLE_COLOR]: (state, { payload: { orderId, styleColorId } }) => {
      const { selectedStyleColors } = state
      const styleColors = get(selectedStyleColors, orderId)
      const isStyleColorSelected = styleColors && styleColorId in styleColors

      if (isStyleColorSelected) {
        const {
          [orderId]: { [styleColorId]: _, ...selectionWithoutPayload },
        } = selectedStyleColors

        return {
          ...state,
          selectedStyleColors: {
            ...state.selectedStyleColors,
            [orderId]: selectionWithoutPayload,
          },
          countUnselectedStyles: state.countUnselectedStyles + 1,
        }
      }

      return {
        ...state,
        selectedStyleColors: {
          ...state.selectedStyleColors,
          [orderId]: {
            ...state.selectedStyleColors[orderId],
            [styleColorId]: true,
          },
        },
        countUnselectedStyles: state.countUnselectedStyles - 1,
      }
    },
    [RESET_SELECTED_STYLE_COLORS]: (state) => ({
      ...state,
      selectedStyleColors: {
        ...defaultState.selectedStyleColors,
      },
      countUnselectedStyles: 0,
    }),
  },
  defaultState,
)

export default reducer
