const prefix = 'STOREFRONT/'
export const TOGGLE_EDIT_MODE = `${prefix}TOGGLE_EDIT_MODE`
export const TOGGLE_BRAND_NAVIGATION = `${prefix}TOGGLE_BRAND_NAVIGATION`
export const SET_BRAND_NAVIGATION = `${prefix}SET_BRAND_NAVIGATION`
export const INIT_PANEL = `${prefix}INIT_PANEL`
export const ADD_ROW = `${prefix}ADD_ROW`
export const SET_ROWS = `${prefix}SET_ROWS`
export const DELETE_ROW = `${prefix}DELETE_ROW`
export const UPDATE_ROW = `${prefix}UPDATE_ROW`
export const MARK_EDITING = `${prefix}MARK_EDITING`
export const MARK_SELECTED = `${prefix}MARK_SELECTED`
export const RESTORE_PANEL = `${prefix}RESTORE_PANEL`
export const VIEW_AS_TARGET = `${prefix}VIEW_AS_TARGET`
export const CLEAR_ACTIVE_ELEMENT = `${prefix}CLEAR_ACTIVE_ELEMENT`
export const UPDATE_TEXT_STYLES = `${prefix}UPDATE_TEXT_STYLES`
export const INIT_PROFILE = `${prefix}INIT_PROFILE`
export const UPDATE_PROFILE = `${prefix}UPDATE_PROFILE`
export const RESTORE_PROFILE = `${prefix}RESTORE_PROFILE`
export const INIT_PAGES = `${prefix}INIT_PAGES`
export const UPDATE_PAGES = `${prefix}UPDATE_PAGES`
export const RESTORE_PAGES = `${prefix}RESTORE_PAGES`
