/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react'

import { Button, SecondaryButton, typography } from '@joor/design-system'
import '@joor/design-system/dist/designTokens/variables.css'
import { compose } from 'lodash/fp'
import isEqual from 'lodash/isEqual'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { getAccountId, isAccountTypeBrand } from 'store/currentUser/selectors'
import { INIT_PANEL, UPDATE_TEXT_STYLES } from 'store/storefront/constants'
import { getBackupPanel, getPanel } from 'store/storefront/selectors'

import { Loader } from 'components/Core'

import TextSectionSettings from './TextSectionSettings/TextSectionSettings.component'
import withAnalytics from './TextSettings.analytics'
import {
  DEFAULT_SETTINGS,
  HEADING_ID,
  PARAGRAPH_ID,
  SUBTITLE_ID,
  TITLE_ID,
  TextStyleConfig,
} from './TextSettings.constants'
import { loadFont } from './utils'
import { useStorefront } from 'features/Storefront/Storefront.hooks'
import { mapTextStyles } from 'features/Storefront/Storefront.transformations.utils'
import { useUpsertStorefrontTextStyles } from 'features/Storefront/StorefrontNavbar/StorefrontNavbar.hooks'

export const TEXT_SETTINGS_APPLY_CHANGES_BUTTON =
  'text-settings-apply-changes-button'

export interface TextStyleElement extends TextStyleConfig {
  id?: string
  name: string
}
const Title = styled.span`
  font-size: 36px !important;
  line-height: 60px;
  letter-spacing: 1.8px;
  margin-bottom: 0px;
  ${typography.alpha.primaryActive.headline1};
`
const Subtitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  ${typography.alpha.primaryActive.body1};
`

const Separator = styled.div`
  width: 100%;
  height: 1px;
`

const ButtonContainer = styled.div`
  margin: 2em 0;
  display: flex;
  width: 85%;
  button:first-child {
    margin-right: 1em;
  }
`
const TextSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 28px;
  overflow-y: scroll;
  overflow-x: hidden;
`
const TextSettingsFormContainer = styled.div`
  width: 85%;
`
const FormContainer = styled.div`
  margin-top: 15px;
  > div:not(:first-child) {
    margin-top: 24px;
  }
`
export const getInitialValueForStyle = (
  initial: TextStyleElement[],
  styleName: string,
): TextStyleElement => {
  return {
    name: styleName,
    ...(initial?.find((style) => style.name === styleName) || {}),
  } as TextStyleElement
}

type TrackTextSettings = {
  Typeface?: string
  'Font Weight'?: string
  'Point Size'?: string
}
export type TrackTextStyles = (
  title: string,
  newSettings: TrackTextSettings,
) => void
type StorefrontTextSettingsFormProps = {
  textStyles: TextStyleElement[]
  onChange: (textStyles: TextStyleElement[]) => void
  trackTextStyles: TrackTextStyles
}
export type TextSectionSettingsOnChange = {
  fontFamily?: string
  fontSize?: string
  fontWeight?: string
}
const StorefrontTextSettingsForm: React.FC<StorefrontTextSettingsFormProps> = ({
  textStyles,
  onChange,
  trackTextStyles,
}) => {
  const [headingSettings, setHeadingSettings] = useState<TextStyleElement>(
    getInitialValueForStyle(textStyles, HEADING_ID),
  )
  const [titleSettings, setTitleSettings] = useState<TextStyleElement>(
    getInitialValueForStyle(textStyles, TITLE_ID),
  )
  const [subtitleSettings, setSubtitleSettings] = useState<TextStyleElement>(
    getInitialValueForStyle(textStyles, SUBTITLE_ID),
  )
  const [paragraphSettings, setParagraphSettings] = useState<TextStyleElement>(
    getInitialValueForStyle(textStyles, PARAGRAPH_ID),
  )

  const trackText = (title: string, newSettings: TextStyleElement) => {
    trackTextStyles(title, {
      Typeface: newSettings?.fontFamily,
      'Font Weight': newSettings?.fontWeight,
      'Point Size': newSettings?.fontSize,
    })
  }

  useEffect(() => {
    onChange([
      headingSettings,
      titleSettings,
      subtitleSettings,
      paragraphSettings,
    ])
  }, [headingSettings, titleSettings, subtitleSettings, paragraphSettings])

  useEffect(() => {
    loadFont(DEFAULT_SETTINGS[HEADING_ID].fontFamily)
  }, [])
  useEffect(() => {
    loadFont(headingSettings.fontFamily)
  }, [headingSettings.fontFamily])
  useEffect(() => {
    loadFont(titleSettings.fontFamily)
  }, [titleSettings.fontFamily])
  useEffect(() => {
    loadFont(subtitleSettings.fontFamily)
  }, [subtitleSettings.fontFamily])
  useEffect(() => {
    loadFont(paragraphSettings.fontFamily)
  }, [paragraphSettings.fontFamily])

  const DEFAULT_SECTIONS = [
    {
      title: HEADING_ID,
      tooltipContent:
        "Heading1 is the largest text on the screen. It's best for short, important text. By default, banner text is set in Heading1",
      onChange: (value: TextSectionSettingsOnChange) => {
        const newSettings = { ...headingSettings, ...value }
        trackText(HEADING_ID, newSettings)
        setHeadingSettings(newSettings)
      },
      defaultSettings: headingSettings,
    },
    {
      title: TITLE_ID,
      tooltipContent:
        'Title1 is the second largest text. By default section titles are set in Title1',
      onChange: (value: TextSectionSettingsOnChange) => {
        const newSettings = { ...titleSettings, ...value }
        trackText(TITLE_ID, newSettings)
        setTitleSettings(newSettings)
      },
      defaultSettings: titleSettings,
    },
    {
      title: SUBTITLE_ID,
      tooltipContent:
        'Subtitles are smaller than titles. By default, the title of individual elements (Documents, Linesheets, The Edit Collections) is set in Subtitle.',
      onChange: (value: TextSectionSettingsOnChange) => {
        const newSettings = { ...subtitleSettings, ...value }
        trackText(SUBTITLE_ID, newSettings)
        setSubtitleSettings(newSettings)
      },
      defaultSettings: subtitleSettings,
    },
    {
      title: PARAGRAPH_ID,
      tooltipContent:
        'Paragraph1 is used for long text. By default, descriptions are set in Paragraph1',
      onChange: (value: TextSectionSettingsOnChange) => {
        const newSettings = { ...paragraphSettings, ...value }
        trackText(PARAGRAPH_ID, newSettings)
        setParagraphSettings(newSettings)
      },
      defaultSettings: paragraphSettings,
    },
  ]

  useEffect(() => {
    DEFAULT_SECTIONS.forEach((section) => {
      const initial = getInitialValueForStyle(textStyles, section.title)
      if (!isEqual(initial, section.defaultSettings)) {
        section.defaultSettings = initial
        section.onChange(initial)
      }
    })
  }, [textStyles])

  return (
    <TextSettingsFormContainer>
      <div>
        <Title>Text Styles</Title>
        <Separator />
        <Subtitle>Customize with your Brands Fonts</Subtitle>
      </div>
      <FormContainer>
        {DEFAULT_SECTIONS.map((section) => (
          <TextSectionSettings
            title={section.title}
            tooltipContent={section.tooltipContent}
            onChange={section.onChange}
            defaultSettings={section.defaultSettings}
            key={section.title}
          />
        ))}
      </FormContainer>
    </TextSettingsFormContainer>
  )
}
type TextSettingsProps = {
  trackTextStyles: TrackTextStyles
}

const TextSettings: React.FC<TextSettingsProps> = ({ trackTextStyles }) => {
  const [storeFrontReady, setStoreFrontReady] = useState<boolean>(true)
  const panel = useSelector(getPanel)
  const { textStyles } = panel
  const { textStyles: backupTextStyles } = useSelector(getBackupPanel)
  const accountId = useSelector<number | string>(getAccountId)
  const isBrand = useSelector<boolean>(isAccountTypeBrand)
  const upsertStorefrontText = useUpsertStorefrontTextStyles()
  const disabled: boolean = isEqual(textStyles, backupTextStyles)
  const dispatch = useDispatch()
  useStorefront(true, accountId, isBrand, accountId)
  const { renderSuccessToast, renderErrorToast } = useRenderToasters()

  const handleChange = useCallback((textStyles) => {
    dispatch({ type: UPDATE_TEXT_STYLES, value: textStyles })
  }, [])

  const handleClick = async (): Promise<void> => {
    if (disabled) return
    setStoreFrontReady(false)
    try {
      const { data, errors } = await upsertStorefrontText(textStyles)
      setStoreFrontReady(true)
      if (!data || errors) {
        return renderErrorToast({
          description:
            'There was an error saving the text styles for storefront.',
        })
      }
      const formattedData = mapTextStyles(data?.upsertStorefrontTextStyles)
      const newPanel = {
        ...panel,
        textStyles: formattedData,
      }
      dispatch({
        type: INIT_PANEL,
        value: newPanel,
      })
      renderSuccessToast({ description: 'Your text styles have been saved.' })
    } catch {
      renderErrorToast({
        description:
          'There was an error saving the text styles for storefront.',
      })
    }
  }

  if (storeFrontReady) {
    return (
      <TextSettingsContainer>
        <StorefrontTextSettingsForm
          textStyles={textStyles}
          onChange={handleChange}
          trackTextStyles={trackTextStyles}
        />
        <ButtonContainer>
          <Button
            data-testid={TEXT_SETTINGS_APPLY_CHANGES_BUTTON}
            disabled={disabled}
            onClick={handleClick}
          >
            Save
          </Button>
          <SecondaryButton
            disabled={disabled}
            onClick={() =>
              dispatch({
                type: UPDATE_TEXT_STYLES,
                value: backupTextStyles,
              })
            }
          >
            Cancel
          </SecondaryButton>
        </ButtonContainer>
      </TextSettingsContainer>
    )
  }
  return <Loader active />
}

export default compose(withAnalytics)(TextSettings)
