import { useEffect, useState } from 'react'

export const useScreenSize = (reference) => {
  const [isUnderReference, setUnderReference] = useState(
    window.innerWidth <= reference || window.visualViewport?.width <= reference,
  )

  const onResize = () => {
    if (
      window.innerWidth <= reference ||
      window.visualViewport?.width <= reference
    ) {
      setUnderReference(true)
    } else {
      setUnderReference(false)
    }
  }
  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })

  return isUnderReference
}

export const useScreenWidth = () => {
  const [width, setWidth] = useState(window.innerWidth)
  const onResize = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  })
  return width
}
