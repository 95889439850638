import React, { ReactNode } from 'react'

import { useSelector } from 'react-redux'

import { getDisplayName } from 'utils/mixpanel'
import { useUserAnalytics } from 'utils/sdks/userAnalytics'

import {
  getAccountId,
  isLiteRetailer,
  isProRetailer,
} from 'store/currentUser/selectors'

type Params = {
  // TODO: type unknown to be changed for number when USER promise and reducer are migrated to TS
  user: unknown
  accountType: string
  brand: unknown
}

type Trackers = {
  trackViewQR: () => void
}

const withAnalytics = (
  WrappedComponent: typeof React.Component,
): (() => ReactNode) => {
  const Wrapper = () => {
    const { trackEvent } = useUserAnalytics()
    const isLite = useSelector<boolean>((state) => isLiteRetailer(state))
    const isPro = useSelector<boolean>(isProRetailer)
    const accountType: string = isLite
      ? 'Lite Retailer'
      : isPro
      ? 'Pro Retailer'
      : 'Brand'
    const accountId = useSelector<number>(getAccountId)

    const params: Params = {
      user: accountId,
      accountType,
      brand: accountId,
    }

    const trackers: Trackers = {
      trackViewQR: () => trackEvent('View QR code in new tab', { ...params }),
    }

    return <WrappedComponent {...trackers} />
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return Wrapper
}

export default withAnalytics
