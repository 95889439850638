import { handleActions } from 'redux-actions'

import { SELECTED_TAB_PARAM, WORKSPACES_TAB_VALUES } from './constants'

const queryParams = new URLSearchParams(window.location.search)
const tabValue = queryParams.get(SELECTED_TAB_PARAM)?.toLowerCase() || ''

export const defaultState = {
  selectedFilters: {
    statusFilter: {
      IN_WORK: true,
      IN_REVIEW: true,
    },
  },
  appliedFilters: {
    statusFilter: ['IN_WORK', 'IN_REVIEW'],
  },
  searchFilter: '',
  brandNoteFilter: tabValue === WORKSPACES_TAB_VALUES.BRAND_NOTES,
  archivedFilter: tabValue === WORKSPACES_TAB_VALUES.ARCHIVED,
}

const reducer = handleActions(
  {
    'ASSORTMENT_LIST_FILTER/TOGGLE_SELECT_FILTER': (state, { payload }) => {
      const previousSelectedState =
        state.selectedFilters[payload.type] &&
        state.selectedFilters[payload.type][payload.id]
      return {
        ...state,
        selectedFilters: {
          ...state.selectedFilters,
          [payload.type]: {
            ...state.selectedFilters[payload.type],
            [payload.id]: !previousSelectedState,
          },
        },
      }
    },
    'ASSORTMENT_LIST_FILTER/TOGGLE_DESELECT_ALL_FILTERS': (state) => ({
      ...state,
      selectedFilters: {},
    }),
    'ASSORTMENT_LIST_FILTER/APPLY_SELECTED_FILTERS': (state) => {
      const { selectedFilters } = state

      const appliedFilters = Object.entries(selectedFilters).reduce(
        (acc, [filterName, filterValues]) => {
          const filterItemList = Object.keys(filterValues).filter(
            (filterItem) => filterValues[filterItem],
          )

          return { ...acc, [filterName]: filterItemList }
        },
        {},
      )

      return {
        ...state,
        appliedFilters,
      }
    },
    'ASSORTMENT_LIST_FILTER/APPLY_SEARCH_FILTER': (
      state,
      { payload: searchText },
    ) => ({
      ...state,
      searchFilter: searchText.trim(),
    }),
    'ASSORTMENT_LIST_FILTER/TOGGLE_BRAND_NOTE_FILTER': (state) => ({
      ...state,
      brandNoteFilter: !state.brandNoteFilter,
    }),
    'ASSORTMENT_LIST_FILTER/SET_BRAND_NOTE_FILTER': (
      state,
      { payload: brandNoteFilter },
    ) => ({
      ...state,
      brandNoteFilter,
    }),
    'ASSORTMENT_LIST_FILTER/SET_ARCHIVED_FILTER': (
      state,
      { payload: archivedFilter },
    ) => ({
      ...state,
      archivedFilter,
    }),
  },
  defaultState,
)

export default reducer
