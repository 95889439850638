import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Button as BaseButton } from 'semantic-ui-react'

import ConditionalWrap from 'components/Core/ConditionalWrap'
import Grid from 'components/Core/Grid/Grid'

import styles from './Button.less'

const Button = ({
  primary,
  secondary,
  tertiary,
  disabled,
  close,
  withGrid,
  onClick,
  className,
  children,
  fullWidth,
  floated,
  inverted,
  bubble,
  borderLess,
  shortcuts,
  shortcutsDisabled,
  onMouseDown,
  testId,
  id,
}) => {
  const buttonClass = classNames(styles.button, {
    [styles.disabled]: disabled,
    [styles.primary]: primary,
    [styles.secondary]: secondary,
    [styles.tertiary]: tertiary,
    [styles.close]: close,
    [styles.fullWidth]: fullWidth,
    [styles.borderLess]: borderLess,
    [styles.shortcuts]: shortcuts,
    [styles.shortcutsDisabled]: shortcutsDisabled,
  })

  const buttonProps = {
    primary: !disabled && primary,
    secondary: !disabled && secondary,
    basic: !disabled && tertiary,
    disabled,
    circular: shortcutsDisabled || (!disabled && (close || shortcuts)),
    onClick,
    onMouseDown,
  }
  if (floated !== null) {
    buttonProps.floated = floated
  }
  if (inverted !== null) {
    buttonProps.inverted = inverted
  }

  return (
    <ConditionalWrap
      condition={withGrid}
      wrap={(wrappedChildren) => (
        <Grid.Col sm={2} md={2} lg={2}>
          {wrappedChildren}
        </Grid.Col>
      )}
    >
      <div className={classNames(buttonClass, className)} data-testid={testId}>
        <div className={styles.buttonBorder}>
          <BaseButton id={id} {...buttonProps}>
            {children}
            {bubble}
          </BaseButton>
        </div>
      </div>
    </ConditionalWrap>
  )
}

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  disabled: PropTypes.bool,
  close: PropTypes.bool,
  withGrid: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  inverted: PropTypes.bool,
  floated: PropTypes.string,
  bubble: PropTypes.node,
  borderLess: PropTypes.bool,
  shortcuts: PropTypes.bool,
  shortcutsDisabled: PropTypes.bool,
  onMouseDown: PropTypes.func,
  testId: PropTypes.string,
  id: PropTypes.string,
}

Button.defaultProps = {
  primary: false,
  secondary: false,
  tertiary: false,
  disabled: false,
  close: false,
  withGrid: false,
  children: null,
  className: '',
  onClick: () => {},
  fullWidth: false,
  inverted: null,
  floated: null,
  bubble: null,
  borderLess: false,
  shortcuts: false,
  shortcutsDisabled: false,
  onMouseDown: () => {},
  testId: null,
  id: null,
}

Button.Content = BaseButton.Content
Button.Group = BaseButton.Group

export default Button
