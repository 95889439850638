import { connect } from 'react-redux'

import { clearErrorMessages } from 'store/exceptionHandler/actions'
import { getErrorMessages } from 'store/exceptionHandler/selectors'

import ExceptionHandler from './ExceptionHandler.component'

const mapStateToProps = (state) => ({
  errorMessages: getErrorMessages(state),
})

const mapDispatchToProps = (dispatch) => ({
  handleClearErrorMessages: () => {
    dispatch(clearErrorMessages())
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(ExceptionHandler)
