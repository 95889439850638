import { handleActions } from 'redux-actions'

export const defaultState = {
  selectedRetailerId: null,
  retailerContactEmail: '',
  retailerIds: [],
  retailerSearchText: '',
}

const reducer = handleActions(
  {
    'ASSIGN_RETAILER_ACCOUNT_MODAL/SET_SELECTED_RETAILER_ID': (
      state,
      { payload },
    ) => ({
      ...state,
      selectedRetailerId: payload,
    }),
    'ASSIGN_RETAILER_ACCOUNT_MODAL/CHANGE_RETAILER_CONTACT_EMAIL': (
      state,
      { payload },
    ) => ({
      ...state,
      retailerContactEmail: payload,
    }),
    'ASSIGN_RETAILER_ACCOUNT_MODAL/CHANGE_RETAILER_ID': (
      state,
      { payload },
    ) => ({
      ...state,
      retailerIds: payload ? [payload] : [],
    }),
    'ASSIGN_RETAILER_ACCOUNT_MODAL/CHANGE_RETAILER_SEARCH_TEXT': (
      state,
      { payload },
    ) => ({
      ...state,
      retailerSearchText: payload,
    }),
  },
  defaultState,
)

export default reducer
