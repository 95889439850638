import { graphene } from 'graphql/tags'

export const priceTypeMatchesFragment = graphene`#graphql
  fragment priceTypeMatches on RetailerAccount {
    priceTypeMatches(orderBy: [{ field: CREATED, order: ASCENDING }]) {
      edges {
        node {
          id
          name
          currency {
            name
            id
            code
          }
          retailCurrency {
            id
            name
            code
          }
        }
      }
    }
  }
`
