import compose from 'lodash/flowRight'
import { connect } from 'react-redux'

import { getDisplayName } from 'utils/mixpanel'
import { toGlobalId } from 'utils/transformations/graphql'

import {
  getAccountId,
  isAccountTypeRetailer,
} from 'store/currentUser/selectors'
import {
  initTableRow,
  initializeTable,
  initializeTableTotalRow,
  resetTableToDefaultState,
  setSelectedTabId,
} from 'store/shop/productDetailModal/actions'
import { getBrand } from 'store/shop/selectors'

import withAnalytics from './ProductDetailTableWrapper.analytics'
import ProductDetailTableWrapper from './ProductDetailTableWrapper.component'

const mapStateToProps = (state) => ({
  accountId: getAccountId(state),
  brand: getBrand(state),
  isAccountTypeRetailer: isAccountTypeRetailer(state),
})

const mapDispatchToProps = {
  initializeTable,
  resetTableToDefaultState,
  setSelectedTabId,
  initTableRow,
  initializeTableTotalRow,
}

const withAccountData = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { accountId, brand, isAccountTypeRetailer } = props
    const brandAccountId = brand?.id || ''
    const brandIdByAccountType = isAccountTypeRetailer
      ? brandAccountId
      : toGlobalId('Brand', accountId)

    const passdownProps = {
      ...props,
      brandId: brandIdByAccountType,
    }

    return <WrappedComponent {...passdownProps} />
  }
  Wrapper.displayName = `withAccountData(${getDisplayName(Wrapper)})`
  return Wrapper
}

const withRedux = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withRedux,
  withAccountData,
  withAnalytics,
)(ProductDetailTableWrapper)
