import { GraphQLErrors } from '@apollo/client/errors'
import isNil from 'lodash/isNil'

// flattens the GraphQL Relay-compliant edges & nodes to a simple array
export const relayConnectionToArray = (connection: any) =>
  connection && connection?.edges
    ? connection?.edges?.map((edge: any) => edge.node)
    : []

// recursively flattens GraphQL Relay-compliant edges & nodes to a simple array
export const relayConnectionToArrayDeep = (connection?: any): unknown[] =>
  connection && connection.edges
    ? connection.edges.map((edge: any) => ({
        ...edge.node,
        children: edge.node && relayConnectionToArrayDeep(edge.node.children),
      }))
    : []

// apply a function over all edges in a connection, resulting in an array
export const mapOverConnection = (connection: any, fn: Function) => {
  if (!connection) return []
  return connection.edges.map(({ node }: { node: any }) => fn(node))
}

const messageWrapper = /[[\]'"]/g

export const cleanGraphQLErrors = (graphQLErrors: GraphQLErrors) =>
  graphQLErrors.map((error) => error.message.replace(messageWrapper, ''))

export const toGlobalId = (
  type?: string | null,
  id?: string | number | null,
) => {
  if (!id) {
    console.warn(`Malformed global identifier ${type}:${id}`)
    return null
  }
  return type ? window.btoa(`${type}:${id}`) : window.btoa(`${id}`)
}

// One should never encode / decode GUID from base 64, but we must
// in some cases until all relevant pages are refactored to use graphQL.
export const fromGlobalId = (guid?: string | number | null) => {
  if (isNil(guid)) return { type: null, id: null }
  const [type, id] = window.atob(guid.toString()).split(':')
  return { type, id }
}

export const isGlobalId = (id: string | number) => {
  const globalIdMatcher = new RegExp(
    /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})?$/g,
  )
  return globalIdMatcher.test(id as string) && isNaN(id as number)
}
