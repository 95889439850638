import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import { getPanel, getProfileInfo } from 'store/storefront/selectors'

import {
  getStorefrontProfileUpsertInput,
  getStorefrontTextUpsertInput,
  getStorefrontUpsertInput,
} from './StorefrontNavbar.transformations'

const UPSERT_STOREFRONT = atlas`#graphql
  mutation UpsertStorefront($input: UpsertStorefrontInput!) {
    upsertStorefront(input: $input) {
      id
      brandId
      isOnline
      modified
      isHome
      displayName
      uniqueName
      orderNbr
      rows {
        edges {
          node {
            id
            title
            template
            orderNbr
            elements {
              id
              orderNbr
              elementType
              presentation
              ... on StorefrontTextElement {
                content
              }
              ... on StorefrontProductElement {
                collectionId
                productId
              }
              ... on StorefrontVideoElement {
                videoId
                videoUrl
              }
              ... on StorefrontImageElement {
                imageId
                imageUrl
                imageExternalLink
              }
              ... on StorefrontCollectionElement {
                id
                orderNbr
                elementType
                collectionId
                collectionTitle
                coverUrl
                coverId
              }
              ... on StorefrontAboutElement {
                settings
              }
              ... on StorefrontShowroomCollectionElement {
                showroomCollectionId
                showroomCollectionTitle
                coverId
                coverUrl
              }
              ... on StorefrontLookbookElement {
                lookbookId
                lookbookTitle
                lookbookUrl
                coverId
                coverUrl
              }
              ... on StorefrontOrb360Element {
                externalVideoId
                externalVideoLink
              }
              ... on StorefrontShowroomBrandElement {
                showroomBrandId
              }
              ... on StorefrontInstagramElement {
                imageUrls
                instagramAuthId
              }
            }
          }
        }
      }
    }
  }
`

const UPSERT_TEXT_STYLES = atlas`#graphql
  mutation UpsertTextStylesMutation($input: UpsertStorefrontTextStylesInput!) {
    upsertStorefrontTextStyles(input: $input) {
      id
      name
      weight
      size
      typeface
      name
      orderNbr
    }
  }
`

const UPSERT_BRAND_PROFILE = atlas`#graphql
  mutation UpsertBrandProfileMutationDupe2($input: UpsertBrandProfileInput!) {
    upsertBrandProfile(input: $input) {
      id
      name
      description
      yearEstablished
      productLines
      wholesaleMin
      wholesaleMax
      retailMin
      retailMax
      websiteUrl
      customUrlHandle
      socialMediaAccounts {
        socialMediaType
        url
      }
      accountMedia {
        id
        url
      }
      logoId
      logoUrl
    }
  }
`

export const useUpsertStorefront = () => {
  const storefront = useSelector((state) => getPanel(state))
  const [upsertStorefront, { loading }] = useMutation(UPSERT_STOREFRONT, {
    client: atlasClient,
  })

  return {
    loading,
    upsertStorefront: (pageSettings, newStorefront) =>
      upsertStorefront({
        variables: {
          input: getStorefrontUpsertInput(
            newStorefront || storefront,
            pageSettings,
          ),
        },
      }),
  }
}

export const useUpsertStorefrontTextStyles = () => {
  const [upsertStorefront] = useMutation(UPSERT_TEXT_STYLES, {
    client: atlasClient,
  })

  return (textStyles) => {
    return upsertStorefront({
      variables: {
        input: getStorefrontTextUpsertInput(textStyles),
      },
    })
  }
}

// TODO: copied in brandDetailHooks, delete this one when the migration to new navbar is finished.
export const useUpsertStorefrontProfile = () => {
  const profile = useSelector(getProfileInfo)

  const [upsertStorefront] = useMutation(UPSERT_BRAND_PROFILE, {
    client: atlasClient,
  })

  return () => {
    return upsertStorefront({
      variables: {
        input: getStorefrontProfileUpsertInput(profile),
      },
    })
  }
}
