export const anyAreTruthy = (...args) =>
  args.length > 0 && args.some((arg) => !!arg)

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  if (!result.length) {
    return result
  }

  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const updateItemInArray = (index, item, array) => {
  return [...array.slice(0, index), item, ...array.slice(index + 1)]
}
