import map from 'lodash/map'
import PropTypes from 'prop-types'

import { formatNameWithCode } from 'utils/formatters'
import { toGlobalId } from 'utils/transformations/graphql'

import { Image, Modal, Swatch, Table } from 'components/Core'
import ItemTableBodyRow from 'components/Core/Table/ItemTableBodyRow'
import ItemTableHeaderRow from 'components/Core/Table/ItemTableHeaderRow'

import { useProduct, useSkuDeliveries } from './SkuDeliveryModal.hooks'
import styles from './SkuDeliveryModal.less'
import { getDeliveryRangeFromSize } from './SkuDeliveryModal.transformations'

const SkuDeliveryModal = ({
  onClose,
  productId,
  variantId,
  collectionId,
  brandId,
}) => {
  const {
    product,
    productSizes,
    productCasepacks,
    productColor,
    productImage,
    productLoading,
  } = useProduct(
    toGlobalId('Product', productId),
    toGlobalId('Collection', collectionId),
    toGlobalId('SKUTraitValue', toGlobalId('StyleColor', variantId)),
  )
  const {
    skuDeliveries,
    productSkuDeliveryRange,
    skuDeliveriesLoading,
  } = useSkuDeliveries(
    toGlobalId('Product', productId),
    toGlobalId('Collection', collectionId),
    toGlobalId('Brand Account', brandId),
  )
  const columns = {
    size: {
      description: 'Size',
      width: 'medium',
      cellContent: (productSize) => productSize.value,
    },
    skuDeliveries: {
      description: 'Deliveries',
      width: 'extra-large',
      cellContent: (productSize) =>
        getDeliveryRangeFromSize(skuDeliveries, productSize),
    },
  }
  return (
    <Modal
      className={styles.SkuDeliveryModal}
      loading={productLoading || skuDeliveriesLoading}
      onClose={onClose}
      open
      title="SKU Delivery"
    >
      <div className={styles.productContainer}>
        <Image
          src={productImage}
          alt="Product Image"
          containerClassName={styles.image}
        />
        <div className={styles.productInfo}>
          <span>{product?.name}</span>
          <div className={styles.productColorInfo}>
            <Swatch
              className={styles.swatch}
              swatch={{
                colorNumber: productColor?.displayDetails?.hexColor,
                url: productColor?.displayDetails?.swatchImage?.url,
              }}
              height={20}
              width={20}
            />
            <span>
              {formatNameWithCode(
                productColor?.value,
                productColor?.externalIdentifier,
              )}
            </span>
          </div>
          <span className={styles.skuDeliveryRange}>
            {productSkuDeliveryRange}
          </span>
        </div>
      </div>
      <Table className={styles.skuDeliveryTable}>
        <Table.Header sticky>
          <ItemTableHeaderRow
            className={styles.headerRow}
            columns={columns}
            columnOrder={['size', 'skuDeliveries']}
            hasSelectColumn={false}
          />
        </Table.Header>
        <Table.Body>
          {map(productSizes, (size) => (
            <ItemTableBodyRow
              key={size.id}
              columns={columns}
              item={size}
              columnOrder={['size', 'skuDeliveries']}
              selectable={false}
            />
          ))}
          {map(productCasepacks, (casepack) => (
            <ItemTableBodyRow
              key={casepack.id}
              columns={columns}
              item={casepack}
              columnOrder={['size', 'skuDeliveries']}
              selectable={false}
            />
          ))}
        </Table.Body>
      </Table>
    </Modal>
  )
}

SkuDeliveryModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  productId: PropTypes.number.isRequired,
  variantId: PropTypes.number.isRequired,
  collectionId: PropTypes.number.isRequired,
  brandId: PropTypes.number.isRequired,
}

export default SkuDeliveryModal
