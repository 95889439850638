import isEmpty from 'lodash/isEmpty'

import { formatDate, toServerDate } from 'utils/formatters'
import { translateText } from 'utils/sdks/localize'

import DatePicker from 'components/Core/DatePicker/DatePicker'

import Filter from './baseFilter'

/*
 * DateFilter
 * It is a type of filter that allows the selection of a single
 * date. This date has the form of { startDate: '', endDate: '' }
 */
export default class DateFilter extends Filter {
  constructor({ messages, defaultState, ...rest }) {
    super(rest)
    this.unselectedState = {
      startDate: '',
      endDate: '',
      isDefault: false,
    }
    this.isSearchable = false
    this.messages = messages
    if (defaultState) {
      this.defaultState = defaultState
    }
  }

  getFilterOptions = () => {}

  getSelectedOptions = () => {}

  isUnselected = (filterItem) => filterItem && filterItem.startDate === ''

  renderFilterPanelHandler = ({ actionHandler }) => ({
    title: this.label,
    content: (
      <DatePicker
        className={this.styles.datePicker}
        placeholder={translateText(this.messages.addDate)}
        range
        singleDate
        buttonText={this.messages.addDateFilter}
        onSubmit={actionHandler}
      />
    ),
    showFilterHandler: this.showFilterHandler,
  })

  renderPillLabelHandler = (filterData, dateFormat) => {
    const { startDate, endDate } = filterData
    return (
      <span>
        {this.label}:{' '}
        <span className="notranslate">
          {this.formatDateValue({ startDate, endDate }, dateFormat)}
        </span>
      </span>
    )
  }

  formatDateValue = (date, dateFormat) => {
    let dateStr = ''
    if (date && date.startDate) {
      dateStr += formatDate(date.startDate, dateFormat, true)
    }
    if (date && date.endDate) {
      const formattedEndDate = formatDate(date.endDate, dateFormat, true)
      if (formattedEndDate !== dateStr) {
        dateStr += ` - ${formattedEndDate}`
      }
    }
    return dateStr
  }

  toGraphQLVariable = (graphQLFilters, { value }) => {
    if (this.isUnselected(value)) {
      return graphQLFilters
    }
    const { startDate, endDate } = value
    return {
      ...graphQLFilters,
      [this.filterKey]: { startDate, endDate },
    }
  }

  reducerHandler = (selectedFilters, filterInfo) => {
    const { startDate, endDate } = filterInfo
    return {
      ...selectedFilters,
      [this.filterKey]: {
        startDate: startDate === '' ? '' : toServerDate(startDate),
        endDate: endDate === '' ? '' : toServerDate(endDate),
        dataKey: this.dataKey,
        isDefault: false,
      },
    }
  }
  // eslint-disable-next-line
  renderSelectedFilterHandler = ({ actionHandler }) => (
    <DatePicker
      placeholder={translateText(this.messages.addDate)}
      range
      buttonText={this.messages.addDateFilter}
      onSubmit={actionHandler}
      className={this.styles.datePicker}
    />
  )

  getSelectedFiltersPillFormatted = ({
    selectedFilters,
    onActionClick,
    dateFormat = 'mm/dd/yyyy',
  }) => {
    const { startDate, endDate } = selectedFilters[this.filterKey]
    if (isEmpty(startDate) && isEmpty(endDate)) {
      return []
    }
    return [
      {
        name: (
          <span>
            {translateText(this.label)}:{` `}
            <var>
              {this.formatDateValue({ startDate, endDate }, dateFormat)}
            </var>
          </span>
        ),
        id: `date_filter_${this.label}`,
        onClick: (filterData) =>
          onActionClick({ startDate: '', endDate: '', ...filterData }),
      },
    ]
  }

  getCountFilters = (filterState) => (this.isUnselected(filterState) ? 0 : 1)
}
