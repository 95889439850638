import { Fragment } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import Divider from 'components/Core/Divider/Divider'
import Grid from 'components/Core/Grid/Grid'
import HeaderAndValues from 'components/Core/HeaderAndValues/HeaderAndValues'

import styles from './InfoPanel.less'

export const BLUE_BACKGROUND = 'blueBackground'
export const GRAY_BACKGROUND = 'grayBackground'
export const DEFAULT_BACKGROUND = ''

const InfoPanel = ({ data, className, background, compactView }) => {
  const extraClasses = {
    [styles[background]]: background,
    [styles.compact]: compactView,
  }
  return (
    <div className={classNames(styles.infoPanel, className)}>
      <Grid container className={classNames(styles.body, extraClasses)}>
        {data.map((row, index) => (
          /* eslint-disable-next-line react/no-array-index-key */
          <Fragment key={index}>
            <Grid.Row className={styles.row}>
              {row.map((valueItem, valueItemIndex) => {
                const {
                  title,
                  values,
                  className: itemClassName,
                  numColumns,
                  headerClassName,
                } = valueItem
                if (!values.length) {
                  values.push(null)
                }
                const colWidth = numColumns
                  ? `${Math.trunc((100 * numColumns) / 12)}%`
                  : '25%'
                return (
                  <div
                    style={{ flexBasis: colWidth }}
                    key={`${title}-${index}.${valueItemIndex}`}
                  >
                    <Grid.Col className={styles.valueItem}>
                      <HeaderAndValues
                        className={classNames(styles.infoItem, itemClassName, {
                          [styles.compact]: compactView,
                        })}
                        headerClassName={headerClassName}
                        header={title}
                        values={values}
                      />
                    </Grid.Col>
                  </div>
                )
              })}
            </Grid.Row>
            {!compactView && index < data.length - 1 && (
              <Grid.Row>
                <Divider verticalMargin={0} horizontalMargin={12} />
              </Grid.Row>
            )}
          </Fragment>
        ))}
      </Grid>
    </div>
  )
}

InfoPanel.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        values: PropTypes.array,
        className: PropTypes.string,
        numColumns: PropTypes.number,
      }),
    ),
  ),
  className: PropTypes.string,
  background: PropTypes.oneOf([
    BLUE_BACKGROUND,
    GRAY_BACKGROUND,
    DEFAULT_BACKGROUND,
  ]),
  compactView: PropTypes.bool,
}

InfoPanel.defaultProps = {
  data: [],
  className: '',
  background: DEFAULT_BACKGROUND,
  compactView: false,
}

export default InfoPanel
