import { formatAccountName } from 'utils/formatters'

const containsLocations = (locations) =>
  locations &&
  Array.isArray(locations) &&
  locations.length > 0 &&
  typeof locations[0] === 'object'

export const formatRetailerWithLocation = (retailer) => {
  const { displayName, locations } = retailer
  if (!containsLocations(locations)) return displayName
  const [{ state, city }] = locations
  const appendedCity = city ? ` - ${city}` : ''
  const appendedState = state ? `, ${state}` : ''
  return `${displayName}${appendedCity}${appendedState}`
}

export const formatConnectedAccountsForDropdown = (accounts) =>
  accounts.map((account) => ({
    value: account.id,
    text: formatAccountName(account),
    key: account.id,
  }))
