import get from 'lodash/get'

import { TYPE_BRAND, TYPE_RETAILER } from 'store/currentUser/selectors'

export const getAccountTypeName = (userInfo) => {
  if (get(userInfo, 'account.type') === TYPE_BRAND)
    return get(userInfo, 'account.limitedAccess') ? 'Lite Brand' : 'Pro Brand'
  else if (get(userInfo, 'account.type') === TYPE_RETAILER)
    return get(userInfo, 'account.canViewAssortments')
      ? 'Pro Retailer'
      : 'Lite Retailer'
  return ''
}
