import { Option } from '@joor/design-system'

import { formatNameWithCode } from 'utils/formatters'
import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import { GrapheneAccount } from './MergeAccountsModal'

export const getAccountOptions = (accounts: GrapheneAccount[]) =>
  accounts?.map((account) => ({
    label: formatNameWithCode(account.name, fromGlobalId(account.id)?.id),
    value: toGlobalId('Account', fromGlobalId(account.id)?.id) ?? '',
    // This filter === false instead of !account.assortmentPlanning because Brands are missing this field
    isDisabled: account.assortmentPlanning !== false,
  }))

export const getExcludedAccounts = ({
  options,
  allowedAccounts,
  primaryAccount,
}: {
  options: Option[]
  allowedAccounts: string[]
  primaryAccount: Option | null
}) =>
  options
    .filter(
      (account) =>
        !allowedAccounts.includes(account.value) &&
        account.value !== primaryAccount?.value,
    )
    .map((account) => account?.value)
