import Bubble, { BLUE } from 'components/Core/Bubble/Bubble'

import Filter from './baseFilter'

/*
 * SingleFilter
 * It is a filter that holds a single boolean value like e.g. created by retailers.
 * The information here is the boolean value which will be used to filter by.
 */
export default class SingleFilter extends Filter {
  constructor({ defaultState, showNewsBubble, getBubbleContent, ...rest }) {
    super(rest)
    this.unselectedState = false
    if (defaultState !== undefined) {
      this.defaultState = defaultState
    }
    this.getBubbleContent = getBubbleContent
    this.showNewsBubble = showNewsBubble
  }

  getState = (selectedFilters) => selectedFilters[this.filterKey]

  renderFilterPanelHandler = ({
    actionHandler,
    selectedFilters,
    withNewsBubble = true,
    bubbleBody = '',
    showFilterHandler,
  }) => ({
    title: this.label,
    isClickable: actionHandler,
    bubble: withNewsBubble && <Bubble color={BLUE}>{bubbleBody}</Bubble>,
    content: null,
    openSingleFilter: this.getState(selectedFilters),
    showFilterHandler,
  })

  isUnselected = (filterItem) => !filterItem

  renderPillLabelHandler = () => `${this.label}`

  toGraphQLVariable = (graphQLFilters, { value }) => ({
    ...graphQLFilters,
    [this.filterKey]: value,
  })

  reducerHandler = (selectedFilters) => {
    const currentValue = selectedFilters[this.filterKey]
    return {
      ...selectedFilters,
      [this.filterKey]: !currentValue,
    }
  }

  addToActiveFilters = (currentState, filterState) => {
    // if it is unselected, it passes without modifying
    if (this.isUnselected(filterState)) {
      return currentState
    }
    return [
      ...currentState,
      {
        dataKey: this.dataKey,
      },
    ]
  }
}
