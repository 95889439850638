import { Dispatch, SetStateAction } from 'react'

import { RadioButton, RadioButtonGroup } from '@joor/design-system'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  getAccountId,
  getAccountProfileName,
} from 'store/currentUser/selectors'

import { PrivacyAccountOptions } from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal'
import { SecondaryAccountSelectionView } from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.ids'

type SecondaryAccountSelectionProps = {
  privacySelection: PrivacyAccountOptions | null
  setPrivacySelection: Dispatch<SetStateAction<PrivacyAccountOptions | null>>
}

const StyledContainer = styled.div`
  max-height: 100%;
  overflow: auto;
`
const StyledInfoText = styled.div`
  padding: 16px 0;
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  line-height: 16px;
  letter-spacing: 0.6px;
`
const StyledHightlightedText = styled.span`
  font-weight: var(--font-weight-bold);
`
const Note = styled.div`
  padding-top: 24px;
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-extrasmall);
  font-style: italic;
  line-height: 16px;
  letter-spacing: 0.6px;
`

const SecondaryAccountSelection: React.FC<SecondaryAccountSelectionProps> = ({
  privacySelection,
  setPrivacySelection,
}) => {
  const accountId = useSelector(getAccountId)
  const accountName = useSelector(getAccountProfileName)
  return (
    <StyledContainer data-testid={SecondaryAccountSelectionView.Component}>
      <StyledInfoText>
        Thank you for identifying{' '}
        <StyledHightlightedText>
          {accountName} ({accountId})
        </StyledHightlightedText>{' '}
        as non-primary. We recommend you hide this account to prevent additional
        brands from connecting with you.
      </StyledInfoText>
      <RadioButtonGroup>
        <RadioButton
          id={SecondaryAccountSelectionView.HideRadioButton}
          data-testid="hide-account"
          label="Yes, hide this account from unconnected brands"
          name="privacy-selection"
          value={PrivacyAccountOptions.Hide}
          onChange={() => setPrivacySelection(PrivacyAccountOptions.Hide)}
          checked={privacySelection === PrivacyAccountOptions.Hide}
        />
        <RadioButton
          id={SecondaryAccountSelectionView.ShowRadioButton}
          data-testid="show-account"
          label="No, keep this account visible to unconnected brands"
          name="privacy-selection"
          value={PrivacyAccountOptions.Show}
          onChange={() => setPrivacySelection(PrivacyAccountOptions.Show)}
          checked={privacySelection === PrivacyAccountOptions.Show}
        />
      </RadioButtonGroup>
      <Note>
        Note: you will still be able to access this account and buy from brands
        you are already connected to. You can update this preference at anytime
        in “My Settings” under “Privacy.”
      </Note>
    </StyledContainer>
  )
}

export default SecondaryAccountSelection
