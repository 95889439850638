import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Dropdown } from 'semantic-ui-react'

import Grid from 'components/Core/Grid/Grid'
import Icon from 'components/Core/Icon/Icon'

import styles from './PanelContainer.less'
import TabContainer from './TabContainer'

export const DEFAULT_NUM_ELEMENTS_SLIDE = 5
export const MENU_ITEMS_TO_SCROLL = 10

const PanelContainer = (props) => {
  const {
    onSelectPanel,
    selectedPanel,
    options,
    onSlide,
    sliderPosition,
    numElementsSlide,
    panelRefHandler,
    panelWidth,
    disabled,
    showMenu,
    showArrows,
    className,
    underlineSelected,
    panelClassName,
    panelContainerClassName,
    borderlessPanels,
  } = props

  const onSelectMenuOption = (panelIndex) => {
    onSelectPanel(panelIndex)
    onSlide(panelIndex - sliderPosition)
  }

  const numPanels = options.length
  const leftShift = Math.min(numElementsSlide, sliderPosition)
  const rightShift = Math.min(numElementsSlide, numPanels - sliderPosition - 1)
  const nextDisabled = sliderPosition === numPanels - 1
  const previousDisabled = sliderPosition === 0
  const sliderPositionLeftPx = sliderPosition * panelWidth
  const menuScroll = numPanels > MENU_ITEMS_TO_SCROLL

  return (
    <Grid.Row className={className}>
      {showMenu && (
        <Grid.Col sm={1} md={1} lg={1} className={styles.MenuContainer}>
          <div className={styles.MenuContainerChildren}>
            <Dropdown
              trigger={<Icon name="menu" />}
              icon={null}
              scrolling={menuScroll}
              disabled={disabled}
            >
              <Dropdown.Menu
                className={classNames(styles.MenuItemContainer, {
                  [styles.MenuHeight]: menuScroll,
                })}
              >
                {options.map((option, index) => (
                  <Dropdown.Item
                    active={selectedPanel === index}
                    className={styles.MenuItem}
                    key={option.id}
                    value={option.id}
                    onClick={() => onSelectMenuOption(index)}
                  >
                    {option.content}
                    {selectedPanel === index && (
                      <Icon name="checkmark" className={styles.MenuItemIcon} />
                    )}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div className={styles.MenuContainerBorder} />
          </div>
        </Grid.Col>
      )}
      <Grid.Col
        sm={10}
        md={10}
        lg={14}
        className={classNames(styles.PanelContainer, panelContainerClassName)}
      >
        <div
          className={styles.PanelSlider}
          style={{ left: `-${sliderPositionLeftPx}px` }}
        >
          {options.map((option, index) => (
            <TabContainer.Panel
              refHandler={panelRefHandler}
              id={option.id}
              key={option.id}
              onClick={() => onSelectPanel(index)}
              highlighted={selectedPanel === index}
              underlined={underlineSelected}
              content={option.content}
              noTranslate={option.noTranslate}
              panelClassName={classNames(panelClassName, option.className)}
              borderless={borderlessPanels}
            />
          ))}
        </div>
      </Grid.Col>
      {showArrows && (
        <Grid.Col
          sm={1}
          md={1}
          lg={1}
          className={styles.PanelArrowContainerCol}
        >
          <div className={styles.PanelArrowContainer}>
            <Icon
              disabled={previousDisabled || disabled}
              onClick={() => onSlide(-leftShift)}
              className={classNames(
                { [styles.disabled]: previousDisabled || disabled },
                styles.PanelArrow,
                styles.PanelArrowLeft,
              )}
              name="arrow-right"
              label="Left"
            />
            <Icon
              disabled={nextDisabled || disabled}
              onClick={() => onSlide(rightShift)}
              className={classNames(
                { [styles.disabled]: nextDisabled || disabled },
                styles.PanelArrow,
              )}
              name="arrow-right"
              label="Right"
            />
          </div>
        </Grid.Col>
      )}
    </Grid.Row>
  )
}

PanelContainer.propTypes = {
  onSelectPanel: PropTypes.func.isRequired,
  selectedPanel: PropTypes.number.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      content: PropTypes.node,
      noTranslate: PropTypes.bool,
      className: PropTypes.string,
    }),
  ).isRequired,
  onSlide: PropTypes.func.isRequired,
  sliderPosition: PropTypes.number.isRequired,
  numElementsSlide: PropTypes.number,
  panelRefHandler: PropTypes.func,
  panelWidth: PropTypes.number,
  disabled: PropTypes.bool,
  showMenu: PropTypes.bool,
  showArrows: PropTypes.bool,
  className: PropTypes.string,
  underlineSelected: PropTypes.bool,
  panelClassName: PropTypes.string,
  panelContainerClassName: PropTypes.string,
  borderlessPanels: PropTypes.bool,
}
PanelContainer.defaultProps = {
  numElementsSlide: DEFAULT_NUM_ELEMENTS_SLIDE,
  panelRefHandler: () => {},
  panelWidth: 0,
  disabled: false,
  showMenu: true,
  showArrows: true,
  className: '',
  underlineSelected: false,
  panelClassName: '',
  panelContainerClassName: '',
  borderlessPanels: false,
}

export default PanelContainer
