import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Button.less'

const Button = ({
  primary,
  primaryDarkerOnHover,
  secondary,
  tertiary,
  tertiaryDarkerOnHover,
  disabled,
  rounded,
  onClick,
  className,
  buttonClassName,
  children,
  fullWidth,
  bubble,
  ...rest
}) => {
  const buttonClass = classNames(styles.button, {
    [styles.disabled]: disabled,
    [styles.fullWidth]: fullWidth,
    [styles.primary]: primary,
    [styles.primaryDarkerOnHover]: primaryDarkerOnHover,
    [styles.secondary]: secondary,
    [styles.tertiary]: tertiary,
    [styles.tertiaryDarkerOnHover]: tertiaryDarkerOnHover,
    [styles.rounded]: rounded,
  })

  return (
    <div
      className={classNames(buttonClass, className)}
      {...(!disabled && { onClick })}
    >
      <button className={buttonClassName} {...rest} disabled={disabled}>
        {children}
        {bubble}
      </button>
    </div>
  )
}

Button.propTypes = {
  bubble: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClick: PropTypes.func,
  primary: PropTypes.bool,
  primaryDarkerOnHover: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  tertiaryDarkerOnHover: PropTypes.bool,
}

Button.defaultProps = {
  bubble: null,
  children: null,
  className: '',
  buttonClassName: '',
  disabled: false,
  rounded: false,
  fullWidth: false,
  onClick: () => {},
  primary: false,
  primaryDarkerOnHover: false,
  secondary: false,
  tertiary: false,
  tertiaryDarkerOnHover: false,
}

export default Button
