import { GraphQLErrors } from '@apollo/client/errors'

import { PendoGuide } from 'types'

import { RenderToastFunc } from 'hooks/useRenderToasters'

import {
  CAPACITY_LIMIT_ERROR_CODE,
  ERROR_MAX_CAPACITY_TOAST_CONTENT,
  RATE_LIMIT_ERROR_CODE,
  RATE_LIMIT_ERROR_MESSAGE,
} from '../constants/constants'

export const getSuccessEnableToastContent = (
  historyGo: (state: number) => void,
) => {
  return {
    title: 'Beta turned on',
    description: 'You can now test the new experience and give us feedback.',
    shouldCloseManually: true,
    action: {
      label:
        'If you don’t see the new experience click here to refresh the page',
      handler: () => historyGo(0),
    },
    className: 'font-size-fix',
  }
}

export const getSuccessDisableToastContent = (
  historyGo: (state: number) => void,
) => {
  return {
    title: 'Beta turned off',
    description: 'You should now see the classic experience.',
    shouldCloseManually: true,
    action: {
      label: 'If you still see the beta, click here to refresh the page',
      handler: () => historyGo(0),
    },
    className: 'font-size-fix',
  }
}

export const getErrorEnableToastContent = (
  historyGo: (state: number) => void,
) => {
  return {
    title: 'The beta did not activate',
    description: 'We’re having trouble loading this feature',
    shouldCloseManually: true,
    action: {
      label: 'Try again later or refresh your browser.',
      handler: () => historyGo(0),
    },
    className: 'font-size-fix',
  }
}

export const getErrorDisableToastContent = (
  historyGo: (state: number) => void,
) => {
  return {
    title: 'The classic experience did not activate',
    description: 'We’re having trouble loading this feature',
    shouldCloseManually: true,
    action: {
      label: 'Try again later or refresh your browser.',
      handler: () => historyGo(0),
    },
    className: 'font-size-fix',
  }
}

export const getErrorManyRequestOnToastContent = (
  historyGo: (state: number) => void,
) => {
  return {
    title: 'There are too many requests',
    description: 'Too many users are trying to turn on this feature.',
    shouldCloseManually: true,
    action: {
      label: 'Try again later or refresh your browser.',
      handler: () => historyGo(0),
    },
    className: 'font-size-fix',
  }
}

export const getErrorManyRequestOffToastContent = (
  historyGo: (state: number) => void,
) => {
  return {
    title: 'There are too many requests',
    description: 'Too many users are trying to turn off this feature.',
    shouldCloseManually: true,
    action: {
      label: 'Try again later or refresh your browser.',
      handler: () => historyGo(0),
    },
    className: 'font-size-fix',
  }
}

export const isRateLimitError = (graphQLErrors: GraphQLErrors) => {
  return (
    graphQLErrors?.length &&
    graphQLErrors[0].extensions?.code === RATE_LIMIT_ERROR_CODE &&
    graphQLErrors[0].message === RATE_LIMIT_ERROR_MESSAGE
  )
}

export const isCapacityLimitError = (graphQLErrors: GraphQLErrors) => {
  return (
    graphQLErrors?.length &&
    graphQLErrors[0].extensions?.code === CAPACITY_LIMIT_ERROR_CODE
  )
}

export const showSubscribeErrorToast = (
  graphQLErrors: GraphQLErrors,
  historyGo: (state: number) => void,
  renderErrorToast: RenderToastFunc,
) => {
  let errorToastContent = getErrorEnableToastContent(historyGo)

  if (isRateLimitError(graphQLErrors)) {
    errorToastContent = getErrorManyRequestOnToastContent(historyGo)
  } else if (isCapacityLimitError(graphQLErrors)) {
    errorToastContent = ERROR_MAX_CAPACITY_TOAST_CONTENT
  }

  renderErrorToast(errorToastContent)
}

export const showUnsubscribeErrorToast = (
  graphQLErrors: GraphQLErrors,
  historyGo: (state: number) => void,
  renderErrorToast: RenderToastFunc,
) => {
  let errorToastContent = getErrorDisableToastContent(historyGo)

  if (isRateLimitError(graphQLErrors)) {
    errorToastContent = getErrorManyRequestOffToastContent(historyGo)
  }

  renderErrorToast(errorToastContent)
}

/**
 * Monitors the DOM for the removal of a specified Pendo guide element and
 * triggers a callback when the element is no longer present in the DOM.
 *
 * @param {HTMLElement} pendoGuideElement - The Pendo guide element to monitor.
 * @param {() => void} onRemoveCallback - The callback function to execute when the guide element is removed.
 */
export const onRemovePendoGuide = (
  pendoGuideElement: HTMLElement,
  pathname: string,
  onRemoveCallback: () => void,
) => {
  /**
   * Callback function for the MutationObserver, triggered when changes in the DOM occur.
   */
  const observerCallback = function () {
    if (pendoGuideElement.parentElement?.style.display === 'none') {
      pendoGuideElement.parentElement.style.display = ''
    }

    if (!document.contains(pendoGuideElement)) {
      observer.disconnect()
      if (window.location.pathname === pathname) {
        onRemoveCallback()
      }
    }
  }

  // Create a MutationObserver to watch for changes in the DOM
  const observer = new MutationObserver(observerCallback)

  // Start observing the document for childList changes and subtree modifications
  observer.observe(document, {
    childList: true,
    subtree: true,
  })
}

export const getPendoGuide = (unsubscribePendoGuideId?: string) => {
  if (!unsubscribePendoGuideId) {
    return null
  }

  let guideData: PendoGuide | null = null

  const matchingGuides = window.pendo?.guides?.filter((guide) => {
    return guide.id === unsubscribePendoGuideId
  })

  if (matchingGuides?.length) {
    guideData = matchingGuides[0]
  }

  return guideData
}

export const getGuideContainer = (guideData: PendoGuide | null) => {
  if (!guideData) {
    return null
  }

  let guideContainer: HTMLElement | null = null
  const stepData = guideData.steps?.[0]

  if (stepData) {
    guideContainer = document.getElementById(stepData.containerId)
  }

  return guideContainer
}

export const getGuideContainerFromId = (guideId?: string) => {
  if (!guideId) {
    return null
  }

  let guideData: PendoGuide | null = null
  let guideContainer: HTMLElement | null = null

  const matchingGuides = window.pendo?.guides?.filter((guide) => {
    return guide.id === guideId
  })

  if (matchingGuides?.length) {
    guideData = matchingGuides[0]
    const stepData = guideData.steps?.[0]

    if (stepData) {
      guideContainer = document.getElementById(stepData.containerId)
    }
  }

  return guideContainer
}
