import {
  Button,
  EmptyPlaceholder,
  Link,
  Paragraph,
  SecondaryButton,
} from '@joor/design-system'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import {
  SubmissionLink,
  SubmissionSent,
} from '../SubmissionLink/SubmissionLink'
import { USER_SETTINGS_URL } from './People'

const AddUser = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Paragraph type="3" textAlign="center" fontSize="small">
        Each user can add a name, title, and image in their{' '}
        <Link href={USER_SETTINGS_URL} fontWeight="semibold" fontSize="small">
          user settings
        </Link>{' '}
        and opt in and out of being
        <br /> displayed on the profile.{' '}
      </Paragraph>
      <div style={{ marginTop: '24px' }}>
        <Link href={USER_SETTINGS_URL} target="_blank">
          <SecondaryButton>Go to user settings</SecondaryButton>
        </Link>
      </div>
    </div>
  )
}

const EmptyPeopleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

type PeopleEmptyProps = {
  isRetailer?: boolean
  showCards?: boolean
  contactUrl?: string
  submissionSent?: SubmissionSent
}

const PeopleEmpty = ({
  isRetailer,
  showCards,
  contactUrl,
  submissionSent,
}: PeopleEmptyProps) => {
  const history = useHistory()

  if (isRetailer) {
    return (
      <EmptyPeopleContainer data-testid="people-section">
        {showCards ? (
          <EmptyPlaceholder
            data-testid="people-section"
            iconName="person"
            title="Display multiple buyers on your profile"
            description={<AddUser />}
          />
        ) : (
          <EmptyPlaceholder iconName="person" title="No owner or Buyer added" />
        )}
      </EmptyPeopleContainer>
    )
  }

  const canSendSubmission = contactUrl && !submissionSent

  return (
    <EmptyPeopleContainer data-testid="people-section">
      <EmptyPlaceholder
        iconName="person"
        title="No people yet"
        description={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
            }}
          >
            <Paragraph
              type="3"
              textAlign="center"
              fontSize="small"
              color="primary-400"
            >
              This retailer has not listed any buyers yet.
              <br />
              {canSendSubmission &&
                'Submit a wholesale inquiry to the company below.'}
            </Paragraph>
            {canSendSubmission && (
              <Button onClick={() => history.push(contactUrl)}>Contact</Button>
            )}
            {submissionSent && (
              <SubmissionLink submissionSent={submissionSent} />
            )}
          </div>
        }
      />
    </EmptyPeopleContainer>
  )
}

export default PeopleEmpty
