import compose from 'lodash/flowRight'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withMixpanel } from 'utils/sdks/mixpanel'

import {
  renderErrorNotification,
  renderSuccessNotification,
} from 'store/notificationHandler/actions'
import {
  changeRetailerSearchText,
  setSelectedRetailerId,
} from 'store/orderList/createOrderTemplateModal/actions'

import { withAnalytics } from 'modals/OrderList/CreateOrderTemplateModal/CreateOrderTemplateModal.analytics'
import CreateOrderTemplateModal from 'modals/OrderList/CreateOrderTemplateModal/CreateOrderTemplateModal.component'

import {
  withConnectedAccounts,
  withInitialCreateOrderTemplateInfo,
  withRetailerPriceTypesAndDoors,
} from './CreateOrderTemplateModal.queries'

const mapStateToProps = ({ createOrderTemplateModal, currentUser }) => ({
  ...createOrderTemplateModal,
  accountId: currentUser.account.accountId,
})

const mapDispatchToProps = (dispatch) => ({
  renderErrorNotification: (message) =>
    dispatch(renderErrorNotification(message)),
  renderSuccessNotification: (message) =>
    dispatch(renderSuccessNotification(message)),
  setSelectedRetailerId: (retailerId) =>
    dispatch(setSelectedRetailerId(retailerId)),
  changeRetailerSearchText: (searchText) =>
    dispatch(changeRetailerSearchText(searchText)),
})

const withRedux = connect(mapStateToProps, mapDispatchToProps)

const withCreateOrderTemplateModalFlagsWrapper = (WrappedComponent) => {
  const Wrapper = (props) => {
    return <WrappedComponent {...props} />
  }
  return Wrapper
}

export default compose(
  withRouter,
  withMixpanel,
  withRedux,
  withCreateOrderTemplateModalFlagsWrapper,
  withInitialCreateOrderTemplateInfo,
  withConnectedAccounts,
  withRetailerPriceTypesAndDoors,
  withAnalytics,
)(CreateOrderTemplateModal)
