import React, { ChangeEvent } from 'react'

import '@joor/design-system/dist/designTokens/variables.css'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`
export const Input = styled.input`
  border: 1px solid var(--color-primary-400);
  height: 30px;
  padding: 5px;
  border-radius: 5px;
  margin: 5px 0 5px 0;
  font-family: var(--font-family-alpha);
  color: #cccccc;
  font-size: var(--size-font-small);
`
export const Label = styled.label`
  font-weight: var(--font-weight-medium);
`

type FormInputProps = {
  description?: string
  id: string
  label?: string
  onChange: (e: ChangeEvent) => any
  type: string
  value: string | number
}

const FormInput: React.FC<FormInputProps> = ({
  description,
  id,
  label,
  type,
  value,
  onChange,
}) => (
  <Container>
    {label && <Label htmlFor={id}>{label}</Label>}
    <Input type={type} id={id} value={value} onChange={onChange} />
    {description && <span>{description}</span>}
  </Container>
)

FormInput.defaultProps = {
  description: '',
  label: '',
  value: '',
}

export default FormInput
