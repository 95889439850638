import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from 'components/Core/Button/Button'
import Header from 'components/Core/Header/Header'
import Icon from 'components/Core/Icon/Icon'

import styles from './VerticalFilterList.less'
import messages from './VerticalFilterList.messages'

class VerticalFilterList extends Component {
  state = { isClosed: this.props.isClosed }

  toggleVisibility = () => {
    if (this.state.isClosed) {
      this.props.trackExpandFilters()
    } else {
      this.props.trackCloseFilters()
    }
    this.setState((prevState) => ({ isClosed: !prevState.isClosed }))
  }

  render() {
    const {
      actionButton,
      actionText,
      children,
      className,
      scrollableClassName,
      onActionClick,
      sidebar,
      title,
      disabled,
      sticky,
    } = this.props
    const { isClosed } = this.state

    return (
      <div
        className={classNames(styles.VerticalFilterList, {
          [styles.sticky]: sticky,
        })}
      >
        <div
          className={classNames(styles.VerticalFilterListContent, className, {
            [styles.closed]: isClosed,
            [styles.sidebar]: sidebar,
          })}
        >
          <div
            className={classNames(
              styles.scrollableSection,
              scrollableClassName,
            )}
          >
            <div className={styles.header}>
              <Header>{title}</Header>
              <div className={styles.toggleVisibilityButton}>
                <Icon
                  name="filter-list"
                  label="Open/Close Filter"
                  onClick={this.toggleVisibility}
                />
              </div>
            </div>
            <div className={styles.children}>{children}</div>
          </div>
          {actionButton && (
            <div className={styles.actions}>
              <Button
                withGrid
                primary
                onClick={onActionClick}
                disabled={disabled}
              >
                {actionText}
              </Button>
            </div>
          )}
        </div>

        {isClosed && (
          <div className={styles.actionCloseText}>
            {messages.actionCloseText}
          </div>
        )}
      </div>
    )
  }
}

VerticalFilterList.propTypes = {
  actionButton: PropTypes.bool,
  actionText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  scrollableClassName: PropTypes.string,
  onActionClick: PropTypes.func,
  sidebar: PropTypes.bool,
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isClosed: PropTypes.bool,
  sticky: PropTypes.bool,
  trackExpandFilters: PropTypes.func,
  trackCloseFilters: PropTypes.func,
}

VerticalFilterList.defaultProps = {
  actionButton: true,
  actionText: '',
  children: null,
  className: null,
  scrollableClassName: null,
  onActionClick: () => {},
  sidebar: false,
  disabled: false,
  isClosed: false,
  sticky: false,
  trackExpandFilters: () => {},
  trackCloseFilters: () => {},
}

export default VerticalFilterList
