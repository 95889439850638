import { Component } from 'react'

import classNames from 'classnames'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'

import { formatRetailerWithLocation } from 'formatters/orders'
import { formatDropdownOptions, formatNameWithCode } from 'utils/formatters'
import { downloadFileFromResponse } from 'utils/requests'
import { getKeyFromFirstElement } from 'utils/transformations/array'
import { fromGlobalId } from 'utils/transformations/graphql'

import Checkbox from 'components/Core/Checkbox/Checkbox'
import Dropdown from 'components/Core/Dropdown/Dropdown'
import Modal from 'components/Core/Modal'

import styles from './CreateOrderTemplateModal.less'
import messages from './CreateOrderTemplateModal.messages'
import { MINIMUM_CHARACTERS_QUERY } from './CreateOrderTemplateModal.queries'

const DEBOUNCE_TIME = 500

export default class CreateOrderTemplateModal extends Component {
  state = {
    fileIsDownloading: false,
    chooseRetailerOnImport: false,
    selectedPriceType: getKeyFromFirstElement(this.props.brandPriceTypes, 'id'),
    selectedCollections: [],
    selectedOrderEvent: null,
    selectedDoors: [],
    includeImages: false,
    retailerOptions: [],
  }

  componentDidUpdate = (prevProps) => {
    const finishedLoading =
      prevProps.connectedAccountsQueryLoading &&
      !this.props.connectedAccountsQueryLoading
    const searchTextChanged =
      prevProps.retailerSearchText !== this.props.retailerSearchText
    const minimumCharactersReached =
      this.props.retailerSearchText.length < MINIMUM_CHARACTERS_QUERY

    if (
      prevProps.initialCreateOrderTemplateLoading &&
      !this.props.initialCreateOrderTemplateLoading
    ) {
      // in case they are not ready when the component first mounts
      this.updateSelectedPriceType(this.props.brandPriceTypes)
    } else if (
      (prevProps.retailerPriceTypesAndDoorsLoading &&
        !this.props.retailerPriceTypesAndDoorsLoading) ||
      prevProps.selectedRetailerId !== this.props.selectedRetailerId
    ) {
      // if a retailer has just been fetched or just changed in the dropdown
      this.updateSelectedPriceType(this.props.retailerPriceTypes)
    }

    if (finishedLoading || searchTextChanged) {
      this.setState({
        retailerOptions: minimumCharactersReached
          ? []
          : this.getRetailerOptions(),
      })
    }
  }

  componentWillUnmount() {
    this.props.changeRetailerSearchText('')
  }

  onClose = () => {
    this.props.setSelectedRetailerId(null)
    this.props.onClose()
  }

  onGoBack = () => {
    this.props.setSelectedRetailerId(null)
    this.props.openExcelOrdersModal()
  }

  getRetailerOptions = () =>
    formatDropdownOptions(
      this.props.connectedAccounts,
      formatRetailerWithLocation,
      true,
      'notranslate',
      'id',
    )

  getPriceTypes = () => {
    const {
      brandPriceTypes,
      retailerPriceTypes,
      selectedRetailerId,
    } = this.props
    const { chooseRetailerOnImport } = this.state
    const priceText = (priceType) =>
      priceType.name === ''
        ? priceType.currency.code
        : `${priceType.currency.code} - ${priceType.name}`
    if (chooseRetailerOnImport || !selectedRetailerId) {
      return formatDropdownOptions(
        brandPriceTypes,
        priceText,
        false,
        'notranslate',
      )
    }
    return formatDropdownOptions(
      retailerPriceTypes,
      priceText,
      false,
      'notranslate',
    )
  }

  getCollectionOptions = () =>
    this.props.collections.map((collection) => ({
      value: collection.id,
      text: collection.name,
      className: 'notranslate',
    }))

  getEventOptions = () =>
    this.props.orderEvents.map((eventInfo) => ({
      value: formatNameWithCode(eventInfo.name, eventInfo.code),
      text: formatNameWithCode(eventInfo.name, eventInfo.code),
      className: 'notranslate',
    }))

  getRequestData = () => {
    const {
      selectedCollections,
      selectedPriceType,
      selectedOrderEvent,
      includeImages,
      selectedDoors,
      chooseRetailerOnImport,
    } = this.state
    const { selectedRetailerId } = this.props
    const collectionIds = selectedCollections.map((selectedCollectionId) => {
      const { id: collectionId } = fromGlobalId(selectedCollectionId)
      return collectionId
    })
    const { id: priceTypeId } = fromGlobalId(selectedPriceType)

    const data = {
      event: selectedOrderEvent,
      for_order_import: 1,
      include_images: includeImages,
      linesheet_ids: collectionIds,
      price_type_id: priceTypeId,
    }

    if (!chooseRetailerOnImport) {
      const { id: retailerId } = fromGlobalId(selectedRetailerId)
      data.account_id = retailerId
      if (selectedDoors.length > 0) {
        const doorIds = selectedDoors.map((selectedDoorId) => {
          const { id: doorId } = fromGlobalId(selectedDoorId)
          return doorId
        })
        data.door_ids = doorIds
      }
    }

    return data
  }

  handleSearchRetailer = debounce((e, { searchQuery }) => {
    if (searchQuery !== this.props.retailerSearchText) {
      this.props.changeRetailerSearchText(searchQuery)
    }
  }, DEBOUNCE_TIME)

  handlePriceTypeChange = (e, { value }) => {
    this.setState({ selectedPriceType: value })
  }

  handleCollectionChange = (e, { value }) => {
    this.setState({
      selectedCollections: value,
    })
  }

  handleRetailerChange = (e, { value }) => {
    this.props.setSelectedRetailerId(value)
  }

  handleIncludeImages = () => {
    this.setState((currentState) => ({
      includeImages: !currentState.includeImages,
    }))
  }

  handleChooseRetailerOnImport = (event, data) => {
    const { selectedRetailerId } = this.props

    if (selectedRetailerId) {
      const { brandPriceTypes, retailerPriceTypes } = this.props
      const firstBrandPriceType = getKeyFromFirstElement(brandPriceTypes, 'id')
      const firstRetailerPriceType = getKeyFromFirstElement(
        retailerPriceTypes,
        'id',
      )
      this.setState({
        chooseRetailerOnImport: data.checked,
        selectedPriceType: data.checked
          ? firstBrandPriceType
          : firstRetailerPriceType,
        selectedDoors: [],
      })
    } else {
      this.setState({
        chooseRetailerOnImport: data.checked,
        selectedDoors: [],
      })
    }
  }

  handleOrderEventChange = (e, { value }) => {
    this.setState({
      selectedOrderEvent: value,
    })
  }

  handleDoorChange = (e, { value }) => {
    this.setState({
      selectedDoors: value,
    })
  }

  downloadTemplate = () => {
    const data = this.getRequestData()
    // TODO(ch15458) rebuild endpoint
    return downloadFileFromResponse('/collections/export/', data)
  }

  handlePrimaryActionClick = () => {
    const {
      selectedPriceType,
      selectedCollections,
      selectedOrderEvent,
    } = this.state

    const { trackCreateOrderTemplateExcelDownloaded } = this.props

    this.setState({ fileIsDownloading: true })

    const collections = this.getCollectionOptions()
    const pricesTypes = this.getPriceTypes()
    const events = this.getEventOptions()
    const retailerOptions = this.getRetailerOptions()

    trackCreateOrderTemplateExcelDownloaded({
      events,
      pricesTypes,
      collections,
      retailerOptions,
      selectedPriceType,
      selectedCollections,
      selectedOrderEvent,
    })

    return this.downloadTemplate()
      .then(() => {
        this.props.renderSuccessNotification(messages.successfulDownload)
        this.setState({ fileIsDownloading: false })
      })
      .catch((error) => {
        this.props.renderErrorNotification(messages.failedDownload)
        this.setState({ fileIsDownloading: false })
        console.error(error)
      })
  }

  updateSelectedPriceType = (newPriceTypes) => {
    this.setState({
      selectedPriceType: getKeyFromFirstElement(newPriceTypes, 'id'),
      selectedDoors: [],
    })
  }

  isPrimaryActionDisabled = () => {
    return (
      (!this.state.chooseRetailerOnImport && !this.props.selectedRetailerId) ||
      !this.state.selectedPriceType ||
      !this.state.selectedCollections.length
    )
  }

  renderCollections = () => (
    <div className={classNames(styles.modalCol, styles.left)}>
      <label htmlFor="linesheets" className={styles.label}>
        <span className={styles.required}>*</span>
        {messages.selectLinesheets}
      </label>
      <Dropdown
        id="linesheets"
        options={this.getCollectionOptions()}
        value={this.state.selectedCollections}
        onChange={this.handleCollectionChange}
        placeholder={messages.selectOne}
        multiple
        search
        className={
          this.state.selectedCollections.length > 0 ? 'notranslate' : ''
        }
      />
    </div>
  )

  render() {
    const {
      chooseRetailerOnImport,
      selectedPriceType,
      selectedOrderEvent,
      includeImages,
      fileIsDownloading,
      selectedDoors,
      retailerOptions,
    } = this.state

    const {
      initialCreateOrderTemplateLoading,
      retailerPriceTypesAndDoorsLoading,
      retailerDoors,
      selectedRetailerId,
      connectedAccountsQueryLoading,
    } = this.props

    const showingDoors = retailerDoors.length > 0 && !chooseRetailerOnImport

    return (
      <Modal
        large
        title={messages.createTemplate}
        onClose={this.onClose}
        primaryActionClose={false}
        primaryActionLabel={messages.downloadTemplate}
        primaryActionOnClick={this.handlePrimaryActionClick}
        primaryDisabled={this.isPrimaryActionDisabled()}
        secondaryActionLabel={messages.goBack}
        secondaryActionOnClick={this.onGoBack}
        secondaryActionClose={false}
        loading={
          fileIsDownloading ||
          initialCreateOrderTemplateLoading ||
          retailerPriceTypesAndDoorsLoading
        }
        open
        className={styles.CreateOrderTemplateModal}
      >
        <div className={styles.modalContainer}>
          <div className={styles.modalRow}>
            <div className={classNames(styles.modalCol, styles.left)}>
              <label htmlFor="retailer" className={styles.label}>
                <span className={styles.required}>*</span>
                {messages.selectRetailer}
              </label>
              <Dropdown
                id="retailer"
                options={retailerOptions}
                loading={connectedAccountsQueryLoading}
                disabled={chooseRetailerOnImport}
                onSearchChange={this.handleSearchRetailer}
                placeholder={messages.startTyping}
                value={selectedRetailerId}
                onChange={this.handleRetailerChange}
                search
                className={selectedRetailerId ? 'notranslate' : ''}
              />
            </div>
            <div className={classNames(styles.modalCol, styles.right)}>
              <label htmlFor="priceType" className={styles.label}>
                <span className={styles.required}>*</span>
                {messages.selectPriceType}
              </label>
              <Dropdown
                id="priceType"
                options={this.getPriceTypes()}
                value={selectedPriceType}
                onChange={this.handlePriceTypeChange}
                placeholder={messages.selectOne}
                className={selectedPriceType ? 'notranslate' : ''}
              />
            </div>
          </div>

          <div className={styles.modalRow}>
            <div className={styles.modalColCheckBox}>
              <Checkbox
                className={styles.checkbox}
                onClick={this.handleChooseRetailerOnImport}
                checked={chooseRetailerOnImport}
                label={messages.chooseOnImport}
              />
            </div>
          </div>

          <div className={styles.modalRow}>
            {showingDoors ? (
              <div className={classNames(styles.modalCol, styles.left)}>
                <span className={styles.label}>{messages.selectDoor}</span>
                <Dropdown
                  id="door"
                  options={retailerDoors}
                  value={selectedDoors}
                  onChange={this.handleDoorChange}
                  placeholder={messages.selectOne}
                  search
                  multiple
                  className={selectedDoors.length > 0 ? 'notranslate' : ''}
                />
              </div>
            ) : (
              this.renderCollections(3)
            )}
            <div className={classNames(styles.modalCol, styles.right)}>
              <label htmlFor="event" className={styles.label}>
                {messages.selectEvent}
              </label>
              <Dropdown
                id="event"
                options={this.getEventOptions()}
                value={selectedOrderEvent}
                onChange={this.handleOrderEventChange}
                search
                placeholder={messages.selectOne}
                className={selectedOrderEvent ? 'notranslate' : ''}
              />
            </div>
          </div>
          {showingDoors && (
            <div className={styles.modalRow}>{this.renderCollections(8)}</div>
          )}
          <div className={styles.modalRow}>
            <div className={styles.modalCol}>
              <Checkbox
                className={styles.checkbox}
                onClick={this.handleIncludeImages}
                checked={includeImages}
                label={messages.includeImages}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}

CreateOrderTemplateModal.propTypes = {
  accountId: PropTypes.number.isRequired,
  connectedAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      displayName: PropTypes.string,
      locations: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          city: PropTypes.string,
          state: PropTypes.string,
        }),
      ),
    }),
  ),
  collections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  orderEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      code: PropTypes.string,
    }),
  ).isRequired,
  brandPriceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        id: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
  setSelectedRetailerId: PropTypes.func.isRequired,
  selectedRetailerId: PropTypes.string,
  retailerDoors: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ),
  retailerPriceTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      currency: PropTypes.shape({
        id: PropTypes.string.isRequired,
        code: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ),
  retailerPriceTypesAndDoorsLoading: PropTypes.bool,
  initialCreateOrderTemplateLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  renderErrorNotification: PropTypes.func.isRequired,
  renderSuccessNotification: PropTypes.func.isRequired,
  changeRetailerSearchText: PropTypes.func.isRequired,
  connectedAccountsQueryLoading: PropTypes.bool,
  retailerSearchText: PropTypes.string.isRequired,
  openExcelOrdersModal: PropTypes.func,
  trackCreateOrderTemplateExcelDownloaded: PropTypes.func,
}

CreateOrderTemplateModal.defaultProps = {
  connectedAccounts: [],
  retailerDoors: [],
  retailerPriceTypes: [],
  retailerPriceTypesAndDoorsLoading: false,
  selectedRetailerId: null,
  connectedAccountsQueryLoading: false,
  openExcelOrdersModal: () => {},
  trackCreateOrderTemplateExcelDownloaded: () => {},
}
