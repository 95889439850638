import { Query } from '@apollo/client/react/components'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'

import { getAggregatedSkuDeliveryRange } from 'utils/transformations/deliveryWindows'
import {
  fromGlobalId,
  relayConnectionToArray,
  toGlobalId,
} from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas, graphene } from 'graphql/tags'
import { aggregateProductCasepacksSkus } from 'shop/products/getters'
import {
  groupProductsByCollection,
  transformProduct,
  transformSkuInventoryItems,
} from 'shop/products/transformations'
import { collectionIsMTO, fromSkuId } from 'shop/products/utils'

import {
  getDiscountFactor,
  transformWarehouses,
} from './ProductDetailModal.transformations'

const warehousesForRetailerQuery = graphene`#graphql
  query warehousesForRetailerQuery($retailerId: ID!) {
    warehousesForRetailer(filterBy: { retailerId: $retailerId }) {
      edges {
        node {
          id
          name
          code
          default
        }
      }
    }
  }
`

const warehousesFromBrandQuery = graphene`#graphql
  query warehousesFromBrandQuery($brandId: ID!) {
    warehousesFromBrand(filterBy: { brandId: $brandId }) {
      edges {
        node {
          id
          name
          code
          default
        }
      }
    }
  }
`

const warehousesQuery = atlas`#graphql
  query WarehousesQueryDupe3($productId: ID, $brandId: ID) {
    warehouses(brandId: $brandId) {
      edges {
        node {
          id
          name
          code
          availabilityGroups {
            edges {
              node {
                id
                availability {
                  availableOn
                  isImmediate
                }
                isEmpty(productId: $productId)
              }
            }
          }
        }
      }
    }
  }
`
const collectionProductQuery = atlas`#graphql
  query CollectionProductQuery(
    $filters: ProductsCollectionFilter
    $brandIds: [ID!]
    $externalMediaEnabled: Boolean!
    $productVideosAreEnabled: Boolean!
  ) {
    collectionProducts(filter: $filters, brandIds: $brandIds) {
      edges {
        node {
          id
          product {
            id
            name
            externalIdentifier
            description
            categories {
              edges {
                node {
                  id
                  name
                  parentName
                }
              }
            }
            tagValues {
              edges {
                node {
                  id
                  externalIdentifier
                  value
                  tag {
                    id
                    name
                  }
                }
              }
            }
            badges {
              edges {
                node {
                  id
                  name
                  image {
                    url
                  }
                }
              }
            }
            orderMinimum
            images {
              url
            }
            videos @include(if: $productVideosAreEnabled) {
              id
              url
            }
            externalMedia @include(if: $externalMediaEnabled) {
              id
              url
              type
              format
              thumbnailUrl
              payload
            }
            traitValues {
              edges {
                node {
                  id
                  value
                  externalIdentifier
                  trait {
                    id
                    name
                    code
                  }
                }
              }
            }
          }
          collection {
            id
          }
          variants {
            edges {
              values {
                edges {
                  node {
                    id
                    value
                    description
                    trait {
                      id
                      code
                      name
                    }
                    externalIdentifier
                    orderMinimum
                    displayDetails {
                      merchandiseImages {
                        url
                      }
                      hexColor
                      swatchImage {
                        url
                      }
                    }
                  }
                }
              }
              node {
                id
                code
                name
              }
            }
          }
          skus {
            edges {
              node {
                id
                prices {
                  id
                  wholesalePrice
                  suggestedRetailPrice
                  priceType {
                    id
                  }
                }
                variantValues {
                  edges {
                    node {
                      id
                      value
                      trait {
                        id
                        code
                        name
                      }
                    }
                  }
                }
              }
            }
          }
          casepacks {
            edges {
              node {
                id
                name
                externalIdentifier
                description
                skus {
                  edges {
                    quantity
                    node {
                      id
                      variantValues {
                        edges {
                          node {
                            id
                            value
                            trait {
                              id
                              code
                              name
                            }
                          }
                        }
                      }
                      prices {
                        id
                        wholesalePrice
                        suggestedRetailPrice
                        priceType {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const collectionsQuery = atlas`#graphql
  query CollectionsQueryDupe7($brandIds: [ID!]) {
    collections(brandIds: $brandIds, filters: { archived: false }) {
      edges {
        node {
          id
          madeToOrder
          delivery {
            id
            startShipDate
            completeShipDate
          }
        }
      }
    }
  }
`

const aggregateSkuInventoryItemsQuery = atlas`#graphql
  query aggregateSkuInventoryItemsDupe3(
    $warehouseIds: [ID!]!
    $endDate: String
    $includeImmediate: Boolean
    $filter: AggregateSkuInventoryItemsFilter
  ) {
    aggregateSkuInventoryItems(
      warehouseIds: $warehouseIds
      endDate: $endDate
      includeImmediate: $includeImmediate
      filter: $filter
    ) {
      items {
        availableQuantity
        skuId
      }
    }
  }
`

const priceTypesQuery = graphene`#graphql
  query priceTypesQuery($brandId: ID) {
    priceTypes(filterBy: { brandIds: [$brandId] }) {
      edges {
        node {
          id
          name
          currency {
            name
            id
            code
          }
          retailCurrency {
            id
            name
            code
          }
        }
      }
    }
  }
`

export const withPriceTypes = (WrappedComponent) => (props) => {
  const {
    brandAccount: { id: brandAccountId },
  } = props
  return (
    <Query
      query={priceTypesQuery}
      variables={{ brandId: brandAccountId }}
      skip={!brandAccountId}
    >
      {({ loading, error, data }) => {
        const customProps = {
          ...props,
          priceTypes:
            !loading && !error && data && data.priceTypes
              ? relayConnectionToArray(data.priceTypes)
              : props.priceTypes,
        }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}

export const withAggregateSkuInventoryItems = (WrappedComponent) => (props) => {
  const {
    warehouseId,
    warehouseDeliveryDate,
    ignoresInventory,
    product: { id: productId },
    defaultWarehouseId,
  } = props
  const warehouseIds = [warehouseId || defaultWarehouseId]
  const filter = { productIds: [productId] }
  // TODO: fix endDate when Hyperion story 59398 is merged
  const endDate = warehouseDeliveryDate || JSON.stringify(warehouseDeliveryDate)
  const includeImmediate = true
  return (
    <Query
      client={atlasClient}
      query={aggregateSkuInventoryItemsQuery}
      variables={{ warehouseIds, endDate, includeImmediate, filter }}
      skip={
        !productId || !(warehouseId || defaultWarehouseId) || ignoresInventory
      }
    >
      {({ loading, error, data }) => {
        const customProps = {
          ...props,
          skuInventoryItemsLoading: loading,
          skuInventoryItems:
            !loading && !error && data
              ? transformSkuInventoryItems(data.aggregateSkuInventoryItems)
              : new Map(),
        }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}

export const withCollectionProduct = (WrappedComponent) => ({
  styleId,
  ...rest
}) => {
  const { skipProductDetailQueryCache } = useFlags()
  const productId = toGlobalId('Product', styleId)
  const collectionId = toGlobalId('Collection', rest.linesheetId)
  const orbVideosAreEnabled = get(rest, 'flags.orb360ProductVideos')
  const vntanaIsEnabled = get(rest, 'flags.vntana')
  const productVideosAreEnabled = get(rest, 'flags.productVideos')
  const externalMediaEnabled = orbVideosAreEnabled || vntanaIsEnabled
  return (
    <Query
      client={atlasClient}
      query={collectionProductQuery}
      variables={{
        limit: 1,
        brandIds: [rest.brandAccount.id],
        filters: {
          productIds: [productId],
          collectionIds: [collectionId],
        },
        externalMediaEnabled,
        productVideosAreEnabled,
      }}
      skip={
        !productId ||
        externalMediaEnabled === undefined ||
        productVideosAreEnabled === undefined
      }
      fetchPolicy={skipProductDetailQueryCache ? 'no-cache' : 'cache-first'}
    >
      {({ loading, error, data, networkStatus }) => {
        const collectionProducts =
          data && (!loading || data.collectionProducts) && !error
            ? relayConnectionToArray(data.collectionProducts)
            : []
        const productsGroupByCollection = groupProductsByCollection(
          collectionProducts,
          (product) => transformProduct(product),
        )
        const product = aggregateProductCasepacksSkus(
          get(
            productsGroupByCollection,
            `${collectionId}[0]`,
            transformProduct({}),
          ),
        )
        const customProps = {
          ...rest,
          productLoading: loading,
          isProductReady: networkStatus === 7,
          product,
        }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}

export const withCollections = (WrappedComponent) => ({
  linesheetId,
  ...rest
}) => {
  const collectionId = toGlobalId('Collection', linesheetId)
  return (
    <Query
      client={atlasClient}
      query={collectionsQuery}
      variables={{ brandIds: [rest.brandAccount.id] }}
      skip={!(rest.enableQuantifying || rest.forceDeliveryWindow)}
    >
      {({ loading, error, data }) => {
        const customProps = {
          ...rest,
          collectionId,
          collectionsLoading: loading,
          collections:
            !loading && !error && data
              ? relayConnectionToArray(data.collections)
              : [],
          ignoresInventory:
            !loading && !error && data
              ? collectionIsMTO(
                  relayConnectionToArray(data.collections),
                  collectionId,
                )
              : true,
        }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}

export const withWarehouses = (WrappedComponent) => (props) => (
  <Query
    client={atlasClient}
    query={warehousesQuery}
    variables={{ productId: props.product.id, brandId: props.brandAccount.id }}
    skip={!props.product.id || !props.enableQuantifying}
    // we do this to make sure we trigger an update from the
    // prop value to the state value of the selectedWarehouseId
    // at the product detail modal sc-177724
    fetchPolicy="no-cache"
  >
    {({ loading, error, data, networkStatus }) => {
      const customProps = {
        ...props,
        areWarehousesReady: networkStatus === 7,
        brandWarehousesWithInventory:
          !loading && !error && data
            ? transformWarehouses(data.warehouses)
            : [],
        brandWarehouses:
          !loading && !error && data
            ? relayConnectionToArray(data.warehouses)
            : [],
        warehouseQueryLoading: loading,
        warehouseQueryError: error,
      }
      return <WrappedComponent {...customProps} />
    }}
  </Query>
)

export const getVariablesForWarehousesFromConnection = (props) => {
  const {
    isBrandUser,
    retailerAccount: { id: retailerId },
    brandAccount: { id: brandId },
  } = props
  if (isBrandUser) {
    return {
      retailerId: toGlobalId('RetailerAccount', fromGlobalId(retailerId).id),
    }
  }
  return { brandId: toGlobalId('BrandAccount', fromGlobalId(brandId).id) }
}
export const withWarehousesFromConnection = (WrappedComponent) => (props) => {
  const { enableQuantifying, isBrandUser } = props
  const query = isBrandUser
    ? warehousesForRetailerQuery
    : warehousesFromBrandQuery
  const dataProperty = isBrandUser
    ? 'warehousesForRetailer'
    : 'warehousesFromBrand'
  const variables = getVariablesForWarehousesFromConnection(props)

  return (
    <Query
      query={query}
      variables={variables}
      skip={!enableQuantifying}
      // we do this to make sure we trigger an update from the
      // prop value to the state value of the selectedWarehouseId
      // at the product detail modal sc-177724
      fetchPolicy="no-cache"
    >
      {({ loading, error, data, networkStatus }) => {
        const customProps = {
          ...props,
          warehousesFromConnection: data
            ? relayConnectionToArray(data[dataProperty])
            : [],
          areWarehousesFromConnectionReady: networkStatus === 7,
          warehousesFromConnectionLoading: loading,
          warehousesFromConnectionError: error,
        }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}

export const discountsQuery = atlas`#graphql
  query DiscountsQuery($brandId: ID, $retailerId: ID!, $productIds: [ID!]!) {
    productDiscounts(
      brandId: $brandId
      retailerId: $retailerId
      productIds: $productIds
    ) {
      edges {
        node {
          factor
          id
        }
      }
    }
  }
`

export const withDiscounts = (WrappedComponent) => (props) => {
  const {
    product: { id: productId },
    retailerAccount: { id: retailerId },
    brandAccount: { id: brandId },
    enableQuantifying,
  } = props

  return (
    <Query
      client={atlasClient}
      query={discountsQuery}
      variables={{ retailerId, brandId, productIds: [productId] }}
      skip={!productId || !enableQuantifying}
    >
      {({ loading, error, data, networkStatus }) => {
        const discountsArray =
          !loading && !error && data
            ? relayConnectionToArray(data.productDiscounts)
            : []
        const customProps = {
          ...props,
          areDiscountsReady: networkStatus === 7,
          discounts: discountsArray,
          discountFactor: getDiscountFactor(discountsArray),
        }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}

export const SKU_DELIVERIES = atlas`#graphql
  query skuDeliveriesDupe1(
    $collectionIds: [ID!]!
    $brandIds: [ID!]
    $filter: SkuDeliveryFilter
  ) {
    skuDeliveries(
      collectionIds: $collectionIds
      brandIds: $brandIds
      filter: $filter
    ) {
      edges {
        node {
          id
          sku {
            id
          }
          startShipDate
          completeShipDate
        }
      }
    }
  }
`

export const withSkuDeliveries = (WrappedComponent) => (props) => {
  const { skuDeliveries } = useFlags()
  return (
    <Query
      client={atlasClient}
      query={SKU_DELIVERIES}
      variables={{
        collectionIds: [props.collectionId],
        filter: {
          productIds: props.product?.id
            ? [props.product?.id]
            : props?.productIds,
          onlyActive: true,
        },
        brandIds: props.brandAccount ? [props.brandAccount.id] : [],
      }}
      skip={
        (!props.product?.id && !props.productIds) ||
        !props.collectionId ||
        !skuDeliveries
      }
    >
      {({ loading, error, data }) => {
        const skuDeliveries =
          !loading && !error && data
            ? relayConnectionToArray(get(data, 'skuDeliveries', []))
            : []
        const skuDeliveriesByColorVariant = groupBy(
          skuDeliveries,
          (skuDelivery) => `${fromSkuId(skuDelivery?.sku?.id).colorVariantId}`,
        )
        const customProps = {
          ...props,
          skuDeliveries: skuDeliveriesByColorVariant,
          skuDeliveriesLoading: loading,
          productSkuDeliveryRange: getAggregatedSkuDeliveryRange(skuDeliveries),
        }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}
