import { handleActions } from 'redux-actions'

export const defaultState = {
  exportOptions: {
    withWholesale: false,
    withDiscounts: false,
    withWholesaleFactor: false,
    withRetail: false,
    withMarkup: false,
    withRetailFactor: false,
    withBudgets: false,
    withComments: false,
    withTags: false,
    withUnits: false,
    perPage: 1,
  },
  submittingPdfExport: false,
}

const reducer = handleActions(
  {
    'BRAND_PDF_EXPORT_MODAL/SET_EXPORT_OPTIONS': (state, { payload }) => ({
      ...state,
      exportOptions: payload,
    }),
    'BRAND_PDF_EXPORT_MODAL/BEGIN_SUBMIT': (state) => ({
      ...state,
      submittingPdfExport: true,
    }),
    'BRAND_PDF_EXPORT_MODAL/END_SUBMIT': (state) => ({
      ...state,
      submittingPdfExport: false,
    }),
  },
  defaultState,
)

export default reducer
