import Filter from 'utils/filters/baseFilter'
import { formatNameWithCode } from 'utils/formatters'

import PriceRangeContainer from 'containers/ProductCatalog/PriceRange/PriceRange.container'

/*
 * PriceRangeFilter
 * It is a type of filter that allows the selection of a range of prices (one price, both prices or none).
 */
export default class PriceRangeFilter extends Filter {
  constructor({ defaultState, selectedPriceType, ...rest }) {
    super(rest)
    const { id: selectedPriceTypeId, currency: selectedPriceTypeCurrency } =
      selectedPriceType || {}
    this.unselectedState = null
    if (defaultState) {
      this.defaultState = defaultState
    }
    this.formattedCurrency = formatNameWithCode(
      selectedPriceTypeCurrency?.name,
      selectedPriceTypeCurrency?.code,
    )
    this.currencyCode = selectedPriceTypeCurrency?.code
    this.priceTypeId = selectedPriceTypeId
  }

  isUnselected = (filterItem) => !filterItem

  addToActiveFilters = (currentState, filterState) => {
    // passes without modifying
    if (this.isUnselected(filterState)) {
      return currentState
    }
    return [...currentState, filterState]
  }

  // eslint-disable-next-line
  toGraphQLVariable = (graphQLFilters, { value }) => {
    if (this.isUnselected(value)) {
      return graphQLFilters
    }
    const { minValue, maxValue, priceTypeId } = value
    return {
      ...graphQLFilters,
      [this.filterKey]: { minValue, maxValue, priceTypeId },
    }
  }

  reducerHandler = (selectedFilters, filterInfo) => {
    const { id } = filterInfo
    // Overwrite or remove
    const isAlreadyThere =
      selectedFilters[this.filterKey] &&
      selectedFilters[this.filterKey].filterKey === id
    return {
      ...selectedFilters,
      [this.filterKey]: isAlreadyThere ? null : filterInfo,
    }
  }

  renderSelectedFilterHandler = ({ actionHandler }) => {
    return (
      <PriceRangeContainer
        currency={this.currencyCode}
        applyFilter={(minValue, maxValue) =>
          actionHandler({
            minValue,
            maxValue,
            priceTypeId: this.priceTypeId,
            text: 'Price Range',
          })
        }
      />
    )
  }

  getSelectedFiltersPillFormatted = ({ selectedFilters, onActionClick }) => {
    const selectedFilter = selectedFilters[this.filterKey]
    if (selectedFilter) {
      const { minValue, maxValue } = selectedFilter
      return [
        {
          id: this.filterKey,
          name: (
            <span data-testid="priceRangePill">
              {`${this.label}: `}
              <var>{`${
                minValue ? `Min: ${minValue} ${this.formattedCurrency}` : ''
              }`}</var>
              {`${minValue && maxValue ? ' - ' : ''}`}
              <var>{`${
                maxValue ? `Max: ${maxValue} ${this.formattedCurrency}` : ''
              }`}</var>
            </span>
          ),
          onClick: onActionClick,
        },
      ]
    }
    return []
  }

  addToActiveFilters = (currentState, filterState) => {
    // if it is unselected, it passes without modifying
    if (this.isUnselected(filterState)) {
      return currentState
    }
    return [
      ...currentState,
      {
        dataKey: this.dataKey,
      },
    ]
  }

  getCountFilters = (filterState) => (this.isUnselected(filterState) ? 0 : 1)
}
