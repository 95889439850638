export const SALES_SUMMARY = 'SALES_SUMMARY'
export const STYLE_DETAIL = 'STYLE_DETAIL'
export const PRODUCTION = 'PRODUCTION'
export const RETAIL_SALES = 'RETAIL_SALES'
export const DELIVERY = 'DELIVERY'
export const CSV_EXPORT = 'CSV'
export const EXCEL_EXPORT = 'Excel'
export const PDF_EXPORT = 'PDF'

export const LOADED = 'LOADED'
export const LOADING = 'LOADING'
