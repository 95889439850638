import { FC, useEffect, useState } from 'react'

import { Icon } from '@joor/design-system'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import styled, { css } from 'styled-components'

import { getSizedImageUrl } from 'utils/images'

type StyledCarouselContainerProps = {
  $width: number
}
const StyledCarouselContainer = styled.div<StyledCarouselContainerProps>`
  width: calc(100% - 64px);
  margin: 0 auto;
  position: relative;
  height: 458px;
  .alice-carousel,
  .alice-carousel__wrapper {
    height: 100% !important;
  }
  .alice-carousel__dots {
    margin: 15px 0 0 !important;
  }
  .alice-carousel__dots-item {
    background-color: var(--color-primary-200) !important;
    margin: 0 4px !important;
    border-radius: 50% !important;
  }
  .__active {
    background-color: var(--color-primary-400) !important;
  }
  .alice-carousel__stage-item {
    background: white !important;
    text-align: center;
  }
  @media only screen and (min-width: 1024px) {
    width: calc(${({ $width }): number => $width}px - 36%);
    margin: 0;
  }
  @media only screen and (min-width: 1024px) {
    width: calc(${({ $width }): number => $width}px - 30% - 64px);
  }
`
const StyledImage = styled.img`
  width: 100%;
  height: 458px;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
`
const baseButtonStyles = css`
  position: absolute;
  top: calc(50% - 20px);
  border-radius: 40px;
  border: 1px solid var(--color-primary-100);
  background: var(--color-neutral-100);
  opacity: 40%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    opacity: 100%;
  }
`
const StyledLeftButton = styled.div`
  ${baseButtonStyles}
  left: 16px;
`
const StyledRightButton = styled.div`
  ${baseButtonStyles}
  right: calc(0% + 16px);
`
const StyledDot = styled.div`
  height: 8px;
  width: 8px;
  cursor: pointer;
`

const getItems = (imageUrls: string[]) =>
  imageUrls.map((imageUrl: string) => (
    <StyledImage
      src={getSizedImageUrl(imageUrl, '1200x800')}
      alt="carousel-image"
    />
  ))

type CarouselProps = {
  imageUrls: string[]
  testId?: string
}

const Carousel: FC<CarouselProps> = ({ imageUrls, testId }) => {
  const [autoPlay, setAutoPlay] = useState<boolean>(true)
  const [width, setWidth] = useState<number>(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  const items = getItems(imageUrls)
  const extraProps = {
    ...(imageUrls.length > 1 && {
      autoPlay,
      animationDuration: 400,
      autoPlayInterval: 5000,
      infinite: true,
      renderPrevButton: () => {
        return (
          <StyledLeftButton onClick={() => setAutoPlay(false)}>
            <Icon iconName="chevron-left" />
          </StyledLeftButton>
        )
      },
      renderNextButton: () => {
        return (
          <StyledRightButton onClick={() => setAutoPlay(false)}>
            <Icon iconName="chevron-right" />
          </StyledRightButton>
        )
      },
      renderDotsItem: () => {
        return <StyledDot onClick={() => setAutoPlay(false)} />
      },
    }),
    ...(imageUrls.length <= 1 && {
      disableButtonsControls: true,
      disableDotsControls: true,
    }),
  }

  return (
    <StyledCarouselContainer $width={width} data-testid={testId ?? ''}>
      <AliceCarousel
        {...extraProps}
        mouseTracking
        items={items}
        responsive={{
          0: { items: 1 },
          568: { items: 1 },
          1024: { items: 1 },
        }}
        controlsStrategy="alternate"
      />
    </StyledCarouselContainer>
  )
}

export default Carousel
