import range from 'lodash/range'
import moment from 'moment'

export const INITIAL_DATE = '1970-01-01T00:00:00+00:00'

export const timestampToUnixEpoch = (dateString) => moment(dateString).valueOf()

export const isDateToday = (dateObject) => moment().isSame(dateObject, 'day')

export const DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'hh:mm A'
export const MONTH_FORMAT = 'MMM D'
export const DEFAULT_DAYS = 30
export const MILISECONDS_DAY = 1000 * 60 * 60 * 24

export const timeRange = (startTime = null) => {
  const start = startTime
    ? moment(startTime, TIME_FORMAT)
    : moment().startOf('day')

  const minutesMax = moment().endOf('day').diff(start, 'minutes')
  const minutesInterval = 15

  return range(0, minutesMax, minutesInterval).map((minuteOffset) =>
    moment(start).add(minuteOffset, 'minutes').format(TIME_FORMAT),
  )
}

export const isBefore = (startTime, endTime) =>
  !endTime ||
  (!!startTime &&
    moment(startTime, TIME_FORMAT).isSameOrBefore(moment(endTime, TIME_FORMAT)))

export const mergeDateTime = (date, time) =>
  date && time
    ? moment(`${date} ${time}`, `${DATE_FORMAT} ${TIME_FORMAT}`)
    : null

export const getDayStringRange = (
  stringFormat = MONTH_FORMAT,
  numDays = DEFAULT_DAYS,
) => {
  const startDateFormatted = moment()
    .subtract(numDays, 'day')
    .format(stringFormat)
  const endDateFormatted = moment().format(stringFormat)

  if (numDays === 0) {
    return `${endDateFormatted}`
  }

  return `${startDateFormatted} - ${endDateFormatted}`
}

export const timeAgoSince = (date) => moment(date).fromNow(true)

export const dateComparator = (value1, value2) => {
  const date1 = moment(value1)
  const date2 = moment(value2)
  if (date1.isSame(date2, 'day')) {
    return 0
  }
  if (date1.isAfter(date2, 'day')) {
    return -1
  }
  return 1
}

export const rangeLessThanDays = (startDate, endDate, maxDays) =>
  Math.abs(moment(startDate).diff(moment(endDate), 'days')) < maxDays

export const isTodayAfterExpirationDate = (expirationMont, expirationYear) => {
  const today = moment()
  const expirationDate = moment(`${expirationYear}${expirationMont}`, 'YYYYM')

  return expirationDate.isValid() && today >= expirationDate.add(1, 'months')
}

export const isRangeUnderThreshold = (threshold = 0, startDate, endDate) => {
  if (!startDate || !endDate) {
    return false
  }

  const formattedStartDate = new Date(startDate).getTime()
  const formattedEndDate = new Date(endDate).getTime()
  const daysInDeliveryWindow =
    (formattedEndDate - formattedStartDate) / MILISECONDS_DAY
  return (
    formattedEndDate < formattedStartDate || daysInDeliveryWindow < threshold
  )
}

export const isEarlierDate = (value1, value2) => {
  if (!value1) {
    return true
  }
  if (!value2) {
    return false
  }
  const date1 = moment(value1)
  const date2 = moment(value2)

  return date1.isBefore(date2)
}

export const getDateFromNow = (daysToAdd) => moment().add(daysToAdd, 'days')

export const getDiffFromNow = (date) => moment(date).diff(moment(), 'days')

export const formatDate = (date) => moment(date).format(DATE_FORMAT)

export const wipeDateHours = (date) => new Date(date.setHours(0, 0, 0, 0))
