import { Component } from 'react'

import PropTypes from 'prop-types'

const downloadFile = (exportResultUrl) => {
  const element = document.createElement('a')
  element.setAttribute('href', exportResultUrl)
  element.setAttribute('download', null)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

class FileDownloadPoller extends Component {
  componentDidUpdate() {
    const {
      downloadStatus,
      downloadUrl,
      removeDownloadTaskId,
      taskId,
    } = this.props

    if (
      downloadStatus &&
      downloadStatus !== 'SUCCESS' &&
      downloadStatus !== 'FAILURE'
    ) {
      return
    }

    if (downloadUrl) {
      downloadFile(downloadUrl)
    }
    removeDownloadTaskId(taskId)
  }

  render = () => null
}

FileDownloadPoller.propTypes = {
  taskId: PropTypes.string.isRequired,
  downloadStatus: PropTypes.string,
  downloadUrl: PropTypes.string,
  removeDownloadTaskId: PropTypes.func.isRequired,
}

FileDownloadPoller.defaultProps = {
  downloadStatus: 'PENDING',
  downloadUrl: '',
}

export default FileDownloadPoller
