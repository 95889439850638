import { useQuery } from '@apollo/client'
import get from 'lodash/get'

import { getAggregatedSkuDeliveryRange } from 'utils/transformations/deliveryWindows'
import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import { transformCollectionProducts } from 'shop/products/transformations'

import {
  getProductCasepacks,
  getProductColor,
  getProductSizes,
} from './SkuDeliveryModal.transformations'

export const SKU_DELIVERIES = atlas`#graphql
  query skuDeliveriesDupe2(
    $collectionIds: [ID!]!
    $brandIds: [ID!]
    $filter: SkuDeliveryFilter
  ) {
    skuDeliveries(
      collectionIds: $collectionIds
      brandIds: $brandIds
      filter: $filter
    ) {
      edges {
        node {
          id
          sku {
            id
          }
          collection {
            id
          }
          product {
            id
          }
          startShipDate
          completeShipDate
        }
      }
    }
  }
`

export const PRODUCT_SKUS = atlas`#graphql
  query collectionProducts($productIds: [ID!], $collectionIds: [ID!]) {
    collectionProducts(
      filter: { productIds: $productIds, collectionIds: $collectionIds }
    ) {
      edges {
        node {
          product {
            id
            name
            images {
              url
            }
          }
          skus {
            edges {
              node {
                id
              }
            }
          }
          casepacks {
            edges {
              node {
                id
                name
                skus {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            }
          }
          variants {
            edges {
              values {
                edges {
                  node {
                    id
                    value
                    externalIdentifier
                    displayDetails {
                      hexColor
                      swatchImage {
                        url
                      }
                      merchandiseImages {
                        url
                      }
                    }
                  }
                }
              }
              node {
                id
                code
              }
            }
          }
        }
      }
    }
  }
`

export const useSkuDeliveries = (productId, collectionId, brandId) => {
  const filter = {
    productIds: [productId],
    onlyActive: true,
  }

  const { loading, data } = useQuery(SKU_DELIVERIES, {
    client: atlasClient,
    skip: !productId,
    variables: { collectionIds: [collectionId], brandIds: [brandId], filter },
  })
  const skuDeliveries = relayConnectionToArray(get(data, 'skuDeliveries', []))
  return {
    skuDeliveriesLoading: loading,
    skuDeliveries,
    productSkuDeliveryRange: getAggregatedSkuDeliveryRange(skuDeliveries),
  }
}

export const useProduct = (productId, collectionId, variantId) => {
  const { loading, data } = useQuery(PRODUCT_SKUS, {
    client: atlasClient,
    skip: !productId,
    variables: { productIds: [productId], collectionIds: [collectionId] },
  })
  const product = transformCollectionProducts(
    relayConnectionToArray(data?.collectionProducts),
  )[0]
  const productColor = getProductColor(product, variantId)
  return {
    productLoading: loading,
    product,
    productSizes: getProductSizes(product, variantId),
    productCasepacks: getProductCasepacks(product, variantId),
    productColor,
    productImage:
      get(productColor, 'displayDetails.merchandiseImages[0].url', '') ||
      get(product, 'images[0].url', ''),
  }
}
