import classNames from 'classnames'
import { PropTypes } from 'prop-types'

import styles from './SearchExpanded.less'
import { EMPTY_RESULTS_TEXT } from './constants'

const Result = ({ inFocus, isSelected, onResultSelect, title }) => {
  const rootStyles = classNames(styles.result, {
    [styles.active]: inFocus,
    [styles.selected]: isSelected,
  })

  return (
    <div
      className={rootStyles}
      onMouseDown={(event) => {
        const resultText = event.target.textContent
        const result = { title: resultText }

        if (resultText !== EMPTY_RESULTS_TEXT) {
          // can't select empty results warning
          onResultSelect(event, { result })
        }
      }}
    >
      {title}
    </div>
  )
}

Result.propTypes = {
  inFocus: PropTypes.bool.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onResultSelect: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default Result
