import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Bubble.less'

export const TOP_RIGHT = 'top-right'

const YELLOW = 'yellow'
export const BLUE = 'blue'

const Bubble = ({ children, color, position }) => (
  <div className={classNames(styles.bubble, styles[color], styles[position])}>
    {children}
  </div>
)

Bubble.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf([TOP_RIGHT, '']),
  color: PropTypes.oneOf([YELLOW, BLUE]),
}

Bubble.defaultProps = {
  color: YELLOW,
  position: '',
}

export default Bubble
