const FACEBOOK_TYPE: string = 'FACEBOOK'
const INSTAGRAM_TYPE: string = 'INSTAGRAM'
const TWITTER_TYPE: string = 'TWITTER'

type AccountMedia = {
  id: string
  mediaType: string
  url: string
  __typename: string
}
type SocialMediaAccount = {
  socialMediaType: string
  url: string
  __typename: string
}
type Profile = {
  accountMedia: AccountMedia
  logoId: string
  logoUrl: string
  productLineIds: string[]
  wholesaleMin: number
  wholesaleMax: number
  retailMin: number
  retailMax: number
  customUrlHandle: string
  websiteUrl: string
  socialMediaAccounts: SocialMediaAccount[]
  yearEstablished: number
  description: string
}
export const formatProfile = (profile: Profile) => ({
  accountMedia: profile?.accountMedia,
  logo: { id: profile?.logoId, url: profile?.logoUrl },
  productLineIds: profile?.productLineIds,
  wholesaleMin: profile?.wholesaleMin,
  wholesaleMax: profile?.wholesaleMax,
  retailMin: profile?.retailMin,
  retailMax: profile?.retailMax,
  customUrlHandle: profile?.customUrlHandle,
  websiteUrl: profile?.websiteUrl,
  facebookLink:
    profile?.socialMediaAccounts
      ?.find(
        (socialMedia: SocialMediaAccount) =>
          socialMedia?.socialMediaType === FACEBOOK_TYPE,
      )
      ?.url?.split('https://facebook.com/')?.[1] || '',
  instagramHandle:
    profile?.socialMediaAccounts
      ?.find(
        (socialMedia: SocialMediaAccount) =>
          socialMedia?.socialMediaType === INSTAGRAM_TYPE,
      )
      ?.url?.split('https://instagram.com/')?.[1] || '',
  twitterHandle:
    profile?.socialMediaAccounts
      ?.find(
        (socialMedia: SocialMediaAccount) =>
          socialMedia?.socialMediaType === TWITTER_TYPE,
      )
      ?.url.split('https://twitter.com/')?.[1] || '',
  yearEstablished: profile?.yearEstablished,
  description: profile?.description,
})
