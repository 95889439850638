import { HOME_DASHBOARD_PREFIX as FEATURE_PREFIX } from 'featurePrefix.ids'

const HOME_DASHBOARD_HEADER = 'snapshot-header'
const HOME_DASHBOARD_SALES = 'snapshot-sales'
const HOME_DASHBOARD_RETAILERS = 'snapshot-retailers'

export const BrandDashBoardHeaderIds = {
  DashboardTitle: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}_account-name-title`,
  DashboardCurrency: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__currency`,
  DashboardFilterBy: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__filterBy`,
  DashboardFilterValue: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__filterValue`,
  DashboardFilterApplied: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__filterApplied`,
}

export const BrandDashBoardFilterIds = {
  DashboardSalesRep: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__salesRep`,
  DashboardLinesheet: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__linesheet`,
  DashboardSeason: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__season`,
  DashboardRetailer: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__retailer`,
  DashboardCreated: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__created`,
  DashboardFilterCurrency: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__currency`,
  DashboardCountry: `${FEATURE_PREFIX}__${HOME_DASHBOARD_HEADER}__country`,
}

export const BrandDashBoardSalesIds = {
  DashboardSalesTitle: `${FEATURE_PREFIX}__${HOME_DASHBOARD_SALES}__title`,
  DashboardSalesOrders: `${FEATURE_PREFIX}__${HOME_DASHBOARD_SALES}__orders`,
}

export const BrandDashBoardRetailersIds = {
  DashboardRetailersTitle: `${FEATURE_PREFIX}__${HOME_DASHBOARD_RETAILERS}__title`,
  DashboardRetailersOrders: `${FEATURE_PREFIX}__${HOME_DASHBOARD_RETAILERS}__orders`,
  DashboardRetailersBudget: `${FEATURE_PREFIX}__${HOME_DASHBOARD_RETAILERS}__budget`,
}
