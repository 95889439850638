import { useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'

import { useUserAnalytics } from 'utils/sdks/userAnalytics'

import {
  isAccountTypeBrand,
  isAccountTypeRetailer,
  isLiteRetailer as isLiteRetailerSelector,
  isProBrand as isProBrandSelector,
} from 'store/currentUser/selectors'

import { Tracker } from './types'

const addPHPMixpanelTrackers: Tracker = (...args) => {
  async function route(route: string) {
    try {
      const path = window.location.pathname
      if (!path.startsWith(route)) return

      const module = await import(`./trackers${route}`)
      Object.values(module).forEach((tracker) => {
        if (typeof tracker !== 'function') return
        tracker(...args)
      })
    } catch (error) {
      console.error(error)
    }
  }

  route('/orders/review')
  route('/orders/modify')
  route('/orders/confirm')
}

const usePHPMixpanelTrackers = () => {
  const { trackEvent } = useUserAnalytics()
  const isLiteRetailer = useSelector(isLiteRetailerSelector)
  const isProBrand = useSelector(isProBrandSelector)
  const isBrand = useSelector(isAccountTypeBrand)
  const isRetailer = useSelector(isAccountTypeRetailer)
  const calledOnce = useRef(false)

  useEffect(() => {
    if (isBrand === false && isRetailer === false) return
    if (!calledOnce.current) {
      addPHPMixpanelTrackers(trackEvent, {
        isLiteRetailer,
        isProBrand,
      })
      calledOnce.current = true
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLiteRetailer, isProBrand, isBrand, isRetailer])
}

export default usePHPMixpanelTrackers
