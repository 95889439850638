import { useEffect } from 'react'

import { useMutation, useQuery } from '@apollo/client'
import isEmpty from 'lodash/isEmpty'
import { useDispatch, useSelector } from 'react-redux'

import { toGlobalId } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { useRenderToasters } from 'hooks/useRenderToasters'
import { getAccountId, isAccountTypeBrand } from 'store/currentUser/selectors'
import { INIT_PROFILE } from 'store/storefront/constants'
import {
  getBrandId,
  getProfileFetched,
  getProfileInfo,
} from 'store/storefront/selectors'

import { ProductLine } from './BrandDetails'
import { formatProfile } from './BrandDetails.formatter'
import {
  BRAND_PRODUCT_LINES,
  UPSERT_BRAND_PROFILE,
} from './BrandDetails.queries'
import { getStorefrontProfileUpsertInput } from 'features/Storefront/StorefrontNavbar/StorefrontNavbar.transformations'
import { BRAND_PROFILE } from 'features/Storefront/StorefrontWidgets.queries.jsx'

const useProductLines = () => {
  const { loading, data, error } = useQuery(BRAND_PRODUCT_LINES, {
    client: atlasClient,
  })
  const productLines = data?.brandProductLines || []
  const selectedProductLines = productLines
    .filter((productLine: ProductLine) => productLine?.active)
    .map((productLine: ProductLine) => productLine?.id)
  return {
    productLines,
    selectedProductLines,
    loading,
    error,
  }
}

const useBrandProfile = (brandId: string, profileFetched: boolean) => {
  const { loading, data, error } = useQuery(BRAND_PROFILE, {
    client: atlasClient,
    variables: { brandIds: [brandId] },
    skip: !brandId || profileFetched,
  })
  const brandProfile = data?.brandProfiles?.edges?.[0]?.node || {}
  return {
    brandProfile,
    loading,
    error,
  }
}

export const useProfileInfo = () => {
  const dispatch = useDispatch()
  let brandId = useSelector(getBrandId)
  const profileFetched = useSelector((state) => getProfileFetched(state))
  const isBrandAccount = useSelector(isAccountTypeBrand)
  const accountId = useSelector(getAccountId)
  const { renderErrorToast } = useRenderToasters()

  if (!brandId) {
    brandId = isBrandAccount ? toGlobalId('Brand', accountId) : ''
  }
  const {
    error: productLinesErrors,
    loading: productLinesLoading,
    selectedProductLines,
    productLines,
  } = useProductLines()
  const {
    error: brandProfileErrors,
    loading: brandProfileLoading,
    brandProfile,
  } = useBrandProfile(brandId, profileFetched)

  useEffect(() => {
    if (!isEmpty(brandProfile)) {
      const profile = formatProfile({
        ...brandProfile,
        productLineIds: selectedProductLines,
      })
      dispatch({ type: INIT_PROFILE, value: profile })
    } else if (productLinesErrors || brandProfileErrors) {
      renderErrorToast({
        description: 'There was a problem loading your profile.',
      })
    }
  }, [brandProfileLoading, brandProfile])

  return {
    loading: brandProfileLoading || productLinesLoading,
    productLines,
  }
}

export const useUpsertStorefrontProfile = () => {
  const profile = useSelector(getProfileInfo)

  const [upsertStorefront] = useMutation(UPSERT_BRAND_PROFILE, {
    client: atlasClient,
  })

  return () => {
    return upsertStorefront({
      variables: {
        input: getStorefrontProfileUpsertInput(profile),
      },
    })
  }
}
