import { createAction } from 'redux-actions'

export const toggleAssortment = createAction('ASSORTMENT/TOGGLE_ASSORTMENT')
export const toggleSelectAssortmentsOnPage = createAction(
  'ASSORTMENT/TOGGLE_ASSORTMENTS_ON_PAGE',
)
export const resetSelectedAssortments = createAction(
  'ASSORTMENT/RESET_SELECTED_ASSORTMENTS',
)
export const setSelectedWorkspaces = createAction(
  'ASSORTMENT/BULK_TOGGLE_WORKSPACES',
)
