import { Icon } from '@joor/design-system'
import styled from 'styled-components'

import NavbarCartDropdown from '../CartDropdown/CartDropdown'
import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import LocalizeDropdownDropdown from '../LocalizeDropdown/LocalizeDropdown'
import MessageMenuOption from '../MessageMenuOption/MessageMenuOption'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import NavbarSettingsDropdown from '../SettingsDropdown/SettingsDropdown'
import Trigger from '../Trigger/Trigger'
import { HamburgerOptionIds } from 'features/NavbarRevamp/navbarRevamp.ids'
import { IHamburgerMenu } from 'features/NavbarRevamp/navbarRevamp.types'

const HamburgerMenuContainer = styled.div`
  display: none;

  .trigger > div {
    padding: 2px 0;
    width: 100%;
  }

  > div > .trigger > div {
    padding: 0;
    width: unset;
  }

  .dropdownMenuGroup > div:not(:first-child) {
    margin: 0;
    padding: 0;
    padding-top: 16px;
    border-top: 1px solid var(--color-primary-400);
  }

  @media only screen and (max-width: 1024px) {
    display: flex;

    .dropdownMenuGroup {
      min-width: 140px;
      padding: 16px 12px;
    }

    & > div > div:nth-child(2) {
      overflow: unset;
    }
  }
`

const MessageOptionWrapper = styled.div`
  a {
    display: flex;
  }
`

const HamburgerDropdown = ({
  isLiteBrandAccount,
}: IHamburgerMenu): JSX.Element => (
  <HamburgerMenuContainer
    id={HamburgerOptionIds.HamburgerTrigger}
    data-testid={HamburgerOptionIds.HamburgerTrigger}
  >
    <NavbarDropdown
      rightPosition
      trigger={
        <Trigger
          label={<Icon iconName="menu" variant="DEFAULT" type="white" plain />}
          id={HamburgerOptionIds.HamburgerTrigger}
          withArrow={false}
        />
      }
    >
      <DropdownMenuGroup>
        <NavbarSettingsDropdown isInsideDropdown />
        {!isLiteBrandAccount && (
          <MessageOptionWrapper>
            <MessageMenuOption isInsideDropdown />
          </MessageOptionWrapper>
        )}
        {!isLiteBrandAccount && <NavbarCartDropdown isInsideDropdown />}
        <LocalizeDropdownDropdown isInsideDropdown />
      </DropdownMenuGroup>
    </NavbarDropdown>
  </HamburgerMenuContainer>
)

export default HamburgerDropdown
