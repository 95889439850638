import { Component } from 'react'

import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { matchPath } from 'react-router-dom'

import {
  PASSPORT_PATHS,
  PUBLIC_PRODUCT_CATALOG,
  SHOWROOM_PUBLIC_COLLECTION_VIEW,
  STOREFRONT_PATHS,
} from 'routes/paths'

import NavbarRevamp from '../features/NavbarRevamp/NavbarRevamp.component'
import { isProductsPageContent } from './LegacyPage/LegacyPage.utils'
import PublicNavbar from 'containers/NavbarPublic/PublicNavbar.container'
import EditionNavbar from 'features/Storefront/EditionNavbar'

export class PageNavbar extends Component {
  getNavBar = () => {
    const { location } = this.props
    const isPassport = this.matchPassportPaths(location.pathname)
    const isStorefront = this.matchStorefrontPaths(location.pathname)
    const isPublicShowroom = this.matchShowroomPaths(location.pathname)
    const isPublicCatalog = this.matchPublicCatalogPath(location.pathname)

    if (isProductsPageContent()) return null

    if (
      isStorefront &&
      this.isStorefontOwner(location.pathname, isStorefront)
    ) {
      return <EditionNavbar />
    }

    if (isPublicShowroom || isPublicCatalog) {
      return <PublicNavbar />
    }

    if (isPassport) return null

    return <NavbarRevamp />
  }

  matchPassportPaths = (pathname) =>
    Boolean(
      matchPath(pathname, {
        path: PASSPORT_PATHS,
        exact: true,
      }),
    )

  matchStorefrontPaths = (pathname) =>
    Boolean(
      matchPath(pathname, {
        path: STOREFRONT_PATHS,
        exact: true,
      }),
    )

  matchShowroomPaths = (pathname) =>
    Boolean(
      matchPath(pathname, {
        path: SHOWROOM_PUBLIC_COLLECTION_VIEW,
        exact: true,
      }),
    )

  matchPublicCatalogPath = (pathname) =>
    Boolean(
      matchPath(pathname, {
        path: PUBLIC_PRODUCT_CATALOG,
        exact: true,
      }),
    )

  isStorefrontTextSettings = (pathname) => pathname === STOREFRONT_PATHS[1]
  isStorefrontProfileInfo = (pathname) => pathname === STOREFRONT_PATHS[2]

  isStorefontOwner = (pathname, isStorefront) => {
    const storefrontPathParams = pathname?.split('/')
    const storefrontId =
      storefrontPathParams.length > 4
        ? storefrontPathParams?.[storefrontPathParams?.length - 2]
        : storefrontPathParams?.[storefrontPathParams?.length - 1]
    return (
      isStorefront &&
      this.props.isBrandAccount &&
      this.props.accountId === parseInt(storefrontId, 10)
    )
  }

  render() {
    return <>{this.getNavBar()}</>
  }
}

PageNavbar.propTypes = {
  location: PropTypes.object.isRequired,
}

export default withRouter(PageNavbar)
