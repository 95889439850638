import { Button, LegacyModal } from '@joor/design-system'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { useRenderToasters } from 'hooks/useRenderToasters'
import { getAccountId, getPanelId } from 'store/storefront/selectors'

import { STOREFRONT } from 'routes/paths'

import { DeleteModalProps } from '../modals.types'
import { useDeleteStorefront } from 'features/Storefront/EditionNavbar/Sections/Pages/Pages.hooks'

export const DELETE_ID = 'storefront_delete_page_action'

const Container = styled.div`
  margin-top: 26px;
  width: 560px;
`

const DeleteModal = ({
  id,
  isOpen,
  isHome,
  setModalIsOpen,
  loadingNavigation,
  refetchStorefrontNavigation,
}: DeleteModalProps): JSX.Element => {
  const { renderErrorToast, renderSuccessToast } = useRenderToasters()

  const history = useHistory()
  const panelId = useSelector(getPanelId)
  const brandId = useSelector(getAccountId)
  const { loading: deleteLoading, deleteStorefronts } = useDeleteStorefront()

  const handleDeletePage = async () => {
    if (isHome) {
      return renderErrorToast({
        description: 'Your homepage cannot be deleted.',
      })
    }
    try {
      const { data, errors } = await deleteStorefronts([id])
      if (!data || errors) {
        renderErrorToast({
          description: 'There was an error deleting your page',
        })
      } else {
        await refetchStorefrontNavigation()
        renderSuccessToast({ description: 'Page deleted.' })
        if (panelId === id)
          history.push(
            generatePath(STOREFRONT, {
              accountId: brandId,
            }),
          )
        setModalIsOpen('')
      }
    } catch {
      renderErrorToast({ description: 'There was an error deleting your page' })
    }
  }

  return (
    <LegacyModal isOpen={isOpen} onClose={(): void => setModalIsOpen('')}>
      <LegacyModal.Title>Delete Page</LegacyModal.Title>
      <LegacyModal.Content>
        <Container>
          Are you sure you want to delete this page? You will not be able to
          recover it after it is deleted.
        </Container>
      </LegacyModal.Content>
      <LegacyModal.Actions>
        <LegacyModal.CloseButton>Cancel</LegacyModal.CloseButton>
        <Button
          onClick={handleDeletePage}
          disabled={deleteLoading || loadingNavigation}
          data-testid={DELETE_ID}
        >
          Delete Page
        </Button>
      </LegacyModal.Actions>
    </LegacyModal>
  )
}

export default DeleteModal
