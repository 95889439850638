import { handleActions } from 'redux-actions'

import {
  initialFilterReducerState,
  unselectedFilterReducerState,
} from 'utils/filters/utils'

import { LOADED, LOADING } from 'store/reporting/constants'

import { reportingFilters } from 'containers/Reporting/FilterPanel/filters'

export const defaultState = {
  loaded: {},
  rowData: {},
  selectedFilters: {
    ...initialFilterReducerState(reportingFilters),
  },
  appliedFilters: {
    ...initialFilterReducerState(reportingFilters),
  },
  shouldSaveFilters: false,
  usdRates: {},
  currencyToConvert: '',
}

const reducer = handleActions(
  {
    'REPORTING/SET_LOADED': (state, action) => ({
      ...state,
      loaded: { ...state.loaded, [action.payload]: LOADED },
    }),
    'REPORTING/SET_LOADING': (state, action) => ({
      ...state,
      loaded: { ...state.loaded, [action.payload]: LOADING },
    }),
    'REPORTING/SET_ROW_DATA': (state, action) => ({
      ...state,
      rowData: { ...state.rowData, ...action.payload },
    }),
    'REPORTING/SET_UNLOADED': (state) => ({
      ...state,
      loaded: {},
      rowData: {},
    }),
    'REPORTING/SET_USD_RATES': (state, action) => ({
      ...state,
      usdRates: { ...action.payload },
    }),
    'REPORTING/SET_CURRENCY_TO_CONVERT': (state, action) => ({
      ...state,
      currencyToConvert: action.payload,
    }),
    'REPORTING/TOGGLE_SELECT_FILTER': (state, { payload }) => {
      const { filterKey } = payload
      const filterSettings = reportingFilters[filterKey]
      const { reducerHandler } = filterSettings
      return {
        ...state,
        selectedFilters: reducerHandler(state.selectedFilters, { ...payload }),
      }
    },
    'REPORTING/TOGGLE_DESELECT_ALL_FILTERS': (state) => ({
      ...state,
      selectedFilters: {
        ...state.selectedFilters,
        ...unselectedFilterReducerState(reportingFilters),
      },
    }),
    'REPORTING/APPLY_SELECTED_FILTERS': (state) => ({
      ...state,
      appliedFilters: { ...state.selectedFilters },
    }),
    'REPORTING/SHOULD_SAVE_FILTERS': (state, action) => ({
      ...state,
      shouldSaveFilters: action.payload,
    }),
  },
  defaultState,
)

export default reducer
