import get from 'lodash/get'

const initialState = {
  featureFlag: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHOWROOM_FEATURE_FLAG': {
      const featureFlag = get(action, 'featureFlag', false)

      return {
        ...state,
        featureFlag,
      }
    }
    default:
      return state
  }
}
