import find from 'lodash/find'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import { createSelector } from 'reselect'

import * as configuration from 'modals/Shop/TablesConfiguration'

const productDetailModalSelector = (state) => state.productDetailModal

export const getCellValueFromTable = (state) => ({
  tableId,
  sectionId,
  rowId,
  columnId,
}) =>
  configuration.getCellValueFromTable({
    table: state.productDetailModal[tableId],
    sectionId,
    rowId,
    columnId,
  })

export const isValidQuantity = (state) => ({
  tableId,
  sectionId,
  rowId,
  columnId,
  variantOrderMinimum,
  productOrderMinimum,
}) => {
  const inputValue = configuration.getCellValueFromTable({
    table: state.productDetailModal[tableId],
    sectionId,
    rowId,
    columnId,
  })

  if (!isEmpty(inputValue) || inputValue > 0) {
    const sectionRowsIds = Object.keys(
      get(state.productDetailModal, `[${tableId}][${sectionId}]`, {}),
    )
    const currentSectionTotal = reduce(
      sectionRowsIds,
      (acc, sectionRowId) => {
        const quantity = configuration.getCellValueFromTable({
          table: state.productDetailModal[tableId],
          sectionId,
          rowId: sectionRowId,
          columnId: configuration.QUANTITY_COLUMN_ID,
        })
        return quantity ? acc + quantity : acc
      },
      0,
    )
    const totalQuantity = configuration.getCellValueFromTable({
      table: state.productDetailModal[tableId],
      sectionId: configuration.TOTAL_SECTION_ID,
      rowId: configuration.TOTAL_ROW_ID,
      columnId: configuration.QUANTITY_COLUMN_ID,
    })
    return (
      totalQuantity >= productOrderMinimum &&
      currentSectionTotal >= variantOrderMinimum
    )
  }
  return !inputValue || inputValue === 0
}

export const getSelectedTabId = (state) =>
  state.productDetailModal.selectedTabId

export const hasValuesInTable = createSelector(
  productDetailModalSelector,
  (productDetailModal) => {
    const totalQuantityInTab = get(
      productDetailModal,
      `[${productDetailModal.selectedTabId}][${configuration.TOTAL_SECTION_ID}]${configuration.TOTAL_ROW_ID}]${configuration.QUANTITY_COLUMN_ID}]`,
      0,
    )
    return totalQuantityInTab > 0
  },
)

export const hasNotedVariants = createSelector(
  productDetailModalSelector,
  (productDetailModal) => {
    const { VARIANT_ROW_ID, FIRST_COLUMN_ID } = configuration
    const selectedTabTable = get(
      productDetailModal,
      `[${productDetailModal.selectedTabId}]`,
      {},
    )
    return !!find(selectedTabTable, (rows) =>
      get(rows, `[${VARIANT_ROW_ID}][${FIRST_COLUMN_ID}]`, false),
    )
  },
)

export const getBulkQuantities = (state) => () => {
  const {
    BULK_TABLE_ID,
    ROWS_EXCLUDED_FROM_MUTATION,
    VARIANT_ROW_ID,
    COLUMNS_EXCLUDED_FROM_MUTATION,
    FIRST_COLUMN_ID,
    QUANTITY_COLUMN_ID,
  } = configuration
  const bulkTable = state.productDetailModal[BULK_TABLE_ID]
  const bulkQuantities = []
  forEach(bulkTable, (rows, sectionId) => {
    const filteredRows = omitBy(
      rows,
      (_, key) => key in ROWS_EXCLUDED_FROM_MUTATION,
    )
    const isNotedVariant = get(
      rows,
      `[${VARIANT_ROW_ID}][${FIRST_COLUMN_ID}]`,
      false,
    )
    const totalSectionQuantity = reduce(
      rows,
      (acc, row) => acc + get(row, `[${QUANTITY_COLUMN_ID}]`, 0),
      0,
    )
    if (!isNotedVariant || totalSectionQuantity > 0) {
      forEach(filteredRows, (columns, rowId) => {
        const filteredColumns = omitBy(
          columns,
          (columnValue, key) =>
            key in COLUMNS_EXCLUDED_FROM_MUTATION ||
            !columnValue ||
            columnValue <= 0,
        )
        forEach(filteredColumns, (value) => {
          bulkQuantities.push({
            variantValueId: sectionId,
            ...(rowId !== VARIANT_ROW_ID && { doorId: rowId }),
            quantity: value,
          })
        })
      })
    } else {
      forEach(filteredRows, (_, rowId) => {
        const bulkParams = {
          variantValueId: sectionId,
          quantity: 0,
        }
        if (size(filteredRows) > 1 && rowId !== VARIANT_ROW_ID) {
          bulkQuantities.push({
            ...bulkParams,
            doorId: rowId,
          })
        } else if (size(filteredRows) <= 1) {
          bulkQuantities.push(bulkParams)
        }
      })
    }
  })
  return bulkQuantities
}

export const getSkuQuantities = (state) => (columnVariantValues) => {
  const {
    SIZED_TABLE_ID,
    ROWS_EXCLUDED_FROM_MUTATION,
    VARIANT_ROW_ID,
    COLUMNS_EXCLUDED_FROM_MUTATION,
    FIRST_COLUMN_ID,
    QUANTITY_COLUMN_ID,
  } = configuration
  const sizeTable = state.productDetailModal[SIZED_TABLE_ID]
  const skuQuantities = []
  forEach(sizeTable, (rows, sectionId) => {
    const filteredRows = omitBy(
      rows,
      (_, key) => key in ROWS_EXCLUDED_FROM_MUTATION,
    )
    const isNotedVariant = get(
      rows,
      `[${VARIANT_ROW_ID}][${FIRST_COLUMN_ID}]`,
      false,
    )
    const totalSectionQuantity = reduce(
      rows,
      (acc, row) => acc + get(row, `[${QUANTITY_COLUMN_ID}]`, 0),
      0,
    )
    if (!isNotedVariant || totalSectionQuantity > 0) {
      forEach(filteredRows, (columns, rowId) => {
        const filteredColumns = omitBy(columns, (columnValue, columnId) => {
          const columnVariantValue = find(
            columnVariantValues,
            (variantValue) => variantValue.id === columnId,
          )
          return (
            columnId in COLUMNS_EXCLUDED_FROM_MUTATION ||
            columnValue <= 0 ||
            !columnVariantValue
          )
        })
        forEach(filteredColumns, (value, columnId) => {
          skuQuantities.push({
            variantRowValueId: sectionId,
            variantColumnValueId: columnId,
            ...(rowId !== VARIANT_ROW_ID && { doorId: rowId }),
            quantity: value,
          })
        })
      })
    } else {
      forEach(filteredRows, (_, rowId) => {
        forEach(columnVariantValues, (variantValue) => {
          const skuParams = {
            variantRowValueId: sectionId,
            variantColumnValueId: variantValue.id,
            quantity: 0,
          }
          if (size(filteredRows) > 1 && rowId !== VARIANT_ROW_ID) {
            skuQuantities.push({
              ...skuParams,
              doorId: rowId,
            })
          } else if (size(filteredRows) <= 1) {
            skuQuantities.push(skuParams)
          }
        })
      })
    }
  })
  return skuQuantities
}

export const getCasepackQuantities = (state) => (casepacks) => {
  const {
    SIZED_TABLE_ID,
    ROWS_EXCLUDED_FROM_MUTATION,
    VARIANT_ROW_ID,
    COLUMNS_EXCLUDED_FROM_MUTATION,
    FIRST_COLUMN_ID,
    QUANTITY_COLUMN_ID,
  } = configuration
  const sizeTable = state.productDetailModal[SIZED_TABLE_ID]
  const casepacksQuantities = []
  forEach(sizeTable, (rows, sectionId) => {
    const isNotedVariant = get(
      rows,
      `[${VARIANT_ROW_ID}][${FIRST_COLUMN_ID}]`,
      false,
    )
    const filteredRows = omitBy(
      rows,
      (_, key) => key in ROWS_EXCLUDED_FROM_MUTATION,
    )
    const totalSectionQuantity = reduce(
      rows,
      (acc, row) => acc + get(row, `[${QUANTITY_COLUMN_ID}]`, 0),
      0,
    )
    forEach(filteredRows, (columns, rowId) => {
      const filteredColumns = omitBy(columns, (columnValue, columnId) => {
        const casepack = get(casepacks, columnId)
        return columnId in COLUMNS_EXCLUDED_FROM_MUTATION || !casepack
      })
      forEach(filteredColumns, (value, columnId) => {
        const caseParams = {
          variantValueId: sectionId,
          casepackId: columnId,
          quantity: 0,
        }
        if (isNotedVariant && totalSectionQuantity === 0) {
          if (size(filteredRows) > 1 && rowId !== VARIANT_ROW_ID) {
            casepacksQuantities.push({
              ...caseParams,
              doorId: rowId,
            })
          } else if (size(filteredRows) <= 1) {
            casepacksQuantities.push(caseParams)
          }
        } else if (value) {
          casepacksQuantities.push({
            ...caseParams,
            ...(rowId !== VARIANT_ROW_ID && { doorId: rowId }),
            quantity: value,
          })
        }
      })
    })
  })
  return casepacksQuantities
}
export const isRowInitialized = (state) => ({ tableId, rowId }) =>
  get(state.productDetailModal.tablesInitialization, `[${tableId}][${rowId}]`)

export const getWarehouseDeliveryDate = (state) =>
  state.productDetailModal.warehouseDeliveryDate
export const getWarehouseId = (state) => state.productDetailModal.warehouseId
