import { handleActions } from 'redux-actions'

import { initialFilterReducerState } from 'utils/filters/utils'

import withStorage from 'store/utils/storage/withStorage'

import {
  TOGGLE_SELECT_FILTER,
  UNSELECT_ALL_FILTERS,
  actionsPrefix,
} from './constants'
import { orderFilters } from 'containers/OrderListFilters/filters'

export const defaultState = {
  selectedFilters: {
    status: initialFilterReducerState(orderFilters).status,
  },
}

const reducer = handleActions(
  {
    // It is possible to apply multiple filters at the same time in this case
    [TOGGLE_SELECT_FILTER]: (state, { payload }) => ({
      ...state,
      selectedFilters: payload.reduce((result, { id, filterDataKey }) => {
        if (!id) return result
        const filterSettings = orderFilters[filterDataKey]
        const { reducerHandler } = filterSettings
        return reducerHandler(result, { id })
      }, state.selectedFilters),
    }),
    [UNSELECT_ALL_FILTERS]: (state) => ({
      ...state,
      selectedFilters: {
        status: [],
      },
    }),
  },
  defaultState,
)

export default withStorage(reducer, {
  version: 0,
  key: actionsPrefix,
})
