import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { isProductsPageContent } from 'pages/LegacyPage/LegacyPage.utils'

import EarlyAccessWidget from 'features/EarlyAccessWidget/EarlyAccessWidget'
import { useAvailableFeatures } from 'features/EarlyAccessWidget/earlyAccessWidget.hooks'
import { WidgetIds } from 'features/EarlyAccessWidget/earlyAccessWidget.ids'
import { Feature } from 'features/EarlyAccessWidget/earlyAccessWidget.types'

const FloatingContainer = styled.div`
  position: absolute;
  top: 0;
  right: 16px;

  &.early-access-php-page {
    top: -65px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 16px;

  &.early-access-react-page {
    margin-top: -12px;
  }
`

const getMatchingConfig = (
  feature: Feature | undefined,
  pathname: string,
  fullRoute: string,
) => {
  if (!feature) {
    return undefined
  }

  return feature.widgetConfigs.find((config) =>
    new RegExp(config.urlRegexp).test(
      config.shouldCheckFullRoute ? fullRoute : pathname,
    ),
  )
}

const EarlyAccessWidgetContainer = () => {
  const location = useLocation()
  const flags = useFlags()
  const availableFeatures = useAvailableFeatures()

  if (isProductsPageContent()) return null

  if (!availableFeatures.length) {
    return null
  }

  const fullRoute = `${location.pathname}${location.search}`

  const matchingFeature = availableFeatures.find((feature) =>
    getMatchingConfig(feature, location.pathname, fullRoute),
  )
  const matchingConfig = getMatchingConfig(
    matchingFeature,
    location.pathname,
    fullRoute,
  )

  if (!matchingConfig) {
    return null
  }

  if (matchingConfig.renderConditions) {
    const matchesConditions = matchingConfig.renderConditions.every(
      (condition) => flags[condition],
    )

    if (!matchesConditions) return null
  }

  const className = matchingConfig.isPhpPage
    ? 'early-access-php-page'
    : 'early-access-react-page'

  if (matchingConfig.pushContent) {
    return (
      <Container data-testid={WidgetIds.PageContainer} className={className}>
        <EarlyAccessWidget />
      </Container>
    )
  } else {
    return (
      <FloatingContainer
        data-testid={WidgetIds.PageContainer}
        className={className}
      >
        <EarlyAccessWidget />
      </FloatingContainer>
    )
  }
}

export default EarlyAccessWidgetContainer
