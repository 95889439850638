import { createAction } from 'redux-actions'

export const toggleSelectFilter = createAction(
  'ASSORTMENT_LIST_FILTER/TOGGLE_SELECT_FILTER',
)
export const toggleDeselectAllFilters = createAction(
  'ASSORTMENT_LIST_FILTER/TOGGLE_DESELECT_ALL_FILTERS',
)
export const toggleBrandNoteFilter = createAction(
  'ASSORTMENT_LIST_FILTER/TOGGLE_BRAND_NOTE_FILTER',
)
export const setArchivedFilter = createAction(
  'ASSORTMENT_LIST_FILTER/SET_ARCHIVED_FILTER',
)
export const setBrandNoteFilter = createAction(
  'ASSORTMENT_LIST_FILTER/SET_BRAND_NOTE_FILTER',
)
export const applySelectedFilters = createAction(
  'ASSORTMENT_LIST_FILTER/APPLY_SELECTED_FILTERS',
)
export const applySearchFilter = createAction(
  'ASSORTMENT_LIST_FILTER/APPLY_SEARCH_FILTER',
)
