import get from 'lodash/get'
import reduce from 'lodash/reduce'
import size from 'lodash/size'

import { translateText } from 'utils/sdks/localize'

import Filter from './baseFilter'

/*
 * ArrayOfIdsFilter
 * It is a type of filter that allows multiple selection e.g.
 * collections or order events. The information here is the ids
 * of the chosen items to filter with.
 */
export default class ArrayOfIdsFilter extends Filter {
  constructor({ defaultState, ...rest }) {
    super(rest)
    if (defaultState) {
      this.defaultState = defaultState
    }
  }

  getSelectedOptions = (selectedFilters) =>
    reduce(
      selectedFilters[this.filterKey],
      (acc, item) => ({ ...acc, [item.id]: true }),
      {},
    )

  isUnselected = (filterItem) => filterItem && filterItem.length === 0

  toGraphQLVariable = (graphQLFilters, { value }) => {
    if (this.isUnselected(value)) {
      return graphQLFilters
    }
    return {
      ...graphQLFilters,
      [this.filterKey]: value.map((filterInfo) => filterInfo.id),
    }
  }

  reducerHandler = (selectedFilters, filterInfo) => {
    const { id } = filterInfo
    const selectedFilterValues = selectedFilters[this.filterKey] || []
    // Add if new, else remove
    const isAlreadyThere = selectedFilterValues.some(
      (filterOption) => filterOption.id === id,
    )
    return {
      ...selectedFilters,
      [this.filterKey]: isAlreadyThere
        ? selectedFilterValues.filter((filterOption) => filterOption.id !== id)
        : [...selectedFilterValues, filterInfo],
    }
  }

  addToActiveFilters = (currentState, filterState) => {
    // passes without modifying
    if (this.isUnselected(filterState)) {
      return currentState
    }
    return [...currentState, ...filterState]
  }

  getSelectedFiltersPillFormatted = ({ selectedFilters, onActionClick }) => {
    const selectedFilterOptions = selectedFilters[this.filterKey]
    return selectedFilterOptions.map((selectedFilterOption) => ({
      id: get(selectedFilterOption, 'id', ''),
      name: (
        <span>
          {translateText(this.label)}:{` `}
          <var>{get(selectedFilterOption, 'text', '')}</var>
        </span>
      ),
      onClick: onActionClick,
    }))
  }

  // could recieve value from selectedFilters or appliedFilters
  getCountFilters = (filterState) =>
    this.isUnselected(filterState) ? 0 : size(filterState)

  toFilterValue = (filterKey = '', { key = '', text = '' }) => ({
    filterKey,
    id: key,
    value: key,
    text,
  })
}
