import { PureComponent } from 'react'

import classNames from 'classnames'
import times from 'lodash/times'
import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'

import styles from './PhotoBreadcrumb.less'

class PhotoBreadcrumb extends PureComponent {
  state = {
    currentlyShownImage: this.props.selectedImageIndex,
    initialPosition: 0,
    endPosition: this.props.imagesInView,
  }

  componentDidUpdate(prevProps) {
    const { selectedImageIndex } = this.props

    if (selectedImageIndex !== prevProps.selectedImageIndex) {
      this.updateShownImage()
    }
  }

  getShownImages() {
    const { initialPosition, endPosition } = this.state
    const { imagePaths, showArrows, imagesInView } = this.props

    if (!showArrows) {
      return imagePaths.slice(0, imagesInView)
    }

    return imagePaths.slice(initialPosition, endPosition)
  }

  getNextImage() {
    const { showArrows, imagePaths, imagesInView } = this.props
    const { currentlyShownImage, endPosition } = this.state

    let nextPhoto = currentlyShownImage + 1
    if (!showArrows && nextPhoto >= this.getShownImages().length) {
      nextPhoto = 0
    }
    if (showArrows && nextPhoto >= imagePaths.length) {
      nextPhoto = 0
      this.setState({
        initialPosition: 0,
        endPosition: imagesInView,
      })
    }
    if (showArrows && nextPhoto >= endPosition) {
      this.setState({
        initialPosition: nextPhoto,
        endPosition: nextPhoto + imagesInView,
      })
    }
    this.setState({ currentlyShownImage: nextPhoto })
  }

  updateShownImage() {
    const { imagesInView, selectedImageIndex } = this.props
    const numOfSlice =
      Math.trunc(selectedImageIndex / imagesInView) * imagesInView

    this.setState({
      initialPosition: numOfSlice,
      endPosition: numOfSlice + imagesInView,
      currentlyShownImage: selectedImageIndex,
    })
  }

  handleClick(position) {
    const { onClick } = this.props
    onClick(position)
    this.setState({ currentlyShownImage: position })
  }

  renderNoPhotos() {
    return (
      <div className={classNames(styles.PhotoBreadcrumb, this.props.className)}>
        <Icon
          name="noImage"
          className={classNames(styles.noImage, 'no-image')}
        />
      </div>
    )
  }

  render() {
    const { initialPosition, endPosition, currentlyShownImage } = this.state
    const { imagePaths, imagesInView, className, showArrows } = this.props

    const numTotalImages = imagePaths.length
    const numDots = this.getShownImages().length
    const activeStyle = classNames(styles.active, styles.dot)
    const isArrowShown = showArrows && numTotalImages > numDots
    const showLeftArrow = isArrowShown && initialPosition > 0
    const showRightArrow = isArrowShown && numTotalImages > endPosition

    if (numDots === 0) {
      return this.renderNoPhotos()
    }

    return (
      <div className={classNames(styles.PhotoBreadcrumb, className)}>
        <img
          src={imagePaths[currentlyShownImage]}
          alt="Breadcrumb"
          onClick={() => this.getNextImage()}
          fetchpriority="high"
        />

        <div
          className={styles.breadcrumbContainer}
          data-testid="breadcrumbContainer"
        >
          {showLeftArrow && (
            <Icon
              id="leftArrow"
              name="dropup"
              className={styles.arrow}
              onClick={() => {
                this.setState({
                  initialPosition: initialPosition - imagesInView,
                  endPosition: initialPosition,
                })
              }}
            />
          )}
          {times(numDots, (i) => (
            <div
              onClick={(e) => {
                this.handleClick(parseInt(e.target.dataset.position, 10))
              }}
              data-position={i + initialPosition}
              key={i}
              className={
                i + initialPosition === currentlyShownImage
                  ? activeStyle
                  : styles.dot
              }
            />
          ))}
          {showRightArrow && (
            <Icon
              id="rightArrow"
              name="dropup"
              className={classNames(styles.arrow, styles.rightArrow)}
              onClick={() => {
                this.setState({
                  initialPosition: endPosition,
                  endPosition: endPosition + numDots,
                })
              }}
            />
          )}
        </div>
      </div>
    )
  }
}

PhotoBreadcrumb.propTypes = {
  imagePaths: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  imagesInView: PropTypes.number,
  showArrows: PropTypes.bool,
  selectedImageIndex: PropTypes.number,
  onClick: PropTypes.func,
}

PhotoBreadcrumb.defaultProps = {
  className: '',
  imagePaths: [],
  imagesInView: 10,
  showArrows: false,
  selectedImageIndex: 0,
  onClick: () => {},
}

export default PhotoBreadcrumb
