import { handleActions } from 'redux-actions'

import {
  CREDIT_CARD_TAB,
  SET_SELECTED_TAB,
  SET_SHOULD_USE_CONNECTED_ACCOUNT,
} from './constants'

export const defaultState = {
  shouldUseConnectedAccount: false,
  selectedTab: CREDIT_CARD_TAB,
}

const reducer = handleActions(
  {
    [SET_SHOULD_USE_CONNECTED_ACCOUNT]: (
      state,
      { payload: shouldUseConnectedAccount },
    ) => ({
      ...state,
      shouldUseConnectedAccount,
    }),
    [SET_SELECTED_TAB]: (state, { payload: selectedTab }) => ({
      ...state,
      selectedTab,
    }),
  },
  defaultState,
)

export default reducer
