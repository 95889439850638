import { createContext, useContext } from 'react'

const BaseProductCardContext = createContext({
  handleSwatchSelection: () => {},
  selectedColorId: '',
})

export const useProductCardContext = () => {
  return useContext(BaseProductCardContext)
}

export default BaseProductCardContext
