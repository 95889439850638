import { FC } from 'react'

import { Icon } from '@joor/design-system'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { isAccountTypeRetailer } from 'store/currentUser/selectors'

import Carousel from './Carousel'

type ProfileImagesViewModeProps = {
  imageUrls?: string[]
}
const StyledEmptyCarousel = styled.div`
  margin: 0 32px;
  height: 458px;
  background-color: var(--color-primary-100);
  color: var(--color-primary-100);
  border: 1px solid var(--color-primary-400);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(100% - 96px);
  gap: 16px;
  @media only screen and (min-width: 1200px) {
    margin: 0 100px 0 32px;
  }
`
const StyledPlaceholder = styled(Icon)`
  > svg > g {
    fill: var(--color-primary-400);
  }
`
const StyledMessage = styled.div`
  width: 50%;
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-mediumlarge);
  line-height: 32px;
  text-align: center;
  color: var(--color-primary-400);
`
const ProfileImagesViewMode: FC<ProfileImagesViewModeProps> = ({
  imageUrls,
}) => {
  const isRetailerAccount = useSelector(isAccountTypeRetailer)

  return (
    <>
      {Boolean(imageUrls?.length) ? (
        <Carousel testId="carousel" imageUrls={imageUrls ?? []} />
      ) : (
        <StyledEmptyCarousel data-testid="empty-carousel">
          <StyledPlaceholder iconName="image" data-testid="placeholder" />
          {isRetailerAccount && (
            <StyledMessage data-testid="empty-message">
              Edit page to add <br /> images of your stores
            </StyledMessage>
          )}
        </StyledEmptyCarousel>
      )}
    </>
  )
}

export default ProfileImagesViewMode
