import PropTypes from 'prop-types'

import { getTextFromSelectedDropdownOption } from 'utils/formatters'
import { getDisplayName, getUrlParam } from 'utils/mixpanel'

export const withAnalytics = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { mixpanel, location, selectedRetailerId, orderTypes } = props

    const getExcelOrderModalImportExcelParams = ({
      retailersOptions,
      warehouseOptions,
      warehouseId,
      orderTypeId,
      poNumber,
    }) => ({
      'Retailer Search': getTextFromSelectedDropdownOption(
        retailersOptions,
        selectedRetailerId,
      ),
      'Warehouse Name':
        getTextFromSelectedDropdownOption(warehouseOptions, warehouseId) ||
        'No warehouse selected',
      'Order Type':
        getTextFromSelectedDropdownOption(orderTypes, orderTypeId) ||
        'No order type selected',
      'PO Number': poNumber,
      ...getUrlParam(location),
    })

    const trackers = {
      trackExcelOrderModalImportExcel: (config) =>
        mixpanel.track(
          'Orders List_Excel Order Uploaded',
          getExcelOrderModalImportExcelParams(config),
        ),
    }

    return <WrappedComponent {...props} {...trackers} />
  }

  Wrapper.propTypes = {
    mixpanel: PropTypes.object,
    location: PropTypes.object.isRequired,
  }
  Wrapper.defaultProps = {
    mixpanel: { track: () => {} },
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return Wrapper
}

export default withAnalytics
