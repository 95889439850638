import { connect } from 'react-redux'

import FileDownloadHandler from './FileDownloadHandler.component'

const mapStateToProps = ({ fileDownloads }) => ({
  downloadTaskIds: fileDownloads.downloadTaskIds,
})

const withRedux = connect(mapStateToProps)

export default withRedux(FileDownloadHandler)
