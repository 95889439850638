import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import Grid from 'components/Core/Grid/Grid'
import TruncateWithTooltip from 'components/Core/TruncateWithTooltip/TruncateWithTooltip'

import styles from './TabContainer.less'

export const MOVE_LEFT = 'left'
export const MOVE_RIGHT = 'right'

class TabContainer extends Component {
  state = {
    selectedPanel: this.props.initialValue,
    sliderPosition: 0,
    panelWidth: null,
  }

  componentDidMount() {
    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this.panel)
    if (node) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        panelWidth: node.offsetWidth,
      })
    }
  }

  onSelectPanel = (selectedPanel) => {
    this.setState({
      selectedPanel,
    })
  }

  onSlide = (increment) => {
    this.setState(({ sliderPosition: previousSliderPosition }) => ({
      sliderPosition: previousSliderPosition + increment,
    }))
  }

  panelRefHandler = (panel) => {
    this.panel = panel
  }

  render() {
    const { render } = this.props
    const { selectedPanel, sliderPosition, panelWidth } = this.state
    return (
      <Grid>
        {render(
          selectedPanel,
          this.onSelectPanel,
          this.onSlide,
          sliderPosition,
          this.panelRefHandler,
          panelWidth,
        )}
      </Grid>
    )
  }
}

TabContainer.propTypes = {
  render: PropTypes.func,
  initialValue: PropTypes.number,
}

TabContainer.defaultProps = {
  render: () => {},
  initialValue: 0,
}

const Panel = ({
  content,
  onClick,
  highlighted,
  underlined,
  refHandler,
  noTranslate,
  panelClassName,
  borderless,
}) => {
  const calculatedClassnames = classNames(
    {
      [styles.highlighted]: highlighted,
      [styles.underlined]: underlined && highlighted,
      [styles.borderless]: highlighted && borderless,
    },
    styles.Panel,
  )

  let contentWrapper = (wrappedContent) => (
    <div className={calculatedClassnames}>{wrappedContent}</div>
  )
  // If we pass string rather than component, we assume we will need a tooltip
  if (!content.type) {
    contentWrapper = (wrappedContent) => (
      <TruncateWithTooltip
        lines={1}
        className={calculatedClassnames}
        noTranslate={noTranslate}
      >
        {wrappedContent}
      </TruncateWithTooltip>
    )
  }
  return (
    <Grid.Col
      sm={2}
      md={2}
      lg={2}
      className={classNames(styles.PanelCol, panelClassName)}
    >
      <div onClick={onClick} ref={refHandler}>
        {contentWrapper(content)}
      </div>
    </Grid.Col>
  )
}

Panel.propTypes = {
  content: PropTypes.node,
  onClick: PropTypes.func,
  highlighted: PropTypes.bool,
  underlined: PropTypes.bool,
  refHandler: PropTypes.func,
  noTranslate: PropTypes.bool,
  panelClassName: PropTypes.string,
  borderless: PropTypes.bool,
}
Panel.defaultProps = {
  content: '',
  onClick: () => {},
  highlighted: false,
  underlined: false,
  refHandler: () => {},
  noTranslate: false,
  panelClassName: '',
  borderless: false,
}

const ScreenContainer = ({ children, className, parentClassName }) => (
  <Grid.Row>
    <Grid.Col className={classNames(styles.ScreenCol, parentClassName)}>
      <div className={classNames(styles.ScreenContainer, className)}>
        {children}
      </div>
    </Grid.Col>
  </Grid.Row>
)

ScreenContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  parentClassName: PropTypes.string,
}

ScreenContainer.defaultProps = {
  children: null,
  className: '',
  parentClassName: '',
}

TabContainer.Panel = Panel
TabContainer.ScreenContainer = ScreenContainer

export default TabContainer
