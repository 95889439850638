import classNames from 'classnames'
import PropTypes from 'prop-types'

import Modal from 'components/Core/Modal'
import Table from 'components/Core/Table/Table'

import styles from './ExcelSummaryModal.less'
import messages from './ExcelSummaryModal.messages'

const UnsuccessfulImportRow = ({ filename, error }) => (
  <Table.Row>
    <Table.Cell>
      <div className={classNames(styles.messageIcon, styles.importError)} />
      {filename}
    </Table.Cell>
    <Table.Cell>{error}</Table.Cell>
    <Table.Cell />
  </Table.Row>
)

UnsuccessfulImportRow.propTypes = {
  error: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
}

const SuccessfulImportRow = ({ filename, order }) => (
  <Table.Row>
    <Table.Cell>
      <div className={classNames(styles.messageIcon, styles.importSuccess)} />
      {filename}
    </Table.Cell>
    <Table.Cell>New Pending order created</Table.Cell>
    <Table.Cell
      link={`/orders/review/${order.id}`}
      shouldLinkOpenInNewTab
      className={styles.poNumber}
    >
      {order.poNumber}
    </Table.Cell>
  </Table.Row>
)

SuccessfulImportRow.propTypes = {
  filename: PropTypes.string.isRequired,
  order: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    poNumber: PropTypes.string,
  }).isRequired,
}

const ExcelSummaryModal = ({
  importedFiles,
  onClose,
  openExcelOrdersModal,
}) => (
  <Modal
    title={messages.excelOrders}
    onClose={onClose}
    open
    large
    secondaryActionHide
    primaryActionLabel={messages.close}
    secondaryActionClose={false}
    secondaryActionLabel={messages.goBack}
    secondaryActionOnClick={openExcelOrdersModal}
    className={styles.ExcelSummaryModal}
  >
    <Table fullWidth>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>{messages.file}</Table.HeaderCell>
          <Table.HeaderCell>{messages.result}</Table.HeaderCell>
          <Table.HeaderCell>{messages.poNumber}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {importedFiles.map(({ error, filename, orders }) => {
          if (error) {
            return (
              <UnsuccessfulImportRow
                filename={filename}
                error={error}
                key={filename}
              />
            )
          }
          return orders.map((order) => (
            <SuccessfulImportRow
              filename={filename}
              order={order}
              key={order.id}
            />
          ))
        })}
      </Table.Body>
    </Table>
  </Modal>
)

ExcelSummaryModal.propTypes = {
  importedFiles: PropTypes.arrayOf(
    PropTypes.shape({
      error: PropTypes.string,
      filename: PropTypes.string,
      orders: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          poNumber: PropTypes.string,
        }),
      ),
    }),
  ),
  onClose: PropTypes.func.isRequired,
  openExcelOrdersModal: PropTypes.func,
}

ExcelSummaryModal.defaultProps = {
  importedFiles: [],
  openExcelOrdersModal: () => {},
}

export default ExcelSummaryModal
