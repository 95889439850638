import { Component } from 'react'

import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { Accordion as BaseAccordion } from 'semantic-ui-react'

import Checkbox from 'components/Core/Checkbox/Checkbox'

import styles from './Accordion.less'
import { List } from './List'
import { ListItem } from './ListItem'

class Accordion extends Component {
  state = { activePanels: {} }

  handleClick = (e, titleProps) => {
    const { index } = titleProps

    this.setState((state) => ({
      activePanels: {
        ...state.activePanels,
        [index]: !state.activePanels[index],
      },
    }))
  }

  isPanelActive(index) {
    return !!this.state.activePanels[index]
  }

  render() {
    const {
      panels,
      noTopBorder,
      centeredExpand,
      withGrid,
      disabled,
    } = this.props

    return (
      <BaseAccordion>
        {panels.map((panel, index) => {
          const panelIsExpanded = panel.expanded !== undefined && panel.expanded
          const noTitle = !panel.title
          const isPanelActive = this.isPanelActive(index) || panelIsExpanded
          const openSingleFilterPanel = panel.openSingleFilter
          const titleClass = classNames(styles.accordionPanelTitle, {
            [styles.accordionPanelTitle__active]:
              !panel.isClickable && isPanelActive,
            [styles.accordionPanelTitle__inactive]:
              !panel.isClickable && !isPanelActive,
            [styles.centeredExpand]: centeredExpand,
            [styles.accordionPanelTitleWithGrid]: withGrid,
            [styles.expandedTitle]: panelIsExpanded,
            [styles.accordionPanelTitle__disabled]: disabled,
          })
          const panelClass = classNames(styles.accordionPanel, {
            [styles.noTitle]: noTitle,
            [styles.noTopBorder]: noTopBorder,
            [styles.expandedPanel]: panelIsExpanded,
          })

          const hasCheckbox = !isEmpty(panel.checkbox)
          const hasBubbleAndCheckbox = panel.bubble && hasCheckbox
          const onlyBubble = panel.bubble && !hasCheckbox
          const bubbleOrCheckbox = hasCheckbox || panel.bubble

          return (
            <div
              className={panelClass}
              key={panel.key ? panel.key : panel.title}
            >
              {panel.title && (
                <BaseAccordion.Title
                  index={index}
                  active={isPanelActive}
                  className={classNames({
                    [styles.accordionPanelTitleWrapper__checkboxAndBubble]: hasBubbleAndCheckbox,
                    [styles.accordionPanelTitleWrapper__singleFilterPanelActive]: openSingleFilterPanel,
                  })}
                  onClick={(e, props) => {
                    if (panel.isClickable) {
                      panel.isClickable()
                    }
                    this.handleClick(e, props)
                  }}
                >
                  <div className={styles.accordionPanelTitleWrapper}>
                    <div
                      className={classNames(titleClass, {
                        [styles.accordionPanelTitle__checkboxAndBubble]: hasBubbleAndCheckbox,
                        [styles.accordionPanelTitle__bubble]: onlyBubble,
                        [styles.accordionPanelTitle__checkbox]: hasCheckbox,
                        [styles.accordionPanelTitle__selectAll]:
                          panel.selectAll,
                      })}
                    >
                      {panel.title}
                      {panel.selectAll && (
                        <div>
                          <div
                            onClick={(e) => {
                              e.stopPropagation()
                              panel.handleSelectAll()
                            }}
                            className={classNames(styles.selectAllWrapper)}
                          >
                            <span className={styles.label}>select all</span>
                          </div>
                        </div>
                      )}
                      {bubbleOrCheckbox && (
                        <div
                          className={classNames({
                            [styles.checkboxAndBubbleWrapper]: hasBubbleAndCheckbox,
                            [styles.bubbleWrapper]: onlyBubble,
                          })}
                        >
                          {panel.bubble}
                          {hasCheckbox && (
                            <div className={styles.checkboxWrapper}>
                              <span className={styles.label}>
                                {panel.checkbox.label}
                              </span>
                              <Checkbox
                                small
                                onClick={(e) => {
                                  e.stopPropagation()
                                  panel.checkbox.onClick()
                                }}
                                checked={panel.checkbox.checked}
                                className={styles.checkbox}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </BaseAccordion.Title>
              )}
              <BaseAccordion.Content active={isPanelActive}>
                <div className={styles.accordionPanelContent}>
                  {panel.content}
                </div>
              </BaseAccordion.Content>
            </div>
          )
        })}
      </BaseAccordion>
    )
  }
}

Accordion.propTypes = {
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      content: PropTypes.node,
      selectAll: PropTypes.bool,
      handleSelectAll: PropTypes.func,
      expanded: PropTypes.bool,
      checkbox: PropTypes.shape({
        onClick: PropTypes.func.isRequired,
        label: PropTypes.string,
        checked: PropTypes.bool,
      }),
      isClickable: PropTypes.func,
    }),
  ).isRequired,
  noTopBorder: PropTypes.bool,
  centeredExpand: PropTypes.bool,
  withGrid: PropTypes.bool,
  disabled: PropTypes.bool,
}

Accordion.defaultProps = {
  noTopBorder: false,
  centeredExpand: false,
  withGrid: false,
  disabled: false,
}

Accordion.Title = BaseAccordion.Title
Accordion.Content = BaseAccordion.Content
Accordion.List = List
Accordion.ListItem = ListItem

export default Accordion
