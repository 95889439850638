import styled, { css } from 'styled-components'

import { CSSPropertySet } from '../../../types'

export const SectionSettingForm = styled.div`
  margin-top: 16px;
  display: flex;
`

export const SectionDropdown = styled.div`
  width: 210px;
`

export const SectionTextField = styled.div`
  width: 328px;
  margin-left: 8px;
`
interface IconWrapperProps {
  $disabled?: boolean
}
export const IconWrapper = styled.div<IconWrapperProps>`
  ${({ $disabled }): CSSPropertySet =>
    $disabled
      ? css`
          border-color: var(--color-primary-200) !important;
          svg {
            --main-color: var(--color-primary-200);
            &:hover {
              --main-color: var(--color-primary-200);
            }
          }
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `}
  border-radius: 50%;
  border: 1px solid var(--color-accent-alpha-600);
  height: 50%;
  display: flex;
  padding: 5px;
  margin-left: 5px;
  margin-top: 23px;
`
