/* eslint-disable no-restricted-properties */

/* eslint-disable no-restricted-globals */
import {
  get,
  isEmpty,
  toString as lToString,
  lowerCase,
  map,
  startCase,
} from 'lodash'
import find from 'lodash/find'
import moment from 'moment'

import { naturalSortBy } from 'utils/transformations/sorting'

export const NUMERIC_FORMATTING = 'numeric'
export const PERCENTAGE_FORMATTING = 'percentage'
export const M_DASH = '—'

/**
 * Helper for guarding against invalid inputs
 */
const isInvalidNumericalValue = (num) =>
  num === null || num === '' || typeof num === 'undefined'

/**
 * Rounds a value to a given precision (2 by default)
 * Does not change the value if precision <= 0
 * Applies rounding with Math.round
 */
export function roundToPrecision(value, precision = 2) {
  if (precision <= 0) {
    return value
  }
  return Math.round(Math.pow(10, precision) * value) / Math.pow(10, precision)
}

/**
 * Returns a function that rounds a value to a given nearest
 * Does not change the value if nearest <= 0
 * Applies rounding with Math.round
 */
export function createRoundToNearest(nearest = 1) {
  return (value) => {
    if (nearest <= 0) {
      return value
    }
    return Math.round(value / nearest) * nearest
  }
}

/**
 * Converts a value to percent with a given precision (2 by default)
 * Applies rounding with Math.round
 */
export function roundToPercent(value, precision = 2) {
  return roundToPrecision(100 * value, precision)
}

export const formatNumber = (number) =>
  isNaN(number) ? '-' : roundToPrecision(number, 2).toLocaleString(undefined)

export const formatPercentage = (percentage) =>
  isNaN(percentage) ? '-' : `${roundToPercent(percentage, 1)}%`

export const formatters = {
  numeric: formatNumber,
  percentage: formatPercentage,
  default: (value) => value,
}

export const formatValue = (value, format) => {
  const formatter = formatters[format] || formatters.default
  return formatter(value)
}

export const formatDate = (
  dateString,
  userDateFormat = 'mm/dd/yyyy',
  utc,
  normalize = true,
) => {
  // userDateFormat has to be normalized because output for non-upcased formats
  // in the Moment API is wonky
  const normalizedDateFormat = normalize
    ? userDateFormat.toUpperCase()
    : userDateFormat
  const parsedDate = utc ? moment.utc(dateString) : moment(dateString)
  return parsedDate.isValid() ? parsedDate.format(normalizedDateFormat) : ''
}

export const formatDateRange = (
  startDateString,
  endDateString,
  { dateFormat, utc, normalize = true },
) =>
  startDateString && endDateString
    ? `${formatDate(
        startDateString,
        dateFormat,
        utc,
        normalize,
      )} - ${formatDate(endDateString, dateFormat, utc, normalize)}`
    : ''

export const formatTimestamp = (
  dateString,
  dateFormat = 'MM/DD/YYYY hh:mm:ss A',
) => {
  const momentDateString = dateString && moment(dateString, moment.ISO_8601)

  return momentDateString && momentDateString.isValid()
    ? momentDateString.format(dateFormat)
    : ''
}

export const formatDateWithInputFormat = (
  dateString,
  inputFormat,
  outputFormat,
  utc = true,
) => {
  const normalizedInputFormat = inputFormat.toUpperCase()
  const normalizedDateFormat = outputFormat.toUpperCase()
  const parsedDate = utc
    ? moment.utc(dateString, normalizedInputFormat)
    : moment(dateString, normalizedInputFormat)
  return parsedDate.isValid() ? parsedDate.format(normalizedDateFormat) : ''
}

export const formatPriceTypeIds = (priceTypes) =>
  map(priceTypes, (priceType) => ({
    name: priceType.name,
    id: priceType.id,
    code: priceType.currency?.code ?? '',
  }))

/**
 * Adds commas to in between thousands in numbers
 * 5000 => 5,000
 * from https://stackoverflow.com/a/2901298
 */
export const numberWithCommas = (num, defaultValue = '') =>
  numberWithSeparator(num, ',', defaultValue)

export const numberWithSeparator = (num, separator, defaultValue = '') =>
  isInvalidNumericalValue(num)
    ? defaultValue
    : num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator)

export const decimalWithCommas = (num, precision = 2, defaultValue = '') =>
  isInvalidNumericalValue(num)
    ? defaultValue
    : numberWithSeparator(parseFloat(num).toFixed(precision), ',')

/**
 * Formats a given number with the specified thousands and/or decimal separators
 *
 * @param {number} number - the number to format
 * @param {string | undefined} thousandsSeparator
 * @param {string | undefined} decimalSeparator
 * @param {number} precision - decimal precision
 * @param {*} defaultValue - if number is invalid, returns this value by default
 * @returns A string with the formatted number
 */
export const formatNumberWithCustomSeparators = (
  number,
  thousandsSeparator,
  decimalSeparator,
  precision = false,
  defaultValue = '',
) => {
  if (isInvalidNumericalValue(number)) {
    return defaultValue
  }

  let result = number
  if (typeof precision === 'number') {
    const decimalValue = formatDecimalValue(result, precision)
    result = decimalSeparator
      ? decimalValue.replace('.', decimalSeparator)
      : decimalValue
  }

  if (thousandsSeparator) {
    result = numberWithSeparator(result, thousandsSeparator)
  }

  return result.toString()
}

export const amountWithCurrencyCode = (amount, currencyCode = '') =>
  `${amount} ${currencyCode}`

export const formatDecimalValue = (num, precision = false) => {
  if (isInvalidNumericalValue(num)) {
    return null
  }
  const numString = num.toString()

  const valueWithoutCommas = numString.replace(/,/g, '')
  if (precision)
    return parseFloat(valueWithoutCommas).toFixed(precision).toString()
  return parseFloat(valueWithoutCommas).toString()
}

export const factorToPercent = ({ adjustmentType, value, precision = 6 }) => {
  if (isInvalidNumericalValue(value)) {
    return null
  }
  const MARKUP_PERCENT_PRECISION = 1

  switch (adjustmentType) {
    case 'ADDITIVE':
      return ((parseFloat(value) - 1) * 100).toFixed(precision)
    case 'SUBTRACTIVE':
      return ((1 - parseFloat(value)) * 100).toFixed(precision)
    case 'MARKUP':
      return (100 * (1 - 1 / value)).toFixed(MARKUP_PERCENT_PRECISION)
    default:
      return value
  }
}

export const percentToFactor = ({ adjustmentType, value, precision = 6 }) => {
  if (isInvalidNumericalValue(value)) {
    return null
  }

  switch (adjustmentType) {
    case 'ADDITIVE':
      return (parseFloat(value) / 100 + 1).toFixed(precision)
    case 'SUBTRACTIVE':
      return ((100 - parseFloat(value)) / 100).toFixed(precision)
    case 'MARKUP':
      return (1 / (1 - value / 100)).toFixed(precision)
    default:
      return value
  }
}

export const formatNameWithCodeAndNameInBrackets = (code, name) => {
  if (code && name) {
    return `${code} (${name})`
  }
  return code || name || ''
}

export const formatNameWithCodeAndDash = (name, code) => {
  if (!name || !code) {
    return name || code || ''
  }
  return `${name} - ${code}`
}

export const formatNameWithCode = (name, code) =>
  (name || '') + (code ? ` (${code})` : '')

export const formatConnectedAccounts = (connectedAccounts, maxLength = 42) => {
  let accountsConnected = ''
  if (connectedAccounts?.length > 0) {
    const formattedConnections = connectedAccounts?.map(
      (retailer) => retailer.profileName,
    )
    const truncatedConnections = getTruncatedListWithNumber({
      elements: formattedConnections,
      maxLength: maxLength,
    })
    const display = truncatedConnections?.displayedElements?.join(', ')
    const remaining = truncatedConnections?.remainingElements
    accountsConnected =
      remaining > 0 ? display + ' + ' + remaining + ' More' : display
  }
  return accountsConnected
}

export const formatAccountName = (account) => {
  if (account) {
    const name = account.aliasedName || account.name || ''
    return formatNameWithCode(name, account.code)
  }
  return ''
}

export const formatOptionDisplayName = (option) => {
  const displayName = option.displayName || option.title
  if (displayName) {
    return displayName
  }
  return formatNameWithCode(option.name, option.code)
}

export const formatDropdownOptions = (
  options,
  textFunc = formatOptionDisplayName,
  sortAlphabetically,
  className = '',
  keyField = 'id',
) => {
  // objects in the options array must contain an id field and a displayName, a title or name field
  if (!options) return []
  const formattedOptions = options.map((option) => ({
    text: textFunc(option),
    key: option[keyField],
    value: option.id,
    className,
  }))

  return sortAlphabetically
    ? naturalSortBy(formattedOptions, 'text')
    : formattedOptions
}

export const formatSimpleDropdownOptions = (elements, className = '') =>
  map(elements, (element) => ({
    value: element,
    id: element,
    text: element,
    className,
  }))

export const formatDropdownCustomOptions = (
  options,
  format = {
    value: 'id',
    textFunc: (e) => formatNameWithCode(e.name, e.code),
    id: 'id',
    code: 'id',
  },
) =>
  options?.map((option) => ({
    value: get(option, `${format.value}`),
    text: format.textFunc(option),
    id: get(option, `${format.id}`),
    code: get(option, `${format.code}`),
  }))

export const joinElementsWithAComma = (elements) =>
  [elements.slice(0, -1).join(', '), elements.slice(-1)[0]].join(
    elements.length < 2 ? '' : ', and ',
  )

export const toServerDate = (date) => moment(date).format('YYYY-MM-DD')

export const formatEnum = (enumName) => startCase(lowerCase(enumName))

export const formatExportStatus = (exportStatus) => {
  if (exportStatus === 'RE_EXPORT') {
    return 'Set to Re-Export'
  }

  return formatEnum(exportStatus)
}

// Note: may be problematic for localization
export const pluralize = (number, things) => {
  let text = `${number} ${things}`
  if (number !== 1) {
    text += 's'
  }
  return text
}

export const getTextFromSelectedDropdownOption = (
  options,
  selectedOptionId,
) => {
  const selectedOption = options.find(
    (option) => option.value === selectedOptionId,
  )
  return selectedOption ? selectedOption.text : ''
}

export const getTextFromSelectedDropdownMultiOptions = (
  options,
  selectedOptionIds,
) =>
  selectedOptionIds.map((selectedOptionId) =>
    getTextFromSelectedDropdownOption(options, selectedOptionId),
  )

export const getDropdownOptionValueByText = (options = [], text) => {
  const selectedItem = options.find((option) => option.text === text)
  return get(selectedItem, 'value', '')
}

export const getDropdownOptionValueById = (options, selectedId) => {
  const selectedOption = find(options, (option) => option.value === selectedId)
  return get(selectedOption, 'value')
}

export const withNoTranslate = (fields) =>
  fields.map((field) => ({ ...field, className: 'notranslate' }))

export const formatTextWithMDash = (value) => (isEmpty(value) ? M_DASH : value)

export const formatBooleanToString = (value) => {
  const stringValue = lToString(value)
  return stringValue.toLowerCase() === 'true' ? 'Yes' : 'No'
}

export const formatStringToTitleCase = (value) =>
  value.replace(
    /\w\S*/g,
    (stringValue) =>
      stringValue.charAt(0).toUpperCase() + stringValue.substr(1).toLowerCase(),
  )

export const getTruncatedListWithNumber = ({ elements, maxLength = 50 }) => {
  let length = 0
  let remainingElements = elements?.length
  let displayedElements = []

  for (const index in elements) {
    length = length + elements[index].length
    if (length < maxLength) {
      displayedElements.push(elements[index])
      if (remainingElements > 0) {
        remainingElements = remainingElements - 1
      }
    }
  }

  return {
    remainingElements,
    displayedElements,
  }
}
