import { handleActions } from 'redux-actions'

const createReducer = (storeKey) => {
  const key = `${storeKey}s`
  const defaultState = {
    [key]: [],
    allSelected: false,
  }

  return handleActions(
    {
      [`${storeKey.toUpperCase()}/TOGGLE_${storeKey.toUpperCase()}`]: (
        state,
        { payload: { id, items } },
      ) => {
        const prevState = state[key]
        const wasSelected = prevState.includes(id)
        const newSelectedItems = wasSelected
          ? prevState.filter((item) => item !== id)
          : [...prevState, id]

        return {
          ...state,
          [key]: newSelectedItems,
          allSelected: items.length === newSelectedItems.length,
        }
      },
      [`${storeKey.toUpperCase()}/TOGGLE_${storeKey.toUpperCase()}S`]: (
        state,
        { payload: { ids, items } },
      ) => {
        const prevState = state[key]
        const newSelectedItems = [...prevState]

        ids.forEach((id) => {
          const stateHasID = prevState.indexOf(id) !== -1
          if (stateHasID) {
            const indexOfId = newSelectedItems.indexOf(id)
            newSelectedItems.splice(indexOfId, 1)
          } else {
            newSelectedItems.push(id)
          }
        })

        return {
          ...state,
          [key]: newSelectedItems,
          allSelected: items.length === newSelectedItems.length,
        }
      },
      [`${storeKey.toUpperCase()}/TOGGLE_ALL_${storeKey.toUpperCase()}`]: (
        state,
        { payload: { items } },
      ) => {
        const newSelectedItems =
          items.length > state[key].length ? [...items] : []

        return {
          ...state,
          [key]: newSelectedItems,
          allSelected: newSelectedItems.length > 0,
        }
      },
      [`${storeKey.toUpperCase()}/SET_${storeKey.toUpperCase()}`]: (
        state,
        { payload: { id } },
      ) => {
        const newSelectedItem = [id]
        return {
          ...state,
          [key]: newSelectedItem,
          allSelected: false,
        }
      },
      [`${storeKey.toUpperCase()}/DESELECT_${storeKey.toUpperCase()}`]: (
        state,
      ) => ({
        ...state,
        ...defaultState,
      }),
    },
    defaultState,
  )
}

export default createReducer
