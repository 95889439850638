import { useMutation } from '@apollo/client'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

const BULK_UPDATE_STOREFRONT = atlas`#graphql
  mutation bulkUpdate($input: BulkUpdateStorefrontsInput!) {
    bulkUpdateStorefronts(input: $input) {
      id
      uniqueName
      displayName
      orderNbr
      isOnline
      isHome
      modified
      statusSince
    }
  }
`

const BULK_DELETE_STOREFRONT = atlas`#graphql
  mutation BulkDeleteStorefront($ids: [ID!]!) {
    deleteStorefronts(ids: $ids) {
      success
      error
    }
  }
`
export const useBulkUpdateStorefront = () => {
  const [bulkUpdate, { loading }] = useMutation(BULK_UPDATE_STOREFRONT, {
    client: atlasClient,
  })

  return {
    loading,
    bulkUpdate: (bulks) => {
      return bulkUpdate({
        variables: {
          input: { storefronts: bulks },
        },
      })
    },
  }
}

export const useDeleteStorefront = () => {
  const [deleteStorefronts, { loading }] = useMutation(BULK_DELETE_STOREFRONT, {
    client: atlasClient,
  })

  return {
    loading,
    deleteStorefronts: (ids) => {
      return deleteStorefronts({
        variables: {
          ids: ids,
        },
      })
    },
  }
}
