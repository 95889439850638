import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Switch.less'

const Switch = ({ onChange, disabled, checked, id }) => (
  <label htmlFor={id} className={styles.switch}>
    <input
      type="checkbox"
      id={id}
      disabled={disabled}
      onChange={onChange}
      checked={checked}
    />
    <span
      className={classNames(styles.slider, styles.round, {
        [styles.disabled]: disabled,
      })}
    />
  </label>
)

Switch.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
}

Switch.defaultProps = {
  id: 'switch',
  onChange: () => {},
  disabled: false,
  checked: false,
}

export default Switch
