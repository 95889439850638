import { map } from 'lodash'
import WebFont from 'webfontloader'

import { INSTALLED_FONTS, WEIGHTS } from './TextSettings.constants'

export const formatCustomSimpleDropdownOptions = (elements) =>
  map(elements, (element) => ({
    value: element,
    label: element,
  }))

export const calculateStyles = (styleSettings) => {
  const { fontFamily, fontWeight } = styleSettings
  const finalFontName =
    getFontNameFromOption(`${fontFamily}:${fontWeight}`) || fontFamily
  return {
    ...styleSettings,
    fontFamily: finalFontName,
  }
}

export const getFontNameFromOption = (option) => {
  switch (option) {
    case 'Verdana:100':
      return 'Verdana Pro W01 Light'
    case 'Verdana:300':
      return 'Verdana Pro W01 Light'
    case 'Verdana:400':
      return 'Verdana Pro W01 Regular'
    case 'Verdana:500':
      return 'VerdanaProW01-SemiBold'
    case 'Verdana:700':
      return 'Verdana Pro W01 Bold'
    case 'Verdana:900':
      return 'Verdana Pro W01 Black'
    case 'Georgia:100':
      return 'GeorgiaProW01-Light'
    case 'Georgia:300':
      return 'GeorgiaProW01-Light'
    case 'Georgia:400':
      return 'GeorgiaProW01-Regular'
    case 'Georgia:500':
      return 'GeorgiaProW01-SemiBold'
    case 'Georgia:700':
      return 'GeorgiaProW01-Bold'
    case 'Georgia:900':
      return 'GeorgiaProW01-Black'
    case 'Palatino:100':
      return 'Palatino LT W01 Light'
    case 'Palatino:300':
      return 'Palatino LT W01 Light'
    case 'Palatino:400':
      return 'Palatino LT W01 Roman'
    case 'Palatino:500':
      return 'Palatino LT W01 Medium'
    case 'Palatino:700':
      return 'Palatino LT W01 Bold'
    case 'Palatino:900':
      return 'Palatino LT W01 Black'
    case 'Arial:100':
      return 'Arial MT W01 Light'
    case 'Arial:300':
      return 'Arial MT W01 Light'
    case 'Arial:400':
      return 'Arial MT W01 Regular'
    case 'Arial:500':
      return 'Arial MT W01 Medium'
    case 'Arial:700':
      return 'Arial MT W01 Bold'
    case 'Arial:900':
      return 'Arial MT W01 ExtraBold'
    default:
      return ''
  }
}

export const shouldLoadFont = (fontName) =>
  !INSTALLED_FONTS.some((font) => font.includes(fontName))

export const loadFont = (fontFamily) => {
  if (shouldLoadFont(fontFamily)) {
    WebFont.load({
      google: {
        families: [`${fontFamily}:${WEIGHTS}`],
      },
    })
  }
}
