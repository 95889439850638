import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import Divider from 'components/Core/Divider/Divider'

import styles from './Header.less'

const Header = ({
  children,
  content,
  divider,
  error,
  className,
  testId,
  right,
  id,
}) => (
  <div
    className={classNames(className, styles.Header, { [styles.error]: error })}
    data-testid={testId}
    id={id}
  >
    {children && right ? (
      <div className={styles.headerWrapper}>
        <h1>{children}</h1>
        {right}
      </div>
    ) : (
      <h1>{children}</h1>
    )}

    <div className={styles.content}>{content}</div>
    {divider && <Divider />}
  </div>
)

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  content: PropTypes.node,
  divider: PropTypes.bool,
  error: PropTypes.bool,
  testId: PropTypes.string,
  id: PropTypes.string,
}

Header.defaultProps = {
  className: '',
  children: null,
  divider: false,
  content: null,
  error: false,
  testId: '',
  id: '',
}

export default Header
