import { handleActions } from 'redux-actions'

export const defaultState = {
  outstandingMutations: 0,
}

const reducer = handleActions(
  {
    'AUTOSAVE/ADD_MUTATION': (state) => ({
      ...state,
      outstandingMutations: state.outstandingMutations + 1,
    }),
    'AUTOSAVE/REMOVE_MUTATION': (state) => ({
      ...state,
      outstandingMutations: state.outstandingMutations - 1,
    }),
  },
  defaultState,
)

export default reducer
