import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { config } from 'config'

const ddApplicationID = process.env.REACT_APP_DD_RUM_APPLICATION_ID
const ddClientToken = process.env.REACT_APP_DD_RUM_CLIENT_TOKEN
const environment =
  process.env.REACT_APP_DD_RUM_ENVIRONMENT ?? process.env.NODE_ENV

const REACT_APP_API_URL = config.urls.api
const REACT_APP_ATLAS_URL = config.urls.atlas

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\/]/g, '\\$&') // $& means the whole matched string
}

export const logger = datadogLogs.createLogger('ddLogger', {
  handler: 'console',
})

const initializeDatadogRUM = () => {
  if (environment && ddApplicationID && ddClientToken) {
    const ddConfig = {
      applicationId: ddApplicationID,
      clientToken: ddClientToken,
      datacenter: 'us',
      env: environment,
      service: 'joor-react',
      sampleRate: 100,
      silentMultipleInit: true,
      trackSessionAcrossSubdomains: true,
    }
    const allowedTracingOrigins = []
    if (REACT_APP_ATLAS_URL !== undefined) {
      allowedTracingOrigins.push(new URL(REACT_APP_ATLAS_URL).origin)
    }
    if (REACT_APP_API_URL !== undefined) {
      allowedTracingOrigins.push(
        new RegExp(
          escapeRegExp('https://') +
            '.*' +
            escapeRegExp(
              REACT_APP_API_URL.slice(
                REACT_APP_API_URL.indexOf(environment) + environment.length,
              ),
            ),
        ),
      )
    }
    datadogRum.init({
      ...ddConfig,
      allowedTracingOrigins,
      // Override sampleRate only for datadogRUM
      sampleRate: 10,
      beforeSend: (event, context) => {
        event.context = {
          ...event.context,
          response:
            'response' in context && context.response
              ? {
                  headers: context.response.headers,
                  body: context.response.body,
                  status: context.response.status,
                }
              : undefined,
        }
      },
    })

    datadogLogs.init({
      ...ddConfig,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'error',
    })
    if (process.env.NODE_ENV !== 'development') {
      logger.setHandler('http')
    }
  }
}

export const setLocationForDatadogRUM = (location) => {
  datadogRum.addRumGlobalContext('location', location)
}

export const setUserForDatadogRUM = ({ userId, account }) => {
  if (environment && ddApplicationID && ddClientToken) {
    datadogRum.addRumGlobalContext('user', {
      userId: userId,
      accountId: account.accountId,
    })
  }
}

export default initializeDatadogRUM
