import { Dispatch, SetStateAction } from 'react'

import { Icon, RadioButton, TextButton, Tooltip } from '@joor/design-system'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  getAccountId,
  getAccountProfileName,
} from 'store/currentUser/selectors'

import { AddressSelectionView } from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.ids'
import { isGoogleAddressComplete } from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.utils'

import AddressElement from './components/AddressElement'
import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

type AddressSelectionProps = {
  providedAddress?: Partial<RetailerProfileLocation>
  googleAddress?: Partial<RetailerProfileLocation>
  providedWebsite?: string
  googleWebsite?: string
  setAddressEdition: () => void
  setSelectedAddressId: Dispatch<SetStateAction<string>>
  selectedAddressId: string
}

const StyledContainer = styled.div`
  max-height: 100%;
  overflow: auto;
`
const StyledInfoText = styled.div`
  padding: 16px 0 24px;
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  line-height: 16px;
  letter-spacing: 0.6px;
`
const StyledHightlightedText = styled.span`
  font-weight: var(--font-weight-bold);
`
const StyledAddressContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  gap: 24px;
  > div {
    max-width: 50%;
  }
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
    margin-right: 48px;
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
`
const StyledRadioButton = styled(RadioButton)`
  > span {
    margin-left: 8px;
  }
`
const StyledTextButton = styled(TextButton)`
  padding: 16px 0 0 21px;
`
const StyledGoogleAddressLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  :hover {
    > span > span > svg > g {
      fill: var(--color-primary-800);
    }
  }
`

const AddressSelection: React.FC<AddressSelectionProps> = ({
  providedAddress,
  googleAddress,
  providedWebsite,
  googleWebsite,
  setAddressEdition,
  setSelectedAddressId,
  selectedAddressId,
}) => {
  const accountId = useSelector(getAccountId)
  const accountName = useSelector(getAccountProfileName)
  return (
    <StyledContainer data-testid={AddressSelectionView.Component}>
      <StyledInfoText>
        For
        <StyledHightlightedText>
          {' '}
          {accountName} ({accountId})
        </StyledHightlightedText>
        , accurately represent your business to brands that you currently work
        with and those that you may want to work with in future.
      </StyledInfoText>
      <StyledAddressContainer>
        <div id={`${AddressSelectionView.ProvidedAddress}__wrapper`}>
          <StyledRadioButton
            id={AddressSelectionView.ProvidedAddress}
            label="Provided Info"
            size="extraLarge"
            checked={selectedAddressId === providedAddress?.id}
            onChange={() => setSelectedAddressId(providedAddress?.id ?? '')}
          />
          <AddressElement address={providedAddress} website={providedWebsite} />
          <StyledTextButton
            id={AddressSelectionView.EditAddressButton}
            onClick={setAddressEdition}
          >
            Edit
          </StyledTextButton>
        </div>
        <div>
          {Boolean(isGoogleAddressComplete(googleAddress)) &&
            Boolean(googleWebsite) && (
              <div id={`${AddressSelectionView.GoogleAddress}__wrapper`}>
                <StyledGoogleAddressLabel>
                  <StyledRadioButton
                    id={AddressSelectionView.GoogleAddress}
                    label="Suggested Info"
                    size="extraLarge"
                    checked={selectedAddressId === googleAddress?.id}
                    onChange={() =>
                      setSelectedAddressId(googleAddress?.id ?? '')
                    }
                  />
                  <Tooltip
                    content={'This information is sourced from Google Maps.'}
                    defaultPlacement="top"
                  >
                    <Icon iconName="information" iconSize="small" />
                  </Tooltip>
                </StyledGoogleAddressLabel>
                <AddressElement
                  address={googleAddress}
                  website={googleWebsite}
                />
              </div>
            )}
        </div>
      </StyledAddressContainer>
    </StyledContainer>
  )
}

export default AddressSelection
