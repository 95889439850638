import { getDisplayName } from 'utils/mixpanel'
import { useUserAnalytics } from 'utils/sdks/userAnalytics'

const withAnalytics = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { trackEvent } = useUserAnalytics()

    const trackers = {
      trackRowSettings: (title, settings) => trackEvent(title, settings),
    }

    return <WrappedComponent {...props} {...trackers} />
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return Wrapper
}

export default withAnalytics
