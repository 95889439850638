import { createContext, useContext, useEffect, useState } from 'react'

import { auth } from 'utils/sdks/authKeycloak'

import { domErrorHandler, errorHandler } from './AuthProviderErrorHandler'

/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */
interface AuthContextValues {
  isAuthenticated: boolean
  login: () => void
  isInitialized: boolean
}

/**
 * defaultAuthContextValues defines the default values for the {@link AuthContext}
 */
const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  login: () => {},
  isInitialized: false,
}

/**
 * AuthContext is the context exposed by the {@link AuthContextProvider}.
 */
const AuthContext = createContext<AuthContextValues>(defaultAuthContextValues)

export const useAuth = () => {
  const context = useContext(AuthContext)
  return context
}
/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */

interface AuthProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element
}

/**
 * AuthProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
const AuthProvider = (props: AuthProviderProps) => {
  // @typescript-eslint/no-explicit-any
  const errorMapper: Record<string, (ex: any) => void> = {
    DOMException: domErrorHandler,
  }

  // @typescript-eslint/no-explicit-any
  const getErrorMessage = (error: any) => {
    const error_message = error instanceof Error ? error.message : undefined
    return JSON.stringify(error) ?? error_message ?? error ?? 'undefined error'
  }

  const [isInitialized, setInitialized] = useState<boolean>(false)
  const [isAuthenticated, setAuthenticated] = useState<boolean>(false)

  const initialize = async (retryAttempts: number = 3) => {
    for (let attempt = 1; attempt <= retryAttempts; attempt++) {
      try {
        const isAuthenticatedResponse = await auth.init()
        setAuthenticated(isAuthenticatedResponse)
        setInitialized(true)
        return
      } catch (error) {
        console.error(
          `Initialization attempt ${attempt} failed with: ${getErrorMessage(
            error,
          )}`,
        )
        errorHandler(error, errorMapper)
        await new Promise((resolve) => setTimeout(resolve, attempt * 1000))
      }
    }
    setAuthenticated(false)
    console.error('Initialization failed after maximum retry attempts.')
  }

  const login = () => {
    auth.login()
  }

  useEffect(() => {
    if (!isInitialized) {
      initialize()
    }
  }, [isInitialized])

  return (
    <AuthContext.Provider value={{ isAuthenticated, isInitialized, login }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
