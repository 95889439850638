import { graphql } from '@apollo/client/react/hoc'
import flow from 'lodash/fp/flow'

import { formatDropdownOptions, withNoTranslate } from 'utils/formatters'
import { getBrandConnectedAccounts } from 'utils/transformations/accounts'
import { relayConnectionToArray } from 'utils/transformations/graphql'

import { graphene } from 'graphql/tags'

export const MINIMUM_CHARACTERS_QUERY = 2

const initialExcelOrderModalQuery = graphene`#graphql
  query InitialExcelOrderModal {
    viewerBrand {
      id
      types {
        edges {
          node {
            id
            name
            code
          }
        }
      }
    }
    warehouses {
      edges {
        node {
          id
          name
          code
        }
      }
    }
  }
`

// TODO(ch44742) Sustainable way (w/o repetition) for connectedAccounts query
const connectedAccountsQuery = graphene`#graphql
  query connectedAccountsQuery($searchFilter: String) {
    connectedAccounts(filterBy: { displayName: $searchFilter }) {
      edges {
        node {
          ... on RetailerAccount {
            id
            displayName
            locations(filterBy: { primary: true }) {
              edges {
                node {
                  id
                  state
                  city
                }
              }
            }
          }
        }
      }
    }
  }
`

const connectionsForRetailerQuery = graphene`#graphql
  query DoorsForRetailer($retailerAccountId: ID!) {
    node(id: $retailerAccountId) {
      ... on RetailerAccount {
        id
        brandDoorMatches(orderBy: [{ field: NAME, order: ASCENDING }]) {
          edges {
            node {
              id
              code
              name
            }
          }
        }
        brandWarehouseMatches(
          orderBy: [
            { field: DEFAULT_WAREHOUSE, order: DESCENDING }
            { field: NAME, order: ASCENDING }
          ]
        ) {
          edges {
            node {
              id
              code
              name
            }
          }
        }
      }
    }
  }
`

const formatForExcelOrderModal = flow(
  relayConnectionToArray,
  formatDropdownOptions,
)

export const connectionsForRetailerData = ({ data: { loading, node } }) => ({
  doors: node
    ? withNoTranslate(formatForExcelOrderModal(node.brandDoorMatches))
    : [],
  loadingConnectionsForRetailer: loading,
  retailerWarehouses: node
    ? withNoTranslate(formatForExcelOrderModal(node.brandWarehouseMatches))
    : [],
})

export const withConnectionsForRetailerData = graphql(
  connectionsForRetailerQuery,
  {
    options: ({ selectedRetailerId }) => ({
      variables: { retailerAccountId: selectedRetailerId },
    }),
    props: connectionsForRetailerData,
    skip: ({ selectedRetailerId }) => !selectedRetailerId,
  },
)

export const initialExcelOrderModalData = ({
  data: { connectedAccounts, error, loading, viewerBrand, warehouses },
}) => ({
  initialDataFailed: error,
  loadingInitialData: loading,
  orderTypes: viewerBrand
    ? withNoTranslate(formatForExcelOrderModal(viewerBrand.types))
    : [],
  retailers: getBrandConnectedAccounts(connectedAccounts),
  brandWarehouses: withNoTranslate(formatForExcelOrderModal(warehouses)),
})

export const connectedAccountsData = ({
  data: { connectedAccounts, error, loading },
}) => ({
  retailers:
    !loading && !error && connectedAccounts
      ? getBrandConnectedAccounts(connectedAccounts)
      : [],
  connectedAccountsQueryLoading: loading,
})

export const withInitialExcelOrderModalData = graphql(
  initialExcelOrderModalQuery,
  {
    props: initialExcelOrderModalData,
  },
)

export const withConnectedAccounts = graphql(connectedAccountsQuery, {
  options: ({ retailerSearchText }) => ({
    variables: { searchFilter: retailerSearchText },
  }),
  props: connectedAccountsData,
  skip: ({ retailerSearchText }) =>
    retailerSearchText.length < MINIMUM_CHARACTERS_QUERY,
})
