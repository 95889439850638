import PropTypes from 'prop-types'

import FileDownloadPoller from './FileDownloadPoller.container'

const FileDownloadHandler = ({ downloadTaskIds }) => (
  <>
    {downloadTaskIds.map((id) => (
      <FileDownloadPoller key={id} taskId={id} />
    ))}
  </>
)

FileDownloadHandler.propTypes = {
  downloadTaskIds: PropTypes.arrayOf(PropTypes.string),
}
FileDownloadHandler.defaultProps = {
  downloadTaskIds: [],
}
export default FileDownloadHandler
