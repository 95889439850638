import { useCallback, useState } from 'react'

import { Caption, Modal, ModalProvider, Option } from '@joor/design-system'
import { useSelector } from 'react-redux'

import { RequestPriceType } from '__generated__/atlas-types'

import { toGlobalId } from 'utils/transformations/graphql'

import usePortal from 'hooks/usePortal'
import { getAccountId } from 'store/currentUser/selectors'

import { useAcceptConnection } from '../../RetailerProfile/RetailerProfileViewMode/components/Header/Header.hooks'
import { useModalData } from '../ConnectModal/ConnectModal.hooks'
import {
  StyledDescription,
  StyledModal,
} from '../ConnectModal/ConnectModal.styles'
import PriceTypeAndTermsSelection from '../ConnectModal/components/PriceTypeAndTermsSelection'

type AcceptConnectionModalProps = {
  isOpen: boolean
  retailerId: string
  retailerName: string
  onClose: () => void
  refetchProfileData: () => void
}

export const AcceptConnectionModal = ({
  isOpen,
  retailerId,
  retailerName,
  onClose,
  refetchProfileData,
}: AcceptConnectionModalProps) => {
  const accountId = useSelector(getAccountId)
  const brandId = toGlobalId('Brand', accountId) ?? ''
  const {
    termsAndConditions,
    priceTypes,
    loading: modalDataLoading,
  } = useModalData(brandId, retailerId)
  const [priceTypeWithTerms, setPriceTypeWithTerms] = useState<
    RequestPriceType[]
  >([])

  const isPriceTypeAssigned = Boolean(priceTypeWithTerms?.length > 0)
  const dissabledButton = !isPriceTypeAssigned || modalDataLoading
  const [modalHeight, setModalHeight] = useState<number>(0)
  const Portal = usePortal()
  const handleAcceptConnection = useAcceptConnection()

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setModalHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const handleSetPriceTypeWithTerms = (
    updatedPriceTypes: RequestPriceType[],
  ) => {
    setPriceTypeWithTerms(updatedPriceTypes)
  }

  return (
    <Portal>
      <ModalProvider>
        <StyledModal
          key={priceTypeWithTerms.length}
          isOpen={isOpen}
          title={{
            text: 'Assign a Currency',
          }}
          size="medium"
          onClose={onClose}
          $modalHeight={modalHeight}
          className={isPriceTypeAssigned ? 'pricetype-assigned' : ''}
        >
          <Modal.Content>
            <div ref={measuredRef}>
              <StyledDescription>
                Please assign a price type for this connection.
              </StyledDescription>
              {Boolean(priceTypes.length) && (
                <PriceTypeAndTermsSelection
                  priceTypeWithTerms={priceTypeWithTerms}
                  setPriceTypeWithTerms={handleSetPriceTypeWithTerms}
                  priceTypes={priceTypes as Option[]}
                  termsAndConditions={termsAndConditions as Option[]}
                />
              )}
              <Caption>
                Note: Styles without a price in the above currency will not be
                available to this connection
              </Caption>
            </div>
          </Modal.Content>
          <Modal.Actions
            primaryButton={{
              text: 'Save',
              handler: async () => {
                const input = {
                  retailerId,
                  brandId,
                  priceTypes: priceTypeWithTerms,
                }
                const success = await handleAcceptConnection({
                  input,
                  retailerName,
                })
                if (success) refetchProfileData()
                onClose()
              },
              disabled: dissabledButton,
            }}
          />
        </StyledModal>
      </ModalProvider>
    </Portal>
  )
}
