import isUndefined from 'lodash/isUndefined'
import { handleActions } from 'redux-actions'

import {
  calculateTotalRowPrices,
  calculateValues,
  getNewTotalRow,
  getTotalUnits,
  getUnitsFromRowValues,
  getUpdatedUnitsRow,
  updateAmount,
  updatePrices,
} from 'store/shop/utils'

import {
  BULK_PANEL_POSITION,
  SIZED_PANEL_POSITION,
} from 'modals/Shop/ProductDetailTableWrapper/ProductDetailTableWrapper.component'
import * as configuration from 'modals/Shop/TablesConfiguration'
import {
  BULK_COLUMN_ID,
  BULK_TABLE_ID,
  PRICE_ROW_ID,
  QUANTITY_COLUMN_ID,
  SIZED_TABLE_ID,
  SUGG_RETAILER_COLUMN_ID,
  TOTAL_ROW_ID,
  TOTAL_SECTION_ID,
  UNITS_AVAILABLE_ROW_ID,
  VARIANT_ROW_ID,
  WHOLESALE_COLUMN_ID,
} from 'modals/Shop/TablesConfiguration'

import {
  INIT_TABLE_ROW,
  INIT_TABLE_STRUCTURE,
  INIT_TABLE_TOTAL_ROW_STRUCTURE,
  PASTE_TO_ROW,
  RESET_TABLE_TO_DEFAULT_STATE,
  SET_ROW_TO_COPY,
  SET_SELECTED_TAB_ID,
  SET_WAREHOUSE_DELIVERY_DATE,
  SET_WAREHOUSE_ID,
  UPDATE_CELL_VALUE,
} from './constants'

export const defaultState = {
  [configuration.BULK_TABLE_ID]: {},
  [configuration.SIZED_TABLE_ID]: {},
  selectedTabId: configuration.SIZED_TABLE_ID,
  warehouseDeliveryDate: null,
  warehouseId: null,
  rowToCopy: null,
  tablesInitialization: {
    [configuration.BULK_TABLE_ID]: {
      [configuration.PRICE_ROW_ID]: false,
    },
    [configuration.SIZED_TABLE_ID]: {
      [configuration.PRICE_ROW_ID]: false,
      [configuration.UNITS_AVAILABLE_ROW_ID]: false,
    },
  },
}

const getCommonData = (state, payload) => {
  const { variantId, door } = state.rowToCopy
  const { selectedTabId } = state
  const { variantId: destinationVariantId, door: destinationDoor } = payload
  const sourceRow = state[selectedTabId][variantId]
  const destinationRow = state[selectedTabId][destinationVariantId]
  const totalRow = state[selectedTabId][TOTAL_SECTION_ID][TOTAL_ROW_ID]
  const sourceRowValues = door ? sourceRow[door] : sourceRow[VARIANT_ROW_ID]
  const destinationRowValues = destinationDoor
    ? destinationRow[destinationDoor]
    : destinationRow[VARIANT_ROW_ID]
  return {
    destinationRow,
    totalRow,
    sourceRowValues,
    destinationRowValues,
  }
}

const handleBulkTablePaste = (state, payload) => {
  const { door } = state.rowToCopy
  const { variantId: destinationVariantId, door: destinationDoor } = payload
  const {
    destinationRow,
    totalRow,
    sourceRowValues,
    destinationRowValues,
  } = getCommonData(state, payload)
  const quantity = sourceRowValues[QUANTITY_COLUMN_ID]
  const destinationRowId = door ? destinationDoor : VARIANT_ROW_ID
  const previousWholesaleValue = destinationRowValues[WHOLESALE_COLUMN_ID]
  const previousRetailValue = destinationRowValues[SUGG_RETAILER_COLUMN_ID]
  const destinationPrices = destinationRow[PRICE_ROW_ID][BULK_COLUMN_ID]
  const [newWholeSaleValue, newRetailValue] = calculateValues(
    destinationPrices,
    quantity,
  )
  return {
    ...state,
    [BULK_TABLE_ID]: {
      ...state[BULK_TABLE_ID],
      [destinationVariantId]: {
        ...destinationRow,
        [destinationRowId]: {
          ...destinationRow[destinationRowId],
          [BULK_COLUMN_ID]: sourceRowValues[BULK_COLUMN_ID],
          [QUANTITY_COLUMN_ID]: sourceRowValues[QUANTITY_COLUMN_ID],
          [WHOLESALE_COLUMN_ID]: newWholeSaleValue,
          [SUGG_RETAILER_COLUMN_ID]: newRetailValue,
        },
      },
      [TOTAL_SECTION_ID]: {
        [TOTAL_ROW_ID]: {
          ...state[BULK_TABLE_ID][TOTAL_SECTION_ID][TOTAL_ROW_ID],
          [BULK_COLUMN_ID]: updateAmount(
            totalRow[BULK_COLUMN_ID],
            sourceRowValues[BULK_COLUMN_ID],
            destinationRowValues[BULK_COLUMN_ID],
          ),
          [QUANTITY_COLUMN_ID]: updateAmount(
            totalRow[QUANTITY_COLUMN_ID],
            sourceRowValues[QUANTITY_COLUMN_ID],
            destinationRowValues[QUANTITY_COLUMN_ID],
          ),
          [WHOLESALE_COLUMN_ID]: updatePrices(
            totalRow[WHOLESALE_COLUMN_ID],
            newWholeSaleValue,
            previousWholesaleValue,
          ),
          [SUGG_RETAILER_COLUMN_ID]: updatePrices(
            totalRow[SUGG_RETAILER_COLUMN_ID],
            newRetailValue,
            previousRetailValue,
          ),
        },
      },
    },
  }
}

const handleSizedTablePaste = (state, payload) => {
  const { door } = state.rowToCopy
  const {
    destinationRow,
    totalRow,
    sourceRowValues,
    destinationRowValues,
  } = getCommonData(state, payload)
  const { variantId: destinationVariantId, door: destinationDoor } = payload
  const destinationRowId = door ? destinationDoor : VARIANT_ROW_ID
  const previousWholesaleValue = destinationRowValues[WHOLESALE_COLUMN_ID]
  const previousRetailValue = destinationRowValues[SUGG_RETAILER_COLUMN_ID]
  const destinationPrices = destinationRow[PRICE_ROW_ID]
  const sourceUnitValues = getUnitsFromRowValues(sourceRowValues)
  const destinationUnitValues = getUnitsFromRowValues(destinationRowValues)
  const totalRowUnitValues = getUnitsFromRowValues(totalRow)
  const unitsRow = destinationRow[UNITS_AVAILABLE_ROW_ID]
  const [newWholeSaleValue, newRetailValue] = calculateTotalRowPrices(
    sourceUnitValues,
    destinationPrices,
  )
  return {
    ...state,
    [SIZED_TABLE_ID]: {
      ...state[SIZED_TABLE_ID],
      [destinationVariantId]: {
        ...destinationRow,
        [destinationRowId]: {
          ...destinationRow[destinationRowId],
          [QUANTITY_COLUMN_ID]: sourceRowValues[QUANTITY_COLUMN_ID],
          [WHOLESALE_COLUMN_ID]: newWholeSaleValue,
          [SUGG_RETAILER_COLUMN_ID]: newRetailValue,
          ...sourceUnitValues,
        },
        [UNITS_AVAILABLE_ROW_ID]: {
          ...destinationRow[UNITS_AVAILABLE_ROW_ID],
          ...getUpdatedUnitsRow(
            unitsRow,
            sourceUnitValues,
            destinationUnitValues,
          ),
        },
      },
      [TOTAL_SECTION_ID]: {
        [TOTAL_ROW_ID]: {
          ...state[SIZED_TABLE_ID][TOTAL_SECTION_ID][TOTAL_ROW_ID],
          ...getNewTotalRow(
            totalRow,
            {
              newQuantity: sourceRowValues[QUANTITY_COLUMN_ID],
              newWholeSaleValue,
              newRetailValue,
            },
            {
              previousQuantity: destinationRowValues[QUANTITY_COLUMN_ID],
              previousWholesaleValue,
              previousRetailValue,
            },
          ),
          ...getTotalUnits(
            totalRowUnitValues,
            sourceUnitValues,
            destinationUnitValues,
          ),
        },
      },
    },
  }
}

const reducer = handleActions(
  {
    [INIT_TABLE_STRUCTURE]: (state, { payload }) => {
      const {
        tableId,
        sectionsIds,
        dynamicRowsIds,
        dynamicColumnsIds,
      } = payload

      return {
        ...state,
        [tableId]: configuration.initializeTable(
          sectionsIds,
          dynamicRowsIds,
          dynamicColumnsIds,
        ),
      }
    },
    [INIT_TABLE_TOTAL_ROW_STRUCTURE]: (state, { payload }) => {
      const { tableId, dynamicColumnsIds } = payload
      const { TOTAL_SECTION_ID, TOTAL_ROW_ID, initTableColumns } = configuration
      return {
        ...state,
        [tableId]: {
          ...state[tableId],
          [TOTAL_SECTION_ID]: {
            [TOTAL_ROW_ID]: initTableColumns(dynamicColumnsIds),
          },
        },
      }
    },
    [UPDATE_CELL_VALUE]: (state, { payload }) => {
      const { tableId, sectionId, rowId, columnId, value } = payload
      const columnValue = configuration.getCellValueFromTable({
        table: state[tableId],
        sectionId,
        rowId,
        columnId,
      })
      if (!isUndefined(columnValue)) {
        return {
          ...state,
          [tableId]: {
            ...state[tableId],
            [sectionId]: {
              ...state[tableId][sectionId],
              [rowId]: {
                ...state[tableId][sectionId][rowId],
                [columnId]: value,
              },
            },
          },
        }
      }

      return state
    },
    [RESET_TABLE_TO_DEFAULT_STATE]: (state, { payload }) => {
      const { tableId } = payload
      if (state[tableId]) {
        return {
          ...state,
          [tableId]: defaultState[tableId],
          tablesInitialization: {
            ...state.tablesInitialization,
            [tableId]: defaultState.tablesInitialization[tableId],
          },
          warehouseDeliveryDate: defaultState.warehouseDeliveryDate,
          warehouseId: defaultState.warehouseId,
        }
      }
      return state
    },
    [SET_SELECTED_TAB_ID]: (state, { payload: selectedTabId }) => {
      const { BULK_TABLE_ID, SIZED_TABLE_ID } = configuration
      switch (selectedTabId) {
        case BULK_PANEL_POSITION:
          return {
            ...state,
            selectedTabId: BULK_TABLE_ID,
            rowToCopy: null,
          }
        case SIZED_PANEL_POSITION:
        default:
          return {
            ...state,
            selectedTabId: SIZED_TABLE_ID,
            rowToCopy: null,
          }
      }
    },
    [INIT_TABLE_ROW]: (state, { payload }) => {
      const { tableId, rowId, value } = payload
      return {
        ...state,
        tablesInitialization: {
          ...state.tablesInitialization,
          [tableId]: {
            ...state.tablesInitialization[tableId],
            [rowId]: value,
          },
        },
      }
    },
    [SET_WAREHOUSE_DELIVERY_DATE]: (state, { payload }) => ({
      ...state,
      warehouseDeliveryDate: payload,
    }),
    [SET_WAREHOUSE_ID]: (state, { payload }) => ({
      ...state,
      warehouseId: payload,
    }),
    [SET_ROW_TO_COPY]: (state, { payload }) => ({
      ...state,
      rowToCopy: payload,
    }),
    [PASTE_TO_ROW]: (state, { payload }) => {
      if (!state.rowToCopy) {
        return state
      }
      if (state.selectedTabId === BULK_TABLE_ID) {
        return handleBulkTablePaste(state, payload)
      }
      return handleSizedTablePaste(state, payload)
    },
  },
  defaultState,
)

export default reducer
