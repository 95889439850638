import { handleActions } from 'redux-actions'

import {
  initialFilterReducerState,
  unselectedFilterReducerState,
} from 'utils/filters/utils'

import { ordersSelectionFilters } from 'containers/OrdersSelectionFilters/filters'

export const defaultState = {
  selectedFilters: {
    ...initialFilterReducerState(ordersSelectionFilters),
  },
  appliedFilters: {
    ...initialFilterReducerState(ordersSelectionFilters),
  },
  searchFilter: '',
}

const reducer = handleActions(
  {
    'ORDERS_SELECTION_FILTER/TOGGLE_SELECT_FILTER': (state, { payload }) => {
      const { dataKey } = payload
      const filterSettings = ordersSelectionFilters[dataKey]
      const { reducerHandler } = filterSettings
      return {
        ...state,
        selectedFilters: reducerHandler(state.selectedFilters, { ...payload }),
      }
    },
    'ORDERS_SELECTION_FILTER/OVERRIDE_APPLY_FILTERS': (state, { payload }) => {
      const { dataKey, filters } = payload
      const overriddenSelectedFilters = {
        ...state.selectedFilters,
        [dataKey]: filters,
      }
      return {
        ...state,
        selectedFilters: overriddenSelectedFilters,
        appliedFilters: overriddenSelectedFilters,
      }
    },
    'ORDERS_SELECTION_FILTER/TOGGLE_DESELECT_ALL_FILTERS': (state) => ({
      ...state,
      selectedFilters: {
        ...state.selectedFilters,
        ...unselectedFilterReducerState(ordersSelectionFilters),
      },
    }),
    'ORDERS_SELECTION_FILTER/APPLY_SELECTED_FILTERS': (state) => ({
      ...state,
      appliedFilters: { ...state.selectedFilters },
    }),
    'ORDERS_SELECTION_FILTER/APPLY_SEARCH_FILTER': (
      state,
      { payload: searchText },
    ) => ({
      ...state,
      searchFilter: searchText.trim(),
    }),
  },
  defaultState,
)

export default reducer
