// NOTE: TagManager has been configured per https://github.com/alinemorelli/react-gtm
import TagManager from 'react-gtm-module'

const REACT_APP_GTM_ID = process.env.REACT_APP_GTM_ID
const tagManagerArgs = { gtmId: REACT_APP_GTM_ID }
const bootstrapGTM = () =>
  REACT_APP_GTM_ID &&
  REACT_APP_GTM_ID.length > 0 &&
  TagManager.initialize(tagManagerArgs)

export default bootstrapGTM
