import { createAction } from 'redux-actions'

import {
  CHANGE_BRAND_SEARCH_TEXT,
  CHANGE_COLLECTION_SEARCH_TEXT,
  SET_BRAND_ID,
} from './constants'

export const changeBrandSearchText = createAction(CHANGE_BRAND_SEARCH_TEXT)
export const setBrandId = createAction(SET_BRAND_ID)
export const changeCollectionSearchText = createAction(
  CHANGE_COLLECTION_SEARCH_TEXT,
)
