import styled from 'styled-components'

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    gap: 0;
  }
`
export const LeftButtonContainer = styled.div``
export const RightButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    > button {
      width: 100%;
    }
  }
`
export const StyledSecondaryAccountButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  :hover {
    > span > span > svg > g {
      fill: var(--color-secondary-600);
    }
  }
  > span > span > svg > g {
    fill: var(--color-secondary-800);
  }
`
