import uniqueId from 'lodash/uniqueId'
import { createAction } from 'redux-actions'

export const payloadCreator = (message, options, id = uniqueId('message_')) => {
  const defaultOptions = {
    long: false,
    link: {
      pathname: null,
      linkText: null,
      search: null,
      baseURL: null,
      hash: null,
    },
  }
  const optionsForNotification = {
    ...defaultOptions,
    ...options,
  }
  return {
    message,
    options: optionsForNotification,
    id,
    type: optionsForNotification.type,
  }
}

export const renderSuccessNotification = createAction(
  'NOTIFICATION_HANDLER/SUCCESS',
  payloadCreator,
)
export const resetNotification = createAction('NOTIFICATION_HANDLER/RESET')
export const renderErrorNotification = createAction(
  'NOTIFICATION_HANDLER/ERROR',
  payloadCreator,
)
export const renderNotification = createAction(
  'NOTIFICATION_HANDLER/DEFAULT',
  payloadCreator,
)
