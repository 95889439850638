export const CONNECTED_RETAILER = 'CONNECTED_RETAILER'
export const DISCONNECTED_RETAILER = 'DISCONNECTED_RETAILER'
export const LOGGED_OUT_USER = 'LOGGED_OUT_USER'

export const PUBLIC_VISIBILITY = 'PUBLIC'
export const VISIBLE_TO_ALL_RETAILERS = 'ALL_CONNECTIONS'
export const SELECTED_CONNECTIONS = 'SELECTED_CONNECTIONS'

export const ONLINE = 'ONLINE'
export const OFFLINE = 'OFFLINE'

// StorefrontAddRowModal permissions for widgets
export const STOREFRONT_PERMISSION = 0
export const SHOWROOM_PERMISSION = 1

export const OVERLAY = 'OVERLAY'
// row template distributions
export const ONE_FULL_WIDTH = 'ONE_FULL_WIDTH'
export const ONE_REGULAR = 'ONE_REGULAR'
export const TWO_EQUAL = 'TWO_EQUAL'
export const TWO_LEFT_WIDE = 'TWO_LEFT_WIDE'
export const TWO_RIGHT_WIDE = 'TWO_RIGHT_WIDE'
export const THREE_EQUAL = 'THREE_EQUAL'
export const FOUR_EQUAL = 'FOUR_EQUAL'
export const SIX_EQUAL = 'SIX_EQUAL'
export const EIGHT_EQUAL = 'EIGHT_EQUAL'
export const NINE_EQUAL = 'NINE_EQUAL'
export const TWELVE_EQUAL = 'TWELVE_EQUAL'

//template types
export const MEDIA_BANNER = 'MEDIA_BANNER'
export const TEXT_BANNER = 'TEXT_BANNER'
export const SHOWROOM_BRAND_GALLERY = 'SHOWROOM_BRAND_GALLERY'
export const IMAGE_GALLERY = 'IMAGE_GALLERY'
export const VIDEO_GALLERY = 'VIDEO_GALLERY'
export const DOCUMENT_GALLERY = 'DOCUMENT_GALLERY'
export const ORB360_GALLERY = 'ORB360_GALLERY'
export const IMAGE_TEXT = 'IMAGE_TEXT'
export const VIDEO_TEXT = 'VIDEO_TEXT'
export const DOCUMENT_TEXT = 'DOCUMENT_TEXT'
export const ORB360_TEXT = 'ORB360_TEXT'
export const PRODUCT_GALLERY = 'PRODUCT_GALLERY'
export const LINESHEET_GALLERY = 'LINESHEET_GALLERY'
export const LINESHEET_TEXT = 'LINESHEET_TEXT'
export const ABOUT_BRAND = 'ABOUT_BRAND'
export const ABOUT_SHOWROOM = 'ABOUT_SHOWROOM'
export const SOCIAL = 'SOCIAL'
export const COLLECTION_GALLERY = 'COLLECTION_GALLERY'
export const COLLECTION_TEXT = 'COLLECTION_TEXT'
export const GALLERY_TEMPLATES = [
  IMAGE_GALLERY,
  VIDEO_GALLERY,
  SHOWROOM_BRAND_GALLERY,
  DOCUMENT_GALLERY,
  ORB360_GALLERY,
  PRODUCT_GALLERY,
  LINESHEET_GALLERY,
  COLLECTION_GALLERY,
]
export const getRowTemplate = (rowDistribution, templateType) =>
  `${rowDistribution}*${templateType}`

export const DISTRIBUTIONS = [
  ONE_FULL_WIDTH,
  ONE_REGULAR,
  TWO_EQUAL,
  TWO_LEFT_WIDE,
  TWO_RIGHT_WIDE,
  THREE_EQUAL,
  FOUR_EQUAL,
  SIX_EQUAL,
  EIGHT_EQUAL,
  NINE_EQUAL,
  TWELVE_EQUAL,
]
export const DISTRIBUTION_SIZE = {
  [ONE_FULL_WIDTH]: 1,
  [ONE_REGULAR]: 1,
  [TWO_EQUAL]: 2,
  [TWO_LEFT_WIDE]: 2,
  [TWO_RIGHT_WIDE]: 2,
  [THREE_EQUAL]: 3,
  [FOUR_EQUAL]: 4,
  [SIX_EQUAL]: 6,
  [EIGHT_EQUAL]: 8,
  [NINE_EQUAL]: 9,
  [TWELVE_EQUAL]: 12,
}

// widget types
export const TEXT_WIDGET = 'TEXT'
export const IMAGE_WIDGET = 'IMAGE'
export const VIDEO_WIDGET = 'VIDEO'
export const DOCUMENT_WIDGET = 'LOOKBOOK'
export const ORB_WIDGET = 'ORB360'
export const PRODUCT_WIDGET = 'PRODUCT'
export const LINESHEET_WIDGET = 'COLLECTION'
export const INFO_WIDGET = 'ABOUT'
export const INSTAGRAM_WIDGET = 'INSTAGRAM'
export const SHOWROOM_BRAND_WIDGET = 'SHOWROOM_BRAND'
export const COLLECTIONS_WIDGET = 'SHOWROOM_COLLECTION'

export const WIDGET_TO_ROWTYPE = {
  [IMAGE_WIDGET]: IMAGE_GALLERY,
  [VIDEO_WIDGET]: VIDEO_GALLERY,
  [DOCUMENT_WIDGET]: DOCUMENT_GALLERY,
  [ORB_WIDGET]: ORB360_GALLERY,
  [PRODUCT_WIDGET]: PRODUCT_GALLERY,
  [LINESHEET_WIDGET]: LINESHEET_GALLERY,
  [SHOWROOM_BRAND_WIDGET]: SHOWROOM_BRAND_GALLERY,
  [COLLECTIONS_WIDGET]: COLLECTION_GALLERY,
}

export const TEXT_DEFAULT_VALUE = {
  ops: [
    { insert: 'Title' },
    { attributes: { styles: 'title1' }, insert: '\n' },
    { insert: 'Paragraph' },
    { attributes: { styles: 'paragraph1' }, insert: '\n' },
  ],
}

export const TEXT_BANNER_DEFAULT_VALUE = {
  ops: [
    { insert: 'Title' },
    { attributes: { styles: 'title1', align: 'center' }, insert: '\n' },
    { insert: 'Paragraph' },
    { attributes: { styles: 'paragraph1', align: 'center' }, insert: '\n' },
  ],
}

export const TEXT_DEFAULT_OVERLAY = {
  ops: [
    {
      attributes: {
        color: '#ffffff',
      },
      insert: 'Overlay Text',
    },
    {
      attributes: {
        align: 'center',
        'line-height': '1',
        styles: 'heading1',
      },
      insert: '\n',
    },
  ],
  overlayText: true,
}

// introduce widgets as default content below
export const WIDGET_CONTENTS = {
  [IMAGE_WIDGET]: {},
  [TEXT_WIDGET]: JSON.stringify(TEXT_DEFAULT_VALUE),
  [DOCUMENT_WIDGET]: {},
  [VIDEO_WIDGET]: {},
  [ORB_WIDGET]: {},
  [PRODUCT_WIDGET]: {},
  [LINESHEET_WIDGET]: {},
  [INFO_WIDGET]: 'info',
  [INSTAGRAM_WIDGET]: {},
  [COLLECTIONS_WIDGET]: {},
  [SHOWROOM_BRAND_WIDGET]: {},
}

// configurations for adding new row modal
export const HEADLINE_ITEMS = {
  title: 'Headline',
  subtitle:
    'Make a bold first impression with a full-width text, image, or video',
  items: [
    {
      name: 'Media Banner',
      icon: 'mediaBanner',
      type: ONE_FULL_WIDTH,
      rowType: MEDIA_BANNER,
      //IMAGE_WIDGET always before TEXT_WIDGET for css purposes
      contentTypes: [IMAGE_WIDGET, TEXT_WIDGET],
      defaultContents: [undefined, JSON.stringify(TEXT_DEFAULT_OVERLAY)],
      userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
    },
    {
      name: 'Text Banner',
      icon: 'textBanner',
      type: ONE_FULL_WIDTH,
      rowType: TEXT_BANNER,
      contentTypes: [TEXT_WIDGET],
      defaultContents: [JSON.stringify(TEXT_BANNER_DEFAULT_VALUE)],
      userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
    },
  ],
  userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
}

export const BRAND_DIRECTORY_ITEMS = {
  title: 'Brand Directory',
  subtitle: 'Showcase associated brands',
  items: [
    {
      name: 'Brand Directory',
      icon: 'brandDirectory',
      type: FOUR_EQUAL,
      rowType: SHOWROOM_BRAND_GALLERY,
      contentTypes: new Array(4).fill(SHOWROOM_BRAND_WIDGET),
    },
  ],
  userPermissions: [SHOWROOM_PERMISSION],
}

export const GALLERY_ITEMS = {
  title: 'Media',
  subtitle:
    'Showcase images, videos, ORB360, and documents in various gallery templates that fit your storeront',
  items: [
    {
      name: 'Image Gallery',
      icon: 'imageGallery',
      type: FOUR_EQUAL,
      rowType: IMAGE_GALLERY,
      contentTypes: new Array(4).fill(IMAGE_WIDGET),
      userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
    },
    {
      name: 'Video Gallery',
      icon: 'videoGallery',
      type: ONE_REGULAR,
      rowType: VIDEO_GALLERY,
      contentTypes: [VIDEO_WIDGET],
      userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
    },
    {
      name: 'Document Gallery',
      icon: 'lookbookGallery',
      type: TWO_EQUAL,
      rowType: DOCUMENT_GALLERY,
      contentTypes: new Array(2).fill(DOCUMENT_WIDGET),
      userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
    },
    {
      name: 'ORB360 Gallery',
      icon: 'orb360Gallery',
      type: FOUR_EQUAL,
      rowType: ORB360_GALLERY,
      contentTypes: new Array(4).fill(ORB_WIDGET),
      userPermissions: [STOREFRONT_PERMISSION],
    },
    {
      name: 'Image + Text',
      icon: 'imageText',
      type: TWO_EQUAL,
      rowType: IMAGE_TEXT,
      contentTypes: [IMAGE_WIDGET, TEXT_WIDGET],
      userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
    },
    {
      name: 'Video + Text',
      icon: 'videoText',
      type: TWO_EQUAL,
      rowType: VIDEO_TEXT,
      contentTypes: [VIDEO_WIDGET, TEXT_WIDGET],
      userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
    },
    {
      name: 'Document + Text',
      icon: 'lookbookText',
      type: TWO_EQUAL,
      rowType: DOCUMENT_TEXT,
      contentTypes: [DOCUMENT_WIDGET, TEXT_WIDGET],
      userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
    },
    {
      name: 'ORB360 + Text',
      icon: 'orb360Text',
      type: TWO_EQUAL,
      rowType: ORB360_TEXT,
      contentTypes: [ORB_WIDGET, TEXT_WIDGET],
      userPermissions: [STOREFRONT_PERMISSION],
    },
  ],
  userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
}

export const SHOP_ITEMS = {
  title: 'Shop',
  subtitle:
    'Display products and linesheets to highlight the styles that represent your brand',
  items: [
    {
      name: 'Product Gallery',
      icon: 'productGallery',
      type: FOUR_EQUAL,
      rowType: PRODUCT_GALLERY,
      contentTypes: new Array(4).fill(PRODUCT_WIDGET),
    },
    {
      name: 'Linesheet Gallery',
      icon: 'linesheetGallery',
      type: TWO_EQUAL,
      rowType: LINESHEET_GALLERY,
      contentTypes: new Array(2).fill(LINESHEET_WIDGET),
    },
    {
      name: 'Linesheet + Text',
      icon: 'linesheetText',
      type: TWO_EQUAL,
      rowType: LINESHEET_TEXT,
      contentTypes: [LINESHEET_WIDGET, TEXT_WIDGET],
    },
  ],
  userPermissions: [STOREFRONT_PERMISSION],
}
export const INFO_ITEMS = {
  title: 'Info',
  subtitle: 'Tell retailers about the brand',
  items: [
    {
      name: 'About the brand',
      icon: 'aboutBrand',
      type: ONE_REGULAR,
      rowType: ABOUT_BRAND,
      contentTypes: [INFO_WIDGET],
      userPermissions: [STOREFRONT_PERMISSION],
    },
    {
      name: 'About the Showroom',
      icon: 'aboutBrand',
      type: ONE_REGULAR,
      rowType: ABOUT_SHOWROOM,
      contentTypes: [INFO_WIDGET],
      userPermissions: [SHOWROOM_PERMISSION],
    },
  ],
  userPermissions: [STOREFRONT_PERMISSION, SHOWROOM_PERMISSION],
}

export const EXPERIENCE_ITEMS = {
  title: 'Experience',
  subtitle: 'Display your collections from The Edit',
  items: [
    {
      name: 'The Edit Gallery',
      icon: 'collectionGallery',
      type: TWO_EQUAL,
      rowType: COLLECTION_GALLERY,
      contentTypes: new Array(2).fill(COLLECTIONS_WIDGET),
    },
    {
      name: 'The Edit + Text',
      icon: 'collectionText',
      type: TWO_EQUAL,
      rowType: COLLECTION_TEXT,
      contentTypes: [COLLECTIONS_WIDGET, TEXT_WIDGET],
    },
  ],
  userPermissions: [STOREFRONT_PERMISSION],
}

export const THE_EDIT_TITLES = ['The Edit Gallery', 'The Edit + Text']
export const ORB360_TITLES = ['ORB360 Gallery', 'ORB360 + Text']

export const NEW_ITEM_OPTIONS = [
  HEADLINE_ITEMS,
  BRAND_DIRECTORY_ITEMS,
  GALLERY_ITEMS,
  SHOP_ITEMS,
  INFO_ITEMS,
  EXPERIENCE_ITEMS,
]

// widget presentation options

export const ASPECT_PORTRAIT = 'PORTRAIT'
export const ASPECT_SQUARE = 'SQUARE'
export const ASPECT_LANDSCAPE = 'LANDSCAPE'
export const WIDE_WIDTH = 'WIDE'
export const MEDIUM_WIDTH = 'MEDIUM'
export const NARROW_WIDTH = 'NARROW'

export const NON_EDITABLE_WIDGETS = { [INFO_WIDGET]: true }

// should update (refetch)
export const UPDATE_STOREFRONT = 'storefront'
export const UPDATE_PROFILE = 'profile'
