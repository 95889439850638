import classNames from 'classnames'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import styles from './Input.less'

const Input = (props) => {
  const { success, icon, onChange, className, ...rest } = props

  const handleChange = (event) => {
    if (onChange) {
      // This value is being set onto props in order to maintain parity with Semantic UI Input
      // We preserved this behaviour in order to not rewrite dependent components, but it's
      // not strictly necessary.
      const value = get(event, 'target.value')
      onChange(event, { ...props, value })
    }
  }

  return (
    <div
      className={classNames(styles.Input, className, {
        [styles.success]: success,
      })}
    >
      <input onChange={handleChange} {...rest} />
      {icon}
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  success: PropTypes.bool,
  type: PropTypes.string,
}

Input.defaultProps = {
  className: null,
  success: false,
  type: 'text',
}

export default Input
