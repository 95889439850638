import ArrayOfIdsFilter from 'utils/filters/arrayOfIdsFilter'

import messages from './ReplaceStyleFilters.messages'

export const replaceStyleFilters = {
  collections: new ArrayOfIdsFilter({
    filterKey: 'collections',
    label: messages.linesheet,
    dataKey: 'collections',
    expanded: true,
  }),
}

export const filterSetup = [
  {
    key: 'collections',
    title: messages.linesheet,
    noTranslate: true,
    filters: [replaceStyleFilters.collections],
  },
]
