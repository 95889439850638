import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './Accordion.less'

export const ListItem = ({
  children,
  selected,
  onClick,
  noTranslate,
  disabled,
  className,
}) => (
  <div
    className={classNames(styles.accordionListItem, className, {
      [styles.accordionListItem__selected]: selected && !disabled,
      [styles.accordionListItem__unselected]: !selected && !disabled,
      [styles.accordionListItem__disabled]: disabled,
      notranslate: noTranslate,
    })}
    onClick={!disabled ? onClick : null}
    role="button"
    tabIndex="0"
    onKeyPress={null}
  >
    {children}
  </div>
)

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  noTranslate: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

ListItem.defaultProps = {
  selected: false,
  onClick: null,
  noTranslate: false,
  disabled: false,
  className: '',
}

export default ListItem
