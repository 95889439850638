import {
  BRAND_START_ORDER_MODAL as FEATURE_PREFIX_BRAND,
  RETAILER_START_ORDER_MODAL as FEATURE_PREFIX_RETAILER,
} from 'featurePrefix.ids'

const START_ORDER_OPTIONS_PREFIX = 'option-dropdown'
const START_ORDER_TITLES_PREFIX = 'option-title'
const START_ORDER_BUTTON_PREFIX = 'option-button'

export const BrandStartOrderIds = {
  ModalTitle: `${FEATURE_PREFIX_BRAND}__${START_ORDER_TITLES_PREFIX}__modal`,
  OrderTypesOptions: `${FEATURE_PREFIX_BRAND}__${START_ORDER_OPTIONS_PREFIX}__orderType`,
  WarehouseOptions: `${FEATURE_PREFIX_BRAND}__${START_ORDER_OPTIONS_PREFIX}__warehouse`,
  PriceTypeOptions: `${FEATURE_PREFIX_BRAND}__${START_ORDER_OPTIONS_PREFIX}__priceType`,
  DoorOptions: `${FEATURE_PREFIX_BRAND}__${START_ORDER_OPTIONS_PREFIX}__doors`,
  RetailerOptions: `${FEATURE_PREFIX_BRAND}__${START_ORDER_OPTIONS_PREFIX}__retailer`,
  CollectionOptions: `${FEATURE_PREFIX_BRAND}__${START_ORDER_OPTIONS_PREFIX}__collection`,
  EventOptions: `${FEATURE_PREFIX_BRAND}__${START_ORDER_OPTIONS_PREFIX}__event`,
  OrderTypesTitle: `${FEATURE_PREFIX_BRAND}__${START_ORDER_TITLES_PREFIX}__orderType`,
  WarehouseTitle: `${FEATURE_PREFIX_BRAND}__${START_ORDER_TITLES_PREFIX}__warehouse`,
  PriceTypeTitle: `${FEATURE_PREFIX_BRAND}__${START_ORDER_TITLES_PREFIX}__priceType`,
  DoorTitle: `${FEATURE_PREFIX_BRAND}__${START_ORDER_TITLES_PREFIX}__door`,
  RetailerTitle: `${FEATURE_PREFIX_BRAND}__${START_ORDER_TITLES_PREFIX}__retailer`,
  CollectionTitle: `${FEATURE_PREFIX_BRAND}__${START_ORDER_TITLES_PREFIX}__collection`,
  EventTitle: `${FEATURE_PREFIX_BRAND}__${START_ORDER_TITLES_PREFIX}__event`,
  AcceptButton: `${FEATURE_PREFIX_BRAND}__${START_ORDER_BUTTON_PREFIX}__start`,
  CancelButton: `${FEATURE_PREFIX_BRAND}__${START_ORDER_BUTTON_PREFIX}__cancel`,
  CloseButton: `${FEATURE_PREFIX_BRAND}__${START_ORDER_BUTTON_PREFIX}__close`,
}

export const RetailerStartOrderIds = {
  ModalTitle: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_TITLES_PREFIX}__modal`,
  BrandOptions: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_OPTIONS_PREFIX}__brand`,
  PriceTypeOptions: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_OPTIONS_PREFIX}__priceType`,
  DoorOptions: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_OPTIONS_PREFIX}___door`,
  CollectionOptions: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_OPTIONS_PREFIX}__collection`,
  PriceTypeTitle: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_TITLES_PREFIX}__priceType`,
  DoorTitle: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_TITLES_PREFIX}__door`,
  CollectionTitle: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_TITLES_PREFIX}__collection`,
  AcceptButton: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_BUTTON_PREFIX}__start`,
  CancelButton: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_BUTTON_PREFIX}__cancel`,
  CloseButton: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_BUTTON_PREFIX}__close`,
  FewerOptions: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_TITLES_PREFIX}__fewerOptions`,
  MoreOptions: `${FEATURE_PREFIX_RETAILER}__${START_ORDER_TITLES_PREFIX}__moreOptions`,
}
