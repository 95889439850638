import { FC } from 'react'

export const IFRAME_TEST_ID = 'iframe'

type IframeProps = {
  autoPlay?: boolean
  className?: string
  disableContextMenu?: boolean
  height: number
  loop?: boolean
  muted?: boolean
  src: string
  testId?: string
  title: string
  width: number
}

const Iframe: FC<IframeProps> = ({
  autoPlay,
  className,
  disableContextMenu,
  height,
  loop,
  muted,
  src,
  title,
  width,
  testId = IFRAME_TEST_ID,
}) => (
  <div
    className={className}
    onContextMenu={(e) => {
      if (disableContextMenu) {
        e.preventDefault()
      }
    }}
  >
    <iframe
      data-testid={testId}
      height={height}
      width={width}
      allow="autoplay"
      src={`${src}${muted ? '&mute=1' : ''}${loop ? '&loop=1' : ''}`}
      title={title}
      {...(autoPlay && { allow: 'autoplay' })}
    />
  </div>
)

export default Iframe
