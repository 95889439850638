import classNames from 'classnames'
import PropTypes from 'prop-types'

import Table from 'components/Core/Table/Table'
import Tooltip from 'components/Core/Tooltip/Tooltip'

import styles from './Indicator.less'

export const RED = 'red'
export const YELLOW = 'yellow'
export const BLUE = 'blue'

const Indicator = ({ className, children }) => (
  <Table.Cell
    width="indicator"
    className={classNames(styles.indicatorCell, className)}
  >
    {children}
  </Table.Cell>
)
Indicator.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
Indicator.defaultProps = {
  className: '',
  children: null,
}

const IndicatorItem = ({ color, tooltip, className }) => (
  <Tooltip
    position="right center"
    trigger={
      <div className={classNames(styles.indicator, styles[color], className)} />
    }
    content={tooltip}
  />
)

IndicatorItem.propTypes = {
  color: PropTypes.oneOf([RED, YELLOW, BLUE]).isRequired,
  tooltip: PropTypes.node.isRequired,
  className: PropTypes.string,
}

IndicatorItem.defaultProps = {
  className: '',
}

Indicator.Item = IndicatorItem

export default Indicator
