import PropTypes from 'prop-types'

import Loader from 'components/Core/Loader/Loader'

const PageLoader = ({ isPageLoaderActive }) => (
  <Loader active={isPageLoaderActive} useFullScreen />
)

PageLoader.propTypes = {
  isPageLoaderActive: PropTypes.bool,
}

PageLoader.defaultProps = {
  isPageLoaderActive: false,
}

export default PageLoader
