import { handleActions } from 'redux-actions'

export const defaultState = {
  displayFormats: {},
}

const reducer = handleActions(
  {
    'PRICE_MODIFIERS/SET_DISPLAY_FORMAT': (state, { payload }) => ({
      displayFormats: {
        ...state.displayFormats,
        ...payload,
      },
    }),
  },
  defaultState,
)

export default reducer
