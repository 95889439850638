import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Checkbox as BaseCheckbox } from 'semantic-ui-react'

import styles from './Checkbox.less'

class Checkbox extends Component {
  state = {
    checked: this.props.checked,
  }

  getChecked = () => {
    if (typeof this.props.checked !== 'undefined') {
      return this.props.checked
    }
    return this.state.checked
  }

  handleOnClick = (event, data) => {
    if (!data.disabled) this.props.onClick(event, data)
  }

  handleOnChange = (event, { checked }) => {
    this.setState({ checked })
  }

  render() {
    const {
      className,
      small,
      restricted,
      noLockWhenDisabled,
      ...rest
    } = this.props

    if (this.props.label && !this.props['aria-label']) {
      rest['aria-label'] = this.props.label
    }
    return (
      <BaseCheckbox
        {...rest}
        checked={this.getChecked()}
        className={classNames(styles.baseCheckbox, className, {
          [styles.small]: small,
          [styles.restricted]: restricted,
          [styles.noLockWhenDisabled]: noLockWhenDisabled,
        })}
        onClick={this.handleOnClick}
        onChange={this.handleOnChange}
      />
    )
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  checked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  noLockWhenDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  radio: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  small: PropTypes.bool,
  /* Indicates that this checkbox's value is restricted in some way relative to its neighbors */
  restricted: PropTypes.bool,
}

Checkbox.defaultProps = {
  className: '',
  checked: undefined,
  label: '',
  disabled: false,
  radio: false,
  value: '',
  small: false,
  restricted: false,
  noLockWhenDisabled: false,
}

export default Checkbox
