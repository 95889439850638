import { CUSTOM_PROFILE as FEATURE_PREFIX } from 'featurePrefix.ids'

const CUSTOM_PROFILE_HEADER = 'custom-profile-page-header'
const CUSTOM_PROFILE_PROFILE_DETAILS_MENU = 'profile-details-menu'

export const HeaderIds = {
  BackToJoorHomeButton: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__back-to-joor-home-button`,
  ViewRetailerOptions: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__view-retailer-options`,
  ViewConnectedRetailerOption: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__view-connected-retailer-option`,
  ViewUnconnectedRetailerOption: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__view-unconnected-retailer-option`,
  ViewLoggedOutUserOption: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__view-logged-out-user-option`,
  SetOnlineOption: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__set-page-online-option`,
  SetOfflineOption: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__set-page-offline-option`,
  EditPageButton: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__edit-page-button`,
  ShowBrandNavButton: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_HEADER}__show-brand-nav-button`,
} as const

export const DetailsMenuIds = {
  SaveButton: `${FEATURE_PREFIX}__${CUSTOM_PROFILE_PROFILE_DETAILS_MENU}__save-button`,
} as const
