import { createAction } from 'redux-actions'

export const setImportedFiles = createAction(
  'EXCEL_ORDERS_MODAL/SET_IMPORTED_FILES',
)
export const setSelectedRetailerId = createAction(
  'EXCEL_ORDERS_MODAL/SET_SELECTED_RETAILER_ID',
)
export const changeRetailerSearchText = createAction(
  'EXCEL_ORDERS_MODAL/CHANGE_RETAILER_SEARCH_TEXT',
)
