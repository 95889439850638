import { ReactPortal, useEffect, useRef, useState } from 'react'

import { createPortal } from 'react-dom'

type RenderInTabProps = {
  children: JSX.Element
}
const RenderInTab = (props: RenderInTabProps): ReactPortal | null => {
  const [container, setContainer] = useState<HTMLElement | null>(null)
  const newWindow = useRef<Window | null>(null)

  useEffect(() => {
    // Create container element on client-side
    setContainer(document.createElement('div'))
  }, [])

  useEffect(() => {
    // When container is ready
    if (container) {
      // Create window
      newWindow.current = window.open('', '_blank')
      // Append container
      newWindow.current?.document.body.appendChild(container)

      // Save reference to window for cleanup
      const curWindow = newWindow.current

      // Return cleanup function
      return () => curWindow?.close()
    }
  }, [container])

  return container && createPortal(props.children, container)
}

export default RenderInTab
