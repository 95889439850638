import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import styles from './Table.less'

const Row = ({
  children,
  className,
  primary,
  secondary,
  tertiary,
  primaryBorder,
  secondaryBorder,
  small,
  medium,
  big,
  skipNavigation,
}) => {
  const extraClasses = {
    [styles.primary]: primary,
    [styles.secondary]: secondary,
    [styles.tertiary]: tertiary,
    [styles.primaryBorder]: primaryBorder,
    [styles.secondaryBorder]: secondaryBorder,
    [styles.small]: small,
    [styles.medium]: medium,
    [styles.big]: big,
  }

  return (
    <tr
      className={classNames(styles.Row, className, extraClasses)}
      data-skip-navigation={skipNavigation || null}
    >
      {children}
    </tr>
  )
}

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  primaryBorder: PropTypes.bool,
  secondaryBorder: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  big: PropTypes.bool,
  skipNavigation: PropTypes.bool,
}

Row.defaultProps = {
  children: null,
  className: '',
  primary: true,
  secondary: false,
  tertiary: false,
  primaryBorder: true,
  secondaryBorder: false,
  small: false,
  medium: false,
  big: false,
  skipNavigation: false,
}

export default Row
