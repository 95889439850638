let editMode = false
const navKeys = ['tab', 'enter', 'f2']

const isHorizontal = (direction) => ['left', 'right'].includes(direction)
const isNegative = (direction) => ['up', 'left'].includes(direction)

const stripArrow = (keyName) => keyName.replace('arrow', '')

const getDirection = {
  tab: (shiftKey) => (shiftKey ? 'left' : 'right'),
  enter: (shiftKey) => (shiftKey ? 'up' : 'down'),
}

const getElementIndex = (target) => {
  const td = target.closest('td')
  const tr = td.closest('tr')
  return [...tr.children].indexOf(td) + 1
}

const moveToDoorTop = (target, shiftKey) => {
  const index = getElementIndex(target)
  const targetIndex = shiftKey ? index - 1 : index + 1
  const container = target.closest('[data-scroll-container="x-axis"]')
  const row = container.querySelector('tr')
  // needs to be 2 separate selectors because all the TDs are in the 4th TD of the overall containing row
  // it's stupid but it works
  const targetElement = row
    .querySelector(`td:nth-of-type(${targetIndex})`)
    .querySelector('input')

  if (targetElement) {
    targetElement.focus()
  }
}

const getNextNonSkippableSibling = (element, selectElementProperty) => {
  let testEl = element
  while (testEl.dataset.skipNavigation) {
    testEl = testEl[selectElementProperty]
    if (!testEl) return null
  }
  return testEl
}

const getContainedElement = (element, selectElementProperty, contains) => {
  let testEl = element
  while (!testEl.querySelector(contains)) {
    testEl = testEl[selectElementProperty]
    if (!testEl) return null
  }
  return testEl
}

const buildElementSiblingFunctionForDirection = (direction) => (
  element,
  contains,
  selfOk,
) => {
  const selectElementProperty = isNegative(direction)
    ? 'previousElementSibling'
    : 'nextElementSibling'
  let testEl = selfOk ? element : element[selectElementProperty]
  if (!testEl) return null
  testEl = getNextNonSkippableSibling(testEl, selectElementProperty)
  if (!contains) return testEl
  testEl = getContainedElement(testEl, selectElementProperty, contains)
  return testEl
}

const getMoveParams = (td, direction) => {
  const horizontal = isHorizontal(direction)
  const getElementSiblingInProperDirection = buildElementSiblingFunctionForDirection(
    direction,
  )
  if (horizontal) {
    return getElementSiblingInProperDirection(td)
  }

  const tr = td.closest('tr')
  const index = getElementIndex(td)
  const target = getElementSiblingInProperDirection(tr)
  if (target) {
    return target.querySelector(`td:nth-child(${index})`)
  }
  const parentTable = tr.closest('table')
  const elementSelector = direction === 'up' ? 'last-of-type' : 'first-of-type'
  if (getElementSiblingInProperDirection(parentTable, 'tr')) {
    return getElementSiblingInProperDirection(parentTable)
      .querySelector(`tr:${elementSelector}`)
      .querySelector(`td:nth-child(${index})`)
  }
  const parentTr = parentTable.closest('tr')
  if (!parentTr) {
    return null
  }
  const targetTr = getElementSiblingInProperDirection(parentTr, 'table')
  if (!targetTr) {
    return null
  }
  const table = getElementSiblingInProperDirection(
    targetTr.querySelector(
      `[data-scroll-container="x-axis"] table:${elementSelector}`,
    ),
    'tr',
    true,
  )
  return (
    table && table.querySelector(`tr:${elementSelector} td:nth-child(${index})`)
  )
}

const moveNext = (direction, el) => {
  const td = el.closest('td')
  const target = getMoveParams(td, direction)
  const input = target && target.querySelector('input')
  if (input) input.focus()
}

export const keyboardNavigationHandler = (e) => {
  const { key, shiftKey, ctrlKey, altKey, target } = e
  if (!target.closest('[data-scroll-container="x-axis"]')) return true

  const keyName = key.toLowerCase()
  const trapArrow = !editMode && keyName.indexOf('arrow') === 0
  const ctrlEnter = ctrlKey && keyName === 'enter'
  if (
    trapArrow ||
    ctrlEnter ||
    (!ctrlKey && !altKey && navKeys.includes(keyName))
  ) {
    e.preventDefault()
    e.stopPropagation()

    if (ctrlEnter) {
      moveToDoorTop(target, shiftKey)
      return false
    }

    if (keyName === 'f2') {
      editMode = !editMode
      if (editMode) {
        target.setSelectionRange(0, 0)
      } else {
        target.setSelectionRange(0, target.value.length)
      }
      return false
    }
    const direction = getDirection[keyName]
      ? getDirection[keyName](shiftKey)
      : stripArrow(keyName)
    moveNext(direction, document.activeElement)
    return false
  }
  return true
}

export const selectAllOnFocus = (e) => {
  const element = e.target
  if (element.tagName === 'INPUT' && element.getAttribute('type') === 'text') {
    element.setSelectionRange(0, element.value.length)
  }
}
