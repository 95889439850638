import { Icon } from '@joor/design-system'
import styled from 'styled-components'

const StyledSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 24px;
  gap: 32px;
`

const StyledTitle = styled.h2`
  && {
    font-family: var(--font-family-alpha);
    font-size: var(--size-font-medium);
    margin: 0;
  }
`

const StyledParagraph = styled.p`
  && {
    font-family: var(--font-family-alpha);
    margin: 0;
  }
`
const StyledWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--color-secondary-200);
  border-radius: 8px;
  border: 1px solid var(--color-secondary-300);
  padding: 18px;
  cursor: auto;
`

const StyledWarningTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  margin-bottom: 16px;
  > h2 {
    margin: 0;
  }
`

const FirstModalStep = () => {
  return (
    <StyledSection>
      <StyledTitle>Welcome to the Account Merge Tool</StyledTitle>
      <StyledParagraph>
        Follow this process to merge your JOOR accounts.
      </StyledParagraph>
      <StyledParagraph>
        A merge means that we will move all data from selected accounts to the
        one of your choosing. Once merged you will <span>only</span> be able to
        access the selected destination account.
      </StyledParagraph>
      <StyledWarning>
        <StyledWarningTitle>
          <Icon iconName="information" />
          <StyledTitle>Please note</StyledTitle>
        </StyledWarningTitle>
        <StyledParagraph>
          Not all accounts can be merged. This process will automatically
          identify what accounts can be merged into your destination account.
        </StyledParagraph>
      </StyledWarning>
    </StyledSection>
  )
}
export default FirstModalStep
