import { useState } from 'react'

import {
  Button,
  LegacyModal,
  LegacyModalProvider,
  Option,
  useNotifications,
} from '@joor/design-system'
import { useDispatch } from 'react-redux'

import { Account } from '__generated__/atlas-types'

import { fromGlobalId } from 'utils/transformations/graphql'

import { setPageLoaderActive } from 'store/pageLoader/actions'

import { useOldSetSelectedAccount } from '../accountsDropdown.hooks'
import FirstModalStep from './FirstModalStep'
import FourthModalStep from './FourthModalStep'
import {
  useCheckMergeCriteria,
  useMergeAccounts,
} from './MergeAccountsModal.hooks'
import {
  ModalWrapper,
  StyledModalContent,
  StyledStep,
  StyledSteps,
} from './MergeAccountsModal.styles'
import {
  getAccountOptions,
  getExcludedAccounts,
} from './MergeAccountsModal.utils'
import SecondModalStep from './SecondModalStep'
import ThirdModalStep from './ThirdModalStep'

const INFORMATION_STEP = 1
const ACCOUNT_SELECTION_STEP = 2
const CONFIRMATION_STEP = 3
const FINAL_MERGING_STEP = 4
const STEPS = [
  INFORMATION_STEP,
  ACCOUNT_SELECTION_STEP,
  CONFIRMATION_STEP,
  FINAL_MERGING_STEP,
]

export type GrapheneAccount = Account & { assortmentPlanning?: boolean }
type MergeAccountsModalProps = {
  isOpen: boolean
  setOpen: (value: boolean) => void
  accounts: GrapheneAccount[]
  accountId: string
}

const MergeAccountsModal: React.FC<MergeAccountsModalProps> = ({
  isOpen,
  setOpen,
  accounts,
  accountId,
}) => {
  const [currentStep, setCurrentStep] = useState(1)
  const [primaryAccount, setPrimaryAccount] = useState<Option | null>(null)
  const [secondaryAccounts, setSecondaryAccounts] = useState<Option[]>([])
  const [mergeAcceptance, setMergeAcceptance] = useState<boolean>(false)

  const secondaryAccountIds = secondaryAccounts.map(
    (secondaryAccount) => secondaryAccount.value,
  )
  const {
    loading: checkMergingAccountCriteriaLoading,
    allowedAccounts,
  } = useCheckMergeCriteria(primaryAccount?.value, secondaryAccountIds)
  const { mergeAccounts, loading } = useMergeAccounts()
  const setSelectedAccount = useOldSetSelectedAccount()
  const dispatch = useDispatch()
  const { addToasts } = useNotifications()

  const options = getAccountOptions(accounts)
  const excludedAccounts = getExcludedAccounts({
    options,
    allowedAccounts,
    primaryAccount,
  })

  const handleRedirect = () => {
    setSelectedAccount({
      variables: {
        input: { account_id: primaryAccount?.value },
      },
    }).then((response) => {
      if (response.data.setSelectedAccount?.success) {
        dispatch(setPageLoaderActive(true))
        window.location.href = '/'
      } else {
        dispatch(setPageLoaderActive(true))
        addToasts([
          {
            title: 'Redirection issue',
            description: 'There was an error setting the selected account',
            type: 'error',
          },
        ])
      }
    })
  }
  const handleClose = () => {
    if (currentStep === FINAL_MERGING_STEP) {
      primaryAccount?.value &&
      fromGlobalId(primaryAccount?.value)?.id !== accountId.toString()
        ? handleRedirect()
        : window.location.reload()
    }
    setCurrentStep(1)
    setOpen(false)
    setPrimaryAccount(null)
    setSecondaryAccounts([])
    setMergeAcceptance(false)
  }

  const backButton = (
    <Button
      onClick={() => {
        setCurrentStep(currentStep - 1)
        setPrimaryAccount(null)
        setSecondaryAccounts([])
      }}
      disabled={loading}
    >
      Back
    </Button>
  )
  const closeButton = <Button onClick={handleClose}>Close</Button>

  const continueButton = (
    <Button
      onClick={() => {
        setCurrentStep(currentStep + 1)
      }}
      disabled={
        currentStep === ACCOUNT_SELECTION_STEP
          ? !primaryAccount?.value ||
            !secondaryAccounts.length ||
            checkMergingAccountCriteriaLoading
          : false
      }
    >
      Continue
    </Button>
  )
  const mergeButton = (
    <Button
      onClick={() => {
        setCurrentStep(currentStep + 1)
        mergeAccounts(
          primaryAccount?.value,
          secondaryAccounts.map((secondaryAccount) => secondaryAccount?.value),
        )
      }}
      disabled={!mergeAcceptance}
    >
      Merge
    </Button>
  )

  const getModalContent = () => {
    switch (currentStep) {
      case 2:
        return (
          <SecondModalStep
            accounts={options}
            excludedAccounts={excludedAccounts}
            primaryAccount={primaryAccount}
            setPrimaryAccount={setPrimaryAccount}
            secondaryAccounts={secondaryAccounts}
            setSecondaryAccounts={setSecondaryAccounts}
            loading={checkMergingAccountCriteriaLoading}
          />
        )
      case 3:
        return (
          <ThirdModalStep
            secondaryAccounts={secondaryAccounts}
            mergeAcceptance={mergeAcceptance}
            setMergeAcceptance={setMergeAcceptance}
          />
        )
      case 4:
        return <FourthModalStep />
      default:
        return <FirstModalStep />
    }
  }
  const getButtons = () => {
    switch (currentStep) {
      case 2:
        return (
          <>
            {backButton}
            {continueButton}
          </>
        )
      case 3:
        return (
          <>
            {backButton}
            {mergeButton}
          </>
        )
      case 4:
        return closeButton
      default:
        return continueButton
    }
  }

  return (
    <ModalWrapper>
      <LegacyModalProvider>
        <LegacyModal isOpen={isOpen} onClose={handleClose}>
          <LegacyModal.Title>Merge Accounts</LegacyModal.Title>
          <LegacyModal.Content>
            <StyledModalContent>
              <StyledSteps>
                {STEPS.map((step) => (
                  <StyledStep $selected={step === currentStep} key={step}>
                    {step}
                  </StyledStep>
                ))}
              </StyledSteps>
              {getModalContent()}
            </StyledModalContent>
          </LegacyModal.Content>
          <LegacyModal.Actions>{getButtons()}</LegacyModal.Actions>
        </LegacyModal>
      </LegacyModalProvider>
    </ModalWrapper>
  )
}

export default MergeAccountsModal
