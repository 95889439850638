import { FC } from 'react'

import {
  Dropdown,
  Icon,
  MultiSelectDropdown,
  Option,
} from '@joor/design-system'

import { RequestPriceType } from '__generated__/atlas-types'

import {
  StyledPriceTypeRow,
  StyledSectionContainer,
} from '../ConnectModal.styles'
import { FindNewRetailerConnectModal } from 'features/LeadGen/FindNewRetailers/findNewRetailersPage.ids'

type PriceTypeAndTermsSelectionProps = {
  priceTypes: Option[]
  termsAndConditions: Option[]
  priceTypeWithTerms: RequestPriceType[]
  setPriceTypeWithTerms: (updatedPriceTypes: RequestPriceType[]) => void
}

const PriceTypeAndTermsSelection: FC<PriceTypeAndTermsSelectionProps> = ({
  priceTypes,
  termsAndConditions,
  priceTypeWithTerms,
  setPriceTypeWithTerms,
}) => {
  const selectedPriceTypeIds = priceTypeWithTerms.map(
    (priceTypeWithTerms) => priceTypeWithTerms.priceTypeId,
  )
  const selectedPriceTypes = priceTypes.filter((priceType) =>
    selectedPriceTypeIds.includes(priceType.value),
  )

  const handleAddPriceType = (selected: Option): void => {
    setPriceTypeWithTerms([
      ...priceTypeWithTerms,
      { priceTypeId: selected.value },
    ])
  }
  const handleRemovePriceType = (deselected: Option): void => {
    const updatedPriceTypeWithTerms = priceTypeWithTerms.filter(
      (priceTypeWithTerm) => priceTypeWithTerm.priceTypeId !== deselected.value,
    )
    setPriceTypeWithTerms(updatedPriceTypeWithTerms)
  }
  const handleAddTerms = (
    selectedPriceTypeWithTerm: RequestPriceType,
    selected: Option,
  ): void => {
    let updatedPriceTypeWithTerms = [...priceTypeWithTerms]
    const priceTypeIndex = updatedPriceTypeWithTerms.findIndex(
      (priceTypeWithTerm) =>
        priceTypeWithTerm.priceTypeId === selectedPriceTypeWithTerm.priceTypeId,
    )
    updatedPriceTypeWithTerms[priceTypeIndex] = {
      priceTypeId: selectedPriceTypeWithTerm.priceTypeId,
      termsAndConditionsId: selected.value,
    }
    setPriceTypeWithTerms(updatedPriceTypeWithTerms)
  }
  const handleRemovePriceTypeWithTerms = (
    deselectedPriceTypeWithTerm: RequestPriceType,
  ): void => {
    const updatedPriceTypeWithTerms = priceTypeWithTerms.filter(
      (priceTypeWithTerm) =>
        priceTypeWithTerm.priceTypeId !==
        deselectedPriceTypeWithTerm.priceTypeId,
    )
    setPriceTypeWithTerms(updatedPriceTypeWithTerms)
  }

  return (
    <StyledSectionContainer>
      <MultiSelectDropdown
        id={FindNewRetailerConnectModal.PriceTypeDropdown}
        key={priceTypeWithTerms?.length}
        label="Assign Price Types"
        placeholder="Select a Price Type"
        values={selectedPriceTypes}
        defaultValue={selectedPriceTypes}
        onSelect={({ selected }: { selected: Option }): void =>
          handleAddPriceType(selected)
        }
        onDeselect={(args: { deselected: Option }) =>
          handleRemovePriceType(args.deselected)
        }
        options={priceTypes}
      />
      {Boolean(priceTypeWithTerms?.length) &&
        Boolean(termsAndConditions?.length) &&
        priceTypeWithTerms.map((priceTypeWithTerm) => {
          const selectedPriceType = priceTypes.find(
            (priceType) => priceType.value === priceTypeWithTerm.priceTypeId,
          )
          const selectedTerm = termsAndConditions.find(
            (term) => term.value === priceTypeWithTerm.termsAndConditionsId,
          )
          return (
            <StyledPriceTypeRow key={priceTypeWithTerm.priceTypeId}>
              <div>{selectedPriceType?.label}</div>
              <Dropdown
                placeholder="Select Terms"
                options={termsAndConditions}
                value={selectedTerm}
                onSelect={({ selected }: { selected: Option }): void =>
                  handleAddTerms(priceTypeWithTerm, selected)
                }
              />
              <Icon
                data-testid={`${priceTypeWithTerm.priceTypeId}_${priceTypeWithTerm?.termsAndConditionsId}`}
                iconName="x-close"
                onClick={() =>
                  handleRemovePriceTypeWithTerms(priceTypeWithTerm)
                }
              />
            </StyledPriceTypeRow>
          )
        })}
    </StyledSectionContainer>
  )
}
export default PriceTypeAndTermsSelection
