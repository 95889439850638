export default {
  createTemplate: 'Create Order Template',
  customPo: 'Custom PO#',
  failedImport:
    'This order was not imported. Please see the errors column in the results file for more details.',
  excelOrders: 'Upload Excel Orders',
  incorrectFileType:
    'Incorrect file type. Please use the Excel order template.',
  poNumber: 'PO#',
  selectDoor: 'Search for a door',
  selectDragFiles: 'Select or drag your files to upload',
  selectOne: 'Select one',
  selectOrderType: 'Search for an order type',
  selectRetailer: 'Search for a retailer',
  selectWarehouse: 'Search for a warehouse',
  step1:
    'Step 1. To download the excel template, click the "Create Order Template" button below.',
  step2:
    "Step 2. Once you're ready to import your order, select a retailer and then upload your file(s)",
  startTyping: 'Start typing to search...',
  upload: 'Upload',
}
