import React, { ReactNode } from 'react'

import { getDisplayName } from 'utils/mixpanel'
import { useUserAnalytics } from 'utils/sdks/userAnalytics'

import { TrackTextStyles } from './TextSettings'

type Trackers = {
  trackTextStyles: TrackTextStyles
}

const withAnalytics = (
  WrappedComponent: typeof React.Component,
): (() => ReactNode) => {
  const Wrapper = () => {
    const { trackEvent } = useUserAnalytics()

    const trackers: Trackers = {
      trackTextStyles: (title, settings) =>
        trackEvent(`Text Styles - ${title}`, settings),
    }

    return <WrappedComponent {...trackers} />
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return Wrapper
}

export default withAnalytics
