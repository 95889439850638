import { useEffect, useRef } from 'react'

import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

import { translateText } from 'utils/sdks/localize'

import Button from 'components/Core/Button/Button'
import Icon from 'components/Core/Icon/Icon'
import Input from 'components/Core/Input/Input'
import Pillbox from 'components/Core/Pillbox/Pillbox'

import styles from './HorizontalFilterList.less'
import messages from './HorizontalFilterList.messages'

const HorizontalFilterList = ({
  children,
  selectedFilters,
  actionText,
  onActionClick,
  errorText,
  handleClearSelection,
  className,
  componentClassName,
  countAppliedFilters,
  expandCollapseFilter,
  isClosed,
  isActionButtonDisabled,
  onBlur,
}) => {
  const ref = useRef()
  useEffect(() => {
    if (!isClosed) {
      ref.current.focus()
    }
  }, [isClosed])
  const inputIcon = (
    <div
      className={classNames(styles.iconContainer, {
        [styles.selected]: !isClosed,
      })}
      onClick={expandCollapseFilter}
    >
      <Icon
        name="arrowUp"
        containerClassName={classNames(styles.icon, {
          [styles.arrowDown]: isClosed,
        })}
      />
    </div>
  )

  return (
    <div
      className={classNames(componentClassName, styles.HorizontalFilterList)}
    >
      {children}
      <div
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        className={classNames(className, styles.filterContainer)}
        onBlur={onBlur}
        ref={ref}
      >
        <Input
          className={styles.filterInput}
          icon={inputIcon}
          value={translateText(messages.filtersApplied(countAppliedFilters))}
          readOnly
        />
        {!isClosed && (
          <div className={styles.selectionContainer}>
            <div className={styles.subSelectionContainer}>
              <div
                className={classNames(styles.selectionMessages, {
                  [styles.error]: !isEmpty(errorText),
                })}
              >
                <div className={styles.userSelection}>
                  <span>{messages.yourSelections}</span>
                  <span
                    className={classNames(styles.clearAll)}
                    onClick={handleClearSelection}
                  >
                    {messages.clearAll}
                  </span>
                </div>
                {!isEmpty(errorText) && (
                  <span className={styles.errorMessage}>{errorText}</span>
                )}
              </div>
              <div className={styles.selectedFiltersContainer}>
                {selectedFilters.map((selectedFilter) => (
                  <Pillbox
                    label={selectedFilter.name}
                    onClick={() => selectedFilter.onClick(selectedFilter)}
                    key={selectedFilter.id}
                    className={styles.pillbox}
                  />
                ))}
              </div>
            </div>
            <div className={styles.actionButtonContainer}>
              <Button
                primary
                className={styles.actionButton}
                onMouseDown={onActionClick}
                disabled={isActionButtonDisabled}
              >
                {actionText}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

HorizontalFilterList.propTypes = {
  selectedFilters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.node.isRequired,
      id: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    }),
  ),
  onActionClick: PropTypes.func,
  actionText: PropTypes.string,
  errorText: PropTypes.string,
  handleClearSelection: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  componentClassName: PropTypes.string,
  countAppliedFilters: PropTypes.number,
  isClosed: PropTypes.bool,
  expandCollapseFilter: PropTypes.func,
  isActionButtonDisabled: PropTypes.bool,
  onBlur: PropTypes.func,
}

HorizontalFilterList.defaultProps = {
  selectedFilters: [],
  onActionClick: () => {},
  actionText: '',
  errorText: '',
  handleClearSelection: () => {},
  children: null,
  className: '',
  componentClassName: '',
  countAppliedFilters: 0,
  isClosed: true,
  expandCollapseFilter: () => {},
  isActionButtonDisabled: false,
  onBlur: () => {},
}

export default HorizontalFilterList
