import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'

import { Divider, Dropdown, Switch, Tooltip } from 'components/Core'
import Button from 'components/Core/ButtonV2/Button'
import { WHITE } from 'components/Core/Tooltip/Tooltip'

import withAnalytics from '../RowSettings.analytics'
import styles from './GallerySettingsTooltip.module.scss'
import { CUSTOM_DROPDOWN } from './GalleryTooltipVariants'
import RadioSelector from 'features/Storefront/DragDropTemplate/Row/RadioSelector/RadioSelector'
import {
  ASPECT_LANDSCAPE,
  ASPECT_PORTRAIT,
  DISTRIBUTION_SIZE,
  FOUR_EQUAL,
  ONE_REGULAR,
  SHOWROOM_BRAND_WIDGET,
  THREE_EQUAL,
  TWO_EQUAL,
  VIDEO_WIDGET,
} from 'features/Storefront/constants'
import { getNewElements } from 'features/Storefront/utils'

const getCustomOptions = (widgetsNum) => {
  const options = []
  for (let rowNumber = 1; rowNumber <= 6; rowNumber++) {
    options.push({
      text: `${rowNumber} (${
        DISTRIBUTION_SIZE[widgetsNum] * rowNumber
      } Brands)`,
      key: rowNumber,
      value: rowNumber,
    })
  }
  return options
}

const GallerySettingsTooltip = ({
  isOpen,
  onApplyChanges,
  switchSettings,
  dropdownSettings,
  radioSettings,
  rowItem,
  top,
  trackRowSettings,
}) => {
  const [showTitle, setShowTitle] = useState(true)
  const [aspect, setAspect] = useState('')
  const [widgetsNum, setWidgetsNum] = useState(FOUR_EQUAL)
  const [rowsNumber, setRowsNumber] = useState(1)

  const defaultTitle = rowItem?.settings?.showTitle
  const defaultAspect = rowItem?.settings?.aspect
  const defaultTemplate = rowItem.template || FOUR_EQUAL
  const defaultRowsNumber = rowItem?.settings?.rowsNumber || 1

  const elementType = rowItem?.elements?.[0]?.elementType

  useEffect(() => {
    if (defaultRowsNumber) {
      setRowsNumber(defaultRowsNumber)
    }
  }, [defaultRowsNumber])

  useEffect(() => {
    if (defaultAspect) {
      setAspect(defaultAspect)
    }
  }, [defaultAspect])
  useEffect(() => {
    if (defaultTemplate) {
      setWidgetsNum(defaultTemplate)
    }
    if (defaultTemplate === ONE_REGULAR) {
      setAspect(ASPECT_LANDSCAPE)
    }
    if (defaultTemplate === TWO_EQUAL && elementType === VIDEO_WIDGET) {
      setAspect(ASPECT_LANDSCAPE)
    }
  }, [defaultTemplate])
  useEffect(() => setShowTitle(defaultTitle), [defaultTitle])

  const mapTemplateToNumber = {
    [ONE_REGULAR]: 1,
    [TWO_EQUAL]: 2,
    [THREE_EQUAL]: 3,
    [FOUR_EQUAL]: 4,
  }

  const handleClickApply = () => {
    const trackerTitle = rowItem?.tooltip?.settings?.trackerTitle
    if (trackerTitle) {
      const trackerSettings = {
        'Overlay Title': showTitle,
        ...(aspect && { 'Aspect Ratio': aspect }),
        ...(widgetsNum && {
          'Number of elements': mapTemplateToNumber[widgetsNum],
        }),
      }
      trackRowSettings(trackerTitle, trackerSettings)
    }
    onApplyChanges({
      ...rowItem,
      elements: getNewElements({
        rowItem,
        template: widgetsNum,
        options: radioSettings?.options,
        rowsNumber,
        elementType,
      }),
      settings: {
        ...rowItem.settings,
        ...(aspect && { aspect }),
        ...(rowsNumber &&
          elementType === SHOWROOM_BRAND_WIDGET && { rowsNumber }),
        showTitle,
      },
      ...(widgetsNum && {
        template: widgetsNum,
      }),
    })
  }

  const content = (
    <>
      <h3 className={styles.title}>{switchSettings?.title}</h3>
      {switchSettings && (
        <div className={styles.switch}>
          <Switch
            id="title-switch"
            onChange={() => setShowTitle((b) => !b)}
            disabled={false}
            checked={showTitle}
          />
          <span>{switchSettings?.label}</span>
        </div>
      )}
      {dropdownSettings && (
        <>
          <h4 className={styles.subtitle}>{dropdownSettings?.title}</h4>
          <Dropdown
            onChange={(_, { value }) =>
              elementType === SHOWROOM_BRAND_WIDGET
                ? setRowsNumber(value)
                : setAspect(value)
            }
            options={
              dropdownSettings?.options === CUSTOM_DROPDOWN
                ? getCustomOptions(widgetsNum)
                : dropdownSettings?.options
            }
            value={elementType === SHOWROOM_BRAND_WIDGET ? rowsNumber : aspect}
            className={styles.dropdown}
            disabled={
              widgetsNum === ONE_REGULAR || elementType === VIDEO_WIDGET
            }
          />
        </>
      )}
      {radioSettings && (
        <RadioSelector
          onClick={(_, { value }) => {
            if (
              value === ONE_REGULAR ||
              (value === TWO_EQUAL && elementType === VIDEO_WIDGET)
            ) {
              setAspect(ASPECT_LANDSCAPE)
            }
            if (
              (value === THREE_EQUAL && elementType === VIDEO_WIDGET) ||
              (value === FOUR_EQUAL && elementType === VIDEO_WIDGET)
            ) {
              setAspect(ASPECT_PORTRAIT)
            }
            setWidgetsNum(value)
          }}
          radioOptions={radioSettings?.options}
          radioTitle={radioSettings?.title}
          selectedRadio={widgetsNum}
          className={styles.subtitle}
        />
      )}
      <Divider />
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.smallButton}
          onClick={() => onApplyChanges(rowItem)}
        >
          Cancel
        </Button>
        <Button className={styles.mediumButton} onClick={handleClickApply}>
          Apply
        </Button>
      </div>
    </>
  )

  return (
    <Tooltip
      className={styles.GallerySettingsTooltip}
      style={{
        top: `${top + 100}px`,
        right: '10px',
      }}
      content={content}
      open={isOpen}
      color={WHITE}
    />
  )
}

GallerySettingsTooltip.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onApplyChanges: PropTypes.func.isRequired,
  top: PropTypes.number.isRequired,
  switchSettings: PropTypes.object,
  dropdownSettings: PropTypes.object,
  radioSettings: PropTypes.object,
}

GallerySettingsTooltip.defaultProps = {
  switchSettings: null,
  dropdownSettings: null,
  radioSettings: null,
}

export default withAnalytics(GallerySettingsTooltip)
