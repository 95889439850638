import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { camelCase } from 'change-case'
import styled from 'styled-components'

import Feature from '../Feature/Feature'
import { useAvailableFeatures } from 'features/EarlyAccessWidget/earlyAccessWidget.hooks'
import { WidgetIds } from 'features/EarlyAccessWidget/earlyAccessWidget.ids'

interface FeatureListProps {
  visible: boolean
}

const Container = styled.div<FeatureListProps>`
  display: flex;
  width: 341px;
  position: absolute;
  left: 47px;
  z-index: 1;
  background: var(--color-neutral-000-full);
  border-radius: 0px 0px 4px 4px;
  border: ${(props) =>
    props.visible ? '1px solid var(--color-primary-200)' : 'none'};
  border-top: none;
  max-height: ${(props) => (props.visible ? 'calc(100vh - 120px)' : '0')};
  transition: max-height 0.3s ease-in-out;
  overflow: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

const Options = styled.div`
  padding: 16px;
  overflow-y: auto;
`

const FeatureList = ({ visible }: FeatureListProps) => {
  const flags = useFlags()
  const availableFeatures = useAvailableFeatures()

  if (!availableFeatures.length) {
    return null
  }

  return (
    <Container visible={visible} id={WidgetIds.Content}>
      <Options>
        {availableFeatures.map((feature) => (
          <Feature
            key={feature.earlyAccessFeatureFlag}
            feature={feature}
            featureFlagEnabled={
              flags[camelCase(feature.earlyAccessFeatureFlag)] || false
            }
          />
        ))}
      </Options>
    </Container>
  )
}

export default FeatureList
