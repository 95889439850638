export default {
  searchStyles: 'Search Styles',
  applyFilters: 'Apply Filters',
  search: 'Search',
  styleColorsNotOnLinesheets: 'Style-colors not on linesheets',
  yourSelection: 'Your Selection',
  clearAll: 'Clear All',
  linesheet: 'Linesheet',
  updatedAt: 'Date Modified',
  addDate: 'Add a date',
  addDateFilter: 'Add Date as Filter',
}
