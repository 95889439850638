import { handleActions } from 'redux-actions'

export const defaultState = {
  account: {},
  apiHost: null,
  assortmentPlanning: false,
  isAdmin: false,
  isOwner: false,
  loggedAsAdmin: false,
  canViewRetailerSettings: false,
  integrations: [],
  isTiedWithBuyerHierarchy: false,
  settings: {
    accountUserPermissions: [],
  },
  userLoggedIn: false,
}

const reducer = handleActions(
  {
    'CURRENT_USER/SET_CURRENT_USER': (state, { payload: currentUser }) => ({
      ...state,
      ...currentUser,
      account: {
        ...state.account,
        ...currentUser.account,
      },
    }),
  },
  defaultState,
)

export default reducer
