/* eslint-disable prefer-destructuring */
import omit from 'lodash/omit'
import pick from 'lodash/pick'

import { LOAD_USER_STATE } from './constants'
import { migrate } from './migrations'

const ACTION_INIT_TYPE = '@@redux/INIT'

const loadState = (key, version, accountId, userId) => {
  const storageKey = `${key}-${version}-${accountId}-${userId}`
  try {
    const serializedState = localStorage.getItem(storageKey)
    return JSON.parse(serializedState)
  } catch (err) {
    return {}
  }
}

const saveState = (state, key, version, accountId, userId) => {
  const storageKey = `${key}-${version}-${accountId}-${userId}`
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem(storageKey, serializedState)
  } catch (err) {
    // Ignore write errors.
  }
}

/*
 * withStorage is a High Order Reducer that add localStorage persistence,
 * when the underlying reducer changes the state, this new state is stored.
 * Since we want that this kind of storage is applied differently per user
 * we have to key it with the userId, so different users in the same
 * browser will have different states stored.
 *
 * For this reason withStorage will do nothing until it the action
 * LOAD_USER_STATE is dispatched.
 *
 * It's first argument is the reducer to wrap, the second one are a set of options.
 * * The key field is mandatory and will be used as a key along with the userId
 * * when saving the state to localstorage.
 * * *
 * * The version field is mandatory and will be used to ease managing migrations.
 * * The omitFields option is optional and should include a list of paths
 * * in the state that we don't want to include, for instance, omit the searchFilter
 * * for orderListFilters
 * * The pickFields option is optional and should include a list of paths
 * * in the state that we want to include. For example, only take into account one of the
 * * multiple fields saved in a reducer (order review).
 * * *
 * * The migrations option is optional and should include a list of migrations to apply.
 * * If a stored state changes its shape, we could get undesired behaviour like
 * * missing stored state or even crashing. Each migration should be a function
 * * that receives a state and returns the new shape of the state (like changing a key,
 * * adding or removing a field, etc).
 * * *
 * postLoadHooks are functions that can modify state after it was loaded from the local storage.
 * It can be used to modify dates, for instance.
 */
const withStorage = (
  reducer,
  {
    key,
    version,
    omitFields = [],
    migrations = [],
    postLoadHooks = [],
    pickFields = [],
  },
) => {
  let accountId
  let userId

  migrations.forEach((migration, index) =>
    migrate(localStorage, saveState, key, index, migration),
  )

  return (state, action) => {
    if (action.type === LOAD_USER_STATE) {
      const { account, id } = action.payload
      accountId = account ? account.accountId : ''
      userId = id || ''
      const newState = {
        ...state,
        ...loadState(key, version, accountId, userId),
      }
      return postLoadHooks.reduce(
        (initialState, postLoadHook) => postLoadHook(initialState),
        newState,
      )
    }

    const newState = reducer(state, action)
    if (newState !== state && accountId && userId) {
      const stateToStore = pickFields.length
        ? pick(newState, pickFields)
        : omit(newState, omitFields)
      if (!action.type.includes(ACTION_INIT_TYPE)) {
        saveState(stateToStore, key, version, accountId, userId)
      }
    }
    return newState
  }
}

export default withStorage
