/* Table row */
export const STYLE_IMAGE_DIMENSION = '56x75'
export const STYLE_COLOR_DIMENSION = '18x18'
export const STYLE_COLOR_CODE = 'COLOR'
export const TABLE_DEFAULT_HEIGHT = 100
export const TABLE_COLOR_HEIGHT = 32
export const SWATCH_SQUARE_SIZE = 28
export const ROW_PADDING = 16
export const NAME_COLUMN_WIDTH = 328
export const MODIFIED_COLUMN_WIDTH = 112
export const COLORS_HEADER_WIDTH = 208
export const CATEGORIES_HEADER_WIDTH = 200

/* Pagination */
export const NUMBER_OF_STYLES_PER_PAGE = 20
export const INITIAL_OFFSET_OF_STYLES = 0
export const TABLE_SORT_ORDER = ['asc', 'desc']
export const URL_ORDER_PARAM = 'order'
export const URL_ORDER_BY_PARAM = 'orderBy'
export const URL_PAGE_PARAM = 'page'
export const URL_SEARCH_TEXT_PARAM = 'searchText'
export const DESCENDING_VALUE = 'desc'
export const ASCENDING_VALUE = 'asc'

/* Query params */
export const IMPORT_STYLES_KEY_PARAM = 'open-modal'
export const IMPORT_STYLES_VALUE_PARAM = 'import-styles'
