import { handleActions } from 'redux-actions'

import {
  initialFilterReducerState,
  unselectedFilterReducerState,
} from 'utils/filters/utils'

import withStorage from 'store/utils/storage/withStorage'

import {
  APPLY_SEARCH_FILTER,
  APPLY_SELECTED_FILTERS,
  SET_APPLIED_FILTERS_TO_CREATED_BY_RETAILERS,
  TOGGLE_DESELECT_ALL_FILTERS,
  TOGGLE_SELECT_FILTER,
  TOGGLE_UNASSIGNED_ORDERS_FILTER,
  VERSION_CODE,
  actionsPrefix,
} from './constants'
import { migrations } from './migrations'
import { orderFilters } from 'containers/OrderListFilters/filters'

export const defaultState = {
  selectedFilters: {
    ...initialFilterReducerState(orderFilters),
  },
  appliedFilters: {
    ...initialFilterReducerState(orderFilters),
  },
  searchFilter: '',
  onlyUnassignedOrdersFilter: false,
}

const reducer = handleActions(
  {
    [TOGGLE_SELECT_FILTER]: (state, { payload }) => {
      const { dataKey } = payload
      const filterSettings = orderFilters[dataKey]
      const { reducerHandler } = filterSettings
      return {
        ...state,
        selectedFilters: reducerHandler(state.selectedFilters, { ...payload }),
      }
    },
    [TOGGLE_DESELECT_ALL_FILTERS]: (state) => ({
      ...state,
      selectedFilters: {
        ...state.selectedFilters,
        ...unselectedFilterReducerState(orderFilters),
      },
    }),
    [TOGGLE_UNASSIGNED_ORDERS_FILTER]: (state) => ({
      ...state,
      onlyUnassignedOrdersFilter: !state.onlyUnassignedOrdersFilter,
    }),
    [APPLY_SELECTED_FILTERS]: (state) => ({
      ...state,
      appliedFilters: { ...state.selectedFilters },
    }),
    [APPLY_SEARCH_FILTER]: (state, { payload: searchText }) => ({
      ...state,
      searchFilter: searchText.trim(),
    }),
    [SET_APPLIED_FILTERS_TO_CREATED_BY_RETAILERS]: (state) => ({
      ...state,
      appliedFilters: {
        ...initialFilterReducerState(orderFilters),
        createdByRetailer: true,
      },
    }),
  },
  defaultState,
)

export default withStorage(reducer, {
  key: actionsPrefix,
  version: VERSION_CODE,
  migrations,
  omitFields: ['searchFilter'],
})
