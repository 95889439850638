import { handleActions } from 'redux-actions'

export const defaultState = {
  selectedRetailerId: null,
  retailerSearchText: '',
}

const reducer = handleActions(
  {
    'DUPLICATE_ORDERS_MODAL/SET_SELECTED_RETAILER_ID': (state, { payload }) => {
      const newState = { ...state }
      newState.selectedRetailerId = payload

      return newState
    },
    'DUPLICATE_ORDERS_MODAL/CHANGE_RETAILER_SEARCH_TEXT': (
      state,
      { payload },
    ) => ({
      ...state,
      retailerSearchText: payload,
    }),
  },
  defaultState,
)

export default reducer
