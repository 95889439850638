import classNames from 'classnames'
import compact from 'lodash/compact'
import map from 'lodash/map'
import PropTypes from 'prop-types'

import Grid from 'components/Core/Grid/Grid'

import styles from './FormBuilder.less'
import renderFormElement from './FormElement'

const FormBuilder = ({ disabled, items, className, handleChange, colSize }) => {
  const handleFormOrFormElementChange = (formElement, data) => {
    const key = formElement.serviceFieldName || formElement.key
    if (formElement.handleChange) {
      formElement.handleChange({ [key]: data })
    } else {
      handleChange({ data: { [key]: data }, label: formElement.label })
    }
  }

  const labelRow = (formElement) => (
    <div className={styles.title1}>
      {formElement.required && <span className={styles.required}>*</span>}
      {formElement.label}
    </div>
  )

  const formBuilderStyle = classNames(styles.FormBuilder, className)

  let formElements = compact(items)
  if (disabled) {
    formElements = formElements.map((item) => ({ ...item, editable: false }))
  }

  return (
    <Grid.Row alignColumns="left" className={formBuilderStyle}>
      {map(formElements, (formElement) => (
        <Grid.Col
          key={formElement.key}
          className={styles.col}
          sm={colSize}
          md={colSize}
          lg={colSize}
        >
          {labelRow(formElement)}
          <div className={classNames(styles.input, formElement.inputClassName)}>
            {renderFormElement({ formElement, handleFormOrFormElementChange })}
          </div>
        </Grid.Col>
      ))}
    </Grid.Row>
  )
}

FormBuilder.propTypes = {
  // a form element can take a serviceFieldName to override the key when using as a name to send up to the server
  items: PropTypes.array.isRequired,
  className: PropTypes.string,
  handleChange: PropTypes.func,
  colSize: PropTypes.number,
  disabled: PropTypes.bool,
}

FormBuilder.defaultProps = {
  className: null,
  handleChange: () => {},
  colSize: 3,
  disabled: false,
}

export default FormBuilder
