import { Icon } from '@joor/design-system'
import styled, { css } from 'styled-components'

import { formatConnectedAccounts } from 'utils/formatters'

import { IDropdownMenuOptionAccounts } from 'features/NavbarRevamp/navbarRevamp.types'

const MenuText = styled.div`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-alpha);
  letter-spacing: var(--letter-spacing-tighter);
  line-height: var(--line-height-16);
  padding: 8px;
  width: 100%;

  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    padding: 7px;
  }
`

const sharedTextStyles = css`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-base);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-normal);
  font-weight: var(--font-weight-medium);
`

const MenuTextMessage = styled.span`
  ${sharedTextStyles}
  color: var(--color-primary-800);
  text-align: left;
`

const MenuTextMessageChecked = styled.span`
  ${sharedTextStyles}
  color: var(--color-secondary-800);
  text-align: left;
`

const MenuTextMessageCheck = styled.span`
  ${sharedTextStyles}
  color: var(--color-secondary-800);
  text-align: center;
  float: right;

  svg {
    --main-color: var(--color-secondary-800);
    width: 16px;
    height: 16px;
  }
`

const MenuConnectedAccounts = styled.span`
  color: var(--color-primary-600);
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-tighter);
  text-align: left;
  margin-top: 4px;
`

const MenuTextAction = styled.button`
  background-color: var(--color-primary-100);
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  text-decoration-line: none;
  margin: auto 0;
  display: flex;
  line-height: var(--line-height-16);
`

const DropdownMenuOptionAccounts = ({
  className,
  message,
  connectedAccounts = [],
  onClick,
  testId,
  id,
  checked,
}: IDropdownMenuOptionAccounts) => (
  <MenuTextAction data-testid={testId} id={id} className="menuTextLink">
    <MenuText
      className={className}
      onClick={(event) => onClick && onClick(event)}
    >
      <div>
        {!checked ? (
          <MenuTextMessage>{message}</MenuTextMessage>
        ) : (
          <>
            <MenuTextMessageChecked>{message}</MenuTextMessageChecked>
            <MenuTextMessageCheck>
              <Icon iconName="checkmark" plain />
            </MenuTextMessageCheck>
          </>
        )}
      </div>
      {!!connectedAccounts.length && (
        <MenuConnectedAccounts>
          {formatConnectedAccounts(connectedAccounts)}
        </MenuConnectedAccounts>
      )}
    </MenuText>
  </MenuTextAction>
)

export default DropdownMenuOptionAccounts
