import { Dispatch, SetStateAction } from 'react'

import { RetailerImpersonationFeedbackOption } from '__generated__/atlas-types'

import {
  LiteRetailerRequiredInfoViews,
  PrivacyAccountOptions,
} from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal'

import AddressEdition from '../AddressEdition/AddressEdition'
import AddressSelection from '../AddressSelection/AddressSelection'
import BrandAccountSelection from '../BrandAccountSelection/BrandAccountSelection'
import BusinessInfo from '../BusinessInfo/BusinessInfo'
import SecondaryAccountSelection from '../SecondayAccountSelection/SecondaryAccountSelection'
import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

type ModalContentProps = {
  googleAddress?: RetailerProfileLocation
  googleWebsite?: string
  impersonationReason: RetailerImpersonationFeedbackOption
  otherReason: string
  privacySelection: PrivacyAccountOptions | null
  selectedAddressId: string
  setImpersonationReason: Dispatch<
    SetStateAction<RetailerImpersonationFeedbackOption>
  >
  setOtherReason: Dispatch<SetStateAction<string>>
  setPrivacySelection: Dispatch<SetStateAction<PrivacyAccountOptions | null>>
  setSelectedAddressId: Dispatch<SetStateAction<string>>
  setUpdatedDescription: Dispatch<SetStateAction<string>>
  setUpdatedProvidedAddress: Dispatch<
    SetStateAction<Partial<RetailerProfileLocation>>
  >
  setUpdatedWebsite: Dispatch<SetStateAction<string>>
  setView: Dispatch<SetStateAction<LiteRetailerRequiredInfoViews>>
  updatedDescription: string
  updatedProvidedAddress: Partial<RetailerProfileLocation>
  updatedWebsite: string
  view: LiteRetailerRequiredInfoViews
}

const ModalContent: React.FC<ModalContentProps> = ({
  googleAddress,
  googleWebsite,
  impersonationReason,
  otherReason,
  privacySelection,
  selectedAddressId,
  setImpersonationReason,
  setOtherReason,
  setPrivacySelection,
  setSelectedAddressId,
  setUpdatedDescription,
  setUpdatedProvidedAddress,
  setUpdatedWebsite,
  setView,
  updatedDescription,
  updatedProvidedAddress,
  updatedWebsite,
  view,
}) => {
  const getModalContent = () => {
    switch (view) {
      case LiteRetailerRequiredInfoViews.AddressEdition:
        return (
          <AddressEdition
            address={updatedProvidedAddress}
            website={updatedWebsite}
            setAddress={setUpdatedProvidedAddress}
            setWebsite={setUpdatedWebsite}
          />
        )
      case LiteRetailerRequiredInfoViews.BrandSelection:
        return (
          <BrandAccountSelection
            impersonationReason={impersonationReason}
            setImpersonationReason={setImpersonationReason}
            otherReason={otherReason}
            setOtherReason={setOtherReason}
          />
        )
      case LiteRetailerRequiredInfoViews.BusinessInfo:
        return (
          <BusinessInfo
            address={updatedProvidedAddress}
            setAddress={setUpdatedProvidedAddress}
            description={updatedDescription}
            setDescription={setUpdatedDescription}
          />
        )
      case LiteRetailerRequiredInfoViews.SecondaryAccount:
        return (
          <SecondaryAccountSelection
            privacySelection={privacySelection}
            setPrivacySelection={setPrivacySelection}
          />
        )
      default:
        return (
          <AddressSelection
            providedAddress={updatedProvidedAddress}
            googleAddress={googleAddress}
            providedWebsite={updatedWebsite}
            googleWebsite={googleWebsite}
            selectedAddressId={selectedAddressId}
            setSelectedAddressId={setSelectedAddressId}
            setAddressEdition={() => {
              setView(LiteRetailerRequiredInfoViews.AddressEdition)
            }}
          />
        )
    }
  }
  return getModalContent()
}

export default ModalContent
