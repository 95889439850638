import times from 'lodash/times'

export const getKeyFromFirstElement = (array, key) => {
  if (Array.isArray(array) && array.length > 0 && key in array[0]) {
    return array[0][key]
  }
  return null
}

export const getNumSlices = (count, sliceLength) => {
  const quotient = Math.floor(count / sliceLength)
  const remainder = count % sliceLength
  return remainder === 0 ? quotient : quotient + 1
}

// Returns an array of slices of sliceLength of the input array
// it might be used e.g. for bucketing of requests
export const sliceArray = (array, sliceLength) => {
  const numSlices = getNumSlices(array.length, sliceLength)

  return times(numSlices, (i) => {
    const sliceStart = i * sliceLength
    const sliceEnd = (i + 1) * sliceLength
    return array.slice(sliceStart, sliceEnd)
  })
}

export const areObjectListsEqual = (one, other, compareField = 'id') => {
  if (one.length !== other.length) {
    return false
  }

  return one.every(({ [compareField]: oneField }) =>
    other.find(({ [compareField]: otherField }) => oneField === otherField),
  )
}
