import { ChangeEvent, Dispatch, SetStateAction } from 'react'

import { RadioButton, RadioButtonGroup, TextField } from '@joor/design-system'
import styled from 'styled-components'

import { RetailerImpersonationFeedbackOption } from '__generated__/atlas-types'

import { BrandAccountSelectionView } from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.ids'

type BrandAccountSelectionProps = {
  impersonationReason: RetailerImpersonationFeedbackOption
  setImpersonationReason: Dispatch<
    SetStateAction<RetailerImpersonationFeedbackOption>
  >
  otherReason: string
  setOtherReason: Dispatch<SetStateAction<string>>
}

const StyledContainer = styled.div`
  max-height: 100%;
  overflow: auto;
  margin-top: 8px;
  && {
    padding: 16px 24px;
  }
`
const StyledInfo = styled.div`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  letter-spacing: 0.6px;
`
const StyledText = styled.div`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  letter-spacing: 0.6px;
  padding-bottom: 16px;
`
const SelectionSection = styled.div`
  padding: 16px 8px;
`
const TextFieldContainer = styled.div`
  padding: 8px 0 0 16px;
`

const BrandAccountSelection: React.FC<BrandAccountSelectionProps> = ({
  impersonationReason,
  setImpersonationReason,
  otherReason,
  setOtherReason,
}) => (
  <StyledContainer data-testid={BrandAccountSelectionView.Component}>
    <StyledInfo>
      We understand some businesses that are not retailers use JOOR’s retail
      platform.
    </StyledInfo>
    <SelectionSection>
      <StyledText>
        Let us know how you are primarily using JOOR’s retail platform:
      </StyledText>
      <RadioButtonGroup>
        <div id={`${BrandAccountSelectionView.PlaceholderOption}__wrapper`}>
          <RadioButton
            id={BrandAccountSelectionView.PlaceholderOption}
            data-testid="impersonating-as-placeholder"
            label="As a placeholder for unconnected retailer orders"
            name="impersonation-reason"
            value={RetailerImpersonationFeedbackOption.UNCONNECTED_ORDERS}
            onChange={() =>
              setImpersonationReason(
                RetailerImpersonationFeedbackOption.UNCONNECTED_ORDERS,
              )
            }
            checked={
              impersonationReason ===
              RetailerImpersonationFeedbackOption.UNCONNECTED_ORDERS
            }
          />
        </div>
        <div id={`${BrandAccountSelectionView.ImpersonationOption}__wrapper`}>
          <RadioButton
            id={BrandAccountSelectionView.ImpersonationOption}
            data-testid="impersonating-to-place-orders"
            label="To place orders on my retailer’s behalf"
            name="impersonation-reason"
            value={RetailerImpersonationFeedbackOption.RETAILER_REPRESENTATIVE}
            onChange={() =>
              setImpersonationReason(
                RetailerImpersonationFeedbackOption.RETAILER_REPRESENTATIVE,
              )
            }
            checked={
              impersonationReason ===
              RetailerImpersonationFeedbackOption.RETAILER_REPRESENTATIVE
            }
          />
        </div>
        <div id={`${BrandAccountSelectionView.BuyerOption}__wrapper`}>
          <RadioButton
            id={BrandAccountSelectionView.BuyerOption}
            data-testid="impersonating-to-buy"
            label="To buy products for personal use"
            name="impersonation-reason"
            value={RetailerImpersonationFeedbackOption.PERSONAL_SHOPPER}
            onChange={() =>
              setImpersonationReason(
                RetailerImpersonationFeedbackOption.PERSONAL_SHOPPER,
              )
            }
            checked={
              impersonationReason ===
              RetailerImpersonationFeedbackOption.PERSONAL_SHOPPER
            }
          />
        </div>
        <div id={`${BrandAccountSelectionView.OtherOption}__wrapper`}>
          <RadioButton
            id={BrandAccountSelectionView.OtherOption}
            data-testid="impersonating-for-other-reasons"
            label="Other"
            name="impersonation-reason"
            value={RetailerImpersonationFeedbackOption.OTHER}
            onChange={() =>
              setImpersonationReason(RetailerImpersonationFeedbackOption.OTHER)
            }
            checked={
              impersonationReason === RetailerImpersonationFeedbackOption.OTHER
            }
          />
        </div>
      </RadioButtonGroup>
      {impersonationReason === RetailerImpersonationFeedbackOption.OTHER && (
        <TextFieldContainer>
          <TextField
            id={BrandAccountSelectionView.OtherField}
            data-testid="other-reason"
            placeholder="ex. integration partner"
            value={otherReason}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setOtherReason(e.target.value)
            }
          />
        </TextFieldContainer>
      )}
    </SelectionSection>
  </StyledContainer>
)

export default BrandAccountSelection
