/* eslint-disable max-len */
import { useRef, useState } from 'react'

import { useQuery } from '@apollo/client'
import { typography } from '@joor/design-system'
import '@joor/design-system/dist/designTokens/variables.css'
import { useSelector } from 'react-redux'
import { generatePath, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { toGlobalId } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useRenderToasters } from 'hooks/useRenderToasters'
import { userIsLoggedIn } from 'store/currentUser/selectors'
import {
  getAccountId,
  getExistsHomePage,
  getInMenuPages,
  getNotInMenuPages,
  getPanel,
  getTotalPages,
  isLastOnlinePage,
} from 'store/storefront/selectors'

import { STOREFRONT as STOREFRONT_PATH } from 'routes/paths'

import { MAX_ONLINE_IN_MENU_PAGES } from '../Pages.constants'
import { useBulkUpdateStorefront } from '../Pages.hooks'
import { PageActionProps, PageElement } from '../Pages.types'
import Modals from './modals'
import { MAX_PAGES } from 'features/Storefront/EditionNavbar/Sections/Pages/Pages.constants'
import {
  calculateNewOrder,
  cleanStorefrontToDuplicate,
  generateUniqueName,
  getCopiedName,
  getCopyNumber,
  getNewPageOrderNbr,
} from 'features/Storefront/EditionNavbar/Sections/Pages/Pages.utils'
import {
  PUBLIC_STOREFRONT,
  STOREFRONT,
} from 'features/Storefront/Storefront.hooks'
import { formatStorefront } from 'features/Storefront/Storefront.transformations'
import { useUpsertStorefront } from 'features/Storefront/StorefrontNavbar/StorefrontNavbar.hooks'

export const MODAL_DELETE: string = 'delete'
export const MODAL_RENAME: string = 'rename'
export const MODAL_FIRST_HOME: string = 'first-home'

type ActionsContainerProps = {
  isOpen: boolean
}
const ActionsContainer = styled.div<ActionsContainerProps>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  width: 174px;
  position: absolute;
  z-index: 10;
  top: 32px;
  right: 8px;
  border-radius: 5px;
  background-color: var(--color-neutral-000-full);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);

  label {
    margin-top: 26px;
  }
  input {
    width: 560px;
  }
`

type ActionItemProps = {
  disabled?: boolean
}
const ActionItem = styled.div<ActionItemProps>`
  ${typography.alpha.primaryActive.caption};
  font-size: 12px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 8px;
  color: ${({ disabled }) => (disabled ? '#CDD4DA' : 'initial')};

  &:hover {
    background: ${({ disabled }) =>
      disabled ? '' : 'var(--color-secondary-300)'};
  }
`

const PageActions = ({
  displayName,
  id,
  isHome,
  isOnline,
  loadingNavigation,
  openActions,
  orderNbr,
  refetchStorefrontNavigation,
  setOpenActions,
}: PageActionProps): JSX.Element => {
  const [openModal, setOpenModal] = useState<string>('')

  const { bulkUpdate } = useBulkUpdateStorefront()
  const { upsertStorefront: duplicatePage } = useUpsertStorefront()

  const totalPages = useSelector(getTotalPages)
  const inMenu: PageElement[] = useSelector(getInMenuPages)
  const notInMenu: PageElement[] = useSelector(getNotInMenuPages)
  const isLastOnline: boolean = useSelector(isLastOnlinePage)
  const existsHomePage: boolean = useSelector(getExistsHomePage)
  const panel = useSelector(getPanel)
  const isLoggedIn = useSelector<boolean>(userIsLoggedIn)
  const brandId = useSelector(getAccountId)
  const history = useHistory()

  const onlinePagesInMenu = inMenu.filter((page) => page.isOnline).length
  const storefrontQuery = isLoggedIn ? STOREFRONT : PUBLIC_STOREFRONT
  const storefrontIds: Array<string> = [...inMenu, ...notInMenu].map(
    (page) => page?.id,
  )
  const { renderSuccessToast, renderErrorToast } = useRenderToasters()
  const { data: storefrontsInfo } = useQuery(storefrontQuery, {
    client: atlasClient,
    skip: !storefrontIds,
    variables: {
      brandId: toGlobalId('Brand', brandId),
      filters: {
        ids: storefrontIds,
      },
    },
  })

  const wrapperRef = useRef(null)

  const handleDuplicatePage = async () => {
    if (totalPages === MAX_PAGES) {
      return renderErrorToast({
        description:
          'Maximum number of 25 pages already reached. Please delete an existing page to create a new one.',
      })
    }

    // Get copied storefront name and data
    const nameList: Array<string> = [...inMenu, ...notInMenu].map(
      (page) => page?.displayName,
    )
    const previousCopies = getCopyNumber(nameList, displayName) + 1
    const copiedPageName = getCopiedName(displayName, previousCopies)
    const newPageConfig = {
      isHome: false,
      orderNbr: getNewPageOrderNbr([...inMenu, ...notInMenu]),
      displayName: copiedPageName,
      uniqueName: generateUniqueName(copiedPageName),
      isOnline: false,
      isNewPage: false,
    }

    // Format storefront to copy to send rows without ids
    const storefrontToCopy = storefrontsInfo?.storefronts?.find(
      (storefront: any) => storefront?.id === id,
    )
    const formattedStorefront = formatStorefront(storefrontToCopy)
    const newStorefront = cleanStorefrontToDuplicate(formattedStorefront)
    let result
    try {
      result = await duplicatePage(newPageConfig, newStorefront)
      if (result?.data?.upsertStorefront) {
        renderSuccessToast({ description: 'Your page was duplicated.' })
      }
      if (result?.errors) {
        return renderErrorToast({
          description: 'There was an error duplicating your page.',
        })
      }
    } catch {
      return renderErrorToast({
        description: 'There was an error duplicating your page.',
      })
    }

    // Reorder storefronts so the copy appears below original
    const isInMenu = storefrontToCopy?.orderNbr > 0
    const updatedStorefronts = isInMenu ? [...inMenu] : [...notInMenu]
    const copiedStorefront = result?.data?.upsertStorefront
    updatedStorefronts.splice(
      Math.abs(storefrontToCopy?.orderNbr),
      0,
      copiedStorefront,
    )
    const updatedOrderedStorefronts = calculateNewOrder(
      updatedStorefronts,
      isInMenu,
    )
    await bulkUpdate([
      ...updatedOrderedStorefronts.map((p) => {
        const { id, orderNbr } = p
        return { id, orderNbr }
      }),
    ])
    refetchStorefrontNavigation()
  }
  const actions = [
    {
      name: 'Edit Page Name',
      disabled: false,
      onClick: (): void => setOpenModal(MODAL_RENAME),
    },
    {
      name: 'Set as Home Page',
      disabled: isHome,
      onClick: async () => {
        if (isHome) return
        if (!isOnline) {
          return renderErrorToast({
            description:
              'This page must be taken online before it can be set as your homepage.',
          })
        }
        try {
          const oldHomeId = [...inMenu, ...notInMenu].filter(
            (page) => page.isHome,
          )?.[0]?.id
          const input = oldHomeId
            ? [
                { id, isHome: true },
                {
                  id: oldHomeId,
                },
              ]
            : [{ id, isHome: true }]
          const { data, errors } = await bulkUpdate(input)

          if (!data || errors) {
            return renderErrorToast({
              description: 'There was an error changing your page settings.',
            })
          }
          history.push(
            generatePath(STOREFRONT_PATH, {
              accountId: brandId,
              ...(id !== panel.id ? { pageName: panel.uniqueName } : {}),
            }),
          )
          return renderSuccessToast({
            description: `${displayName} page has been set as your homepage.`,
          })
        } catch {
          return renderErrorToast({
            description: 'There was an error changing your page settings.',
          })
        }
      },
    },
    {
      name: 'Duplicate Page',
      disabled: false,
      onClick: () => handleDuplicatePage(),
    },
    {
      name: `Take Page ${isOnline ? 'Offline' : 'Online'}`,
      disabled: false,
      onClick: async () => {
        if (
          orderNbr > 0 &&
          onlinePagesInMenu === MAX_ONLINE_IN_MENU_PAGES &&
          !isOnline
        ) {
          return renderErrorToast({
            description:
              'Maximum number of 10 online pages in navigation menu already reached.',
          })
        }
        if (isHome) {
          return renderErrorToast({
            description: 'Your homepage cannot be taken offline.',
          })
        }
        if (isOnline && isLastOnline) {
          return renderErrorToast({
            description:
              'Please make sure that at least one of your pages are online in your custom profile before you take this page offline.',
          })
        }
        if (!existsHomePage) return setOpenModal(MODAL_FIRST_HOME)
        try {
          const { data, errors } = await bulkUpdate([
            { id, isOnline: !isOnline },
          ])
          if (!data || errors) {
            return renderErrorToast({
              description: 'There was an error changing your page settings.',
            })
          }
          return renderSuccessToast({
            description: `${displayName} page has been taken ${
              isOnline ? 'Offline' : 'Online'
            }.`,
          })
        } catch {
          return renderErrorToast({
            description: 'There was an error changing your page settings.',
          })
        }
      },
    },
    {
      name: 'Delete Page',
      disabled: false,
      onClick: (): void => setOpenModal(MODAL_DELETE),
    },
  ]
  useOnClickOutside(wrapperRef, () => setOpenActions(false), openActions)
  return (
    <ActionsContainer isOpen={openActions} ref={wrapperRef}>
      {actions.map((action) => (
        <ActionItem
          data-testid={`storefront_page_action_${action.name}`}
          key={action.name}
          disabled={action.disabled}
          onClick={() => {
            setOpenActions(false)
            action.onClick()
          }}
        >
          {action.name}
        </ActionItem>
      ))}
      {openModal && (
        <Modals
          id={id}
          openModal={openModal}
          setOpenModal={setOpenModal}
          name={displayName}
          isHome={isHome}
          loadingNavigation={loadingNavigation}
          refetchStorefrontNavigation={refetchStorefrontNavigation}
        />
      )}
    </ActionsContainer>
  )
}

export default PageActions
