export const migrate = (storage, save, key, version, migration) => {
  const entriesToMigrate = Object.entries(storage)
    .filter(([k]) => k.startsWith(`${key}-${version}`))
    .map(([k, v]) => [k, JSON.parse(v)])

  entriesToMigrate.forEach(([oldStorageKey, storedState]) => {
    const keyParts = oldStorageKey.split('-')
    const accountId = keyParts[2]
    const userId = keyParts[3]
    if (accountId && userId) {
      const newState = migration(storedState)
      save(newState, key, version + 1, accountId, userId)
      storage.removeItem(oldStorageKey)
    }
  })
}

/**
 * In a nutshell, a migration is a function that receives a state and returns a state,
 * Some examples of migrations could be:
 *
 * To add a field to a state we could do something like this
 * Here we would be adding the field 'divisions' to two fields of the state
 * const addDivisionCodeMigration = state => ({
 *    selectedFilters: {
 *    ...state.selectedFilters,
 *     divisions: [],
 *   },
 *   appliedFilters: {
 *     ...state.appliedFilters,
 *     divisions: [],
 *   },
 * })
 *
 * Another example for, lets say, add a field to the quickfilters
 * const addO = state => ({
 *    selectedFilters: {
 *    ...state.selectedFilters,
 *     divisions: [],
 *   },
 *   appliedFilters: {
 *     ...state.appliedFilters,
 *     divisions: [],
 *   },
 * })
 *
 * A migration to remove a field would be as simple as
 * const removeSearchMigration = (state) => {
 *   const { fieldToRemove: _, ...newState } = state
 *   return newState
 * }
 *
 * To rename a field we could do
 * const renameAFieldMigration = (state) => {
 *   const { fieldToRemove, ...newState } = state
 *   return {
 *      ...newState,
 *      newField: fieldToRemove
 *   }
 * }
 *
 * If we want to change the shape of a field, for instance from a list of strings to an
 * object with { key: true } we could do
 * const changeShapeOfFieldMigration = (state) => {
 *
 *   return {
 *      ...state,
 *      fieldToChangeShape: state.fieldToChangeShape.reduce((acc, key) => ({
 *        ...acc,
 *        [key]: true
 *      }), {})
 *   }
 * }
 *
 * Basically, we just have to change a state into how we want a new state to be.
 * We could also add a field and set the value to the default value of the reducer for that new field
 *
 * const defaulState = {
 *  // something we had before
 *  newField: [1, 2, 3]
 * }
 *
 * const aMigrationToAddAFieldWithDefaultState = state => ({
 *    ...state,
 *    newField: defaultState.newField
 * })
 *
 * The mutations don't have to be immutable, it is ok to edit the state and return that
 * The shape of each migration is the same as in redux persist (but there they use a object
 * instead of a list to pass all the migrations)
 * https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md
 *
 */
