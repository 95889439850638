import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'
import Tooltip from 'components/Core/Tooltip/Tooltip'

import styles from './Info.less'

const Info = ({ content, className, tooltipClassName, position }) => (
  <Tooltip
    trigger={
      <div className={classNames(styles.Info, className)}>
        <Icon name="infoBubble" />
      </div>
    }
    content={content}
    on="hover"
    position={position}
    className={tooltipClassName}
  />
)

Info.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  position: PropTypes.string,
}

Info.defaultProps = {
  className: '',
  tooltipClassName: '',
  position: 'right center',
}

export default Info
