// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ExceptionHandler_ExceptionHandler__1N73g {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  bottom: 1.8em;\n  right: 1em;\n  margin: 1em;\n  z-index: 20001;\n}\n", "",{"version":3,"sources":["webpack://src/containers/ExceptionHandler/ExceptionHandler.less"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,aAAa;EACb,UAAU;EACV,WAAW;EACX,cAAc;AAChB","sourcesContent":[".ExceptionHandler {\n  display: flex;\n  flex-direction: column;\n  position: fixed;\n  bottom: 1.8em;\n  right: 1em;\n  margin: 1em;\n  z-index: 20001;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ExceptionHandler": "ExceptionHandler_ExceptionHandler__1N73g"
};
export default ___CSS_LOADER_EXPORT___;
