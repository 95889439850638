import { handleActions } from 'redux-actions'

import { SET_COLLECTION_ID, SET_SEARCH_TEXT } from './constants'

export const defaultState = {
  collectionId: null,
  searchText: null,
}

const reducer = handleActions(
  {
    [SET_COLLECTION_ID]: (state, { payload }) => ({
      ...state,
      collectionId: payload,
    }),
    [SET_SEARCH_TEXT]: (state, { payload }) => ({
      ...state,
      searchText: payload,
    }),
  },
  defaultState,
)

export default reducer
