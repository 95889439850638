import React, { useState } from 'react'

import { typography } from '@joor/design-system'
import '@joor/design-system/dist/designTokens/variables.css'
import { compose } from 'lodash/fp'
import QRCode from 'qrcode.react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { getAccountId } from 'store/currentUser/selectors'

import { Button } from 'components/Core'

import withAnalytics from './QR.analytics'
import RenderInTab from './RenderInTab'

const Container = styled.div`
  padding: 30px 20px 0;
  font-family: var(--font-family-alpha);

  .qr-link {
    .ui.button {
      background-color: transparent;
      border: none;
      color: var(--color-secondary-800);
      padding-top: 24px;
      font-weight: var(--font-weight-regular);
    }
  }
`
const Title = styled.p`
  font-size: 36px !important;
  ${typography.alpha.primaryActive.headline1};
  line-height: 60px;
  margin: 0 !important;
`
const Content = styled.p`
  ${typography.alpha.primaryActive.body1};
  line-height: 20px;
  padding: 12px 0 16px;
`
const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

type QRProps = {
  trackViewQR: () => Function
}
const QR: React.FC<QRProps> = ({ trackViewQR }) => {
  const [openTab, setOpenTab] = useState<number>(0)
  const accountId = useSelector<number | string>(getAccountId) || 'accountId'
  const link: string = `${window.location.origin}/r/storefront/${accountId}`
  const qrLink: string = `${link}?source=qr`

  const onViewQR = (): void => {
    trackViewQR()
    setOpenTab(openTab + 1)
  }
  const QRText: string = `Scanning this QR Code will lead users to a public view of your profile
  on JOOR. We recommend taking a screenshot of your QR code for external
  applications.`
  return (
    <Container>
      <Title>QR Code</Title>
      <Content>{QRText}</Content>
      <QRContainer>
        <QRCode
          value={qrLink}
          renderAs="svg"
          size={140}
          data-testid="qr-code"
        />
        <Button className="qr-link" onClick={onViewQR}>
          Open QR code in new tab
        </Button>
      </QRContainer>
      {openTab > 0 && (
        <RenderInTab key={openTab}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '24px 0',
            }}
          >
            <QRCode value={qrLink} renderAs="svg" size={512} />
            <p
              style={{
                marginTop: '88px',
                fontFamily:
                  "'Montserrat', 'Nunito Sans', Helvetica, Arial, sans-serif",
              }}
            >
              {QRText}
            </p>
          </div>
        </RenderInTab>
      )}
    </Container>
  )
}

export default compose(withAnalytics)(QR)
