import classnames from 'classnames'

import Grid from 'components/Core/Grid/Grid'
import { CONTACT_SUPPORT, PRIVACY_POLICY, TERMS_OF_SERVICE } from 'routes/paths'

import styles from './Footer.less'

const Footer = ({ containerClassName, rowClassName }) => {
  return (
    <Grid className={containerClassName}>
      <Grid.Row className={classnames(styles.footer, rowClassName)}>
        <Grid.Col sm={2} md={2} lg={4} />
        <Grid.Col sm={2} md={2} lg={2}>
          {`${new Date().getFullYear()} Joor`}
        </Grid.Col>
        <Grid.Col sm={2} md={2} lg={2}>
          <a href={TERMS_OF_SERVICE}>Terms of service</a>
        </Grid.Col>
        <Grid.Col sm={2} md={2} lg={2}>
          <a href={PRIVACY_POLICY}>Privacy policy</a>
        </Grid.Col>
        <Grid.Col sm={2} md={2} lg={2}>
          <a href={CONTACT_SUPPORT}>Contact us</a>
        </Grid.Col>
        <Grid.Col sm={2} md={2} lg={4} />
      </Grid.Row>
    </Grid>
  )
}

export default Footer
