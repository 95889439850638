import classNames from 'classnames/bind'
import PropTypes from 'prop-types'

import Checkbox from 'components/Core/Checkbox/Checkbox'
import Radio from 'components/Core/Radio/Radio'
import Table from 'components/Core/Table/Table'
import Tooltip, { BLACK } from 'components/Core/Tooltip/Tooltip'
import TruncateWithTooltip from 'components/Core/TruncateWithTooltip/TruncateWithTooltip'

import Indicator, { RED, YELLOW } from './Indicator'
import styles from './Table.less'

const ItemTableBodyRow = ({
  item,
  itemLink,
  columns,
  columnOrder,
  disabled,
  restricted,
  isItemSelected,
  selectable,
  selectItem,
  singleSelect,
  className,
  tooltip,
  tooltipColor,
  tooltipClassName,
  indicator,
  indicatorInfo,
  noLockWhenDisabled,
  onRowClick,
  isExpandedRow,
  ...rest
}) => {
  const rowStyles = classNames(className, {
    [styles.highlightedRow]: isItemSelected && !disabled,
  })
  const renderCheckbox = (isSingleSelect) =>
    isSingleSelect ? (
      <Radio
        onClick={() => selectItem(item.id)}
        checked={isItemSelected}
        disabled={disabled}
      />
    ) : (
      <Checkbox
        onClick={() => selectItem(item.id)}
        checked={isItemSelected}
        disabled={disabled}
        noLockWhenDisabled={noLockWhenDisabled}
        restricted={restricted}
      />
    )

  const row = (
    <Table.Row className={rowStyles} onClick={onRowClick}>
      {indicator && (
        <Indicator>
          {indicatorInfo.map(
            ({
              color,
              tooltip: indicatorTooltip,
              className: itemClassName,
            }) => (
              <Indicator.Item
                key={`${color}-${indicatorTooltip}-${item.id}`}
                tooltip={indicatorTooltip}
                className={itemClassName}
                color={color}
              />
            ),
          )}
        </Indicator>
      )}
      {isExpandedRow && <Table.Cell> </Table.Cell>}
      {selectable && (
        <Table.Cell
          width="checkbox"
          className={classNames({ [styles.noPaddingLeft]: indicator })}
        >
          {renderCheckbox(singleSelect)}
        </Table.Cell>
      )}
      {columnOrder.map((id) => (
        <Table.Cell
          key={id}
          link={itemLink}
          width={columns[id].width}
          tooltip={columns[id].tooltip && columns[id].tooltip(item)}
          disableTooltip={columns[id].disableTooltip}
          className={
            columns[id].cellClassName && columns[id].cellClassName(item)
          }
          {...columns[id].align}
          {...rest}
        >
          {columns[id].truncate ? (
            <TruncateWithTooltip lines={1}>
              {columns[id].cellContent(item)}
            </TruncateWithTooltip>
          ) : (
            columns[id].cellContent(item)
          )}
        </Table.Cell>
      ))}
    </Table.Row>
  )
  const tooltipProps = {
    trigger: row,
    content: tooltip,
    color: tooltipColor,
    className: tooltipClassName,
  }

  const rowWithTooltip = <Tooltip {...tooltipProps} />

  return tooltip ? rowWithTooltip : row
}

ItemTableBodyRow.propTypes = {
  /* item to be used to calculate cell contents */
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  /* link for table row to be passed to React Router Link */
  itemLink: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string,
    }),
  ]),
  /*
  The prop columns takes an object containing columnId as key.
  {
    columnId: {
      description: 'Editable', // Table header label
      width: 'small', // Width of the column
      cellContent: item => item.editableOn, // function determining cell contents
    },
  }
  */
  // eslint-disable-next-line react/forbid-prop-types
  columns: PropTypes.object.isRequired,
  /* array of Column Id in the order that they should be displayed */
  columnOrder: PropTypes.arrayOf(PropTypes.string).isRequired,
  isItemSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  restricted: PropTypes.bool,
  className: PropTypes.string,
  onRowClick: PropTypes.func,
  noLockWhenDisabled: PropTypes.bool,
  selectable: PropTypes.bool,
  selectItem: PropTypes.func,
  shouldLinkOpenInNewTab: PropTypes.bool,
  singleSelect: PropTypes.bool,
  onClickLink: PropTypes.func,
  tooltip: PropTypes.node,
  tooltipColor: PropTypes.string,
  tooltipClassName: PropTypes.string,
  indicator: PropTypes.bool,
  indicatorInfo: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf([RED, YELLOW]).isRequired,
      tooltip: PropTypes.string.isRequired,
      className: PropTypes.string,
    }),
  ),
  isExpandedRow: PropTypes.bool,
}

ItemTableBodyRow.defaultProps = {
  itemLink: null,
  shouldLinkOpenInNewTab: false,
  singleSelect: false,
  disabled: false,
  className: '',
  isItemSelected: false,
  noLockWhenDisabled: false,
  selectable: true,
  selectItem: () => {},
  onClickLink: null,
  tooltip: null,
  tooltipColor: BLACK,
  tooltipClassName: null,
  indicator: false,
  indicatorInfo: [],
  isExpandedRow: false,
}

export default ItemTableBodyRow
