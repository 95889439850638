import styled from 'styled-components'

export const Container = styled.div`
  width: calc(100% - 64px);
  margin: 32px;
  @media only screen and (min-width: 1200px) {
    margin: 0 32px;
  }
`
export const StyledHeader = styled.div`
  width: 100%;
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-regular);
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 1.2px;
  color: var(--color-primary-700);
  border-bottom: 1px solid var(--color-primary-300);
  padding: 0 0 8px 0;
`
export const StyledContent = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 24px;
  flex-direction: row;

  > div {
    flex-basis: 25%;
  }
  > div:not(:last-child) {
    border-right: 1px solid var(--color-primary-200);
  }
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 576px) {
    flex-direction: column;
    > div {
      flex-basis: auto;
      border-right: none !important;
    }
  }
`
export const StyledTitle = styled.div`
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  font-style: normal;
  color: var(--color-primary-900);
  line-height: 20px;
  letter-spacing: 1.4px;
`
export const StyledSubtitle = styled.div`
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  line-height: 16px;
  letter-spacing: 1px;
  color: var(--color-primary-900);
`
export const StyledText = styled.div`
  padding: 12px 8px;
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  color: var(--color-primary-500);
  line-height: 16px;
  letter-spacing: 0.6px;
  margin-top: 8px;
  margin-right: 4px;
  word-break: break-word;
  text-wrap: balance;
`
export const StyledAddress = styled.div`
  padding: 12px 8px;
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-medium);
  font-size: var(--size-font-small);
  color: var(--color-primary-500);
  line-height: 16px;
  letter-spacing: 0.6px;
  margin-top: 8px;
  margin-right: 4px;
  word-break: break-word;
  text-wrap: balance;
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const StyledSection = styled.div`
  margin: 8px 0 16px;
`
export const StyledSectionWithFlex = styled.div`
  margin: 8px 0 16px;
  @media only screen and (min-width: 768px) {
    max-height: 380px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  @media only screen and (min-width: 1200px) {
    max-height: 260px;
  }
`
export const StyledSubSection = styled.div`
  margin: 8px 10px 8px 0;
`
