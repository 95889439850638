import styled from 'styled-components'

export const ModalWrapper = styled.div`
  > div: first-child > div:first-child {
    z-index: 30000;
  }
`
export const StyledModalContent = styled.div`
  height: 460px;
  width: 500px;
  overflow: visible;
`

type StyledStepProps = {
  $selected: boolean
}

export const StyledStep = styled.div<StyledStepProps>`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: ${({ $selected }): string =>
    $selected ? 'var(--color-secondary-400)' : 'var(--color-secondary-200)'};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledSteps = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
`
