import { typography } from '@joor/design-system'
import '@joor/design-system/dist/designTokens/variables.css'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 20px 20px;
  font-family: var(--font-family-alpha);
  color: var(--color-primary-900);
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;

  textarea {
    border-radius: 5px;
    border: 1px solid var(--color-primary-400);
    font-family: var(--font-family-alpha);
    font-size: var(--size-font-small);
  }
`
export const Title = styled.p`
  font-size: 36px !important;
  ${typography.alpha.primaryActive.headline1};
  margin: 0 !important;
  line-height: 60px;
`
export const Info = styled.p`
  ${typography.alpha.primaryActive.body1};
  margin: 8px 0 0 !important;
`
export const Subtitle = styled.span`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-semibold);
  margin-top: 24px;
`
export const Block = styled.div`
  margin-top: 1em;
`
export const Label = styled.span`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
`
export const PricesContainer = styled.div`
  display: flex;
  & > div {
    width: 45%;
  }
  .spacer {
    margin: 30px 5px 0;
  }
`
