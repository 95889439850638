import { translateText } from 'utils/sdks/localize'

import Filter from './baseFilter'

/*
 * SingleIdFilter
 * It is a type of filter that allows the selection of a single
 * element e.g. Export Status. These elements are identified by an
 * id.
 */
export default class SingleIdFilter extends Filter {
  constructor({ defaultState, ...rest }) {
    super(rest)
    this.unselectedState = null
    if (defaultState) {
      this.defaultState = defaultState
    }
  }

  getSelectedOptions = (selectedFilters) =>
    selectedFilters[this.filterKey] && {
      [selectedFilters[this.filterKey].id]: true,
    }

  isUnselected = (filterItem) => !filterItem

  toGraphQLVariable = (graphQLFilters, { value }) => {
    if (this.isUnselected(value)) {
      return graphQLFilters
    }
    return {
      ...graphQLFilters,
      [this.filterKey]: value.id,
    }
  }

  reducerHandler = (selectedFilters, filterInfo) => {
    const { id } = filterInfo
    // Overwrite or remove
    const isAlreadyThere =
      selectedFilters[this.filterKey] &&
      selectedFilters[this.filterKey].id === id
    return {
      ...selectedFilters,
      [this.filterKey]: isAlreadyThere ? null : filterInfo,
    }
  }

  addToActiveFilters = (currentState, filterState) => {
    // passes without modifying
    if (this.isUnselected(filterState)) {
      return currentState
    }
    return [...currentState, filterState]
  }

  getSelectedFiltersPillFormatted = ({ selectedFilters, onActionClick }) => {
    const selectedFilter = selectedFilters[this.filterKey]
    if (selectedFilter) {
      const { id, text } = selectedFilter
      return [
        {
          id,
          name: (
            <span>
              {translateText(this.label)}:{` `}
              <var>{text}</var>
            </span>
          ),
          onClick: onActionClick,
        },
      ]
    }
    return []
  }

  getCountFilters = (filterState) => (this.isUnselected(filterState) ? 0 : 1)
}
