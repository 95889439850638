import { handleActions } from 'redux-actions'

export const defaultState = {
  selectedRetailerId: null,
  retailerSearchText: '',
}

const reducer = handleActions(
  {
    'CREATE_ORDER_TEMPLATE_MODAL/SET_SELECTED_RETAILER_ID': (
      state,
      { payload },
    ) => ({
      ...state,
      selectedRetailerId: payload,
    }),
    'CREATE_ORDER_TEMPLATE_MODAL/CHANGE_RETAILER_SEARCH_TEXT': (
      state,
      { payload },
    ) => ({
      ...state,
      retailerSearchText: payload,
    }),
  },
  defaultState,
)

export default reducer
