import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

import {
  EMPTY_DELIVERY_WINDOW,
  getAggregatedSkuDeliveryRange,
  getDeliveryWindowText,
} from 'utils/transformations/deliveryWindows'
import {
  fromGlobalId,
  relayConnectionToArray,
} from 'utils/transformations/graphql'

import { PRODUCT_SIZE_CODE } from 'shop/products/traits'
import {
  fromCasepackId,
  fromSkuId,
  getVariantByCode,
} from 'shop/products/utils'

export const orderAvailabilityGroups = (availabilityGroups) =>
  orderBy(
    availabilityGroups,
    ({ availability }) => [
      availability.availableOn,
      availability.isImmediate && !availability.availableOn,
    ],
    ['asc', 'asc'],
  )

export const filterAvailabilityGroups = (availabilityGroups) =>
  relayConnectionToArray(availabilityGroups).filter(
    (availabilityGroup) => !availabilityGroup.isEmpty,
  )

const getWarehouseWithAvailabilityGroups = (warehouse) => {
  return {
    ...warehouse,
    availabilityGroups: orderAvailabilityGroups(
      filterAvailabilityGroups(warehouse.availabilityGroups),
    ),
  }
}

export const transformWarehouses = (warehouses) =>
  relayConnectionToArray(warehouses).reduce((acc, warehouse) => {
    const warehouseWithAvailabilityGroups = getWarehouseWithAvailabilityGroups(
      warehouse,
    )
    return [
      ...acc,
      ...(!isEmpty(warehouseWithAvailabilityGroups.availabilityGroups)
        ? [warehouseWithAvailabilityGroups]
        : []),
    ]
  }, [])

export const getDiscountFactor = (discounts) => {
  const discount = get(discounts[0], 'factor', 0)
  return (100 - discount) / 100
}

export const getSizesByColor = (variants = [], variantId = '') => {
  const sizeVariants = getVariantByCode(variants, PRODUCT_SIZE_CODE)
  return get(sizeVariants, 'values', [])
    .map((sizeVariant) => ({
      id: sizeVariant.id,
      name: sizeVariant.value,
      skuId: get(sizeVariant, 'skusIds', []).find(
        (skuId) => fromSkuId(skuId).colorVariantId === variantId,
      ),
    }))
    .filter((variant) => Boolean(variant.skuId))
}

export const getCasepacksByColor = (casepacks = {}, variantId = '') =>
  Object.values(casepacks).filter((casepack) =>
    Boolean(
      casepack?.skus?.find(
        (casepackSku) =>
          fromSkuId(casepackSku?.id).colorVariantId === variantId,
      ),
    ),
  )

export const getSizeItemsForAccordion = (
  sizesByColor = [],
  colorSkuDeliveries = [],
  className = '',
  userDateFormat,
) =>
  sizesByColor.map((sizeByColor) => {
    const skuDelivery = colorSkuDeliveries.find(
      (colorSkuDelivery) => sizeByColor?.skuId === colorSkuDelivery?.sku?.id,
    )
    return {
      id: sizeByColor.id,
      name: `${sizeByColor.name}: ${getDeliveryWindowText(
        skuDelivery?.startShipDate,
        skuDelivery?.completeShipDate,
        EMPTY_DELIVERY_WINDOW,
        undefined,
        userDateFormat,
      )}`,
      deliveryRange: `${getDeliveryWindowText(
        skuDelivery?.startShipDate,
        skuDelivery?.completeShipDate,
        EMPTY_DELIVERY_WINDOW,
        undefined,
        userDateFormat,
      )}`,
      className,
    }
  })

export const getCasepackSkuDelivery = (
  skuDeliveries = [],
  casepack = {},
  className = '',
  userDateFormat,
) => {
  const casepackSkuDelivery = skuDeliveries.find((colorSkuDelivery) => {
    const {
      sizeVariantId: casepackSizeVariant,
      colorVariantId: casepackColorVariant,
    } = fromCasepackId(casepack?.originalId)
    const {
      sizeVariantId: skuDeliverySizeVariant,
      colorVariantId: skuDeliveryColorVariant,
    } = fromSkuId(colorSkuDelivery?.sku?.id)
    return (
      casepackSizeVariant === skuDeliverySizeVariant &&
      casepackColorVariant === skuDeliveryColorVariant
    )
  })
  if (casepackSkuDelivery) {
    return {
      id: casepack.originalId,
      name: `${casepack.name}: ${getDeliveryWindowText(
        casepackSkuDelivery?.startShipDate,
        casepackSkuDelivery?.completeShipDate,
        EMPTY_DELIVERY_WINDOW,
        undefined,
        userDateFormat,
      )}`,
      deliveryRange: `${getDeliveryWindowText(
        casepackSkuDelivery?.startShipDate,
        casepackSkuDelivery?.completeShipDate,
        EMPTY_DELIVERY_WINDOW,
        undefined,
        userDateFormat,
      )}`,
      className,
    }
  }
}

export const getAggregatedCasepackDelivery = (
  skuDeliveries = [],
  casepack = {},
  className = '',
  userDateFormat,
) => {
  const casepackSkusWithDelivery = skuDeliveries.reduce((acc, skuDelivery) => {
    const { sizeVariantId } = fromSkuId(skuDelivery?.sku?.id)
    const sizeInCasepack = casepack?.sizes?.find(
      (size) => fromGlobalId(fromGlobalId(size?.id).id).id === sizeVariantId,
    )
    return sizeInCasepack ? [...acc, skuDelivery?.sku?.id] : acc
  }, [])
  const casepackAggregatedDelivery = skuDeliveries.filter((el) =>
    casepackSkusWithDelivery.includes(el?.sku?.id),
  )
  return {
    id: casepack.originalId,
    name: `${casepack.name}: ${getAggregatedSkuDeliveryRange(
      casepackAggregatedDelivery,
      userDateFormat,
    )}`,
    deliveryRange: `${getAggregatedSkuDeliveryRange(
      casepackAggregatedDelivery,
      userDateFormat,
    )}`,
    className,
  }
}

export const getCasepackDeliveryRange = (
  skuDeliveries = [],
  casepack = {},
  className = '',
  userDateFormat,
) => {
  const casepackSkuDelivery = getCasepackSkuDelivery(
    skuDeliveries,
    casepack,
    className,
    userDateFormat,
  )
  const casepackSkusWithDelivery = getAggregatedCasepackDelivery(
    skuDeliveries,
    casepack,
    className,
  )
  return casepackSkuDelivery || casepackSkusWithDelivery
}

export const getCasepackItemsForAccordion = (
  casepacksByColor = [],
  colorSkuDeliveries = [],
  className = '',
  userDateFormat,
) =>
  casepacksByColor.map((casepackByColor) =>
    getCasepackDeliveryRange(
      colorSkuDeliveries,
      casepackByColor,
      className,
      userDateFormat,
    ),
  )

export const getSkuDeliveryItems = ({
  skuDeliveries = {},
  variantId = '',
  variants = [],
  casepacks = {},
  className = '',
  userDateFormat,
}) => {
  const colorSkuDeliveries = skuDeliveries[variantId]
  const itemsFromSizes = getSizeItemsForAccordion(
    getSizesByColor(variants, variantId),
    colorSkuDeliveries,
    className,
    userDateFormat,
  )
  const itemsFromCasepacks = getCasepackItemsForAccordion(
    getCasepacksByColor(casepacks, variantId),
    colorSkuDeliveries,
    className,
  )

  return [...itemsFromSizes, ...itemsFromCasepacks]
}
