import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import {
  Button,
  Modal,
  PlainIcon,
  SecondaryButton,
  TextButton,
  Tooltip,
} from '@joor/design-system'
import { generatePath, useHistory } from 'react-router-dom'

import {
  GoogleAddress,
  RetailerImpersonationFeedbackOption,
} from '__generated__/atlas-types'

import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import { LITE_RETAILER_MODAL_SNOOZED } from 'hooks/useRenderLiteRetailerRequiredInfoModal'

import {
  LiteRetailerRequiredInfoViews,
  PrivacyAccountOptions,
} from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal'
import {
  AddressEditionView,
  AddressSelectionView,
  BrandAccountSelectionView,
  BusinessInfoView,
  SecondaryAccountSelectionView,
} from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.ids'
import {
  useSaveGoogleAddressSelection,
  useSaveImpersonationFeedback,
  useUpdateAccountPrivacy,
  useUpdateAddress,
  useUpdateCounter,
  useUpdatePrimaryAccount,
  useUpdateWebsiteAndDescription,
} from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.mutations'
import {
  checkAddressChanges,
  checkCompleteAddressFields,
  checkCompleteBusinessInfo,
  getAddressInput,
  getBasicInfoInput,
  getImpersonationFeedbackInput,
  validateWebsiteUrl,
} from 'modals/LiteRetailerRequiredInfo/LiteRetailerRequiredInfoModal.utils'
import { RETAILER_PROFILE } from 'routes/paths'

import { useRetailerConnections } from './ModalActions.queries'
import {
  ActionsContainer,
  LeftButtonContainer,
  RightButtonContainer,
  StyledSecondaryAccountButton,
} from './ModalActions.styles'
import {
  RetailerProfile,
  RetailerProfileLocation,
} from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

type ModalActionsProps = {
  googleAddress?: GoogleAddress
  impersonationReason: RetailerImpersonationFeedbackOption
  loading?: boolean
  otherReason?: string
  privacySelection: PrivacyAccountOptions | null
  providedAddress: Partial<RetailerProfileLocation>
  providedProfileData: Partial<RetailerProfile>
  refetchProvidedAddress: any
  retailerId: string
  selectedAddressId: string
  setOpen: Dispatch<SetStateAction<boolean>>
  setSelectedAddressId: Dispatch<SetStateAction<string>>
  setUpdatedProvidedAddress: Dispatch<
    SetStateAction<Partial<RetailerProfileLocation>>
  >
  setUpdatedWebsite: Dispatch<SetStateAction<string>>
  setView: Dispatch<SetStateAction<LiteRetailerRequiredInfoViews>>
  updatedDescription: string
  updatedProvidedAddress: Partial<RetailerProfileLocation>
  updatedWebsite: string
  view: LiteRetailerRequiredInfoViews
}

const ModalActions: React.FC<ModalActionsProps> = ({
  googleAddress,
  impersonationReason,
  loading,
  otherReason,
  privacySelection,
  providedAddress,
  providedProfileData,
  refetchProvidedAddress,
  retailerId,
  selectedAddressId,
  setOpen,
  setSelectedAddressId,
  setUpdatedProvidedAddress,
  setUpdatedWebsite,
  setView,
  updatedDescription,
  updatedProvidedAddress,
  updatedWebsite,
  view,
}) => {
  const [websiteUrl, setWebsiteUrl] = useState<string>('')
  const history = useHistory()

  const updateAddress = useUpdateAddress()
  const updateWebsiteAndDescription = useUpdateWebsiteAndDescription()
  const updateCounter = useUpdateCounter()
  const updatePrivacy = useUpdateAccountPrivacy()
  const saveGoogleOption = useSaveGoogleAddressSelection()
  const updatePrimaryAccount = useUpdatePrimaryAccount()
  const saveImpersonationFeedback = useSaveImpersonationFeedback()
  const hasRetailerConnections = useRetailerConnections(retailerId)

  useEffect(() => {
    const updatedWebsiteUrl =
      selectedAddressId === googleAddress?.id
        ? googleAddress?.website
        : updatedWebsite
    setWebsiteUrl(updatedWebsiteUrl ?? '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedWebsite, googleAddress])

  const getModalActions = () => {
    switch (view) {
      case LiteRetailerRequiredInfoViews.AddressEdition:
        return (
          <Modal.Actions>
            <ActionsContainer>
              <LeftButtonContainer />
              <RightButtonContainer>
                <SecondaryButton
                  id={AddressEditionView.CancelButton}
                  onClick={() => {
                    setUpdatedProvidedAddress(providedAddress)
                    setUpdatedWebsite(providedProfileData?.websiteUrl ?? '')
                    setView(LiteRetailerRequiredInfoViews.AddressSelection)
                  }}
                >
                  Cancel
                </SecondaryButton>
                <Button
                  id={AddressEditionView.UpdateButton}
                  onClick={async () => {
                    await updateAddress(
                      getAddressInput(updatedProvidedAddress),
                      LiteRetailerRequiredInfoViews.AddressEdition,
                    )
                    await updateWebsiteAndDescription(
                      getBasicInfoInput(retailerId, {
                        ...providedProfileData,
                        websiteUrl,
                        description: updatedDescription,
                      }),
                    )
                    if (googleAddress?.id) {
                      await saveGoogleOption([
                        {
                          id: googleAddress.id,
                          accepted: false,
                        },
                      ])
                    }
                    if (providedAddress?.id) {
                      setSelectedAddressId(providedAddress?.id)
                    }
                    setView(LiteRetailerRequiredInfoViews.AddressSelection)
                    refetchProvidedAddress()
                  }}
                  disabled={
                    !checkCompleteAddressFields(
                      updatedProvidedAddress,
                      updatedWebsite,
                    ) ||
                    !checkAddressChanges({
                      previousLocation: providedAddress,
                      updatedLocation: updatedProvidedAddress,
                      previousWebsite: providedProfileData?.websiteUrl ?? '',
                      updatedWebsite: updatedWebsite,
                    }) ||
                    !validateWebsiteUrl(updatedWebsite)
                  }
                >
                  Update
                </Button>
              </RightButtonContainer>
            </ActionsContainer>
          </Modal.Actions>
        )
      case LiteRetailerRequiredInfoViews.BusinessInfo:
        return (
          <Modal.Actions>
            <ActionsContainer>
              <LeftButtonContainer>
                <TextButton
                  id={BusinessInfoView.SecondaryAccountButton}
                  onClick={() => {
                    setView(LiteRetailerRequiredInfoViews.SecondaryAccount)
                  }}
                >
                  Not my primary account
                </TextButton>
              </LeftButtonContainer>
              <RightButtonContainer>
                <SecondaryButton
                  id={BusinessInfoView.BackButton}
                  onClick={() => {
                    setView(LiteRetailerRequiredInfoViews.AddressSelection)
                  }}
                >
                  Back
                </SecondaryButton>
                <Button
                  id={BusinessInfoView.SaveButton}
                  onClick={async () => {
                    if (selectedAddressId === googleAddress?.id) {
                      await updateAddress(
                        getAddressInput({
                          id: providedAddress.id,
                          name: providedAddress.name,
                          type: updatedProvidedAddress.type,
                          address1: googleAddress.address1,
                          address2: '',
                          city: googleAddress.city,
                          state: googleAddress.state,
                          zip: googleAddress.zip,
                          country: googleAddress.country,
                          phone: googleAddress.phone,
                          yearEstablished: providedAddress.yearEstablished,
                          wholesaleRange: {
                            min: updatedProvidedAddress.wholesaleRange?.min,
                            max: updatedProvidedAddress.wholesaleRange?.max,
                          },
                          brandsCarried: updatedProvidedAddress.brandsCarried,
                          categories: updatedProvidedAddress.categories,
                          clienteleInfo: providedAddress.clienteleInfo,
                        }),
                        LiteRetailerRequiredInfoViews.BusinessInfo,
                      )
                    } else {
                      await updateAddress(
                        getAddressInput(updatedProvidedAddress),
                        LiteRetailerRequiredInfoViews.BusinessInfo,
                      )
                    }
                    await updateCounter(
                      getBasicInfoInput(retailerId, {
                        ...providedProfileData,
                        profileSnoozeCounter: null,
                        description: updatedDescription,
                        websiteUrl,
                      }),
                    )
                    setOpen(false)
                    history.push(
                      generatePath(RETAILER_PROFILE, {
                        accountId: fromGlobalId(retailerId)?.id ?? '',
                      }),
                    )
                  }}
                  disabled={
                    !checkCompleteBusinessInfo(
                      updatedProvidedAddress,
                      updatedDescription,
                      hasRetailerConnections,
                    )
                  }
                >
                  Save
                </Button>
              </RightButtonContainer>
            </ActionsContainer>
          </Modal.Actions>
        )
      case LiteRetailerRequiredInfoViews.BrandSelection:
        return (
          <Modal.Actions>
            <ActionsContainer>
              <LeftButtonContainer />
              <RightButtonContainer>
                <SecondaryButton
                  id={BrandAccountSelectionView.BackButton}
                  onClick={() => {
                    setView(LiteRetailerRequiredInfoViews.AddressSelection)
                  }}
                >
                  Back
                </SecondaryButton>
                <Button
                  id={BrandAccountSelectionView.SubmitButton}
                  onClick={async () => {
                    await saveImpersonationFeedback(
                      getImpersonationFeedbackInput({
                        accountId: retailerId,
                        option: impersonationReason,
                        comment: otherReason,
                      }),
                    )
                    await updateCounter(
                      getBasicInfoInput(retailerId, {
                        ...providedProfileData,
                        profileSnoozeCounter: null,
                        description: updatedDescription,
                        websiteUrl,
                      }),
                    )
                    setOpen(false)
                    history.push(
                      generatePath(RETAILER_PROFILE, {
                        accountId: fromGlobalId(retailerId)?.id ?? '',
                      }),
                    )
                  }}
                  disabled={
                    impersonationReason ===
                      RetailerImpersonationFeedbackOption.UNKNOWN ||
                    (impersonationReason ===
                      RetailerImpersonationFeedbackOption.OTHER &&
                      !Boolean(otherReason?.replace(/\s/g, '')))
                  }
                >
                  Submit
                </Button>
              </RightButtonContainer>
            </ActionsContainer>
          </Modal.Actions>
        )
      case LiteRetailerRequiredInfoViews.SecondaryAccount:
        return (
          <Modal.Actions>
            <ActionsContainer>
              <LeftButtonContainer />
              <RightButtonContainer>
                <SecondaryButton
                  id={SecondaryAccountSelectionView.BackButton}
                  onClick={() => {
                    setView(LiteRetailerRequiredInfoViews.BusinessInfo)
                  }}
                >
                  Back
                </SecondaryButton>
                <Button
                  id={SecondaryAccountSelectionView.SaveButton}
                  onClick={async () => {
                    await updateCounter(
                      getBasicInfoInput(retailerId, {
                        ...providedProfileData,
                        profileSnoozeCounter: null,
                        description: updatedDescription,
                        websiteUrl,
                      }),
                    )
                    await updatePrivacy({
                      accountId:
                        toGlobalId('Account', fromGlobalId(retailerId)?.id) ??
                        '',
                      privacy:
                        privacySelection === PrivacyAccountOptions.Show
                          ? true
                          : false,
                    })
                    await updatePrimaryAccount({
                      retailerId,
                      primaryAccount: false,
                    })
                    setOpen(false)
                    history.push(
                      generatePath(RETAILER_PROFILE, {
                        accountId: fromGlobalId(retailerId)?.id ?? '',
                      }),
                    )
                  }}
                  disabled={!privacySelection}
                >
                  Save
                </Button>
              </RightButtonContainer>
            </ActionsContainer>
          </Modal.Actions>
        )
      default:
        return (
          <Modal.Actions>
            <ActionsContainer>
              <LeftButtonContainer>
                <TextButton
                  id={AddressSelectionView.BrandAccountButton}
                  onClick={() => {
                    setView(LiteRetailerRequiredInfoViews.BrandSelection)
                  }}
                  disabled={loading}
                >
                  <StyledSecondaryAccountButton>
                    Account not owned by a retailer
                    <Tooltip
                      content={
                        // eslint-disable-next-line max-len
                        'Click here if the company that primarily uses this JOOR account is not a multi-brand retailer (i.e. boutique, department store, e-commerce).'
                      }
                      customTooltipStyles={{
                        width: '380px',
                        padding: '8px 12px',
                      }}
                      customContentStyles={{
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-word',
                        textAlign: 'justify',
                      }}
                      defaultPlacement="top"
                    >
                      <PlainIcon iconName="question" iconSize="small" />
                    </Tooltip>
                  </StyledSecondaryAccountButton>
                </TextButton>
              </LeftButtonContainer>
              <RightButtonContainer>
                <SecondaryButton
                  id={AddressSelectionView.SnoozeButton}
                  onClick={async () => {
                    window.localStorage.setItem(
                      LITE_RETAILER_MODAL_SNOOZED,
                      'true',
                    )
                    await updateCounter(
                      getBasicInfoInput(retailerId, {
                        ...providedProfileData,
                        profileSnoozeCounter:
                          (providedProfileData?.profileSnoozeCounter || 0) + 1,
                        description: updatedDescription,
                        websiteUrl,
                      }),
                    )
                    setOpen(false)
                  }}
                  disabled={
                    loading || providedProfileData?.profileSnoozeCounter === 3
                  }
                >
                  {`Remind me later (${
                    providedProfileData?.profileSnoozeCounter || 0
                  }/3)`}
                </SecondaryButton>
                <Button
                  id={AddressSelectionView.NextButton}
                  onClick={async () => {
                    if (selectedAddressId === googleAddress?.id) {
                      await updateAddress(
                        getAddressInput({
                          id: providedAddress.id,
                          name: providedAddress.name,
                          type: providedAddress.type,
                          address1: googleAddress.address1,
                          address2: '',
                          city: googleAddress.city,
                          state: googleAddress.state,
                          zip: googleAddress.zip,
                          country: googleAddress.country,
                          phone: googleAddress.phone,
                          yearEstablished: providedAddress.yearEstablished,
                          wholesaleRange: {
                            min: providedAddress.wholesaleRange?.min,
                            max: providedAddress.wholesaleRange?.max,
                          },
                          brandsCarried: providedAddress.brandsCarried,
                          categories: providedAddress.categories,
                          clienteleInfo: providedAddress.clienteleInfo,
                        }),
                        LiteRetailerRequiredInfoViews.AddressSelection,
                      )
                      await updateWebsiteAndDescription(
                        getBasicInfoInput(retailerId, {
                          ...providedProfileData,
                          websiteUrl,
                          description: updatedDescription,
                        }),
                      )
                    }
                    if (googleAddress?.id) {
                      await saveGoogleOption([
                        {
                          id: googleAddress.id ?? '',
                          accepted: selectedAddressId === googleAddress.id,
                        },
                      ])
                    }
                    setView(LiteRetailerRequiredInfoViews.BusinessInfo)
                  }}
                  disabled={loading}
                >
                  Next
                </Button>
              </RightButtonContainer>
            </ActionsContainer>
          </Modal.Actions>
        )
    }
  }

  return getModalActions()
}

export default ModalActions
