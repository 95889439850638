import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { renderErrorNotification } from 'store/notificationHandler/actions'
import { RESTORE_PANEL, TOGGLE_EDIT_MODE } from 'store/storefront/constants'

import { Modal } from 'components/Core'

import styles from './StorefrontSaveChanges.module.scss'
import { useUpsertStorefront } from 'features/Storefront/StorefrontNavbar/StorefrontNavbar.hooks'

const StorefrontSaveChanges = ({
  onClose,
  trackers = () => {},
  redirectTo,
}) => {
  const dispatch = useDispatch()
  const { loading, upsertStorefront } = useUpsertStorefront()
  const history = useHistory()
  const throwError = (text) => dispatch(renderErrorNotification(text))
  const actionButtons = [
    {
      disabled: false,
      type: 'primary',

      onClick: () => {},
      closeOnClick: true,
      label: 'Cancel',
      testId: '',
    },
    {
      disabled: false,
      type: 'primary',
      onClick: () => {
        dispatch({ type: TOGGLE_EDIT_MODE })
        dispatch({ type: RESTORE_PANEL })
        trackers()
        history.push(redirectTo)
      },
      closeOnClick: true,
      label: "Don't Save",
      testId: '',
    },
    {
      disabled: loading,
      type: 'secondary',
      onClick: () => {
        upsertStorefront()
          .then(({ data }) => {
            if (data.errors) {
              throwError('There was an error saving your custom profile')
            } else {
              dispatch({ type: TOGGLE_EDIT_MODE })
            }
          })
          .catch(() => {
            throwError('There was an error saving your custom profile')
          })
        trackers()
        history.push(redirectTo)
      },
      closeOnClick: true,
      label: 'Save',
      testId: '',
    },
  ]

  return (
    <Modal
      title={'Are you sure you want to leave this page?'}
      className={classNames(
        styles.StorefrontSaveChanges,
        styles.additionalClass,
      )}
      open
      onClose={onClose}
      large
      hideCloseButton
      actionButtons={actionButtons}
    >
      <div className={styles.title}>
        Leaving this page will discard any unsaved changes. Would you like to
        save changes before exiting?
      </div>
    </Modal>
  )
}

StorefrontSaveChanges.propTypes = {
  onClose: PropTypes.func.isRequired,
  trackers: PropTypes.func,
  redirectTo: PropTypes.string.isRequired,
}

export default StorefrontSaveChanges
