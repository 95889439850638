// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Video_Video__3dcK4 > div {\n  background-color: transparent;\n}\n.Video_Video__3dcK4 button.Video_pipButton__P7SuG {\n  padding: 0;\n  width: 30px;\n}\n.Video_Video__3dcK4 button.Video_pipButton__P7SuG > span {\n  display: initial;\n  position: initial;\n  margin: 0 -5px 0 0;\n}\n.Video_Video__3dcK4 button.Video_pipButton__P7SuG > span > span.Video_pipIcon__qEZxu {\n  display: initial;\n  position: initial;\n  margin: 0;\n}\n.Video_Video__3dcK4 button.Video_pipButton__P7SuG > span > span.Video_pipIcon__qEZxu > svg {\n  height: 16px;\n  width: 16px;\n  padding-top: 2px;\n}\n", "",{"version":3,"sources":["webpack://src/components/Core/Video/Video.less"],"names":[],"mappings":"AAAA;EACE,6BAA6B;AAC/B;AACA;EACE,UAAU;EACV,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,SAAS;AACX;AACA;EACE,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB","sourcesContent":[".Video > div {\n  background-color: transparent;\n}\n.Video button.pipButton {\n  padding: 0;\n  width: 30px;\n}\n.Video button.pipButton > span {\n  display: initial;\n  position: initial;\n  margin: 0 -5px 0 0;\n}\n.Video button.pipButton > span > span.pipIcon {\n  display: initial;\n  position: initial;\n  margin: 0;\n}\n.Video button.pipButton > span > span.pipIcon > svg {\n  height: 16px;\n  width: 16px;\n  padding-top: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Video": "Video_Video__3dcK4",
	"pipButton": "Video_pipButton__P7SuG",
	"pipIcon": "Video_pipIcon__qEZxu"
};
export default ___CSS_LOADER_EXPORT___;
