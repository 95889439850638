// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Expander_Expander__2uG1E {\n  cursor: pointer;\n}\n.Expander_Expander__2uG1E .Expander_disabled__3mGeL {\n  visibility: hidden;\n}\n", "",{"version":3,"sources":["webpack://src/components/Core/Expander/Expander.less"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".Expander {\n  cursor: pointer;\n}\n.Expander .disabled {\n  visibility: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Expander": "Expander_Expander__2uG1E",
	"disabled": "Expander_disabled__3mGeL"
};
export default ___CSS_LOADER_EXPORT___;
