import PropTypes from 'prop-types'

import { getTextFromSelectedDropdownOption } from 'utils/formatters'
import { getDisplayName, getUrlParam } from 'utils/mixpanel'

export const withAnalytics = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { mixpanel, location } = props

    const getStartOrderModalSubmittedParameters = ({
      brandOptions,
      collectionOptions,
      selectedCollectionId,
    }) => ({
      Brand: getTextFromSelectedDropdownOption(
        brandOptions,
        props.selectedBrandId,
      ),
      Linesheet: getTextFromSelectedDropdownOption(
        collectionOptions,
        selectedCollectionId,
      ),
      ...getUrlParam(location),
    })

    const trackers = {
      trackStartOrderModalSubmitted: (config) =>
        mixpanel.track(
          'Orders List_Start Web Order Submitted',
          getStartOrderModalSubmittedParameters(config),
        ),
      trackStartOrderModalCancelled: () =>
        mixpanel.track('Orders List_Start Web Order Cancelled'),
    }

    return <WrappedComponent {...props} {...trackers} />
  }

  Wrapper.propTypes = {
    mixpanel: PropTypes.object,
    location: PropTypes.object.isRequired,
  }
  Wrapper.defaultProps = {
    mixpanel: { track: () => {} },
  }

  Wrapper.displayName = `withAnalytics(${getDisplayName(WrappedComponent)})`
  return Wrapper
}

export default withAnalytics
