import { handleActions } from 'redux-actions'

export const defaultState = {
  mutationInFlight: false,
}

const reducer = handleActions(
  {
    'SEND_TO_BRAND/BEGIN_SEND': (state) => ({
      ...state,
      mutationInFlight: true,
    }),
    'SEND_TO_BRAND/END_SEND': (state) => ({
      ...state,
      mutationInFlight: false,
    }),
  },
  defaultState,
)

export default reducer
