import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Message } from 'semantic-ui-react'

import styles from './Toaster.less'

export const TIME_TO_DISMISS = 5000
export const TIME_TO_DISMISS_AFTER_HOVER = 2000
export const LONG_TIME_TO_DISMISS = 7000

class Toaster extends Component {
  componentDidMount = () => {
    this.startTimerToClose(
      this.props.long ? LONG_TIME_TO_DISMISS : TIME_TO_DISMISS,
    )
  }

  timeoutID = null

  startTimerToClose = (t) => {
    if (!this.props.isPersistent) {
      this.timeoutID = setTimeout(this.props.onDismiss || (() => {}), t)
    }
  }

  handleOnMouseOut = () => {
    this.startTimerToClose(TIME_TO_DISMISS_AFTER_HOVER)
  }

  handleOnMouseOver = () => {
    if (!this.props.isPersistent) {
      window.clearTimeout(this.timeoutID)
    }
  }

  toasterClass = () => {
    const { success, error, yellow } = this.props

    return classNames({
      [styles.notificationError]: error,
      [styles.notificationSuccess]: success,
      [styles.yellow]: yellow,
    })
  }

  buildLink = (pathname, linkText, search, baseURL, hash) =>
    !baseURL ? (
      <Link
        to={{
          pathname,
          search,
        }}
        data-testid="toastLink"
        className={styles.toastLink}
      >
        {linkText}
      </Link>
    ) : (
      <a
        href={
          new URL((pathname || '/') + (search || '') + (hash || ''), baseURL)
        }
        className={styles.toastLink}
        data-testid="toastLink"
      >
        {linkText}
      </a>
    )

  render() {
    return (
      <Message
        className={this.toasterClass()}
        onDismiss={this.props.onDismiss}
        error={this.props.error}
        warning={this.props.warning}
        info={this.props.info}
        success={this.props.success}
        hidden={false}
        compact
        onMouseOver={this.handleOnMouseOver}
        onFocus={this.handleOnMouseOver} // included with onMouseOver for accessibility
        onMouseOut={this.handleOnMouseOut}
        onBlur={this.handleOnMouseOut} // included with onMouseOut for accessibility
      >
        <Message.Header>{this.props.headerMessage}</Message.Header>
        <Message.Content>
          <div className={styles.messageIcon} />
          <div>
            {this.props.children}
            {this.props.link !== {} &&
              this.buildLink(
                this.props.link.pathname,
                this.props.link.linkText,
                this.props.link.search,
                this.props.link.baseURL,
                this.props.link.hash,
              )}
          </div>
        </Message.Content>
      </Message>
    )
  }
}

Toaster.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  headerMessage: PropTypes.string,
  children: PropTypes.node,
  onDismiss: PropTypes.func,
  long: PropTypes.bool,
  yellow: PropTypes.bool,
  isPersistent: PropTypes.bool,
  link: PropTypes.shape({
    pathname: PropTypes.string,
    linkText: PropTypes.string,
    search: PropTypes.string,
    baseURL: PropTypes.string,
    hash: PropTypes.string,
  }),
}

Toaster.defaultProps = {
  error: false,
  success: false,
  info: false,
  warning: false,
  headerMessage: '',
  children: '',
  long: false,
  yellow: false,
  onDismiss: null,
  isPersistent: false,
  link: { pathname: '', linkText: '', search: '', baseURL: '', hash: '' },
}

export default Toaster
