import {
  LINESHEET_GALLERY,
  PRODUCT_GALLERY,
} from 'features/Storefront/constants'

export const getStorefrontProductIds = (storefront) => {
  const filteredRows = storefront?.rows?.filter(
    (row) =>
      row?.rowType === PRODUCT_GALLERY || row?.rowType === LINESHEET_GALLERY,
  )
  const productElements = filteredRows?.reduce(
    (acc, filteredRow) => [...acc, ...filteredRow?.elements],
    [],
  )
  const productIds = productElements?.reduce(
    (acc, el) =>
      el?.content?.productId ? [...acc, el?.content?.productId] : acc,
    [],
  )
  const collectionIds = productElements?.reduce(
    (acc, el) =>
      el?.content?.collectionId ? [...acc, el?.content?.collectionId] : acc,
    [],
  )

  return {
    collectionIds,
    productIds,
  }
}

export const combineCollectionProducts = (
  productsInfo = [],
  collectionProductsVisibility = [],
) =>
  productsInfo.map((productInfo) => {
    const matchingCollection = collectionProductsVisibility.find(
      (collectionProduct) => collectionProduct.id === productInfo.id,
    )
    return {
      ...productInfo,
      collection: {
        ...matchingCollection?.collection,
        ...productInfo?.collection,
      },
    }
  })
