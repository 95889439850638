import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Dropdown as BaseDropdown } from 'semantic-ui-react'

import ConditionalWrap from 'components/Core/ConditionalWrap'
import Grid from 'components/Core/Grid/Grid'

import styles from './Dropdown.less'

export const NO_RESULTS_MESSAGE = 'No results found.'

class Dropdown extends Component {
  state = {}

  render() {
    const {
      children,
      className,
      disabled,
      fluid,
      multiple,
      openOnFocus,
      options,
      onChange,
      placeholder,
      noResultsMessage,
      renderLabel,
      search,
      nullable,
      text,
      upward,
      value,
      withGrid,
      defaultValue,
      parentClassName,
      onSearchChange,
      loading,
      selectOnBlur,
      filter,
      withEllipsis,
      id,
      ...rest
    } = this.props

    const childProps = {
      children,
      className,
      disabled,
      fluid,
      multiple,
      openOnFocus,
      options,
      onChange,
      placeholder,
      noResultsMessage,
      renderLabel,
      search: filter || search,
      nullable: nullable ? 1 : 0,
      text,
      upward,
      selection: true,
      scrolling: true,
      onSearchChange,
      loading,
      selectOnBlur,
      ...rest,
    }

    if (defaultValue !== null) {
      childProps.defaultValue = defaultValue
    }

    if (value !== null) {
      childProps.value = value
    }

    if ((nullable && defaultValue === null) || (nullable && value !== null)) {
      const nullableOption = {
        key: 'nullableOption',
        text: 'Select one',
        value: null,
      }

      childProps.options.unshift(nullableOption)
    }

    return (
      <ConditionalWrap
        condition={withGrid}
        wrap={(wrappedChildren) => (
          <Grid.Col sm={2} md={2} lg={2}>
            {wrappedChildren}
          </Grid.Col>
        )}
      >
        <div
          id={id}
          data-testid="dropdown"
          className={classNames(
            { [styles.filter]: filter, [styles.withEllipsis]: withEllipsis },
            styles.Dropdown,
            parentClassName,
          )}
        >
          <BaseDropdown {...childProps}>{childProps.children}</BaseDropdown>
        </div>
      </ConditionalWrap>
    )
  }
}

export const validOptions = [
  'key',
  'text',
  'disabled',
  'value',
  'icon',
  'onClick',
  'className',
  'code',
  'id',
]
Dropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool,
  nullable: PropTypes.bool,
  multiple: PropTypes.bool,
  options: PropTypes.arrayOf((propValue, key, componentName) => {
    const validKeys = Object.keys(propValue[key]).every((propKey) =>
      validOptions.includes(propKey),
    )
    return validKeys
      ? null
      : new Error(`Invalid options were passed to ${componentName}`)
  }),
  onChange: PropTypes.func,
  openOnFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  noResultsMessage: PropTypes.string,
  renderLabel: PropTypes.func,
  search: PropTypes.bool,
  text: PropTypes.string,
  parentClassName: PropTypes.string,
  upward: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  withGrid: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ]),
  onSearchChange: PropTypes.func,
  loading: PropTypes.bool,
  selectOnBlur: PropTypes.bool,
  filter: PropTypes.bool,
  withEllipsis: PropTypes.bool,
}

Dropdown.defaultProps = {
  children: null,
  parentClassName: '',
  className: '',
  disabled: false,
  fluid: false,
  nullable: false,
  multiple: false,
  options: [],
  onChange: () => {},
  openOnFocus: true,
  placeholder: '',
  noResultsMessage: NO_RESULTS_MESSAGE,
  renderLabel: (ref) => ref.text,
  search: false,
  text: '',
  upward: false,
  value: null,
  withGrid: false,
  defaultValue: null,
  onSearchChange: () => {},
  loading: false,
  selectOnBlur: true, // By default is true in the semantic ui component
  filter: false,
  withEllipsis: false,
}

Dropdown.Item = BaseDropdown.Item

export default Dropdown
