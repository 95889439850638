import { cloneElement } from 'react'

import PropTypes from 'prop-types'

import { Divider, SwatchCarousel } from 'components/Core'

import BaseProductCard from './BaseProductCard'
import { useProductCardContext } from './BaseProductCard.context'
import { ProductPropType } from './propTypes'

const ProductCardSwatchCarousel = ({
  swatchesInfo,
  displayCheckboxes,
  checkboxOnClick,
  selectedCheckboxes,
  disabledCheckboxes,
}) => {
  const { handleSwatchSelection, selectedColorId } = useProductCardContext()

  return (
    <SwatchCarousel
      swatchesInfo={swatchesInfo}
      onClick={handleSwatchSelection}
      selectedColorId={selectedColorId}
      displayCheckboxes={displayCheckboxes}
      checkboxOnClick={checkboxOnClick}
      selectedCheckboxes={selectedCheckboxes}
      disabledCheckboxes={disabledCheckboxes}
    />
  )
}
const ProductCard = ({
  product,
  className,
  children,
  displayCheckboxes,
  displayProductCode,
  checkboxOnClick,
  selectedVariants,
  disabledVariants,
  enableNoting,
  notingNode,
  displayExternalMediaThumbnail,
  displayExternalMediaIndicators,
}) => {
  const { swatchesInfo } = product

  return (
    <BaseProductCard
      data-testid="BaseProductCard"
      product={product}
      className={className}
      displayProductCode={displayProductCode}
      displayExternalMediaThumbnail={displayExternalMediaThumbnail}
      displayExternalMediaIndicators={displayExternalMediaIndicators}
    >
      {enableNoting ? (
        cloneElement(notingNode, {
          swatchesInfo,
        })
      ) : (
        <ProductCardSwatchCarousel
          data-testid="ProductCardSwatchCarousel"
          swatchesInfo={swatchesInfo}
          displayCheckboxes={displayCheckboxes}
          checkboxOnClick={checkboxOnClick}
          selectedCheckboxes={selectedVariants}
          disabledCheckboxes={disabledVariants}
        />
      )}

      <Divider verticalMargin={10} horizontalMargin={0} />
      {children}
    </BaseProductCard>
  )
}

ProductCard.propTypes = {
  product: ProductPropType,
  children: PropTypes.node,
  className: PropTypes.string,
  displayProductCode: PropTypes.bool,
  displayCheckboxes: PropTypes.bool,
  displayExternalMediaThumbnail: PropTypes.bool,
  displayExternalMediaIndicators: PropTypes.bool,
  checkboxOnClick: PropTypes.func,
  selectedVariants: PropTypes.object,
  disabledVariants: PropTypes.array,
  enableNoting: PropTypes.bool,
  notedVariants: PropTypes.array,
}

ProductCard.defaultProps = {
  product: {
    code: '',
    swatchesInfo: [],
  },
  children: null,
  className: '',
  notingNode: ProductCardSwatchCarousel,
  displayCheckboxes: false,
  displayProductCode: true,
  checkboxOnClick: () => {},
  selectedVariants: {},
  disabledVariants: [],
  enableNoting: false,
  notedVariants: [],
  displayExternalMediaThumbnail: false,
  displayExternalMediaIndicators: false,
}

export default ProductCard
