import { Query } from '@apollo/client/react/components'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'
import { getCollectionsFormatted } from 'shop/formatters'

export const MINIMUM_CHARACTERS_QUERY = 2
const MAX_INITIAL_COLLECTIONS = 10

const collectionsQuery = atlas`#graphql
  query CollectionsQueryDupe5(
    $brandIds: [ID!]
    $searchText: String
    $limit: Int
    $orderBy: CollectionOrderByField
  ) {
    collections(
      brandIds: $brandIds
      filters: { archived: false, searchText: $searchText }
      limit: $limit
      orderBy: { field: $orderBy }
    ) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const shouldSkipCollectionQuery = (props) => {
  const {
    isLiteRetailer,
    brandId,
    isAccountTypeRetailer,
    collectionSearchText = '',
    isAccountTypeBrand,
  } = props

  const hasInput = collectionSearchText.length > 0
  if (isAccountTypeRetailer) {
    return (
      !isLiteRetailer ||
      !brandId ||
      (hasInput && collectionSearchText.length < MINIMUM_CHARACTERS_QUERY)
    )
  }

  return (
    isAccountTypeBrand &&
    hasInput &&
    collectionSearchText.length < MINIMUM_CHARACTERS_QUERY
  )
}

export const withCollections = (WrappedComponent) => (props) => {
  const { brandId, isAccountTypeRetailer, collectionSearchText } = props

  const isEmptyInput = collectionSearchText.length === 0
  const variables = {
    brandIds: isAccountTypeRetailer && brandId ? [brandId] : [],
    ...{ searchText: collectionSearchText },
    limit: isEmptyInput ? MAX_INITIAL_COLLECTIONS : 0,
    orderBy: isEmptyInput ? 'POSITION' : 'NAME',
  }

  return (
    <Query
      client={atlasClient}
      query={collectionsQuery}
      variables={variables}
      skip={shouldSkipCollectionQuery({
        ...props,
      })}
    >
      {({ loading, error, data }) => {
        const customProps = {
          ...props,
          collectionsLoading: loading,
          collections:
            !loading && !error && data
              ? getCollectionsFormatted(
                  relayConnectionToArray(data.collections),
                )
              : getCollectionsFormatted([]),
        }
        return <WrappedComponent {...customProps} />
      }}
    </Query>
  )
}
