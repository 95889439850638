import isEmpty from 'lodash/isEmpty'

import * as constants from 'features/Storefront/constants'

export const checkElementsRequiredContent = (rowType, content) => {
  if (isEmpty(content)) return ''
  switch (rowType) {
    case constants.TEXT_WIDGET:
      return !content && 'text'
    case constants.IMAGE_WIDGET:
      return !content.imageId && 'image'
    case constants.VIDEO_WIDGET:
      return !content.videoId && !content.videoUrl && 'video'
    case constants.DOCUMENT_WIDGET:
      return (!content.lookbookId || !content.lookbookTitle) && 'document'
    case constants.ORB_WIDGET:
      return !content.externalVideoId && 'video'
    case constants.PRODUCT_WIDGET:
      return (!content.collectionId || !content.productId) && 'product'
    case constants.LINESHEET_WIDGET:
      return (!content.collectionId || !content.collectionTitle) && 'collection'
    case constants.SHOWROOM_BRAND_WIDGET:
      return !content.showroomBrandId && 'brand'
    case constants.INSTAGRAM_WIDGET:
      return !content.instagramAuthId && 'instagram account'
    case constants.COLLECTIONS_WIDGET:
      return (
        (!content.showroomCollectionId || !content.showroomCollectionTitle) &&
        'collection'
      )
    default:
      return ''
  }
}
