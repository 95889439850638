import { defineMessages } from 'react-intl'

export default defineMessages({
  dragFiles: {
    id: 'core.uploader.dragFiles',
    defaultMessage:
      'Drag and drop {multi, plural, one {file} other {files}} here',
  },
  or: {
    id: 'core.uploader.or',
    defaultMessage: 'Or',
  },
  selectFiles: {
    id: 'core.uploader.selectFiles',
    defaultMessage: 'Select {multi, plural, one {file} other {files}}',
  },
})
