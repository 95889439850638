import { useSelector } from 'react-redux'

import { isAccountTypeRetailer } from 'store/currentUser/selectors'

import { PASSPORT_LANDING } from 'routes/paths'

import MenuLinkWrapper from '../MenuLinkWrapper/MenuLinkWrapper'
import messages from 'features/NavbarRevamp/constants/navbarRevamp.messages'
import { PassportOptionIds } from 'features/NavbarRevamp/navbarRevamp.ids'

const PassportMenuLink = () => {
  const isRetailerAccount = useSelector<object, boolean>(isAccountTypeRetailer)

  if (isRetailerAccount) {
    return null
  }
  return (
    <MenuLinkWrapper
      to={PASSPORT_LANDING}
      id={PassportOptionIds.PassportTrigger}
      data-testid={PassportOptionIds.PassportTrigger}
    >
      {messages.passport}
    </MenuLinkWrapper>
  )
}

export default PassportMenuLink
