import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'

import styles from './ActionDropup.less'

const noOp = () => {}

const Action = ({ children, disabled, onClick }) => (
  <div
    className={classNames(styles.action, { [styles.disabled]: disabled })}
    onClick={disabled ? noOp : onClick}
  >
    {children}
  </div>
)

Action.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

Action.defaultProps = {
  disabled: false,
  onClick: () => {},
}

class ActionDropup extends Component {
  state = {
    open: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      open: prevState.open && !nextProps.disabled,
    }
  }

  toggleOpen = (disabled) => () => {
    if (!disabled) {
      this.setState((prevState) => ({
        open: !prevState.open,
      }))
    }
  }

  render() {
    const { children, disabled, placeholder } = this.props
    const { open } = this.state
    const iconClass = classNames(styles.dropupIcon, {
      [styles.open]: open,
    })
    return (
      <div className={styles.ActionDropup}>
        <span
          className={classNames(styles.placeholder, {
            [styles.disabled]: disabled,
          })}
          onClick={this.toggleOpen(disabled)}
        >
          {placeholder}
          <Icon name="dropup" className={iconClass} />
        </span>
        {open && <div className={styles.actions}>{children}</div>}
      </div>
    )
  }
}

ActionDropup.propTypes = {
  placeholder: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  disabled: PropTypes.bool,
}

ActionDropup.defaultProps = {
  disabled: false,
}

ActionDropup.Action = Action

export default ActionDropup
