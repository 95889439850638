import { handleActions } from 'redux-actions'

export const defaultState = {
  selectedItemColorIds: [],
  selectedTagIds: [],
  selectedTagGrouping: '',
  appliedTagGrouping: [],
  highlightedItemColors: [],
  highlightedDoors: [],
  itemColorPageNumber: 1,
  itemColorsPerPage: 5,
  mutationsLoadingState: false,
}

const GROUP_BY_TAGS_MAX = 2

const reducer = handleActions(
  {
    'GROUP_BY_TAGS/SET_APPLIED_TAG_GROUPING': (state, action) => ({
      ...state,
      appliedTagGrouping: action.payload,
      selectedItemColorIds: [],
    }),
    'GROUP_BY_TAGS/SET_SELECTED_TAG_IDS': (state, action) => ({
      ...state,
      selectedTagIds: action.payload.slice(0, GROUP_BY_TAGS_MAX),
      selectedTagGrouping: '',
    }),
    'GROUP_BY_TAGS/SET_SELECTED_TAG_GROUPING': (state, action) => ({
      ...state,
      selectedTagGrouping: action.payload,
    }),
    'HIGHLIGHTING/SET_HIGHLIGHTED_ITEM_COLORS': (state, action) => ({
      ...state,
      highlightedItemColors: action.payload,
    }),
    'HIGHLIGHTING/SET_HIGHLIGHTED_DOORS': (state, action) => ({
      ...state,
      highlightedDoors: action.payload,
    }),
    'PAGING/SET_ITEM_COLOR_PAGE': (state, action) => ({
      ...state,
      itemColorPageNumber: action.payload,
      selectedItemColorIds: [],
    }),
    'PAGING/SET_ITEM_COLORS_PER_PAGE': (state, action) => ({
      ...state,
      itemColorsPerPage: action.payload,
    }),
    'LOADING/SET_MUTATIONS_LOADING_STATE': (state, action) => ({
      ...state,
      mutationsLoadingState: action.payload,
    }),
    'ITEM_COLOR/TOGGLE': (state, action) => {
      const { selectedItemColorIds } = state
      const { payload } = action
      if (selectedItemColorIds.includes(payload)) {
        return {
          ...state,
          selectedItemColorIds: selectedItemColorIds.filter(
            (itemColorId) => itemColorId !== payload,
          ),
        }
      }

      return {
        ...state,
        selectedItemColorIds: [...selectedItemColorIds, payload],
      }
    },
    'ITEM_COLOR/TOGGLE_ALL': (state, action) => {
      const { selectedItemColorIds } = state
      const { payload } = action
      return {
        ...state,
        selectedItemColorIds:
          selectedItemColorIds.length === payload.length ? [] : payload,
      }
    },
  },
  defaultState,
)

export default reducer
