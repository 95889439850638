import PropTypes from 'prop-types'

import { generateOrderGroupId } from 'shop/products/utils'

import BrandStartOrderModal from './BrandStartOrderModal'
import RetailerStartOrderModal from './RetailerStartOrderModal'

const StartOrderModalWrapper = ({
  isAccountTypeRetailer,
  userId,
  setOrderGroupId,
  ...rest
}) => {
  const saveNewOrderGroupId = () => {
    const orderGroupId = generateOrderGroupId(userId)
    setOrderGroupId(orderGroupId)
  }
  if (isAccountTypeRetailer) {
    return (
      <RetailerStartOrderModal
        saveNewOrderGroupId={saveNewOrderGroupId}
        {...rest}
      />
    )
  }
  return (
    <BrandStartOrderModal saveNewOrderGroupId={saveNewOrderGroupId} {...rest} />
  )
}

StartOrderModalWrapper.propTypes = {
  isAccountTypeRetailer: PropTypes.bool.isRequired,
}

export default StartOrderModalWrapper
