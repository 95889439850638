import { FC, useCallback, useEffect, useState } from 'react'

import { createPortal } from 'react-dom'

/**
 * This hook is created to wrap those elements (mainly modals, dialogs...)
 * which we want to be mounted on the 'root-popups' container.
 *
 * @returns the Portal wrapper component
 */

const usePortal = (): FC => {
  const [rootPopupsNode, setRootPopupsNode] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const node = document.getElementById('root-popups')
    setRootPopupsNode(node)
  }, [])

  const Portal: FC = useCallback(
    ({ children }) => rootPopupsNode && createPortal(children, rootPopupsNode),
    [rootPopupsNode],
  )

  return Portal
}

export default usePortal
