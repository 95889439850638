import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Popup } from 'semantic-ui-react'

import styles from './Tooltip.less'

export const BLACK = 'BLACK'
export const YELLOW = 'YELLOW'
export const WHITE = 'WHITE'

const Tooltip = ({ className, content, color, ...rest }) => (
  <Popup
    className={classNames(
      styles.Tooltip,
      {
        [styles.black]: color === BLACK,
        [styles.yellow]: color === YELLOW,
        [styles.white]: color === WHITE,
      },
      className,
    )}
    {...rest}
  >
    {content}
  </Popup>
)

Tooltip.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  color: PropTypes.string,
}

Tooltip.defaultProps = {
  className: '',
  content: null,
  color: BLACK,
}

export default Tooltip
