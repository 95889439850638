import { Button, SecondaryButton } from '@joor/design-system'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { INavbarCartDropdown } from 'features/NavbarRevamp/navbarRevamp.types'

export const CartDropdownContainer = styled.div<INavbarCartDropdown>`
  display: flex;
  margin-left: 2px;

  .trigger > div {
    padding: 0 2px;

    > div {
      height: 24px;
    }
  }

  .dropdownMenuGroup {
    padding: 8px;
  }

  @media only screen and (max-width: 1024px) {
    display: ${({ isInsideDropdown }) => (isInsideDropdown ? 'flex' : 'none')};
  }
`
export const StyledCartIconLink = styled(Link)`
  position: relative;
  border-bottom: none;
  padding: 0 8px;
  margin: 14px 0;
  margin-right: 4px;

  &:hover {
    border: 1px solid var(--color-primary-400);
    border-radius: 4px;
    margin: 13px -1px;
    margin-right: 3px;
  }

  @media only screen and (max-width: 1024px) {
    display: flex;
    margin: 0;
    width: 100%;
    padding: 2px 0;
    &:hover {
      margin: -1px;
    }
  }
`

export const CartMenuIconContainer = styled.div<INavbarCartDropdown>`
  position: relative;
  cursor: pointer;

  svg * {
    fill: ${({ isInsideDropdown }) =>
      isInsideDropdown ? 'var(--color-primary-600)' : 'white'};
  }

  @media only screen and (max-width: 1024px) {
    & > span {
      width: 20px;
      height: 20px;
      padding: 2px 0;

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
`

export const CartMenuLabel = styled.span`
  margin-left: 4px;
  color: var(--color-secondary-800);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: 16px;
  margin-right: auto;
  padding: 4px 0px;
`

export const QuantityBadge = styled.span`
  position: absolute;
  top: -8px;
  left: 16px;
  z-index: 999;

  @media only screen and (max-width: 1024px) {
    top: -12px;
    left: 8px;
  }
`

export const BrandCartContainer = styled.div`
  width: 238px;
  cursor: auto;
  text-align: left !important;
`

export const MenuHeader = styled.div`
  color: var(--color-primary-800);
  font-size: var(--size-font-mediumlarge);
  font-weight: var(--font-weight-regular);
  line-height: 32px;
`

export const MenuBody = styled.div`
  margin-top: 8px;
  margin-right: -3px;
  max-height: 200px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 14px;
  scrollbar-color: #b2b2b2 transparent;

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`

export const OrderTitle = styled.h2`
  &&& {
    color: var(--color-primary-800);
    margin: 0;
    padding-bottom: 14px;
    font-family: Montserrat;
    font-size: var(--size-font-small);
    font-weight: var(--font-weight-semibold);
    line-height: 16px;
    letter-spacing: var(--letter-spacing-tighter);
    text-align: left !important;
  }
`

export const RetailerOrder = styled.div`
  padding-bottom: 14px;

  :not(:last-child) {
    border-bottom: 1px solid var(--color-primary-400);
  }

  :last-child {
    padding-bottom: 0px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
`

const UnitColumn = css`
  padding: 0 5px;
  border-right: 0.5px solid var(--color-primary-400);
  border-left: 0.5px solid var(--color-primary-400);

  :first-child {
    padding-left: 0;
    border-left: none;
  }

  :last-child {
    padding-right: 0;
    border-right: none;
  }
`

export const Unit = styled.div`
  ${UnitColumn}
`

export const Price = styled.div`
  ${UnitColumn}
`

export const UnitLabel = styled.p`
  font-size: var(--size-font-extrasmall);
  font-weight: var(--font-weight-medium);
  letter-spacing: var(--letter-spacing-tight);
  &&& {
    line-height: 12px;
    margin: 0;
  }
`

export const UnitAmount = styled.p`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  &&& {
    line-height: 16px;
    margin: 0;
  }
`

export const MenuSubHeader = styled.div`
  font-weight: var(--font-weight-bold);
  line-height: 20px;

  &&& {
    font-size: var(--size-font-base);
  }
`

export const ViewCartButton = styled(Button)`
  width: 100%;
  &&& {
    font-size: 12px;
    padding: 8px 0;
  }
`

export const LinkWrapper = styled.div`
  padding-top: 8px;
  border-top: 1px solid var(--color-primary-400);
`

export const ReportContainer = styled.div`
  display: flex;
  margin-top: 14px;

  > a {
    margin-top: -1px;
  }
`

export const ReportsLabel = styled.span`
  font-size: var(--size-font-extrasmall);
  font-weight: var(--font-weight-semibold);
  color: var(--color-primary-800);
  line-height: 10px;
  letter-spacing: var(--letter-spacing-tight);
  padding: 5px 0;
  margin-right: 14px;
  height: 20px;
`

export const ReportButton = styled(SecondaryButton)`
  height: 20px;
  &&& {
    padding: 2px 8px;

    span {
      color: var(--color-primary-900);
      font-size: 8px;
      font-weight: var(--font-weight-medium);
      letter-spacing: var(--letter-spacing-tighter);
      line-height: 16px;
    }
  }

  :first-child {
    &&& {
      margin-right: 14px;
    }
  }
`
