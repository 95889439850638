import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { Modal } from '@joor/design-system'
import { ModalProvider, SquareDotLoadingIndicator } from '@joor/design-system'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { RetailerImpersonationFeedbackOption } from '__generated__/atlas-types'

import { useRenderLiteRetailerRequiredInfoModal } from 'hooks/useRenderLiteRetailerRequiredInfoModal'
import { isLiteRetailer } from 'store/currentUser/selectors'
import { userIsLoggedInAsAdmin } from 'store/currentUser/selectors'

import {
  useGoogleInfo,
  useProvidedInfo,
} from './LiteRetailerRequiredInfoModal.queries'
import { isGoogleAddressComplete } from './LiteRetailerRequiredInfoModal.utils'
import ModalActions from './components/ModalActions/ModalActions'
import ModalContent from './components/ModalContent/ModalContent'
import { RetailerProfileLocation } from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

type LiteRetailerRequiredInfoModalProps = {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export enum LiteRetailerRequiredInfoViews {
  AddressSelection = 'address-selection',
  AddressEdition = 'address-edition',
  BusinessInfo = 'business-info',
  BrandSelection = 'brand-selection',
  SecondaryAccount = 'secondary-account',
}

export enum PrivacyAccountOptions {
  Hide = 'hide',
  Show = 'show',
}

export const StyledBackgroundComponent = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  // This is needed to override navbar and other elements
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
`
type StyledModalProps = {
  $isAdmin: boolean
}
export const StyledModal = styled(Modal)<StyledModalProps>`
  overflow: unset;
  position: absolute;
  top: 80px;
  > span:first-child {
    display: ${({ $isAdmin }): string => ($isAdmin ? 'auto' : 'none')};
  }
  > header {
    border-radius: var(--size-border-radius-medium)
      var(--size-border-radius-medium) 0 0;
    background: #ebedf0;
  }
`
export const LoaderContainer = styled.div`
  height: 262px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LiteRetailerRequiredInfoModal: React.FC<LiteRetailerRequiredInfoModalProps> = ({
  isOpen,
  setOpen,
}) => {
  const isAdmin = useSelector(userIsLoggedInAsAdmin)
  const [selectedAddressId, setSelectedAddressId] = useState<string>('')
  const [updatedProvidedAddress, setUpdatedProvidedAddress] = useState<
    Partial<RetailerProfileLocation>
  >({})
  const [updatedWebsite, setUpdatedWebsite] = useState<string>('')
  const [updatedDescription, setUpdatedDescription] = useState<string>('')
  const [
    impersonationReason,
    setImpersonationReason,
  ] = useState<RetailerImpersonationFeedbackOption>(
    RetailerImpersonationFeedbackOption.UNKNOWN,
  )
  const [
    privacySelection,
    setPrivacySelection,
  ] = useState<PrivacyAccountOptions | null>(null)
  const [otherReason, setOtherReason] = useState<string>('')
  const [view, setView] = useState<LiteRetailerRequiredInfoViews>(
    LiteRetailerRequiredInfoViews.AddressSelection,
  )

  const {
    retailerId,
    providedAddress,
    providedProfileData,
    loading: providedInfoLoading,
    refetchProvidedAddress,
  } = useProvidedInfo()
  const {
    googleAddress,
    googleWebsite,
    loading: googleInfoLoading,
  } = useGoogleInfo()

  const loading = providedInfoLoading || googleInfoLoading

  useEffect(() => {
    if (!Boolean(selectedAddressId) && !loading) {
      const shouldPreselectGoogle =
        isGoogleAddressComplete(googleAddress) && Boolean(googleWebsite)
      const addressId = shouldPreselectGoogle
        ? googleAddress.id
        : providedAddress?.id
      setSelectedAddressId(addressId)
    }
    if (providedAddress?.id && !providedInfoLoading) {
      setUpdatedProvidedAddress(providedAddress)
    }
    if (providedProfileData?.websiteUrl) {
      setUpdatedWebsite(providedProfileData?.websiteUrl)
    }
    if (providedProfileData?.description) {
      setUpdatedDescription(providedProfileData?.description)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providedInfoLoading, googleInfoLoading])

  return (
    <ModalProvider backgroundComponent={StyledBackgroundComponent}>
      <StyledModal
        $isAdmin={isAdmin}
        // @ts-expect-error
        onEscapeKeydown={() => {}}
        onBackgroundClick={() => {}}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        title={{
          text: 'Required: Verify details for your account',
          iconName: 'information',
        }}
      >
        {loading ? (
          <LoaderContainer>
            <SquareDotLoadingIndicator />
          </LoaderContainer>
        ) : (
          <ModalContent
            googleAddress={googleAddress}
            googleWebsite={googleWebsite}
            impersonationReason={impersonationReason}
            otherReason={otherReason}
            privacySelection={privacySelection}
            selectedAddressId={selectedAddressId}
            setImpersonationReason={setImpersonationReason}
            setOtherReason={setOtherReason}
            setPrivacySelection={setPrivacySelection}
            setSelectedAddressId={setSelectedAddressId}
            setUpdatedDescription={setUpdatedDescription}
            setUpdatedProvidedAddress={setUpdatedProvidedAddress}
            setUpdatedWebsite={setUpdatedWebsite}
            setView={setView}
            updatedDescription={updatedDescription}
            updatedProvidedAddress={updatedProvidedAddress}
            updatedWebsite={updatedWebsite}
            view={view}
          />
        )}
        <ModalActions
          googleAddress={googleAddress}
          impersonationReason={impersonationReason}
          loading={loading}
          otherReason={otherReason}
          privacySelection={privacySelection}
          providedAddress={providedAddress}
          providedProfileData={providedProfileData ?? {}}
          refetchProvidedAddress={refetchProvidedAddress}
          retailerId={retailerId}
          selectedAddressId={selectedAddressId}
          setOpen={setOpen}
          setSelectedAddressId={setSelectedAddressId}
          setUpdatedProvidedAddress={setUpdatedProvidedAddress}
          setUpdatedWebsite={setUpdatedWebsite}
          setView={setView}
          updatedDescription={updatedDescription}
          updatedProvidedAddress={updatedProvidedAddress}
          updatedWebsite={updatedWebsite}
          view={view}
        />
      </StyledModal>
    </ModalProvider>
  )
}

const LiteRetailerRequiredInfoModalContainer = () => {
  const isAccountLiteRetailer = useSelector(isLiteRetailer)
  const [
    liteRetailerRequiredInfoOpen,
    setLiteRetailerRequiredInfoOpen,
  ] = useState(false)
  const {
    shouldOpenLiteRetailerRequiredInfoModal,
    loading: shouldOpenLiteRetailerRequiredInfoModalLoading,
  } = useRenderLiteRetailerRequiredInfoModal()

  useEffect(() => {
    if (
      isAccountLiteRetailer &&
      !shouldOpenLiteRetailerRequiredInfoModalLoading &&
      shouldOpenLiteRetailerRequiredInfoModal
    ) {
      setLiteRetailerRequiredInfoOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAccountLiteRetailer, shouldOpenLiteRetailerRequiredInfoModalLoading])

  return (
    <LiteRetailerRequiredInfoModal
      isOpen={liteRetailerRequiredInfoOpen}
      setOpen={setLiteRetailerRequiredInfoOpen}
    />
  )
}

export default LiteRetailerRequiredInfoModalContainer
