import compose from 'lodash/flowRight'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { withMixpanel } from 'utils/sdks/mixpanel'

import {
  changeCollectionSearchText,
  changeRetailerSearchText,
  setRetailerId,
} from 'store/orderList/brandStartOrderModal/actions'
import {
  getRetailerId,
  getRetailerSearchText,
} from 'store/orderList/brandStartOrderModal/selectors'
import { setPageLoaderActive } from 'store/pageLoader/actions'
import {
  applySelectedFilters,
  setDoors,
  setEvent,
  setFilter,
  setGroupByTagInfo,
  setOrderPriceType,
  setOrderTypeId,
  setRetailer,
  setSearchText,
  setSortByTagInfo,
  setWarehouse,
  toggleDeselectAllFilters,
} from 'store/shop/actions'

import { withAnalytics } from 'modals/OrderList/StartOrderModal/BrandStartOrderModal/BrandStartOrderModal.analytics'

import BrandStartOrderModal from './BrandStartOrderModal.component'
import {
  withConnectedAccounts,
  withInitialBrandStartOrderInfo,
  withRetailersData,
} from './BrandStartOrderModal.queries'

const mapStateToProps = (state) => ({
  accountId: state.currentUser.account.accountId,
  retailerId: getRetailerId(state),
  retailerSearchText: getRetailerSearchText(state),
})

const mapDispatchToProps = (dispatch) => ({
  changeRetailerSearchText: (searchText) =>
    dispatch(changeRetailerSearchText(searchText)),
  setPageLoaderActive: (pageLoaderIsActive) =>
    dispatch(setPageLoaderActive(pageLoaderIsActive)),
  setRetailer: (data) => dispatch(setRetailer(data)),
  setDoors: (data) => dispatch(setDoors(data)),
  setOrderPriceType: (data) => dispatch(setOrderPriceType(data)),
  setWarehouse: (data) => dispatch(setWarehouse(data)),
  setRetailerId: (data) => dispatch(setRetailerId(data)),
  setEvent: (data) => dispatch(setEvent(data)),
  setFilter: (data) => dispatch(setFilter(data)),
  setOrderTypeId: (data) => dispatch(setOrderTypeId(data)),
  changeCollectionSearchText: (data) =>
    dispatch(changeCollectionSearchText(data)),
  toggleDeselectAllFilters: (data) => dispatch(toggleDeselectAllFilters(data)),
  applySelectedFilters: (data) => dispatch(applySelectedFilters(data)),
  setSearchText: (data) => dispatch(setSearchText(data)),
  setSortByTagInfo: (data) => dispatch(setSortByTagInfo(data)),
  setGroupByTagInfo: (data) => dispatch(setGroupByTagInfo(data)),
})

const withRedux = connect(mapStateToProps, mapDispatchToProps)

const BrandStartOrderModalContainer = compose(
  withRouter,
  withMixpanel,
  withRedux,
  withInitialBrandStartOrderInfo,
  withConnectedAccounts,
  withRetailersData,
  withAnalytics,
)(BrandStartOrderModal)

export default function BrandStartOrderModalContainerWrapper(props) {
  return <BrandStartOrderModalContainer {...props} />
}
