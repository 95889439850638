export const isValidEmailAddress = (emailAddress) => {
  const re = /\S+@\S+\.\S+/
  return re.test(emailAddress)
}

export const isValidURL = (url, HTTPProtocol = true) => {
  const HTTPre = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  const re = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
  return HTTPProtocol ? HTTPre.test(url) : re.test(url)
}

export const isValidYoutubeUrl = (url) => {
  const youtubeRe = /(?:http(?:s)?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\?&\"'<> #]+)/
  return youtubeRe.test(url)
}
export const isValidVimeoUrl = (url) => {
  const vimeoRe = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
  return vimeoRe.test(url)
}
export const isValidYoutubeOrVimeoUrl = (url) => {
  return isValidYoutubeUrl(url) || isValidVimeoUrl(url)
}
