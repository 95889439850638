import { useContext } from 'react'

import MixpanelContext from 'react-mixpanel'

import { getUrlParam } from 'utils/mixpanel'

const useUserAnalytics = () => {
  const mixpanel = useContext(MixpanelContext)

  return {
    trackEvent: (...args) => mixpanel.track(...args),
  }
}

export { getUrlParam, useUserAnalytics }
