import compose from 'lodash/flowRight'
import { connect } from 'react-redux'

import ExcelSummaryModal from './ExcelSummaryModal.component'

const mapStateToProps = ({ excelOrdersModal: { importedFiles } }) => ({
  importedFiles,
})

const withRedux = connect(mapStateToProps)
export default compose(withRedux)(ExcelSummaryModal)
