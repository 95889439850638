import { handleActions } from 'redux-actions'

import {
  RESET_SELECTED_ORDERS,
  RESET_SELECTED_STYLE_COLORS_FOR_CANCELLATION,
  RESET_SELECTED_STYLE_COLOR_FOR_REPLACEMENT,
  SELECT_STYLE_COLOR_FOR_REPLACEMENT,
  TOGGLE_ALL_ORDERS_IN_PAGE,
  TOGGLE_ORDER,
  TOGGLE_STYLE_COLOR_FOR_CANCELLATION,
} from './constants'

export const defaultState = {
  styleColorsSelectedForCancellation: {},
  styleColorSelectedForReplacement: {},
  selectedOrders: {},
}

const reducer = handleActions(
  {
    [RESET_SELECTED_STYLE_COLOR_FOR_REPLACEMENT]: (state) => ({
      ...state,
      styleColorSelectedForReplacement:
        defaultState.styleColorSelectedForReplacement,
    }),
    [RESET_SELECTED_STYLE_COLORS_FOR_CANCELLATION]: (state) => ({
      ...state,
      styleColorsSelectedForCancellation:
        defaultState.styleColorsSelectedForCancellation,
    }),
    [SELECT_STYLE_COLOR_FOR_REPLACEMENT]: (state, { payload: styleColor }) => ({
      ...state,
      styleColorSelectedForReplacement: styleColor,
    }),
    [TOGGLE_STYLE_COLOR_FOR_CANCELLATION]: (state, { payload: styleColor }) => {
      const { styleColorsSelectedForCancellation } = state
      const styleColorId = styleColor.id

      const styleColorIsSelected =
        styleColorId in styleColorsSelectedForCancellation

      if (styleColorIsSelected) {
        const {
          [styleColorId]: _,
          ...selectionWithoutPayload
        } = styleColorsSelectedForCancellation
        return {
          ...state,
          styleColorsSelectedForCancellation: selectionWithoutPayload,
        }
      }

      return {
        ...state,
        styleColorsSelectedForCancellation: {
          ...styleColorsSelectedForCancellation,
          [styleColorId]: styleColor,
        },
      }
    },
    [TOGGLE_ORDER]: (state, { payload: order }) => {
      const { selectedOrders } = state
      const orderId = order.id

      const orderIsSelected = orderId in selectedOrders

      if (orderIsSelected) {
        const { [orderId]: _, ...selectionWithoutPayload } = selectedOrders
        return {
          ...state,
          selectedOrders: selectionWithoutPayload,
        }
      }

      return {
        ...state,
        selectedOrders: {
          ...selectedOrders,
          [orderId]: order,
        },
      }
    },
    [TOGGLE_ALL_ORDERS_IN_PAGE]: (state, { payload: orders }) => {
      const { selectedOrders } = state

      const allOrdersAreSelected = orders.every(
        (order) => order.id in selectedOrders,
      )

      const ordersSelectedOnOtherPages = orders.reduce((result, order) => {
        if (order.id in selectedOrders) {
          const { [order.id]: _, ...selectionWithoutPayload } = result
          return selectionWithoutPayload
        }
        return result
      }, selectedOrders)

      if (allOrdersAreSelected) {
        return {
          ...state,
          selectedOrders: ordersSelectedOnOtherPages,
        }
      }

      const ordersInPage = orders.reduce(
        (result, order) => ({
          ...result,
          [order.id]: order,
        }),
        {},
      )

      return {
        ...state,
        selectedOrders: {
          ...ordersSelectedOnOtherPages,
          ...ordersInPage,
        },
      }
    },
    [RESET_SELECTED_ORDERS]: (state) => ({
      ...state,
      selectedOrders: defaultState.selectedOrders,
    }),
  },
  defaultState,
)

export default reducer
