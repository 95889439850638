import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import camelCase from 'lodash/camelCase'

import useSearchParam from 'hooks/useSearchParam'

export const parseFlags = (flagKey, flags) =>
  flagKey.split('||').reduce(
    (acc, flag) =>
      acc ||
      flag
        .trim()
        .split('&&')
        .reduce(
          (andAcc, andFlag) => andAcc && flags[camelCase(andFlag.trim())],
          true,
        ),
    false,
  )

export const FeatureFlag = ({
  flagKey,
  renderDefaultCallback,
  renderFeatureCallback,
}) => {
  const flags = useFlags()
  const flag = parseFlags(flagKey, flags)
  const isTabView = useSearchParam('order_id')

  if (isTabView !== null) {
    return renderDefaultCallback()
  }

  if (flag) {
    return renderFeatureCallback(flag) || <></>
  }

  return renderDefaultCallback()
}

export const featureFlagContentWrapper = (
  featureContent,
  defaultContent,
  flagKey,
) => {
  const featureCallback = (featureFlagOn) => featureFlagOn && featureContent
  const defaultCallback = () => defaultContent

  return (
    <FeatureFlag
      flagKey={flagKey}
      renderDefaultCallback={defaultCallback}
      renderFeatureCallback={featureCallback}
    />
  )
}
