import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { UserInfoQueryDocument } from '__generated__/atlas-types'

import { getCurrentUserFromConfig } from 'utils/config'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

import { useAuth } from '../context/AuthProvider'

atlas`#graphql
   query UserInfoQuery {
    viewingUser {
      id
      email
      displayName
      dateFormat
      assortmentPlanning
      profileImage
      code
      isAdmin
      isOwner
      loggedInAsAdmin
    }
  }
  `

export const useViewingUserInfoQuery = () => {
  const { isInitialized } = useAuth()
  const { data, loading, error } = useQuery(UserInfoQueryDocument, {
    client: atlasClient,
    skip: !isInitialized,
  })

  const userFromConfig = getCurrentUserFromConfig()

  const userDataFromQuery = useMemo(
    () => ({
      apiHost: userFromConfig.apiHost,
      isAdmin: data?.viewingUser.isAdmin || false,
      isOwner: data?.viewingUser.isOwner || false,
      loggedAsAdmin: data?.viewingUser.loggedInAsAdmin || false,
      targetAccountId: userFromConfig.targetAccountId,
    }),
    [data],
  )

  return {
    userDataFromQuery,
    loading,
    error,
  }
}
