import styled from 'styled-components'

const SectionHeader = styled.div`
  width: 100%;
  font-family: var(--font-family-alpha);
  font-weight: var(--font-weight-regular);
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 1.2px;
  color: var(--color-primary-700);
  border-bottom: 1px solid var(--color-primary-300);
  padding: 0 0 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`

export default SectionHeader
