import classNames from 'classnames'

import { Nullable } from 'types'

import styles from './Divider.module.scss'

type DividerProps = {
  verticalMargin?: Nullable<number>
  horizontalMargin?: Nullable<number>
  className?: Nullable<string>
}

const Divider = ({
  verticalMargin = null,
  horizontalMargin = null,
  className = '',
}: DividerProps) => {
  const verticalPart = verticalMargin !== null ? `${verticalMargin}px` : ''
  const horizontalPart =
    horizontalMargin !== null ? `${horizontalMargin}px` : ''
  const dividerStyle = { margin: `${verticalPart} ${horizontalPart}` }
  return (
    <div
      style={dividerStyle}
      className={classNames(styles.Divider, className)}
    />
  )
}

export default Divider
