import { config } from 'config'
import get from 'lodash/get'

export const getAppConfig = () =>
  get(window, 'appConfig') || get(document, 'appConfig')

export type UserFromConfig = {
  apiHost: string
  targetAccountId: string
}

export const getCurrentUserFromConfig = (): UserFromConfig => {
  const appConfig = getAppConfig()

  return {
    apiHost: config.urls.api,
    targetAccountId: get(appConfig, 'targetAccountId'),
  }
}
