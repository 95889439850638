import { FC, useCallback, useEffect, useState } from 'react'

import { Modal, ModalProvider, Option, TextArea } from '@joor/design-system'
import { useSelector } from 'react-redux'

import { LGOtherUserBrands, RequestPriceType } from '__generated__/atlas-types'

import { toGlobalId } from 'utils/transformations/graphql'

import usePortal from 'hooks/usePortal'
import { getAccountId } from 'store/currentUser/selectors'

import { useModalData, useSendConnectionRequest } from './ConnectModal.hooks'
import {
  StyledDescription,
  StyledModal,
  StyledSectionContainer,
} from './ConnectModal.styles'
import AdminBrandList from './components/AdminBrandList'
import PriceTypeAndTermsSelection from './components/PriceTypeAndTermsSelection'
import { FindNewRetailerConnectModal } from 'features/LeadGen/FindNewRetailers/findNewRetailersPage.ids'

type ConnectModalProps = {
  retailerName: string
  retailerId: string
  onClose: () => void
  isOpen: boolean
  autoConnect?: boolean | null
}
export type OtherBrandWithPriceType = {
  brandId: string
  priceTypeIds?: string[]
}

const getDisabledButton = ({
  connectionMessage,
  priceTypeWithTerms,
  otherBrandsWithPriceType,
  loading,
}: {
  connectionMessage: string
  priceTypeWithTerms: RequestPriceType[]
  otherBrandsWithPriceType: OtherBrandWithPriceType[]
  loading: boolean
}) =>
  !connectionMessage ||
  !priceTypeWithTerms.length ||
  !otherBrandsWithPriceType.every(
    (otherBrand) => otherBrand?.priceTypeIds?.length,
  ) ||
  loading

export const ConnectModal: FC<ConnectModalProps> = ({
  retailerName,
  retailerId,
  onClose,
  isOpen,
  autoConnect = false,
}) => {
  const accountId = useSelector(getAccountId)
  const brandId = toGlobalId('Brand', accountId) ?? ''
  const {
    inviteMessage,
    termsAndConditions,
    priceTypes,
    otherUserBrands,
    loading: modalDataLoading,
  } = useModalData(brandId, retailerId)
  const [connectionMessage, setConnectionMessage] = useState<string>('')
  const [priceTypeWithTerms, setPriceTypeWithTerms] = useState<
    RequestPriceType[]
  >([])
  const [otherBrandsWithPriceType, setOtherBrandsWithPriceType] = useState<
    OtherBrandWithPriceType[]
  >([])
  const isPriceTypeAssigned = Boolean(priceTypeWithTerms?.length > 0)
  const [disableButton, setDisableButton] = useState<boolean>(true)
  const [, setForceRerender] = useState(false)
  const [modalHeight, setModalHeight] = useState<number>(0)
  const Portal = usePortal()
  const {
    handleRequestConnectionToRetailer,
    loading,
  } = useSendConnectionRequest(retailerId, brandId)

  useEffect(() => {
    const handleResize = () => setForceRerender((prevState) => !prevState)
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  useEffect(() => {
    if (Boolean(inviteMessage.length)) {
      setConnectionMessage(inviteMessage)
      setDisableButton(
        getDisabledButton({
          connectionMessage: inviteMessage,
          priceTypeWithTerms,
          otherBrandsWithPriceType,
          loading: modalDataLoading,
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteMessage])

  const measuredRef = useCallback((node) => {
    if (node !== null) {
      setModalHeight(node.getBoundingClientRect().height)
    }
  }, [])

  const handleSetPriceTypeWithTerms = (
    updatedPriceTypes: RequestPriceType[],
  ) => {
    setPriceTypeWithTerms(updatedPriceTypes)
    setDisableButton(
      getDisabledButton({
        connectionMessage,
        priceTypeWithTerms: updatedPriceTypes,
        otherBrandsWithPriceType,
        loading: modalDataLoading,
      }),
    )
  }

  const handleSetOtherBrandsWithPriceType = (
    updatedBrandsWithPriceType: OtherBrandWithPriceType[],
  ) => {
    setOtherBrandsWithPriceType(updatedBrandsWithPriceType)
    setDisableButton(
      getDisabledButton({
        connectionMessage,
        priceTypeWithTerms,
        otherBrandsWithPriceType: updatedBrandsWithPriceType,
        loading: modalDataLoading,
      }),
    )
  }

  return (
    <Portal>
      <ModalProvider>
        <StyledModal
          key={priceTypeWithTerms.length}
          isOpen={isOpen}
          title={{
            id: FindNewRetailerConnectModal.Title,
            text: `${
              autoConnect ? 'Connect with' : 'Request connection to'
            } ${retailerName}`,
          }}
          size="medium"
          onClose={onClose}
          $modalHeight={modalHeight}
          className={isPriceTypeAssigned ? 'pricetype-assigned' : ''}
        >
          <Modal.Content>
            <div ref={measuredRef}>
              <StyledDescription>
                {autoConnect
                  ? // eslint-disable-next-line max-len
                    'This retailer is open to connect to any brand on JOOR. Select a price type to finalize your new connection.'
                  : // eslint-disable-next-line max-len
                    'Connections are your current retail customers. Once connected, you can share linesheets and start orders.'}
              </StyledDescription>
              <StyledSectionContainer>
                <TextArea
                  label="Message (500 word limit)"
                  placeholder="Hi, I'd like to connect with you on JOOR."
                  value={connectionMessage}
                  onChange={(e) => {
                    setConnectionMessage(e.target.value)
                    setDisableButton(
                      getDisabledButton({
                        connectionMessage,
                        priceTypeWithTerms,
                        otherBrandsWithPriceType,
                        loading: modalDataLoading,
                      }),
                    )
                  }}
                  maxLength={500}
                  id={FindNewRetailerConnectModal.Message}
                />
              </StyledSectionContainer>
              {Boolean(priceTypes.length) && (
                <PriceTypeAndTermsSelection
                  priceTypeWithTerms={priceTypeWithTerms}
                  setPriceTypeWithTerms={handleSetPriceTypeWithTerms}
                  priceTypes={priceTypes as Option[]}
                  termsAndConditions={termsAndConditions as Option[]}
                />
              )}
              {Boolean(priceTypeWithTerms.length) &&
                Boolean(otherUserBrands.length) && (
                  <AdminBrandList
                    otherUserBrands={otherUserBrands as LGOtherUserBrands[]}
                    otherBrandsWithPriceType={otherBrandsWithPriceType}
                    setOtherBrandsWithPriceType={
                      handleSetOtherBrandsWithPriceType
                    }
                  />
                )}
            </div>
          </Modal.Content>
          <Modal.Actions
            primaryButton={{
              text: autoConnect ? 'Connect' : 'Send',
              handler: async () => {
                const input = {
                  retailerId,
                  brandId,
                  invitationMessage: connectionMessage,
                  priceTypes: priceTypeWithTerms,
                  otherUserBrands: otherBrandsWithPriceType.map(
                    (otherBrandWithPriceType) => ({
                      brandId: otherBrandWithPriceType.brandId,
                      priceTypes:
                        otherBrandWithPriceType.priceTypeIds?.map(
                          (priceTypeId) => ({ priceTypeId }),
                        ) ?? [],
                    }),
                  ),
                }
                await handleRequestConnectionToRetailer(input, retailerName)
                onClose()
              },
              disabled: disableButton,
              loading,
              id: FindNewRetailerConnectModal.SendButton,
            }}
          />
        </StyledModal>
      </ModalProvider>
    </Portal>
  )
}
