import { connect } from 'react-redux'

import { resetNotification } from 'store/notificationHandler/actions'
import * as selectors from 'store/notificationHandler/selectors'

import NotificationHandler from './NotificationHandler.component'

const mapStateToProps = (state) => ({
  messages: selectors.getMessages(state),
})

const mapDispatchToProps = { resetNotification }

export default connect(mapStateToProps, mapDispatchToProps)(NotificationHandler)
