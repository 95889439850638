import styled, { css } from 'styled-components'

import { INavbarDropdown } from 'features/NavbarRevamp/navbarRevamp.types'

interface INavbarDropdownContainerProps {
  rightPosition?: boolean
  isInsideDropdown?: boolean
}

const isInsideDropdownContainerStyles = css`
  position: unset;
  margin-right: 0;
  width: 100%;
`

const NavbarDropdownContainer = styled.div<INavbarDropdownContainerProps>`
  cursor: pointer;
  display: flex;
  position: relative;
  margin-right: ${({ rightPosition }) => (rightPosition ? '4px' : '8px')};

  &:hover {
    > .trigger > div:first-child {
      border: 1px solid var(--color-primary-400);
      border-radius: 4px;
      margin: -1px;
    }

    > div:not(.trigger) {
      visibility: visible;
      opacity: 1;
    }
  }

  @media only screen and (max-width: 1250px) {
    margin-right: 4px;
  }

  @media only screen and (max-width: 1160px) {
    margin-right: 2px;
  }

  @media only screen and (max-width: 1100px) {
    margin-right: 0;
  }

  @media only screen and (max-width: 1024px) {
    margin-right: 4px;
  }

  ${({ isInsideDropdown }) =>
    isInsideDropdown ? isInsideDropdownContainerStyles : ''};
`

const Trigger = styled.div`
  display: flex;
  width: 100%;
`

interface INavbarDropdownItemsProps {
  rightPosition?: boolean
  isInsideDropdown?: boolean
}

const RightPositionStyles = css`
  left: unset;
  right: 0;
`

const isInsideDropdownStyles = css`
  top: 0;
  right: calc(100% - 16px);
  left: unset;
  padding-right: 20px;
  padding-top: 4px;
  background: transparent;

  > div {
    border-radius: 4px;
    border: none;
  }
`

const NavbarDropdownItems = styled.div<INavbarDropdownItemsProps>`
  visibility: hidden;
  position: absolute;
  top: 53px;
  left: 0;
  right: unset;
  width: max-content;
  height: auto;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s ease;
  background: white;
  border-radius: 0px 0px 4px 4px;
  overflow: auto;
  max-height: calc(100vh - 53px);

  ${({ rightPosition }) => (rightPosition ? RightPositionStyles : '')}
  ${({ isInsideDropdown }) => (isInsideDropdown ? isInsideDropdownStyles : '')}
`

const NavbarDropdown = ({
  testId,
  trigger,
  children,
  rightPosition = false,
  isInsideDropdown = false,
}: INavbarDropdown) => (
  <NavbarDropdownContainer
    {...(testId ? { 'data-testid': testId } : [])}
    rightPosition={rightPosition}
    isInsideDropdown={isInsideDropdown}
  >
    <Trigger className="trigger">{trigger}</Trigger>
    <NavbarDropdownItems
      rightPosition={rightPosition}
      isInsideDropdown={isInsideDropdown}
    >
      {children}
    </NavbarDropdownItems>
  </NavbarDropdownContainer>
)

export default NavbarDropdown
