import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Button from 'components/Core/Button/Button'
import Icon from 'components/Core/Icon/Icon'

import styles from './HeaderWithIcon.less'

const HeaderWithIcon = ({
  title,
  icon,
  link,
  className,
  collapsingIcon,
  children,
  button,
  cancelButton,
}) => {
  const renderCollapsingIcon = () => {
    const { className: collapsibleClassName, open, onClick } = collapsingIcon

    return (
      <Icon
        name={!open ? 'plus' : 'minus'}
        onClick={onClick}
        className={classNames(styles.collapsingIcon, collapsibleClassName)}
      />
    )
  }

  const renderIcon = () => {
    const { className: iconClassName, ...rest } = icon
    return <Icon {...rest} className={classNames(styles.icon, iconClassName)} />
  }

  const renderLink = () => {
    const { className: linkClassName, text, linkTo } = link
    return (
      <Link to={linkTo} className={classNames(styles.link, linkClassName)}>
        {text}
      </Link>
    )
  }

  const renderCancelButton = () => {
    const { className: cancelButtonClassName, ...rest } = cancelButton
    return (
      <Button
        {...rest}
        className={classNames(
          styles.buttons,
          styles.cancelButton,
          cancelButtonClassName,
        )}
      >
        Cancel
      </Button>
    )
  }

  const renderButton = () => {
    const {
      className: buttonClassName,
      children: buttonChildren,
      ...rest
    } = button
    return (
      <Button
        {...rest}
        primary
        className={classNames(styles.buttons, buttonClassName)}
      >
        {buttonChildren}
      </Button>
    )
  }

  return (
    <div className={classNames(styles.HeaderWithIcon, className)}>
      <div className={styles.leftContainer}>
        {collapsingIcon && renderCollapsingIcon()}
        {icon && renderIcon()}
        <div className={styles.title}>{title}</div>
        {link && renderLink()}
        {children}
      </div>
      <div className={styles.rightContainer}>
        {cancelButton && renderCancelButton()}
        {button && renderButton()}
      </div>
    </div>
  )
}

HeaderWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    name: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }),
  link: PropTypes.shape({
    text: PropTypes.string,
    linkTo: PropTypes.string,
    className: PropTypes.string,
  }),
  className: PropTypes.string,
  collapsingIcon: PropTypes.shape({
    onClick: PropTypes.func,
    open: PropTypes.bool,
    className: PropTypes.string,
  }),
  children: PropTypes.node,
  button: PropTypes.shape({
    children: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }),
  cancelButton: PropTypes.shape({
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  }),
}

HeaderWithIcon.defaultProps = {
  icon: null,
  link: null,
  className: '',
  collapsingIcon: null,
  children: null,
  button: null,
  cancelButton: null,
}

export default HeaderWithIcon
