import { useQuery } from '@apollo/client'
import get from 'lodash/get'
import sumBy from 'lodash/sumBy'
import { useSelector } from 'react-redux'

import { relayConnectionToArray } from 'utils/transformations/graphql'

import { graphene } from 'graphql/tags'
import { isAccountTypeBrand, userIsLoggedIn } from 'store/currentUser/selectors'

const ordersInProgress = graphene`#graphql
  query ordersInProgress($isBrandAccount: Boolean!) {
    connectedAccounts(filterBy: { onlyOrdersInCart: true })
      @include(if: $isBrandAccount) {
      edges {
        node {
          ... on RetailerAccount {
            id
            displayName
            orders(filterBy: { onlyInCart: true }) {
              totalSkus
              totalStyles
              totalDiscountedPrice
              totalUnits
              priceType {
                id
                currency {
                  id
                  code
                }
              }
            }
          }
        }
      }
    }
    viewer @skip(if: $isBrandAccount) {
      id
      viewerAccount {
        ... on RetailerAccount {
          id
          orders(filterBy: { onlyInCart: true }) {
            totalSkus
            totalUnits
          }
        }
      }
    }
  }
`

type OrderType = {
  totalSkus: number
  totalStyles: number
  totalDiscountedPrice: number
  totalUnits: number
  priceType?: {
    id: string
    currency: {
      id: string
      code: string
    }
  }
}

export type AccountType = {
  id: string
  displayName?: string
  orders: OrderType
}

type CartDataResultType = {
  accountsWithOrders: AccountType[]
  cartLoading: boolean
  refetchOrdersInCart: () => void
  unitsInCart: number
}

export const useCartData = (): CartDataResultType => {
  const { isUserLoggedIn, isBrandAccount } = useSelector((state) => ({
    isUserLoggedIn: userIsLoggedIn(state),
    isBrandAccount: isAccountTypeBrand(state),
  }))

  const { data, loading, refetch } = useQuery(ordersInProgress, {
    variables: { isBrandAccount },
    skip: !isUserLoggedIn,
    notifyOnNetworkStatusChange: true,
  })

  const connectedAccounts = get(data, 'connectedAccounts', [])
  const viewer = get(data, 'viewer', {})
  const viewerAccount = get(viewer, 'viewerAccount', {})
  return {
    accountsWithOrders: isBrandAccount
      ? relayConnectionToArray(connectedAccounts)
      : viewerAccount
      ? [viewerAccount]
      : [],
    cartLoading: loading,
    refetchOrdersInCart: refetch,
    unitsInCart: isBrandAccount
      ? sumBy(relayConnectionToArray(connectedAccounts), 'orders.totalUnits')
      : get(viewer, 'viewerAccount.orders.totalUnits', 0),
  }
}

export default useCartData
