import { useMemo } from 'react'

import { ApolloProvider } from '@apollo/client'
import { IntlProvider } from 'react-intl'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import 'wdyr'

import { language, messages } from 'localization/setup'
import MixpanelProvider from 'utils/sdks/mixpanel'

import { getApolloClient } from 'graphql/client'
import { useFetchCurrentUser } from 'hooks/fetchCurrentUser'
import { getReduxStore } from 'store'

import LoaderOverlay from 'components/Loader/LoaderOverlay'

import BaseApp from './BaseApp'
import { config } from './config'
import { useAuth } from './context/AuthProvider'

// eslint-disable-next-line no-underscore-dangle, max-len
const reduxDevtoolsExtension =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()

const App = () => {
  const { isInitialized } = useAuth()
  const store = getReduxStore(reduxDevtoolsExtension)
  const client = useMemo(
    () =>
      getApolloClient({ uri: config.urls.backend, dispatch: store.dispatch }),
    [store.dispatch],
  )
  const { userInfo, loading, error } = useFetchCurrentUser(client)

  if (loading || !isInitialized) {
    return <LoaderOverlay />
  }

  if (error) {
    // FIXME: this does not work with legacy pages
    // return (
    //   <div className={styles.routerOutlet}>
    //     <RouterPage path="" title="home">
    //       <ErrorPage />
    //     </RouterPage>
    //   </div>
    // )
    return <></>
  }

  return (
    <ApolloProvider client={client}>
      <ReduxProvider store={store}>
        <IntlProvider locale={language} messages={messages[language]}>
          <BrowserRouter>
            <MixpanelProvider userInfo={userInfo}>
              <BaseApp userInfo={userInfo} />
            </MixpanelProvider>
          </BrowserRouter>
        </IntlProvider>
      </ReduxProvider>
    </ApolloProvider>
  )
}

export default App
