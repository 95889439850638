import { useEffect, useState } from 'react'

import { isAnonymousRoute } from 'utils/isAnonymousRoute'
import { setUserForDatadogRUM } from 'utils/sdks/datadog'
import { switchLanguage } from 'utils/sdks/localize'
import { getUserInfo } from 'utils/userInfo'

import { fetchCurrentUser } from 'graphql/fetch'
import { useViewingUserInfoQuery } from 'hooks/useViewingAccountInfo'

import { useAuth } from '../context/AuthProvider'

export function useFetchCurrentUser(client) {
  const { isInitialized } = useAuth()
  const [userInfo, setUserInfo] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const {
    userDataFromQuery,
    loading: loadingUserInfo,
  } = useViewingUserInfoQuery()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedUser = await fetchCurrentUser(client)
        const parsedUserInfo = getUserInfo(userDataFromQuery, fetchedUser)
        setUserForDatadogRUM(parsedUserInfo)
        switchLanguage(parsedUserInfo.displayLanguage)
        setUserInfo(parsedUserInfo)
        setLoading(false)
      } catch (error) {
        if (!isAnonymousRoute()) {
          // FIXME: should we redirect to login page?
          setError(true)
        } else {
          const parsedUserInfo = getUserInfo(userDataFromQuery, {})
          setUserInfo(parsedUserInfo)
        }
        setLoading(false)
      }
    }
    if (isInitialized && !loadingUserInfo) {
      fetchData()
    }
  }, [isInitialized, userDataFromQuery, loadingUserInfo])

  return { userInfo, loading, error }
}
