import { ChangeEvent, FC } from 'react'

import { Checkbox } from '@joor/design-system'

import { LGOtherUserBrands, LGPriceType } from '__generated__/atlas-types'

import { formatNameWithCodeAndDash } from 'utils/formatters'

import { OtherBrandWithPriceType } from '../ConnectModal'
import {
  BrandCheckboxContainer,
  BrandCheckboxSectionMessage,
  PriceTypeCheckboxContainer,
  StyledSectionContainer,
} from '../ConnectModal.styles'
import { FindNewRetailerConnectModal } from 'features/LeadGen/FindNewRetailers/findNewRetailersPage.ids'

type AdminBrandListProps = {
  otherUserBrands: LGOtherUserBrands[]
  otherBrandsWithPriceType: OtherBrandWithPriceType[]
  setOtherBrandsWithPriceType: (
    updatedBrandsWithPriceType: OtherBrandWithPriceType[],
  ) => void
}

const AdminBrandList: FC<AdminBrandListProps> = ({
  otherUserBrands,
  otherBrandsWithPriceType,
  setOtherBrandsWithPriceType,
}) => (
  <>
    <StyledSectionContainer>
      <BrandCheckboxSectionMessage>
        Connect other brands to retailer
      </BrandCheckboxSectionMessage>
      {otherUserBrands.map((brand: LGOtherUserBrands) => {
        const isBrandSelected = Boolean(
          otherBrandsWithPriceType.find(
            (otherBrandWithPriceType) =>
              otherBrandWithPriceType.brandId === brand?.id,
          ),
        )

        return (
          <BrandCheckboxContainer key={`brand-list_${brand?.id}`}>
            <Checkbox
              id={`${FindNewRetailerConnectModal.OtherBrand}_${brand?.id}`}
              label={brand?.name}
              checked={isBrandSelected}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                const { checked } = event.currentTarget
                const updatedBrands = checked
                  ? [...otherBrandsWithPriceType, { brandId: brand?.id }]
                  : otherBrandsWithPriceType.filter(
                      (otherBrandWithPriceType) =>
                        otherBrandWithPriceType.brandId !== brand?.id,
                    )
                setOtherBrandsWithPriceType(updatedBrands)
              }}
            />
            {isBrandSelected &&
              Boolean(brand?.priceTypes.length) &&
              brand.priceTypes.map((pricetype: LGPriceType | null) => {
                const brandPriceTypes =
                  otherBrandsWithPriceType.find(
                    (otherBrandWithPriceType) =>
                      otherBrandWithPriceType.brandId === brand.id,
                  )?.priceTypeIds ?? []
                const isPriceTypeSelected = brandPriceTypes?.includes(
                  pricetype?.id ?? '',
                )
                return (
                  <PriceTypeCheckboxContainer key={pricetype?.id}>
                    <Checkbox
                      id={`${brand.id}_${pricetype?.id}`}
                      className="notranslate"
                      label={formatNameWithCodeAndDash(
                        pricetype?.currencyName,
                        pricetype?.name,
                      )}
                      disabled={brand?.priceTypes === undefined}
                      checked={isPriceTypeSelected}
                      onChange={(event: ChangeEvent<HTMLInputElement>) => {
                        const { checked } = event.currentTarget

                        let updatedBrandsWithPriceType = [
                          ...otherBrandsWithPriceType,
                        ]
                        const brandIndex = updatedBrandsWithPriceType.findIndex(
                          (brandWithPriceTypes) =>
                            brandWithPriceTypes.brandId === brand.id,
                        )
                        const priceTypeIds = checked
                          ? ([...brandPriceTypes, pricetype?.id].filter(
                              Boolean,
                            ) as string[])
                          : brandPriceTypes.filter(
                              (brandPriceType) =>
                                brandPriceType !== pricetype?.id,
                            )
                        updatedBrandsWithPriceType[brandIndex] = {
                          brandId: brand.id,
                          priceTypeIds,
                        }
                        setOtherBrandsWithPriceType(updatedBrandsWithPriceType)
                      }}
                    />
                  </PriceTypeCheckboxContainer>
                )
              })}
          </BrandCheckboxContainer>
        )
      })}
    </StyledSectionContainer>
  </>
)

export default AdminBrandList
