export const actionsPrefix = 'NEWS'
export const DISMISS_NEW = `${actionsPrefix}/DISMISS_NEW`
export const DISMISS_TIMESTAMPED_NEWS = `${actionsPrefix}/DISMISS_TIMESTAMPED_NEWS`

export const RETAILER_CREATED_ORDERS_KEY = 'retailerCreatedOrders'
export const CREATED_BY_RETAILER_FILTER_KEY = 'createdByRetailerFilter'
export const UPCOMING_MAINTENANCE_BANNER_KEY = 'upcomingMaintenanceBanner'
export const STYLEBOARD_COMMENTS_KEY = 'styleboardCommentsBanner'

export const NEWS_KEYS = [
  RETAILER_CREATED_ORDERS_KEY,
  CREATED_BY_RETAILER_FILTER_KEY,
  UPCOMING_MAINTENANCE_BANNER_KEY,
  STYLEBOARD_COMMENTS_KEY,
]
