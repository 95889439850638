import { connect } from 'react-redux'

import { isPageLoaderActive } from 'store/pageLoader/selectors'

import PageLoader from './PageLoader.component'

const mapStateToProps = (state) => ({
  isPageLoaderActive: isPageLoaderActive(state),
})

export default connect(mapStateToProps, null)(PageLoader)
