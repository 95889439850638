import { formatNameWithCode, formatNameWithCodeAndDash } from 'utils/formatters'
import { fromGlobalId } from 'utils/transformations/graphql'

export const formatOptionsForDropdown = (options) =>
  options
    ?.reduce((acc, option, index) => {
      const isListedBrand =
        options.filter((brand) => brand.id === option.id).length > 1
      const formattedBrand = {
        key: `${option.id}_${index}`,
        value: `${option.id}_${option.retailerId}`,
        text: isListedBrand
          ? formatNameWithCode(
              formatNameWithCode(option.profileName, option.code),
              formatNameWithCodeAndDash(option.retailerName, option.retailerId),
            )
          : formatNameWithCode(option.profileName, option.code),
      }
      return [...acc, formattedBrand]
    }, [])
    .sort((a, b) => (a.text > b.text ? 1 : -1))

export const getConnectedAccountsBrandList = (data) =>
  data?.retailers?.edges?.reduce((acc, edge) => {
    const retailerId = parseInt(fromGlobalId(edge.node.id)?.id, 10)
    const retailerName = Boolean(edge.node.displayName)
      ? edge.node.displayName
      : edge.node.name
    const edgeData = edge.node.brands.edges.map((e) => ({
      id: e.node?.id,
      profileName: e.profileName,
      code: e.code,
      retailerId,
      retailerName,
    }))
    return [...acc, ...edgeData]
  }, [])
