import get from 'lodash/get'

import * as constants from './constants'
import {
  PRODUCT,
  TEXT,
} from 'containers/Showroom/GridLayout/GridElements/GridElements.constants'

const initialState = {
  zIndexes: {},
  collection: {
    looks: [
      {
        layout: [],
        media: [],
        items: [],
        textContents: [],
      },
    ],
  },
  collectionLoading: true,
  elementsInNewLooks: [],
  selectedProducts: [],
  lookIndex: 0,
  viewMode: constants.EDIT_MODE,
}

/**
 * TODO: move function to util folder.
 */
const getGreatestZIndex = (zIndexes) => {
  let greatest = 1
  for (let i = 0; i < zIndexes?.length; i++) {
    let z = zIndexes?.[i]?.z
    if (z > greatest) {
      greatest = z
    }
  }
  return greatest
}

const pipe = (fns) => (x) => {
  let result = x
  for (let i = 0; i < fns.length; i++) {
    let fn = fns[i]
    result = fn(result)
  }
  return result
}

const getStateWithUpdatedLook = (updatedLook) => (state) => {
  const { collection, lookIndex } = state

  return {
    ...state,
    collection: {
      ...collection,
      looks: [
        ...get(collection, 'looks', []).map((look, index) =>
          index === lookIndex ? updatedLook : look,
        ),
      ],
    },
  }
}

const getStateWithUpdatedZIndexes = (state) => {
  const zIndexes = getZIndexesDictionary(state?.collection)

  return {
    ...state,
    zIndexes,
  }
}

const getZIndexesDictionary = (collection) => {
  let dictionary = {}
  collection?.looks?.forEach((look, index) => {
    const pageName = `page_${index + 1}`
    dictionary[pageName] = look?.layout?.map((item) => {
      return { id: item?.i, z: item?.z ?? 1 }
    })
  })
  return dictionary
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  const {
    collection,
    collection: { looks },
    lookIndex,
    zIndexes,
  } = state
  switch (action.type) {
    case constants.ADD_AND_GO_TO_LOOK: {
      const { elementsInNewLooks } = state
      const newLook = {
        layout: get(
          looks,
          `[${lookIndex}].layout`,
          [],
        ).filter((layoutElement) =>
          elementsInNewLooks.includes(layoutElement.i),
        ),
        media: get(looks, `[${lookIndex}].media`, []).filter((mediaFile) =>
          elementsInNewLooks.includes(mediaFile.id),
        ),
        items: get(
          looks,
          `[${lookIndex}].items`,
          [],
        ).filter(({ collectionProduct }) =>
          elementsInNewLooks.includes(collectionProduct.product.id),
        ),
        textContents: get(
          looks,
          `[${lookIndex}].textContents`,
          [],
        ).filter((textContent) =>
          elementsInNewLooks.includes(textContent.layoutKey),
        ),
      }

      return {
        ...state,
        collection: {
          ...collection,
          looks: [...collection.looks, newLook],
        },
        lookIndex: collection.looks.length,
        zIndexes: {
          ...state.zIndexes,
          [`page_${collection.looks.length + 1}`]: newLook.layout.map(
            (element) => ({
              id: element.i,
              z: 1,
            }),
          ),
        },
      }
    }
    case constants.ADD_ELEMENT_TO_LOOK: {
      const { element, elementType } = action
      const elementId = `${elementType}-${Date.now()}`
      const lookId = collection.looks?.[lookIndex]?.id
      const z = getGreatestZIndex(zIndexes?.[lookId])

      const updatedLook = {
        ...collection.looks[lookIndex],
        layout: [
          ...get(collection, `looks[${lookIndex}].layout`, []),
          {
            i: element.id || elementId,
            x: 0,
            y: 2,
            z,
            ...action.layout,
          },
        ],
        ...(elementType === PRODUCT && {
          items: [...get(looks, `[${lookIndex}].items`, []), element],
        }),
        ...(elementType === TEXT && {
          textContents: [
            ...get(looks, `[${lookIndex}].textContents`, []),
            {
              ...element,
              layoutKey: elementId,
            },
          ],
        }),
      }
      return pipe([
        getStateWithUpdatedLook(updatedLook),
        getStateWithUpdatedZIndexes,
      ])(state)
    }
    case constants.DELETE_ELEMENT_FROM_LOOK: {
      const updatedLook = {
        ...collection.looks[lookIndex],
        layout: [
          ...get(collection, `looks[${lookIndex}].layout`, []).filter(
            (element) => element.i !== action.id,
          ),
        ],
        media: [
          ...get(collection, `looks[${lookIndex}].media`, []).filter(
            (mediaFile) => mediaFile.id !== action.id,
          ),
        ],
        items: [
          ...get(collection, `looks[${lookIndex}].items`, []).filter(
            ({ collectionProduct }) =>
              collectionProduct.product.id !== action.id,
          ),
        ],
        textContents: [
          ...get(collection, `looks[${lookIndex}].textContents`, []).filter(
            (textContent) => textContent.layoutKey !== action.id,
          ),
        ],
      }
      return getStateWithUpdatedLook(updatedLook)(state)
    }
    case constants.DELETE_LOOK: {
      if (collection.looks.length < 2) {
        return state
      }
      return {
        ...state,
        collection: {
          ...collection,
          looks: [
            ...get(collection, 'looks', []).filter((_, i) => i !== lookIndex),
          ],
        },
        lookIndex: lookIndex ? lookIndex - 1 : 0,
      }
    }
    case constants.SET_COLLECTION: {
      const firstLayout = get(action, 'collection.looks[0].layout', [])
      const firstLayoutIds = firstLayout.map((layoutElement) => layoutElement.i)

      return {
        ...state,
        collection: action.collection,
        collectionLoading: action.collectionLoading,
        elementsInNewLooks:
          get(action, 'collection.looks', []).length === 1
            ? firstLayoutIds
            : [],
        lookIndex: 0,
      }
    }
    case constants.SET_MEDIA_FILE: {
      const updatedLayout = {
        ...collection.looks[lookIndex].layout.find(
          (element) => element.i === action.placeholderId,
        ),
        i: action.mediaFile.id,
      }

      const updatedLook = {
        ...collection.looks[lookIndex],
        layout: [
          ...collection.looks[lookIndex].layout.filter(
            (element) => element.i !== action.placeholderId,
          ),
          updatedLayout,
        ],
        media: [
          ...collection.looks[lookIndex].media.filter(
            (element) => element.id !== action.placeholderId,
          ),
          action.mediaFile,
        ],
      }
      return getStateWithUpdatedLook(updatedLook)(state)
    }
    case constants.SET_LOOK: {
      return getStateWithUpdatedLook(action.look, state)
    }
    case constants.SET_LOOK_INDEX: {
      return {
        ...state,
        lookIndex: action.lookIndex,
      }
    }
    case constants.SET_LOOK_LAYOUT: {
      const updatedLook = {
        ...get(collection, `looks[${lookIndex}]`, {}),
        layout: action.layout,
      }
      return getStateWithUpdatedLook(updatedLook)(state)
    }
    case constants.SET_STATIC_ELEMENT_IN_LOOK: {
      const lookLayout = get(collection, `looks[${lookIndex}].layout`, [])
      const elementLayout = lookLayout.find(({ i }) => i === action.id)
      const updatedLook = {
        ...get(collection, `looks[${lookIndex}]`, {}),
        layout: [
          ...lookLayout.filter(({ i }) => i !== action.id),
          { ...elementLayout, isDraggable: !action.static },
        ],
      }
      return getStateWithUpdatedLook(updatedLook)(state)
    }
    case constants.SET_TEXT_IN_LOOK: {
      const { content, layoutKey } = action
      const existingTextContent = get(
        collection,
        `looks[${lookIndex}].textContents`,
        [],
      ).find((textContent) => textContent.layoutKey === layoutKey)
      const updatedLook = {
        ...get(collection, `looks[${lookIndex}]`, {}),
        textContents: [
          ...get(collection, `looks[${lookIndex}].textContents`, []).filter(
            (element) => element.layoutKey !== layoutKey,
          ),
          { ...existingTextContent, content },
        ],
      }
      return getStateWithUpdatedLook(updatedLook)(state)
    }
    case constants.TOGGLE_ELEMENT_IN_NEW_LOOK: {
      return {
        ...state,
        elementsInNewLooks: [
          ...state.elementsInNewLooks.filter(
            (elementId) => elementId !== action.id,
          ),
          ...(action.copyToNewLook ? [action.id] : []),
        ],
      }
    }
    case constants.TOGGLE_PRODUCT_SELECTED: {
      return {
        ...state,
        selectedProducts: [
          ...state.selectedProducts.filter(
            (selectedProduct) => selectedProduct.id !== action.product.id,
          ),
          ...(action.isSelected ? [action.product] : []),
        ],
      }
    }
    case constants.UPDATE_COLLECTION: {
      return {
        ...state,
        collection: action.collection,
      }
    }
    case constants.UPDATE_VIEW_MODE: {
      return {
        ...state,
        viewMode: action.viewMode,
      }
    }
    case constants.SET_LOOKS: {
      const newLooks = action.looks
      return {
        ...state,
        lookIndex: 0,
        collection: {
          ...collection,
          looks: newLooks,
        },
      }
    }
    case constants.SET_Z_INDEXES: {
      const zIndexes = getZIndexesDictionary(collection)
      return {
        ...state,
        zIndexes,
      }
    }
    case constants.UPDATE_Z_INDEX: {
      const { lookIndex, zIndexes } = state
      const pageName = `page_${lookIndex + 1}`
      const { itemId, zIndex } = action

      return {
        ...state,
        zIndexes: {
          ...zIndexes,
          [pageName]: state?.zIndexes?.[pageName]?.map((item) => {
            if (item.id === itemId) {
              return { ...item, z: zIndex }
            }
            return item
          }),
        },
      }
    }

    case constants.MERGE_Z_INDEXES_TO_LAYOUTS: {
      const { zIndexes, collection } = state

      return {
        ...state,
        collection: {
          ...collection,
          looks: collection?.looks?.map((look, index) => {
            const pageName = `page_${index + 1}`
            const indexes = zIndexes?.[pageName]
            return {
              ...look,
              layout: look?.layout?.map((item) => {
                const index = indexes.find((ind) => ind?.id === item?.i)
                return {
                  ...item,
                  z: index?.z,
                }
              }),
            }
          }),
        },
      }
    }

    case constants.UPDATE_Z_INDEXES: {
      const { zIndexes } = action
      return {
        ...state,
        zIndexes,
      }
    }

    case constants.SET_3D_SHOWROOM_URL: {
      const { url3dShowroom } = action
      const { displayUrl, ...rest } = url3dShowroom
      return {
        ...state,
        collection: {
          ...state.collection,
          url3dShowroom: {
            ...rest,
            url: displayUrl,
          },
        },
      }
    }

    default:
      return state
  }
}
