// Sections configuration
import { TextStyleElement } from './TextSettings'

export interface TextStyleConfig {
  fontFamily: string
  fontWeight: string
  fontSize: string
  order: number
}
type SettingsRecord = {
  [key: string]: TextStyleConfig
}
export const HEADING_ID = 'Heading1'
export const TITLE_ID = 'Title1'
export const SUBTITLE_ID = 'Subtitle1'
export const PARAGRAPH_ID = 'Paragraph1'
export const DEFAULT_SETTINGS: SettingsRecord = {
  [HEADING_ID]: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '36px',
    order: 1,
  },
  [TITLE_ID]: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '24px',
    order: 2,
  },
  [SUBTITLE_ID]: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '18px',
    order: 3,
  },
  [PARAGRAPH_ID]: {
    fontFamily: 'Montserrat',
    fontWeight: '400',
    fontSize: '14px',
    order: 4,
  },
}

export const getDefaultTextStyles = (): TextStyleElement[] => {
  return [HEADING_ID, TITLE_ID, SUBTITLE_ID, PARAGRAPH_ID].map((val) => {
    return {
      ...DEFAULT_SETTINGS[val],
      name: val,
    } as TextStyleElement
  })
}

// Fonts configuration
export const FONTS = [
  'Roboto',
  'Open Sans',
  'Noto Sans JP',
  'Lato',
  'Montserrat',
  'Source Sans Pro',
  'Oswald',
  'Poppins',
  'Roboto Slab',
  'Merriweather',
  'Playfair Display',
  'Lora',
  'Nunito Sans',
  'Roboto Condensed',
  'Work Sans',
  'Spectral',
  'PT Sans',
  'IBM Plex Sans',
  'Crimson',
  'Raleway',
  'EB Garamond',
  'Courier Prime',
  'GFS Didot',
  'Verdana',
  'Georgia',
  'Palatino',
  'Arial',
]
export const WEIGHTS = 'thin,light,regular,medium,bold,black'

export const INSTALLED_FONTS = [
  'Verdana Pro W01 Black',
  'VerdanaProW01-BlackItal',
  'Verdana Pro W01 Bold',
  'VerdanaProW01-BoldItali',
  'Verdana Pro W01 Italic',
  'Verdana Pro W01 Light',
  'VerdanaProW01-LightItal',
  'Verdana Pro W01 Regular',
  'VerdanaProW01-SemiBold',
  'VerdanaProW01-SemiBoldI',
  'GeorgiaProW01-Black',
  'GeorgiaProW01-BlackItal',
  'GeorgiaProW01-Bold',
  'GeorgiaProW01-BoldItali',
  'GeorgiaProW01-Italic',
  'GeorgiaProW01-Light',
  'GeorgiaProW01-Regular',
  'GeorgiaProW01-SemiBold',
  'GeorgiaProW01-SemiBoldI',
  'GeorgiaProW01-SCLightIt',
  'Palatino LT W01 Light',
  'Palatino LT W01 Light Italic',
  'Palatino LT W01 Roman',
  'Palatino LT W01 Italic',
  'Palatino LT W01 Medium',
  'Palatino LT W01 Medium Italic',
  'Palatino LT W01 Bold',
  'Palatino LT W01 Bold Italic',
  'Palatino LT W01 Black',
  'Palatino LT W01 Black Italic',
  'Arial MT W01 Light',
  'Arial MT W01 Light Italic',
  'Arial MT W01 Regular',
  'Arial MT W01 Italic',
  'Arial MT W01 Medium',
  'Arial MT W01 Medium Italic',
  'Arial MT W01 Bold',
  'Arial MT W01 Bold Italic',
  'Arial MT W01 ExtraBold',
  'Arial MT W01 ExtraBold Italic',
]

export const getFontWeightOptions = () => [
  {
    value: '100',
    id: 'Thin',
    text: 'Thin',
    label: 'Thin',
  },
  {
    value: '300',
    id: 'Light',
    text: 'Light',
    label: 'Light',
  },
  {
    value: '400',
    id: 'Regular',
    text: 'Regular',
    label: 'Regular',
  },
  {
    value: '500',
    id: 'Medium',
    text: 'Medium',
    label: 'Medium',
  },
  {
    value: '700',
    id: 'Bold',
    text: 'Bold',
    label: 'Bold',
  },
  {
    value: '900',
    id: 'Black',
    text: 'Black',
    label: 'Black',
  },
]

export const getFontSizeOptions = () => [
  {
    value: '8px',
    id: '8',
    text: '8',
    label: '8',
  },
  {
    value: '9px',
    id: '9',
    text: '9',
    label: '9',
  },
  {
    value: '10px',
    id: '10',
    text: '10',
    label: '10',
  },
  {
    value: '11px',
    id: '11',
    text: '11',
    label: '11',
  },
  {
    value: '12px',
    id: '12',
    text: '12',
    label: '12',
  },
  {
    value: '14px',
    id: '14',
    text: '14',
    label: '14',
  },
  {
    value: '18px',
    id: '18',
    text: '18',
    label: '18',
  },
  {
    value: '24px',
    id: '24',
    text: '24',
    label: '24',
  },
  {
    value: '26px',
    id: '26',
    text: '26',
    label: '26',
  },
  {
    value: '30px',
    id: '30',
    text: '30',
    label: '30',
  },
  {
    value: '36px',
    id: '36',
    text: '36',
    label: '36',
  },
  {
    value: '48px',
    id: '48',
    text: '48',
    label: '48',
  },
  {
    value: '60px',
    id: '60',
    text: '60',
    label: '60',
  },
  {
    value: '72px',
    id: '72',
    text: '72',
    label: '72',
  },
  {
    value: '96px',
    id: '96',
    text: '96',
    label: '96',
  },
]
