import {
  BulkUpsertRetailerLocationsInput,
  LocationType,
  RetailerImpersonationFeedbackInput,
  RetailerImpersonationFeedbackOption,
  UpdateRetailerProfileInput,
} from '__generated__/atlas-types'

import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import {
  RetailerProfile,
  RetailerProfileLocation,
} from 'features/LeadGen/RetailerProfile/retailerProfilePage.types'

export const validateWebsiteUrl = (url: string) =>
  /^((ftp|http|https):\/\/)(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/.test(
    url,
  )

export const checkCompleteAddressFields = (
  address: Partial<RetailerProfileLocation>,
  website: string,
) =>
  address.id &&
  address.address1 &&
  address.city &&
  address.state &&
  address.phone &&
  address.country &&
  address.type &&
  validateWebsiteUrl(website)

export const checkCompleteBusinessInfo = (
  address: Partial<RetailerProfileLocation>,
  description: string,
  hasRetailerConnections: boolean,
) =>
  address.id &&
  address.wholesaleRange?.min &&
  address.wholesaleRange?.max &&
  Boolean(address.categories?.length) &&
  (Boolean(address.brandsCarried?.length) || !hasRetailerConnections) &&
  Boolean(description)

export const checkAddressChanges = ({
  previousLocation,
  previousWebsite,
  updatedLocation,
  updatedWebsite,
}: {
  previousLocation: Partial<RetailerProfileLocation>
  previousWebsite: string
  updatedLocation: Partial<RetailerProfileLocation>
  updatedWebsite: string
}): boolean => {
  const updatedName = previousLocation.name !== updatedLocation.name
  const updatedAddress1 = previousLocation.address1 !== updatedLocation.address1
  const updatedAddress2 = previousLocation.address2 !== updatedLocation.address2
  const updatedCity = previousLocation.city !== updatedLocation.city
  const updatedState = previousLocation.state !== updatedLocation.state
  const updatedZip = previousLocation.zip !== updatedLocation.zip
  const updatedCountry = previousLocation.country !== updatedLocation.country
  const updatedPhone = previousLocation.phone !== updatedLocation.phone
  const updatedType = previousLocation.type !== updatedLocation.type
  const updatedWebsiteUrl = previousWebsite !== updatedWebsite
  return (
    updatedName ||
    updatedAddress1 ||
    updatedAddress2 ||
    updatedCity ||
    updatedState ||
    updatedZip ||
    updatedCountry ||
    updatedType ||
    updatedPhone ||
    updatedWebsiteUrl
  )
}

export const getAddressInput = (
  address: Partial<RetailerProfileLocation>,
): BulkUpsertRetailerLocationsInput => ({
  locations: [
    {
      id: address.id,
      name: address.name || '',
      isPrimaryLocation: true,
      type: address.type ?? LocationType.STORE,
      address1: address.address1 || '',
      ...(address.address2 && { address2: address.address2 }),
      city: address.city || '',
      ...(address.state && { state: address.state }),
      ...(address.zip && { zip: address.zip }),
      country: address.country || '',
      ...(address.phone && { phone: address.phone }),
      yearEstablished: address.yearEstablished,
      ...(address.wholesaleRange?.min && {
        wholesaleMin: address.wholesaleRange?.min,
      }),
      ...(address.wholesaleRange?.max && {
        wholesaleMax: address.wholesaleRange?.max,
      }),
      ...(Boolean(address.brandsCarried?.length) && {
        carriedBrands: address.brandsCarried?.map(
          (carriedBrand) => carriedBrand?.id,
        ),
      }),
      ...(Boolean(address.categories?.length) && {
        categories: address.categories?.map((category) => category?.id),
      }),
      clienteleInfo: {
        clienteleMale: address?.clienteleInfo?.male,
        clienteleFemale: address?.clienteleInfo?.female,
        clienteleAge: address?.clienteleInfo?.age,
        ...(Boolean(address.clienteleInfo?.descriptions?.length) && {
          clienteleDescriptions: address.clienteleInfo?.descriptions?.map(
            (clienteleDesc) => clienteleDesc?.id,
          ),
        }),
        ...(Boolean(address.clienteleInfo?.interests?.length) && {
          clienteleInterests: address.clienteleInfo?.interests?.map(
            (clienteleInt) => clienteleInt?.id,
          ),
        }),
      },
    },
  ],
})

export const getBasicInfoInput = (
  retailerId: string,
  publicRetailerProfile: Partial<RetailerProfile>,
): UpdateRetailerProfileInput => {
  const description = publicRetailerProfile?.description || ''
  const logoId = publicRetailerProfile?.logo?.id
  const websiteUrl = publicRetailerProfile?.websiteUrl || ''
  const socialMediaAccounts = publicRetailerProfile?.socialMediaAccounts
  const profileSnoozeCounter = publicRetailerProfile?.profileSnoozeCounter

  return {
    retailerId,
    description,
    websiteUrl,
    ...(logoId && { logoId }),
    ...(socialMediaAccounts?.length && {
      socialMediaAccounts: socialMediaAccounts?.map((sm) => ({
        type: sm.type,
        url: sm.url,
      })),
    }),
    profileSnoozeCounter,
  }
}

export const getImpersonationFeedbackInput = ({
  accountId,
  option,
  comment,
}: {
  accountId: string
  option?: RetailerImpersonationFeedbackOption | null
  comment?: string
}): RetailerImpersonationFeedbackInput => ({
  accountId: toGlobalId('Account', fromGlobalId(accountId)?.id) ?? '',
  option: option ?? RetailerImpersonationFeedbackOption.UNKNOWN,
  comment,
})

export const isGoogleAddressComplete = (
  address?: Partial<RetailerProfileLocation>,
) =>
  address?.id &&
  address?.country &&
  address?.city &&
  address?.address1 &&
  address?.phone
