import classNames from 'classnames'
import PropTypes from 'prop-types'

import styles from './IconButton.less'

const IconButton = ({
  active,
  onMouseEnter,
  onMouseLeave,
  onClick,
  children,
  className,
  type,
  disabled,
  testId,
}) => {
  const buttonStyles = classNames(styles.iconButton, className, {
    [styles.active]: active,
    [styles[type]]: type,
  })

  return (
    <button
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      className={buttonStyles}
      disabled={disabled}
      data-testid={testId}
    >
      {children}
    </button>
  )
}

IconButton.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
  type: PropTypes.string,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
}

IconButton.defaultProps = {
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onClick: () => {},
  className: '',
  type: '',
}

export default IconButton
