import { Component } from 'react'

import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from 'components/Core/Button/Button'

import Search from '../Search/Search'
import styles from './Accordion.less'
import { ListItem } from './ListItem'

export class List extends Component {
  state = { search: '' }

  handleSearch = (value) => {
    this.setState(() => ({ search: value }))
  }

  render() {
    return (
      <div
        className={classNames(
          {
            [styles.accordionList]: !this.props.inheritHeight,
            [styles.accordionListSearch]: this.props.search,
          },
          this.props.className,
        )}
        data-testid={this.props['data-testid']}
      >
        {this.props.search && (
          <>
            {this.props.selectAll && (
              <Button
                secondary
                className={styles.selectAllButton}
                onClick={this.props.selectAllHandler}
              >
                Select All
              </Button>
            )}
            <Search
              className={styles.accordionListSearch}
              placeholder="Search..."
              inputOnly
              isGray
              onSearchChange={this.handleSearch}
              filter
              disabled={this.props.disabled}
            />
          </>
        )}
        {this.props.children}
        {this.props.items
          .filter(
            ({ name }) =>
              !this.state.search ||
              name.toLowerCase().includes(this.state.search.toLowerCase()),
          )
          .map((item) => {
            const isSelected =
              this.props.selectedItems && this.props.selectedItems[item.id]
            return (
              <ListItem
                key={item.id}
                selected={isSelected}
                onClick={() =>
                  this.props.toggleItems
                    ? this.props.onItemClick(item)
                    : !isSelected && this.props.onItemClick(item)
                }
                noTranslate={this.props.noTranslate}
                disabled={this.props.disabled}
                className={item.className}
              >
                {item.name}
              </ListItem>
            )
          })}
      </div>
    )
  }
}

List.propTypes = {
  children: PropTypes.node,
  'data-testid': PropTypes.string,
  onItemClick: PropTypes.func,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      isSelected: PropTypes.bool,
      className: PropTypes.string,
    }),
  ),
  // NOTE: selectedItems ids are dynamic hence PropTypes.object
  // eslint-disable-next-line react/forbid-prop-types
  selectedItems: PropTypes.object,
  search: PropTypes.bool,
  className: PropTypes.string,
  selectAll: PropTypes.bool,
  inheritHeight: PropTypes.bool,
  selectAllHandler: PropTypes.func,
  toggleItems: PropTypes.bool,
  disabled: PropTypes.bool,
  noTranslate: PropTypes.bool,
}

List.defaultProps = {
  children: null,
  'data-testid': null,
  onItemClick: () => {},
  items: [],
  selectedItems: {},
  search: false,
  className: '',
  selectAll: false,
  inheritHeight: false,
  selectAllHandler: null,
  toggleItems: false,
  disabled: false,
  noTranslate: false,
}

export default List
