import classnames from 'classnames'
import find from 'lodash/find'
import naturalSort from 'natural-sort'

import { translateText } from 'utils/sdks/localize'

import Accordion from 'components/Core/Accordion/Accordion'
import Bubble from 'components/Core/Bubble/Bubble'
import Dropdown from 'components/Core/Dropdown/Dropdown'

/*
 * Class Filter
 * * * Attributes
 * - filterKey: Key used in the GraphQL query filters
 * - filterSubgroupKey: Key used in the GraphQL query filters, as a subgroup (is not used if undefined)
 * - label: To render the title of the filter and its pills
 * - isQuickFilter: Whether it is applied immediately or not
 * - isSearchable: Whether the filter options can be searched
 * - options: It if is not empty, this array contains the filter options.
 * Normally it is needed for filters that do not require information
 * from the backend (like export status)
 * - dataKey: Key used to access filter information
 * - optionsKey: Key used to access filter options
 * that come from the backend (like linesheets). Equals to dataKey if not specified
 * - sort: Sorting function for the filter options
 * - nameFormatter: Function to format the filter options' display names
 * - unselectedState: What their value should be if they are not selected
 * * * Class functions
 * - getFilterOptions: Receives information from the backend (like linesheets or
 * types) and returns the format needed for the render
 * - getSelectedOptions: Receives information about the current configuration of
 * filters (selectedFilters from redux) and returns the information needed for the
 * render
 * - isUnselected: Auxiliary function to determine if the filter is not selected
 * - renderFilterPanelHandler: render function of the filter
 * - renderPillLabelHandler: render function for the pill label
 * - toGraphQLVariable: function to transform the state of the filter to
 * the needed format for the graphQL query
 * - reducerHandler: function that handles how the redux reducer for this filter
 * should behave
 * - addToActiveFilters: function that accumulates to an array of active filters
 * from the redux state.
 * - renderSelectedFilterHandler: function that returns a dropdown with the selected filter options
 * - getSelectedFiltersPillFormatted: function that returns the selected filters as the PillBox component
 * needs.
 * - getCountFilters: function that returns the number of filters
 */
export default class Filter {
  constructor({
    filterKey = '',
    filterSubgroupKey = '',
    newsKey = '',
    label = '',
    isQuickFilter = false,
    options = [],
    isSearchable = true,
    dataKey = '',
    optionsKey = '',
    sort = (a, b) => naturalSort()(a.name, b.name),
    nameFormatter = (filterOption) => filterOption.name,
    unselectedState = [],
    expanded = false,
    isDisabled = () => false,
    modifierType = null,
    translateOptions = false,
    showFilterHandler = () => true,
    styles = '',
    parentClassName = '',
  }) {
    this.filterKey = filterKey
    this.filterSubgroupKey = filterSubgroupKey
    this.newsKey = newsKey
    this.label = label
    this.isQuickFilter = isQuickFilter
    this.options = options
    this.isSearchable = isSearchable
    this.dataKey = dataKey
    this.optionsKey = optionsKey || dataKey
    this.sort = sort
    this.nameFormatter = nameFormatter
    this.unselectedState = unselectedState
    this.expanded = expanded
    this.isDisabled = isDisabled
    this.modifierType = modifierType
    this.translateOptions = translateOptions
    this.showFilterHandler = showFilterHandler
    this.styles = styles
    this.parentClassName = parentClassName
  }

  getFilterOptions = (filterOutsideData, sorting = true) => {
    if (this.options.length) {
      return this.options.sort(this.sort)
    }
    if (!filterOutsideData[this.optionsKey]) {
      return null
    }
    const options = filterOutsideData[this.optionsKey].map((filterOption) => ({
      ...filterOption,
      name: this.nameFormatter(filterOption),
      className: this.translateOptions ? '' : 'notranslate',
    }))
    return sorting ? options.sort(this.sort) : options
  }

  getSelectedOptions = () => null

  isUnselected = () => null

  renderFilterPanelHandler = ({
    filterData,
    selectedFilters,
    actionHandler,
    withNewsBubble = false,
    disabled = false,
    noTranslate = false,
  }) => {
    const filterOptions = this.getFilterOptions(filterData)
    const selectedOptions = this.getSelectedOptions(selectedFilters)
    return {
      title: this.label,
      bubble: withNewsBubble && <Bubble>New</Bubble>,
      content: (
        <Accordion.List
          search={this.isSearchable}
          categoryName={this.label}
          items={filterOptions}
          selectedItems={selectedOptions}
          onItemClick={actionHandler}
          disabled={disabled}
          noTranslate={noTranslate}
        />
      ),
      expanded: this.expanded,
      showFilterHandler: this.showFilterHandler,
    }
  }
  // eslint-disable-next-line
  renderPillLabelHandler = ({ name }) => (
    <span>
      {this.label}: <span className="notranslate">{name}</span>
    </span>
  )

  toGraphQLVariable = () => null

  reducerHandler = () => null

  addToActiveFilters = (currentState, filterState) => {
    // passes without modifying
    if (this.isUnselected(filterState)) {
      return currentState
    }
    return [...currentState, filterState]
  }

  /* eslint-disable react/prop-types */
  renderSelectedFilterHandler = ({
    filterData,
    actionHandler,
    disabled = true,
    loading = false,
    defaultValue,
    sorting = true,
  }) => {
    const filterOptions = this.getFilterOptions(filterData, sorting).map(
      (option) => ({
        className: option.className,
        id: option.id,
        text: option.text,
        value: option.value,
      }),
    )

    return (
      <Dropdown
        filter
        options={filterOptions}
        selectOnBlur={false}
        onChange={(e, { options, value }) =>
          actionHandler(find(options, { value }))
        }
        className={classnames(this.styles, {
          notranslate: !this.translateOptions,
        })}
        parentClassName={this.parentClassName}
        placeholder={translateText('Select a value')}
        disabled={disabled}
        loading={loading}
        value={defaultValue}
      />
    )
  }

  getSelectedFiltersPillFormatted = () => null

  getCountFilters = () => 0
}
