import { handleActions } from 'redux-actions'

import withStorage from 'store/utils/storage/withStorage'

import {
  SET_ORDERS,
  SET_SELECTED_ORDER_IDS,
  SET_TARGET_ORDER_ID,
} from './constants'

export const defaultState = {
  selectedOrdersIds: [],
  orders: {},
}

const reducer = handleActions(
  {
    [SET_SELECTED_ORDER_IDS]: (state, { payload: selectedOrdersIds }) => ({
      ...state,
      selectedOrdersIds,
    }),
    [SET_ORDERS]: (state, { payload: orders }) => {
      return {
        ...state,
        orders,
      }
    },
    [SET_TARGET_ORDER_ID]: (state, { payload: targetOrderId }) => {
      return {
        ...state,
        targetOrderId,
      }
    },
  },
  defaultState,
)

export default withStorage(reducer, {
  key: 'MERGE_ORDERS',
  version: 1,
})
