import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from 'components/Core/Icon/Icon'

import styles from './Expander.less'

const Expander = ({ expanded, disabled }) => {
  const name = expanded ? 'minus' : 'plus'
  const label = expanded ? 'collapse' : 'expand'

  return (
    <Icon
      className={classNames(styles.Expander, { [styles.disabled]: disabled })}
      name={name}
      label={label}
    />
  )
}

Expander.propTypes = {
  expanded: PropTypes.bool,
  disabled: PropTypes.bool,
}

Expander.defaultProps = {
  expanded: false,
  disabled: false,
}

export default Expander
