export enum UserTypeSelector {
  isProRetailer = 'isProRetailer',
  isLiteRetailer = 'isLiteRetailer',
  isProBrand = 'isProBrand',
  isLiteBrand = 'isLiteBrand',
}

export type Feature = {
  earlyAccessAdminFlag: string
  earlyAccessFeatureFlag: string
  title: (condition?: boolean) => string
  description: (condition?: boolean) => string
  oldFeatureLink?: () => string
  newFeatureLink?: () => string
  customLink?: string
  linkText?: string
  previewImage?: string
  feedbackButtonId?: string
  unsubscribePendoGuideId?: string
  walkthroughText?: string
  walkthroughId?: string
  widgetConfigs: WidgetConfig[]
  conditionallyFeatureFlag?: string
  userTypeSpecific?: UserTypeSelector[]
}

export type WidgetConfig = {
  urlRegexp: RegExp
  pushContent: boolean
  isPhpPage?: boolean
  renderConditions?: string[]
  shouldCheckFullRoute?: boolean
}
