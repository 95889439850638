import { FC } from 'react'

import { SecondaryButton, Tooltip } from '@joor/design-system'
import { useFlags } from '@joor/launchdarkly-react-client-sdk'

import { LGConnectionStatus } from '__generated__/atlas-types'

import { ViewModeIds } from 'features/LeadGen/RetailerProfile/retailerProfilePage.ids'

type HeaderCTAProps = {
  retailerName: string
  isRetailerAccount: boolean
  isProfileOwner: boolean
  handleEditClick: () => void
  handleAcceptClick: () => void
  handleMessageClick: () => void
  handleConnectClick: () => void
  connectionStatus?: LGConnectionStatus | null
}

const HeaderCTA: FC<HeaderCTAProps> = ({
  isRetailerAccount,
  isProfileOwner,
  connectionStatus,
  handleEditClick,
  handleAcceptClick,
  handleMessageClick,
  handleConnectClick,
  retailerName,
}) => {
  const { retailerProfileBrandVisibility } = useFlags()
  return (
    <>
      {isRetailerAccount && isProfileOwner && (
        <SecondaryButton id={ViewModeIds.EditButton} onClick={handleEditClick}>
          Edit Page
        </SecondaryButton>
      )}
      {!isRetailerAccount &&
        connectionStatus === LGConnectionStatus.PENDING_BRAND &&
        retailerProfileBrandVisibility && (
          <SecondaryButton
            id={ViewModeIds.AcceptButton}
            onClick={handleAcceptClick}
          >
            Accept
          </SecondaryButton>
        )}
      {!isRetailerAccount &&
        connectionStatus === LGConnectionStatus.CONNECTED &&
        retailerProfileBrandVisibility && (
          <SecondaryButton
            id={ViewModeIds.MessageButton}
            onClick={handleMessageClick}
          >
            Message
          </SecondaryButton>
        )}
      {!isRetailerAccount &&
        (connectionStatus === LGConnectionStatus.UNCONNECTED ||
          connectionStatus === LGConnectionStatus.PENDING_RETAILER) &&
        retailerProfileBrandVisibility && (
          <Tooltip
            // eslint-disable-next-line max-len
            content={`Your connection request to ${retailerName} is still pending. ${retailerName} must accept the request before you can communicate with them directly.`}
            customTooltipStyles={
              connectionStatus === LGConnectionStatus.PENDING_RETAILER
                ? { width: '400px' }
                : {
                    display: 'none',
                  }
            }
            defaultPlacement="left"
          >
            <SecondaryButton
              id={ViewModeIds.ConnectButton}
              onClick={handleConnectClick}
              disabled={
                connectionStatus === LGConnectionStatus.PENDING_RETAILER
              }
            >
              Connect
            </SecondaryButton>
          </Tooltip>
        )}
    </>
  )
}

export default HeaderCTA
