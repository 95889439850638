import { useEffect, useRef, useState } from 'react'

import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { renderErrorNotification } from 'store/notificationHandler/actions'

import {
  Divider,
  Icon,
  Image,
  Input,
  Loader,
  Modal,
  Pagination,
} from 'components/Core'
import Button from 'components/Core/ButtonV2/Button'

import { PAGE_SIZE, useBrandImages } from './AddImageModal.hooks'
import styles from './AddImageModal.module.scss'
import { IMAGE } from 'containers/Showroom/GridLayout/GridElements/GridElements.constants'
import { uploadMediaFile } from 'features/Storefront/utils'

const AddImageModal = ({ onClose, onCancel, onExit, onSelect }) => {
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [selectedImage, setSelectedImage] = useState({})
  const [imagesSize, setImagesSize] = useState({})
  const [activePage, setActivePage] = useState(1)
  const [currentOffset, setCurrentOffset] = useState(0)
  const [pages, setPages] = useState(1)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef()
  const { images, imagesLoading, totalPages } = useBrandImages(
    searchText,
    currentOffset,
  )

  useEffect(() => {
    if (!imagesLoading) {
      setPages(totalPages)
    }
  }, [imagesLoading, totalPages])

  useEffect(() => {
    setLoading(imagesLoading)
  }, [imagesLoading])

  const handlePaginationChange = (_, { activePage }) => {
    setActivePage(activePage)
    setCurrentOffset((activePage - 1) * PAGE_SIZE)
  }
  const handleInputChange = async (img) => {
    setLoading(true)
    const uploadedImage = await uploadMediaFile(img, IMAGE, () =>
      dispatch(
        renderErrorNotification('There was a problem uploading your image.'),
      ),
    )
    setLoading(false)
    onSelect({
      ...uploadedImage,
      filename: 'uploaded image',
    })
    onExit()
    onClose()
  }

  return (
    <Modal
      open
      large
      hideCloseButton
      className={classNames(styles.AddImageModal, {
        [styles.disabledButtons]: isEmpty(selectedImage),
      })}
      onClose={() => {
        onExit()
        onClose()
      }}
      primaryActionLabel="Select Image"
      primaryActionOnClick={() =>
        onSelect({
          ...selectedImage,
          ...imagesSize[selectedImage?.id],
        })
      }
      primaryDisabled={isEmpty(selectedImage)}
      secondaryActionLabel="Cancel"
      secondaryActionOnClick={onCancel}
    >
      <>
        <h3 className={styles.title}>My images</h3>
        <div className={styles.buttonsContainer}>
          <Input
            className={styles.search}
            onChange={(e, { value }) => {
              setSearchText(value)
              setCurrentOffset(0)
              setActivePage(1)
            }}
            icon={<Icon className={styles.searchIcon} name="searchSmall" />}
            placeholder="Search..."
          />
          <Button
            className={styles.mediumButton}
            onClick={() => inputRef?.current?.click()}
          >
            Upload Image
          </Button>
          <input
            data-testid="fileInput"
            ref={inputRef}
            type="file"
            accept="image/*"
            onChange={(e) => handleInputChange(e.target?.files?.[0])}
            style={{ display: 'none' }}
          />
        </div>
        <div className={styles.imagesContainer}>
          <Loader active={loading} className={styles.loader}>
            {images.map((image) => (
              <Image
                key={image.id}
                onClick={() => setSelectedImage(image)}
                className={classNames(styles.image, {
                  [styles.selected]: selectedImage.id === image.id,
                })}
                src={image.url}
                alt={image.filename}
                height={140}
                width={140}
                onLoad={(e) =>
                  setImagesSize({
                    ...imagesSize,
                    [image.id]: {
                      naturalWidth: e.currentTarget.naturalWidth,
                      naturalHeight: e.currentTarget.naturalHeight,
                    },
                  })
                }
              />
            ))}
            {isEmpty(images) && (
              <span className={styles.noResults}>
                No results found. Please try changing your search criteria.
              </span>
            )}
          </Loader>
        </div>
        <div className={styles.paginationContainer}>
          <Pagination
            onPageChange={handlePaginationChange}
            totalPages={pages}
            disabled={imagesLoading}
            activePage={activePage}
          />
        </div>
        <Divider />
      </>
    </Modal>
  )
}

AddImageModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default AddImageModal
