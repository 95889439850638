export const ADD_AND_GO_TO_LOOK = 'ADD_AND_GO_TO_LOOK'
export const ADD_ELEMENT_TO_LOOK = 'ADD_ELEMENT_TO_LOOK'
export const DELETE_ELEMENT_FROM_LOOK = 'DELETE_ELEMENT_FROM_LOOK'
export const DELETE_LOOK = 'DELETE_LOOK'
export const SET_COLLECTION = 'SET_COLLECTION'
export const SET_LOOK = 'SET_LOOK'
export const SET_LOOK_INDEX = 'SET_LOOK_INDEX'
export const SET_LOOK_LAYOUT = 'SET_LOOK_LAYOUT'
export const SET_MEDIA_FILE = 'SET_MEDIA_FILE'
export const SET_STATIC_ELEMENT_IN_LOOK = 'SET_STATIC_ELEMENT_IN_LOOK'
export const SET_TEXT_IN_LOOK = 'SET_TEXT_IN_LOOK'
export const TOGGLE_ELEMENT_IN_NEW_LOOK = 'TOGGLE_ELEMENT_IN_NEW_LOOK'
export const TOGGLE_PRODUCT_SELECTED = 'TOGGLE_PRODUCT_SELECTED'
export const UPDATE_VIEW_MODE = 'UPDATE_VIEW_MODE'
export const UPDATE_COLLECTION = 'UPDATE_COLLECTION'
export const EDIT_MODE = 'EDIT_MODE'
export const PRESENTATION_MODE = 'PRESENTATION_MODE'
export const SET_LOOKS = 'SET_LOOKS'
export const SET_Z_INDEXES = 'SET_Z_INDEXES'
export const UPDATE_Z_INDEX = 'UPDATE_Z_INDEX'
export const UPDATE_Z_INDEXES = 'UPDATE_Z_INDEXES'
export const MERGE_Z_INDEXES_TO_LAYOUTS = 'MERGE_Z_INDEXES_TO_LAYOUTS'
export const SET_3D_SHOWROOM_URL = 'SET_3D_SHOWROOM_URL'
