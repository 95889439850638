// TODO (ch61297): Move content from this file to shop/TablesConfiguration
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import size from 'lodash/size'

export const BULK_TABLE_ID = 'BULK_TABLE_ID'
export const SIZED_TABLE_ID = 'SIZED_TABLE_ID'

export const TOTAL_SECTION_ID = 'TOTAL_SECTION_ID'

export const PRICE_ROW_ID = 'PRICE_ROW_ID'
export const VARIANT_ROW_ID = 'VARIANT_ROW_ID'
export const DISCOUNT_ROW_ID = 'DISCOUNT_ROW_ID'
export const ACCORDION_DOOR_ROW_ID = 'ACCORDION_DOOR_ROW_ID'
export const UNITS_AVAILABLE_ROW_ID = 'UNITS_AVAILABLE_ROW_ID'
export const TOTAL_ROW_ID = 'TOTAL_ROW_ID'

export const FIRST_COLUMN_ID = 'FIRST_COLUMN_ID'
export const BULK_COLUMN_ID = 'BULK_COLUMN_ID'
export const QUANTITY_COLUMN_ID = 'QUANTITY_COLUMN_ID'
export const WHOLESALE_COLUMN_ID = 'WHOLESALE_COLUMN_ID'
export const SUGG_RETAILER_COLUMN_ID = 'SUGG_RETAILER_COLUMN_ID'

export const ROWS_EXCLUDED_FROM_MUTATION = {
  PRICE_ROW_ID,
  DISCOUNT_ROW_ID,
  ACCORDION_DOOR_ROW_ID,
  UNITS_AVAILABLE_ROW_ID,
  TOTAL_ROW_ID,
}

export const COLUMNS_EXCLUDED_FROM_MUTATION = {
  FIRST_COLUMN_ID,
  QUANTITY_COLUMN_ID,
  WHOLESALE_COLUMN_ID,
  SUGG_RETAILER_COLUMN_ID,
}

const COLUMNS_DEFAULT_STATE = {
  [FIRST_COLUMN_ID]: false,
  [BULK_COLUMN_ID]: 0,
  [QUANTITY_COLUMN_ID]: 0,
  [WHOLESALE_COLUMN_ID]: { min: 0, max: 0 },
  [SUGG_RETAILER_COLUMN_ID]: { min: 0, max: 0 },
}

export const PRICE_POSITION_IN_ROWS = 0
export const DISCOUNT_POSITION_IN_ROWS = 1
export const VARIANT_POSITION_IN_ROWS = 2
export const DOOR_POSITION_IN_ROWS = 3

export const BULK_POSITION_IN_COLUMNS = 1
// Bulk table is 2,
// Sized table we should sum size(variantColumn.values) and size(casepacks)
export const QUANTITY_POSITION_IN_COLUMNS = 2

export const initTableColumns = (dynamicColumnsIds = []) => {
  const columnsIds = [
    FIRST_COLUMN_ID,
    QUANTITY_COLUMN_ID,
    WHOLESALE_COLUMN_ID,
    SUGG_RETAILER_COLUMN_ID,
    ...dynamicColumnsIds,
  ]

  return reduce(
    columnsIds,
    (acc, columnId) => ({
      ...acc,
      [columnId]: get(COLUMNS_DEFAULT_STATE, `[${columnId}]`, null),
    }),
    {},
  )
}

export const initTableRows = (dynamicRowsIds = [], dynamicColumnsIds = []) => {
  const rowsIds = [PRICE_ROW_ID, VARIANT_ROW_ID, ...dynamicRowsIds]

  return reduce(
    rowsIds,
    (acc, rowId) => ({ ...acc, [rowId]: initTableColumns(dynamicColumnsIds) }),
    {},
  )
}

export const initializeTable = (
  sectionsIds,
  dynamicRowsIds = [],
  dynamicColumnsIds = [],
) =>
  reduce(
    sectionsIds,
    (acc, sectionId) => ({
      ...acc,
      [sectionId]: initTableRows(dynamicRowsIds, dynamicColumnsIds),
    }),
    {},
  )
export const getCellValueFromTable = ({ table, sectionId, rowId, columnId }) =>
  get(table, `[${sectionId}][${rowId}][${columnId}]`)

export const getDoorsFinalPositionInRows = (doors, index) => {
  const doorSize = size(doors)
  const doorPosition = index + 1
  return doorSize > 1 ? doorPosition + doorSize : doorPosition
}

export const getPositionInRows = (initialIndex, discounts) => {
  if (initialIndex > DISCOUNT_POSITION_IN_ROWS && isEmpty(discounts)) {
    return initialIndex - DISCOUNT_POSITION_IN_ROWS
  }
  return initialIndex
}

export const getShowNumberInput = (doorSize, index) => {
  const multipleDoorsCondition = doorSize > 1 && index !== 0
  return multipleDoorsCondition || doorSize === 1
}
