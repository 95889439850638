import { handleActions } from 'redux-actions'

import {
  initialFilterReducerState,
  unselectedFilterReducerState,
} from 'utils/filters/utils'

import {
  APPLY_SELECTED_FILTERS,
  ASCENDING,
  DESCENDING,
  TOGGLE_DESELECT_ALL_FILTERS,
  TOGGLE_SELECT_FILTER,
  TOGGLE_SORT_METHOD,
} from './constants'
import { styleboardFilters } from 'containers/Showroom/StyleboardList/StyleboardList.filters'

export const defaultState = {
  filters: {
    selected: {
      ...initialFilterReducerState(styleboardFilters),
    },
    applied: {
      ...initialFilterReducerState(styleboardFilters),
    },
  },
  sorting: {
    column: null,
    order: null,
  },
}

const reducer = handleActions(
  {
    [TOGGLE_SELECT_FILTER]: (state, { payload }) => {
      const { dataKey } = payload
      const filterSettings = styleboardFilters[dataKey]
      const { reducerHandler } = filterSettings
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: reducerHandler(state.filters.selected, { ...payload }),
        },
      }
    },
    [TOGGLE_DESELECT_ALL_FILTERS]: (state) => ({
      ...state,
      filters: {
        ...state.filters,
        selected: {
          ...state.filters.selected,
          ...unselectedFilterReducerState(styleboardFilters),
        },
      },
    }),
    [APPLY_SELECTED_FILTERS]: (state) => ({
      ...state,
      filters: {
        ...state.filters,
        applied: state.filters.selected,
      },
    }),
    [TOGGLE_SORT_METHOD]: (state, { payload }) => {
      const { columnId } = payload
      const {
        sorting: { column, order },
      } = state

      if (column !== columnId) {
        return { ...state, sorting: { column: columnId, order: ASCENDING } }
      }

      if (order === null) {
        return {
          ...state,
          sorting: {
            column: column === columnId ? column : columnId,
            order: ASCENDING,
          },
        }
      }

      if (order === ASCENDING) {
        return { ...state, sorting: { column, order: DESCENDING } }
      }

      if (order === DESCENDING) {
        return { ...state, sorting: { column: null, order: null } }
      }

      return state
    },
  },
  defaultState,
)

export default reducer
